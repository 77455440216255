import React, { Fragment } from "react";
import { withRouter } from "react-router";
import {
    BonusConfig, BonusConfigListRequest, BonusStatusAlert, BonusVisibilityStatus, ListRequest, TurnoverContributionsListRequest
} from "../../http/protocol";
import { getContainer } from "../../ioc/IOCSetup";
import { BonusConfigService } from "../../services/BonusConfigService";
import { Form, FormListener } from "../form/Form";
import { FormGroup } from "../form/FormGroup";
import { SelectInput } from "../form/SelectInput";
import { Submit } from "../form/Submit";
import { ColumnType } from "../list/list/ColumnType";
import { ActionType } from "../list/list/DataTable";
import { EntityDataTable } from "../list/list/EntityDataTable";
import { ListUtils } from "../list/list/ListUtils";
import { PagerModel } from "../list/list/PagerModel";
import { ListView, ListViewProps, ListViewState } from "../ListView";




interface State extends ListViewState<TurnoverContributionsListRequest> {
    form? : BonusConfigListRequest;
    validationMap? : any;
}
interface Props extends ListViewProps  {

}

export class BonusConfigListView extends ListView<State, BonusConfigListRequest> implements FormListener<BonusConfigListRequest>{

    columns = [
        {
            property: "bonusStatusAlert",
            name: "Status",
            renderer: (property: string, name: string, item: any) => {

                let backgroundColor = '';
                if (item["bonusStatusAlert"] === BonusStatusAlert.GREEN) {
                    backgroundColor = '#28A745';
                } else if (item["bonusStatusAlert"] === BonusStatusAlert.GREY) {
                    backgroundColor = '#6C757D';
                } else if (item["bonusStatusAlert"] === BonusStatusAlert.RED) {
                    backgroundColor = '#F1556C';
                } else if (item["bonusStatusAlert"] === BonusStatusAlert.YELLOW) {
                    backgroundColor = '#F7B84B';
                }
                const backgroundColorCSS = {
                    backgroundColor: backgroundColor
                };

                return <span style={backgroundColorCSS} className={"dot"} title={item["bonusAlertDescription"]}/>
            }
        },
        { property : "id", name : "ID", orderBy : "id"},
        { property : "name", name : "Name"},
        { property : "type", name : "Logic Type"},
        { property : "monetaryConfig.currencyCode", name : "Currency"},
        { property:  "validFromDate" , name : "Valid From", type : ColumnType.DATE},
        { property:  "validToDate" , name : "Valid To", type : ColumnType.DATE},
        { property: "expiresAfterDays", name : "Expires After (days)"}
    ];

    actions = [
        { id : "delete", name : "delete", className : "btn btn-danger", iconClassName : "fa fa-times", type : ActionType.CONFIRMATION},
        { id : "view", name : "View", className : "btn btn-primary", iconClassName : "fa fa-user", type : ActionType.LINK, getLink : (item : any) => "/bonus/config/" + item.id }

    ];


    service : BonusConfigService;

    constructor(props : Props){
        super(props, "/bonus/configs/list");
        this.state = {...this.state, validationMap : {}, form : {...this.state.listRequest } as any};
        if(!(this.state.listRequest as any).bonusVisibilityStatus) {
            let lr = {...this.state.listRequest, bonusVisibilityStatus : BonusVisibilityStatus.NON_ARCHIVED} as any;
            this.state = {...this.state, listRequest : lr, form : lr};
        }
        this.service = getContainer().getBonusConfigService();
    }
    onSubmit() : void | Promise<any> {
        if(this.state.form) {
            this.updateListState(this.state.form);
        }
    }
    onSubmitError() :  void {

    }

    formDidUpdate(formModel: ListRequest, validationMap?: any): void {
        this.setState({form : formModel, validationMap : validationMap});
    }

    onAction(item : BonusConfig, action : string) : Promise<any> | undefined {
        if(action == "delete") {
            return  this.service.remove(""+item.id);
        }
    }

    pagerChanged(pager : PagerModel) {
        if(this.state.listRequest) {
            console.log("pager changed!", pager);
            let listRequest = ListUtils.toListRequest<any>(this.state.listRequest,pager);
            this.updateListState(listRequest);
        }
    }
    getTitle() {
        return "List Bonuses";
    }

    renderContent() {
        if(!this.state.listRequest) {
            return <div/>;
        }
        return (
            <Fragment>
                <div className={"card-box"}>
                    <h4>Search Filters</h4>
                    <Form formListener={this} modelObject={this.state.form} validationMap={this.state.validationMap}>
                        <FormGroup className={"form-group"}>
                            <label>Status</label>
                            <SelectInput model={"bonusVisibilityStatus"} className={"form-control"} values={Object.keys(BonusVisibilityStatus)}/>
                            <span className={"help-text"}>Search bonuses with the following status</span>
                        </FormGroup>
                        <Submit className={"btn btn-primary"}>Search</Submit>
                    </Form>
                </div>
                <EntityDataTable service={this.service}
                                 onPagerChange={this.pagerChanged.bind(this)}
                                 columns={this.columns}
                                 listRequest={this.state.listRequest}
                                 onAction={this.onAction.bind(this)}
                                 actions={this.actions}/>
            </Fragment>
        );
    }



}

export default withRouter(BonusConfigListView);
