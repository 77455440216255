import {HttpService} from "../http/HttpService";
import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {ActiveLocales, ListTranslationsResponse, Translation, TranslationExportType, TranslationsBatchUpdateResult} from "../http/protocol";


export class TranslationsService  extends AbstractAdminEntityService<Translation, ListTranslationsResponse>  {

    constructor(http : HttpService) {
        super("/translations", http)
    }

    getLocales() : Promise<ActiveLocales> {
        return this.http.get(this.entityPath + "/locales");
    }

    delete(key: string, locale: string) {
        const url = `${this.entityPath}/delete/${locale}/${key}`
        return this.http.delete(url)
    }

    getJsonExportUrl(locale: string) {
        return `${this.http.getBaseUrl()}${this.entityPath}/export/${locale}.json`
    }

    getCsvExportUrl(locales: string[], exportType: TranslationExportType) {
        const params = locales.map(l => `locale=${l}`).join("&")
        return `${this.http.getBaseUrl()}${this.entityPath}/export/translations.csv?${params}&type=${exportType}`
    }

    batchUpdateTranslations(translationList: Translation[]): Promise<TranslationsBatchUpdateResult> {
        return this.http.post(this.entityPath + "/batch", translationList);
    }

    importTranslationsCsv(csv: string, deleteIfNullText = false): Promise<TranslationsBatchUpdateResult> {
        return this.http.post(`${this.entityPath}/import/csv?deleteIfNullText=${deleteIfNullText}`, csv, {
            headers : {
                "Content-type" : "text/csv"
            }
        });
    }

}