import {useRouteMatch} from "react-router-dom";
import {RouterViewTemplate, useLoadingState} from "../RouterView";
import {getContainer} from "../../ioc/IOCSetup";
import {SportsBookBonusItem} from "../../http/protocol";
import {useState} from "react";
import {withRouter} from "react-router";

const SportsbookBonusDetailsView = () => {

    let match = useRouteMatch<{ id: string }>();
    const [bonusItem, setBonusItem] = useState<SportsBookBonusItem>({});

    const status = useLoadingState(() => {
        const id = match.params.id;

        return getContainer().getSportsbookBonusService().getBonusDetails(id).then((bonusItem) => {
            setBonusItem(bonusItem);
        });

    }, []);

    return (
        <RouterViewTemplate status={status} title={"Free Bet Details"}>
            <div className="">
                <table className={"table table-striped "}>
                    <tbody>
                    <tr>
                        <td>Bonus ID</td>
                        <td>{bonusItem.id}</td>
                    </tr>
                    <tr>
                        <td>Template ID</td>
                        <td>{bonusItem.templateId}</td>
                    </tr>
                    <tr>
                        <td>Name</td>
                        <td>{bonusItem.name}</td>
                    </tr>
                    <tr>
                        <td>Status</td>
                        <td>{bonusItem.status}</td>
                    </tr>
                    <tr>
                        <td>Type</td>
                        <td>{bonusItem.type}</td>
                    </tr>
                    <tr>
                        <td>Player UID</td>
                        {/*this is nano players uid*/}
                        <td>{bonusItem.playerUId}</td>
                    </tr>
                    <tr>
                        <td>External Player ID</td>
                        {/*this is sportsbook provider player id*/}
                        <td>{bonusItem.externalPlayerId}</td>
                    </tr>
                    <tr>
                        <td>Brand ID</td>
                        <td>{bonusItem.brandId}</td>
                    </tr>
                    <tr>
                        <td>Receipt Date</td>
                        <td>{new Date(bonusItem.receiptDate || 0).toDateString()}</td>
                    </tr>
                    <tr>
                        <td>Issue Type</td>
                        <td>{bonusItem.issueType}</td>
                    </tr>
                    <tr>
                        <td>Viewed</td>
                        <td>{bonusItem.viewed  ? 'true' : 'false'}</td>
                    </tr>
                    <tr>
                        <td>Activation Date</td>
                        <td>{new Date(bonusItem.activationDate || 0).toDateString()}</td>
                    </tr>
                    <tr>
                        <td>Force Activated</td>
                        <td>{bonusItem.forceActivated ? 'true' : 'false'}</td>
                    </tr>
                    <tr>
                        <td>Ending Date</td>
                        <td>{new Date(bonusItem.endingDate || 0).toDateString()}</td>
                    </tr>
                    <tr>
                        <td>From Time</td>
                        <td>{new Date(bonusItem.fromTime || 0).toDateString()}</td>
                    </tr>
                    <tr>
                        <td>To Time</td>
                        <td>{new Date(bonusItem.toTime || 0).toDateString()}</td>
                    </tr>
                    <tr>
                        <td>Amount</td>
                        <td>{bonusItem.freebetData?.amount}</td>
                    </tr>
                    <tr>
                        <td>Currency</td>
                        <td>{bonusItem.freebetData?.currency}</td>
                    </tr>
                    <tr>
                        <td>Exchange Rate</td>
                        <td>{bonusItem.freebetData?.exchange_rate}</td>
                    </tr>
                    <tr>
                        <td>Type</td>
                        <td>{bonusItem.freebetData?.type}</td>
                    </tr>
                    <tr>
                        <td>Min Selection</td>
                        <td>{bonusItem.freebetData?.min_selection}</td>
                    </tr>
                    <tr>
                        <td>Max Selection</td>
                        <td>{bonusItem.freebetData?.max_selection}</td>
                    </tr>
                    <tr>
                        <td>Min Odd</td>
                        <td>{bonusItem.freebetData?.min_odd}</td>
                    </tr>
                    <tr>
                        <td>Max Odd</td>
                        <td>{bonusItem.freebetData?.max_odd}</td>
                    </tr>
                    <tr>
                        <td>Bonus Refund</td>
                        <td>{bonusItem.freebetData?.bonus_refund ? 'true' : 'false'}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className="row">
            </div>
        </RouterViewTemplate>
    )
}

export default withRouter(SportsbookBonusDetailsView);