import React from "react";
import {RewardsConfigService} from "../../services/RewardsConfigService";
import {getContainer} from "../../ioc/IOCSetup";
import {RewardStatus, RewardType, UserGameReward} from "../../http/protocol";
import {formatDate} from "../../util/date";
import {LoadingSpinner} from "../../util/LoadingSpinner";
import {Collapsible} from "../Collapsible";
import {Link, NavLink} from "react-router-dom";
import {t} from "../../i18n";


interface Props {
    userId:number;
}
interface State {
    rewards?: UserGameReward[];
    showClaimed?: boolean;
    loading?: boolean;
}
export class UserRewardListComponent extends React.Component<Props, State> {
    service:RewardsConfigService;
    constructor(props:Props) {
        super(props);
        this.service = getContainer().getRewardsConfigService();
        this.state = {rewards:[], showClaimed : false, loading : true }
    }

    componentDidMount() {
       this.load();
    }
    toggleShowClaimed() {
        this.setState({ showClaimed : !this.state.showClaimed });
    }
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if(prevState.showClaimed != this.state.showClaimed) {
            this.load();
        }
    }

    load() {
        this.setState({loading : true})
        this.service.listUserRewards({userId:this.props.userId, status : this.state.showClaimed ? RewardStatus.CLAIMED : RewardStatus.NEW, offset: 0, limit: 20, orderBy : "created", ascending : false}).then((res) => {
            this.setState({ rewards: res.items, loading : false});
        })
    }
    remove(reward :  UserGameReward) {
        if(reward.id) {
            return this.service.removeRewardFromUser(reward.id).then(()=>this.load());
        } else {
            return Promise.reject("Invalid reward");
        }
    }
    render() {
        return(
            <Collapsible title={t(`title.showUserRewards`)} collapsed={true}>
                <div>
                    <label>
                        <input type={"checkbox"} checked={this.state.showClaimed ?? false} onClick={()=>this.toggleShowClaimed()}/> {t(`label.showClaimedRewards`)}
                    </label>
                </div>
                {this.state.loading && <LoadingSpinner />}
                {!this.state.loading && (
                    <>
                        {this.state.rewards?.length == 0 && (<div className={"alert alert-info"}>{t(`alertInfo.noRewardsFound`)}</div>)}
                        {(this.state.rewards?.length != 0) && (
                            <table className={"table table-striped mb-0 dt-responsive dataTable no-footer dtr-inline"}>
                                <tr>
                                    <th>{t(`dataTable.header.id`)}</th>
                                    <th>{t(`dataTable.header.rewardType`)}</th>
                                    <th>{t(`dataTable.header.gameId`)}</th>
                                    <th>{t(`dataTable.header.freespinsAmount`)}</th>
                                    <th>{t(`dataTable.header.expires`)}</th>
                                    <th>{t(`dataTable.header.removed`)}</th>
                                    <th>{t(`dataTable.header.action`)}</th>
                                </tr>
                                <tbody>
                                {this.state.rewards?.map((reward) => {
                                    console.log('REWARD: ', reward)
                                    return (
                                        <tr key={reward.id}>
                                            <td>
                                                <Link className="list-link" to={`/user-reward/${reward.id}`}>{reward.id}</Link>
                                            </td>
                                            <td>{reward.rewardTemplate?.rewardType}</td>
                                            <td>{reward.gameId}</td>
                                            <td>{reward.rewardTemplate?.rewardType == RewardType.freespins ? reward.nrOfFreeSpins : reward.amount}</td>
                                            <td>{formatDate(reward.expires)}</td>
                                            <td>{reward.removed ? <span className={"badge badge-danger"}>{t(`badge.yes`)}</span> : <span className={"badge badge-info"}>{t(`badge.no`)}</span>}</td>
                                            <td><NavLink className={"btn btn-primary"} to={"/user-reward/"+reward.id}>{t(`button.details`)}</NavLink></td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        )}
                    </>
                )}
                <NavLink to={"/user-rewards/list/userId/"+this.props.userId} className={"btn btn-primary"}>{t(`button.showAll`)}</NavLink>

            </Collapsible>
        )
    }
}