import React, { useState } from 'react';
import { Modal, Button, Form, Card } from 'react-bootstrap';
import { AbuseType } from '../../http/protocol';
import { t } from '../../i18n';

interface ReportAbuseDialogProps {
    show: boolean;
    onHide: () => void;
    onSubmit: (report: any) => void;
    updatePage: () => void;
}

const ReportAbuseDialog: React.FC<ReportAbuseDialogProps> = ({ show, onHide, onSubmit, updatePage }) => {
    const [selectedAbuseTypes, setSelectedAbuseTypes] = useState<AbuseType[]>([]);
    const [comment, setComment] = useState('');
    const [blockPlayer, setBlockPlayer] = useState(false);

    const handleCheckboxChange = (abuseType: AbuseType) => {
        setSelectedAbuseTypes(prev =>
            prev.includes(abuseType) ? prev.filter(type => type !== abuseType) : [...prev, abuseType]
        );
    };

    const handleSubmit = () => {
        onSubmit({ selectedAbuseTypes, comment, blockPlayer });
        onHide();
        updatePage();
    };

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title className="modal-title">{t('title.reportAbuse')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-full">
                <Card>
                    <Card.Body>
                        <p>{t('helpText.reportAbuse')}</p>
                        <hr/>
                        <Form>
                            <Form.Label>{t('label.abuseType')}</Form.Label>
                            {Object.values(AbuseType).map((type) => (
                                <Form.Check
                                    key={type}
                                    type="checkbox"
                                    label={t(`abuseType.${type}`)}
                                    checked={selectedAbuseTypes.includes(type)}
                                    onChange={() => handleCheckboxChange(type)}
                                />
                            ))}
                            <hr/>
                            <Form.Group controlId="comment">
                                <Form.Label>{t('label.comment')}</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                />
                            </Form.Group>
                            <hr/>
                            <Form.Check
                                type="checkbox"
                                label={t('label.blockPlayer')}
                                checked={blockPlayer}
                                onChange={() => setBlockPlayer(!blockPlayer)}
                            />
                        </Form>
                    </Card.Body>
                </Card>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    {t('button.cancel')}
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    {t('button.submit')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ReportAbuseDialog;