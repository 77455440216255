import {MetricsService} from "./services/MetricsService";
import {getContainer} from "./ioc/IOCSetup";
import * as React from "react";
import {MetricsValuePanel} from "./ui/metrics/MetricsValuePanel";
import Big from 'big.js';
import {MetricHighChart} from "./ui/metrics/MetricHighChart";
import {MetricsSingleValuePanel} from "./ui/metrics/MetricSingleValuePanel";
import {PaymentService} from "./services/PaymentService";
import {UserService} from "./services/UserService";
import {KycStatus, Level, PermissionCategory} from "./http/protocol";
import {SystemEventService} from "./services/SystemEventService";
import {t} from "./i18n";
import { PermissionRequired } from "./ui/security/PermissionRequired";

interface Props {
    currency: string;
}

interface State {
    dailyLogin: any;
    dailyRevenue: any;
    dailyDeposit: any;
    monthlyRevenue?: number;
    lastMonthRevenue?: number;
    monthlyData: MonthlyData[];
    monthlyDataProduct: MonthlyData[];
    dailyLoginGraph: MonthlyData[];
    pendingWithdraws?: number;
    failedPayments?: number;
    kycStatusCount?: number;
    errorEvents?: number;
}

interface MonthlyData {
    data: Array<{ date: string, value: number }>;
    title: string
}


interface MetricsServiceResult {
    points: Array<{
        date: string,
        value: string
    }>
}

export class HomeMetrics extends React.Component<Props, State> {
    //Services
    metricsService: MetricsService;
    paymentService: PaymentService;
    userService: UserService;
    systemEventService: SystemEventService;
    //Dates
    date = new Date();
    today = new Date().toISOString().slice(0, 10);
    yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10);
    startOfThisMonth = new Date(this.date.getFullYear(), this.date.getUTCMonth(), 2).toISOString().slice(0, 10);
    startOfLastMonth = new Date(this.date.getFullYear(), this.date.getMonth() - 1, 2).toISOString().slice(0, 10);
    endOfLastMonth = new Date(this.date.getFullYear(), this.date.getMonth(), 1).toISOString().slice(0, 10);
    ninetyDaysAgo = new Date(new Date().setDate(new Date().getDate() - 90)).toISOString().slice(0, 10);

    constructor(props: Props) {
        super(props);
        this.metricsService = getContainer().getMetricsService();
        this.paymentService = getContainer().getPaymentService();
        this.userService = getContainer().getUserService();
        this.systemEventService = getContainer().getSystemEventService();

        this.state = {
            dailyLogin: {[this.today]: 0, [this.yesterday]: 0},
            dailyRevenue: {[this.today]: 0, [this.yesterday]: 0},
            dailyDeposit: {[this.today]: 0, [this.yesterday]: 0},
            monthlyData: [],
            monthlyDataProduct: [],
            dailyLoginGraph: []
        };
    }


    componentDidMount() {
        let pendingListRequest: any = {types: ["WITHDRAWAL"], statuses: ["PENDING", "INITIATED"], limit : 1, ascending:false};
        let failedListRequest: any = {types: ["WITHDRAWAL", "DEPOSIT"], statuses: ["FAILED", "FAILED_PERMANENTLY"], limit : 1, ascending:false};

        // IMPORTANT: Split fetch from different services in separate promises. Otherwise one missing permission will break all widgets.

        Promise.all<any>([
            this.metricsService.getUniqueUsersCount("LOGIN"),
            this.metricsService.getSum("REVENUE", null, this.props.currency),
            this.metricsService.getSum("REVENUE", null, this.props.currency, this.startOfThisMonth, this.today),
            this.metricsService.getSum("REVENUE", null, this.props.currency, this.startOfLastMonth, this.endOfLastMonth),
            this.metricsService.getSum("DEPOSIT", null, this.props.currency),
            this.metricsService.getSum("REVENUE", "POKER", this.props.currency, this.ninetyDaysAgo, this.today),
            this.metricsService.getSum("REVENUE", "CASINO", this.props.currency, this.ninetyDaysAgo, this.today),
            this.metricsService.getSum("REVENUE", "SPORTSBOOK", this.props.currency, this.ninetyDaysAgo, this.today),
            this.metricsService.getUniqueUsersCount("LOGIN", this.ninetyDaysAgo, this.today),
        ]).then((values) => {
            let dailyLogin = this.setDailyValues(values[0]);
            let dailyRevenue = this.setDailyValues(values[1]);
            let monthlyRevenue = this.calculateMonthlyRevenue(values[2]);
            let lastMonthRevenue = this.calculateMonthlyRevenue(values[3]);
            let dailyDeposit = this.setDailyValues(values[4]);
            let monthlyData: MonthlyData[] = [{
                data: values[2] != null ? values[2].points : [],
                title: "All"
            }, ...this.state.monthlyData];
            let monthlyDataProduct: MonthlyData[] = [
                {data: values[5] != null ? values[5].points : [], title: "Poker"},
                {data: values[6] != null ? values[6].points : [], title: "Casino"},
                {data: values[7] != null ? values[7].points : [], title: "Sportsbook"},
                ...this.state.monthlyData];
            let dailyLoginGraph: MonthlyData[] = [{data: values[8] != null ? values[8].points : [], title: "Login"}];
            this.setState({
                dailyLogin: dailyLogin,
                dailyRevenue: dailyRevenue,
                monthlyRevenue: monthlyRevenue,
                lastMonthRevenue: lastMonthRevenue,
                dailyDeposit: dailyDeposit,
                monthlyData: monthlyData,
                monthlyDataProduct: monthlyDataProduct,
                dailyLoginGraph: dailyLoginGraph,
            });
        });

        Promise.all<any>([
            this.paymentService.getDashboardStats()
        ]).then(([stats]) => {
            this.setState({
                pendingWithdraws: stats.pendingWithdrawals,
                failedPayments: stats.failedPayments
            });
        });

        Promise.all<any>([
            this.userService.getUserKycStatusCount(KycStatus.REVIEW_NEEDED),
        ]).then(([kycStatusCount]) => {
            this.setState({
                kycStatusCount: kycStatusCount,
            });
        });        

        Promise.all<any>([
            this.systemEventService.getCounts()
        ]).then(([errorEvents]) => {
            this.setState({
                errorEvents: errorEvents.errorEventsCount,
            });
        });        
    }

    calculateMonthlyRevenue(data: MetricsServiceResult) {
        let result: number = 0;
        if (data === null || data === undefined) {
            return Number(Big(result).round(4));
        }
        data.points.map((data: any) => {
            result += Number(data.value);
        });
        return Number(Big(result).round(4));
    }

    setDailyValues(data: MetricsServiceResult) {
        let result: any = {[this.today]: 0, [this.yesterday]: 0};
        if (data === null || data.points === undefined) {
            return result;
        }
        data.points.map((point) => {
            if (point.date === this.today) {
                result[this.today] = point.value;
            }
            if (point.date === this.yesterday) {
                result[this.yesterday] = point.value;
            }
        });

        return result;
    }


    render() {
        return (
            <div>
                <div className="row">
                    <PermissionRequired permissions={[PermissionCategory.METRICS, PermissionCategory.PAYMENTS]} accessLevel={"r"} showError={false}>
                        <MetricsSingleValuePanel
                            header={""}
                            mainTitle={this.state.pendingWithdraws && this.state.pendingWithdraws > 1 ? t(`home.dashboard.card.pendingWithdrawals`) : t(`home.dashboard.card.pendingWithdrawal`)}
                            mainValue={this.state.pendingWithdraws && this.state.pendingWithdraws}
                            type={this.state.pendingWithdraws && this.state.pendingWithdraws > 0 ? "WARNING" : "OK"}
                            navTo={"/payments"}
                            listRequest={{types: ["WITHDRAWAL"], statuses: ["PENDING", "INITIATED"]}}/>

                        <MetricsSingleValuePanel
                            header={""}
                            mainTitle={t(`home.dashboard.card.failedPayments`)}
                            mainValue={this.state.failedPayments && this.state.failedPayments}
                            type={this.state.failedPayments && this.state.failedPayments > 0 ? "ERROR" : "OK"}
                            navTo={"/payments"}
                            listRequest={{types: ["WITHDRAWAL", "DEPOSIT"], statuses: ["FAILED", "FAILED_PERMANENTLY"]}}/>
                    </PermissionRequired>

                    <PermissionRequired permissions={[PermissionCategory.METRICS, PermissionCategory.USER_KYC_STATUS, PermissionCategory.USERS]} accessLevel={"r"} showError={false}>
                        <MetricsSingleValuePanel
                            header={""}
                            mainTitle={t(`home.dashboard.card.kycReview`)}
                            mainValue={this.state.kycStatusCount && this.state.kycStatusCount}
                            type={this.state.kycStatusCount && this.state.kycStatusCount > 0 ? "WARNING" : "OK"}
                            navTo={"/users"}
                            listRequest={{kycStatus: [KycStatus.REVIEW_NEEDED]}}/>
                    </PermissionRequired>

                    <PermissionRequired permissions={[PermissionCategory.AUDIT]} accessLevel={"r"} showError={false}>
                        <MetricsSingleValuePanel
                            header={""}
                            mainTitle={t(`home.dashboard.card.error`)}
                            mainValue={this.state.errorEvents && this.state.errorEvents}
                            type={this.state.errorEvents && this.state.errorEvents > 0 ? "ERROR" : "OK"}
                            navTo={"/system-events"}
                            listRequest={{level: Level.ERROR, resolved: false, offset: 0, limit: 50}}
                        />
                    </PermissionRequired>
                </div>
                <PermissionRequired permissions={[PermissionCategory.METRICS]} accessLevel={"r"} showError={false}>
                    <div className="row">
                        <MetricsValuePanel
                            header={t(`home.dashboard.card.logins`)}
                            mainTitle={t(`home.dashboard.card.today`)}
                            subTitle={t(`home.dashboard.card.yesterday`)}
                            mainValue={this.state.dailyLogin[this.today]}
                            subValue={this.state.dailyLogin[this.yesterday]}
                            type="LOGIN"/>

                        <MetricsValuePanel
                            header={t(`home.dashboard.card.deposits`)}
                            mainTitle={t(`home.dashboard.card.today`)}
                            subTitle={t(`home.dashboard.card.yesterday`)}
                            mainValue={this.state.dailyDeposit[this.today]}
                            subValue={this.state.dailyDeposit[this.yesterday]}
                            currency={this.props.currency}
                            type="DEPOSIT"/>

                        <MetricsValuePanel
                            header={t(`home.dashboard.card.revenue`)}
                            mainTitle={t(`home.dashboard.card.today`)}
                            subTitle={t(`home.dashboard.card.yesterday`)}
                            mainValue={this.state.dailyRevenue[this.today]}
                            subValue={this.state.dailyRevenue[this.yesterday]}
                            currency={this.props.currency}
                            type="REVENUE"/>

                        <MetricsValuePanel
                            header={t(`home.dashboard.card.revenue`)}
                            mainTitle={t(`home.dashboard.card.thisMonth`)}
                            subTitle={t(`home.dashboard.card.lastMonth`)}
                            mainValue={this.state.monthlyRevenue}
                            subValue={this.state.lastMonthRevenue}
                            currency={this.props.currency}
                            type="REVENUE"/>

                        {/* <MetricHighChart containerClassName="col-lg-12 col-xl-6" data={[this.state.monthlyData[0]]}
                                        header={`${t(`home.dashboard.card.dailyRevenue`)} ${this.props.currency} - ${this.date.toLocaleString('default', {month: 'long'})}`}/>
                        <MetricHighChart containerClassName="col-lg-12 col-xl-6" data={this.state.monthlyDataProduct}
                                        header={`${t(`home.dashboard.card.dailyRevenue`)} ${this.props.currency} ${t(`home.dashboard.card.byProduct`)} - 90 ${t(`home.dashboard.card.days`)}`}/>
                        <MetricHighChart containerClassName="col-lg-12 col-xl-6" data={this.state.dailyLoginGraph}
                                        header={`${t(`home.dashboard.card.dailyLogins`)} - 90 ${t(`home.dashboard.card.days`)}`}/> */}

                    </div>
                </PermissionRequired>
            </div>
        )
    }
}