import React, { ReactNode } from "react";
import { Button, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { SubMenuTab } from "../../http/protocol";
import _ from "lodash";

interface Props {
    onDragOver: (event: React.DragEvent<HTMLLIElement>, index: number) => void;
    onDragStart: (event: React.DragEvent<HTMLDivElement>, index: number) => void;
    onDragEnd: (event: React.DragEvent<HTMLDivElement>) => void;
    deleteSubMenuTab: (id?: string | number) => void;
    editSubMenuTab: (id: number) => void;
    index: number;
    item: SubMenuTab;
}

export default function SubmenuItem(props: Props) {
    return (
        <li className="row tab-row w-100" key={props.index} onDragOver={(e) => props.onDragOver(e, props.index)}>
            <div className="drag d-flex p-1 casino-tab-drag-container w-100" draggable onDragStart={(e) => props.onDragStart(e, props.index)} onDragEnd={(e) => props.onDragEnd(e)}>
                <div className="casino-tab-drag-name-section">
                    <i className="fe-menu" />
                    <span className="content">
                        <span className={"content-container"}>{props.item.name}</span>
                    </span>
                </div>
                <div>
                    <button className="btn btn-primary mx-1" type="button" onClick={() => props.editSubMenuTab(props.index)}>
                        Edit
                    </button>
                    <button className="btn btn-danger mx-1" type="button" onClick={() => props.deleteSubMenuTab(props.item.id ?? 0)}>
                        Delete
                    </button>
                </div>
            </div>
        </li>
    );
}
