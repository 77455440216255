import * as React from "react";
import {useState} from "react";
import {getContainer} from "../../ioc/IOCSetup";
import {CardBox, RouterViewTemplate, useLoadingState} from "../RouterView";
import {Form, useForm} from "../form/Form";
import {FormSubmitError} from "../form/FormFeedback";
import {AdminSecuritySettings, OtpRequirement} from "../../http/protocol";
import "./SettingsGeneralView.scss";
import {SelectInput} from "../form/SelectInput";
import {Submit} from "../form/Submit";


export const SecuritySettingsView = () => {
    const [settings, setSettings] = useState<AdminSecuritySettings|undefined>();

    const status = useLoadingState(()=>{
        return getContainer().getSecuritySettingsService().fetchSecuritySettings().then((current) => {
                setSettings(current);
        })
    },[]);

    return (
        <RouterViewTemplate status={status}>
            <CardBox title={"Admin Security Settings"}>
                {settings && <SettingsForm initial={settings}/>}
            </CardBox>
        </RouterViewTemplate>
    )
}
const SettingsForm = (props : { initial : AdminSecuritySettings}) =>{

    const [form , listener, setFormState] = useForm<AdminSecuritySettings>(props.initial, () => {
        return getContainer().getSecuritySettingsService().saveSettings(form.model)
    });

    return (
        <Form className={"form"} formListener={listener} modelObject={form.model} validationMap={form.validationMap}>
            <div className={"form-group"}>
                <label>Admin User Login OTP Requirement</label>
                <SelectInput className={"form-control"} model={"otpRequirement"} values={Object.keys(OtpRequirement)} />
                <span className={"help-block"}>
                    <strong>NOT_REQUIRED</strong> - No verification required.<br/>
                    <strong>ALWAYS</strong> - always require the admin users to enter verification code.<br/>
                    <strong>PER_DEVICE</strong> - require an admin to enter a verification code once per device per 30 days.<br/>
                </span>
            </div>
            <div>
                <FormSubmitError />
                <Submit className={"btn btn-primary"}>Save</Submit>

            </div>
        </Form>
    )
}