import {useRouteMatch} from "react-router-dom";
import {useEffect, useState} from "react";
import {CardBox, RouterViewTemplate, useLoadingState} from "../RouterView";
import {getContainer} from "../../ioc/IOCSetup";
import {Link} from "react-router-dom";
import {
    FormStep,
    InputType,
    UserAttributeDefinition,
    UserInput,
    UserInputGroup,
    UserJourneyWrapper,
    UserLoginPrincipal,
    UserPrimaryField, UserRiskRuleCollection
} from "../../http/protocol";
import {Form, useForm} from "../form/Form";
import "./EditUserJourney.scss";
import {DragParent} from "../casino/EditCasinoPage";
import {Draggable} from "react-beautiful-dnd";
import {moveItem} from "../../util/ArrayUtils";
import {SelectInput} from "../form/SelectInput";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {DeleteButtonModal} from "../../util/DeleteButtonModal";

export const EditUserJourney = () => {
    const match = useRouteMatch<{id : string}>();
    const [attributes, setAttributes] = useState<UserAttributeDefinition[]>([]);
    const [riskCollections, setRiskCollections] = useState<UserRiskRuleCollection[]>([]);
    const [journeyWrapper, setJourneyWrapper] = useState<UserJourneyWrapper>();
    const [editStep, setEditStep] = useState(-1);


    const getAttribute = (id : string) => {
        console.log("get attributeBy key = ", id);
        let attribute = attributes.find(a => a.attributeKey == id)
        if(attribute) {
            return attribute.attributeKey;

        } else {
            return undefined;
        }
    }

    const status = useLoadingState(()=>{
        return Promise.all([
            getContainer().getUserAttributeService().list({
                limit: 200,
                offset: 0,
            }),
            getContainer().getUserRiskCollectionService().list({
                limit : 100,
                offset : 0
            }),
            match.params.id === "new" ? Promise.resolve({}) : getContainer().getUserJourneyService().getEntity(match.params.id)
        ]).then(([attributeResponse, riskCollections,journeyWrapper]) => {
            setAttributes(attributeResponse.items ?? []);
            setJourneyWrapper(journeyWrapper);
            setRiskCollections(riskCollections.items ?? []);
        })
        }, [])

    let registration = journeyWrapper?.journey?.registration;

    const updateGroup = (stepIndex : number, groupIndex : number, g : UserInputGroup) => {
        let journey = journeyWrapper?.journey;
        let steps = journey?.registration?.formSteps?.concat() ?? [];
        let groups = steps[stepIndex]?.groups?.concat() ?? [];
        groups[groupIndex] = g;
        steps[stepIndex] = {...steps[stepIndex], groups : groups};
        setJourneyWrapper({
            ...journeyWrapper,
            journey : {
                ...journey,
                registration : {
                    ...journey?.registration,
                    formSteps : steps
                }}})
    }
    const addGroup = (stepIndex : number) => {
        let journey = journeyWrapper?.journey;
        let steps = journey?.registration?.formSteps?.concat() ?? [];
        steps[stepIndex] = {...steps[stepIndex], groups : steps[stepIndex].groups?.concat({}) ?? [{}]};

        setJourneyWrapper({
            ...journeyWrapper,
            journey : {
                ...journey,
                registration : {
                    ...journey?.registration,
                    formSteps : steps
                }}})
    }
    const removeGroup = (stepIndex : number, groupIndex : number) => {
        let journey = journeyWrapper?.journey;
        let steps = journey?.registration?.formSteps?.concat() ?? [];
        let update = steps[stepIndex].groups?.concat();
        update?.splice(groupIndex,1);
        steps[stepIndex] = {...steps[stepIndex], groups : update };

        setJourneyWrapper({
            ...journeyWrapper,
            journey : {
                ...journey,
                registration : {
                    ...journey?.registration,
                    formSteps : steps
                }}})
    }

    const onGroupDragEnd = (result : any, stepIndex : number) => {
        if (!result.destination || !result.source) {
            return;
        }
        if(result.destination.index === result.source.index) {
            return;
        }

        let from = result.source.index;
        let to = result.destination.index;

        let formStep = journeyWrapper?.journey?.registration?.formSteps?.[stepIndex];
        let groups = formStep?.groups;
        groups = moveItem(groups ?? [],from,to);

        setJourneyWrapper(updateStep(stepIndex, {...formStep, groups : groups}));

    }

    const onStepDragEnd = (result : any) => {
        if (!result.destination || !result.source) {
            return;
        }
        if(result.destination.index === result.source.index) {
            return;
        }

        let from = result.source.index;
        let to = result.destination.index;

        let formSteps = journeyWrapper?.journey?.registration?.formSteps;
        formSteps = moveItem(formSteps ?? [],from,to);

        setJourneyWrapper({
            ...journeyWrapper,
            journey : {
                ...journeyWrapper?.journey,
                registration : {
                    ...journeyWrapper?.journey?.registration,
                    formSteps : formSteps
                }
            }
        })

    }

    const updateStep = (stepIndex : number, step: FormStep) : UserJourneyWrapper =>{
        let formSteps = journeyWrapper?.journey?.registration?.formSteps?.concat() ?? [];
        formSteps[stepIndex] = step;
        return {
            ...journeyWrapper,
            journey : {
                ...journeyWrapper?.journey,
                registration : {
                    ...journeyWrapper?.journey?.registration,
                    formSteps : formSteps
                }
            }
        }
    }
    let deleteStep = (index : number) => {
        let formSteps = journeyWrapper?.journey?.registration?.formSteps?.concat() ?? [];
        formSteps.splice(index,1);
        setJourneyWrapper({
            ...journeyWrapper,
            journey : {
                ...journeyWrapper?.journey,
                registration : {
                    ...journeyWrapper?.journey?.registration,
                    formSteps : formSteps
                }
            }
        });
        setEditStep(-1);
    }

    const saveJourney = () => {
        if(journeyWrapper) {
            return getContainer().getUserJourneyService().save({
                ...journeyWrapper,
                classifier : journeyWrapper.classifier == "" ? undefined : journeyWrapper.classifier,
                journey : {
                    ...journeyWrapper.journey,
                    login : {},
                    deposit : {},
                    withdraw : {}
                }
            });
        }
        return Promise.reject("Error");
    }
    const addFormStep = () => {
        let formSteps = journeyWrapper?.journey?.registration?.formSteps?.concat() ?? [];
        formSteps.push({})
        setJourneyWrapper({
            ...journeyWrapper,
            journey : {
                ...journeyWrapper?.journey,
                registration : {
                    ...journeyWrapper?.journey?.registration,
                    formSteps : formSteps
                }
            }
        });
    }
    const getMissingRequiredAttributes = () =>{
        let inputs = journeyWrapper?.journey?.registration?.formSteps?.flatMap(s => s.groups).flatMap(g => g?.inputs);

        let missingAttributes = attributes.filter((a : UserAttributeDefinition) => a.required).filter((a : UserAttributeDefinition) => {
            let exist = inputs?.find(i => i?.userAttributeKey == a.attributeKey);
            return !exist;
        });
        return missingAttributes;
    }
    const togglePrincipal = (l : UserLoginPrincipal) => {
        let principals = journeyWrapper?.journey?.userLoginPrincipals ?? [];
        let existing = principals.find(p => p == l);
        if(existing) {
            principals = principals.filter(p => p != l);
        } else {
            principals = principals.concat();
            principals.push(l);
        }
        setJourneyWrapper({...journeyWrapper, journey : {...journeyWrapper?.journey, userLoginPrincipals : principals }});
    }
    const toggleExcludeRegistrationPrincipal = (l : UserLoginPrincipal) => {
        let principals = journeyWrapper?.journey?.registration?.exclude ?? [];
        let existing = principals.find(p => p == l);
        if(existing) {
            principals = principals.filter(p => p != l);
        } else {
            principals = principals.concat();
            principals.push(l);
        }
        setJourneyWrapper({...journeyWrapper, journey : {...journeyWrapper?.journey, registration : {...journeyWrapper?.journey?.registration, exclude : principals} }});
    }
    const updateRiskCollection = (value : string) => {
        let id : number | undefined = parseInt(value);
        if(value != "" + id) {
            id = undefined;
        }
        setJourneyWrapper({...journeyWrapper, journey : {...journeyWrapper?.journey, registration : {...journeyWrapper?.journey?.registration, riskCollectionId : id } }});
    }
    const hasExactlyOnePrincipal = () => {
        let principals = journeyWrapper?.journey?.registration?.formSteps?.flatMap(s => s.groups).flatMap(g => g?.inputs).filter( i => i?.type == InputType.LOGIN_PRINCIPAL) ?? [];
        return principals.length == 1;
    }
    let missingRequiredAttributes = getMissingRequiredAttributes();

    return (
        <RouterViewTemplate status={status}>

                <CardBox title={"User Journey"}>
                    {match.params.id !== "new" && (
                        <div className={"form-group"}>
                            <label>ID</label>
                            <input className={"form-control"} type={"text"} name={"classifier"} value={journeyWrapper?.id} disabled={true}/>
                        </div>
                    )}
                    <div className={"form-group"}>
                        <label>Classifier</label>
                        <input className={"form-control"} type={"text"} name={"classifier"} value={journeyWrapper?.classifier} onChange={(e)=> setJourneyWrapper({...journeyWrapper, classifier : e.target.value})}/>
                        <span className={"help-block"}>Classifier would be "SE" for Sweden. Use "default" to make it default.</span>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className={"form-group"}>
                        <input id="enabled"
                               type={"checkbox"}
                               name={"enabled"}
                               checked={journeyWrapper?.enabled}
                               onChange={(e)=> setJourneyWrapper({...journeyWrapper, enabled : !journeyWrapper?.enabled})}/>
                        &nbsp;<label htmlFor={"enabled"}>Enabled</label>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <h5>Login Principal</h5>

                    {Object.keys(UserLoginPrincipal).map(k =>{
                        let loginPrincipals = journeyWrapper?.journey?.userLoginPrincipals;
                        return (
                            <div key={k}>
                                <input type={"checkbox"} id={k} onChange={()=>togglePrincipal(k as UserLoginPrincipal)} checked={!!loginPrincipals?.find(l => l == k)} /> <label htmlFor={k}>{k}</label>
                            </div>
                        )
                    })}
                    <span className={"help-block"}>
                        With what method the user should login. At least one Login Principal is required. <br/>
                        If more than one are selected the user can choose which one to log in with.
                    </span>

                </CardBox>
                <CardBox title={"Registration"}>
                    <h5>Exclude Principal from registration</h5>

                    {Object.keys(UserLoginPrincipal).map(k =>{
                        let excludeRegistrationPrincipals = journeyWrapper?.journey?.registration?.exclude ?? [];
                        return (
                            <div key={k}>
                                <input type={"checkbox"} id={"reg"+k} onChange={()=>toggleExcludeRegistrationPrincipal(k as UserLoginPrincipal)} checked={!!excludeRegistrationPrincipals?.find(l => l == k)} /> <label htmlFor={"reg"+k}>{k}</label>
                            </div>
                        )
                    })}
                    <span className={"help-block"}>
                        You can exclude user login principals from registration. This means you can allow login for one but not registration for one.
                    </span>
                    {!hasExactlyOnePrincipal() && (
                        <div className={"alert alert-warning"}>
                            Exactly ONE input of type LOGIN_PRINCIPAL is required in your registration form.<br/>
                            That input will be either Email or Phone number depending on what you select as Login Principal above.
                        </div>
                    )}
                    {missingRequiredAttributes.length > 0 && (
                        <div className={"alert alert-danger"}>
                            This user journey is missing required <Link to={"/user-attributes"}>user attributes</Link>: { missingRequiredAttributes.map(a => a.attributeKey).join(",")}
                        </div>
                    )}
                    <div className="hr-line-dashed"></div>
                    <div className={"form-group"}>
                        <label>Registration Risk Rules</label>
                        <select className={"form-control"} value={journeyWrapper?.journey?.registration?.riskCollectionId} onChange={(e) => updateRiskCollection(e.target.value)}>
                            <option>None</option>
                            {riskCollections.map(r => <option key={r.id} value={r.id}>{r.name}</option>)}
                        </select>
                        <span className={"help-block"}>
                            Select which risk handling you want on registration, read more at <Link to={"/user-risk-rules"}>User Registration Rules</Link>
                        </span>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className={"form-steps"}>
                        <DragParent onDragEnd={onStepDragEnd} id={"stepsDrop"}>
                        {registration?.formSteps?.map((s,stepIndex) => (
                            <Draggable key={stepIndex} draggableId={"step"+stepIndex} index={stepIndex}>
                                {(provided,snapshot)=> (
                                    <div ref={provided.innerRef}  {...provided.draggableProps}>
                                        <div key={stepIndex} className={"form-step " + (snapshot.isDragging ? "dragging" : "")}>
                                            <h4>
                                                <i className={"fa fa-bars"} {...provided.dragHandleProps}></i>
                                                &nbsp;Form Step {stepIndex + 1}
                                                &nbsp;<i className={"fa fa-cog"} onClick={()=> editStep == -1 ? setEditStep(stepIndex) : setEditStep(-1)}></i>
                                            </h4>
                                            {(editStep == stepIndex) && (
                                                <div>
                                                    <a className={"btn btn-secondary"} onClick={()=>setEditStep(-1)}>Cancel</a>
                                                    <a className={"btn btn-danger"} onClick={()=>deleteStep(stepIndex)}>Delete Step</a>
                                                </div>
                                            )}
                                            {(editStep != stepIndex) && (
                                                <div className={"form-groups-container"}>
                                                    <DragParent onDragEnd={(res)=> onGroupDragEnd(res, stepIndex)} id={"groupsDrop"}>
                                                        {s.groups?.map((g,groupIndex) => (
                                                            <Draggable key={groupIndex} draggableId={"group"+groupIndex} index={groupIndex}>
                                                                {(provided,snapshot)=> (
                                                                    <div className={"group-container"} ref={provided.innerRef}  {...provided.draggableProps}>
                                                                        <h5>
                                                                            <i className={"fa fa-bars"} {...provided.dragHandleProps}></i>
                                                                        </h5>
                                                                        <Group minimized={snapshot.isDragging}
                                                                               key={groupIndex} group={g}
                                                                               getAttribute={getAttribute}
                                                                               attributes={attributes}
                                                                               removeGroup={()=> removeGroup(stepIndex, groupIndex)}
                                                                               onGroupChanged={(g) => updateGroup(stepIndex, groupIndex, g)}/>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                    </DragParent>
                                                    <button className={"user-input button"} onClick={()=>addGroup(stepIndex)}>
                                                        <i className={"fa fa-plus-circle"}></i> Add Group
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        </DragParent>
                    </div>
                    <button className={"btn btn-primary"} onClick={()=>addFormStep()}>Add Form Step</button>
                </CardBox>
                <DeleteButtonModal className={"btn btn-primary"} label={"Save User Journey"} description={""} onDelete={saveJourney}/>

        </RouterViewTemplate>
    )
}
const Group = (props : {
        group : UserInputGroup,
        minimized : boolean,
        getAttribute : (id : string) => string | undefined ,
        onGroupChanged : (g : UserInputGroup) => void,
        removeGroup : () => void,
        attributes : UserAttributeDefinition[]}) => {

    const g = props.group;
    const [editInput, setEditInput] = useState(-1);
    const [addInput, setAddInput] = useState(false);
    const [editGroup, setEditGroup] = useState(false);
    const onDragEnd = (result : any) => {
        if (!result.destination || !result.source) {
            return;
        }
        if(result.destination.index === result.source.index) {
            return;
        }

        let from = result.source.index;
        let to = result.destination.index;
        console.log("move " + from + " to " + to)

        let inputs = moveItem(props.group.inputs ?? [],from,to);
        props.onGroupChanged({...props.group, inputs : inputs});
    }
    const save = (i : UserInput, index : number) => {
        if (addInput) {
            let inputs = props.group.inputs?.concat() ?? [];
            inputs.push(i);
            props.onGroupChanged({...props.group, inputs: inputs});
            setAddInput(false);
        } else if (editInput != -1) {
            let inputs = props.group.inputs?.concat() ?? [];
            inputs[index] = i;
            props.onGroupChanged({...props.group, inputs: inputs});
            setEditInput(-1);
        }

    }
    const removeInput = (index : number) => {
        let inputs = props.group.inputs?.concat() ?? [];
        inputs.splice(index, 1)
        props.onGroupChanged({...props.group, inputs: inputs});
        setEditInput(-1);
    }
    const cancel = () => {
        if(addInput) {
            setAddInput(false);
        } else {
            setEditInput(-1);

        }
    }
    const saveGroup = (group : UserInputGroup) => {
        props.onGroupChanged({...props.group, name : group.name, translationKey : group.translationKey});
        setEditGroup(false);
    }
    const removeGroup = () =>{
        props.removeGroup();
        setEditGroup(false);
    }
    return (
        <div className={"group " + (props.minimized ? "minimized" : "")}>
            <h5>{g.name ? g.name : "Untitled"} <i className={"fa fa-cog"} onClick={()=>setEditGroup(!editGroup)}></i></h5>
            {editGroup && <EditGroup group={g} cancel={()=>setEditGroup(false)} save={saveGroup} removeGroup={()=> removeGroup()}/>}
            {!editGroup && (
                <div className={"input-container"}>
                    <DragParent onDragEnd={onDragEnd} id={"tabsDrop"}>
                        {g.inputs?.map((i,index)=>(
                            <Draggable key={index} draggableId={"input"+index} index={index}>
                                {(provided,snapshot)=> (
                                    <div ref={provided.innerRef} {...provided.draggableProps}>

                                        <div className={"user-input"} >
                                            <i className={"fa fa-bars"} {...provided.dragHandleProps}></i>&nbsp;
                                            {i.field && (i.field)}
                                            {i.userAttributeKey && (props.getAttribute(i.userAttributeKey) ?? i.userAttributeKey)}
                                            {i.userAttributeKey && !(props.getAttribute(i.userAttributeKey))  && (
                                                <span style={{color:"#f00000"}}> <i className={"fa fa-exclamation-circle"}></i> Attribute Missing</span>
                                            )}
                                            {editInput !== index && (
                                              <i className={"fa fa-cog pull-right"} onClick={()=>setEditInput(index)}></i>
                                            )}
                                        </div>
                                        {editInput === index && (
                                            <>
                                                <div {...provided.dragHandleProps}>
                                                </div>
                                                <EditUserInput remove={()=> removeInput(index)} attributes={props.attributes} input={i} cancel={cancel} save={(i)=> save(i,index)}/>
                                            </>
                                        )}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                    </DragParent>
                    {!addInput && (
                        <button className={"user-input button"} onClick={()=>setAddInput(true)}>
                            <i className={"fa fa-plus-circle"}></i> Add Input
                        </button>
                    )}
                    {addInput && (
                        <EditUserInput  attributes={props.attributes} input={{}} cancel={cancel} save={(i)=> save(i,-1)}/>
                    )}
                </div>
            )}
        </div>
    )
}

const EditGroup = (props : { group : UserInputGroup, save : (group : UserInputGroup) => void, cancel : () => void, removeGroup : () => void}) => {
    const [form , listener, setFormState] = useForm<UserInputGroup>(props.group, () => {
        props.save(form.model);
    });
    return (
        <div className={"group-edit-form"}>
            <Form modelObject={form.model} formListener={listener} validationMap={form.validationMap}>
                <div className={"actions"}>
                    <a className={"btn btn-danger"} style={{float : "right"}} onClick={()=>props.removeGroup()}>Remove Group</a>
                </div>
                <div className="hr-line-dashed"></div>
                <FormGroup model={"name"} className={"form-group"}>
                    <label>Name</label>
                    <FormInput className={"form-control"} model={"name"} required={true}/>
                    <span className={"help-block"}>Internal name, not displayed to players</span>
                </FormGroup>
                <div className="hr-line-dashed"></div>
                <FormGroup model={"translationKey"} className={"form-group"}>
                    <label>Translation Key</label>
                    <FormInput  className={"form-control"} model={"translationKey"} />
                    <span className={"help-block"}>Translation key used for title, leave empty if you don't want a title</span>
                </FormGroup>
                <div className="hr-line-dashed"></div>
                <div className={"actions"}>
                    <button className={"btn btn-secondary"} onClick={props.cancel}>Cancel</button>
                    <button className={"btn btn-primary"} type="submit">Save</button>
                </div>
            </Form>
        </div>
    )
}


const EditUserInput = (props : { input : UserInput, save : (i : UserInput) => void, remove? : () => void, cancel : () => void, attributes : UserAttributeDefinition[]}) => {
    const [form , listener, setFormState] = useForm<UserInput>(props.input, () => {
        let model = form.model;
        console.log("form model = ", model);

        if(model.type == InputType.LOGIN_PRINCIPAL) {
            model = {...model, field : UserPrimaryField.loginPrincipal, userAttributeKey : undefined, required : true}
        } else if(model.type == InputType.ACCEPT_TERMS || model.type == InputType.AUTO_ACCEPT_TERMS) {
            model = {...model, field : UserPrimaryField.termsAccepted};
        } else if(model.type == InputType.PROMO_CODE) {
            model = {...model, field : undefined};
        } else if(model.field) {
            model = {...model, userAttributeKey : undefined};
        } else if(model.userAttributeKey) {
            model = {...model, field : undefined};
        }
        props.save(model);
    });

    const isPromoCode = form.model.type == InputType.PROMO_CODE;

    const [fieldType, setFieldType] = useState<"attribute"|"field"|undefined>(!!form.model.userAttributeKey ? "attribute" : "field");

    useEffect(() => {
        if(isPromoCode) {
            setFieldType("attribute");
            setFormState({model: {
                    ...form.model,
                    userAttributeKey: "promoCode"
                },
                validationMap: form.validationMap
            });
        }
    }, [isPromoCode])

    let termsType = form.model.type == InputType.AUTO_ACCEPT_TERMS || form.model.type == InputType.ACCEPT_TERMS;

    const updateFieldType= (e : "field" | "attribute") => {
        let model = {...form.model, field : e == "field" ? form.model.field : undefined, userAttributeKey : e == "attribute" ? form.model.userAttributeKey : undefined};
        setFormState({ model : model, validationMap : form.validationMap})
        setFieldType(e);
    }
    let loginPrincipal = form.model.type == InputType.LOGIN_PRINCIPAL;

    return (
        <div className={"edit-input-form"}>
            <Form validationMap={form.validationMap} modelObject={form.model} formListener={listener}>
                {props.remove && (
                    <>
                        <div className={"actions"}>
                            <a className={"btn btn-danger"} style={{float : "right"}} onClick={()=>props.remove?.()}>Remove Input</a>
                        </div>
                        <div className="hr-line-dashed"></div>
                    </>
                )}
               <FormGroup className={"form-group"} model={"type"}>
                   <label>Input Type</label>
                   <SelectInput className={"form-control"} model={"type"} values={Object.keys(InputType)}/>
               </FormGroup>

                <div className="hr-line-dashed"></div>
                {!loginPrincipal && !termsType && !isPromoCode && (
                    <>
                        <div className="hr-line-dashed"></div>
                        <FormGroup className={"form-group"} model={"userAttributeKey"}>
                            <label>Field or Attribute</label>
                            <div >
                                <input onChange={(e) => updateFieldType("field")} type="radio" value="field" name="fieldType" checked={fieldType=="field"}/> Field (email, phone etc)
                                <input onChange={(e) => updateFieldType("attribute")} type="radio" value="attribute" name="fieldType" checked={fieldType=="attribute"} /> Attribute
                            </div>
                        </FormGroup>
                        {fieldType == "field" && (
                            <>
                                <FormGroup className={"form-group"} model={"field"}>
                                    <label>Field</label>
                                    <SelectInput className={"form-control"} model={"field"} values={Object.keys(UserPrimaryField)}/>
                                </FormGroup>
                                <div className="hr-line-dashed"></div>
                            </>
                        )}
                        {fieldType == "attribute" && (
                            <>
                                <FormGroup className={"form-group"} model={"userAttributeKey"}>
                                    <label>Attribute</label>
                                    <SelectInput
                                        className={"form-control"}
                                        model={"userAttributeKey"}
                                        optional={false}
                                        values={props.attributes}
                                        displayValue={(a : any) => a.attributeKey}
                                        optionModelValue={(a : any) => a.attributeKey}
                                        disabled={isPromoCode}
                                    />
                                </FormGroup>
                                <div className="hr-line-dashed"></div>
                            </>
                        )}
                        <FormGroup model={"required"}>
                            <FormInput type={"checkbox"} id={"required"} model={"required"}/>  <label htmlFor={"required"}>Required</label>
                        </FormGroup>
                        <div className="hr-line-dashed"></div>
                    </>
                )}
                {isPromoCode && (
                    <>
                        {fieldType == "attribute" && (
                            <>
                                <FormGroup className={"form-group"} model={"userAttributeKey"}>
                                    <label>Attribute</label>
                                    <SelectInput
                                        className={"form-control"}
                                        model={"userAttributeKey"}
                                        optional={false}
                                        values={props.attributes}
                                        displayValue={(a : any) => a.attributeKey}
                                        optionModelValue={(a : any) => a.attributeKey}
                                        disabled={isPromoCode}
                                    />
                                </FormGroup>
                                <div className="hr-line-dashed"></div>
                            </>
                        )}
                        <FormGroup model={"required"}>
                            <FormInput type={"checkbox"} id={"required"} model={"required"}/>  <label htmlFor={"required"}>Required</label>
                        </FormGroup>
                    </>
                )}
                {form.model.type == InputType.PASSWORD && (
                    <>
                        <FormGroup model={"hidden"}>
                            <FormInput type={"checkbox"}  model={"hidden"} id={"hidden"}/>  <label htmlFor={"hidden"}>Hidden</label>
                        </FormGroup>
                        <div className="hr-line-dashed"></div>
                        <FormGroup model={"repeatInput"}>
                            <FormInput type={"checkbox"}  model={"repeatInput"} id={"repeatInput"}/>  <label htmlFor={"repeatInput"}>Repeat Input</label>
                        </FormGroup>
                        <div className="hr-line-dashed"></div>
                    </>
                )}
                {form.model.type == InputType.DATE_OF_BIRTH && (
                   <>
                       <FormGroup model={"minAge"}>
                           <label htmlFor={"repeatInput"}>Min Age</label>
                           <FormInput type={"integer"}  model={"minAge"} id={"minAge"} className={"form-control"}/>
                       </FormGroup>
                       <div className="hr-line-dashed"></div>
                   </>
                )}
               <div className={"actions"}>
                   <button className={"btn btn-secondary"} onClick={props.cancel}>Cancel</button>
                   <button className={"btn btn-primary"} type="submit">Save</button>
               </div>
            </Form>
        </div>
    )
}