import {Form, useForm} from "../form/Form";
import {CardBox, RouterViewTemplate, useLoadingState} from "../RouterView";
import {getContainer} from "../../ioc/IOCSetup";
import * as React from "react";
import {useState} from "react";
import {MaintenanceCategory, MaintenanceWindow} from "../../http/protocol";
import {FormGroup} from "react-bootstrap";
import {FormInput} from "../form/FormInput";
import {FormDateTimeInput} from "../form/FormDateTimeInput";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {Submit} from "../form/Submit";
import {SelectInput} from "../form/SelectInput";
import {useHistory, useRouteMatch} from "react-router-dom";

export const toCategoryName = (c? : MaintenanceCategory) => {
    if(c == MaintenanceCategory.ALL) {
        return "WEBSITE"
    } else if(typeof c != "undefined") {
        return ""+c;
    }
    return "New";
}
export const MaintenanceView = () => {
    let match = useRouteMatch<{ id : string }>();

    const [maintenanceWindow, setMaintenanceWindow] = useState<MaintenanceWindow>({});
    const status = useLoadingState(()=>{
        if(match.params?.id == "new") {
            return Promise.resolve();
        }
        return getContainer().getMaintenanceService().getById(match.params.id).then((mw) => {
            setMaintenanceWindow(mw);
        })

    },[]);

    return (
        <RouterViewTemplate status={status}>
            <CardBox title={(toCategoryName(maintenanceWindow.category) ?? "New") + " Maintenance"} key={maintenanceWindow.id ?? "new"}>
                <MaintenanceWindowForm mw={maintenanceWindow}/>
            </CardBox>
        </RouterViewTemplate>
    )
}

const after = (a? : Date | number, b? : Date | number) => {
    let aa = typeof(a) == "number" ? (a ?? 0) : (a?.getTime() ?? 0);
    let bb = typeof(b) == "number" ? (b ?? 0) : (b?.getTime() ?? 0);
    return aa > bb;
}
const MaintenanceWindowForm = (props : { mw : MaintenanceWindow}) => {
    let history = useHistory();
    const [form , listener, setFormState] = useForm<MaintenanceWindow>(props.mw, () => {
        if(after(form.model.announceBeforeStart, form.model.start)) {
            return Promise.reject("Announce time should be before start time")
        }
        return getContainer().getMaintenanceService().save(form.model).then(()=> history.push("/maintenance"))
    });

    return (
        <Form className={"form"} formListener={listener} modelObject={form.model} validationMap={form.validationMap}>
            <div className="hr-line-dashed"></div>
            <FormGroup>
                <label>Enabled <FormInput type={"checkbox"} model={"enabled"}/></label>
            </FormGroup>
            <div className="hr-line-dashed"></div>
            <FormGroup>
                <label>Maintenance for Product/Site</label>
                <SelectInput  className={"form-control"} displayValue={(c) => toCategoryName(c)} values={Object.keys(MaintenanceCategory)} model={"category"}  required={true}/>
                <span className={"help-block"}>What part of the site is maintenance scheduled on</span>
            </FormGroup>
            <div className="hr-line-dashed"></div>
            <FormGroup>
                <label>Announce</label>
                <FormDateTimeInput model={"announceBeforeStart"} dateFormat={"yyyy-MM-DD"} timeFormat={"HH:mm"} />
                <span className={"help-block"}>What time to announce the maintenance to the players</span>
            </FormGroup>
            <FormGroup>
                <label>Start</label>
                <FormDateTimeInput model={"start"}  dateFormat={"yyyy-MM-DD"} timeFormat={"HH:mm"}/>
                <span className={"help-block"}>When the maintenance starts</span>
            </FormGroup>

            <FormSubmitError/>
            <FormSubmitSuccess text={"Maintenance saved"}/>
            <Submit className={"btn btn-primary"}>Save</Submit>

        </Form>
    )
}