import { Currency } from "../http/protocol";
import { ReduxAction } from "../user/UserStore";
import { CURRENCIES } from "./WalletActions";

export interface WalletStoreState {
    currencies?: Currency[]
}

export function walletReducer(state : WalletStoreState = {}, action : ReduxAction<any>) {
    switch (action.type) {
        case CURRENCIES:
            return {...state, currencies: action.payload}
        default:
            return state;
    }
}


