import React from "react";
import {
    GameIntegration,
    LobbyUpdateRequest,
    PermissionCategory
} from "../../http/protocol";
import {getContainer} from "../../ioc/IOCSetup";
import {RouteComponentProps, withRouter} from "react-router";
import {Form, FormListener} from "../form/Form";
import { CasinoLobbyService } from "../../services/CasinoLobbyService";
import {LoadingViewState, RouterView} from "../RouterView";
import { PermissionRequired } from "../security/PermissionRequired";
import { Alert } from "../form/Alert";
import {SelectInput} from "../form/SelectInput";

interface State extends LoadingViewState {
    message?: string;
    gameIntegrations?: Set<GameIntegration>;
    form?: LobbyUpdateRequest;
    validationMap?: any;
    expandSelect?: boolean;
    selectedImageIntegration?: GameIntegration;
    selectedImageStudio?: string; 

    studios?: string[];

    expandImageSelect?: boolean;
}

export class CasinoLobbyView extends RouterView<RouteComponentProps, State> implements FormListener<LobbyUpdateRequest> {

    service : CasinoLobbyService;

    constructor(props : any){
        super(props)
        this.state = {expandSelect: false, expandImageSelect: false, gameIntegrations: new Set<GameIntegration>(), validationMap: {}}
        this.service = getContainer().getCasinoLobbyService();
    }

    async loadContent(): Promise<any> {
        let gameIntegrations : Set<GameIntegration> = await this.service.getEnabledIntegrations();
        this.setState({gameIntegrations: gameIntegrations})
    }

    refreshCache() {
        this.service.refreshLobbyCache()
        this.setState({message: "Lobby caches is being invalidated in the background. It will take a few seconds for the changes to be visible."})
    }

    refreshLobby() {
        let selectedIntegrationsCount = this.state.form?.gameIntegrations?.length || 0;
        let countDependantMessage = selectedIntegrationsCount == 0 ? 'all' : selectedIntegrationsCount;

        this.service.lobbyRefresh(this.state.form?.gameIntegrations).then(r => console.log('lobbyRefresh', r))
        this.setState({
            form: undefined,
            message: `An update of lobby games for ${countDependantMessage} providers has been triggered in the background. It will take a few minutes for the changes to be visible.`})
    }

    refreshCentralImages() {
        let integration = this.state.selectedImageIntegration as string
        let studio = this.state.selectedImageStudio as string

        if (integration == undefined  ||  integration == 'ALL') {
            integration = ''
        }

        if (studio == undefined  ||  studio == 'ALL') {
            studio = ''
        }

        this.service.refreshCentralImages(integration, studio);
        this.setState({message: "Nano Central images will be checked for the selected Integration & Studio (if applied)"})
    }

    getTitle() {
        return "Casino Lobby Management";
    }

    onSubmit(): void {
    }

    formDidUpdate(form: LobbyUpdateRequest, validationMap?: any): void {
        this.setState({form: form, validationMap: validationMap});
    }

    renderContent() {
        let selectionCount = this.state.form?.gameIntegrations?.length || 0;
        let buttonMessage = selectionCount == 0 ? 'Refresh Lobby Games (all)' : `Refresh Lobby Games (${selectionCount})`

        console.log("sel image int: %o, form: %o", this.state.selectedImageIntegration, this.state.form)

        return (
            <div className="wrapper wrapper-content animated fadeInRight">
                <PermissionRequired permissions={[PermissionCategory.CASINO]} accessLevel="w">

                    <div className="row">
                        <div className="col-lg-12">

                            { this.state.message  &&
                                <Alert type="success" text={this.state.message}/>
                            }

                            <div className="card-box">
                                <h5>Lobby Cache</h5>
                                <p>Refreshing the lobby cache happens in the background and will continue to serve the old content until finished.</p>
                                <div className="ibox-content">
                                    <button className="btn btn-primary" onClick={() => this.refreshCache()}>Refresh Lobby Cache</button>
                                </div>
                            </div>

                            <div className="card-box">
                                <h5>Lobby Games Update</h5>
                                <p className={'mb-0'}>Trigger a lobby games update for one or more providers. Normally this happens for all providers automatically hourly.</p>
                                <p>Refreshing lobby games happens in the background and will continue to serve the old content until finished.</p>
                                <div className="ibox-content">
                                    {this.getUpdateGamesByIntegration()}
                                    <button className="btn btn-primary mt-2" onClick={() => this.refreshLobby()}>{buttonMessage}</button>
                                </div>
                            </div>

                            <div className="card-box">
                                <h5>Nano Central Images Update</h5>
                                <p>Trigger check & update of Nano Central images for the selected Integration & Studio (if applied).</p>
                                <div className="ibox-content">
                                    {this.getUpdateImagesByProviderAndStudio()}
                                    <button className="btn btn-primary mt-2" onClick={() => this.refreshCentralImages()}>Refresh Nano Central Images</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </PermissionRequired>
            </div>
        );
    }

    getUpdateGamesByIntegration() {
        let labelText = this.state.expandSelect ? "Hide" : "Update by Provider"
        let feSymbol = this.state.expandSelect ? "fe-chevron-up" : "fe-chevron-down"
        let listItems = [...Object.values(this.state.gameIntegrations ?? [])]
        listItems.sort()

        return (
            <div style={{cursor: "pointer"}}
                 onClick={() => !this.state.expandSelect ? this.setState({expandSelect: true}) : null}>
                <h4 className="header-title"
                    onClick={() => this.setState({expandSelect: !this.state.expandSelect, form: {}})}>
                    <i className={feSymbol}></i> {labelText}
                </h4>

                {this.state.expandSelect && (
                    <div>
                        <div className="mt-1">
                            <Form className="form-horizontal"
                                  formListener={this}
                                  modelObject={this.state.form}
                                  validationMap={this.state.validationMap}
                            >
                                <SelectInput
                                    className="form-control mb-1"
                                    model="gameIntegrations"
                                    size={listItems.length}
                                    multiple={true}
                                    values={listItems}
                                    optional={true}
                                    placeholder="Select Game Integrations"
                                >
                                </SelectInput>
                                <span className={'help-text'}>CTRL select for multiple statuses</span>
                            </Form>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    async onImageIntegrationChange(integration: GameIntegration) {
        this.setState({selectedImageIntegration: integration, selectedImageStudio: undefined})

        console.log("select integration: ", integration)

        if (integration != 'ALL') {
            const studios = await this.service.getStudioNamesByIntegration(integration)
            studios.sort()
            this.setState({studios: studios})
        } else {
            this.setState({studios: undefined})
        }
    }


    getUpdateImagesByProviderAndStudio() {
        let labelText = this.state.expandImageSelect ? "Hide" : "Update by Provider"
        let feSymbol = this.state.expandImageSelect ? "fe-chevron-up" : "fe-chevron-down"

        const intArr = Array.from(this.state.gameIntegrations ?? [])
        intArr.sort()
        const intOpts = intArr.map(gi => {
            return <option key={gi} value={gi}>{gi}</option>
        });

        const studioArr = Array.from(this.state.studios ?? [])
        studioArr.sort()
        const studioOpts = studioArr.map(gs => {
            return <option key={`s-${gs}`} value={gs}>{gs}</option>
        });

        return (
            <div style={{cursor: "pointer"}}
                 onClick={() => !this.state.expandImageSelect ? this.setState({expandImageSelect: true}) : null}>
                <h4 className="header-title"
                    onClick={() => this.setState({expandImageSelect: !this.state.expandImageSelect, selectedImageIntegration: undefined})}>
                    <i className={feSymbol}></i> {labelText}
                </h4>

                {this.state.expandImageSelect && (
                    <div>
                        <div className="mt-1">
                            <select className="form-control mb-1" multiple={false} onChange={e => {console.log(e.target); this.onImageIntegrationChange(e.target.value as GameIntegration)}}>
                                <option value={'ALL'}>All Integrations</option>
                                {intOpts}
                            </select>

                            { this.state.selectedImageIntegration  &&
                                <select className="form-control mb-1" multiple={false} onChange={e => this.setState({selectedImageStudio: e.target.value})}>
                                    <option value={'ALL'}>All Studios</option>
                                    {studioOpts}
                                </select>
                            }  
                        </div>
                    </div>
                )}
            </div>
        );
    }

}


export default withRouter(CasinoLobbyView);
