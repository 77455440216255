import * as React from "react";
import {Link} from "react-router-dom";

export interface Props {
    header:string;
    mainTitle:string;
    mainValue:any;
    type:string;
    navTo?:string;
    listRequest?: any;
    onClick?: (e?:any) => void;
}

export class MetricsSingleValuePanel extends React.Component<Props, {}> {

    //Move to its own component when finished.
    renderIcon(type:string) {
        if(type === "ERROR") {
            return(
                <div className="avatar-lg rounded-circle border bg-white">
                    <i className="fe-x font-22 avatar-title text-danger"></i>
                </div>
            )
        } else if(type === "WARNING"){
            return(
                <div className="avatar-lg rounded-circle border bg-white" >
                    <i className="fe-alert-triangle font-22 avatar-title text-warning"></i>
                </div>
            );
        } else if(type === "OK"){
            return(
                <div className="avatar-lg rounded-circle border bg-white" >
                    <i className="fe-thumbs-up font-22 avatar-title text-success"></i>
                </div>
            );
        }
    }

    getStatusColor(type:string) {
        if(type==="ERROR") {
            return "bg-danger";
        } else if(type==="WARNING") {
            return "bg-warning";
        } else if(type==="OK") {
            return "bg-success";
        } else {
            return "bg-blue";
        }
    }


    render() {
        if(this.props.navTo && this.props.listRequest) {
            return(
                <div className="col-md-6 col-xl-3">
                    <Link to={{
                        pathname: this.props.navTo,
                        state: {
                            listRequest: this.props.listRequest,
                            lookupExpanded: true
                        }
                    }} >
                        <div className={`widget-rounded-circle card-box ${this.getStatusColor(this.props.type)}`}>
                            <div className="row align-items-center">
                                <div className="col-4">
                                    <div className="avatar-lg">
                                        {this.renderIcon(this.props.type)}
                                    </div>
                                </div>
                                <div className="col-8">
                                    <h3 className="mb-1 mt-2 text-white text-right">{this.props.mainValue}</h3>
                                    <p className="mb-2 text-white text-right">{this.props.mainTitle}</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            )
        } else if(this.props.onClick) {
            return(
                <div className="col-md-6 col-xl-3">
                    <a onClick={this.props.onClick}>
                        <div className={`widget-rounded-circle card-box ${this.getStatusColor(this.props.type)}`}>
                            <div className="row align-items-center">
                                <div className="col-4">
                                    <div className="avatar-lg">
                                        {this.renderIcon(this.props.type)}
                                    </div>
                                </div>
                                <div className="col-8">
                                    <h3 className="mb-1 mt-2 text-white text-right">{this.props.mainValue}</h3>
                                    <p className="mb-2 text-white text-right">{this.props.mainTitle}</p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            )
        }
        else {
            return(
                <div className="col-md-6 col-xl-3">
                    <div className={`widget-rounded-circle card-box ${this.getStatusColor(this.props.type)}`}>
                        <div className="row align-items-center">
                            <div className="col-4">
                                <div className="avatar-lg">
                                    {this.renderIcon(this.props.type)}
                                </div>
                            </div>
                            <div className="col-8">
                                <h3 className="mb-1 mt-2 text-white text-right">{this.props.mainValue}</h3>
                                <p className="mb-2 text-white text-right">{this.props.mainTitle}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}