import {CardBox, RouterViewTemplate, useLoadingState} from "../RouterView";
import {getContainer} from "../../ioc/IOCSetup";
import * as React from "react";
import {useState} from "react";
import {Settings, Site} from "../../http/protocol";
import {useHistory, useRouteMatch} from "react-router-dom";
import {Form, useForm} from "../form/Form";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Validators} from "../form/Validators";
import {Submit} from "../form/Submit";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {DeleteButtonModal} from "../../util/DeleteButtonModal";
import {SelectInput} from "../form/SelectInput";


export const EditSite = () => {
    const history = useHistory();
    const match = useRouteMatch<{id : string}>();
    const service = getContainer().getAdminSiteService();
    const settingsService = getContainer().getSettingsService();

    const [settings, setSettings] = useState<Settings[]>();
    let  [form, formListener, setForm] = useForm<Site>(
        {},
        () => {
            return service.save(form.model).then(r => {
                setForm({ model : r, validationMap : form.validationMap})
                history.replace("/admin/site/" + r.id);
            });
        },
        ()=> {
            console.log("Error saving site");
        });
    const status = useLoadingState(()=>{
        if(match.params.id == "new") {
            return settingsService.list({ limit: 100, offset : 0, orderBy : "id", ascending : true }).then( res => {
                setSettings((res as any).items)
            })

        } else {
            return service.getEntity(match.params.id)
                .then(e => setForm({model : e, validationMap : {}}))
                .then(() => {
                    return settingsService.list({ limit: 100, offset : 0, orderBy : "id", ascending : true }).then( res => {
                        setSettings((res as any).items)
                    });
                });
        }
    },[]);


    const updateHost = (index : number, val : string) => {
        let hosts = (form.model.hosts?? []).concat();
        hosts?.splice(index,1,val);
        setForm({ model : {...form.model, hosts : hosts}, validationMap : form.validationMap });
    }
    const deleteHost = (index : number) => {
        let hosts = (form.model.hosts?? []).concat();
        hosts?.splice(index,1);
        setForm({ model : {...form.model, hosts : hosts}, validationMap : form.validationMap });
    }
    const addHost = () => {
        let hosts = (form.model.hosts?? []).concat();
        hosts.push("");
        setForm({ model : {...form.model, hosts : hosts}, validationMap : form.validationMap });
    }

    return (
        <RouterViewTemplate status={status} title={"Edit/Create Sites"}>
            {form.model.id && (
                <CardBox title={"Actions"}>
                    <DeleteButtonModal onDelete={() => service.remove("" + form.model.id).then(()=>{
                        history.push("/admin/sites");
                    })} className={"btn btn-danger"} label={"Delete Site"}/>
                </CardBox>
            )}

            <Form formListener={formListener} modelObject={form.model} validationMap={form.validationMap}>
            <CardBox title={"Info"}>
                    {form.model.id && (
                        <FormGroup model={"id"}>
                            <label>Id</label>
                            <FormInput className={"form-control"} disabled={true} model={"id"} />
                        </FormGroup>
                    )}
                    <div className="hr-line-dashed"></div>
                    <FormGroup model={"name"}>
                        <label>Name</label>
                        <FormInput className={"form-control"}  model={"name"} validators={[Validators.required()]} />
                    </FormGroup>
                    <div className="hr-line-dashed"></div>
                <FormGroup model={"nameId"}>
                    <label>Name ID</label>
                    <FormInput className={"form-control"} model={"nameId"} validators={[Validators.required()]}/>
                    <span className={"help-block"}>Unique id that will be stored on the users and used as a postfix for email/sms templates.
                        If nameId is test it will look for email templates ending with '_test' (example: verify_email_test). Use value 'default'
                        for the main site, then postfix will not be used for email/sms templates etc.</span>
                </FormGroup>
                <div className="hr-line-dashed"></div>
                    <FormGroup model={"enabled"}>
                        <FormInput type={"checkbox"}  model={"enabled"} />
                        <label>&nbsp;Enabled</label>
                    </FormGroup>
                    <div className="hr-line-dashed"></div>

                    <div className="hr-line-dashed"></div>
                    <FormGroup model={"casinoExitUrl"}>
                        <label>Casino Exit Url</label>
                        <FormInput className={"form-control"}  model={"casinoExitUrl"} />
                    </FormGroup>

                    <div className="hr-line-dashed"></div>
                    <FormGroup model={"sportsbookExitUrl"}>
                        <label>Sportsbook Exit Url</label>
                        <FormInput className={"form-control"}  model={"sportsbookExitUrl"} />
                    </FormGroup>

            </CardBox>
            <CardBox title={"Hosts"}>
                {form.model.hosts?.map((h,i) => (
                    <div className={"row"} style={{marginBottom: "4px"}} key={i}>
                        <input type={"text"} placeholder={"Host (eg www.test.com, test.com)"}
                               className={"form-control col-10"} value={h ?? ""}
                               onChange={(e) => updateHost(i, e.target.value)}/>
                        <a className={"btn btn-danger"} onClick={() => deleteHost(i)}><i className={"fa fa-minus"}></i></a>
                        <span className={"help-block"}>Host (eg www.test.com, test.com)</span>
                    </div>
                ))}
                <a className={"btn btn-secondary"} onClick={addHost}><i className={"fa fa-plus"}></i></a>
            </CardBox>
                <CardBox title={"Use Settings"}>
                    <FormGroup>
                        <label>Settings: </label>
                        {settings && (
                            <SelectInput className={"form-control"} values={settings ?? []} model={"settingsId"} displayValue={(modelValue) => modelValue.id  + " - " + (modelValue.operatorName ?? modelValue.title ?? "N/A")} optionModelValue={(s) => s.id}/>)}
                </FormGroup>
            </CardBox>
            <CardBox title={"Actions"}>
                <FormSubmitSuccess text={"Save successful!"}/>
                <FormSubmitError />
                <Submit className={"btn btn-primary"}>Save Site</Submit>
            </CardBox>
        </Form>
        </RouterViewTemplate>
    )
}
