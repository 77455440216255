import * as React from "react";
import {Fragment} from "react";

import {RouteComponentProps, withRouter} from "react-router";
import {LoadingViewState, RouterView} from "../RouterView";
import {
    BonusConfig,
    BonusStatus,
    CurrencyDefinition,
    MonetaryConfig,
    BonusTrigger,
    Settings,
    TurnoverContributions, CasinoGame, CasinoGameTranslation, BonusLogicType, AwardType, BonusPaymentType, UserBonusView
} from "../../http/protocol";
import {Form, FormListener} from "../form/Form";
import {getContainer} from "../../ioc/IOCSetup";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {Submit} from "../form/Submit";
import {Validators} from "../form/Validators";
import {TurnoverContributionsService} from "../../services/TurnoverContributionsService";
import {MonetaryConfigService} from "../../services/MonetaryConfigService";
import {SettingsService} from "../../services/SettingsService";
import {BonusConfigService} from "../../services/BonusConfigService";
import {OfferRuleService} from "../../services/OfferRuleService";
import {SelectInput} from "../form/SelectInput";
import {FormTextArea} from "../form/FormTextArea";
import {BindModel, FormList, ModelBinding} from "../form/FormList";
import { UserBonusService } from "../../services/UserBonusService";
import { formatDate } from "../../util/date";
import { Link } from "react-router-dom";

interface State extends LoadingViewState {
    bonus?: UserBonusView
}

export class BonusView extends RouterView<RouteComponentProps,State> {

    bonusService: UserBonusService;

    constructor(props : RouteComponentProps){
        super(props);
        this.bonusService = getContainer().getUserBonusService();

        this.state = { };
    }

    getTitle(): string {
        return "Bonus Details";
    }

    componentDidUpdate(prevProps : RouteComponentProps, prevState : State) {
    }

    async loadContent() {
        const bonusId = (this.props.match.params as any).id
        const bonus = await this.bonusService.getEntity(bonusId)
        this.setState({ bonus: bonus })
    }

    async tryComplete() {
        this.setState({loading: true})
        const bonus = await this.bonusService.tryEnding(this.state.bonus!.id!)
        this.setState({ bonus: bonus, loading: false })
    }

    renderContent() {
        if(!this.state.bonus) {
            return <span></span>
        }

        const bonus = this.state.bonus;
        const config = bonus.bonusConfig!;
        const trigger = config.trigger!;
        const monc = config.monetaryConfig!;

        const ctrs = config.turnoverContributions?.contributions?.map(c => {
            return (
                <tr key={c.id}>
                    <th>{c.description} ({c.product})</th>
                    <td>Contribution Factor: {c.contributionFactor}</td>
                </tr>
            )
        })

        const turnoverPerc = (100 * bonus.currentTurnover! / bonus.turnoverRequirement!).toFixed(2);

        return (
            <div className="wrapper wrapper-content animated fadeInRight">
                <div className={"col-lg-6 col-md-12"}>
                    <div className="row">
                        <div className={"col-lg-12"}>
                            <div className={"card-box"}>
                                <h4>User Bonus</h4>
                                <p>The properties of the bonus given to the user when the offering was accepted.</p>
                                <Link className={"btn btn-primary btn-xs"} to={`/bonus/events/${bonus.id}`}><i className="fa fa-eye"/> Events</Link>                                
                                <table className="table table-striped">
                                    <thead>
                                    </thead>
                                    <tbody>
                                        <tr><th>Bonus Id</th><td>{bonus.id}</td></tr>
                                        <tr><th>User Id</th><td><Link to={`/user/${bonus.userId}`}>{bonus.userId}</Link></td></tr>
                                        <tr><th>Currency</th><td>{bonus.currency}</td></tr>
                                        <tr><th>Status</th><td>{bonus.status}</td></tr>
                                        <tr><th>Expires</th><td>{formatDate(bonus.expires)}</td></tr>
                                        <tr><th>Total Bonus Amount</th><td>{bonus.totalBonusAmount}</td></tr>
                                        <tr><th>Current Turnover</th><td>{bonus.currentTurnover} ({turnoverPerc}%)</td></tr>
                                        <tr><th>Turnover Requirement</th><td>{bonus.turnoverRequirement}</td></tr>
                                        { (this.state.bonus.bonusConfig?.type == "PARACHUTE")  &&  
                                            <>
                                                <tr><th>Parachute Phase</th><td>{this.state.bonus.parachuteBonusPhase ? "Bonus Money" : "Real Money"}</td></tr>
                                                <tr><th>Parachute Bonus Balance</th><td>{this.state.bonus.parachuteBonusBalance}</td></tr>
                                            </>
                                        }
                                    </tbody>
                                </table>
                                <button className="btn btn-warning" onClick={() => this.tryComplete()}>Try to Complete Bonus</button>
                            </div>
                        </div>


                        <div className="col-lg-12">
                            <div className={"card-box"}>
                                <h4>Bonus Configuration</h4>
                                <p>Generic configuration of the bonus that was used to create the concrete user bonus.</p>
                                <Link className={"btn btn-primary btn-xs"} to={`/bonus/config/${config.id}`}><i className="fa fa-eye"/> Details</Link>
                                <table className="table table-striped">
                                    <thead>
                                    </thead>
                                    <tbody>
                                        <tr><th>Bonus Config Id</th><td>{config.id}</td></tr>
                                        <tr><th>Name</th><td>{config.name}</td></tr>
                                        <tr><th>Type</th><td>{config.type}</td></tr>
                                        <tr><th>Description</th><td>{config.description}</td></tr>
                                        <tr><th>Bonus Expiry Days</th><td>{config.expiresAfterDays}</td></tr>
                                        <tr><th>Summary</th><td>{config.summary}</td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className={"card-box"}>
                                <h4>Trigger</h4>
                                <p>The trigger of the bonus offering.</p>
                                <Link className={"btn btn-primary btn-xs"} to={`/bonus/offer-rule/${trigger.id}`}><i className="fa fa-eye"/> Details</Link>
                                <table className="table table-striped">
                                    <thead>
                                    </thead>
                                    <tbody>
                                        <tr><th>Trigger Id</th><td>{trigger.id}</td></tr>
                                        <tr><th>Name</th><td>{trigger.name}</td></tr>
                                        <tr><th>Type</th><td>{trigger.triggerType}</td></tr>
                                        <tr><th>Offer Expiry Days</th><td>{trigger.expiresAfterDays}</td></tr>
                                        <tr><th>Event</th><td>{trigger.event}</td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className={"card-box"}>
                                <h4>Monetary Configuration</h4>
                                <p>Monetary details of the bonus configuration.</p>
                                <Link className={"btn btn-primary btn-xs"} to={`/bonus/monetary-config/${monc.id}`}><i className="fa fa-eye"/> Details</Link>
                                <table className="table table-striped">
                                    <thead>
                                    </thead>
                                    <tbody>
                                        <tr><th>Monetary Config Id</th><td>{monc.id}</td></tr>
                                        <tr><th>Name</th><td>{monc.name}</td></tr>
                                        <tr><th>Award Amount Factor</th><td>{monc.awardAmountFraction}</td></tr>
                                        <tr><th>Award Type</th><td>{monc.awardType}</td></tr>
                                        <tr><th>Payment Type</th><td>{monc.bonusPaymentType}</td></tr>
                                        <tr><th>Parachute Bust Threshold</th><td>{monc.bustThreshold}</td></tr>
                                        <tr><th>Currency</th><td>{monc.currencyCode}</td></tr>
                                        <tr><th>Max Input Amount</th><td>{monc.maxInputAmount}</td></tr>
                                        <tr><th>Max Wager Amount</th><td>{monc.maxWagerAmount}</td></tr>
                                        <tr><th>Turnover Requirement</th><td>{monc.turnoverRequirementFactor}x</td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className={"card-box"}>
                                <h4>Turnover Contribution</h4>
                                <p>The various turnover contribution configurations of this bonus.</p>
                                <Link className={"btn btn-primary btn-xs"} to={`/bonus/turnover-contributions/${config.turnoverContributions?.id}`}><i className="fa fa-eye"/> Details</Link>
                                <table className="table table-striped">
                                    <thead>
                                    </thead>
                                    <tbody>
                                        <tr><th>Contributions Id</th><td>{config.turnoverContributions?.id}</td></tr>
                                        <tr><th>Name</th><td>{config.turnoverContributions?.name}</td></tr>
                                        {ctrs}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

}

export default withRouter(BonusView);