import React, {FormEvent} from "react";
import {
    CryptoAddressLog,
    ListCryptoAddressLogRequest,
    ListCryptoAddressLogResponse,
    ListPaymentsResponse,
    Payment,
    User
} from "../../http/protocol";
import {formatDate} from "../../util/date";
import {getContainer} from "../../ioc/IOCSetup";
import {PaymentService} from "../../services/PaymentService";
import {LoadingViewState} from "../RouterView";
import {UserService} from "../../services/UserService";
import {Link} from "react-router-dom";
import {MetricsSingleValuePanel} from "../metrics/MetricSingleValuePanel";
import {t} from "../../i18n";


interface Props {
    params: {id: string}
}


interface State extends LoadingViewState {
    cryptoAddressLog?: ListCryptoAddressLogResponse;
    userInfo?: User;
    form:ListCryptoAddressLogRequest;
    addressValue:string;
}

export class UserCryptoAddressHistory extends React.Component<Props,State> {
    paymentService: PaymentService;

    constructor(props:Props) {
        super(props);
        this.paymentService = getContainer().getPaymentService();
        let id = this.props.params.id;
        this.state = {cryptoAddressLog: {}, userInfo: {}, addressValue:"", form:{userId:Number(id), address:"", limit:10, offset: 0, ascending: false, orderBy:"id" }};
        console.log("USER PAYMENT HISTORY")
    }

    componentDidMount(): void {
        console.log("component did mount");
        this.paymentService.getCryptoAddresses(this.state.form).then((res) =>{
            if(res) {
                this.setState({cryptoAddressLog:res})
            }
            console.log("getCryptoAddresses: ", res);
        }).catch(e=>console.log(e))
    }

    renderCoinHistory() {
        let list = this.state.cryptoAddressLog && this.state.cryptoAddressLog.items;
        let lastTenTransactions : CryptoAddressLog[] = [];
        let rows:any;


        if(list) {
            let listMaxLength = list.length > 10 ? 10 : list.length;
            lastTenTransactions = list.slice(0,listMaxLength);
            rows = lastTenTransactions.map((p:CryptoAddressLog) => {
                console.log('CryptoAddressLog: ', p)
                return (
                    <tr key={p.id}>
                        {p.timestamp && (
                            <td>{formatDate(Number(p.timestamp.toString().split(".").join("").substring(0,13)))}</td>
                        )}
                        <td>{p.address}</td>
                        <td>{p.currency}</td>
                        <td>{p.provider}</td>
                        <td>{p.method}</td>
                    </tr>
                );
            });
        }

        return (
            <table className="table table-striped mb-0 dt-responsive nowrap dataTable no-footer dtr-inline">
                <thead>
                <tr>
                    <th>{t(`dataTable.header.created`)}</th>
                    <th>{t(`dataTable.header.address`)}</th>
                    <th>{t(`dataTable.header.coin`)}</th>
                    <th>{t(`dataTable.header.provider`)}</th>
                    <th>{t(`dataTable.header.method`)}</th>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </table>
        );
    }

    onSubmit() {
        if(this.state.form.address !== this.state.addressValue){
            this.paymentService.getCryptoAddresses({...this.state.form, address:this.state.addressValue}).then((res) => {
                this.setState({cryptoAddressLog:res, form:{...this.state.form, address:this.state.addressValue}});
            })
        }
    }

    onClear() {
        if(this.state.form.address == this.state.addressValue) {
            this.setState({addressValue:""},() => {
                this.paymentService.getCryptoAddresses({...this.state.form, address:""}).then((res) => {
                    this.setState({cryptoAddressLog:res, addressValue:""});
                })
            })
        } else {
            this.setState({addressValue:""})
        }
    }

    render() {
        return (
            <div className="col-lg-12">
                <div className={"card"}>
                    <div className="card-header">
                        <h4>{t(`title.cryptoAddressHistory`)}</h4>
                    </div>
                    <div className="card-body">
                        <div className="ibox-content">
                            <p className="text-left">{t(`label.upToTenLast`)}</p>
                            <label htmlFor="address" className="text-left">{t(`label.findAddress`)}:</label>
                            <div className="text-right form-inline">
                                <input className="form-control col-8" id="address" type="text" value={this.state.addressValue} onChange={(e) => this.setState({addressValue:e.target.value})}/>
                                <button className="btn btn-primary col-2" id="address" onClick={() => this.onSubmit()}><i className="fas fa-search"/></button>
                                <button className="btn btn-secondary col-2"  id="address" onClick={() => this.onClear()}><i className="fas fa-times"/></button>
                            </div>
                            <div className="table-responsive">
                            {this.renderCoinHistory()}
                            </div>
                            {/*<Link className="btn btn-primary" to={{*/}
                            {/*    pathname: "/payments",*/}
                            {/*    state: {*/}
                            {/*        listRequest : {userId:this.props.params.id}*/}
                            {/*    }*/}
                            {/*}}>*/}
                            {/*    <i className="fe-eye"/> {t(`button.viewAll`)}*/}
                            {/*</Link>*/}
                        </div>
                        <div className="ibox-content">

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}