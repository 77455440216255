import React, {Fragment} from "react";
import {getContainer} from "../../ioc/IOCSetup";
import {LoadingViewState, RouterView} from "../RouterView";
import {RouteComponentProps} from "react-router";
import {MetabaseService} from "../../services/MetabaseService";
import {Link} from "react-router-dom";


interface Props {

}
export type MetabaseDashboard = {
    name: string,
    id: number
}

interface State extends LoadingViewState{
    dashboards: MetabaseDashboard[];
}

export default class MetabaseDashboardListView extends RouterView<RouteComponentProps,State>{
    metabaseService: MetabaseService;
    constructor(props:RouteComponentProps) {
        super(props);

        this.metabaseService = getContainer().getMetabaseService();

        this.state = {dashboards: []};
    }

    loadContent(): void {
        this.metabaseService.getMetabaseDashboardList().then((dashboards:MetabaseDashboard[]) => {
            console.log("Metabase dashboards: ", dashboards);
            this.setState({dashboards:dashboards})
        })
    }
    getTitle() {
        return "Reports"
    }

    onClick() {

    }

    getDashboardsTable() {
        let list = this.state.dashboards;
        let rows:any;


        if(list) {
            rows = list.map(p => {
                return (
                    <tr key={p.id}>
                        <td>{p.id}</td>
                        <td>{p.name}</td>
                        <td align="right"><Link to={"/metabase/dashboard/" + p.id} className="btn btn-primary">View</Link></td>
                    </tr>
                );
            });
        }

        return (
            <table className="table m-b-xs table-striped">
                <thead>
                <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </table>
        );
    }

    renderContent() {
        if(this.state.dashboards.length == 0) {
            return <span></span>
        } else {
            return(
                <Fragment>
                    <div className="col-lg-12">
                        <div className={"card"}>
                            <div className="card-header">
                                <h4>Dashboards</h4>
                            </div>
                            <div className="card-body">
                                <div className="ibox-content">
                                    {this.getDashboardsTable()}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }
}