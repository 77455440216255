import * as React from "react";

import {Form, FormListener} from "../form/Form";
import {SelectInput} from "../form/SelectInput";
import {FormInput} from "../form/FormInput";
import {Validators} from "../form/Validators";
import {Submit} from "../form/Submit";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {Alert} from "../form/Alert";
import {Collapsible} from "../Collapsible";
import {AccountBalanceResult, TransactionResult, TransferRequest, TransferRequestType, UserBonusView} from "../../http/protocol";
import { CryptoCurrencyEuroValue } from "../account/CurrencyEuroValue";
import {t} from "../../i18n";


interface Props {
    accounts? : AccountBalanceResult[];
    activeBonuses?: UserBonusView[]
    transfer : (transferRequest : TransferRequest) => Promise<TransactionResult>;
    onTransferComplete : ( result : TransactionResult) => void;
}
interface State {
    transactionForm? : TransferRequest;
    validationMap? : any;
}

export class UserTransactionPanel extends React.Component<Props, State> implements FormListener<TransferRequest> {
    constructor(props : Props) {
        super(props);
        this.state = { transactionForm : {}, validationMap : {}};
    }

    onSubmit() : Promise<any> | undefined {
        if(this.state.transactionForm) {
            return this.props.transfer(this.state.transactionForm).then((tr)=>{
                this.setState({transactionForm : Object.assign({},this.state.transactionForm, { amount : "", comment : ""})})
                this.props.onTransferComplete(tr);
            }).catch(err=>{
               return Promise.reject("Unable to transfer funds");
            });
        }

    }

    formDidUpdate(formModel: TransferRequest, validationMap?: any) {
        this.setState({transactionForm : formModel, validationMap : validationMap});
    }

    findParachateBonusBalance(balance: AccountBalanceResult) {
        const role = balance.accountAttributes?.['ROLE']

        if (role !== 'MAIN') {
            return null;
        }

        const matchingBonus = this.props.activeBonuses?.find(b => b.currency == balance.balance?.currencyCode)
        return matchingBonus?.parachuteBonusBalance
    }

    getAccounts() {
        return this.props.accounts;
    }

    toDisplayValue(a : AccountBalanceResult){
        const amount = a.balance && a.balance.amount && a.balance.amount.toLocaleString("en",{maximumFractionDigits:8, minimumFractionDigits:2});
        if(!a.accountAttributes  || !a.balance) {
            return t(`status.na`);
        }

        const paracuteBalance = this.findParachateBonusBalance(a)
        const reservedBalanceStr = paracuteBalance ? (`(${paracuteBalance})`  ) : "";

        return a.accountAttributes["ROLE"] + "  " + amount + " " + reservedBalanceStr + " " + a.balance.currencyCode;
    }

    getCurrentAccount() : AccountBalanceResult | undefined{
        if(!this.state.transactionForm || !this.props.accounts) {
            return;
        }
        let current = this.state.transactionForm.accountId;
        return this.props.accounts.find( a => current == a.accountId);
    }


    getForm() {
        let accounts = this.getAccounts();
        if(!accounts  || accounts.length == 0 || !this.state.transactionForm) {
            return (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <Alert text={t(`alertInfo.userHaveNoAccounts`)} type="info" />
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <Form className="manual-adjustment-form container-fluid" formListener={this} validationMap={this.state.validationMap} modelObject={this.state.transactionForm}>
                <div className="row">
                    <div className="col-sm-6">
                        <label>{t(`label.transactionType`)}</label>
                        <SelectInput className="form-control" model="type">
                            <option value={TransferRequestType.CREDIT}>{t(`option.transactionType.credit`)}</option>
                            <option value={TransferRequestType.DEBIT}>{t(`option.transactionType.debit`)}</option>
                        </SelectInput>
                    </div>
                    <div className="col-sm-6">
                        <label>{t(`label.amount`)}:</label>
                        <FormInput className="form-control"  model="amount" type="float" validators={[Validators.required(), Validators.number()]}/>

                        {
                            // EUR rate converter disabled until we have fiat rates AND there is a way to configure the default currency for the site.
                            // Not all customers want to to convert everything to EUR.
                            /*
                            <CryptoCurrencyEuroValue sourceCurrency={this.getCurrentAccount()?.balance?.currencyCode} sourceAmount={this.state.transactionForm.amount} />
                            */
                        }
                    </div>

                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <label>{ this.state.transactionForm.type == "CREDIT" ? t(`label.to`) : t(`label.from`) } {t(`label.account`)}</label>
                        <SelectInput className="form-control" model="accountId" values={accounts} displayValue={x => this.toDisplayValue(x)} optionModelValue={ (a : AccountBalanceResult)=> { return a.accountId; }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-8">
                        <label>{t(`label.comment`)}</label>
                        <FormInput type="text" className="form-control" model="comment" validators={[Validators.required(), Validators.textInput()]}/>
                    </div>
                    <div className="col-sm-4">
                        <label>&nbsp;</label>
                        <FormSubmitError />
                        <FormSubmitSuccess text={"Transfer successful!"}/>
                        <Submit className="btn btn-primary btn-block">{t(`button.transfer`)}</Submit>
                    </div>
                </div>
            </Form>
        );
    }

    render() {
        return (
            <Collapsible title={t(`title.manualBalanceAdjustment`)} collapsed={true}>
                {this.getForm()}
            </Collapsible>
        );
    }
}