import {HttpService} from "../http/HttpService";
import {TxBatchCsvRequest, TxBatchResult} from "../http/protocol";


export class BatchTransactionService {
    http : HttpService;
    constructor(http : HttpService) {
       this.http = http;
    }

    prepareBatchTransfer(req : TxBatchCsvRequest) : Promise<TxBatchResult> {
        return this.http.post("/wallet/batch/batch/prepare", req);
    }
    performBatchTransfer(batchId : string) : Promise<TxBatchResult> {
        return this.http.post("/wallet/batch/batch/perform/"+batchId, undefined);
    }
    getAllCachedBatchTransferResults() : Promise<TxBatchResult[]> {
        return this.http.get("/wallet/batch/batch");
    }
    getBatchTransferResults(batchId : string) : Promise<TxBatchResult> {
        return this.http.get("/wallet/batch/batch/"+batchId);
    }
    cancelPreparedBatchTransfer(batchId : string) : Promise<TxBatchResult> {
        return this.http.delete("/wallet/batch/batch/"+batchId);
    }


}