import * as React from "react";
import {SettingsService} from "../../services/SettingsService";
import {getContainer} from "../../ioc/IOCSetup";
import {LoadingViewState, RouterView} from "../RouterView";
import {Form, FormListener} from "../form/Form";
import {RouteComponentProps, withRouter} from "react-router";
import {Fragment} from "react";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Submit} from "../form/Submit";
import {Settings} from "../../http/protocol";
import {Link} from "react-router-dom";

interface Props extends RouteComponentProps<{siteId?: string}>  {

}

interface State extends LoadingViewState{
    settings?: Settings;
    validationMap? : any;

}

export class SettingsIntegrationsView extends RouterView<Props,State> implements FormListener<Settings>{

    settingsService: SettingsService;

    constructor(props:Props) {
        super(props);

        this.settingsService = getContainer().getSettingsService();

        this.state = {validationMap : {}, loading : true};
    }

    loadContent(): Promise<any> | undefined {
        if(this.props.match.params?.siteId) {
            return this.settingsService.getEntity(this.props.match.params.siteId).then((data) => {
                this.setState({settings:data});
            });
        }
    }

    componentDidUpdate(prevProps : RouteComponentProps, prevState : any) {
        super.componentDidUpdate(prevProps, prevState);

    }
    formDidUpdate(formModel:Settings, validationMap : any) {
        this.setState({ settings : formModel, validationMap : validationMap});
    }

    onSubmit():Promise<any> {
        if(!this.state.settings) {
            return Promise.reject("Unable to save settings");
        }


        return this.settingsService.saveSettings(this.state.settings).catch(err=>{
            throw "Unable to create/save settings";
        });
    }

    onSubmitError(){}

    getTitle() : string {
        return "Integration Settings";
    }

    renderContent() {
        if(!this.state.settings) {
            return <span></span>
        }
        return(
            <Fragment>
                <Form className="form-horizontal" formListener={this} modelObject={this.state.settings}
                      validationMap={this.state.validationMap}>

                    <div className={"card"}>
                        <div className={"card-header"}>
                            <h4>Freshchat</h4>
                            <span className={"help-block"}>
                                Freshchat is the customer support live chat widget. You need to signup
                                your own Freshchat account in order to enable. <a href={"https://www.freshworks.com/live-chat-software/"} target={"_blank"}>Freshworks</a>
                            </span>
                        </div>
                        <div className="card-body">
                        <div className="row">
                                <div className="col-lg-12">
                                    <FormGroup className="custom-control custom-checkbox" model="freshChatEnabled">
                                        <FormInput className="custom-control-input" model="freshChatEnabled" id={"freshChatEnabled"} type="checkbox"/>
                                        <label htmlFor={"freshChatEnabled"} className={"custom-control-label"}>Enable FreshChat</label><br/>
                                    </FormGroup>
                                    <FormGroup className="form-group mb-3" model="freshChatWidgetToken">
                                        <label>FreshChat Widget Token</label>
                                        <FormInput className="form-control" model="freshChatWidgetToken" type="text"/>
                                        <span className={"help-block"}>Needed for Freshchat  integration</span>
                                    </FormGroup>
                                    <FormGroup className="form-group mb-3" model="freshChatWidgetSiteId">
                                        <label>FreshChat Site Id</label>
                                        <FormInput className="form-control" model="freshChatWidgetSiteId" type="text"/>
                                        <span className={"help-block"}>
                                            Optional site id if you share the same fresh chat account between sites.
                                            The site id can be any string as long as it is unique between sites.
                                            If you have different brand/logo you can configure the chat widget
                                            colors on <Link to={"/admin/settings/advanced/config.freshchat"}>Advanced Settings</Link> by editing the
                                            config.freshchat key. One limitation is that you can not override the main logo
                                            of the widget which means you have to leave it blank in the fresh chat admin panel.
                                        </span>
                                    </FormGroup>
                                    <FormGroup className="form-group mb-3" model="freshChatHost">
                                        <label>FreshChat Widget Host</label>
                                        <FormInput className="form-control" model="freshChatHost" type="text"/>
                                        <span className={"help-block"}>Optional. Override for the fresh chat host, default is https://wchat.freshchat.com and
                                            is used for the host parameter when initializing the Freshchat script</span>
                                    </FormGroup>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"card"}>
                        <div className={"card-header"}>
                            <h4>Fingerprint Pro</h4>
                            <span className={"help-block"}>
                                Fingerprint Pro enables a more accurate device identification with up to 99.5% accuracy. Enabling Fingerprint Pro will
                                add a new user attribute named 'fingerprint'. Note that Fingerprint Pro has additional costs attached to it. Please
                                talk to your account manager for a cost estimate if required. <a href={"https://fingerprint.com"} target={"_blank"}>Fingerprint Pro</a>
                            </span>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <FormGroup className="custom-control custom-checkbox" model="fingerprintEnabled">
                                        <FormInput className="custom-control-input" model="fingerprintEnabled" id={"fingerprintEnabled"} type="checkbox"/>
                                        <label htmlFor={"fingerprintEnabled"} className={"custom-control-label"}>Enable
                                            Fingerprint Pro</label><br/>
                                    </FormGroup>
                                    <FormGroup className="form-group mb-3" model="fingerprintDomain">
                                        <label>Fingerprint Pro Domain</label>
                                        <FormInput className="form-control" model="fingerprintDomain" type="text"/>
                                        <span className={"help-block"}>Fingerprint Pro configured subdomain.
                                            Needed to avoid getting blocked by ad blockers. Do not include https// or trailing /. Example: fp.neobet.io.
                                            Setup instructions: https://dev.fingerprint.com/docs/custom-subdomain-setup</span>
                                    </FormGroup>
                                    <FormGroup className="form-group mb-3" model="fingerprintPublicKey">
                                        <label>Fingerprint Pro Public Key</label>
                                        <FormInput className="form-control" model="fingerprintPublicKey" type="text"/>
                                        <span className={"help-block"}>
                                            Public key as generated in Fingerprint pro dashboard
                                        </span>
                                    </FormGroup>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"card"}>
                        <div className={"card-header"}>
                            <h4>Metamap</h4>
                            <span className={"help-block"}>
                                Metamap is an identity service and KYC provider. You need to create your own Metamap account to use this. <a href={"https://www.metamap.com/"} target={"_blank"}>Metamap</a>
                            </span>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <FormGroup className="custom-control custom-checkbox" model="matiEnabled">
                                        <FormInput className="custom-control-input" model="matiEnabled" id={"matiEnabled"} type="checkbox"/>
                                        <label htmlFor={"matiEnabled"} className={"custom-control-label"}>Enable Metamap</label><br/>
                                    </FormGroup>
                                    <FormGroup className="form-group mb-3" model="matiClientId">
                                        <label>Metamap client id</label>
                                        <FormInput className="form-control" model="matiClientId" type="text"/>
                                        <span className={"help-block"}>Needed for Metamap integration</span>
                                    </FormGroup>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"card"}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <FormSubmitError/>
                                    <FormSubmitSuccess text="Settings was updated successfully"/>
                                    <FormGroup className="form-group mb-3" model="facebookAppId">
                                        <label>Facebook App ID</label>
                                        <FormInput className="form-control" model="facebookAppId" type="text"/>
                                        <span className={"help-block"}>Leave empty to disable facebook login. You also need to configure Facebook App Secret in the Internal Settings</span>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>

                                    <FormGroup className="form-group mb-3" model="googleAnalyticsId">
                                        <label>Google Analytics Tracker</label>
                                        <FormInput className="form-control" model="googleAnalyticsId" type="text"/>
                                        <span className={"help-block"}>Google Analytics "TrackerId" goes here. Leave empty to disable google analytics.</span>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>


                                    <div className="hr-line-dashed"></div>

                                    <FormGroup className="custom-control custom-checkbox" model="useAmplitude">
                                        <FormInput className="custom-control-input" model="useAmplitude"
                                                   id={"useAmplitude"} type="checkbox"/>
                                        <label htmlFor={"useAmplitude"} className={"custom-control-label"}>Enable
                                            Amplitude</label><br/>
                                        <span className={"help-block"}></span>
                                    </FormGroup>
                                    <FormGroup className="form-group mb-3" model="amplitudeApiKey">
                                        <label>Amplitude API Key</label>
                                        <FormInput className="form-control" model="amplitudeApiKey" type="text"/>
                                        <span className={"help-block"}>Needed for Amplitude integration</span>
                                    </FormGroup>

                                    <div className="hr-line-dashed"></div>

                                    <label>Onramper</label>
                                    <FormGroup className="form-group mb-3" model="onramperCurrencies">
                                        <label>Onramper Currencies</label>
                                        <FormInput className="form-control" model="onramperCurrencies" type="text"/>
                                        <span className={"help-block"}>Comma separated list of currencies that can use Onramper ex. EUR,BTC,SEK (note: no spaces). Leave empty to disable Onramper completely.</span>
                                    </FormGroup>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"card"}>
                        <div className={"card-body"}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="ibox float-e-margins">
                                        <div className="ibox-content">
                                            <div className="ibox-title">
                                                <h5>Actions</h5>
                                            </div>
                                            <div className="form-group">
                                                <div className="col-sm-10 col-sm-offset-2">
                                                    <Submit className="btn btn-primary waves-effect waves-light">Save
                                                        Settings</Submit>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Form>


            </Fragment>
        )
    }

}

export default withRouter(SettingsIntegrationsView);