import * as React from "react";
import {LoadingViewState, RouterView} from "../RouterView";
import {formatDate} from "../../util/date";
import {RouteComponentProps, withRouter} from "react-router";
import {getContainer} from "../../ioc/IOCSetup";
import {AuditEntityType, AuditLog} from "../../http/protocol";
import {AuditLogService} from "../../services/AuditLogService";
import {NavLink} from "react-router-dom";


interface State extends LoadingViewState{
    auditLog? : AuditLog;
}

export class AuditLogView extends RouterView<RouteComponentProps,State>{

    auditLogService : AuditLogService;

    constructor(props : RouteComponentProps){
        super(props);
        this.auditLogService = getContainer().getAuditLogService();
        this.state = { };
    }

    getTitle() {
        return "Audit Log";
    }

    loadContent(): Promise<any>  {
        var id = (this.props.match.params as any).id;
        return Promise.all<any>([
            this.auditLogService.getAuditLog(id),
        ]).then((results) => {
            let auditLog : AuditLog = results[0];
            this.setState({auditLog : auditLog});
        })
    }


    getDateTime(timestamp?:Date|number) {
        if (typeof(timestamp) == "undefined") {
            return "";
        }
        return formatDate(timestamp);
    }

    renderContent() {
        if (!this.state.auditLog) {
            return <div></div>;
        }
        return (
            <div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card-box">
                                <div className="ibox-content">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="m-b-md">
                                                <h4>Audit Log Details</h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12">
                                            <table className="table table-striped">
                                                <tbody>
                                                <tr>
                                                    <td style={{ width : "30%"}}>ID:</td>
                                                    <td>{this.state.auditLog.id}</td>
                                                </tr>
                                                <tr>
                                                    <td>Actor ID:</td>
                                                    <td>
                                                        {(this.state.auditLog.actorType as AuditEntityType) === AuditEntityType.USER &&
                                                            <NavLink to={"/user/"+this.state.auditLog.actorId}>{this.state.auditLog.actorId}</NavLink>
                                                        }
                                                        {(this.state.auditLog.actorType as AuditEntityType) !== AuditEntityType.USER &&
                                                        <div>{this.state.auditLog.actorId}</div>
                                                        }
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td>Actor Type:</td>
                                                    <td>{this.state.auditLog.actorType}</td>
                                                </tr>

                                                <tr>
                                                    <td>Actor IP:</td>
                                                    <td>{this.state.auditLog.actorIp}</td>
                                                </tr>
                                                <tr>
                                                    <td>Actor Email:</td>
                                                    <td>{this.state.auditLog.actorEmail}</td>
                                                </tr>
                                                <tr>
                                                    <td>Target ID:</td>
                                                    <td>
                                                        {(this.state.auditLog.targetType as AuditEntityType) === AuditEntityType.USER &&
                                                            <NavLink to={"/user/"+this.state.auditLog.targetId}>{this.state.auditLog.targetId}</NavLink>
                                                        }
                                                        {(this.state.auditLog.targetType as AuditEntityType) !== AuditEntityType.USER &&
                                                        <div>{this.state.auditLog.targetId}</div>
                                                        }

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Target Type:</td>
                                                    <td>{this.state.auditLog.targetType}</td>
                                                </tr>
                                                <tr>
                                                    <td>Event Name:</td>
                                                    <td>{this.state.auditLog.eventName}</td>
                                                </tr>
                                                <tr>
                                                    <td>Endpoint:</td>
                                                    <td>{this.state.auditLog.method} {this.state.auditLog.path}</td>
                                                </tr>
                                                <tr>
                                                    <td>Date:</td>
                                                    <td>{this.getDateTime(this.state.auditLog.timestamp)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Description:</td>
                                                    <td className="long-text">{this.state.auditLog.description}</td>
                                                </tr>
                                                <tr>
                                                    <td>Request:</td>
                                                    <td className="long-text">{this.state.auditLog.request}</td>
                                                </tr>
                                                <tr>
                                                    <td>Affected Object Type:</td>
                                                    <td>{this.state.auditLog.affectedObjectType}</td>
                                                </tr>
                                                <tr>
                                                    <td>Affected Object Id:</td>
                                                    <td>{this.state.auditLog.affectedObjectId}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>





            </div>
        );
    }

}
export default withRouter(AuditLogView);