import {HttpService} from "../http/HttpService";
import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {CasinoLobbyCategory, ListCasinoLobbyCategoryResponse} from "../http/protocol";


export class CasinoJackpotService  extends AbstractAdminEntityService<any, any>  {

    constructor(http : HttpService) {
        super("/casino-jackpots", http)
    }



    refreshAdminCache(): Promise<{}> {
        return this.http.put(`${this.entityPath}/refresh`, {})
    }

}