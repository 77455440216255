
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import { NavLink, useParams } from "react-router-dom";
import {
    CreateVoucherRequest, PermissionCategory, Voucher
} from "../../http/protocol";
import { t } from "../../i18n";
import { getContainer } from "../../ioc/IOCSetup";
import { ApplicationState } from "../../user/UserStore";
import { LoadingStatus, RouterViewTemplate } from "../RouterView";
import { useForm } from "../form/Form";
import { PermissionRequired } from "../security/PermissionRequired";





const EditVoucher = () => {

    // const currencies = useSelector((s: ApplicationState) => s.settings.settings?.currencies)
    const params = useParams<{id?: string}>()
    const voucherId = params!.id!;
    const service = getContainer().getVoucherService();

    // const onSubmit  = async () => {
    //     if (!form.model) return;
    //     const voucher = await service.createVoucher(form.model)    
    // }

    const onError = () => {}

    const expiry = moment().add(1, 'year').toDate()

    // const [form, formListener, setForm] = useForm<CreateVoucherRequest>({expiry: expiry.getTime() as any}, onSubmit, onError)

    const [voucher, setVoucher] = useState<Voucher>()

    const [userId, setUserId] = useState<number|undefined>(undefined)

    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        (async () => {
            const voucher = await service.getVoucherById(voucherId) 
            setVoucher(voucher)
            setLoading(false)
        })()
    }, [service, voucherId])

    const updateUserId = async () => {
        if (voucher?.id  &&  userId  &&  !isNaN(userId)) {
            setLoading(true)
            const v = await service.setUserId(voucherId, userId)
            setVoucher(v)
            setLoading(false)
        }
    }

    const revokeVoucher = async () => {
        if (!voucher?.id) {
            return
        }

        setLoading(true)

        const v = await service.revokeVoucher(voucherId)

        setVoucher(v)
        setLoading(false)
    }


    const openVoucher = async () => {
        setLoading(true)

        const v = await service.openVoucher(voucherId)

        setVoucher(v)
        setLoading(false)
    }

    const copyCodeToClipboard = () => {
        navigator.clipboard.writeText(voucher?.code ?? "");
    }

    
    if (!voucher  ||  loading) {
        return <></>
    }

    return (
        <RouterViewTemplate status={LoadingStatus.DONE} title={t("payments.vouchers.edit.pageTitle")}>
            

            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card-box">
                            <div className="ibox-content">

                                <h4>Voucher Code: </h4>
                                {voucher.status == "CREATED"  && 
                                    <> Open voucher to reveal</>
                                }
                                {voucher.status != "CREATED"  && 
                                    <>
                                        <span><code style={{fontSize: "150%"}}>{voucher.code}</code></span> 
                                        <a onClick={copyCodeToClipboard} title="Copy to Clipboard" href="#"> <i className="fa fa-clipboard"></i></a>                                        
                                    </>
                                }

                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <th>User Id</th>
                                            <td>
                                                {voucher.userId ??
                                                    <> 
                                                        {voucher.status == "OPEN"  &&
                                                            <PermissionRequired accessLevel={"w"} permissions={[PermissionCategory.VOUCHERS]} showError={false}>
                                                                <div className="row"> 
                                                                    <div className="col-sm-4">
                                                                        <input className="form-control" type="number" placeholder="User Id" onChange={(e) => setUserId(Number(e.target.value))}></input>
                                                                    </div>
                                                                    <div className="col-sm-4">
                                                                        <button onClick={updateUserId} className="btn btn-primary" disabled={!userId}>Set User</button>
                                                                    </div>
                                                                </div>
                                                            </PermissionRequired>
                                                        }
                                                    </>
                                                }
                                            </td>
                                        </tr>

                                        <tr>
                                            <th>Status</th>
                                            <td>{voucher.status}</td>
                                        </tr>
                                        <tr>
                                            <th>Code</th>
                                            <td>
                                                {voucher.status != "CREATED"  &&  <>{voucher.code}</>}
                                                {voucher.status == "CREATED"  &&  <em>Hidden</em>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Amount</th>
                                            <td>{voucher.amount} {voucher.currency}</td>
                                        </tr>
                                        <tr>
                                            <th>Voucher Id</th>
                                            <td>{voucher.id}</td>
                                        </tr>
                                        <tr>
                                            <th>Created</th>
                                            <td>{new Date(voucher.created!).toISOString()}</td>
                                        </tr>
                                        <tr>
                                            <th>Updated</th>
                                            <td>{new Date(voucher.updated!).toISOString()}</td>
                                        </tr>
                                        <tr>
                                            <th>Expiry</th>
                                            <td>{new Date(voucher.expiry!).toISOString()}</td>
                                        </tr>
                                        <tr>
                                            <th>Reference</th>
                                            <td>{voucher.reference}</td>
                                        </tr>
                                        <tr>
                                            <th>Payment Id</th>
                                            <td>{voucher.paymentId}</td>
                                        </tr>
                                    </tbody>


                                </table>

                                <div className="hr-line-dashed"></div>
                                <h4>Actions</h4>
                                {voucher.status == "CREATED"   &&
                                    <PermissionRequired accessLevel={"w"} permissions={[PermissionCategory.VOUCHERS]} showError={false}>
                                        <button className="btn btn-success" onClick={openVoucher}>Open</button>
                                    </PermissionRequired>
                                }
                                &nbsp;

                                {(voucher.status == "OPEN"  ||  voucher.status == "CREATED")  &&
                                    <PermissionRequired accessLevel={"w"} permissions={[PermissionCategory.VOUCHERS]} showError={false}>
                                        <button className="btn btn-danger" onClick={revokeVoucher}>Revoke</button>
                                    </PermissionRequired>
                                }
                                &nbsp;

                                <PermissionRequired accessLevel={"r"} permissions={[PermissionCategory.VOUCHERS, PermissionCategory.AUDIT]} showError={false}>
                                    <NavLink className="btn btn-primary" to={`/audit-logs/limit/50/ascending/false/orderBy/id/affectedObjectId/${voucher.id}/affectedObjectType/VOUCHER/offset/0`}>Audit Logs</NavLink>
                                </PermissionRequired>

                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </RouterViewTemplate>
    );
}


export default withRouter(EditVoucher);