import * as React from "react";

import {RouteComponentProps, withRouter} from "react-router";
import {LoadingViewState, RouterView} from "../RouterView";
import {
    CasinoStudio,
    GameTag,
    MonetaryConfig,
    BonusTrigger,
    RuleTriggerType,
    BonusOfferStatus
} from "../../http/protocol";
import {Form, FormListener} from "../form/Form";
import {getContainer} from "../../ioc/IOCSetup";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {Submit} from "../form/Submit";
import {Validators} from "../form/Validators";
import {SelectInput} from "../form/SelectInput";
import {OfferRuleService} from "../../services/OfferRuleService";
import {FormUtils} from "../form/FormUtils";
import {FormIf} from "../form/Switch";
import {t} from "../../i18n";



interface State extends LoadingViewState {
    tc? : BonusTrigger;
    validationMap? : any;
}

export class EditOfferRule extends RouterView<RouteComponentProps,State> implements FormListener<BonusTrigger>{

    service : OfferRuleService;

    constructor(props : RouteComponentProps){
        super(props);
        this.service = getContainer().getOfferRuleService();
        this.state = { tc : {event:"deposit"}, validationMap : {} };
    }

    getTitle(): string {
        return "Edit Trigger";
    }

    componentDidUpdate(prevProps : RouteComponentProps, prevState : State) {
        if((prevProps.match.params as any).id != (this.props.match.params as any).id)  {
            this.refreshContent();
        }
    }

    loadContent(): Promise<any>  {
        if((this.props.match.params as any).id != "new") {
            return this.service.getEntity((this.props.match.params as any).id).then((tc : BonusTrigger) => {
                this.setState({ tc : tc});

            });
        }
        return Promise.resolve();

    }

    onSubmit()  {
        if(!this.state.tc) return;
        return this.service.save(this.state.tc).then((s : CasinoStudio)=> {
            this.setState({tc : s});
        })
    }

    formDidUpdate(formModel: GameTag, validationMap?: any) {
        this.setState({tc : formModel, validationMap : validationMap})
    }

    renderContent() {
        if(!this.state.tc) {
            return <span></span>
        }

        return (
            <div>


                <div className="wrapper wrapper-content animated fadeInRight">
                    <Form className="form form-horizontal" validationMap={this.state.validationMap} modelObject={this.state.tc} formListener={this}>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className={"alert alert-info"}>

                                </div>
                                <div className="card-box">
                                    <div className="ibox-content">
                                        {(this.props.match.params as any).id != "new" && (   <div className="hr-line-dashed"></div> )}

                                        {(this.props.match.params as any).id != "new" && (
                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">ID</label>
                                                <div className="col-sm-10">
                                                    <FormInput  model="id" type="integer"  className="form-control" disabled={true}/>
                                                </div>
                                            </FormGroup>
                                        )}

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Name</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="name" validators={[Validators.required()]} type="text"  className="form-control" />
                                                <span className={"help"}>Internal name of this offer rule</span>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Offer Expires After (Days)</label>
                                            <div className="col-sm-10">
                                                <FormInput model="expiresAfterDays" validators={[Validators.required(), Validators.integer()]} type={"integer"} className={"form-control"}  />
                                                <span className={"help"}>After how many days should this offer expire.</span>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Rule Trigger Type</label>
                                            <div className="col-sm-10">
                                                <SelectInput values={Object.keys(RuleTriggerType)}  model="triggerType" displayValue={FormUtils.fromEnum} className={"form-control"}  />
                                                <span className={"help"}>When this bonus should be triggered</span>
                                            </div>
                                        </FormGroup>


                                       <FormIf model={"triggerType"} value={RuleTriggerType.ABSOLUTE_COUNT}>
                                           <div className="hr-line-dashed"></div>
                                           <FormGroup className="form-group">
                                               <label className="col-sm-2 control-label">Count</label>
                                               <div className="col-sm-10">
                                                   <FormInput  model="count" validators={[Validators.required(), Validators.integer()]} type="integer"  className="form-control" />
                                                   <span className={"help"}></span>
                                               </div>
                                           </FormGroup>
                                       </FormIf>
                                    </div>
                                </div>



                            </div>

                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card-box">

                                    <FormSubmitError />
                                    <FormSubmitSuccess text={t(`alertInfo.accountSuccessfully`)}/>
                                    <div className="form-group">
                                        <div className="col-sm-12">
                                            <Submit className="btn  btn-primary">Save</Submit>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }

}

export default withRouter(EditOfferRule);