import * as React from "react";
import * as _ from "lodash";
import * as fp  from 'lodash/fp';
import {FormUtils} from "./FormUtils";
import {FormHTMLAttributes, HTMLAttributes, ReactElement, useState} from "react";
import {t} from "../../i18n";



export interface FieldSetListener<M> {
    formDidUpdate(formModel : M, validationMap? : any) : void;
}

export interface FormListener<M> extends FieldSetListener<M> {
    onSubmitError? : () => void;
    onSubmit : () =>  void | Promise<any>;
}


export interface FormComponentProps {
    errorClass? : string;
    fieldSet?: FieldSet;
}

export interface InputValidation {
    displayError : boolean;
    errorName : string;
    pristine : boolean;
}

export interface ValidationResult {
    errorName? : string;
    pristine : boolean;
}
export enum FieldSetStatus {
    CREATED,
    SUBMITTING,
    SUBMIT_ERROR,
    SUBMIT_SUCCESS
}
interface FieldSetState {
    valid? : boolean;
    status? : FieldSetStatus;
    formError? : string;
}

export class KeyValue<M> {
    key : string;
    value : M;
    pristine? : boolean;
    validationResult? : ValidationResult;
    constructor(key : string, value : M, pristine? : boolean, validationResult? : ValidationResult){
        this.key = key;
        this.value = value;
        this.pristine = pristine;
        this.validationResult = validationResult;
    }
}
export interface FieldSet {
    /**
     * Sets the initial state of the form model and validation map usually when child components are
     * created, the actual models should update on componentDidMount
     * @param model
     * @param value
     * @param pristine
     * @param validationResult
     */
    setInitialModel(model : string | undefined, value : any,  pristine : boolean, validationResult? : ValidationResult) : void;

    /**
     * Updates a model and its validation, can only be called once each update cycle, if you need to update
     * multiple models at the same time use updateModels
     * @param model
     * @param value
     * @param pristine
     * @param validationResult
     */
    updateModel(model : string | undefined, value : any,  pristine : boolean, validationResult? : ValidationResult) : void;

    /**
     * Updates multiple models within the same transaction, since calling updateModel consecutive times
     * will not work due to the nature of react states
     * @param keyValue
     */
    updateModels(keyValue : KeyValue<any>[]) : void;


    /**
     * Removes the model
     * @param model
     */
    removeModel(model : string): void;

    /**
     * Removes validations, usually called when a form component is unmounted
     * @param model
     */
    removeValidation(model? : string): void;
    modelDidUpdate(modelObject : any , valid? : boolean): void;
    getModelValue(model? : string) : any;
    getModelObject() : any;
    isModelValid(model? : string) : boolean;
    removeCurrentItem() : void;
    isSubmitting() : boolean;
    getStatus() : FieldSetStatus | undefined;
    getFormError() : string | undefined;
    resetSubmitStatus() : void;
    isReadOnly() : boolean;

}
export interface ListItemFieldSet extends FieldSet {
    move(back? : boolean) : void;
}

interface FieldSetProps<E extends React.FormHTMLAttributes<any>> extends React.HTMLProps<E>{
    children? :any;
    formListener : FieldSetListener<any>;
    modelObject : any;
    validationMap : any;
    errorClass? : string;
    parent? : FieldSet;
    viewOnly? : boolean;
    ref?: any;

}
export class AbstractFieldSet<E extends FieldSetProps<any>>  extends React.Component<E,FieldSetState> implements FieldSet {


    pendingModelObject : any;
    pendingValidationMap : any;
    constructor(props : E) {
        super(props);
        this.pendingModelObject = props.modelObject;
        this.state = { valid : true, formError : undefined, status : FieldSetStatus.CREATED };
    }
    componentDidMount() {
        this.modelDidUpdate(this.pendingModelObject, this.pendingValidationMap);
        console.log("Fieldset mounted", this.pendingModelObject, this.pendingValidationMap);
        this.setState({ valid : this._isValid(this.pendingValidationMap,false) });
        this.pendingModelObject = undefined;
        this.pendingValidationMap = undefined;
    }
    isValid() {
        return this.state.valid;
    }
    resetSubmitStatus() {
        this.setState({status : FieldSetStatus.CREATED});
    }
    isSubmitting() : boolean {
        return this.state.status == FieldSetStatus.SUBMITTING;
    }
    getStatus() : FieldSetStatus | undefined{
        return this.state.status;
    }

    getFormError() : string | undefined{
        return this.state.formError;
    }

    getModelValue(model  : string) : any {
        return _.get(this.props.modelObject,model);
    }


    updateModels(values : KeyValue<any>[]) {
        var validationMap = Object.assign({},this.props.validationMap);
        var newModel : any =  this.props.modelObject;

        values.forEach( (kvp) => {
            if(kvp.key != "" && kvp.validationResult) {
                validationMap[kvp.key] = { displayError : (kvp.validationResult.errorName!=null && !kvp.pristine), errorName : kvp.validationResult.errorName, pristine : kvp.pristine};
                newModel = fp.set(kvp.key, kvp.value,newModel);
            } else {
                console.warn("Model was empty when updating key value pairs");
            }
        });
        this.modelDidUpdate(newModel, validationMap);
    }
    setInitialModel(model: string, value: any, pristine: boolean, validationResult?: ValidationResult) {

        if(!this.pendingValidationMap) {
            this.pendingValidationMap = _.cloneDeep(this.props.validationMap);
        }
        if(!this.pendingModelObject) {
            this.pendingModelObject = _.cloneDeep(this.props.modelObject);
        }


        this.pendingValidationMap[model] = { displayError : (validationResult && validationResult.errorName!=null && !pristine), errorName : validationResult && validationResult.errorName, pristine : pristine};
        let update = {}
        _.set(update, model, value);
        this.pendingModelObject = _.merge(this.pendingModelObject, update);
    }
    removeModel(model: string) {
        if(model == "") {
            throw new Error("Model undefined or empty");
        }
        let newModel : any;
        if(model.endsWith("]")) {
            var index = model.lastIndexOf("[");
            let property = model.substr(0, index);
            var indexPart = model.substr(index + 1, 1);
            let arrayIndex = parseInt(indexPart);
            let arr : any[] = (_.get(this.props.modelObject, property) as Array<any>)
            if(arr) {
                arr = arr.concat();
                arr.splice(arrayIndex,1);
                newModel = fp.set(property,arr,this.props.modelObject);
            }
        } else {
            newModel = fp.unset(model,this.props.modelObject);
        }

        this.modelDidUpdate(newModel, this.props.validationMap);
    }
    removeValidation(model : string) {
        if(!this.pendingValidationMap) {
            this.pendingValidationMap = Object.assign({},this.props.validationMap);
        }

        for(let key in this.pendingValidationMap) {
            if(key.indexOf(model as string) == 0) {
                delete this.pendingValidationMap[key];
            }
        }
    }
    updateModel(model : any, value: any,  pristine: boolean, validationResult : ValidationResult) {
        if(model == "") {
            throw new Error("Model undefined or empty");
        }
        var newModel : any = fp.set(model,value, this.props.modelObject);
        var validationMap = Object.assign({},this.props.validationMap);
        validationMap[model] = { displayError : (validationResult.errorName!=null && !pristine), errorName : validationResult.errorName, pristine : pristine};
        this.modelDidUpdate(newModel, validationMap);

    }

    modelDidUpdate(model : any, validationMap? : any) {
        this.props.formListener.formDidUpdate(model, validationMap);
    }

    _isValid(map : {[key:string] : InputValidation }, includeAll : boolean) : boolean {
        if(includeAll == true) {
            map = Object.assign({},map);
        }
        var valid = true;
        for(var prop in map) {
            if(!FormUtils.isFormModelValid(map, prop, includeAll)) {
                if(includeAll == false) {
                    return false;
                } else {
                    map[prop].displayError = true;
                    valid = false;
                }
            }
        }
        if(includeAll == true && valid == false) {
            this.modelDidUpdate(this.props.modelObject, map);
        }
        return valid;
    }
    isModelValid(model : string) {
        return FormUtils.isFormModelValid(this.props.validationMap, model, false);
    }
    getModelObject() {
        return this.props.modelObject;
    }
    getClassNames() {
        return FormUtils.getClassName(this.props.className, this.props.errorClass, this.state.valid );
    }
    removeCurrentItem(){}

    componentDidUpdate(prevProps: E, prevState : FieldSetState) {
        let modelObject = undefined;
        if(this.pendingModelObject) {
            modelObject = this.pendingModelObject;
            this.pendingModelObject = undefined;
        }
        let validationMap = undefined;
        if(this.pendingValidationMap){
            validationMap =  this.pendingValidationMap;
            this.pendingValidationMap = undefined;
        }
        if(validationMap || modelObject) {
            this.modelDidUpdate(modelObject ? modelObject : this.props.modelObject, validationMap ? validationMap : this.props.validationMap);
        }
    }

    isReadOnly(): boolean {
        return typeof(this.props.viewOnly) != "undefined" && this.props.viewOnly == true;
    }

}

export class SimpleFieldSet extends AbstractFieldSet<FieldSetProps<React.HTMLAttributes<any>>> {
    render() {
        let rest : React.HTMLAttributes<any> = _.omit(this.props,['inProgress', 'formListener', 'modelObject', 'errorClass', 'parent', 'validationMap', 'ref']);
        return (
            <span {...rest} className={this.getClassNames()}>
                {FormUtils.renderChildren(this.props.children,this)}
            </span>
        );
    }
}
export interface FormProps extends FieldSetProps<FormHTMLAttributes<any>> {
    formListener : FormListener<any>;

}
export class Form extends AbstractFieldSet<FormProps> {

    constructor(props : FormProps) {
        super(props);
        console.log("Form props", props);
        if(!this.props.validationMap) {
            console.error("Validation map on Form is undefined");
        }
    }

    onSubmit(e : React.FormEvent) {
        e.preventDefault();
        if(this.isSubmitting()) {
            return;
        }

        if(this._isValid(this.props.validationMap, true)) {
            this.setState({ status : FieldSetStatus.SUBMITTING });
            if(typeof(this.props.formListener.onSubmit) != "undefined") {
                let promise = this.props.formListener.onSubmit() as Promise<any>;
                if(promise) {
                    promise.then(()=>{
                        setTimeout(()=>{
                            this.setState({ status : FieldSetStatus.SUBMIT_SUCCESS });
                        },0);
                    }).catch(err => {
                        console.log("Promise throw errror", err);
                        setTimeout(()=>{
                            let msg = "";
                            if(err.message) {
                                msg = err.message;
                            } else {
                                msg = err.toString();
                            }
                            this.setState({ status : FieldSetStatus.SUBMIT_ERROR, formError :msg });
                        },0);
                    });
                } else {
                    setTimeout(()=>this.setState({ status : FieldSetStatus.SUBMIT_SUCCESS }), 50);
                }
            } else {
                setTimeout(()=>this.setState({ status : FieldSetStatus.SUBMIT_SUCCESS }), 50);
                console.warn("No submit handler implemented form listener")
            }
        } else {
            this.setState({ status : FieldSetStatus.SUBMIT_ERROR, formError : t(`alertInfo.fieldsNotCorrect`) });
            if(this.props.formListener.onSubmitError) {
                this.props.formListener.onSubmitError();
            }
            console.log("Form is invalid", this.props.validationMap);
        }
    }


    render() {
        let rest : React.FormHTMLAttributes<any>= _.omit(this.props,['inProgress', 'formListener', 'modelObject', 'errorClass', 'parent', 'fieldSet', 'model','modelValue','valid', 'validationMap', 'viewOnly']);
        return (
            <form {...rest} className={this.getClassNames()}  onSubmit={this.onSubmit.bind(this)}>
                {FormUtils.renderChildren(this.props.children, this)}
            </form>
        );
    }
}

export type FormHookState<T> = { model : T, validationMap : any }
export function useForm<T>(initialForm : T, onSubmit? : () => void, onSubmitError? : () => void) : [FormHookState<T>, FormListener<T>,  React.Dispatch<React.SetStateAction<FormHookState<T>>>] {
    const [form, setForm] =  useState<FormHookState<T>>({ model : initialForm, validationMap : {}});

    const formListener : FormListener<T> = {
        formDidUpdate(formModel: T, validationMap: any): void {
            setForm({model : formModel, validationMap : validationMap});
        },
        onSubmit(): void | Promise<any> {
            return onSubmit?.();
        },
        onSubmitError(): void {
            onSubmitError?.()
        }

    }
    return [form, formListener, setForm]
}