import {applyMiddleware, createStore, Store} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';

import {ApplicationState, appReducer, createAction} from "./user/UserStore";

let store : Store<ApplicationState>;
export function configureStore() {
    const middlewares = [thunk];
    const composedEnhancers = composeWithDevTools({
        trace: true // Enable tracing of action calls
      })(applyMiddleware(...middlewares));
    store = createStore(appReducer, process.env.NODE_ENV === 'development' ? composedEnhancers : applyMiddleware(...middlewares));
     return store;
}

export function getStore() : Store<ApplicationState> {
    return store;
}
export function createAndDispatch<T>(type : any, payload : T) {
    return store.dispatch(createAction(type, payload));
}