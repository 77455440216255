import * as React from "react";
import {Fragment} from "react";
import Datetime from 'react-datetime';
import {RouteComponentProps, withRouter} from "react-router";
import {LoadingViewState, RouterView} from "../RouterView";
import {
    BonusConfig,
    BonusStatus,
    CurrencyDefinition,
    MonetaryConfig,
    BonusTrigger,
    Settings,
    TurnoverContributions, CasinoGame, CasinoGameTranslation, BonusLogicType, AwardType, BonusPaymentType
} from "../../http/protocol";
import {Form, FormListener} from "../form/Form";
import {getContainer} from "../../ioc/IOCSetup";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {Submit} from "../form/Submit";
import {Validators} from "../form/Validators";
import {TurnoverContributionsService} from "../../services/TurnoverContributionsService";
import {MonetaryConfigService} from "../../services/MonetaryConfigService";
import {SettingsService} from "../../services/SettingsService";
import {BonusConfigService} from "../../services/BonusConfigService";
import {OfferRuleService} from "../../services/OfferRuleService";
import {SelectInput} from "../form/SelectInput";

import {FormTextArea} from "../form/FormTextArea";
import {BindModel, FormList, ModelBinding} from "../form/FormList";
import {t} from "../../i18n";
import { ApplicationState } from "../../user/UserStore";
import { connect } from "react-redux";
import { FormDateTimeInput } from "../form/FormDateTimeInput";

interface State extends LoadingViewState {
    tc? : BonusConfig;
    validationMap? : any;
    contributions?: TurnoverContributions[];
    monetaryConfigs? : MonetaryConfig[];
    offerRules? : BonusTrigger[];
    edit?: boolean;
}

interface TCProps extends RouteComponentProps {
    currencies? : CurrencyDefinition[];
}

export class EditTurnoverContributions extends RouterView<TCProps,State> implements FormListener<BonusConfig>{

    service : BonusConfigService;
    monetaryService : MonetaryConfigService;
    contributionService : TurnoverContributionsService;
    offerRuleService: OfferRuleService;

    constructor(props : TCProps){
        super(props);
        this.service = getContainer().getBonusConfigService();
        this.monetaryService = getContainer().getMonetaryConfigService();
        this.contributionService = getContainer().getTurnoverContributionsService();
        this.offerRuleService = getContainer().getOfferRuleService();

        this.state = { tc: { status: BonusStatus.DISABLED, type: BonusLogicType.BOUNTY }, validationMap: {}, contributions: [], monetaryConfigs: [], offerRules: [] };
    }

    isNew = () => (this.props.match.params as any).id == "new";

    getTitle(): string {
        return this.isNew() ? "Create Bonus" : "Edit Bonus";
    }

    componentDidUpdate(prevProps : TCProps, prevState : State) {
        if((prevProps.match.params as any).id != (this.props.match.params as any).id)  {
            this.refreshContent();
        }
    }

    async loadContent(): Promise<any>  {
        if(!this.isNew()) {
            return Promise.all([
                this.contributionService.list({ offset : 0, limit: 500, orderBy : "name"}),
                this.offerRuleService.list({ offset : 0, limit: 500, orderBy : "name"}),
                this.monetaryService.list({ offset : 0, limit: 500, orderBy : "name"})
            ]).then( (res : any[])=>{
                this.setState({ contributions : res[0].items, offerRules : res[1].items, monetaryConfigs : res[2].items})
                return this.service.getEntity((this.props.match.params as any).id).then((tc : BonusConfig) => {
                    this.setState({ tc : tc });

                });
            });
        }

        const [contributions, offerRules, monetaryConfigs] = await Promise.all([
            this.contributionService.list({ offset : 0, limit: 100, orderBy : "name"}),
            this.offerRuleService.list({ offset : 0, limit: 100, orderBy : "name"}),
            this.monetaryService.list({ offset : 0, limit: 100, orderBy : "name"})
        ])

        this.setState({ contributions : contributions.items, offerRules : offerRules.items, monetaryConfigs : monetaryConfigs.items})
    }

    onSubmit()  {
        if(!this.state.tc) return;

        if (!this.state.tc.validFromDate  ||  !this.state.tc.validToDate) {
            return Promise.reject("Missing valid from/to to date(s)")
        }


        return this.service.save(this.state.tc).then((s : BonusConfig)=> {
            this.setState({tc : s});
        }).catch((e : any) => {
            return Promise.reject("Failed to save bonus config")
        })
    }

    formDidUpdate(formModel: BonusConfig, validationMap?: any) {
        this.setState({tc : formModel, validationMap : validationMap})
    }
    getCurrencyLabel(code : string) {
        if(this.props.currencies) {
            let c = this.props.currencies.find( c => c.code == code);
            if(c) {
                return c.name + " (" + c.code + ")";
            }
        }
        return "";
    }
    setFromDate(d : number) {
        this.setState({ tc : {...this.state.tc, validFromDate : (d as any) }});
    }
    setToDate(d : number) {
        this.setState({ tc : {...this.state.tc, validToDate : (d as any) }});
    }
    sortTranslations(s : CasinoGame) {
        if(s.translations) {
            s.translations.sort((a,b)=>{
                if(!a.language) {
                    return 1;
                } else if(!b.language) {
                    return 1;
                } else  if(!a && !b) {
                    return 0;
                }
                return a.language.localeCompare(b.language);
            })
        }
    }

    addTranslation() {
        if(this.state.tc && this.state.tc.translations) {
            let translations = this.state.tc.translations.concat([{}])
            let tc = {...this.state.tc,  translations : translations };
            this.sortTranslations(tc);
            this.setState({ tc : tc})
        } else {
            let tc = {...this.state.tc,  translations : [{}] };
            this.setState({ tc : tc})
        }
    }

    getCompatibleMonetaryConfigs() {
        if(this.state.tc?.type !== BonusLogicType.PARACHUTE) {
            return this.state.monetaryConfigs;
        } else {
            return this.state.monetaryConfigs?.filter(mc => mc.bonusPaymentType == BonusPaymentType.DIRECT);
        }
    }

    renderContent() {
        if(!this.state.tc) {
            return <span></span>
        }
        let monetaryConfig = this.state.tc.monetaryConfig;
        return (
            <div>


                <div className="wrapper wrapper-content animated fadeInRight">
                    <Form className="form form-horizontal" validationMap={this.state.validationMap} modelObject={this.state.tc} formListener={this}>
                        <div className="row">
                            <div className="col-lg-12">

                                <div className="card-box">
                                    <div className="ibox-content">
                                        <h4 className={"ibox-title"}>Meta Data</h4>
                                        {!this.isNew() && (
                                            <span>
                                                <div className="hr-line-dashed"></div>

                                                <FormGroup className="form-group">
                                                    <label className="col-sm-2 control-label">ID</label>
                                                    <div className="col-sm-10">
                                                        <FormInput  model="id" type="integer"  className="form-control" disabled={true}/>
                                                    </div>
                                                </FormGroup>
                                            </span>
                                        )}

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Name</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="name" validators={[Validators.required()]} type="text"  className="form-control" />
                                                <span className={"help"}>The name in the default language for this bonus</span>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Summary</label>
                                            <div className="col-sm-10">
                                                <FormTextArea  model="summary" className="form-control" />
                                                <span className={"help"}>Short description of the bonus. Will be displayed on the bonus & promos page.</span>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Description</label>
                                            <div className="col-sm-10">
                                                <FormTextArea  model="description" className="form-control" />
                                                <span className={"help"}>A full description of the bonus. Will be displayed on the bonus detail page.</span>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Image URL</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="imageUrl" type="text"  className="form-control" />
                                                <span className={"help"}>URL to the image displayed on the bonus page (optional)</span>
                                            </div>
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="card-box">
                                    <div className="ibox-content">
                                        <h4 className={"ibox-title"}>Bonus Constraints</h4>
                                        <div className="hr-line-dashed"></div>

                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Type</label>
                                            <div className="col-sm-10">
                                                <SelectInput  model="type" values={Object.keys(BonusLogicType)} disabled={!this.isNew()} className="form-control" />
                                                <span className={"help"}>Type of bonus:
                                                    <ul>
                                                        <li><strong>Bounty:</strong> Real money with turnover requirement. When goal is met the prize will be rewarded. Deposits and withdrawals are allowed.</li>
                                                        <li><strong>Parachute:</strong> Real money first, then bonus money with turnover requirement. Bonus money added directly to balance but restricted.
                                                            When goal is met the remaining bonus money is released. Deposits allowed. Withdrawals will forfeit the bonus.</li>
                                                    </ul>
                                                </span>
                                            </div>
                                        </FormGroup>


                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Status</label>
                                            <div className="col-sm-10">
                                                <SelectInput  model="status" values={Object.keys(BonusStatus)}  className="form-control" />
                                                <span className={"help"}>Status of the bonus</span>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Valid from/to dates</label>
                                            <div className="col-sm-10">
                                                <div>
                                                  <DateRange fromDate={this.state.tc.validFromDate} toDate={this.state.tc.validToDate}
                                                               setFromDate={(d) => this.setFromDate(d)} setToDate={(d) => this.setToDate(d)}/>

                                                </div>
                                                <span className={"help"}>Select the from & to date when this bonus is valid to claim.
                                                    Bonuses that are claimed within this window will still be valid for it's full period even if it is after this period dates</span>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Expires in (Days)</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="expiresAfterDays" validators={[Validators.required()]} type="text"  className="form-control" />
                                                <span className={"help"}>After how many days it will expire from a player activates this bonus</span>
                                            </div>
                                        </FormGroup>

                                        <div className="custom-control custom-checkbox">
                                            <FormInput className="custom-control-input" id="blockUponOtherClaims"
                                                       model="blockUponOtherClaims" type="checkbox"/>
                                            <label htmlFor="blockUponOtherClaims" className="custom-control-label">
                                                Block upon other claims
                                            </label>
                                            <br/>
                                            <span className="help">Specifies if this bonus will be blocked instead of canceled when other bonuses are claimed</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-box">
                            <div className="ibox-content">
                                <div className="hr-line-dashed"></div>
                                <FormGroup className="form-group">
                                    <label className="col-sm-2 control-label">Monetary Config</label>
                                    <div className="col-sm-10">
                                        <SelectInput values={this.getCompatibleMonetaryConfigs()}
                                                     convertOptionValueToModel={ (id) => {
                                                         if(this.state.monetaryConfigs) {
                                                             return this.state.monetaryConfigs.find(m => m.id == id);
                                                         } else {
                                                             return "N/A";
                                                         }
                                                     }}
                                                     convertModelValueToOption={(m)=>m.id}
                                                     optionModelValue={(m)=>m.id}
                                                     displayValue={(mc : MonetaryConfig) => mc.name + " - " + mc.currencyCode}  model="monetaryConfig"  className="form-control" />
                                        <span className={"help"}>
                                                    Award amount: {monetaryConfig && monetaryConfig.awardAmountFraction}.<br/>
                                                    <strong>NOTE: </strong> Parachute bonuses can only have DIRECT type rewards. Incompatible rewards are filtered out in the dropdown.
                                                </span>
                                    </div>
                                </FormGroup>

                                <div className="hr-line-dashed"></div>
                                <FormGroup className="form-group">
                                    <label className="col-sm-2 control-label">Turnover contribution</label>
                                    <div className="col-sm-10">
                                        <SelectInput values={this.state.contributions}
                                                     convertOptionValueToModel={ (id) => {
                                                         if(this.state.contributions) {
                                                             return this.state.contributions.find(m => m.id == id);
                                                         } else {
                                                             return "N/A";
                                                         }
                                                     }}
                                                     convertModelValueToOption={(m)=>m.id}
                                                     optionModelValue={(m)=>m.id}
                                                     displayValue={(mc : TurnoverContributions) => ""+mc.name}
                                                     model="turnoverContributions"  className="form-control" />
                                    </div>
                                </FormGroup>

                                <div className="hr-line-dashed"></div>
                                <FormGroup className="form-group">
                                    <label className="col-sm-2 control-label">Trigger</label>
                                    <div className="col-sm-10">
                                        <SelectInput values={this.state.offerRules}
                                                     convertOptionValueToModel={ (id) => {
                                                         if(this.state.offerRules) {
                                                             return this.state.offerRules.find(m => m.id == id);
                                                         } else {
                                                             return "N/A";
                                                         }
                                                     }}
                                                     optionModelValue={(m)=>m.id}
                                                     convertModelValueToOption={(m)=>m.id}
                                                     displayValue={(mc : BonusTrigger) => ""+mc.name}  model="trigger"  className="form-control" />
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                        <div className={"card-box"}>
                            <div className={"ibox-content"}>
                                <FormList model={"translations"}>
                                    <div className="hr-line-dashed"></div>
                                    <FormGroup className="form-group">
                                        <label className="col-sm-2 control-label">Language</label>
                                        <div className="col-sm-10">
                                            <FormInput  model="language" validators={[Validators.required()]} type="text"  className="form-control" />
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <label className="col-sm-2 control-label">Name</label>
                                        <div className="col-sm-10">
                                            <FormInput  model="name" validators={[Validators.required()]} type="text"  className="form-control" />
                                            <span className={"help"}>The name in the default language for this bonus</span>
                                        </div>
                                    </FormGroup>

                                    <FormGroup className="form-group">
                                        <label className="col-sm-2 control-label">Summary</label>
                                        <div className="col-sm-10">
                                            <FormTextArea  model="summary" className="form-control" />
                                            <span className={"help"}>Short description of the bonus</span>
                                        </div>
                                    </FormGroup>

                                    <FormGroup className="form-group">
                                        <label className="col-sm-2 control-label">Description</label>
                                        <div className="col-sm-10">
                                            <FormTextArea  model="description" className="form-control" />
                                            <span className={"help"}>A full description of the bonus</span>
                                        </div>
                                    </FormGroup>

                                    <FormGroup className="form-group">
                                        <label className="col-sm-2 control-label">Image URL</label>
                                        <div className="col-sm-10">
                                            <FormInput  model="imageUrl" type="text"  className="form-control" />
                                            <span className={"help"}>URL to the image displayed on the bonus page (optional)</span>
                                        </div>
                                    </FormGroup>
                                    <div className={"col-sm-10"}>
                                        <BindModel render={(b : ModelBinding<BonusConfig>)=>{
                                            return <a onClick={()=>b.removeCurrentItem()} className={"btn btn-danger"} style={{ float : "right"}}>Remove</a>
                                        }}/>
                                    </div>
                                </FormList>
                                <div className="hr-line-dashed"></div>
                                <div>
                                    <a onClick={()=>this.addTranslation()} className={"btn btn-primary"}>Add Translation</a>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card-box">

                                    <FormSubmitError />
                                    <FormSubmitSuccess text={t(`alertInfo.accountSuccessfully`)}/>
                                    <div className="form-group">
                                        <div className="col-sm-12">
                                            <Submit className="btn  btn-primary">Save</Submit>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }

}
interface Props {
    setToDate : (d : number) => void,
    setFromDate : (d : number) => void,
    fromDate?: Date|number;
    toDate?: Date|number;
}
export class DateRange extends React.Component<Props, { from? : any, to? : any }> {
    to: any = React.createRef<HTMLDivElement>();
    constructor(props : Props) {
        super(props);
        let fromDate = (typeof (this.props.fromDate) == "number" ? new Date(this.props.fromDate) : this.props.fromDate);
        let toDate = (typeof (this.props.toDate) == "number" ? new Date(this.props.toDate) : this.props.toDate);
        this.state = { from : fromDate, to : toDate};
    }

    render() {
        console.log(this.props.fromDate)
        let from = this.state.from;
        let to = this.state.to;
        const modifiers = { start: from, end: to };
        return (
           <Fragment>
                <div style={{display: 'flex'}}>
                    <Datetime onChange={(e) => this.props.setFromDate(Number(e))} defaultValue={new Date()} viewDate={new Date()} dateFormat={"yyyy-MM-DD"} timeFormat={"HH:mm"}/>
                    <Datetime onChange={(e) => this.props.setToDate(Number(e))}  defaultValue={new Date()} viewDate={new Date()} dateFormat={"yyyy-MM-DD"} timeFormat={"HH:mm"}/>
                </div>
           </Fragment>
        )
    }
}

const mapStateToProps = (state : ApplicationState) => {
    return { currencies: state.settings.settings?.currencies }
}

export default connect(mapStateToProps)(withRouter(EditTurnoverContributions));

