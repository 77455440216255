import * as React from "react";

import {RouteComponentProps, withRouter} from "react-router";
import {LoadingViewState, RouterView} from "../RouterView";
import {CasinoGame, CasinoGamesUpdateLog, CasinoStudio, GamesUpdateStatus} from "../../http/protocol";
import {Form, FormListener} from "../form/Form";
import {getContainer} from "../../ioc/IOCSetup";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {AdminCasinoGameUpdateLogService} from "../../services/AdminCasinoGameUpdateLogService";
import {DeleteButtonModal} from "../../util/DeleteButtonModal";

import {formatDate} from "../../util/date";


interface State extends LoadingViewState {
    log? : CasinoGamesUpdateLog;
    validationMap? : any;
}
export class CasinoUpdateLogView extends RouterView<RouteComponentProps,State> implements FormListener<CasinoGame|CasinoStudio>{

    service : AdminCasinoGameUpdateLogService;

    constructor(props : RouteComponentProps){
        super(props);
        this.service = getContainer().getAdminCasinoUpdateLogService();
        this.state = { log : {}, validationMap : {} };
    }

    getTitle(): string {
        return "Casino Update Log";
    }

    componentDidUpdate(prevProps : RouteComponentProps, prevState : State) {
        if((prevProps.match.params as any).id != (this.props.match.params as any).id)  {
            this.refreshContent();
        }
    }
    sortTranslations(s : CasinoGame) {
        if(s.translations) {
            s.translations.sort((a,b)=>{
                if(!a.language) {
                    return 1;
                } else if(!b.language) {
                    return 1;
                } else  if(!a && !b) {
                    return 0;
                }
                return a.language.localeCompare(b.language);
            })
        }
    }
    loadContent(): Promise<any>  {
        return this.service.getEntity((this.props.match.params as any).id).then((s : CasinoGame) => {

            this.setState({ log : s });
        });
    }

    onSubmit()  {

    }
    setAsTimedOut() {
        if (this.state.log?.id) {
            return this.service.setAsTimedOut(this.state.log.id);
        }
        return Promise.reject();
    }
    formDidUpdate(formModel: CasinoStudio, validationMap?: any) {

        this.setState({log : formModel, validationMap : validationMap})
    }


    renderContent() {
        if(!this.state.log ) {
            return <span></span>
        }

        return (
            <div>


                <div className="wrapper wrapper-content animated fadeInRight">
                    <Form className="form form-horizontal" validationMap={this.state.validationMap} modelObject={this.state.log} formListener={this}>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card-box">
                                    <h5>Account Info</h5>
                                    <div className="ibox-content">

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-4 control-label">ID</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="id" type="integer"  className="form-control" disabled={true}/>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-4 control-label">Integration</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="integration" className="form-control" disabled={true}/>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-4 control-label">Status</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="status"  className="form-control" disabled={true}/>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-4 control-label">Batch Id</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="batchId" className="form-control" disabled={true}/>
                                            </div>
                                        </FormGroup>


                                        {this.state.log.status == GamesUpdateStatus.STARTED && (
                                            <div style={{ marginLeft:"15px", marginBottom : "10px"}}>
                                                <DeleteButtonModal className={"btn btn-danger"} label={"Set as timed out"}  onDelete={()=> {
                                                    return this.service.setAsTimedOut(this.state.log!.id!).then(res => {
                                                        this.setState({ log : res});
                                                    });
                                                }} />
                                            </div>


                                        )}

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-4 control-label">Started</label>
                                            <div className="col-sm-10">
                                                <input className="form-control" disabled={true} value={formatDate(this.state.log.started)}/>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-4 control-label">Finished</label>
                                            <div className="col-sm-10">
                                                <input className="form-control" disabled={true} value={formatDate(this.state.log.finished)}/>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-4 control-label">Nr Of Game Errors</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="nrOfGameErrors"  className="form-control" disabled={true}/>
                                            </div>
                                        </FormGroup>
                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-4 control-label">Nr Of Games Removed</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="nrOfGamesRemoved"  className="form-control" disabled={true}/>
                                            </div>
                                        </FormGroup>
                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-4 control-label">Nr Of Games Updated</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="nrOfGamesUpdated"  className="form-control" disabled={true}/>
                                            </div>
                                        </FormGroup>
                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-4 control-label">Nr Of New Games Added</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="nrOfNewGamesAdded"  className="form-control" disabled={true}/>
                                            </div>
                                        </FormGroup>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </Form>
                </div>
            </div>
        );
    }

}

export default withRouter(CasinoUpdateLogView);