import React, {Component} from 'react';
import './App.scss';
import {BrowserRouter as Router} from "react-router-dom";
import {Provider} from "react-redux";
import {configureStore} from "./RootReducer";
import LoginForm from "./login/LoginForm";
import LoggedInContent from "./ui/LoggedInContent";
import {fetchLogoUrl} from "./user/UserActions";
import { fetchAdminSettings } from './settings/SettingsActions';
import { fetchCurrencies } from './wallet/WalletActions';
import './assets/icons/nano-icons.css'
class App extends Component {

    render() {
        return (
            <Provider store={configureStore()}>
                <Router>
                    <FetchContent />
                    <LoginForm />
                    <LoggedInContent />
                </Router>
            </Provider>
        );
  }
}
class FetchContent extends React.Component<{  }, {  }> {
    componentDidMount() {
        fetchLogoUrl()
    }

    render() {
        return <div></div>
    }
}

export default App;

