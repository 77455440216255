import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {HttpService} from "../http/HttpService";
import {ListSystemEventLogsResponse, Payment, SystemEventLog} from "../http/protocol";


export class SystemEventService extends AbstractAdminEntityService<Payment, ListSystemEventLogsResponse> {

    constructor(http: HttpService) {
        super("/system-event", http)
    }

    async getCounts() {
        let url = `${this.entityPath}/counts`;
        return this.http.get(url);
    }

    async resolve(id: number) : Promise<SystemEventLog>{
        return this.http.put(this.entityPath + `/resolve/${id}`, id);
    }
}