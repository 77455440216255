import {AbstractAdminEntityService} from "./AbstractAdminEntityService";

import {HttpService} from "../http/HttpService";
import {Entry, ListEntriesResponse, Transaction} from "../http/protocol";


export class TransactionEntriesService extends AbstractAdminEntityService<Entry, ListEntriesResponse> {

    constructor(http : HttpService) {
        super("/wallet/entries", http);
    }

    getTransaction(id : string) : Promise<Transaction> {
        return this.http.get("/wallet/transaction/" + id);
    }
}