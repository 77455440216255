

export function  removeItemFromArray<E>(arr : E[], func : (item : E) => boolean) : E[] {
    console.log("REMOVE from ", arr);
    let newArr = arr.concat();
    let index = newArr.findIndex(func);
    console.log("INDEX FOUND", index);
    if(typeof(index) != "undefined" && index >= 0) {
        newArr.splice(index,1);
        return newArr;
    }
    return arr;
}
export function moveItem<T>(inputs : T[], from: number, to : number)  {
    inputs = inputs.concat();
    if (to >= inputs.length) {
        var k = to - inputs.length + 1;
        while (k--) {
            inputs.push({} as any);
        }
    }
    inputs.splice(to, 0, inputs.splice(from, 1)[0]);
    return inputs;
}
export function  changePosition<E>(arr : E[], func1 : (item : E) => boolean , func2 : (item : E) => boolean ) : E[] {
    let newArr = arr.concat();
    let index1 = newArr.findIndex(func1);
    let index2 = newArr.findIndex(func2);


    console.log("INDEX1 FOUND", index1);
    console.log("INDEX2 FOUND", index2);
    if(typeof(index1) != "undefined" && index1 >= 0 && typeof(index2) != "undefined" && index2 >= 0) {
        let element = newArr[index1];
        newArr.splice(index1, 1);
        newArr.splice(index2, 0, element);

        return newArr;
    }
    return arr;
}