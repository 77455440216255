import React, {Fragment} from "react";
import {ActionType} from "../list/list/DataTable";
import {
    CasinoGame,
    CasinoStudio,
    GameTag,
    ListCasinoGameRequest,
    ListCasinoLobbyCategoryRequest,
    ListStudioRequest
} from "../../http/protocol";
import {getContainer} from "../../ioc/IOCSetup";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {PagerModel} from "../list/list/PagerModel";
import {ListUtils} from "../list/list/ListUtils";
import {withRouter} from "react-router";
import {ListView, ListViewProps, ListViewState} from "../ListView";
import {Form, FormListener} from "../form/Form";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Validators} from "../form/Validators";
import {Submit} from "../form/Submit";
import {CasinoGameService} from "../../services/CasinoGameService";
import {formatInstant} from "../../util/date";
import {SelectInput} from "../form/SelectInput";
import {CasinoStudioService} from "../../services/CasinoStudioService";
import {CasinoGameTagService} from "../../services/CasinoGameTagService"


interface State extends ListViewState<ListCasinoLobbyCategoryRequest> {
    form? : ListCasinoGameRequest;
    validationMap? : any;
    studioList: string[];
    integrations: string[];
    gameTagList: GameTag[];
    noOfStudios:number;
}
interface Props extends ListViewProps  {

}

function toTranslations( i : CasinoGame) {
    if(!i.translations) {
        return  "";
    } else {
        let trans = i.translations.concat()
        trans.sort((a,b)=>{
            if(!a || !a.language) {
                return -1;
            }
            if(!b || !b.language) {
                return 1;
            }
            return a.language.localeCompare(b.language)
        })
        return trans.map(t => t.language).join(", ");
    }
}

function renderJurisdictions(i: CasinoGame): string {
    return i.jurisdictions && i.jurisdictions.length > 0 ? i.jurisdictions.map(j => j.name).join(", ") : "No Jurisdictions";
}
export class CasinoGameListView extends ListView<State, ListCasinoGameRequest> implements FormListener<ListCasinoGameRequest>{

    columns = [
        { property : "id", name : "ID", orderBy : "id"},
        { property : "externalId", name : "External Id", orderBy : "externalId"},
        { property : "name", name : "Name", orderBy : "name"},
        { property : "integration", name : "Integration"},
        { property : "studio.name", name : "Studio"},
        { property : "releaseDate", name : "Release Date", orderBy:'releaseDate',  renderer :  (p : string,n : string,i : CasinoGame)=> formatInstant(i.releaseDate, "YYYY-MM-DD")},
        { property : "translations", name : "Translations", renderer : (p : string, n : string, i : CasinoGame) =>  toTranslations(i)},
        { property : "enabled", name : "Enabled", renderer : (p : string,n : string,i : any) => i[p] == true ? <span className={"green"}>Yes</span> :  <span className={"red"}>No</span>},
        { property : "removed", name : "Removed", renderer : (p : string,n : string,i : any) => i[p] == true ? <span className={"red"}>Yes</span> :  <span className={"green"}>No</span>},
        { property : "freeSpinsSupported", name : "freeSpinsSupported", renderer : (p : string,n : string,i : any) => i[p] == true ? <span className={"green"}>Yes</span> :  <span className={"red"}>No</span>},
        { property : "studioEnabled", name : "Studio Enabled", renderer : (p : string,n : string,i : any) => i.studio?.enabled == true ? <span className={"green"}>Yes</span> :  <span className={"red"}>No</span>},
        { property : "jurisdictions", name: "Jurisdictions", renderer: (p: string, n: string, i: CasinoGame) => renderJurisdictions(i)}
    ];

    actions = [
        { id : "view", name : "View", className : "btn btn-primary", iconClassName : "fa fa-user", type : ActionType.LINK, getLink : (item : any) => "/casino-game/" + item.id }

    ];

    service : CasinoGameService;
    studioService : CasinoStudioService;
    gameTagService : CasinoGameTagService

    constructor(props : Props){
        super(props, "/casino-games");

        this.state = {...this.state,  listRequest : {...this.state.listRequest, orderBy : "id", includeDisabledGames : false} as ListCasinoGameRequest, validationMap : {}, form : {}, studioList:[], integrations:[], noOfStudios:0};
        this.service = getContainer().getCasinoGameService();
        this.studioService = getContainer().getCasinoStudioService();
        this.gameTagService = getContainer().getCasinoGameTagService()
    }
    loadContent(): Promise<any> | undefined {
        return this.studioService.list({limit:500}).then((res) => {
            let studioNames:any = []
            let integrations:any = []
            let gameTags:any = []
            res && res.items && console.log("number of studios from list response: ", res.items.length)
            if(res.items && res.items.length > 0) {
                studioNames = res.items.map((item:CasinoStudio) => {
                    console.log("ADDING: ", item.name, " to state!")
                    if(item.name) {
                        return item.name.toUpperCase();
                    }
                })
                integrations = res.items.map((item:CasinoStudio) => {
                    console.log("ADDING: ", item.integration, " to state!")
                    if(item.integration) {
                        return item.integration;
                    }
                })
                this.loadGameTags();
                studioNames.sort();
                integrations.sort();
                let uniqueStudios = studioNames.filter((item:any, pos:any) => {
                    return studioNames.indexOf(item) == pos;
                })
                let uniqueIntegrations = integrations.filter((item:any, pos:any) => {
                    return integrations.indexOf(item) == pos;
                })
                let uniqueGameTags = gameTags.filter((item:any, pos:any) => {
                    return gameTags.indexOf(item) == pos;
                })
                this.setState({studioList:uniqueStudios, noOfStudios:uniqueStudios.length, integrations:uniqueIntegrations, gameTagList:uniqueGameTags})
            }
        })
    }

    async loadGameTags(): Promise<void> {
        try {
            const res = await this.gameTagService.list({limit: 500});
            if(res.items) {
                res.items.sort((a:GameTag, b: GameTag) => (a.name || "").localeCompare(b.name || ""))
            }
            this.setState({gameTagList: res.items ?? []});

        } catch (error) {
            console.error("Failed to load game tags:", error);
        }
    }

    onSubmit() : void | Promise<any> {
        if(this.state.form) {
            let form = this.state.form;
            form.offset = 0;
            this.updateListState(form);
        }
    }
    onSubmitError() :  void {

    }

    formDidUpdate(formModel: ListStudioRequest, validationMap?: any): void {
        this.setState({form : formModel, validationMap : validationMap});
    }

    onAction(item : CasinoStudio, action : string) : Promise<any> | undefined {
        return;
    }

    pagerChanged(pager : PagerModel) {
        if(this.state.listRequest) {
            console.log("pager changed!", pager);
            let listRequest = ListUtils.toListRequest<any>(this.state.listRequest,pager);
            this.updateListState(listRequest);
        }
    }
    getTitle() {
        return "Casino Games";
    }

    renderContent() {
        if(!this.state.listRequest) {
            return <div/>;
        }
        return (
            <Fragment>
                <div className={"card-box"}>
                    <h4>Search Filters</h4>
                    <Form formListener={this} modelObject={this.state.form} validationMap={this.state.validationMap}>
                        <FormGroup className={"form-group"}>
                            <label>Game Name</label>
                            <FormInput model={"name"} className={"form-control"} validators={[Validators.strLength(1,255)]}/>
                            <span className={"help-text"}>Search by name</span>
                        </FormGroup>
                        <FormGroup className={"form-group"}>
                            <label>Studios</label>
                            <SelectInput size={this.state.studioList.length > 6 ? 6 : this.state.studioList.length} className="form-control" model="studioNames" optional={true} multiple={true} values={this.state.studioList}  />
                            <span className={"help-text"}>Filter by studios - (Ctrl + LM Click) to de-select</span>
                        </FormGroup>
                        <FormGroup className={"form-group"}>
                            <label>Integrations</label>
                            <SelectInput size={this.state.integrations.length > 6 ? 6 : this.state.integrations.length} className="form-control" model="integrations" optional={true} multiple={true} values={this.state.integrations}  />
                            <span className={"help-text"}>Filter by studios - (Ctrl + LM Click) to de-select</span>
                        </FormGroup>
                        <FormGroup className={"form-group"}>
                            <label>Game Tags</label>
                            <SelectInput type={"integer"} optionModelValue={(value:any) => value?.id} displayValue={(value:any) => value?.name} size={this.state.gameTagList.length > 6 ? 6 : this.state.gameTagList.length} className="form-control" model="gameTags" optional={true} multiple={true} values={this.state.gameTagList} />
                            <span className={"help-text"}>Filter by game tags - (Ctrl + LM Click) to de-select</span>
                        </FormGroup>
                        <FormGroup className={"form-group"}>
                            <FormInput type={"checkbox"} model={"includeDisabledGames"} name={"includeDisabledGames"}/> <label htmlFor={"includeDisabledGames"}>Include Disabled Games</label>
                            <br/>
                            <span className={"help-text"}>Include games that are disabled (enabled = false, removed = true) & games of studios that are disabled</span>
                        </FormGroup>
                        <Submit id={"studios"} className={"btn btn-primary"}>Search</Submit>
                    </Form>
                </div>
                <EntityDataTable service={this.service}
                                 onPagerChange={this.pagerChanged.bind(this)}
                                 columns={this.columns}
                                 listRequest={this.state.listRequest}
                                 onAction={this.onAction.bind(this)}
                                 actions={this.actions}/>
            </Fragment>
        );
    }



}

export default withRouter(CasinoGameListView);
