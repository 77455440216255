import React, {Fragment} from "react";
import {LoadingViewState, RouterView} from "../RouterView";
import {JsonDocument, JsonDocumentUpdateResult, Settings} from "../../http/protocol";
import {SettingsService} from "../../services/SettingsService";
import {getContainer} from "../../ioc/IOCSetup";
import {RouteComponentProps, withRouter} from "react-router";
import {DeleteButtonModal} from "../../util/DeleteButtonModal";
import { Alert } from "../form/Alert";
import { SettingsAdvancedVisualEditor } from "./SettingsAdvancedVisialEditor";


interface Props extends RouteComponentProps<{config? : string}> {

}




interface State extends LoadingViewState {
    settings?: Settings;
    validationMap? : any;
    // documents:JsonDocument[];
    docs: {key: string, id: number}[];
    activeDocument: JsonDocument;
    activeDocumentSchema?: any;
    activeDocumentVersions?: number[];
    unSavedDoc: boolean;
    validJSON:boolean;
    initialDocument?: string;
    result?: JsonDocumentUpdateResult;
    visalEditor?: boolean;
}

export class SettingsAdvancedView extends RouterView<Props,State> {

    settingsService: SettingsService;

    constructor(props:Props) {
        super(props);

        this.settingsService = getContainer().getSettingsService();

        this.state = {validationMap : {}, loading : true, docs:[], activeDocument:{}, unSavedDoc:false, validJSON:true, initialDocument : props.match.params.config, visalEditor: false};
    }

    async loadContent() {

        const allDocs = (await this.settingsService.getDocuments()).map(d => { return {id: d.id!, key: d.akey!}})

        this.setState({docs: allDocs, activeDocument: {}, unSavedDoc:false, validJSON:true, initialDocument: undefined})

        let activeKey = this.state.initialDocument

        if (!activeKey) {
            activeKey = allDocs[0].key
        }

        this.handleDocChange(activeKey)

/*
        return this.settingsService.getDocuments().then((data : JsonDocument[]) => {
            if(data) {
                let ad = undefined;
                if(this.state.initialDocument) {
                    ad = data.find(key => key.akey == this.state.initialDocument);
                }
                if(!ad){
                    ad =  this.state.activeDocument.json ?  data.find(key => key.akey === this.state.activeDocument.akey) || this.state.activeDocument : data[0];
                }
   
                this.settingsService.getSchema(ad.akey!).then(schema => {
                    this.setState({activeDocumentSchema: schema})
                }).catch(e => this.setState({activeDocumentSchema: undefined}))

                this.settingsService.getDocumentVersions(ad.akey!).then(versions => {
                    this.setState({activeDocumentVersions: versions})
                }).catch(e => this.setState({activeDocumentVersions: undefined}))

                this.setState({documents: data, activeDocument: ad, unSavedDoc:false, validJSON:true, initialDocument: undefined});
            }

        });
        */
    }

    formatJSON(e?:any) {
        if(e) {
            e.preventDefault();
        }
        let activeJSON = JSON.stringify(JSON.parse(this.state.activeDocument.json||""), null, 2)
        console.log("Format", activeJSON)
        this.setState({activeDocument:{...this.state.activeDocument, json:activeJSON}})
    }

    async onSubmit() {
        let docToSave = this.state.activeDocument;


        this.setState({loading: true});

        var res
        
        try {
            res = await this.settingsService.saveDocument(docToSave)
        } catch (e) {
            if ((e as any).status == 400) {
                return Promise.reject("Unable to save document. Check that the JSON is valid.")
            } else {
                throw e
            }            
        }

        
        if (res.updateResult == "SUCCESS") {
            const updatedDoc = await this.settingsService.getDocument(docToSave.akey!)
            const versions = await this.settingsService.getDocumentVersions(docToSave.akey!)

            this.setState({unSavedDoc:false, activeDocument:updatedDoc, activeDocumentVersions: versions, validJSON:true});
        } else if (res.updateResult == "NO_CHANGES") {
            this.setState({unSavedDoc:false});
        }

        this.setState({loading: false, result: res});

    }


    getTitle() : string {
        return "Advanced Settings";
    }


    async handleDocChange(key: string, version?: number) {

        this.setState({visalEditor: false})

        const active = await this.settingsService.getDocument(key, version)

        this.settingsService.getSchema(active.akey!).then(schema => {
            this.setState({activeDocumentSchema: schema})
        }).catch(e => this.setState({activeDocumentSchema: undefined}))

        this.settingsService.getDocumentVersions(active.akey!).then(versions => {
            this.setState({activeDocumentVersions: versions})
        }).catch(e => this.setState({activeDocumentVersions: undefined}))

        this.setState({activeDocument: active, unSavedDoc:false, validJSON:true, result: undefined})
    }

    async handleVersionChange(version: number) {
        this.setState({loading: true})
        const updatedDoc = await this.settingsService.getDocument(this.state.activeDocument.akey!, version)
        this.setState({loading: false, unSavedDoc:false, activeDocument:updatedDoc, validJSON:true, result: undefined});
    }


    handleEdit(event:any) {
        let doc = this.state.activeDocument;
        doc.locked = true;
        doc.json = event.target.value;
        doc && doc.json && this.setState({activeDocument:doc, unSavedDoc:true, validJSON:this.isJSON(doc.json)})

    }
    isJSON(json:string):boolean {
        try {
            let parse = JSON.parse(json);
            if(parse) {
                return true
            } else return false;
        } catch (e) {
            return false;
        }
    }

    async handleLock(e:any) {
        e.preventDefault();
        const locked = !this.state.activeDocument.locked
        this.setState({activeDocument:{...this.state.activeDocument, locked:locked}})
        await this.settingsService.setDocumentLocked(this.state.activeDocument.akey!, locked);
    }

    resetDoc(e:any) {
        e.preventDefault();
        // const doc = this.settingsService.getDocument(this.state.activeDocument.akey!, this.state.activeDocument.version)
        this.handleDocChange(this.state.activeDocument.akey!, this.state.activeDocument.version)
    }

    renderMessage() {

        if (!this.state.result) {
            return;
        }

        const res = this.state.result
        var tp = "success"
        var msg = "Saved document"
        const items = res.messages?.map(e => <li>{e}</li>) ?? []

        if (res.updateResult == "SUCCESS") {

            if (res.validationResult == "VALIDATED") {
                msg = "Saved document. Validatiton Passed!"
            } else {
                var tp = "warning"
                msg = "Saved document with validation warning: " + res.validationResult
            }
        } else if (res.updateResult == "NO_CHANGES") {
            var tp = "info"
            msg = "Not saved, no changes found."
        } else if (res.updateResult == "LOCKED") {
            var tp = "warning"
            msg = "Unable to save locked document."
        } else if (res.updateResult == "VALIDATION_ERROR") {
            var tp = "danger"
            msg = "Validation error while saving document: " + res.validationResult
        }

        return (
            <div className={`alert alert-${tp}`}>
                <strong>{msg}</strong>
                {items.length > 0  &&
                    <ul>    
                        {items}
                    </ul>
                }
            </div>
        )
    }

    renderContent() {

        const latestVersion = this.state.activeDocumentVersions?.[0] ?? 0

        return(
            <Fragment>
                <form>
                    <div className={"card advanced-settings"}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">

                                    {this.renderMessage()}

                                    <label>Configs</label>
                                    <select disabled={this.state.unSavedDoc} value={this.state.activeDocument.akey} className="form-control" onChange={(e) => this.handleDocChange(e.target.value)}>
                                        {this.state.docs.map((doc) => {
                                            return <option value={doc.key} key={doc.key}>{doc.key}</option>
                                        })}
                                    </select>
                                    <span className={"help-block"}>Select a configuration file and edit it below. </span>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    {!this.state.activeDocument.validated  &&  <p className="text-warning"><strong>Caution:</strong> This document lacks a schema and can not be validated on submit.</p>}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-4">
                                    <p>
                                        <select value={this.state.activeDocument.version} className="form-control" onChange={(e) => this.handleVersionChange(Number(e.target.value))}>
                                            {(this.state.activeDocumentVersions ?? []).map((v) => {
                                                return <option value={v} key={v}>{`Version: ${v} ${(v == latestVersion) ? '(latest)' : ''}`}</option>
                                            })}
                                        </select>
                                    </p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <h5>Document: {this.state.activeDocument.akey}, version {this.state.activeDocument.version}</h5>

                                    { !this.state.visalEditor &&  
                                        <>
                                            <textarea spellCheck={false} className="form-control json-editor" onChange={(e) => this.handleEdit(e)} value={this.state.activeDocument.json} />
                                            <button disabled={!this.state.validJSON} className="btn btn-primary" style={{marginTop:"4px"}} onClick={(e) => this.formatJSON(e)}>Format</button>
                                        </>
                                    }

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    {!this.state.validJSON && (
                                        <span style={{right:"200px", bottom:"10px", position:"absolute", color:"red"}}>JSON is not valid</span>
                                    )}
                                </div>
                            </div>
                            <div className="row" style={{paddingTop:"2rem"}}>
                                <div className="col-lg-12" style={{marginBottom:"1rem"}}>
                                    <button className="btn btn-light" title={this.state.activeDocument.locked ? "Locked" : "Unlocked"} onClick={(e) => this.handleLock(e)}>{this.state.activeDocument.locked ? <i className="fa fa-lock" style={{color:"green"}}/> : <i className="fa fa-unlock" style={{color:"red"}}/>}</button>
                                    <span style={{marginLeft:"1rem"}}>{this.state.activeDocument.locked ? "Locked":"Unlocked"}</span>
                                    <br/><span className={"help-block"}>You can lock/unlock the document with the lock icon. Locking a config prevents it from reverting back to default.</span>
                                </div>
                                <div className="col-lg-12">
                                    <DeleteButtonModal className={"btn btn-primary"} disabled={!this.state.validJSON} onDelete={() => this.onSubmit()} label={"Save Document"} description={""} />
                                    <button className="btn btn-secondary" style={{marginLeft:"1rem"}} onClick={(e) => this.resetDoc(e)}>Reset</button>
                                </div>
                                <div className="col-lg-12">
                                    {this.state.activeDocument.akey && this.state.unSavedDoc ? <span className="text-danger">Unsaved changes!</span> : ""}


                                    { this.state.activeDocument.validated  &&
                                            <div className="text-right" style={{marginTop: "10px", cursor: "pointer", opacity: "0.2"}} 
                                                onClick={(e) => {e.preventDefault(); this.setState({visalEditor: !this.state.visalEditor})}}>View in Visual Editor (alfa preview)</div>
                                    }




                                </div>

                            </div>
                        </div>
                    </div>

                </form>

                { this.state.visalEditor &&
                    <div className={"card advanced-settings"}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                        <SettingsAdvancedVisualEditor document={this.state.activeDocument} schema={this.state.activeDocumentSchema}/>
                                </div>
                            </div>
                        </div>
                    </div>
                }



            </Fragment>
        )
    }

}
export default withRouter(SettingsAdvancedView);