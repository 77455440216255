import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {
    CasinoGamesUpdateLog,
    CasinoPage,
    ListCasinoGamesUpdateLogResponse,
    ListCasinoPageRequest,
    ListCasinoPageResponse
} from "../http/protocol";
import {HttpService} from "../http/HttpService";



export class AdminCasinoGameUpdateLogService extends AbstractAdminEntityService<CasinoGamesUpdateLog, ListCasinoGamesUpdateLogResponse> {

    constructor(http : HttpService) {
        super("/casino-update-log",http);
    }
    setAsTimedOut(id : number) : Promise<CasinoGamesUpdateLog> {
        return this.http.get("/casino-update-log/set-timed-out/"+id);
    }
}