import { HttpService } from "../http/HttpService";
import {
    UserBonusEvent,
    UserBonusEventListResponse
} from "../http/protocol";
import { AbstractAdminEntityService } from "./AbstractAdminEntityService";


export class UserBonusEventService extends AbstractAdminEntityService<UserBonusEvent, UserBonusEventListResponse> {
    constructor(http: HttpService) {
        super("/user/bonus/event", http)
    }
}


