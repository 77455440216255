import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {AdminNote, ListAdminNotesResponse} from "../http/protocol";
import {HttpService} from "../http/HttpService";


export class AdminNotesService extends AbstractAdminEntityService<AdminNote, ListAdminNotesResponse> {

    constructor(http: HttpService) {
        super("/notes", http);
    }

}