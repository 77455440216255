import * as React from "react";
import * as _ from "lodash";
import {FieldSet} from "./Form";

export interface SubmitProps extends React.ButtonHTMLAttributes<any>{
    fieldSet? : FieldSet;
    progressClass? : string;
}

export class Submit extends React.Component<SubmitProps,{}> {

    getLoadingSpinner() {
        if(this.props.fieldSet && this.props.fieldSet.isSubmitting()) {
            return (
                <div className="sk-spinner sk-spinner-circle">
                    <div className="sk-circle1 sk-circle"></div>
                    <div className="sk-circle2 sk-circle"></div>
                    <div className="sk-circle3 sk-circle"></div>
                    <div className="sk-circle4 sk-circle"></div>
                    <div className="sk-circle5 sk-circle"></div>
                    <div className="sk-circle6 sk-circle"></div>
                    <div className="sk-circle7 sk-circle"></div>
                    <div className="sk-circle8 sk-circle"></div>
                    <div className="sk-circle9 sk-circle"></div>
                    <div className="sk-circle10 sk-circle"></div>
                    <div className="sk-circle11 sk-circle"></div>
                    <div className="sk-circle12 sk-circle"></div>
                </div>
            )
        }
    }

    render() {
        let rest: React.ButtonHTMLAttributes<any> = _.omit(this.props,['inProgress', 'progressClass','model','fieldSet', 'modelValue','valid', 'validationMap']);
        return <button type="submit"  {...rest}>
                <span>{this.props.children}</span>
                {this.getLoadingSpinner()}
            </button>
    }
}