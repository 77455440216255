import React, {Fragment} from "react";
import {SystemEventLog} from "../../http/protocol";
import {getContainer} from "../../ioc/IOCSetup";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {LoadingViewState, RouterView} from "../RouterView";
import {formatDate} from "../../util/date";
import {LoadingSpinner} from "../../util/LoadingSpinner";
import {SystemEventService} from "../../services/SystemEventService";
import {ConfirmModal} from "../../util/ConfirmModal";
import {FormGroup} from "react-bootstrap";


interface State extends LoadingViewState {
    systemEventLog?: SystemEventLog;
    showResolveDialog?: boolean;
}

interface Props extends RouteComponentProps {
    params: { id: string }
}

export class SystemEventLogView extends RouterView<Props, State> {

    systemEventService: SystemEventService;

    constructor(props: Props) {
        super(props);
        this.systemEventService = getContainer().getSystemEventService();
        this.state = {systemEventLog: {}, showResolveDialog: false, loading: false};
    }

    loadContent(): Promise<any> | void {
        let id = (this.props.match.params as any).id;
        this.systemEventService.getEntity(id).then((s: SystemEventLog) => {
            this.setState({systemEventLog: s, loading: false})
        });

    }

    getTitle(): string {
        return "System Event Detail";
    }

    renderContent() {
        if (!this.state.systemEventLog) {
            return <LoadingSpinner/>;
        }

        let s = this.state.systemEventLog;
        return (
            <Fragment>
                <ConfirmModal
                    show={this.state.showResolveDialog}
                    heading="Mark Event As Resolved"
                    description={`Event will be marked as resolved`}
                    okLabel="Resolve"
                    okClassName="btn-danger"
                    onOk={this.resolve.bind(this)}
                    onCancel={() => this.setState({showResolveDialog: false})}
                />

                <div>
                    <div className="wrapper wrapper-content animated">
                        <div className="row">
                            <div className="col-lg-12   ">
                                <div className={"card"}>
                                    <div className="card-body">
                                        <div className="ibox-content">
                                            <table className="table m-b-xs table-striped">
                                                <tbody>
                                                <tr>
                                                    <td className="col-md-4"><strong>ID:</strong></td>
                                                    <td className="col-md-8">{s.id}</td>
                                                </tr>
                                                {s.timestamp && (
                                                    <tr>
                                                        <td className="col-md-4"><strong>Timestamp:</strong></td>
                                                        <td className="col-md-8">{formatDate(Number(s.timestamp.toString().split(".").join("").substring(0, 13)))}</td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <td className="col-md-4"><strong>Type:</strong></td>
                                                    <td className="col-md-8">{s.subType}</td>
                                                </tr>
                                                <tr>
                                                    <td className="col-md-4"><strong>Level:</strong></td>
                                                    <td className="col-md-8">{s.level}</td>
                                                </tr>
                                                <tr>
                                                    <td className="col-md-4"><strong>Resolved:</strong></td>
                                                    <td className="col-md-8">{s.resolved && "Yes"}{!s.resolved && "NO"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="col-md-4"><strong>Message:</strong></td>
                                                    <td className="col-md-8">{s.message}</td>
                                                </tr>
                                                <tr>
                                                    <td className="col-md-4"><strong>Attributes:</strong></td>
                                                    <td className="col-md-8">
                                                        <pre>{JSON.stringify(s.attributes, null, 2)}</pre>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.systemEventLog && !this.state.systemEventLog.resolved && <div className="row">
                    <div className="col-lg-12">
                        <div className={"card"}>
                            <div className="card-header">
                                <h4>Actions</h4>
                            </div>
                            <div className="card-body">
                                <div>
                                    <FormGroup className="form-group">
                                        <div>
                                            <button className="btn btn-spacing btn-danger"
                                                    onClick={() => this.setState({showResolveDialog: true})}>Mark as
                                                resolved
                                            </button>
                                        </div>
                                    </FormGroup>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>}
            </Fragment>
        )
    }

    
    resolve() {
        if (this.state.systemEventLog && this.state.systemEventLog.id) {
            this.setState({showResolveDialog: false, loading: true});
            this.systemEventService.resolve(this.state.systemEventLog.id).then((response) => {
                this.setState({systemEventLog: response, loading: false})
            });
        }
    }

}

export default withRouter(SystemEventLogView);
