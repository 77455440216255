import React, { Fragment, useState } from "react";
import { ListView, ListViewProps, ListViewState, createInitialState } from "../ListView";
import { GeneralReportRequest, PayoutsListRequest, User } from "../../http/protocol";
import { getContainer } from "../../ioc/IOCSetup";
import { AffiliatesReportService } from "../../services/AffiliatesReportService";
import { RouterView } from "../RouterView";
import ListFilter, { ListFilters } from "../list/list/ListFilter";
import moment from "moment";
import DateFilter from "../list/list/DateFilter";
import Pagination from "../list/list/Pagination";
import { orderBy } from "lodash";

interface State extends ListViewState<GeneralReportRequest> {
  listRequest?: GeneralReportRequest;
  form: any;
  detailedReportList: any;
  validationMap?: any;
  range: any;
  paginate: any;
  details: any;
  trackers: any;
  groupByTracker: boolean;
  trackerCode: any;
  status: any;
  period: string;
  orderBy:any;
}

interface Props extends ListViewProps {}

export enum DateFormat {
  YEARLY = "YYYY",
  MONTHLY = "YYYY-MM",
  DAILY = "YYYY-MM-DD",
  WEEKLY = "YYYY-MM-DD",
  STATIC = "yyyy-MM-dd'T'HH:mm:ss",
}

export enum DatePeriod {
  YEARLY = "P1Y",
  MONTHLY = "P1M",
  DAILY = "P1D",
  WEEKLY = "P7D",
}

export const formatPeriod = (option: string): DatePeriod => {
  let period: any;
  Object.keys(DatePeriod).map((key) => {
    if (key.toLowerCase() == option.toLowerCase()) {
      // @ts-ignore
      period = DatePeriod[key];
    }
  });
  return period;
};
const filterOptions = {
  [ListFilters.LIMIT]: [10, 30, 100, 500],
  [ListFilters.VIEW]: ["Yearly", "Monthly", "Weekly", "Daily"],
  [ListFilters.ORDERBY]: ['depositsum', 'ftd', 'depositors', 'activeplayers','ggr','ngr','commission','date']
};
const now = new Date();
const utc_timestamp = now.toLocaleString("en-US", { timeZone: `Europe/Lisbon` });
let listRequest = {
  from: moment(new Date()).startOf("month").format(DateFormat.WEEKLY),
  to: moment(new Date(utc_timestamp)).format(DateFormat.WEEKLY),
  period: "P1D",
  limit: 30,
  offset: 0,
  currentIndex:1,
  tracker: null,
  groupByTracker: false,
  orderBy:null
};
export default class AffiliatesDetailedReportView extends RouterView<Props, State> {
  service: AffiliatesReportService;
  constructor(props: Props) {
    super(props);

    this.service = getContainer().getAffiliatesReportService();

    this.state = {
      ...this.state,
      validationMap: {},
      form: { ...listRequest },
      range: {
        from: moment(new Date()).startOf("month").format(DateFormat.WEEKLY),
        to: moment(new Date(utc_timestamp)).format(DateFormat.WEEKLY),
      },
      details: undefined,
      trackers: undefined,
      groupByTracker: false,
      paginate: { ...listRequest },
      status: undefined,
      period: "Daily",
      orderBy:undefined
    };
  }
  componentDidUpdate(prevProps: Props, prevState: State): void {
    if (
      this.state.period !== prevState.period ||
      this.state.trackers !== prevState.trackers ||
      this.state.groupByTracker !== prevState.groupByTracker ||
      this.state.range !== prevState.range ||
      this.state.paginate !== prevState.paginate ||
      this.state.orderBy !== prevState.orderBy
    ) {
      listRequest = {
        from: moment(new Date(this.state.range.from)).format(DateFormat.WEEKLY),
        to: moment(new Date(this.state.range.to)).format(DateFormat.WEEKLY),
        period: formatPeriod(this.state.period),
        limit: this.state.paginate.limit ? this.state.paginate.limit : 30,
        offset: this.state.paginate.offset,
        currentIndex:this.state.paginate.currentIndex ? this.state.paginate.currentIndex:1,
        tracker: this.state.trackers,
        groupByTracker: this.state.groupByTracker,
        orderBy:this.state.orderBy
      };
      this.setState({ form: listRequest });
    }
    if (this.state.form.from !== prevState.form.from || 
      this.state.form.to !== prevState.form.to || 
     this.state.form.period !== prevState.form.period ||
     this.state.form.limit !== prevState.form.limit ||
     this.state.form.tracker !== prevState.form.tracker ||
     this.state.form.groupByTracker !== prevState.form.groupByTracker ||
     this.state.form.orderBy !== prevState.form.orderBy) {
      this.loadContent();
    }
  }

  onAction(item: User, action: string): Promise<any> | undefined {
    return;
  }

  getTitle() {
    return "Affiliates Detailed Report View";
  }
  loadContent(): Promise<any> | undefined {
    let id = (this.props.match.params as any).id;

    return (
      id &&
      this.service
        .getAffiliateDetailedReport(id, this.state.form)
        .then((data: any) => {
          console.log("Affiliates Detailed Report ", data?.items);
          this.setState({ detailedReportList: data });
        })
        .catch((e) => {
          console.log("failed to get affiliate player list`: ", e);
        })
    );
  }

  renderContent() {
    const options = {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
    };
    let numberFormater = new Intl.NumberFormat("en-US", options);
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <div className="card-box">
              <div className="d-flex align-items-center">
                <DateFilter range={this.state.range} setRange={(range: any) => this.setState({ range })} />
                <div className="mr-auto p-2">
                  <ListFilter
                    viewBy={true}
                    setViewBy={(period: any) => this.setState({ period })}
                    setFilter={(paginate: any) => this.setState({ paginate })}
                    filterOptions={filterOptions}
                    setTracker={(trackerCode: any) => this.setState({ trackerCode })}
                    currentTracker={this.state.trackerCode}
                    setOrderBy={(orderBy: any) => this.setState({ orderBy })}
                    trackers={
                      this.state.trackers &&
                      this.state.trackers.items.map((p: any) => ({ label: p.code, value: p.code }))
                    }
                    setGroupByTracker={(groupByTracker: any) => this.setState({ groupByTracker })}
                    groupByTracker={this.state.groupByTracker}
                  />
                </div>
              </div>
              <div className="table-responsive">
                <table className={"table table-striped mb-0 dt-responsive dataTable no-footer dtr-inline"}>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Unique Clicks</th>
                      <th>Signups</th>
                      <th>FTDs</th>
                      <th>Qualified FTDs</th>
                      <th>Depositors</th>
                      <th>Active Players</th>
                      <th>Total Deposit</th>
                      <th>Gross Revenue</th>
                      <th>Net Revenue</th>
                      <th>RS Commission</th>
                    </tr>
                  </thead>
                
                  <tbody>
                    {this.state.detailedReportList && this.state.detailedReportList.items.length > 0 ? (
                      this.state.detailedReportList.items.slice((this.state.paginate?.currentIndex - 1) * this.state.paginate?.limit, (this.state.paginate?.currentIndex - 1) * this.state.paginate?.limit + this.state.paginate?.limit).map((detailedReportList: any) => {
                        return (
                          <tr key={detailedReportList.date}>
                            <td>{detailedReportList.date}</td>
                            <td>{detailedReportList.uniqueClicks}</td>
                            <td>{detailedReportList.signups}</td>
                            <td>
                              {detailedReportList.newDepositingPlayers && detailedReportList.newDepositingPlayers
                                ? detailedReportList.newDepositingPlayers
                                : 0}
                            </td>
                            <td>
                              {detailedReportList.cpaSum && detailedReportList.cpaSum ? detailedReportList.cpaSum : 0}
                            </td>
                            <td>
                              {detailedReportList.depositingPlayers && detailedReportList.depositingPlayers
                                ? detailedReportList.depositingPlayers
                                : 0}
                            </td>
                            <td>
                              {detailedReportList.activePlayers && detailedReportList.activePlayers
                                ? detailedReportList.activePlayers
                                : 0}
                            </td>
                            <td>
                              {detailedReportList.depositSum && detailedReportList.depositSum
                                ? numberFormater.format(detailedReportList.depositSum)
                                : numberFormater.format(0)}
                            </td>
                            <td>
                              {detailedReportList.ggr && detailedReportList.ggr
                                ? numberFormater.format(detailedReportList.ggr)
                                : numberFormater.format(0)}
                            </td>
                            <td>
                              {detailedReportList.netRevenue && detailedReportList.netRevenue
                                ? numberFormater.format(detailedReportList.netRevenue)
                                : numberFormater.format(0)}
                            </td>
                            <td>
                              {detailedReportList.commission && detailedReportList.commission
                                ? numberFormater.format(detailedReportList.commission)
                                : numberFormater.format(0)}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={12}>
                          <div className="empty-table">
                            <div
                              style={{ marginLeft: "10px" }}
                              className="empty-table-txt ps-5 fs-6 fw-normal text-gray-600"
                            >
                              We are experiencing server problem at the moment. Try again soon!
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <Pagination paginate={this.state.paginate} setPaginate={(paginate: any) => this.setState({ paginate })} nrOfResults={this.state.detailedReportList && this.state.detailedReportList.nrOfResults} />
       
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
