import {HttpService} from "../http/HttpService";
import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {BonusTrigger, BonusTriggerListResponse} from "../http/protocol";


export class OfferRuleService  extends AbstractAdminEntityService<BonusTrigger, BonusTriggerListResponse>  {

    constructor(http : HttpService) {
        super("/bonus/trigger", http)
    }

}