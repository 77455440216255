import * as React from "react";
import * as _ from "lodash";
import {FieldSet, FormListener, SimpleFieldSet, ValidationResult} from "./Form";
import {Validator, Validators} from "./Validators";
import {FormTransferContainer} from "./FormTransferContainer";
import {FormGroup} from "./FormGroup";
import {Big} from "big.js";
import {HTMLAttributes} from "react";
import TagsInput from 'react-tagsinput'
import {t} from "../../i18n";

export interface FormElement<M,S extends HTMLAttributes<any>> extends React.HTMLProps<S> {
    modelValue? : M;
    valid? : boolean;
    fieldSet? : FieldSet;
    model? : string;
    validators? : Array<Validator<any>>;
    errorClass? : string;
}
var requiredValidator = Validators.required();
export abstract class AbstractFormElement<M,E extends FormElement<M,HTMLAttributes<any>>, T> extends React.Component<E,T> {
    constructor(props : E) {
        super(props);
    }
    componentDidMount() {
        var modelValue : any = this.props.modelValue;
        if(modelValue == null || modelValue == "undefined") {
            modelValue = this.getDefaultValue();
        }
        this.props.fieldSet && this.props.fieldSet.setInitialModel(this.props.model, modelValue, true,  this.validate(modelValue, true))
    }
    getDefaultValue()  : M | undefined {
        return undefined;
    }
    abstract validate(modelValue : M, pristine : boolean) : ValidationResult ;
    getModelObject() {
        return this.props.fieldSet && this.props.fieldSet.getModelObject();
    }
    updateModelValue(value : any, pristine : boolean) {
        var result = this.validate(value, pristine);
        this.props.fieldSet && this.props.model && this.props.fieldSet.updateModel(this.props.model, value, pristine, result);
    }
    getModelValue() {
        var modelValue : any = this.props.modelValue;
        if(modelValue == null || modelValue == "undefined") {
            modelValue = this.getDefaultValue();
        }
        return modelValue;
    }
    renderFormElements() {
    }
    render() {
        return (
            <FormTransferContainer  {...this.props as any}>
                {this.renderFormElements()}
            </FormTransferContainer>
        );
    }
}
export abstract class AbstractInputElement<M,E extends FormElement<M,HTMLAttributes<any>>, T> extends AbstractFormElement<M,E, T>{
    constructor(props : E) {
        super(props);
    }
    componentWillUnmount() {
        if(this.props.fieldSet) {
           this.props.fieldSet.removeValidation(this.props.model);
        }
    }
    getClassNames() {
        var className = this.props.className ? this.props.className  : "";
        if(this.isValid() == false ) {
            var errorClass = this.props.errorClass;
            className  = className  + " " + ( errorClass != null ? errorClass : "error is-invalid");
        }
        if(this.props.model)  {
            className += " fm-" + (this.props.model.replace(".","_"));
        }
        return className;
    }
    shouldComponentUpdate(nextProps : E, nextState : any) {
        if(!_.isEqual(nextProps.modelValue,this.props.modelValue) || nextProps.valid != this.props.valid) {
            return true;
        } else if(!_.isEqual(nextProps.validators, this.props.validators)) {
            return true;
        } else {
            return false;
        }
    }
    validate(value : any, pristine : boolean) : ValidationResult {
        if(this.props.required == true) {
            if(!requiredValidator.validate(value,this.getModelObject())){
                return Validators.error(requiredValidator.getName(), pristine);
            }
        }
        var validators : Validator<any>[] = this.getValidators();
        if(typeof validators == "undefined" || validators == null || validators.length == 0) {
            return Validators.valid(pristine);
        }
        for(var i = 0; i<validators.length; i++) {
            if(!validators[i].validate(value, this.getModelObject())){
                return Validators.error(validators[i].getName(),pristine);
            }
        }
        return Validators.valid(pristine);
    }
    getValidators() : Validator<any>[]  {
        return (this.props.validators as Validator<any>[]);
    }
    isValid() {
        if(this.props.model && this.props.fieldSet ) {
            return this.props.fieldSet.isModelValid(this.props.model);
        } else {
            return false;
        }
    }
    handleChange(event : React.FormEvent) {
        var value : M  = (event.target as any).value;
        this.onChange(value);
    }
    onChange(value : M)  {
        this.updateModelValue(value, false);
    }
}
export class StaticFormValue extends AbstractInputElement<string|number|boolean,FormElement<string,HTMLAttributes<any>>,{}> {
    componentDidMount() {
        this.props.fieldSet && this.props.fieldSet.setInitialModel(this.props.model, this.props.value, true,  this.validate(this.props.value, true))
    }
    render() {
        return  <input  type={"hidden"} value={this.props.value == null ? "" : this.props.value}/>
    }
}
export class FormInput extends AbstractInputElement<string|number|boolean,FormElement<string,React.InputHTMLAttributes<any>>,{}> {
    handleChange(event : React.FormEvent) {
        var value : string | number;
        if(this.props.type == "checkbox") {
            value = (event.target as any).checked;
        } else {
            value = (event.target as any).value;
        }
        if(this.props.type == "integer") {
            if(!isNaN(parseInt(""+value))) {
                value = parseInt(""+value);
            }
        } else if(this.props.type == "float"){
            let floatVal = parseFloat(""+value);
            if("" + value == ""+floatVal) {
                value = floatVal;
            }
        }
        this.onChange(value);
    }
    validate(value : any, pristine : boolean) : ValidationResult {
        if(this.props.value != null && this.props.value != "") {
            if(this.props.type == "integer" && ""+value != ""+parseInt(value)) {
                return Validators.error("type", pristine);
            } else if(this.props.type == "float" && ""+value != ""+parseFloat(value)) {
                return Validators.error("type", pristine);
            }
        }
        return super.validate(value, pristine);
    }
    getDefaultValue() : string|number|boolean|undefined {
        let type = this.props.type;
        if(type == "checkbox") {
            return false;
        } else if(type == "integer") {
            return undefined;
        } else if(type == "float") {
            return undefined;
        } else {
            return "";
        }
    }
    render() {
        if(!this.props.fieldSet){
            return <input/>
        }
        let rest : React.InputHTMLAttributes<any> = _.omit(this.props,['validators',`model`, `fieldSet`, `modelValue`, `valid`, 'validationMap', 'disabled']);
        if(this.props.type == "checkbox") {
            let modelValue : boolean = "" + this.props.modelValue == "true";
            return <input {...rest} disabled={this.props.fieldSet.isReadOnly() ? true : this.props.disabled} className={this.getClassNames()} checked={modelValue} onChange={this.handleChange.bind(this)}/>
        } else if(this.props.type == 'textarea') {
            return <textarea  {...rest}  disabled={this.props.fieldSet.isReadOnly() ? true : this.props.disabled} className={this.getClassNames()} value={this.getModelValue() == null ? "" : this.getModelValue()} onChange={this.handleChange.bind(this)}/>
        } else if(this.props.type == 'json') {
            return <textarea spellCheck={false} {...rest} disabled={this.props.fieldSet.isReadOnly() ? true : this.props.disabled} className={this.getClassNames()} value={this.getModelValue() == null ? "" : this.getModelValue()}  onChange={this.handleChange.bind(this)}>JSON.stringify(JSON.parse(this.getModelValue()),null, 2)</textarea>
        } else {
            let type = this.props.type;
            if(type === "integer") {
                type = "text";
            } else if(type === "float") {
                type = "text";
            }
            return <input  {...rest}  disabled={this.props.fieldSet.isReadOnly() ? true : this.props.disabled} type={type} required={undefined} className={this.getClassNames()} value={this.getModelValue() == null ? "" : this.getModelValue()} onChange={this.handleChange.bind(this)}/>
        }
    }
}
/**
 * Input for displaying fractions (0.1) as percentage (10)
 */
export class FractionInput extends AbstractInputElement<string,FormElement<string,React.InputHTMLAttributes<any>>,{}> {
    onChange(value : string) {
        if(!isNaN(parseFloat(value))) {
            this.updateModelValue(new Big(value).div(100).toString(), false);
        } else {
            return this.updateModelValue(value,false);
        }
    }
    getValue() {
        var value = (this.getModelValue() as string);
        if(!isNaN(parseFloat(value))){
           return new Big(value).times(100).toString();
        } else {
            return value;
        }
    }
    render() {
        let rest : React.InputHTMLAttributes<any> = _.omit(this.props,['validators',`model`, `fieldSet`, `modelValue`, `valid`, 'validationMap']);
        return <input {...rest} required={undefined} type="text" className={this.getClassNames()} value={this.getValue()} onChange={this.handleChange.bind(this)}/>
    }
}
export class FormTagsInput extends AbstractInputElement<string,FormElement<string,React.InputHTMLAttributes<any>>,{}> {
    handleValueChange(value : string[]) {
        this.updateModelValue(value, false);
    }
    getValue() : string[]{
       return this.getModelValue();
    }
    render() {
        //let rest : React.InputHTMLAttributes<any> = _.omit(this.props,['validators',`model`, `fieldSet`, `modelValue`, `valid`, 'validationMap']);
        return <TagsInput className={this.getClassNames()}  value={this.getValue()} onChange={(values)=>this.handleValueChange(values)}/>
    }
}
interface KeyValue {
    key?: string;
    value? : string | number;
}
interface FormMapProps {
    removeAttribute? : (key? : string)=>void,
    mapModel? : string,
    fieldSet? : FieldSet,
    addAttribute : (key : any,value : any)=>void
    meta? : { title : string; key : string, value : string};
    valueType?: string;
    readOnlyKeys?: string[]
}
export class FormMap extends React.Component<FormMapProps, { addKey? : KeyValue, validationMap? : any, inputError?: boolean}> implements FormListener<KeyValue>{
    constructor(props : FormMapProps) {
        super(props);
        this.state = { addKey : {}, validationMap : {}, inputError : false};
    }
    asList(map : any): KeyValue[] {
        if(!map) {
            return [];
        }
        let res = Object.keys(map).filter(k => {
            return typeof ("" + map[k]) == "string"
        }).map(k => {
            return {key: ""+k, value: map[k]}
        })
        console.log("LIST = ", res);
        return res;
    }
    onSubmit(){
        // @ts-ignore: Unreachable code error
        if(this.state.addKey && this.state.addKey.key && this.state.addKey != "") {

            this.props.addAttribute(this.state.addKey.key, this.state.addKey.value);
            this.setState({addKey : {}, inputError : false});
        }
    }
    formDidUpdate(formModel: KeyValue, validationMap?: any) {
        this.setState({addKey : formModel, validationMap : validationMap})
    }

    isReadOnly(key: string) {
        return this.props.readOnlyKeys?.includes(`attributes.${key}`) ?? false
    }


    render() {
        if(!this.props.fieldSet) {
            return <span></span>
        }
        return (
            <FormTransferContainer  {...this.props as any} model={this.props.mapModel}>
                {this.asList(this.props.fieldSet.getModelValue(this.props.mapModel)).map((kvp : KeyValue)=>{
                   return (
                       <React.Fragment>
                           <div className="hr-line-dashed"></div>
                           <FormGroup key={kvp.key} className="form-group row inline attribute">
                               <label className="col-xs-4 control-label">{kvp.key?.replaceAll("::",".")}</label>
                               <div className="col-xs-6">
                                   <FormInput  model={kvp.key}   type="text" className="form-control" validators={[Validators.regExp(/[a-zA-Z0-9_\-]*/)]} readOnly={this.isReadOnly(kvp.key ?? "")} />
                               </div>
                               <div className="col-xs-2" style={{ marginLeft : "5px"}}>
                                   { !this.isReadOnly(kvp.key ?? "")  &&
                                        <a className="btn btn-danger waves-effect waves-light" onClick={()=>this.props.removeAttribute && this.props.removeAttribute(kvp.key)}>
                                            <i className="mdi mdi-close"></i>
                                        </a>
                                   }
                               </div>
                           </FormGroup>
                       </React.Fragment>
                   )
                })}
                <SimpleFieldSet formListener={this} modelObject={this.state.addKey} validationMap={this.state.validationMap}>
                    <div className="hr-line-dashed"></div>
                    {this.state.inputError && (
                        <div className={"row"}>
                            <span className={"alert alert-danger"}>Dot's are not allowed in attribute names</span>
                        </div>
                    )}
                    <FormGroup className="row form-group inline">
                        <label className="col-xs-3 control-label">
                            {this.props.meta ? this.props.meta.title : ` ${t(`label.addAttribute`)}`}
                        </label>
                        <div className="col-xs-3">
                            <FormInput  model="key"  placeholder={this.props.meta ? this.props.meta.key : t(`placeholder.key`)} validators={[Validators.required(), Validators.regExp(/[a-zA-Z0-9_\-]*/)]} type="text" className="form-control"/>
                        </div>
                        <div className="col-xs-5">
                            <FormInput  model="value" placeholder={this.props.meta ? this.props.meta.value : t(`placeholder.value`)}  validators={[Validators.required()]}  type={this.props.valueType ?? "text"} className="form-control"/>
                        </div>
                        <div className="col-sm-2">
                            <a className="btn btn-primary" onClick={()=>this.onSubmit()}>{t(`button.add`)}</a>
                        </div>
                    </FormGroup>
                </SimpleFieldSet>
            </FormTransferContainer>
        )
    }
}
