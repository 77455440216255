import React, {Fragment} from "react";
import {ListPaymentsRequest, ListUsersRequest, Payment} from "../../http/protocol";
import {PaymentService} from "../../services/PaymentService";
import {createInitialState, ListView, ListViewProps, ListViewState} from "../ListView";
import {Form, FormListener} from "../form/Form";
import {ColumnType} from "../list/list/ColumnType";
import {PagerModel} from "../list/list/PagerModel";
import {ListUtils} from "../list/list/ListUtils";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {ActionType} from "../list/list/DataTable";
import {Validators} from "../form/Validators";
import {SelectInput} from "../form/SelectInput";
import {Link} from "react-router-dom";
import {withRouter} from "react-router";
import {Alert} from "../form/Alert";
import {getContainer} from "../../ioc/IOCSetup";
import {MetricsSingleValuePanel} from "../metrics/MetricSingleValuePanel";
import {t} from "../../i18n";
import { Collapsible } from "../Collapsible";

interface State extends ListViewState<ListPaymentsRequest> {
    listRequest?: ListPaymentsRequest;
    form?: ListPaymentsRequest;
    validationMap?: any;
    lookupId?: string;
    lookupError?: string;
    pendingWithdraws?:number;
    failedPayments?:number;
}

interface Props extends ListViewProps{
}

function paymentLinkFactory(id: number): any {
    return <Link key={"action" + id} to={`/payment/${id}`} className="btn btn-primary btn-sm">Details</Link>;
}

function userLinkRenderer(prop : any , name : any, item : any) : any {
    if (item.userId) {
        return <Link key={"user-action" + item.userId} to={`/user/${item.userId}`}>{item.userId}</Link>;
    } else {
        return <div>n/a</div>;
    }
}

function amountRenderer(prop : any, name : any, item : any) : any{
    return <div>{item.amount} {item.currency}</div>
}

function paymentMethodRenderer(prop: any, name: any, item: any): any {
    if (item.attributes != undefined) {
        return <div>{item.attributes["PAYMENT_METHOD"]}</div>
    }
}

function amountEuroRenderer(prop: any, name: any, item: any): any {
    if (item.amountEuro != undefined) {
        return <div>€{item.amountEuro}</div>
    }
}

const uidFactory = (uid: string) => <code>{uid}</code>;

export class PaymentListView extends ListView<State, ListPaymentsRequest> implements FormListener<ListPaymentsRequest> {
    statuses:string[] = ["FAILED_PERMANENTLY", "COMPLETED", "FAILED", "DECLINED", "CANCELED", "INITIATED", "PENDING", "RESOLVED"];

    columns = [
        { property : "id", name : "Id", orderBy : "id"},
        { property : "created", name : "Created", type : ColumnType.DATE},
        { property : "userId", name : "User Id", renderer: userLinkRenderer },
        { property : "type", name : "Type" },
        { property : "provider", name : "Provider" },
        { property : "method", name : "Method", renderer: paymentMethodRenderer },
        { property : "amount", name : "Amount", renderer: amountRenderer },
        { property : "amountEuro", name : "Amount Euro", renderer: amountEuroRenderer },
        { property : "status", name : "Status" },
        { property : "approvedBy", name : "approvedBy" },
        { property : "mostRecentNoteText", name : "Notes", type: ColumnType.NOTE },
    ];


    paymentService : PaymentService;

    constructor(props : Props){
        super(props, "/payments");
        this.paymentService = getContainer().getPaymentService();
        let locProp = this.props.location;
        let storedForm = localStorage.getItem("paymentListForm") || undefined;
        if (this.state.listRequest){
            let statuses, types, userId = undefined;
            let locPropState = locProp.state as any;
            if(locPropState && locPropState.listRequest) {
                statuses = locPropState.listRequest.statuses && locPropState.listRequest.statuses;
                types = locPropState.listRequest.types && locPropState.listRequest.types;
                userId = locPropState.listRequest.userId && locPropState.listRequest.userId;
            }
            let initialForm:{};
            if(storedForm != undefined && locPropState == undefined) {
                initialForm = JSON.parse(storedForm);
            } else {
                initialForm = {
                    limit: 20,
                    orderBy: "id",
                    userId: userId == undefined ? "" : userId,
                    types: types == undefined ? ["DEPOSIT","WITHDRAWAL", "BONUS"] : types,
                    statuses: statuses == undefined ? this.statuses : statuses,
                    ascending: false
                };
            }
            this.state = createInitialState(
                initialForm,
                this.state = {...this.state});
        }
    }

    componentDidMount() {
        this.props.history.replace("/payments/list")
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    formDidUpdate(formModel: ListUsersRequest, validationMap : any): void {
        this.setState({ form : formModel, validationMap : validationMap});
    }

    onSubmitError(): void {

    };

    onSubmit(): void {
        if (this.state.form){
            let listRequest = this.state.form;
            listRequest.offset = 0;
            listRequest.orderBy = "id";
            this.updateListState(listRequest);
        }
        this.props.history.replace("/payments/list")
    };

    onStatusesChanged(statuses: string[]) {
        let newReq = Object.assign({}, this.state.listRequest, { statuses: statuses });
        this.setState({ listRequest: newReq });
    }

    pagerChanged(pager : PagerModel) {
        if (this.state.listRequest) {
            var listRequest = ListUtils.toListRequest(this.state.listRequest, pager);
            this.setState({listRequest : listRequest});
        }
    }

    onAction(item : Payment, action : string) : Promise<any> | undefined {
        if (action == "details") {
            this.navigate("/payment/" + item.id);
        }
        return;
    }

    preset(e:any, preset: "default" | "failed" | "all" | "completed" | "canceled") {
        e.preventDefault();
        let userId = this.state.listRequest && this.state.listRequest.userId  ? this.state.listRequest.userId : "";
        let types = this.state.form && this.state.form.types || [];
        let sort: any = {
            limit: 20,
            orderBy: "id",
            ascending: false,
            offset: 0
        };

        let filter;

        switch(preset) {
            case "default":
                filter = { types: types, statuses: ["PENDING", "INITIATED"], userId: userId};
                break;

            case "failed":
                filter = { types: types, statuses: ["FAILED_PERMANENTLY", "FAILED"], userId: userId};
                break;

            case "completed":
                filter = { types: types, statuses: ["COMPLETED", "RESOLVED"], userId: userId};
                break;

            case "canceled":
                filter = { types: types, statuses: ["CANCELED", "DECLINED"], userId: userId};
                break;

            case "all":
                filter = { types: types, statuses:this.statuses, userId: userId};
                break;
        }


        this.updateListState({...sort, ...filter});
        this.props.history.replace("/payments/list")
    }

    handleLookupValueChanged(event : any) {
        let value = event.target.value;
        this.setState({lookupId: value});
    }

    async lookup(type: "id" | "uid") {
        if (!this.state.lookupId){
            return;
        }

        let payment = undefined
        if (type === "id") {

            if (isNaN(this.state.lookupId as any)) {
                this.setState({ lookupError: "Id must be numeric" });
                return;
            }

            try {
                payment = await this.paymentService.getPaymentById(parseInt(this.state.lookupId)) as Payment;
            } catch (e) {
                // do nothing
            }
        } else if (type === "uid") {
            try {
                payment = await this.paymentService.getPaymentByUid(this.state.lookupId) as Payment;
            } catch (e) {
                // do nothing
            }
        }

        if (!payment) {
            this.setState({ lookupError: "Payment not found" });
        } else {
            this.navigate("/payment/" + payment.id);
        }
    }

    getTitle() {
        return t(`payments.payments.pageTitle`);
    }



    renderLookupForm() {
        return (
            <Collapsible title={t(`title.lookupPayment`)} collapsed={true}>

                { this.state.lookupError  &&
                    <Alert type="danger" text={this.state.lookupError} />
                }
                <form className="form-horizontal">
                    <FormGroup className="form-group">
                        <label className="col-lg-1 control-label">{t(`label.uuid`)}</label>
                        <div className="col-lg-12">
                            <input className="form-control" placeholder="dc3a1af6-c138-4475-a372-e64bb5bb4a1a" onChange={this.handleLookupValueChanged.bind(this)}/>
                            <span className={"help-text"}>{t(`helpText.uuid`)}</span>
                        </div>
                    </FormGroup>
                    <FormGroup className="form-group">
                        <div className="col-lg-offset-1 col-lg-4">
                            <button className="btn btn-primary" type="button" onClick={this.lookup.bind(this, "uid")}>{t(`button.byUuid`)}</button>
                        </div>
                    </FormGroup>
                </form>
            </Collapsible>                
        );
    }

    renderFilterForm() {
        return (
            <div className="card-box">
                <h4>{t(`title.filterPayments`)}</h4>
                    <Form className="form" formListener={this} modelObject={this.state.form} validationMap={this.state.validationMap}>
                        <div>
                            <div className="row">
                                <div className="col-lg-4">
                                    <FormGroup className="form-group">
                                        <h5>{t(`label.userId`)}</h5>
                                        <FormInput className="form-control" model="userId" type="integer" placeholder={t(`placeholder.userId`)} validators={[Validators.integer()]}/>
                                    </FormGroup>

                                    <FormGroup className="form-group">
                                        <h5>{t(`label.type`)}</h5>
                                        <SelectInput className="form-control" multiple={true} size={4} model="types" values={["DEPOSIT", "WITHDRAWAL", "BONUS", "CORRECTION"]} >
                                        </SelectInput>
                                    </FormGroup>
                                </div>
                                <div className="col-lg-8">
                                    <FormGroup className="form-group">
                                        <h5>{t(`label.presets`)}</h5>
                                        <div>
                                            <button className="btn btn-sm btn-spacing btn-primary" onClick={(e) => this.preset(e,"default")}>{t(`button.ongoing`)}</button>
                                            <button className="btn btn-sm btn-spacing btn-primary" onClick={(e) => this.preset(e,"all")}>{t(`button.all`)}</button>
                                            <button className="btn btn-sm btn-spacing btn-primary" onClick={(e) => this.preset(e,"completed")}>{t(`button.complete`)}</button>
                                            <button className="btn btn-sm btn-spacing btn-primary" onClick={(e) => this.preset(e,"canceled")}>{t(`button.canceled`)}</button>
                                            <button className="btn btn-sm btn-spacing btn-primary" onClick={(e) => this.preset(e,"failed")}>{t(`button.failed`)}</button>
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <h5>{t(`label.status`)}</h5>
                                        <SelectInput className="form-control" model="statuses" size={7} multiple={true}
                                            values={this.statuses}>
                                        </SelectInput>
                                    </FormGroup>
                                </div>
                            </div>

                            <input className="btn btn-primary" value={t(`button.listPayments`)} type="submit" style={{marginRight:"15px"}}/>
                            <button className="btn-xs btn-new-default" onClick={(e) => this.clearForm(e)}>{t(`button.clearfilters`)}</button>
                        </div>
                    </Form>
            </div>
        );
    }

    clearForm(e:any) {
        e.preventDefault();
        return this.setState({form:{}});
    }


    renderContent() {

        if (!this.state.listRequest) {
            return <div />;
        }

        return (
            <div>
                <div className="wrapper wrapper-content">

                    {this.renderFilterForm()}
                    {this.renderLookupForm()}

                    <EntityDataTable service={this.paymentService}
                                     onPagerChange={this.pagerChanged.bind(this)}
                                     columns={this.columns}
                                     listRequest={this.state.listRequest}
                                     onAction={this.onAction.bind(this)}
                                     disableCount={true}
                                     actions={
                                         [
                                             { id: "details", name: "Details", className: "btn btn-primary", type: ActionType.LINK, getLink: (item: any) => {
                                                     localStorage.setItem("paymentListForm", JSON.stringify(this.state.listRequest));
                                                     return "/payment/" + item.id
                                                 } },
                                         ]
                                     }/>
                </div>
            </div>
        );
    }
}
export default withRouter(PaymentListView);