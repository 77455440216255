import * as React from "react";
import {Fragment} from "react";
import Datetime from 'react-datetime';

import {RouteComponentProps, withRouter} from "react-router";
import {LoadingViewState, RouterView} from "../RouterView";
import {
    AwardType,
    BonusStatus,
    CashbackCasinoTagExclusion,
    CashbackConfig,
    CashbackFrequency,
    CashbackType,
    DayOfWeek,
    GameTag,
    ListAccountsRequest,
    ListRequest,
    Product,
    RewardTemplate, RewardType
} from "../../http/protocol";
import {Form, FormListener} from "../form/Form";
import {getContainer} from "../../ioc/IOCSetup";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {Submit} from "../form/Submit";
import {Validators} from "../form/Validators";
import {SettingsService} from "../../services/SettingsService";
import {SelectInput} from "../form/SelectInput";

import {CashbackConfigService} from "../../services/CashbackConfigService";
import {RewardsConfigService} from "../../services/RewardsConfigService";
import { CasinoGameTagService } from "../../services/CasinoGameTagService";
import { FormList } from "../form/FormList";


interface State extends LoadingViewState {
    exclusions? : CashbackCasinoTagExclusion[];
    tags? : GameTag[];
    form?: CashbackCasinoTagExclusion;
    validationMap? : any;
}

export class CashbackConfigCasinoTagExclusions extends RouterView<RouteComponentProps,State> implements FormListener<CashbackCasinoTagExclusion> {

    service : CashbackConfigService;
    tagService: CasinoGameTagService;

    constructor(props : RouteComponentProps){
        super(props);
        this.service = getContainer().getCashbackConfigService();
        this.tagService = getContainer().getCasinoGameTagService();

        this.state = { loading: true, validationMap: {}, form: { excludeCashback: true, excludeRakeback: true} };
    }

    async loadContent() {

        const excls = await this.service.getCasinoTagExclusions()
        const tags = (await this.tagService.list({orderBy: "name", ascending: true, limit: 1000} as ListRequest)).items

        this.setState({exclusions: excls, tags: tags, loading: false});
    }

    getTitle(): string {
        return "Cashback Casino Tag Exclusions";
    }

    componentDidUpdate(prevProps : RouteComponentProps, prevState : State) {
    }



    formDidUpdate(formModel: CashbackCasinoTagExclusion, validationMap : any): void {
        this.setState({ form : formModel, validationMap : validationMap});
    }

    onSubmitError(): void {
    };

    async onSubmit(): Promise<void> {
        this.setState({loading: true})

        await this.service.createOrUpdateCasinoTagExclusion(this.state.form!)
        const excls = await this.service.getCasinoTagExclusions()

        this.setState({loading: false, exclusions: excls, form: {excludeCashback: true, excludeRakeback: true}})
    };


    async removeExclusion(tagNameId: string) {
        this.setState({loading: true})

        await this.service.removeCasinoTagExclusion(tagNameId)
        const excls = await this.service.getCasinoTagExclusions()

        this.setState({loading: false, exclusions: excls})
    }


    renderExclusions() {
        return this.state.exclusions?.map(x => { 
            
            const tag = this.state.tags?.find(t => t.nameId == x.tagNameId)
            
            return (
                
                <tr key={x.id}>
                    <td><strong>{tag ? <span>{tag.name}</span> : <><span className="text-danger">{x.tagNameId}</span> (missing tag)</> }</strong></td>
                    <td>{x.excludeCashback ? "Yes": "No"}</td>
                    <td>{x.excludeRakeback ? "Yes": "No"}</td>
                    <td><button className="btn btn-danger" onClick={() => this.removeExclusion(x.tagNameId!)}>Remove</button></td>
                </tr>
            )
        });
    }


    renderContent() {
        if(!this.state.exclusions) {
            return <span></span>
        }
        
        return (
            <div>
                <div className="wrapper wrapper-content animated fadeInRight">
                <Form className="form-horizontal" formListener={this} modelObject={this.state.form} validationMap={this.state.validationMap}>
                    <div className="row">
                        <div className="col-lg-12">

                            <div className="card-box">
                                <div className="ibox-content">

                                    <h4 className={"ibox-title"}>Exclusions</h4>
                                    <p>
                                        Casino games matching the tags configured below will be exluded from rake- and/or cashbacks accordingly. 
                                        This setting is global and will affect all cashback configurations. 
                                    </p>

                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Tag</th>
                                                <th>Exclude from Cashback</th>
                                                <th>Exclude from Rakeback</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.renderExclusions()}
                                        </tbody>

                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card-box">
                                <div className="ibox-content">
                                    <h4 className={"ibox-title"}>Add Exclusion</h4>


                                        <FormGroup className="form-group">
                                            <label className="col-lg-2 control-label">Tag</label>
                                            <div className="col-lg-10">
                                                {/* <FormInput className="form-control" model="tageNameId" type={"text"}/> */}
                                                <SelectInput className="form-control" model="tagNameId" values={this.state.tags} displayValue={t => t.name } optionModelValue={t => t.nameId} />
                                            </div>
                                        </FormGroup>

                                        <FormGroup className="form-group">
                                            <label className="col-lg-2 control-label">Exclude from Cashback</label>
                                            <div className="col-lg-10">
                                                <FormInput model="excludeCashback" type={"checkbox"}/>
                                            </div>
                                        </FormGroup>
                                        
                                        <FormGroup className="form-group">
                                            <label className="col-lg-2 control-label">Exclude from Rakeback</label>
                                            <div className="col-lg-10">
                                                <FormInput model="excludeRakeback" type={"checkbox"}/>
                                            </div>
                                        </FormGroup>


                                        <Submit className="btn btn-primary">Add</Submit>

                                </div>
                            </div>
                        </div>
                    </div>

                        
                                    </Form>
                </div>
            </div>
        );
    }
}

export default withRouter(CashbackConfigCasinoTagExclusions);