import React, {Fragment} from "react";
/* import  CodeMirror from "react-codemirror" */
import {Link} from "react-router-dom";
import {GuideAlert, GuideCardBox, GuideIndex} from "./CMSGuide";

import CodeMirror from '@uiw/react-codemirror';
import { darcula } from '@uiw/codemirror-theme-darcula';
import { css } from '@codemirror/lang-css'




export class CssStyling extends React.Component<{},{}> {

    constructor(props: {}) {
        super(props);

    }
    componentDidMount(): void {

    }

    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <div className="page-title-right"></div>
                            <h4 className={"page-title"}>CSS Styling Guide</h4>
                        </div>
                    </div>
                </div>

                <GuideIndex />

                <GuideCardBox title={"Custom Theme"}>
                    <p>
                        To implement your own theme of Cubeia Nano you will nead to create a CSS-Override. This is a normal CSS-file
                        that will be included as the last of the css-files and give you the ability to override all the styles of the default theme.<br/>
                        <GuideAlert>
                           You can configure the 'CSS Override Url' in the <Link className={"alert-link"} to={"/admin/settings/general"}>Settings {'>'} General</Link> page.
                        </GuideAlert>
                    </p>
                </GuideCardBox>
                <GuideCardBox title={"Changing Logo"}>
                    <GuideAlert>
                        The site logo can be changed in Contentful CMS see <Link className={"alert-link"} to={"/guides/cms"}>Styling {'>'}  Content/CMS</Link> for more info.
                    </GuideAlert>

                </GuideCardBox>
                <GuideCardBox title={"CSS Variables"}>
                    <p>
                        The default theme is using CSS-variables for most colors which makes it easy for you to change them. This is done by overriding the CSS-variables in your CSS.<br/>
                        Bellow you have an example of an 'CSS Override' file that defines all the variables that you can override:<br/>
                    </p>
                    <CodeMirror  theme={darcula} extensions={[css()]} value={MAIN_CSS_TEMPLATE}></CodeMirror>
                </GuideCardBox>


                <GuideCardBox title={"Language Specific Styling"}>
                    <p>
                        You are able to customize/override css-styles per language by using the pseudo-class <code className={"highlighter-rouge"}>:lang(locale)</code>.
                        The 'locale' will be the locale that is currently in use by the user, examples: en, en-US, th, th-TH, zh-Hans.
                    </p>
                    <CodeMirror theme={darcula} extensions={[css()]} value={I18N_CSS_TEMPLATE}></CodeMirror>
                </GuideCardBox>


                <GuideCardBox title={"Fonts and Additional CSS-files"}>
                    <p>
                        You are able to include your own additional fonts & CSS-files in the <Link to={"/admin/settings/general"}>Settings {'>'}  General</Link> under 'Custom Scripts/CSS/Fonts'.
                        If you do not include any custom fonts, the default fonts will be added to your site.
                    </p>
                </GuideCardBox>


                <GuideCardBox title={"Advanced Styling"}>
                    <p>
                        If you want to override CSS-styles that are not in the list of CSS-variables you can do this in the 'CSS Override' file.
                        The Cubeia Nano site is using <a href="https://getbootstrap.com/" target={"_blank"}>Bootstrap</a> as
                        CSS toolkit and all basic HTML components are defined with the default bootstrap CSS-classes.
                    </p>
                    <GuideAlert>
                        In addition to the bootstrap button  <code>.btn-primary</code> and <code>.btn-secondary</code> classes,
                        Cubeia Nano uses the <code>.btn-cta</code> class which is short for 'Call to Action' and is used for the buttons you usually want the user
                        to focus on, such as Register, Login, Deposit etc.

                    </GuideAlert>
                    <CodeMirror  theme={darcula} extensions={[css()]} value={ADVANCED_CSS_TEMPLATE}></CodeMirror>
                </GuideCardBox>
            </Fragment>
        );
    }
}
const ADVANCED_CSS_TEMPLATE = `/* All buttons are using the bootstrap .btn class as base*/

/* Add a red text shadow to all buttons */
.btn {
    text-shadow: 2px 2px #ff0000;
}
/* Add a black text shadow for the CTA-buttons */
.btn.btn-cta {
    text-shadow: 2px 2px #000000;
}`;


const I18N_CSS_TEMPLATE = `/* Overriding fonts for a specific locale, in this case Thai (th)*/
html:lang(th) {
    --button-font: Kanit, sans-serif;
    --header-font:  Kanit, sans-serif;
    --text-font:  Arial, sans-serif;
    --banner-text:  Kanit, sans-serif;
}`;

const MAIN_CSS_TEMPLATE = `:root {
    
    /* Main background color */
    --main-background: #18123F;
    
    /* the main paragraph/body text color */
    --text: #fff;
    
    /* 'Call to action' colors, used for buttons such as register & deposit */
    --cta: #00b686;
    --cta-text: #fff;
    --cta-hover: #00e9ac;
    --cta-link-text: #00b686;
    
    /* primary colors, used for primary buttons that are not CTA-buttons */
    --primary: #272bee;
    --primary-text: #fff;
    --primary-hover: #5659f2;
    
    /* secondary colors, used for buttons such as cancel buttons etc */
    --secondary: #6c757d;
    --secondary-text: #fff;
    --secondary-hover: #868e96;
    
    /* status colors for labels and alerts */
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    
    /* Panels are components that have their own background (boxed content) such as the casino games in the casino lobby */
    --panel-background: #2F2768;
    --panel-text: #fff;
    
    /* icons that are inside form inputs */
    --input-icon: rgba(255, 255, 255, 0.1);
    --input-icon-highlight: #00b686;
    
    /* Popups are components that are displayed floating/on top of other content such as the 'Choose screenname' dialog'*/
    --popup-background: #100c2b;
    --popup-text: #fff;
    --popup-search: #100c2b;
    --popup-search-selected: #201853;
    
    /* The menu that opens up by clicking the menu button in the top right corner*/
    --user-menu-item: #271d67;
    --user-menu-item-text: #fff;
    --user-menu-item-hover: #36298e;
    --user-menu-item-hover-text: #fff;
    
    /* Top/main menu colors */
    /* Color of the top menu when the page is not scrolled down */
    --top-menu-start: rgba(24, 18, 63, 0);
    /* the color of the top menu  when the user has scrolled down */
    --top-menu: #18123F;
    --top-menu-text: rgba(255, 255, 255, 0.8);
    --top-menu-text-active: #ffffff;
    
    
    /* Form Inputs  */
    --input-field: #e8f0fe;
    --input-text-color: #000000;
    --input-border-radius: 2.5rem;
    --input-letter-spacing: normal;
    
    /* Border radius of all buttons */
    --button-border-radius: 2.5rem;

    /* Search input usually has a bit of different style than the normal form inputs */
    --search-input: #100c2b;
    --search-input-text: #ffffff;
    
    
    /* Back & close icon colors */
    --navigation-icon: #fff;
    
    /* Tabbed navigation such as Casino categories, currency selector*/
    --nav-link-text: #fff;
    --nav-link: #271d67;
    --nav-link-active: #272bee;
    --nav-link-text-active: #fff;
    --nav-link-text-hover: #fff;
    --nav-link-hover: #36298e;
    
    /* Payment method drop down in the deposit/withdraw page */
    --drop-down: #090717;
    --drop-down-selected: #201853;
    
    /* Favourite, fullscreen and close icon-buttons in the casino game view */
    --game-controls: #fff;
    --game-controls-active: #00b686;
    
    /* Footer */
    --footer: #000;
    --footer-text: #fff;
    
    /* Landing page 1,2,3 steps */
    --content-steps: #fff;
    --content-steps-text: #fff;
    
    /* Text color for texts on carousel/banners. Example: Top casino banner */
    --banner-text: #ffffff;
    
    /*** Fonts & Text properties ***/
    --navigation-font: Roboto, sans-serif;
    --button-font: Roboto, sans-serif;
    --header-font: Roboto, sans-serif;
    --text-font: Roboto, sans-serif;
    --footer-text-font: Quantico, sans-serif;
    --button-letter-spacing: 0.05rem;
    --header-letter-spacing: 0.1rem;
    --text-letter-spacing: 0.03rem;
    --navigation-letter-spacing: 0.08rem;
  
}`