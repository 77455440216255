import React, {Fragment} from "react";
import {Form, FormListener} from "../form/Form";
import {Affiliate, AffiliatePayoutModel, User, UserAttributeDefinition} from "../../http/protocol";
import {FormGroup} from "../form/FormGroup";
import {FormInput, FormMap} from "../form/FormInput";
import {Submit} from "../form/Submit";
import {getContainer} from "../../ioc/IOCSetup";
import {RouteComponentProps, withRouter} from "react-router";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {LoadingViewState, RouterView} from "../RouterView";
import {Validators} from "../form/Validators";
import {UserService} from "../../services/UserService";
import {AffiliatesService} from "../../services/AffiliatesService";
import {SelectInput} from "../form/SelectInput";

interface State extends LoadingViewState {
    affiliate? : Affiliate;
    validationMap? : any;
}

interface Props extends RouteComponentProps {

}

export const AFFILIATE_STATUS = ["PENDING", "APPROVED","SUSPENDED"];

export class AffiliateEditView extends RouterView<Props,State> implements FormListener<Affiliate>{

    service : AffiliatesService;
    constructor(props : Props){
        super(props);
        this.service = getContainer().getAffiliatesService();

        this.state = { affiliate : {}, validationMap : {}, loading : true};
    }

    loadContent() : Promise<any> | undefined {
        var id = (this.props.match.params as any).id;
        return this.service.getEntity(id).then((a : User) => {
            this.setState({ affiliate : a});
        })

    }
    componentDidUpdate(prevProps : RouteComponentProps, prevState : any) {
        if((this.props.match.params as any).id != (prevProps.match.params as any).id) {
            this.refreshContent();
        } else {
            super.componentDidUpdate(prevProps, prevState);
        }
    }

    onSubmit():Promise<any> {
        if(!this.state.affiliate) {
            return Promise.reject("Unable to save attribute");
        }
        return this.service.save(this.state.affiliate).then( (a : User) => {
            this.setState({ affiliate : a });
        }).catch(err=>{
            throw "Unable to create/save affiliate";
        });
    }

    onSubmitError(){}

    formDidUpdate(formModel:Affiliate, validationMap : any) {
        this.setState({ affiliate : formModel, validationMap : validationMap});
    }


    getTitle() : string {
        return "Edit Affiliate";
    }

    renderContent() {
        if(!this.state.affiliate) {
            return <span></span>
        }
        return (

            <Fragment>
                <Form className="form-horizontal" formListener={this} modelObject={this.state.affiliate} validationMap={this.state.validationMap}>
                    <div className={"card"}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <FormSubmitError />
                                    <FormSubmitSuccess text="Affiliate Attribute was updated successfully"/>

                                    <FormGroup className="form-group mb-3" model="email">
                                        <label>E-mail</label>
                                        <FormInput className="form-control" model="email" type="text"/>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>

                                    <div className="custom-control custom-checkbox">
                                        <FormInput className="custom-control-input"  id={"enabled"} model="enabled" type="checkbox"/>
                                        <label htmlFor={"enabled"} className={"custom-control-label"}>Enabled</label>
                                    </div>
                                    <div className="hr-line-dashed"></div>
                                    <FormGroup className="form-group mb-3" model="payoutModel">
                                        <label>Payout Model</label>
                                        <SelectInput  model="payoutModel" values={Object.values(AffiliatePayoutModel)} className="form-control"/>
                                        <span className={"help-block"}>PLAYER_ACCOUNT (default): Paid to affiliate's corresponding player account</span><br/>
                                        <span className={"help-block"}>DIRECT: Paid directly to affiliate's crypto account</span><br/>
                                        <span className={"help-block"}>INVOICE: Paid using Invoice by admin. Affiliate admin is responsible for payouts</span>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>

                                    <div className="custom-control custom-checkbox">
                                        <FormInput className="custom-control-input"  id={"agentsEnabled"} model="agentsEnabled" type="checkbox"/>
                                        <label htmlFor={"agentsEnabled"} className={"custom-control-label"}>Sales Agents Enabled</label><br/>
                                        <span className={"help-block"}>Sales Agents handles KYC,deposits and withdrawals. Needs agent payment provider enabled.</span>
                                    </div>

                                    <div className="custom-control custom-checkbox">
                                        <FormInput className="custom-control-input"  id={"agentsKycEnabled"} model="agentsKycEnabled" type="checkbox"/>
                                        <label htmlFor={"agentsKycEnabled"} className={"custom-control-label"}>Sales Agents KYC Enabled</label><br/>
                                        <span className={"help-block"}>Check if Sales Agents should be able to perform KYC and mark players as KYC verified.</span>
                                    </div>


                                    <div className="hr-line-dashed"></div>

                                    <FormGroup className="form-group mb-3" model="name">
                                        <label>Name</label>
                                        <FormInput className="form-control" model="name" type="text"/>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>
                                    <FormGroup className="form-group mb-3" model="username">
                                        <label>Username</label>
                                        <FormInput className="form-control" model="username" type="text" disabled={true}/>
                                    </FormGroup>

                                    <FormGroup className="form-group mb-3" model="defaultRevenueShare">
                                        <label>Default Revenue Share</label>
                                        <FormInput className="form-control" model="defaultRevenueShare" type="text"/>
                                        <span className={"help-block"}>Overrides the system default revenue share when applicable. Fractional, use 0.20 for 20%.</span>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>

                                    <FormGroup className="form-group mb-3" model="cpaDepositRequirement">
                                        <label>CPA Deposit Requirement (€)</label>
                                        <FormInput className="form-control" model="cpaDepositRequirement" type="number"/>
                                        <span className="help-block">Minimum deposit in Euro to pay out CPA. If not set, the default value from settings will be used</span>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>
                                    <FormGroup className="form-group mb-3" model="cpaTurnoverRequirement">
                                        <label>CPA Turnover Requirement (€)</label>
                                        <FormInput className="form-control" model="cpaTurnoverRequirement" type="float"/>
                                        <span className="help-block">Minimum turnover in Euro to pay out CPA. If not set, the default value from settings will be used</span>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>
                                    <FormGroup className="form-group mb-3" model="cpaPayoutAmount">
                                        <label>CPA Payout Amount (€)</label>
                                        <FormInput className="form-control" model="cpaPayoutAmount" type="float"/>
                                        <span className={"help-block"}>CPA amount in Euro to pay out.  If not set, the default value from settings will be used. Set to Zero (0) to disable CPA for this affiliate</span>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>
                                    <FormGroup className="form-group mb-3" model="cpaCap">
                                        <label>CPA Cap</label>
                                        <FormInput className="form-control" model="cpaCap" type="float"/>
                                        <span className="help-block">Maximum number of CPAs to be paid each month to the affiliate. Affiliates wont receive CPA payment for additional triggers exceeding the cap. If not set, the default value from settings will be used</span>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>
                                    <FormGroup className="form-group mb-3" model="depositLimit">
                                        <label>Deposit Amount Limit (€)</label>
                                        <FormInput className="form-control" model="depositLimit" type="float"/>
                                        <span className="help-block">Deposit amount in Euro to limit for the Affialiate's Agents</span>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>
                                    <FormGroup className="form-group mb-3" model="withdrawLimit">
                                        <label>Withdraw Amount Limit (€)</label>
                                        <FormInput className="form-control" model="withdrawLimit" type="float"/>
                                        <span className="help-block">Withdraw amount in Euro to limit for the Affialiate's Agents</span>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>

                                    <FormGroup className="form-group mb-3" model="newPassword">
                                        <label>New Password</label>
                                        <FormInput className="form-control" model="newPassword" type="text"/>
                                        <span className={"help-block"}>Sets new password for the affiliate. Leave empty to keep it unchanged. Minimum 5 characters.</span>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>

                                    <FormGroup className="form-group mb-3" model="status">
                                        <label>status</label>
                                        <SelectInput  model="status" values={AFFILIATE_STATUS} className="form-control"/>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"card"}>
                        <div className={"card-body"}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="ibox float-e-margins">
                                        <div className="ibox-content">
                                            <div className="ibox-title">
                                                <h5>Actions</h5>
                                            </div>
                                            <div className="form-group">
                                                <div className="col-sm-10 col-sm-offset-2">
                                                    <Submit className="btn btn-primary waves-effect waves-light">Save Affiliate</Submit>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </Fragment>
        )
    }

}

export default withRouter(AffiliateEditView);