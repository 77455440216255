import {HttpService} from "../http/HttpService";
import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {CashbackLog, CashbackLogListResponse, PushMessage} from "../http/protocol";


export class CashbackLogService extends AbstractAdminEntityService<CashbackLog, CashbackLogListResponse> {

    constructor(http: HttpService) {
        super("/cashback/log", http)
    }
    rerunPayouts(cashbackLogId: string) :Promise<Response>{
        return this.http.post(this.entityPath + `/rerun/`, {cashbackLogId:cashbackLogId});
    }
}