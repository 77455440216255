import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {HttpService} from "../http/HttpService";
import { Bundle, ListBundlesResponse } from "../http/protocol";


export class BundleService extends AbstractAdminEntityService<Bundle, ListBundlesResponse> {

    constructor(http: HttpService) {
        super("/store/bundle", http);
    }

}