import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {HttpService} from "../http/HttpService";
import {
    AssignRewardToUserRequest, RewardTemplate, RewardTemplateListResponse, UserGameReward,
    UserGameRewardQueryResult,
} from "../http/protocol";

export class UserGameRewardService extends AbstractAdminEntityService<UserGameReward, UserGameRewardQueryResult> {

    constructor(http: HttpService) {
        super("/user-game-reward", http);
    }

    removeAward(rewardId : string) {
        return this.http.delete("/user-game-reward/remove-reward-from-user/"+rewardId);
    }

}