import * as React from "react";
import {AbstractInputElement, FormElement} from "./FormInput";
import * as _ from "lodash";


interface SelectInputProps extends FormElement<any,React.SelectHTMLAttributes<any>> {
    values? : any[];
    displayValue? : (value : any) => string;
    optionModelValue? : (value : any) => any;
    /**
     * If you have a select input where the value in the model is an object you can use
     * this to convert this method to convert the value in the model to a value stored in the DOM
     * @param value value to convert
     */
    convertOptionValueToModel? : (value : any) => any
    /**
     * If you have a select input where the value in the model is an object you can use
     * this to convert this method to convert the value in dom to a value to be set to your model
     * @param value value to convert
     */
    convertModelValueToOption? : (value : any) => any;
    optional?: boolean;
    optionalText?: string;
    beNull?: boolean;
    bigInteger?: boolean;

}

export class SelectInput extends AbstractInputElement<any,SelectInputProps,{}>{

    componentDidMount() {
        super.componentDidMount();
    }
    getDefaultValue() {
        let value = this.props.modelValue;
        if(this.props.modelValue == null || this.props.modelValue == "" || typeof(this.props.modelValue) == "undefined" ){
            if(this.props.values && this.props.values.length > 0) {
                if(this.props.optional == true) {
                    value = null;
                } else {
                    value = this.getFirstValue();
                }
                if(this.props.multiple == true) {
                    if(!this.props.optional) {
                        value = [value];
                    } else if(this.props.beNull) {
                        value = null;
                    }
                    else {
                        value = [];
                    }
                }
            }
        } else if(this.props.defaultValue == null && this.props.required == true) {
            console.warn("No default value set for required select input " + this.props.model);
        }

        return value;
    }

    private getFirstValue() {
        if (this.props.optionModelValue && this.props.values) {
            let optionModelValue = this.props.optionModelValue(this.props.values[0]);
            if(this.props.convertOptionValueToModel) {
                return this.props.convertOptionValueToModel(optionModelValue);
            }
            return optionModelValue;
        } else if(this.props.values) {
            return this.props.values[0];
        } else {
            return undefined;
        }
    }
    shouldComponentUpdate(nextProps : SelectInputProps, nextState : any) {

        let update = super.shouldComponentUpdate(nextProps,nextState);
        if(update) {
            return true;
        }
        if(this.props.values != nextProps.values) {
            return  true;
        }
        if(!_.isEqual(nextProps,this.props)) {
            return true;
        } else {
            return false;
        }
    }
    onChange(event:React.FormEvent):void {

        if(this.props.multiple == true) {
            var options = (event.target as any).options;
            var values = [];
            for (var i = 0, l = options.length; i < l; i++) {
                if (options[i].selected) {
                    values.push(options[i].value);
                }
            }
            if(this.props.convertOptionValueToModel) {
                let convert = this.props.convertOptionValueToModel;
                values = values.map(v => convert(v));
            }
            this.updateModelValue(values, false);
        } else if(this.props.optional == true && (event.target as any).value == "") {
            this.updateModelValue(null, false);
        } else if(!this.props.bigInteger && ""+parseInt(this.getModelValue()) == this.getModelValue()) {
            var value: number = parseInt((event.target as any).value);
            if(this.props.convertOptionValueToModel) {
                value = this.props.convertOptionValueToModel(value);
            }
            this.updateModelValue(value, false);
        } else {
            let value = (event.target as any).value;
            if(this.props.convertOptionValueToModel) {
                value = this.props.convertOptionValueToModel(value);
            }
            this.updateModelValue(value, false);
        }

    }
    getDisplayValue(val : any) {
        if(this.props.displayValue) {
            return this.props.displayValue(val);
        } else {
            return val;
        }
    }
    getOptionModelValue(val : any) {
        if(this.props.optionModelValue) {
            return this.props.optionModelValue(val);
        } else {
            return val;
        }
    }

    getOptions() {
        if(this.props.values != null && typeof this.props.values != "undefined") {
            let values = this.props.values;
            if(this.props.optional == true && !this.props.multiple) {
                values = [null].concat(values);
            }
            return values.map(v => {
                if(v == null) {
                    return <option key={"no-value"} value={""}>{this.props.optionalText ? this.props.optionalText : "Select value"}</option>
                } else {
                    var optionValue = this.getOptionModelValue(v);
                    return <option key={optionValue} value={optionValue}>{this.getDisplayValue(v)}</option>
                }
            });
        }
        return [];
    }

    render() {
        let rest: React.SelectHTMLAttributes<any> = _.omit(this.props,['convertModelValueToOption', 'convertOptionValueToModel','model','displayValue', 'optionModelValue', 'values','validators',`fieldSet`, `modelValue`, `valid`, 'validationMap', 'defaultValue','optional']);
        let modelValue = this.props.modelValue;
        if(this.props.convertModelValueToOption && modelValue) {
            modelValue = this.props.convertModelValueToOption(modelValue);
        }
        return <select {...rest} multiple={this.props.multiple} className={this.getClassNames()} value={modelValue == null ? (this.props.multiple ? [] : "") : modelValue} onChange={this.onChange.bind(this)}>{this.props.children}{this.getOptions()}</select>
    }
}