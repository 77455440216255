import React, {Fragment} from "react";
import {getContainer} from "../../ioc/IOCSetup";
import {RouteComponentProps, withRouter} from "react-router";
import {LoadingViewState, RouterView} from "../RouterView";
import {CashbackConfigService} from "../../services/CashbackConfigService";
import {CalculationStatus, UpcomingCashbacksInfo} from "../../http/protocol";

interface State extends LoadingViewState {
    cashBacksText: string;
}

interface Props extends RouteComponentProps {

}

export class UserCashbacksView extends RouterView<Props, State> {

    service: CashbackConfigService;
    intervalId: NodeJS.Timeout | null = null;

    constructor(props: Props) {
        super(props);
        this.service = getContainer().getCashbackConfigService();
        this.state = {cashBacksText: 'Calculating... Please be patient.'};
    }

    componentWillUnmount() {
        this.clearInterval();
    }

    clearInterval() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }


    async loadContent() {
        const id = (this.props.match.params as any).id;
        this.service.triggerUpcomingCashbacksCalculationForUser(id).then(() => {
            const maxWaitTime = 5 * 60 * 1000;
            const intervalTime = 10_000;
            let elapsedTime = 0;

            const fetchCashbacks = () => {
                this.service.getUpcomingCashbacksForUser(id).then(response => {
                    if (response && response.status === CalculationStatus.SUCCESS) {
                        this.clearInterval();

                        if (Array.isArray(response.items) && response.items.length === 0) {
                            this.setState({ cashBacksText: 'No upcoming cashback payouts' });
                        } else if(Array.isArray(response.items)) {
                            const str = this.buildCashbackText(response.items);
                            this.setState({ cashBacksText: str });
                        }
                    }else if(response && response.status === CalculationStatus.FAILURE){
                        this.clearInterval();
                        this.setState({ cashBacksText: 'Error while calculating cashbacks' });
                    }
                }).catch(err => {
                    console.log(err);
                    this.setState({ cashBacksText: 'Error while calculating cashbacks' });
                    this.clearInterval();
                });
            };

            // Start fetching cashbacks at intervals
            this.intervalId = setInterval(() => {
                elapsedTime += intervalTime;
                fetchCashbacks();

                // Stop interval if the maximum wait time has been reached
                if (elapsedTime >= maxWaitTime) {
                    this.clearInterval();
                    this.setState({ cashBacksText: 'The cashback calculation is taking longer than expected. Please try again later.' });
                }
            }, intervalTime);

        }).catch(err => {
            console.log(err);
            this.setState({ cashBacksText: 'Error while calculating cashbacks' });
        });

    }

    buildCashbackText(cashbackList: UpcomingCashbacksInfo[]): string {
        let str: string = '';
        cashbackList.forEach(cashbackInfo => {
            if (!cashbackInfo.isSummarized){
                str += (cashbackInfo.payoutDate + ", " + cashbackInfo.configName + ", " + cashbackInfo.amount + " " + cashbackInfo.currency + '\n');
            } else {
                str += `Summarized Cashback for ${cashbackInfo.configName} on ${cashbackInfo.payoutDate} with total amount of ${cashbackInfo.amount} ${cashbackInfo.currency} \n`
                str += '\n'
            }
        })
        return str;
    }

    getTitle(): string {
        return "User Upcoming Cashbacks";
    }

    renderContent() {
        return (
            <Fragment>
                <div>
                    <div className={"card"}>
                        <div className="card-body">
                            <button onClick={(e) => {
                                navigator.clipboard.writeText(this.state.cashBacksText)
                            }} className="btn btn-primary">Copy Data
                            </button>
                        </div>
                        <div className="card-body">
                            <div className="help-block">Format: Payout Date, Cashback Type, Amount Currency</div><br/>
                            <div className="help-block"><strong>Calculation is based on activity up to 1 hour ago. Transactions after this time are not included.</strong></div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <textarea spellCheck={false} className="form-control" style={{height: "350px"}}
                                              disabled={true}
                                              value={this.state.cashBacksText}/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Fragment>
        )
    }

}

export default withRouter(UserCashbacksView);