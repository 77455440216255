import { HttpService } from "../http/HttpService";
import { AdminConfig } from "../http/Types";
import { AccountService } from "../services/AccountService";
import { AdminUserService } from "../services/AdminUserService";
import { ChartService } from "../services/ChartService";
import { MenuService } from "../services/MenuService";
import { TransactionEntriesService } from "../services/TransactionEntriesService";
import { UserAttributeService } from "../services/UserAttributeService";
import { UserAuthenticationService } from "../services/UserAuthenticationService";
import { UserGroupService } from "../services/UserGroupService";
import { UserService } from "../services/UserService";
import { WalletService } from "../services/WalletService";

import { CasinoCategoryService } from "../services/CasinoCategoryService";
import { CasinoGameService } from "../services/CasinoGameService";

import { AdminCasinoGameUpdateLogService } from "../services/AdminCasinoGameUpdateLogService";
import { AdminCasinoPageService } from "../services/AdminCasinoPageService";
import { AdminNotesService } from "../services/AdminNotesService";
import { AffiliatesPaymentService } from "../services/AffiliatesPaymentService";
import { AffiliatesService } from "../services/AffiliatesService";
import { AnalyticsService } from "../services/AnalyticsService";
import { AuditLogService } from "../services/AuditLogService";
import { BatchTransactionService } from "../services/BatchTransactionService";
import { BonusConfigService } from "../services/BonusConfigService";
import { BonusOfferService } from "../services/BonusOfferService";
import { BundleService } from "../services/BundleService";
import { CashbackConfigService } from "../services/CashbackConfigService";
import { CasinoGameTagService } from "../services/CasinoGameTagService";
import { CasinoJackpotService } from "../services/CasinoJackpotService";
import { CasinoLobbyService } from "../services/CasinoLobbyService";
import { CasinoStudioService } from "../services/CasinoStudioService";
import { CurrencyRateService } from "../services/CurrencyRateService";
import { DwUserService } from "../services/DwUserService";
import { IPRulesService } from "../services/IPRulesService";
import { InternalSettingsService } from "../services/InternalSettingsService";
import { MaintenanceWindowService } from "../services/MaintenanceWindowService";
import { MetabaseService } from "../services/MetabaseService";
import { MetricsService } from "../services/MetricsService";
import { MonetaryConfigService } from "../services/MonetaryConfigService";
import { NotificationsService } from "../services/NotificationsService";
import { OfferRuleService } from "../services/OfferRuleService";
import { PaymentService } from "../services/PaymentService";
import { ProviderService } from "../services/ProviderService";
import { RewardsConfigService } from "../services/RewardsConfigService";
import { ScriptWidgetService } from "../services/ScriptWidgetService";
import { SecuritySettingsService } from "../services/SecuritySettingsService";
import { SettingsService } from "../services/SettingsService";
import { SportsbookService } from "../services/SportsbookService";
import { SystemEventService } from "../services/SystemEventService";
import { TransactionService } from "../services/TransactionService";
import { TranslationsService } from "../services/TranslationsService";
import { TurnoverContributionsService } from "../services/TurnoverContributionsService";
import { UserBonusEventService } from "../services/UserBonusEventService";
import { UserBonusService } from "../services/UserBonusService";
import { UserGameRewardService } from "../services/UserGameRewardService";
import { UserJourneyService } from "../services/UserJourneyService";
import { UserRiskCollectionService } from "../services/UserRiskCollectionService";
import { VoucherService } from "../services/VoucherService";
import {AdminSiteService} from "../services/AdminSiteService";
import { InstantWithdrawalService } from "../services/InstantWithdrawalService";
import { ServiceLevelConfigurationService } from "../services/ServiceLevelConfigurationService";
import { AffiliatesReportService } from "../services/AffiliatesReportService";
import {AbuseService} from "../services/AbuseService";
import {SportsbookBonusService} from "../services/SportsbookBonusService";
import {config} from "process";
import {CashbackLogService} from "../services/CashbackLogService";
import {CashbackSingleCurrencyPayoutService} from "../services/CashbackSingleCurrencyPayoutService";
import {CashbackPayoutService} from "../services/CashbackPayoutService";


var container : AppContext;

interface AppContext {
    getHttpService() : HttpService;
    getProviderService(): ProviderService;
    getAdminUserService() : AdminUserService;
    getUserAuthenticationService() : UserAuthenticationService;
    getMenuService() : MenuService;
    getUserGroupService() : UserGroupService;
    getUserAttributeService(): UserAttributeService;
    getUserService() : UserService;
    getAbuseService() : AbuseService;
    getChartService() : ChartService;
    getWalletService() : WalletService;
    getAccountService() : AccountService;
    getMaintenanceService() : MaintenanceWindowService;
    getAuditLogService() : AuditLogService;
    getTransactionEntriesService() : TransactionEntriesService;
    getCasinoCategoryService() : CasinoCategoryService;
    getCasinoGameService() : CasinoGameService;
    getPaymentService() : PaymentService;
    getVoucherService() : VoucherService;
    getAdminNotesService() : AdminNotesService;
    getAnalyticsService(): AnalyticsService;
    getMetricsService() : MetricsService;
    getSettingsService() : SettingsService;
    getInstantWithdrawalService() : InstantWithdrawalService;
    getInternalSettingsService() : InternalSettingsService;
    getBundleService() : BundleService;
    getAffiliatesService() : AffiliatesService;
    getAffiliatesReportService() : AffiliatesReportService;
    getAffiliatesPaymentService() : AffiliatesPaymentService;
    getCasinoStudioService() : CasinoStudioService;
    getCasinoGameTagService() : CasinoGameTagService;
    getTranslationsService() : TranslationsService;
    getTransactionService() : TransactionService;
    getNotificationsService() : NotificationsService;
    getBatchTransactionService() : BatchTransactionService;
    getMetabaseService() : MetabaseService;
    getTurnoverContributionsService() : TurnoverContributionsService;
    getOfferRuleService() : OfferRuleService;
    getMonetaryConfigService() : MonetaryConfigService;
    getCashbackConfigService() : CashbackConfigService;
    getCashbackLogService() : CashbackLogService;
    getCashbackSingleCurrencyPayoutService() : CashbackSingleCurrencyPayoutService;
    getCashbackPayoutService() : CashbackPayoutService;
    getBonusConfigService() : BonusConfigService;
    getBonusOfferService() : BonusOfferService;
    getUserBonusService() : UserBonusService;
    getSystemEventService() : SystemEventService;
    getRewardsConfigService() : RewardsConfigService;
    getUserGameRewardService() : UserGameRewardService;
    getCasinoPageService() : AdminCasinoPageService;
    getUserBonusEventService() : UserBonusEventService;
    getCurrencyRateService(): CurrencyRateService;
    getAdminCasinoUpdateLogService() : AdminCasinoGameUpdateLogService;
    getUserJourneyService() : UserJourneyService;
    getSecuritySettingsService() : SecuritySettingsService;
    getAdminConfig() : AdminConfig;
    getCasinoLobbyService(): CasinoLobbyService;
    getScriptWidgetService() : ScriptWidgetService;
    getIpRuleService () :IPRulesService;
    getSportsbookService() : SportsbookService;
    getSportsbookBonusService(): SportsbookBonusService;
    getCasinoJackpotService() : CasinoJackpotService;
    getDwUserService(): DwUserService;
    getUserRiskCollectionService() : UserRiskCollectionService;
    getAdminSiteService() : AdminSiteService;
    getServiceLevelConfigurationService() : ServiceLevelConfigurationService;
}
export function wireApplication(config : AdminConfig) {
    const httpService = new HttpService(config.baseUrl);
    const providerService = new ProviderService(config.cubeiaDashboardBaseUrl, config.integrationBackOffices, config.enabledPaymentProviders);
    const adminUserService = new AdminUserService(httpService);
    const userAuthService = new UserAuthenticationService(httpService);
    const menuService = new MenuService(httpService);
    const userGroupService = new UserGroupService(httpService);
    const userAttributeService = new UserAttributeService(httpService);
    const userService = new UserService(httpService);
    const abuseService = new AbuseService(httpService)
    const userJourneyService = new UserJourneyService(httpService);
    const chartService = new ChartService(httpService);
    const walletService = new WalletService(httpService);
    const accountService = new AccountService(httpService);
    const auditLogService = new AuditLogService(httpService);
    const transactionEntriesService = new TransactionEntriesService(httpService);
    const casinoCategoryService = new CasinoCategoryService(httpService);
    const casinoGameServie = new CasinoGameService(httpService);
    const paymentService = new PaymentService(httpService);
    const voucherService = new VoucherService(httpService);
    const adminNotesService = new AdminNotesService(httpService);
    const analyticsService = new AnalyticsService(httpService);
    const metricsService = new MetricsService(httpService);
    const settingsService = new SettingsService(httpService);
    const securitySettingsService = new SecuritySettingsService(httpService);
    const internalSettingsService = new InternalSettingsService(httpService);
    const instantWithdrawalService = new InstantWithdrawalService(httpService);
    const bundleService = new BundleService(httpService);
    const affiliatesService = new AffiliatesService(httpService);
    const affiliatesReportService = new AffiliatesReportService(httpService);
    const affiliatesPaymentService = new AffiliatesPaymentService(httpService);
    const casinoStudioService = new CasinoStudioService(httpService);
    const casinoGameTagService = new CasinoGameTagService(httpService);
    const translationsService = new TranslationsService(httpService);
    const transactionService = new TransactionService(httpService);
    const notificationsService =  new NotificationsService(httpService);
    const batchTransactionService = new BatchTransactionService(httpService);
    const metabaseService = new MetabaseService(httpService);
    const turnoverContributionsService = new TurnoverContributionsService(httpService);
    const offerRuleService = new OfferRuleService(httpService);
    const monetaryConfigService = new MonetaryConfigService(httpService);
    const cashbackConfigService = new CashbackConfigService(httpService);
    const cashbackLogService = new CashbackLogService(httpService);
    const cashbackSingleCurrencyPayoutService = new CashbackSingleCurrencyPayoutService(httpService);
    const cashbackPayoutService = new CashbackPayoutService(httpService);
    const bonusConfigService = new BonusConfigService(httpService);
    const bonusOfferService = new BonusOfferService(httpService);
    const userBonusService = new UserBonusService(httpService);
    const systemEventService = new SystemEventService(httpService);
    const rewardsConfigService = new RewardsConfigService(httpService);
    const userGameRewardService = new UserGameRewardService(httpService);
    const adminCasinoPageService = new AdminCasinoPageService(httpService);
    const userBonusEventService = new UserBonusEventService(httpService);
    const currencyRateService = new CurrencyRateService(httpService);
    const adminCasinoUpdateLogService = new AdminCasinoGameUpdateLogService(httpService);
    const casinoLobbyService = new CasinoLobbyService(httpService);
    const casinoJackpotService = new CasinoJackpotService(httpService);
    const widgetService = new ScriptWidgetService(httpService);
    const ipRuleService = new IPRulesService(httpService);
    const maintenanceService = new MaintenanceWindowService(httpService);
    const sportsbookBonusService = new SportsbookBonusService(httpService);
    const sportsbookService = new SportsbookService(httpService);
    const dwUserService = new DwUserService(httpService);
    const userRiskCollectionService = new UserRiskCollectionService(httpService);
    const adminSiteService = new AdminSiteService(httpService);
    const serviceLevelConfigurationService = new ServiceLevelConfigurationService(httpService);

    container = {
        getSportsbookBonusService(): SportsbookBonusService {
            return sportsbookBonusService;
        },
        getUserRiskCollectionService(): UserRiskCollectionService {
            return userRiskCollectionService;
        },
        getCasinoJackpotService() : CasinoJackpotService {
            return casinoJackpotService;
        },
        getMaintenanceService(): MaintenanceWindowService {
            return maintenanceService;
        },
        getIpRuleService () :IPRulesService {
            return ipRuleService;
        },
        getSecuritySettingsService() : SecuritySettingsService {
            return securitySettingsService;
        },
        getUserJourneyService() : UserJourneyService {
            return userJourneyService;
        },
        getCashbackConfigService(): CashbackConfigService {
            return cashbackConfigService;
        },
        getCashbackLogService(): CashbackLogService {
            return cashbackLogService;
        },
        getCashbackSingleCurrencyPayoutService(): CashbackSingleCurrencyPayoutService {
            return cashbackSingleCurrencyPayoutService;
        },
        getCashbackPayoutService(): CashbackPayoutService {
            return cashbackPayoutService;
        },
        getBonusOfferService(): BonusOfferService {
            return bonusOfferService;
        },
        getBonusConfigService(): BonusConfigService {
            return bonusConfigService;
        },
        getMonetaryConfigService(): MonetaryConfigService {
            return monetaryConfigService;
        }, getOfferRuleService(): OfferRuleService {
            return offerRuleService;
        },
        getHttpService: () => httpService,
        getProviderService: () => providerService,
        getAdminUserService: () => adminUserService,
        getUserAuthenticationService: () => userAuthService,
        getMenuService: () => menuService,
        getUserGroupService: () => userGroupService,
        getUserAttributeService: () => userAttributeService,
        getUserService: () => userService,
        getAbuseService: () => abuseService,
        getChartService: () => chartService,
        getWalletService: () => walletService,
        getAccountService : () => accountService,
        getAuditLogService : () => auditLogService,
        getTransactionEntriesService: () => transactionEntriesService,
        getCasinoCategoryService : () => casinoCategoryService,
        getCasinoGameService : () => casinoGameServie,
        getPaymentService: () => paymentService,
        getVoucherService: () => voucherService,
        getAdminNotesService : () => adminNotesService,
        getAnalyticsService: () => analyticsService,
        getMetricsService: () => metricsService,
        getSettingsService: () => settingsService,
        getInternalSettingsService: () => internalSettingsService,
        getInstantWithdrawalService: () => instantWithdrawalService,
        getBundleService: () => bundleService,
        getAffiliatesService: () => affiliatesService,
        getAffiliatesReportService: () => affiliatesReportService,
        getAffiliatesPaymentService: () => affiliatesPaymentService,
        getCasinoStudioService : () => casinoStudioService,
        getCasinoGameTagService : () => casinoGameTagService,
        getTranslationsService : () => translationsService,
        getTransactionService : () => transactionService,
        getNotificationsService : () => notificationsService,
        getBatchTransactionService : () => batchTransactionService,
        getMetabaseService : () => metabaseService,
        getTurnoverContributionsService : () => turnoverContributionsService,
        getUserBonusService : () => userBonusService,
        getSystemEventService : () => systemEventService,
        getRewardsConfigService : () => rewardsConfigService,
        getUserGameRewardService : () => userGameRewardService,
        getCasinoPageService : () => adminCasinoPageService,
        getUserBonusEventService : () => userBonusEventService,
        getCurrencyRateService : () => currencyRateService,
        getAdminCasinoUpdateLogService : () => adminCasinoUpdateLogService,
        getAdminConfig : () => config,
        getCasinoLobbyService: () => casinoLobbyService,
        getScriptWidgetService : () => widgetService,
        getSportsbookService : () => sportsbookService,
        getDwUserService: () => dwUserService,
        getAdminSiteService: () => adminSiteService,
        getServiceLevelConfigurationService: () => serviceLevelConfigurationService
    }
}

export function getContainer() : AppContext {
    return container;
}