

import {IPagerModel, PagerModel} from "./PagerModel";

import {ListRequest, UnorderedListRequest} from "../../../http/protocol";


export class ListUtils {
    static toListRequest<E extends UnorderedListRequest>(listRequestState : E, pager : IPagerModel) : E {
        let listRequest : any = Object.assign({}, listRequestState);
        listRequest.limit = pager.limit;
        listRequest.ascending = pager.ascending;
        listRequest.offset = pager.offset;
        if(typeof(listRequest.offset)=="undefined") {
            listRequest.offset = 0;
        }
        listRequest.orderBy = pager.orderBy;
        return listRequest;
    };
}