import React, { Fragment } from "react";
import {ListView, ListViewProps, ListViewState, createInitialState} from "../ListView";
import {PayoutsListRequest, User} from "../../http/protocol";
import {Form, FormListener} from "../form/Form";
import {getContainer} from "../../ioc/IOCSetup";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {ActionType} from "../list/list/DataTable";
import {AffiliatesPaymentService} from "../../services/AffiliatesPaymentService";
import {ColumnType} from "../list/list/ColumnType";
import { PagerModel } from "../list/list/PagerModel";
import { ListUtils } from "../list/list/ListUtils";
import { FormGroup } from "../form/FormGroup";
import { FormInput } from "../form/FormInput";
import {Validators} from "../form/Validators";
import { Submit } from "../form/Submit";


interface State extends ListViewState<PayoutsListRequest> {
    listRequest? : PayoutsListRequest;
    //form? : PayoutsListRequest;
    validationMap? : any;
}
interface Props extends ListViewProps  {

}

export default class AffiliatesReportPayoutsView extends ListView<State, PayoutsListRequest> implements FormListener<PayoutsListRequest> {
    service: AffiliatesPaymentService;

    columns = [
        { property : "id", name : "ID"},
        { property : "affiliateId", name : "Affiliate ID"},
        { property : "month", name : "Month", type: ColumnType.TEXT},
        { property : "amount", name : "amount"},
        { property : "grossRevenue", name : "grossRevenue"},
        { property : "netRevenue", name : "netRevenue"},
        { property : "bonusCost", name : "bonusCost"},
        { property : "networkCost", name : "networkCost"},
        { property : "percentage", name : "percentage"},
        { property : "profit", name : "profit"},
        { property : "carryOver", name : "carryOver"},
        { property : "currency", name : "currency"},
        { property : "status", name : "status"},
        { property : "progress", name : "progress"},
    ];

    actions = [
        { id : "view", name : "Go to Affiliate", className : "btn btn-primary", iconClassName : "fa fa-user", type : ActionType.LINK, getLink : (item : any) => "/affiliate/user/" + item.affiliateId },

    ];

    onAction(item : User, action : string) : Promise<any> | undefined {
        return;
    }

    initialState : PayoutsListRequest = {
        limit: 20,
        month:undefined,
        affiliateId:undefined,
        cashoutId:undefined
    }

    formDidUpdate(formModel: PayoutsListRequest, validationMap?: any): void {
        this.setState({listRequest : formModel, validationMap : validationMap});
    }

    onSubmit(){
        let listRequest : PayoutsListRequest = Object.assign({},this.state.listRequest);
        listRequest.offset = 0;
        this.updateListState(listRequest);
    }

    pagerChanged(pager : PagerModel) {
        if(this.state.listRequest) {
            console.log("pager changed!", pager);
            let listRequest = ListUtils.toListRequest<any>(this.state.listRequest,pager);
            this.updateListState(listRequest);
        }
    }

    constructor(props:Props) {
        super(props, 'payouts');
        this.service = getContainer().getAffiliatesPaymentService();
        this.state = createInitialState(
            this.initialState,
            this.state = {...this.state, listRequest : {...this.state.listRequest, orderBy : "name", offset: 0, limit: 10}});
    }

    getTitle() {
        return "List Affiliates Payouts"
    }

    getSearchByAffiliateId() {
        return(
            <div className="row">
                <div className={"col-lg-4"}>
                    <div className="card-box">
                        <h4>Find By Affiliate Id</h4>
                        <Form formListener={this} modelObject={this.state.listRequest} validationMap={this.state.validationMap}>
                            <FormGroup className={"form-group"} model="affiliateId">
                                <FormInput model={"affiliateId"} className={"form-control"} validators={[Validators.integer()]}/>
                                <span className={"help-text"}>Finds reports by affiliate id. Exact match only.</span>
                            </FormGroup>
                            <Submit className={"btn btn-primary"}>Search</Submit>
                        </Form>
                    </div>

                </div>
                
                <div className={"col-lg-4"}>
                    <div className="card-box">
                        <h4>Find By Cashout Id</h4>
                        <Form formListener={this} modelObject={this.state.listRequest} validationMap={this.state.validationMap}>
                            <FormGroup className={"form-group"} model="cashoutId">
                                <FormInput model={"cashoutId"} className={"form-control"} validators={[Validators.integer()]}/>
                                <span className={"help-text"}>Find reports by cashout id. Exact match only.</span>
                            </FormGroup>
                            <Submit className={"btn btn-primary"}>Search</Submit>
                        </Form>
                    </div>

                </div>

                <div className={"col-lg-4"}>
                    <div className="card-box">
                        <h4>Find By Month-Year</h4>
                        <Form formListener={this} modelObject={this.state.listRequest} validationMap={this.state.validationMap}>
                            <FormGroup className={"form-group"} model="month">
                                <FormInput model={"month"} className={"form-control"} type={"date"}/>
                                <span className={"help-text"}>Find reports by month-year.</span>
                            </FormGroup>
                            <Submit className={"btn btn-primary"}>Search</Submit>
                        </Form>
                    </div>

                </div>
            </div>
        )
    }

    renderContent() {
        if(!this.state.listRequest) {
            return <div/>;
        }
        return (
            <Fragment>
                {this.getSearchByAffiliateId()}
                <EntityDataTable service={this.service}
                                onPagerChange={this.pagerChanged.bind(this)}
                                columns={this.columns}
                                listRequest={this.state.listRequest}
                                onAction={this.onAction.bind(this)}
                                actions={this.actions}
                                setMaxLimit={100} />
            </Fragment>
        );
    }
}