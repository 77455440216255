import { isNumber } from "lodash";
import * as React from "react";
import { Link } from "react-router-dom";
import {t} from "../i18n";



export interface AttributePanelProps {
    attributes:{[key: string]:string};
    header: string;
    hideTableHeader? : boolean;
}


type ValueRenderer = {
    matches: (key: string, value: string, allAttributes: {[key: string]:string}) => boolean,
    render: (key: string, value: string) => React.ReactFragment
}

export class AttributePanel extends React.Component<AttributePanelProps, {}>{
    /**
     * List of renerers for attributes. The fallback text rendrer must always be last in the list.
     */
    private static readonly valueRenderers: ValueRenderer[] = [
        // HTTP link
        {
            matches: (k, v, aa) => v.startsWith("http"),
            render: (k, v) => <a href={v} target={"_blank"}>{v}</a>
        },
        // Bonus view link
        {
            matches: (k, v, aa) => k == "bonusId"  ||  k == "BONUS_ID",
            render: (k, v) => <Link to={`/bonus/view/${v}`}>{v}</Link>
        },
        // Sportsbook Bet 
        {
            matches: (k, v, aa) => aa['PRODUCT'] == 'SPORTSBOOK'  &&  k == 'BET_ID'  &&  !isNaN(v as any),
            render: (k, v) => <Link to={`/sportsbook/bet/${v}`}>{v}</Link>
        },
        // Fallback text renderer
        {
            matches: (k, v, aa) => true,
            render: (k, v) => <>{v}</>
        },
    ]


    getOrderedAttributes() {
        let orderAttributes:{[key: string]:string} = {};
        if(this.props.attributes) {
            Object.keys(this.props.attributes)
                .sort()
                .forEach((v,i) => {
                    orderAttributes[v] = this.props.attributes[v];
                });
        }
        return orderAttributes;
    }

    renderTH() {
        if (this.props.hideTableHeader == true) {
            return (<thead></thead>);
        }

        return (
            <thead>
                <tr>
                    <th>{t(`dataTable.header.key`)}</th>
                    <th>{t(`dataTable.header.value`)}</th>
                </tr>
            </thead>
        )
    }

    render() {
        return(
            <div className={"card-box"}>
                <h4>{this.props.header}</h4>

                <table className="table table-striped">
                    {this.renderTH()}
                    <tbody>
                    {this.getAttributes()}
                    </tbody>
                </table>
            </div>
        );
    }

    getAttributes() {
        let attr = [];
        let orderedAttributes:{[key: string]:string} = this.getOrderedAttributes();
        for (let x in orderedAttributes) {
            attr.push(this.renderAttribute(x, orderedAttributes[x], orderedAttributes));
        }
        return attr;
    }

    renderAttribute(key:string, value:string, attributes: {[key: string]:string}) {
        const renderedValue = AttributePanel.valueRenderers.find(vr => vr.matches(key, value, attributes))!.render(key, value)

        return (
            <tr key={key}>
                <td>{key}</td>
                <td>{renderedValue}</td>
            </tr>
        );
    }

}
