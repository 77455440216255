import React, {Fragment} from "react";
import {ActionType} from "../list/list/DataTable";
import {CashbackLog, CashbackLogListRequest, ListRequest} from "../../http/protocol";
import {getContainer} from "../../ioc/IOCSetup";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {PagerModel} from "../list/list/PagerModel";
import {ListUtils} from "../list/list/ListUtils";
import {withRouter} from "react-router";
import {ListView, ListViewProps, ListViewState} from "../ListView";
import {FormListener} from "../form/Form";
import {CashbackLogService} from "../../services/CashbackLogService";
import {ColumnType} from "../list/list/ColumnType";


interface State extends ListViewState<CashbackLogListRequest> {
    form?: CashbackLogListRequest;
    validationMap?: any;
}

interface Props extends ListViewProps {

}

export class CashbackLogListView extends ListView<State, CashbackLogListRequest> implements FormListener<CashbackLogListRequest> {

    columns = [
        {property: "id", name: "ID", orderBy: "id"},
        {property: "status", name: "Status"},
        {
            property: "configId",
            name: "Cashback Config ID",
            type: ColumnType.LINK,
            getUrl: (item: CashbackLog) => "/cashback/config/" + item.configId
        },
        {property: "created", name: "Created", type: ColumnType.DATE},
        {property: "executionTimeSeconds", name: "Execution Time (Seconds)"},
        {property: "periodStart", name: "Period Start", type: ColumnType.DATE},
        {property: "periodEnd", name: "Period End", type: ColumnType.DATE},
    ];

    actions = [
        {
            id: "rerunPayouts",
            name: "Rerun Payouts",
            className: "btn btn-danger",
            iconClassName: "fa fa-user",
            type: ActionType.CONFIRMATION
        }
    ];


    service: CashbackLogService;

    constructor(props: Props) {
        super(props, "/cashback/log/list");
        this.state = {...this.state, validationMap: {}, form: {...this.state.listRequest} as any};
        this.service = getContainer().getCashbackLogService();
    }

    onSubmit(): void | Promise<any> {
        if (this.state.form) {
            this.updateListState(this.state.form);
        }
    }

    onSubmitError(): void {

    }

    formDidUpdate(formModel: ListRequest, validationMap?: any): void {
        this.setState({form: formModel, validationMap: validationMap});
    }

    onAction(item: CashbackLog, action: string): Promise<any> | undefined {
        if (action == 'rerunPayouts') {
            return this.service.rerunPayouts("" + item.id).then(value => this.refreshContent());
        }
    }

    pagerChanged(pager: PagerModel) {
        if (this.state.listRequest) {
            console.log("pager changed!", pager);
            let listRequest = ListUtils.toListRequest<any>(this.state.listRequest, pager);
            this.updateListState(listRequest);
        }
    }

    getTitle() {
        return "List Cashback Logs";
    }

    renderContent() {
        if (!this.state.listRequest) {
            return <div/>;
        }
        return (
            <Fragment>
                <EntityDataTable service={this.service}
                                 onPagerChange={this.pagerChanged.bind(this)}
                                 columns={this.columns}
                                 listRequest={this.state.listRequest}
                                 onAction={this.onAction.bind(this)}
                                 actions={this.actions}/>
            </Fragment>
        );
    }


}

export default withRouter(CashbackLogListView);
