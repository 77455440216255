import React from "react";
import {getContainer} from "../../ioc/IOCSetup";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {LoadingViewState, RouterView} from "../RouterView";
import {Credentials, Settings} from "../../http/protocol";
import {MetabaseService} from "../../services/MetabaseService";
import {ProviderService} from "../../services/ProviderService";
let jwt = require("jsonwebtoken");


interface State extends LoadingViewState {
    loading:boolean;
    settings?:Settings;
    url?:string;
}

interface Props extends RouteComponentProps{

}

export class MetabaseReportView extends RouterView<Props,State> {
    metabaseService:MetabaseService;
    providerService:ProviderService;

    constructor(props : Props) {
        super(props);
        this.metabaseService = getContainer().getMetabaseService();
        this.providerService = getContainer().getProviderService();
        this.state = {loading:true}
    }

    loadContent(): Promise<any>  {
        let id = (this.props.match.params as any).id;
        return this.metabaseService.getCredentials().then((credential:Credentials) => {
            var METABASE_SITE_URL =  credential.url;
            var METABASE_SECRET_KEY = credential.secretKey;
            var payload = {
                resource: { question: Number(id) },
                params: {},
                exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
            };
            var token = jwt.sign(payload, METABASE_SECRET_KEY);

            var iframeUrl = METABASE_SITE_URL + "/embed/question/" + token + "#bordered=true&titled=true";
            this.setState({loading: false, url:iframeUrl});
        })
    }



    getTitle(): string {
        return "Reports";
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        super.componentDidUpdate(prevProps, prevState);
        if((prevProps.match.params as any).id != (this.props.match.params as any).id) {
            this.refreshContent();
        }
    }

    renderContent() {
        return <div>
            <iframe
                src={this.state.url}
                frameBorder="0"
                width="100%"
                height={1000}
            />
        </div>
    }

}

export default withRouter(MetabaseReportView);
