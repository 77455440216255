import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {HttpService} from "../http/HttpService";
import {JsonDocument, ListResponse, Settings, ContentfulGlobalModel, AdminSecuritySettings, AdminSettings, JsonDocumentUpdateResult} from "../http/protocol";

export class SettingsService extends AbstractAdminEntityService<Settings,ListResponse> implements SettingsService{

    constructor(http: HttpService) {
        super("/settings", http);
    }

    getSettings(): Promise<Settings> {
        return this.http.get(`${this.entityPath}/`);
    }

    /** Fetch global admin settings. Those should always be available to all admin users. (Permission REQUIRED that is automatically added) */
    getAdminSettings(): Promise<AdminSettings> {
        return this.http.get(`${this.entityPath}/admin`);
    }

    getContentfulGlobalModel() : Promise<ContentfulGlobalModel> {
        return this.http.get(`${this.entityPath}/contentful`);
    }

    saveSettings(settings:Settings):Promise<any> {
        return this.http.put<Settings,Settings>(`${this.entityPath}/`, settings);
    }

    getDocument(key: string, version?: number): Promise<JsonDocument> {
        var url = `/config/json/document/${key}`

        if (version) {
            url += `?v=${version}`
        }

        return this.http.get(url);
    }

    getDocumentVersions(key: string): Promise<number[]> {
        return this.http.get(`/config/json/document/${key}/versions`);
    }

    getDocuments(): Promise<JsonDocument[]> {
        return this.http.get('/config/json/document/all');
    }

    saveDocument(doc:JsonDocument):Promise<JsonDocumentUpdateResult> {
        return this.http.post('/config/json/document/'+doc.akey, doc);
    }

    setDocumentLocked(key: string, locked: boolean) {
        return this.http.post(`/config/json/document/${key}/locked/${locked}`, undefined)
    }

    getSchema(key: string): Promise<any> {
        return this.http.get(`/config/json/schema/${key}`)
    }

}