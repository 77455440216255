import { Modal } from "react-bootstrap";

import { ChangeEvent, useEffect, useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AsyncStatus, IconNames, addStylesheet, extractClassnames } from "./utils";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../user/UserStore";
import { setIconSettings } from "../../settings/SettingsActions";
import Axios from "axios";

interface TranslationProps {
    saveTranslations?: (translations: { [index: string]: string }) => void;
    translations?: { [index: string]: string } | undefined;
    className?: string;
}

export function Translation(props: TranslationProps) {
    const [translation, setTranslation] = useState<{ [index: string]: string }>({});
    const { register, handleSubmit } = useForm<{ language: string; name: string } | any>({ values: {} });

    const onSubmit = (values: { language: string; name: string }) => {
        setTranslation((prev) => ({ ...prev, [values.language]: values.name }));
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTranslation((prev) => ({ ...prev, [event.target.id]: event.target.value }));
    };

    const deleteTranslation = (key: any) => {
        let newTranslations: { [index: string]: string } = {};
        Object.entries(translation).forEach(([k, v]) => {
            if (k !== key) {
                newTranslations[k] = v;
            }
        });
        setTranslation(newTranslations);
    };

    useLayoutEffect(() => {
        if (props.translations) {
            setTranslation(props.translations);
        }
    }, [props.translations, props]);

    useEffect(() => {
        if (props.saveTranslations) {
            props.saveTranslations(translation);
        }
    }, [translation]);

    const renderTranslation = () => {
        return Object.entries(translation).map(([key, val]) => {
            return (
                <div className="my-2" key={key}>
                    <label>{key}</label>
                    <div className="d-flex">
                        <input onChange={handleChange} id={key} value={val} className={"form-control w-25"} />
                        <button onClick={() => deleteTranslation(key)} className="btn btn-danger waves-effect waves-light">
                            <i className="mdi mdi-close"></i>
                        </button>
                    </div>
                </div>
            );
        });
    };

    return (
        <form>
            <div className="hr-line-dashed mt-2"></div>

            <label>Translations</label>
            <div style={{ gap: "10px" }} className={`d-flex ${props.className ?? ""}`}>
                <div className={"form-group"}>
                    <input {...register("language")} placeholder="Language" className={"form-control"} />
                </div>
                <div className={"form-group"}>
                    <input {...register("name")} placeholder="Name" className={"form-control"} />
                </div>
                <button style={{ height: "38px" }} type="button" onClick={handleSubmit(onSubmit)} className="btn btn-sm btn-primary">
                    Add
                </button>
            </div>
            <span className={"help-block"}>- To translate name use language code ( DE, EN etc.) and translation for that code.</span>

            <div>{renderTranslation()}</div>

            <div className="hr-line-dashed mt-2"></div>
        </form>
    );
}
