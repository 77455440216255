import React from "react";
import {ListPaymentsResponse, Payment, User} from "../../http/protocol";
import {formatDate} from "../../util/date";
import {getContainer} from "../../ioc/IOCSetup";
import {PaymentService} from "../../services/PaymentService";
import {LoadingViewState} from "../RouterView";
import {UserService} from "../../services/UserService";
import {Link} from "react-router-dom";
import {MetricsSingleValuePanel} from "../metrics/MetricSingleValuePanel";
import {t} from "../../i18n";


interface Props {
    params: {id: string}
}


interface State extends LoadingViewState {
    userPaymentList?: ListPaymentsResponse;
    payment? : Payment;
    userInfo?: User;
}

export class UserPaymentHistory extends React.Component<Props,State> {
    paymentService: PaymentService;
    userService: UserService;

    constructor(props:Props) {
        super(props);
        this.paymentService = getContainer().getPaymentService();
        this.userService = getContainer().getUserService();
        this.state = {payment: {}, userPaymentList: {}, userInfo: {}};
        console.log("USER PAYMENT HISTORY")
    }

    componentDidMount(): void {
        console.log("component did mount");
        let id = this.props.params.id;
        console.log("id: ", id);
        let payment: any = {userId:id, ascending: false};
        Promise.all([this.paymentService.list(payment), this.userService.getEntity(id)]).then((data) => {
            if(data && payment) {
                this.setState({payment:payment, userPaymentList:data[0], userInfo:data[1]})
            }
        })
    }

    renderUserHistory() {
        let list = this.state.userPaymentList && this.state.userPaymentList.items;
        let lastTenTransactions : Payment[] = [];
        let rows:any;


        if(list) {
            let listMaxLength = list.length > 10 ? 10 : list.length;
            lastTenTransactions = list.slice(0,listMaxLength);
            rows = lastTenTransactions.map(p => {
                console.log("UserHistory: ", p);
                return (
                    <tr key={p.id}>
                        <td>
                            <Link className="list-link" to={`/payment/${p.id}`}>{p.id}</Link>
                        </td>
                        {p.created && (
                            <td>{formatDate(Number(p.created.toString().split(".").join("").substring(0,13)))}</td>
                        )}
                        <td>{p.type}</td>
                        <td>{p.amount}</td>
                        <td>{p.currency}</td>
                        <td>{p.status}</td>
                    </tr>
                );
            });
        }

        return (
            <table className="table table-striped mb-0 dt-responsive nowrap dataTable no-footer dtr-inline">
                <thead>
                <tr>
                    <th>{t(`dataTable.header.id`)}</th>
                    <th>{t(`dataTable.header.timestamp`)}</th>
                    <th>{t(`dataTable.header.eventType`)}</th>
                    <th>{t(`dataTable.header.amount`)}</th>
                    <th>{t(`dataTable.header.currency`)}</th>
                    <th>{t(`dataTable.header.paymentStatus`)}</th>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </table>
        );
    }

    render() {
        return (
            <div className="col-lg-12">
                <div className={"card"}>
                    <div className="card-header">
                        <h4>{t(`title.paymentHistory`)}</h4>
                    </div>
                    <div className="card-body">
                        <div className="ibox-content text-right">
                            <p className="text-left">{t(`label.upToTenLast`)}</p>

                            <div className="table-responsive">
                                {this.renderUserHistory()}
                            </div>
                            <Link className="btn btn-primary" to={{
                                pathname: "/payments",
                                state: {
                                    listRequest : {userId:this.props.params.id}
                                }
                            }}>
                                <i className="fe-eye"/> {t(`button.viewAll`)}
                            </Link>
                        </div>
                        <div className="ibox-content">

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}