import * as React from "react";
import {Fragment} from "react";
import Datetime from 'react-datetime';

import {RouteComponentProps, withRouter} from "react-router";
import {LoadingViewState, RouterView} from "../RouterView";
import {
    AwardType,
    BonusStatus,
    CashbackConfig,
    CashbackFrequency,
    CashbackType,
    DayOfWeek,
    GameTag,
    Product,
    RewardTemplate, RewardType
} from "../../http/protocol";
import {Form, FormListener} from "../form/Form";
import {getContainer} from "../../ioc/IOCSetup";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {Submit} from "../form/Submit";
import {Validators} from "../form/Validators";
import {SettingsService} from "../../services/SettingsService";
import {SelectInput} from "../form/SelectInput";

import {CashbackConfigService} from "../../services/CashbackConfigService";
import {RewardsConfigService} from "../../services/RewardsConfigService";
import { CasinoGameTagService } from "../../services/CasinoGameTagService";


interface State extends LoadingViewState {
    cashbackConfig? : CashbackConfig;
    validationMap? : any;
    levels? : string[];
    addLevelFraction? : string;
    rewards? : RewardTemplate[];
}

export class EditCashbackConfig extends RouterView<RouteComponentProps,State> implements FormListener<CashbackConfig>{

    service : CashbackConfigService;
    settingsService : SettingsService;
    rewardService : RewardsConfigService;

    constructor(props : RouteComponentProps){
        super(props);
        this.service = getContainer().getCashbackConfigService();
        this.settingsService = getContainer().getSettingsService();

        this.state = { cashbackConfig : { status  : BonusStatus.DISABLED }, validationMap : {},levels : [], addLevelFraction : "", rewards : []};
        this.rewardService = getContainer().getRewardsConfigService();
    }

    getTitle(): string {
        return "Edit Cashback";
    }

    componentDidUpdate(prevProps : RouteComponentProps, prevState : State) {
        if((prevProps.match.params as any).id != (this.props.match.params as any).id)  {
            this.refreshContent();
        }
    }
    updateLevelFraction(fraction : string) {
        this.setState({ addLevelFraction : fraction});
    }
    updateLevel(fraction : string, index : number) {
        if(this.state.levels) {
            console.log("Update level = ", fraction, index);
            let levels = this.state.levels.concat();
            levels[index] = fraction;
            this.setState({ levels : levels });

        }
    }
    addLevel() {
        if(!this.state.addLevelFraction ) {
            return;
        }
        let levels = this.state.levels?.concat() ?? [];
        levels.push(this.state.addLevelFraction);
        this.setState({ levels : levels});
    }
    removeLevel(index : number) {
        let levels = this.state.levels?.concat() ?? [];
        if(levels.length > index) {
            levels.splice(index,1);
            this.setState({levels : levels});
        }
    }

    loadContent(): Promise<any>  {
        if((this.props.match.params as any).id != "new") {
            return this.service.getEntity((this.props.match.params as any).id).then((s : CashbackConfig) => {
                return this.rewardService.list({limit : 1000, offset: 0, orderBy : "name",  rewardType : RewardType.cash }).then((r) => {
                    this.setState({ rewards : r.items ?? [], cashbackConfig : s, 
                        levels : s.levels ? Object.keys(s.levels).map(k => ""+s.levels?.[k] ?? "0" ) : []
                    });
                })
            });
        } else {
            this.rewardService.list({limit : 1000, offset: 0, orderBy : "name",  rewardType : RewardType.cash}).then((r) => {
                this.setState({ rewards : r.items ?? []});
            })
        }

        return Promise.resolve();
    }

    isValidLevels(levels? : string[]) {
        if(!levels) {
            return false;
        }
        return levels.filter(l => !isNaN(parseFloat(l))).length == levels.length;
    }

    onSubmit()  {
        if(!this.state.cashbackConfig) return;
        if(!this.isValidLevels(this.state.levels)) {
           return Promise.reject("Level configuration is not valid");
        }

        let levelsAttributes : any = {};
        if(this.state.levels && this.state.levels.length > 0) {
            this.state.levels.forEach((value,index) => {
                levelsAttributes[""+(index)] = parseFloat(value);
            });
        }
        let tc = {...this.state.cashbackConfig, levels : levelsAttributes};

        return this.service.save(tc).then((s : CashbackConfig)=> {
            this.setState({cashbackConfig : s, levels : s.levels ? Object.keys(s.levels).map(k => ""+s.levels?.[k] ?? "0" ) : []});
        })

    }

    formDidUpdate(formModel: CashbackConfig, validationMap?: any) {
        this.setState({cashbackConfig : formModel, validationMap : validationMap})
    }
    
    renderContent() {
        if(!this.state.cashbackConfig) {
            return <span></span>
        }
        
        return (
            <div>
                <div className="wrapper wrapper-content animated fadeInRight">
                    <Form className="form form-horizontal" validationMap={this.state.validationMap} modelObject={this.state.cashbackConfig} formListener={this}>
                        <div className="row">
                            <div className="col-lg-12">

                                <div className="card-box">
                                    <div className="ibox-content">
                                        <h4 className={"ibox-title"}>General Settings</h4>
                                        {(this.props.match.params as any).id != "new" && (   <div className="hr-line-dashed"></div> )}

                                        {(this.props.match.params as any).id != "new" && (
                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">ID</label>
                                                <div className="col-sm-10">
                                                    <FormInput  model="id" type="integer"  className="form-control" disabled={true}/>
                                                </div>
                                            </FormGroup>
                                        )}

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Name</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="name" validators={[Validators.required()]} type="text"  className="form-control" />
                                                <span className={"help"}>Internal name, will not be shown to players</span>
                                            </div>
                                        </FormGroup>

                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Status</label>
                                            <div className="col-sm-10">
                                                <SelectInput  model="status" values={Object.keys(BonusStatus)}  className="form-control" />
                                                <span className={"help"}>Status of the cashback. If ENABLED then it will run and payout as configured in scheduling.</span>
                                            </div>
                                        </FormGroup>

                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Type</label>
                                            <div className="col-sm-10">
                                                <SelectInput  model="type" values={Object.keys(CashbackType)} validators={[Validators.required()]}  className="form-control" />
                                                <span className={"help"}>Cashback = based on total lost amount, Rakeback = based on wagered amount</span>
                                            </div>
                                        </FormGroup>

                                        <FormGroup className="form-group">
                                            <label className="col-sm-4 control-label">Default Award Fraction</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="defaultAwardFraction" validators={[Validators.required(), Validators.number()]} type="float"  className="form-control" />
                                                <span className={"help"}>The default amount to apply. 0 if none. This fraction will be multipled with losses or wagered amount, according to type, and 
                                                awarded to the player as a cash reward. Example: 0.10 = 10%</span>
                                            </div>
                                        </FormGroup>

                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Lifetime Loss Only</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="lifetimeLossOnly" type="checkbox"  />
                                                <span className={"help"}> &nbsp; Only players that have more life time losses than winnings should be eligible for the Cashback</span>
                                            </div>
                                        </FormGroup>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Products</label>
                                            <div className="col-sm-10">
                                                <FormInput  validators={[Validators.required()]}  model="products"  type="text"  className="form-control" />
                                                <span className={"help"}> &nbsp; Comma separated list of products to be included in the cashback calculations</span>
                                            </div>
                                        </FormGroup>

                                        <FormGroup className="form-group">
                                            <label className="col-sm-4 control-label">Cash Reward Template ID</label>
                                            <div className="col-sm-10">
                                                <SelectInput className={"form-control"} model={"cashRewardId"}
                             
                                                             optionModelValue={(m) => m.id}
                                                             values={this.state.rewards}
                                                             displayValue={(m) => m.name}/>
                                                <span className={"help"}>You must specify a cash reward template that will be used for payouts. Currency does not have to match, it will be overriden by the payout.</span>
                                            </div>
                                        </FormGroup>

                                    </div>
                                </div>

                                <div className="card-box">
                                    <div className="ibox-content">
                                        <h4 className={"ibox-title"}>Scheduling</h4>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Recurrence</label>
                                            <div className="col-sm-10">
                                                <SelectInput  model="frequency" values={Object.keys(CashbackFrequency)} validators={[Validators.required()]}  className="form-control" />
                                                <span className={"help"}>How often the cashback should perform a payout</span>
                                            </div>
                                        </FormGroup>

                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Payout Time</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="payoutTime" validators={[Validators.required()]} type="text"  className="form-control" />
                                                <span className={"help"}>Time in UTC. Format must be HH:00, example: 09:00 or 18:00</span><br/>
                                                <span className={"help"}><strong>Cashback is calculated up to 1 hour before payout. For example, if the payout is at 14:00, only events before 13:00 are considered, and later events are excluded.</strong></span>
                                            </div>
                                        </FormGroup>

                                        {(this.state.cashbackConfig.frequency == CashbackFrequency.WEEKLY)  &&
                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">Payout Day of Week</label>
                                                <div className="col-sm-10">
                                                    <SelectInput  model="payoutDayOfWeek" values={Object.keys(DayOfWeek)} validators={[]}  className="form-control" />
                                                    <span className={"help"}>Only applies for Weekly payout</span>
                                                </div>
                                            </FormGroup>
                                        }
                                        
                                        {this.state.cashbackConfig.frequency == CashbackFrequency.MONTHLY &&
                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">Payout Day of Month</label>
                                                <div className="col-sm-10">
                                                    <FormInput  model="payoutDayOfMonth" validators={[Validators.number()]} type="integer"  className="form-control" />
                                                    <span className={"help"}>Only applies for Monthly payout. Must be between 1 and 28.</span>
                                                </div>
                                            </FormGroup>
                                        }

                                    </div>
                                </div>

                                <div className="card-box">
                                    <div className="ibox-content">
                                        <h4 className={"ibox-title"}>User Levels</h4>
                                            <span>Configure award fraction per User Level. This setting will override the default award fraction configured above.</span>
                                        <table className={"table table-striped"}>
                                            <thead>
                                                <tr>
                                                    <th>Player Level</th>
                                                    <th>Award Fraction</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.levels?.map((l,index) => (
                                                <tr>
                                                    <td style={{width : "15%"}}>{""+index}</td>
                                                    <td >
                                                        <input type={"text"} value={""+l} className={"form-control"} onChange={(e)=> this.updateLevel(e.target.value,index)}/>
                                                    </td>
                                                    <td>
                                                        <a className={"btn btn-danger"} onClick={()=>this.removeLevel(index)}>Remove</a>
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td>
                                                    Add level {(this.state.levels?.length ?? 0)}
                                                </td>
                                                <td>
                                                    <input className={"form-control"} placeholder={"Award Fraction"} value={this.state.addLevelFraction} onChange={(e)=> this.updateLevelFraction(e.target.value)}/>
                                                </td>
                                                <td>
                                                    <a className={"btn btn-primary"} onClick={()=>this.addLevel()}>Add Level</a>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                        <div className="hr-line-dashed"></div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card-box">

                                    <FormSubmitError />
                                    <FormSubmitSuccess text="Cashback was updated successfully"/>
                                    <div className="form-group">
                                        <div className="col-sm-12">
                                            <Submit className="btn  btn-primary">Save</Submit>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }
    removeAttribute(key: string): void {
        console.log("Remove Attribute not implemented.");
        console.log("Add Attribute not implemented.");
        if(this.state.cashbackConfig?.levels && this.state.cashbackConfig.levels[key]) {
            let levels = {...this.state.cashbackConfig?.levels}
            delete levels[key];
            this.setState({ cashbackConfig : {...this.state.cashbackConfig, levels : levels}});
        }
    }
    addAttribute(key: any, value: any): void {
        console.log("key = ", key, " value = ", value, this.state.cashbackConfig?.levels);
        console.log("Add Attribute not implemented.");
        let levels : any = {}
        if(this.state.cashbackConfig?.levels) {
            levels = {...this.state.cashbackConfig?.levels}
        }
        levels[""+key] = value;
        let tc = {...this.state.cashbackConfig, levels : levels};
        console.log("TC = ", tc);
        this.setState({ cashbackConfig : tc});

    }

}
interface Props {
    setToDate : (d : number) => void,
    setFromDate : (d : number) => void,
    fromDate?: Date|number;
    toDate?: Date|number;
}
export class DateRange extends React.Component<Props, { from? : any, to? : any }> {
    to: any = React.createRef<HTMLDivElement>();
    constructor(props : Props) {
        super(props);
        let fromDate = (typeof (this.props.fromDate) == "number" ? new Date(this.props.fromDate) : this.props.fromDate);
        let toDate = (typeof (this.props.toDate) == "number" ? new Date(this.props.toDate) : this.props.toDate);
        this.state = { from : fromDate, to : toDate};
    }
    render() {
        let from = this.state.from;
        let to = this.state.to;
        const modifiers = { start: from, end: to };
        return (
            <Fragment>
            <div style={{display: 'flex'}}>
                <Datetime onChange={(e) => this.props.setFromDate(Number(e))} defaultValue={new Date()} viewDate={new Date()} dateFormat={"yyyy-MM-DD"} timeFormat={"HH:mm"}/>
                <Datetime onChange={(e) => this.props.setToDate(Number(e))}  defaultValue={new Date()} viewDate={new Date()} dateFormat={"yyyy-MM-DD"} timeFormat={"HH:mm"}/>
            </div>
       </Fragment>
        )
    }
}
export default withRouter(EditCashbackConfig);