import {LoadingViewState, RouterView} from "../RouterView";
import {Credentials, Settings} from "../../http/protocol";
import {Link, RouteComponentProps} from "react-router-dom";
import {MetabaseService} from "../../services/MetabaseService";
import {getContainer} from "../../ioc/IOCSetup";
import {MetabaseDashboard} from "./MetabaseDashboardListView";
import {Collapsible} from "../Collapsible";
import React from "react";
import {LoadingSpinner} from "../../util/LoadingSpinner";
let jwt = require("jsonwebtoken");

interface State extends LoadingViewState {
    loading:boolean;
    settings?:Settings;
    url?:string;
    metabaseLoading: boolean;
    categorizedDashboards: CategorizedDashboards;
    favoriteDashboards: MetabaseDashboard[];
    selectedDashboard?: MetabaseDashboard;
    showMenu: boolean;
}

type CategorizedDashboards = { [category: string]: MetabaseDashboard[] }

interface Props extends RouteComponentProps{
}

function categorizeDashboards(dashboards: MetabaseDashboard[]): CategorizedDashboards {
    const categorizedDashboards: CategorizedDashboards = {};

    // Categorize dashboards
    dashboards.forEach(dashboard => {
        const parts = dashboard.name.split('-');
        const category = parts.length > 1 ? parts[0] : 'Others';

        if (!categorizedDashboards[category]) {
            categorizedDashboards[category] = [];
        }

        categorizedDashboards[category].push(dashboard);
    });

    // Sort categories alphabetically with 'Others' at the end
    const sortedKeys = Object.keys(categorizedDashboards).sort((a, b) => {
        if (a === 'Others') return 1; // Ensure 'Others' comes last
        if (b === 'Others') return -1; // Ensure 'Others' comes last
        return a.localeCompare(b); // Sort alphabetically
    });

    // Create a new object with sorted categories
    const sortedCategorized: CategorizedDashboards = {};
    sortedKeys.forEach(key => {
        sortedCategorized[key] = categorizedDashboards[key];
    });

    return sortedCategorized;
}

function loadFavoriteDashboardsFromLocalStorage(): MetabaseDashboard[] {
    const favoriteDashboardsJson = localStorage.getItem('favoriteDashboards');
    if (favoriteDashboardsJson) {
        return JSON.parse(favoriteDashboardsJson);
    } else {
        // If no favorite dashboards found in localStorage, return an empty array
        return [];
    }
}

function extractName(input: string | undefined): string {
    if (!input) return '';
    const index = input.indexOf('-');
    if (index !== -1) {
        return input.slice(index + 1).trimStart();
    } else  {return input}
}

export class MetabaseReportsPage extends RouterView<Props,State> {
    metabaseService: MetabaseService;
    constructor(props: Props) {
        super(props);
        this.metabaseService = getContainer().getMetabaseService();
        this.state = {loading: true, showMenu: true, metabaseLoading: false, favoriteDashboards: [], categorizedDashboards: {}};
    }


    getTitle(): string {
        return "Reports";
    }

    async loadContent(): Promise<void> {
        const result: MetabaseDashboard[] = await this.metabaseService.getMetabaseCardsList();
        const categorizedDashboards = categorizeDashboards(result);
        const favoriteDashboards = loadFavoriteDashboardsFromLocalStorage();
        this.setState({...this.state, favoriteDashboards: favoriteDashboards, categorizedDashboards: categorizedDashboards, loading: false});
    }

    toggleDashboardToFavorites(id: number, name: string) {
        // Retrieve the current list of favorite dashboards from localStorage
        const favoriteDashboardsJson = localStorage.getItem('favoriteDashboards');
        let favoriteDashboards: MetabaseDashboard[] = [];

        if (favoriteDashboardsJson) {
            // If there are already favorite dashboards stored in localStorage, parse the JSON
            favoriteDashboards = JSON.parse(favoriteDashboardsJson);
        }

        // Check if the dashboard with the given ID already exists in the favorites
        const index = favoriteDashboards.findIndex(dashboard => dashboard.id === id);

        if (index !== -1) {
            // If the dashboard exists in favorites, remove it
            favoriteDashboards.splice(index, 1);
        } else {
            // If the dashboard doesn't exist in favorites, add it
            // Create a new MetabaseDashboard object
            const newDashboard: MetabaseDashboard = {
                id: id,
                name: name
            };
            // Add the new dashboard to the array of favorite dashboards
            favoriteDashboards.push(newDashboard);
        }

        // Update the localStorage with the updated list of favorite dashboards
        localStorage.setItem('favoriteDashboards', JSON.stringify(favoriteDashboards));
        this.setState({...this.state, favoriteDashboards: favoriteDashboards})
    }

    renderCards(cards: CategorizedDashboards) {
        const hasFavorites = this.state.favoriteDashboards.length > 0;
        return (
            <div>
                <Collapsible isSmall={true} collapsed={!this.state.showMenu} title={`All Reports`}>
                    <div>
                        <Collapsible isSmall={true} collapsed={!hasFavorites}
                                     title={`Favorites (${this.state.favoriteDashboards.length})`}>
                            <table className="table table-sm table-borderless table-hover mb-0">
                                <tbody>
                                {this.state.favoriteDashboards.map((card) => {
                                    const isSelected = this.state.selectedDashboard?.id === card.id;
                                    return (
                                        <tr key={card.id}>
                                            <td
                                                style={{color: "gold"}}
                                                className={"fe-star-on p-0 pl-2"} // this is a star icon
                                                onClick={() => this.toggleDashboardToFavorites(card.id, card.name)}>
                                            </td>
                                            <td className={isSelected ? "font-weight-bold p-1" : 'p-1'}
                                                style={{color: '#6658dd', fontSize: '0.9em', cursor: 'pointer'}}
                                                onClick={() => this.loadReport(card.id)}>
                                                {extractName(card.name)}
                                            </td>
                                            <td className={'text-right p-1'}>
                                                <Link
                                                    title={"Open in new tab"}
                                                    target="_blank" // Open link in new tab
                                                    to={`/metabase/report/${card.id}`}>
                                                    <i className="fe-external-link"></i>
                                                </Link>
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </Collapsible>
                    </div>
                    <div>
                        {Object.keys(cards).map((category) => (
                            <div key={category}>
                                <div>
                                    <Collapsible collapsed={true}
                                                 isSmall={true}
                                                 title={`${category} (${cards[category].length})`}>
                                        <table className="table table-sm table-hover table-borderless mb-0">
                                            <tbody>
                                            {cards[category].map((card) => {
                                                const isFavorite = this.state.favoriteDashboards.some(favorite => favorite.id === card.id);
                                                const isSelected = this.state.selectedDashboard?.id === card.id;
                                                return (
                                                    <tr key={card.id}>
                                                        <td
                                                            style={{color: "gold"}}
                                                            className={isFavorite ? "fe-star-on p-0 pl-2" : "fe-star p-0 pl-2"} // this is a star icon
                                                            onClick={() => this.toggleDashboardToFavorites(card.id, card.name)}>
                                                        </td>
                                                        <td className={isSelected ? "font-weight-bold p-1" : 'p-1'}
                                                            style={{
                                                                color: '#6658dd',
                                                                fontSize: '0.9em',
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() => this.loadReport(card.id)}>
                                                            {extractName(card.name)}
                                                        </td>
                                                        <td className={'text-right p-1'}>
                                                            <Link
                                                                title={"Open in new tab"}
                                                                target="_blank" // Open link in new tab
                                                                to={`/metabase/report/${card.id}`}>
                                                                <i className="fe-external-link"></i>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                    </Collapsible>
                                </div>
                            </div>
                        ))}
                    </div>
                </Collapsible>
            </div>
        );
    }

    async loadReport(id: number) {
        this.setState({...this.state, metabaseLoading: true})
        const credential: Credentials = await this.metabaseService.getCredentials();
        const METABASE_SITE_URL = credential.url;
        const METABASE_SECRET_KEY = credential.secretKey;
        const payload = {
            resource: {question: Number(id)},
            params: {},
            exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
        };
        const token = jwt.sign(payload, METABASE_SECRET_KEY);
        const iframeUrl = METABASE_SITE_URL + "/embed/question/" + token + "#bordered=true&titled=true";

        // get name of selected dashboard
        let selectedDashboard = undefined;
        Object.keys(this.state.categorizedDashboards).forEach(category => {
            this.state.categorizedDashboards[category].forEach(dashboard => {
                if (dashboard.id === id) {
                    selectedDashboard = {...dashboard}
                }
            })
        })

        this.setState({...this.state, selectedDashboard: selectedDashboard, url: iframeUrl, metabaseLoading: false})
    }

    renderContent() {
        return (
            <div className="position-relative">
                <div className="row position-absolute w-100 mb-4" style={{ top: 0, zIndex: 100 }}>
                    <div className="col-6" style={{ paddingRight: '15px' }}>
                        <div>
                            {this.state.loading
                                ? <LoadingSpinner/>
                                : this.renderCards(this.state.categorizedDashboards)
                            }
                        </div>
                    </div>
                </div>

                <br/>
                <div className="row">
                    <div className="col-12 pt-4">
                        <div>
                            <iframe
                                src={this.state.url}
                                style={{ border: 0, width: '100%', height: '1000px' }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}