import * as React from "react";
import * as _ from "lodash";
import {FormElement, AbstractInputElement} from "./FormInput";


export class FormTextArea extends AbstractInputElement<string,FormElement<string,React.TextareaHTMLAttributes<any>>,{}> {

    getDefaultValue() : string {
        return "";
    }
    render() {
        let rest : React.TextareaHTMLAttributes<any> = _.omit(this.props,['validators',`model`, `fieldSet`, `modelValue`, `valid`, 'validationMap']);
        return <textarea {...rest} className={this.getClassNames()} value={this.getModelValue()} onChange={this.handleChange.bind(this)}></textarea>
    }

}

