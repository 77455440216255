import * as React from "react";
import {PagerModel} from "./PagerModel";

interface Props {
    model : PagerModel;
    onOffsetUpdate? : (page : number) => void;
}

export class Pager extends React.Component<Props, {}>{


    constructor(props : Props) {
        super(props);
    }

    getPages() : number[] {
        if(this.props.model.nrOfResults <= 0 || this.props.model.limit <= 0) {
            return [];
        }
        let pages = [];
        let nrOfPages = this.props.model.nrOfResults / this.props.model.limit;
        for (let i = 0; i < nrOfPages; i++) {
            pages.push(i);
        }
        return pages;
    };


    setOffset(page : number) {
        this.props.onOffsetUpdate && this.props.onOffsetUpdate(page * this.props.model.limit)
    }
    getPageButtonClass(page : any) : string {
        let css = "paginate_button page-item";
        let offset = this.props.model.offset;
        let limit = this.props.model.limit;

        if(page == (offset / limit) ) {
            css+=" active";
        }

        return css;
    }
    displayPage(page : number): boolean {
        let offset = this.props.model.offset ? this.props.model.offset  : 0;
        let limit = this.props.model.limit;
        let nrOfPages = this.props.model.nrOfResults / this.props.model.limit;

        if(nrOfPages < 20) {
            return true;
        }
        let currentPage = (offset / limit);
        if(page < 4 || page > nrOfPages - 5) {
            return true;
        } else if( page > currentPage-4 && page < currentPage + 4) {
            return true;
        } else {
            return false;
        }
    }

    getPageButtons() {
        let prev  = 0;
        let buttons : any[] = [];
        this.getPages().forEach((p,i)=>{
            if(!this.displayPage(p)){
                return false;
            }

            if(i - prev > 1){
                buttons.push(<li key={"space" + i} className="paginate_button page-item"><a>...</a></li>);
            }
            prev = i;
            buttons.push(
                (
                    <li key={"pager-"+p} onClick={this.setOffset.bind(this, p)} className={this.getPageButtonClass(p)}>
                        <a className={"page-link"}>
                            {p + 1}
                        </a>
                    </li>
                )
            );
        });
        return buttons;

    }
    previous(e : any) {
        if (this.props.model.offset - this.props.model.limit >=0 ) {
            this.props.onOffsetUpdate && this.props.onOffsetUpdate(this.props.model.offset - this.props.model.limit);
        }
    }
    next(e : any) {
        if (this.props.model.nrOfResults < 0 || this.props.model.offset + this.props.model.limit < this.props.model.nrOfResults) {
            this.props.onOffsetUpdate && this.props.onOffsetUpdate(this.props.model.offset + this.props.model.limit);
        }
    }
    render() {
        return (
            <div className="dataTables_paginate paging_simple_numbers">
                <ul className="pagination pagination-rounded">
                    <li className="paginate_button page-item previous"  onClick={this.previous.bind(this)}>
                        <a tabIndex={0} className={"page-link"}>
                            <i className="mdi mdi-chevron-left"></i>
                        </a>
                    </li>
                    {this.getPageButtons()}
                    <li className="paginate_button page-item next" onClick={this.next.bind(this)}>
                        <a  tabIndex={0} className={"page-link"}>
                            <i className="mdi mdi-chevron-right"></i>
                        </a>
                    </li>
                </ul>
            </div>
        );
    }

}

export class NextPreviousPager extends React.Component<Props, {}>{
    constructor(props : Props) {
        super(props);
    }

    getPages() : number[] {
        if(this.props.model.nrOfResults <= 0 || this.props.model.limit <= 0) {
            return [];
        }
        let pages = [];
        let nrOfPages = this.props.model.nrOfResults / this.props.model.limit;
        for (let i = 0; i < nrOfPages; i++) {
            pages.push(i);
        }
        return pages;
    };


    setOffset(page : number) {
        this.props.onOffsetUpdate && this.props.onOffsetUpdate(page * this.props.model.limit)
    }
    getPageButtonClass(page : any) : string {
        let css = "paginate_button page-item";
        let offset = this.props.model.offset;
        let limit = this.props.model.limit;

        if(page == (offset / limit) ) {
            css+=" active";
        }

        return css;
    }
    previous(e : any) {
        if (this.props.model.offset - this.props.model.limit >=0 ) {
            this.props.onOffsetUpdate && this.props.onOffsetUpdate(this.props.model.offset - this.props.model.limit);
        }
    }
    next(e : any) {
        this.props.onOffsetUpdate && this.props.onOffsetUpdate(this.props.model.offset + this.props.model.limit);
    }
    render() {
        return (
            <div className="dataTables_paginate paging_simple_numbers">
                <ul className="pagination pagination-rounded">
                    <li className="paginate_button page-item previous"  onClick={this.previous.bind(this)}>
                        <a tabIndex={0} className={"page-link"} style={{cursor : "pointer"}}>
                            <i className="mdi mdi-chevron-left"></i> Previous
                        </a>
                    </li>
                    <li className="paginate_button page-item next" onClick={this.next.bind(this)}>
                        <a  tabIndex={0} className={"page-link"}  style={{cursor : "pointer"}}>
                            Next <i className="mdi mdi-chevron-right"></i>
                        </a>
                    </li>
                </ul>
            </div>
        );
    }
}