import * as React from "react";
import {SettingsService} from "../../services/SettingsService";
import {getContainer} from "../../ioc/IOCSetup";
import {LoadingViewState, RouterView} from "../RouterView";
import {Form, FormListener} from "../form/Form";
import {RouteComponentProps, withRouter} from "react-router";
import {Fragment} from "react";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Submit} from "../form/Submit";
import {Settings} from "../../http/protocol";
import {t} from "../../i18n";
import {Link} from "react-router-dom";


interface Props extends RouteComponentProps {

}

interface State extends LoadingViewState{
    settings?: Settings;
    validationMap? : any;

}

export class SettingsUserView extends RouterView<Props,State> implements FormListener<Settings>{

    settingsService: SettingsService;

    constructor(props:Props) {
        super(props);

        this.settingsService = getContainer().getSettingsService();

        this.state = {validationMap : {}, loading : true};
    }


    loadContent(): Promise<any> | undefined {
        return this.settingsService.getSettings().then((data) => {
            this.setState({settings:data});
        });
    }
    componentDidUpdate(prevProps : RouteComponentProps, prevState : any) {
        super.componentDidUpdate(prevProps, prevState);

    }

    formDidUpdate(formModel:Settings, validationMap : any) {
        this.setState({ settings : formModel, validationMap : validationMap});
    }

    onSubmit():Promise<any> {
        if(!this.state.settings) {
            return Promise.reject("Unable to save settings");
        }
        return this.settingsService.saveSettings(this.state.settings).catch(err=>{
            throw "Unable to create/save settings";
        });
    }

    onSubmitError(){}

    getTitle() : string {
        return t(`title.userSettings`);
    }

    renderContent() {
        if(!this.state.settings) {
            return <span></span>
        }
        return(
            <Fragment>
                <Form className="form-horizontal" formListener={this} modelObject={this.state.settings} validationMap={this.state.validationMap}>
                    <div className={"card"}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <FormSubmitError/>
                                    <FormSubmitSuccess text="Settings was updated successfully"/>
                                    <FormGroup className="custom-control custom-checkbox"
                                               model="requireUserChannelVerification">
                                        <FormInput className="custom-control-input"
                                                   model={`requireUserChannelVerification`}
                                                   id="requireUserChannelVerification" type="checkbox"/>
                                        <label htmlFor="requireUserChannelVerification"
                                               className={"custom-control-label"}>Require User Channel
                                            Verification</label><br/>
                                        <span className="help-block">User need to verify their account with either phone or email before logging in and depositing</span>
                                    </FormGroup>
                                    <FormGroup className="form-group mb-3" model={"maxVerificationAttempts"}>
                                        <label>Maximum code verification attempts</label>
                                        <FormInput className="form-control" model={"maxVerificationAttempts"}
                                                   type="number" id="maxVerificationAttempts"/>
                                        <span className={"help-block"}>Verification code will reset and use will need to request a new code if failed attempts exceed this number</span>
                                    </FormGroup>
                                    <FormGroup className="custom-control custom-checkbox" model="emailSignupAvailable">
                                        <FormInput className="custom-control-input" model={`emailSignupAvailable`}
                                                   id="emailSignupAvailable" type="checkbox"/>
                                        <label htmlFor="emailSignupAvailable" className={"custom-control-label"}>Email
                                            signup available</label><br/>
                                        <span className="help-block">User can signup with email</span>
                                    </FormGroup>
                                    <FormGroup className="custom-control custom-checkbox" model="phoneSignupAvailable">
                                        <FormInput className="custom-control-input" model={`phoneSignupAvailable`}
                                                   id="phoneSignupAvailable" type="checkbox"/>
                                        <label htmlFor="phoneSignupAvailable" className={"custom-control-label"}>Phone
                                            signup available</label><br/>
                                        <span className={"help-block"}>User can signup with phone</span>
                                    </FormGroup>

                                    <FormGroup className="custom-control custom-checkbox" model="tokenLoginEnabled">
                                        <FormInput className="custom-control-input" model="tokenLoginEnabled"
                                                   id={"tokenLoginEnabled"} type="checkbox"/>
                                        <label htmlFor={"tokenLoginEnabled"} className={"custom-control-label"}>Token
                                            Login Enabled</label><br/>
                                        <span className={"help-block"}>Enables 'remember me' token login for mobile PWA apps</span>
                                    </FormGroup>

                                    <FormGroup className="custom-control custom-checkbox" model="mfaEnabled">
                                        <FormInput className="custom-control-input" model="mfaEnabled" id={"mfaEnabled"}
                                                   type="checkbox"/>
                                        <label htmlFor={"mfaEnabled"} className={"custom-control-label"}>Two Factor
                                            Authentication (2FA) Enabled</label><br/>
                                        <span className={"help-block"}>Allows users to enable 2FA on their accounts for added security. OTP and Email supported.</span>
                                    </FormGroup>

                                    <FormGroup className="custom-control custom-checkbox"
                                               model="displayFiatExchangeByDefault">
                                        <FormInput className="custom-control-input" model="displayFiatExchangeByDefault"
                                                   id={"displayFiatExchangeByDefault"} type="checkbox"/>
                                        <label htmlFor={"displayFiatExchangeByDefault"}
                                               className={"custom-control-label"}>Display FIAT Exchanged Currency by
                                            Default</label><br/>
                                        <span className={"help-block"}>If enabled, players will default to see fiat exchanged amount in the top menu bar for the crypto balances. They can still choose in the profile menu to see crypto value directly.</span>
                                    </FormGroup>

                                    <FormGroup className="form-group mb-3" model={"minimumAge"}>
                                        <label>Minimum Player Age Allowed</label>
                                        <FormInput className="form-control" model={"minimumAge"} type="text"
                                                   id="minimumAge"/>
                                        <span className={"help-block"}>If user attribute 'dateOfBirth' is set on User then it will be checked on signup so that minimum age is met.</span>
                                    </FormGroup>

                                    <hr/>
                                    <h4>KYC Settings</h4><br/>
                                    <FormGroup className="custom-control custom-checkbox" model="kycOnDepositRequired">
                                        <FormInput className="custom-control-input" model="kycOnDepositRequired"
                                                   id={"kycOnDepositRequired"} type="checkbox"/>
                                        <label htmlFor={"kycOnDepositRequired"} className={"custom-control-label"}>KYC
                                            Required for Deposit</label><br/>
                                        <span className={"help-block"}>User needs to provide identity documents in order to withdraw funds</span>
                                    </FormGroup>

                                    <FormGroup className="custom-control custom-checkbox" model="kycOnWithdrawRequired">
                                        <FormInput className="custom-control-input" model="kycOnWithdrawRequired"
                                                   id={"kycOnWithdrawRequired"} type="checkbox"/>
                                        <label htmlFor={"kycOnWithdrawRequired"} className={"custom-control-label"}>KYC
                                            required for Withdraw</label><br/>
                                        <span className={"help-block"}>User needs to provide identity documents in order to deposit funds</span>
                                    </FormGroup>

                                    <FormGroup className="custom-control custom-checkbox" model="kycOnPlayRequired">
                                        <FormInput className="custom-control-input" model="kycOnPlayRequired"
                                                   id={"kycOnPlayRequired"} type="checkbox"/>
                                        <label htmlFor={"kycOnPlayRequired"} className={"custom-control-label"}>KYC
                                            required to play</label><br/>
                                        <span className={"help-block"}>User needs to provide identity documents in order to play any games</span>
                                    </FormGroup>

                                    <span className={"help-block"}>You can configure KYC requirement for specific withdraw/deposit methods in <Link to={"/admin/settings/advanced"}>Advanced Config</Link></span>

                                    <hr/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"card"}>
                        <div className={"card-body"}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="ibox float-e-margins">
                                        <div className="ibox-content">
                                            <div className="ibox-title">
                                                <h5>Actions</h5>
                                            </div>
                                            <div className="form-group">
                                                <div className="col-sm-10 col-sm-offset-2">
                                                    <Submit className="btn btn-primary waves-effect waves-light">Save Settings</Submit>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Form>


            </Fragment>
        )
    }

}

export default withRouter(SettingsUserView);