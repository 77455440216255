import React from "react";
import {getContainer} from "../../ioc/IOCSetup";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {LoadingViewState, RouterView} from "../RouterView";
import {UserService} from "../../services/UserService";
import {BatchTransactionService} from "../../services/BatchTransactionService";
import {TransactionType, TxBatchError, TxBatchResult, TxBatchRowResult, TxBatchStatus} from "../../http/protocol";
import {t} from "../../i18n";


interface BatchForm {
    file? : any;
    transactionType? : string;
    comment?: string;
}
interface State extends LoadingViewState {
    form? : BatchForm;
    invalidFile?: boolean;
    formError? : boolean;
    failedBatch?: TxBatchResult;
    inProgress?: boolean;
    results? : TxBatchResult[];
}

interface Props extends RouteComponentProps{

}
const values = [TransactionType.CORRECTION, TransactionType.BONUS];
export class CreateBatchTransactionView extends RouterView<Props, State>{

    batchService: BatchTransactionService;
    userService: UserService;

    constructor(props : Props) {
        super(props);
        this.batchService = getContainer().getBatchTransactionService();
        this.userService = getContainer().getUserService();
        this.state = { form : { transactionType : TransactionType.CORRECTION, comment : ""}, invalidFile : false, inProgress : false, results : []};
    }

    loadContent() : Promise<any> | void {
        return this.batchService.getAllCachedBatchTransferResults().then( r=> {
            this.setState({ results  : r});
        })

    }

    getTitle(): string {
        return "Batch Transaction";
    }
    readFile(file : File) : Promise<string> {
        return new Promise((resolve, reject)=>{
            var reader = new FileReader();
            reader.readAsText(file);
            reader.onload = (e : any)=> {
                if(e.target && e.target.result) {
                   resolve(e.target.result);
                } else {
                    reject("Unable to read file");
                }
            }
            reader.onerror = ()=> reject("Error reading file");
            reader.onabort = ()=> reject("Abort reading file");
        });

    }
    onSubmit(e : any) {
        console.log("On submit", e);
        if(this.state.inProgress) {
            return false;
        }
        if(!this.state.form || !this.state.form.comment  || !this.state.form.transactionType || !this.state.form.file) {
            this.setState({ formError : true});
            return false;
        }
        if(this.state.form.comment.trim().length == 0) {
            this.setState({ formError : true});
            return false;
        }


        let transactionType : TransactionType = this.state.form.transactionType as TransactionType;
        let comment : string = this.state.form.comment;
        if(this.state.form && transactionType && comment && this.state.form.file)  {
            this.setState({ inProgress : true, formError : false });
            this.readFile(this.state.form.file).then((fileContent : string)=>{
                this.batchService.prepareBatchTransfer({
                    csv : fileContent,
                    transactionType : transactionType,
                    comment : comment
                }).then( (r : TxBatchResult)=>{
                    if(r.status == "FAILED") {
                        this.setState({ inProgress : false });
                        this.setState({ failedBatch : r});
                    } else {
                        this.props.history.push("/batch-transaction/"+r.batchId);
                    }
                }).catch(e => {
                    this.setState({  inProgress : false, failedBatch : { status : TxBatchStatus.FAILED, errorMessage : "500 exception from server", rows : [], error : TxBatchError.GENERAL}});
                });
            })

        }
        return false;
    }
    onFileChange(e : any) {
        var files = e.target.files;
        console.log(files);
        if(files.length == 1) {
            let fileError = false;
            let form = Object.assign({}, this.state.form);
            form.file = files[0];
            if(form.file.name){
                let fileName : string = form.file.name;
                if(!fileName.toLowerCase().endsWith(".csv")) {
                    fileError = true;
                }
            }
            this.setState({form : form, invalidFile : fileError});
        }
    }
    updateType (type : string) {
        let form = Object.assign({}, this.state.form);
        form.transactionType = type;
        this.setState({ form : form})
    }
    updateComment(comment : string) {
        let form = Object.assign({}, this.state.form);
        form.comment = comment;
        this.setState({ form : form});
    }
    removeFile() {
        let form = Object.assign({}, this.state.form);
        form.file = undefined;
        this.setState({ form : form})
    }
    renderContent() {

            if(!this.state.form) {
                return <div/>
            }


            return (
                <div>
                    <div className={"card"}>
                        <div className="card-body">
                            <h3>Batches</h3>
                            {this.state.results && this.state.results.length == 0 && (
                                <div>Currently no batches</div>
                            )}
                            {this.state.results && this.state.results.length > 0 && (
                                <div>
                                    <table className={"table table-striped"}>
                                        <thead>
                                        <tr>
                                            <th>Batch ID</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        { this.state.results.map( r => (
                                            <tr key={r.batchId}>
                                                <td>{r.batchId}</td>
                                                <td>{r.status}</td>
                                                <td><Link to={"/batch-transaction/"+r.batchId} className={"btn btn-primary"}>{t(`button.show`)}</Link></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={"card"}>
                        <div className="card-body">
                            <h3>Perform Batch</h3>
                            Perform a batch of transactions from/to user accounts.<br/>
                            <br/>
                            The batch job must be defined in a CSV file with the following format:<br/>
                            <figure style={{ background : "#eee", padding : "15px 15px"}}>
                            <pre>
                                <code >
                                    userId,amount,currency<br/>
                                    8,25.20,SEK<br/>
                                    7,-7,EUR
                                </code>
                            </pre>
                            </figure>
                            {!this.state.failedBatch && (
                                <form onSubmit={(e)=> { e.preventDefault(); this.onSubmit(e)}}>
                                    <div className={"form-group"}>
                                        <label>Type</label>
                                        <select value={this.state.form.transactionType} onChange={(e)=>this.updateType(e.target.value)} className={"form-control"}>
                                            <option>CORRECTION</option>
                                            <option>BONUS</option>
                                        </select>
                                        <span className={"help-block"}>What type of batch transactions you are performing</span>
                                    </div>
                                    <div className={"form-group"}>
                                        <label>Comment</label>
                                        <input value={this.state.form.comment} onChange={(e)=>this.updateComment(e.target.value)} className={"form-control"}/>
                                        <span className={"help-block"}>The comment you want to add to each transaction</span>
                                    </div>
                                    <div className={"form-group"}>
                                        <label>Batch File:</label><br/>
                                        {!this.state.form || !this.state.form.file && (
                                            <label className="btn btn-secondary">
                                                <input type="file" style={{display: "none"}} name="file" onChange={(e)=> this.onFileChange(e)}/>
                                                <i className="fe-upload-cloud" /> Select CSV-file
                                            </label>
                                        )}
                                        {this.state.form && this.state.form.file && (
                                            <div className={"alert " + (this.state.invalidFile ? "alert-danger" : "alert-info")}>
                                                {this.state.invalidFile ? "File seems to be of the wrong type:" : ""} <strong>{this.state.form.file.name}</strong> <a href={"#"} onClick={()=>this.removeFile()}>Remove File</a>
                                            </div>
                                        )}

                                    </div>
                                    {this.state.formError && (
                                        <div className={"alert alert-danger"}>
                                            Invalid batch, all fields are required
                                        </div>
                                    )}
                                    <input type={"submit"} className={"btn btn-primary"} value={"Prepare Batch"}/>
                                </form>
                            )}
                            {this.state.failedBatch && (
                                <div>
                                    <div className={"alert alert-danger"}>Batch has failed: {this.state.failedBatch.errorMessage}</div>
                                    <table className={"table table-striped"}>
                                        <thead>
                                        <tr>
                                            <th>User Id</th>
                                            <th>Amount</th>
                                            <th>Error</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        { this.state.failedBatch.rows && this.state.failedBatch.rows.filter(r=>(r.error)).map((r : TxBatchRowResult,index : number) => {
                                            return (
                                                <tr key={"indx"+index}>

                                                    <td>{r.row && r.row.userId}</td>
                                                    <td>{r.row && r.row.amount}</td>
                                                    <td>{r.error}</td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                    <button className={"btn btn-primary"} onClick={()=>this.setState({ failedBatch : undefined })}>Continue</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
        );
    }

}

export default withRouter(CreateBatchTransactionView);
