export enum ColumnType {
    ENUM,
    DATE,
    TEXT,
    BIG,
    IMAGE,
    LINK,
    LIST,
    NOTE,
    MONEY,
    BOOLEAN,
    YES_OR_NO,
    COMPONENT
}