import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {HttpService} from "../http/HttpService";
import {InstantWithdrawStatus, InternalSettings, ListResponse} from "../http/protocol";

export class InstantWithdrawalService extends AbstractAdminEntityService<void,ListResponse> implements InstantWithdrawalService{
    constructor(http: HttpService) {
        super("/instant-withdrawal", http);
    }

    getStatus(): Promise<InstantWithdrawStatus> {
        return this.http.get(`${this.entityPath}/service/status`);
    }

    isEnabled(): Promise<boolean> {
        return this.http.get(`${this.entityPath}/settings/enabled`);
    }

    setEnabled(enabled: boolean): Promise<InstantWithdrawStatus> {
        return this.http.put(`${this.entityPath}/settings/enabled/${enabled}`, {});
    }

}