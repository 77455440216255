import React from "react";
import {getContainer} from "../../ioc/IOCSetup";
import {ListRequest, UserBonusStatus, UserBonusView} from "../../http/protocol";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {ColumnType} from "../list/list/ColumnType";
import {ActionType} from "../list/list/DataTable";
import {UserBonusService} from "../../services/UserBonusService";
import { Link } from "react-router-dom";
import {t} from "../../i18n";

interface State  {
    bonusOffers: UserBonusView[]
}

interface Props {
    userId:number
}



export class UserBonusHistory extends React.Component<Props,State> {
    bonusService:UserBonusService

    columns = [
        { property : "id", name : "ID", type: ColumnType.LINK, getUrl: (item : any) => `/bonus/view/${item.id}`, linkClass: "list-link"},
        { property : "timestamp", name : "Created", type : ColumnType.DATE},
        { property : "bonusConfig.name", name: "Name"},
        { property : "bonusConfig.type", name: "Type"},
        { property : "status", name : "Status"},
        { property : "totalBonusAmount", name : "Amount", type: ColumnType.MONEY, secondProperty: "currency"},
        { property : "currentTurnover", name : "Current Turnover"},
        { property : "turnoverRequirement", name : "Turnover Requirement"},
    ];

    actions = [
        { id : "bonus", name : t(`button.view`), className : "btn btn-primary btn-xs", iconClassName : "fa fa-eye", type : ActionType.LINK, getLink : (item : any) => "/bonus/view/" + item.id },
        { id : "events", name : t(`button.events`), className : "btn btn-primary btn-xs", iconClassName : "fa fa-eye", type : ActionType.LINK, getLink : (item : any) => "/bonus/events/" + item.id },
        { id : "cancel", name : t(`button.cancel`), className : "btn btn-danger btn-xs", iconClassName : "fa fa-times", type : ActionType.CANCEL, optional : (item : any) => item.status === UserBonusStatus.RUNNING }
    ];

    constructor(props:Props) {
        super(props);
        this.bonusService = getContainer().getUserBonusService()
        this.state = {bonusOffers:[]}
    }


    async onAction(item : UserBonusView, action : string) {
        if (action == "cancel") {
            await (item.id  &&  this.bonusService.cancelUserBonus(this.props.userId, item?.id))
            document.location.reload();
        }
    }

    render() {
        return(
            <div className="col-lg-12">
                <div className={"card"}>
                    <div className="card-header">
                        <h4>{t(`title.bonusHistory`)}</h4>

                        <Link className={"btn btn-primary"} to={`/bonus/offers/list/userId/${this.props.userId}`}>{t(`button.offers`)}</Link>

                    </div>
                    <EntityDataTable
                        service={this.bonusService}
                        listRequest={{orderBy:"timestamp", ascending:false, userId: this.props.userId, limit:10} as ListRequest}
                        columns={this.columns}
                        onPagerChange={() => console.log("")}
                        onAction={this.onAction.bind(this)}
                        actions={this.actions}
                    />
                </div>
            </div>
        )
    }
}

export default UserBonusHistory;