/* tslint:disable */
/* eslint-disable */

export interface AbuseReportRequest {
    abuseTypes?: AbuseType[];
    blockUser?: boolean;
    comment?: string;
    userId?: number;
}

export interface Account extends Serializable {
    attributes?: { [index: string]: string };
    closed?: Date;
    created?: Date;
    currencyCode?: string;
    id?: number;
    information?: AccountInformation;
    negativeAmountAllowed?: boolean;
    operatorId?: number;
    status?: AccountStatus;
    subtype?: string;
    type?: AccountType;
    typeId?: number;
    userId?: number;
}

export interface AccountBalanceResult extends Serializable {
    accountAttributes?: { [index: string]: string };
    accountId?: number;
    balance?: Money;
}

export interface AccountConstraints {
    statuses?: AccountStatus[];
    subtypes?: string[];
    types?: AccountType[];
}

export interface AccountInformation extends Serializable {
    name?: string;
}

export interface AccountTransferRequest {
    amount?: number;
    comment?: string;
    fromAccount?: number;
    toAccount?: number;
}

export interface ActiveLocales {
    defaultLocale?: string;
    locales?: string[];
}

export interface AdminMfaRequest {
    provider?: AdminMfaProvider;
    verificationCode?: number;
}

export interface AdminNote {
    archived?: boolean;
    authorAdminUserId?: number;
    authorName?: string;
    comment?: string;
    created?: Date;
    id?: number;
    pinned?: boolean;
    type?: string;
    typeId?: string;
}

export interface AdminScriptWidget {
    enabled?: boolean;
    id?: number;
    integrationApiKey?: string;
    integrationUrl?: string;
    scriptUrl?: string;
    variableName?: string;
}

export interface AdminScriptWidgetListResponse extends ListResponse {
    items?: AdminScriptWidget[];
}

export interface AdminSecuritySettings {
    otpRequirement?: OtpRequirement;
}

export interface AdminSession {
    adminId?: number;
    agent?: string;
    created?: Date;
    lastAccess?: Date;
    remoteAddr?: string;
}

export interface AdminSettings {
    currencies?: CurrencyDefinition[];
}

export interface AdminUser extends Principal, Serializable {
    adminMfaProvider?: AdminMfaProvider;
    authAppMfaVerified?: boolean;
    blocked?: boolean;
    displaySensitiveInformation?: boolean;
    email?: string;
    encryptedAuthAppMfaSecret?: string;
    firstName?: string;
    groups?: number[];
    id?: number;
    lastName?: string;
    password?: string;
    passwordExpired?: boolean;
}

export interface Affiliate {
    agentsEnabled?: boolean;
    agentsKycEnabled?: boolean;
    cashoutUserId?: number;
    cashoutUsername?: string;
    cpaCap?: number;
    cpaDepositRequirement?: number;
    cpaPayoutAmount?: number;
    cpaTurnoverRequirement?: number;
    created?: Date;
    defaultRevenueShare?: number;
    depositLimit?: number;
    email?: string;
    enabled?: boolean;
    id?: number;
    name?: string;
    newPassword?: string;
    operatorId?: number;
    payoutCurrency?: string;
    payoutModel?: AffiliatePayoutModel;
    recipientAddress?: string;
    recipientNetwork?: string;
    status?: AffiliateStatus;
    username?: string;
    withdrawLimit?: number;
}

export interface AffiliatesListRequest extends BaseListRequest {
    payoutModel?: AffiliatePayoutModel;
    searchString?: string;
    status?: AffiliateStatus;
    userId?: number;
}

export interface AffiliatesListResponse extends BaseListResponse<Affiliate> {
    items?: Affiliate[];
}

export interface AffiliatesSettings {
    approvalRequired?: boolean;
    defaultAffiliateRevenueSharePercentage?: number;
    defaultCpaCap?: number;
    defaultCpaDepositRequirement?: number;
    defaultCpaPayoutAmount?: number;
    defaultCpaTurnoverRequirement?: number;
    defaultDepositLimit?: number;
    defaultPayoutCurrency?: string;
    defaultWithdrawLimit?: number;
    enabled?: boolean;
    enabledCurrencies?: string;
    enabledCurrenciesList?: string[];
    id?: number;
    linkURL?: string;
    logoUrl?: string;
    negativeCarryOverEnabled?: boolean;
    networkCostPercentage?: number;
    selectablePayoutCurrencies?: string;
    selectablePayoutNetworks?: string;
    supportEmail?: string;
}

export interface AssignRewardToUserRequest {
    amount?: number;
    currencyCode?: string;
    gameId?: number;
    nrOfFreeSpins?: number;
    reason?: RewardReason;
    rewardTemplateId?: number;
    userId?: number;
}

export interface AuditLog {
    actorEmail?: string;
    actorId?: number;
    actorIp?: string;
    actorType?: AuditEntityType;
    affectedObjectId?: string;
    affectedObjectType?: AuditObjectType;
    description?: string;
    eventName?: AuditEventSubType;
    id?: number;
    method?: string;
    path?: string;
    request?: string;
    targetId?: number;
    targetType?: AuditEntityType;
    timestamp?: Date;
}

export interface AuthResult {
    adminUser?: AdminUser;
    loginResp?: LoginResponse;
    token?: string;
}

export interface AwardBonusOfferRequest {
    amount?: number;
    bonusConfigId?: number;
    claimableOnNextDeposit?: boolean;
    expiryInDays?: number;
    fromDeposit?: boolean;
    id?: number;
    userId?: number;
}

export interface BaseListRequest {
    ascending?: boolean;
    limit?: number;
    offset?: number;
    orderBy?: string;
}

export interface BaseListResponse<E> {
    items?: E[];
    nrOfResults?: number;
}

export interface BonusConfig {
    blockUponOtherClaims?: boolean;
    bonusAlertDescription?: string;
    bonusStatusAlert?: BonusStatusAlert;
    description?: string;
    expiresAfterDays?: number;
    hasOfferedBonus?: boolean;
    id?: number;
    imageUrl?: string;
    monetaryConfig?: MonetaryConfig;
    name?: string;
    status?: BonusStatus;
    summary?: string;
    translations?: BonusConfigTranslation[];
    trigger?: BonusTrigger;
    turnoverContributions?: TurnoverContributions;
    type?: BonusLogicType;
    validFromDate?: Date;
    validToDate?: Date;
}

export interface BonusConfigListRequest extends ListRequest {
    bonusVisibilityStatus?: BonusVisibilityStatus;
    status?: BonusStatus;
}

export interface BonusConfigListResponse extends ListResponse {
    items?: BonusConfig[];
}

export interface BonusConfigTranslation {
    description?: string;
    id?: number;
    imageUrl?: string;
    language?: string;
    name?: string;
    summary?: string;
}

export interface BonusOffer {
    amount?: number;
    bonusConfig?: BonusConfig;
    currency?: string;
    expires?: Date;
    id?: number;
    inputAmount?: number;
    status?: BonusOfferStatus;
    timestamp?: Date;
    turnoverRequirement?: number;
    userId?: number;
}

export interface BonusOfferListRequest extends ListRequest {
    status?: BonusOfferStatus;
    userId?: number;
}

export interface BonusOfferListResponse extends ListResponse {
    items?: BonusOffer[];
}

export interface BonusTrigger {
    count?: number;
    event?: string;
    expiresAfterDays?: number;
    id?: number;
    name?: string;
    triggerType?: RuleTriggerType;
}

export interface BonusTriggerListRequest extends ListRequest {
}

export interface BonusTriggerListResponse extends ListResponse {
    items?: BonusTrigger[];
}

export interface Bundle {
    awardFunds1?: Monetary;
    awardFunds2?: Monetary;
    awardPokerItemId?: string;
    awardPokerItemList?: PokerItem[];
    created?: Date;
    description?: string;
    enabled?: boolean;
    featured?: boolean;
    id?: number;
    imageUrl?: string;
    label?: string;
    name?: string;
    nameId?: string;
    price?: Monetary;
    type?: BundleType;
}

export interface CashbackCasinoTagExclusion {
    excludeCashback?: boolean;
    excludeRakeback?: boolean;
    id?: number;
    tagNameId?: string;
}

export interface CashbackConfig {
    cashRewardId?: number;
    defaultAwardFraction?: number;
    frequency?: CashbackFrequency;
    id?: number;
    levels?: { [index: string]: number };
    lifetimeLossOnly?: boolean;
    name?: string;
    payoutDayOfMonth?: number;
    payoutDayOfWeek?: DayOfWeek;
    payoutTime?: string;
    products?: string;
    status?: BonusStatus;
    type?: CashbackType;
}

export interface CashbackConfigListRequest extends ListRequest {
    bonusVisibilityStatus?: BonusVisibilityStatus;
    status?: BonusStatus;
}

export interface CashbackConfigListResponse extends ListResponse {
    items?: CashbackConfig[];
}

export interface CashbackLog {
    configId?: number;
    created?: Date;
    executionTimeSeconds?: number;
    id?: number;
    periodEnd?: Date;
    periodStart?: Date;
    scheduled?: Date;
    status?: CashbackLogStatus;
}

export interface CashbackLogListRequest extends ListRequest {
}

export interface CashbackLogListResponse extends ListResponse {
    items?: CashbackLog[];
}

export interface CashbackLogRerunRequest {
    cashbackLogId?: number;
}

export interface CashbackPayout {
    amount?: number;
    cashbackLogId?: number;
    configId?: number;
    configName?: string;
    created?: Date;
    currency?: string;
    id?: number;
    inputAmount?: number;
    rate?: number;
    status?: CashbackPayoutStatus;
    userId?: number;
    userLevel?: number;
}

export interface CashbackPayoutListResponse extends ListResponse {
    items?: CashbackPayout[];
}

export interface CashbackPayoutRequest extends ListRequest {
    cashbackLogId?: number;
    userId?: number;
}

export interface CashbackSingleCurrencyPayoutListResponse extends ListResponse {
    items?: SingleCurrencyCashbackPayout[];
}

export interface CasinoGame {
    allowedCountries?: string;
    aspectRatio?: CasinoGameAspectRatio;
    attributes?: { [index: string]: string };
    backgroundImageUrl?: string;
    blockedCountries?: string;
    created?: Date;
    description?: string;
    desktop?: boolean;
    display?: GameDisplay;
    enabled?: boolean;
    externalId?: string;
    freeSpinsSupported?: boolean;
    gameTags?: GameTag[];
    gameType?: CasinoGameType;
    id?: number;
    imageUrl?: string;
    imageVariantLinks?: { [index: string]: string };
    imageVariants?: string;
    integration?: GameIntegration;
    jurisdictions?: Jurisdiction[];
    mobile?: boolean;
    mobileExternalId?: string;
    mobileNavigation?: CasinoMobileNavigation;
    name?: string;
    playForFun?: boolean;
    promoImageUrl?: string;
    releaseDate?: Date;
    removed?: boolean;
    studio?: CasinoStudio;
    translations?: CasinoGameTranslation[];
    updated?: Date;
}

export interface CasinoGameTranslation {
    description?: string;
    id?: number;
    imageUrl?: string;
    language?: string;
    name?: string;
}

export interface CasinoGamesUpdateLog {
    batchId?: string;
    finished?: Date;
    id?: number;
    integration?: GameIntegration;
    nrOfGameErrors?: number;
    nrOfGamesRemoved?: number;
    nrOfGamesUpdated?: number;
    nrOfNewGamesAdded?: number;
    started?: Date;
    status?: GamesUpdateStatus;
}

export interface CasinoLobbyCategory {
    alwaysRenderVertically?: boolean;
    categoryType?: CasinoLobbyCategoryType;
    contentKey?: string;
    gameOrder?: GameOrder;
    gameTypes?: CasinoGameType[];
    games?: number[];
    id?: number;
    imgVariant?: Style;
    itemView?: number;
    lobbyOrder?: number;
    matchAllSelectedTags?: boolean;
    maxNrOfGames?: number;
    name?: string;
    studioIds?: number[];
    studios?: number[];
    surpriseEnabled?: boolean;
    tags?: number[];
    translationKey?: string;
    translations?: { [index: string]: string };
}

export interface CasinoPage {
    defaultPage?: boolean;
    gameTags?: number[];
    games?: number[];
    hideCountryRestrictedGames?: boolean;
    id?: number;
    name?: string;
    singleTab?: CasinoPageTab;
    studios?: number[];
    tabs?: CasinoPageTab[];
}

export interface CasinoPageTab {
    categories?: CasinoLobbyCategory[];
    id?: number;
    name?: string;
    translations?: { [index: string]: string };
}

export interface CasinoStudio {
    allowedCountries?: string;
    blockedCountries?: string;
    blockedCurrencies?: string;
    enabled?: boolean;
    externalId?: string;
    gameTagsToBeAdded?: string;
    gameTagsToBeRemoved?: string;
    icon?: string;
    id?: number;
    imageUrl?: string;
    integration?: GameIntegration;
    lobbyOrder?: number;
    mobileNavigation?: boolean;
    name?: string;
    popular?: boolean;
    promoImageUrl?: string;
    providerBlockedCountries?: string;
}

export interface ChangePasswordLoginRequest {
    loginRequest?: LoginRequest;
    oldPassword?: string;
    otp?: string;
}

export interface Chart {
    externalId?: string;
    id?: number;
    name?: string;
    segmentTitles?: string;
    tags?: string[];
    type?: ChartType;
}

export interface ChronoPeriod extends TemporalAmount {
    chronology?: Chronology;
    negative?: boolean;
    zero?: boolean;
}

export interface Chronology extends Comparable<Chronology> {
    calendarType?: string;
    id?: string;
    isoBased?: boolean;
}

export interface ClientConfig {
    enabledPermissionCategories?: PermissionCategory[];
    environmentType?: EnvironmentType;
}

export interface ComboboostDataItem {
    is_global?: boolean;
    min_odd?: number;
    multipliers?: number[];
    total_multiplier?: number;
}

export interface Comparable<T> {
}

export interface ContentfulGlobalModel {
    brandName?: string;
    logo?: string;
    logoDark?: string;
}

export interface Contribution {
    contributionFactor?: number;
    description?: string;
    excludeCasinoTags?: string;
    id?: number;
    includeCasinoTags?: string;
    liveBetting?: boolean;
    maxOdds?: number;
    minOdds?: number;
    preMatchBetting?: boolean;
    product?: ContributionProduct;
    translations?: ContributionTranslation[];
}

export interface ContributionTranslation {
    description?: string;
    id?: number;
    language?: string;
}

export interface CreateVoucherRequest {
    amount?: number;
    currency?: string;
    expiryDays?: number;
    openAtStart?: boolean;
    reference?: string;
    userId?: number;
}

export interface Credentials {
    secretKey?: string;
    url?: string;
}

export interface CryptoAddressLog {
    address?: string;
    currency?: string;
    id?: number;
    method?: string;
    provider?: string;
    timestamp?: Date;
    userId?: number;
}

export interface CryptoExchangeRate {
    currencyDefinition?: CurrencyDefinition;
    rate?: number;
}

export interface Currency extends Serializable {
    code?: string;
    cryptoCurrency?: boolean;
    cryptoExchangeEnabled?: boolean;
    eurExchangeRate?: number;
    fractionalDigits?: number;
    irlCurrencyCode?: string;
    irlCurrencyFactor?: number;
    name?: string;
    postfixSymbol?: string;
    prefixSymbol?: string;
    visible?: boolean;
}

export interface CurrencyDefinition {
    code?: string;
    cryptoCurrency?: boolean;
    cryptoPrices?: { [index: string]: CryptoExchangeRate };
    fractionalDigits?: number;
    irlCurrencyCode?: string;
    irlCurrencyFactor?: number;
    name?: string;
    postfixSymbol?: string;
    prefixSymbol?: string;
    visible?: boolean;
}

export interface DataPoint {
    date?: string;
    value?: number;
}

export interface DataSet {
    points?: DataPoint[];
}

export interface Duration extends TemporalAmount, Comparable<Duration>, Serializable {
}

export interface Embeddable {
    id?: number;
    name?: string;
}

export interface EndpointPermissions {
    categories?: PermittedCategories;
    method?: string;
    resource?: string;
}

export interface Entry extends Serializable {
    accountId?: number;
    accountStatus?: AccountStatus;
    accountType?: AccountType;
    accountUserId?: number;
    amount?: Money;
    id?: number;
    resultingBalance?: Money;
    timestamp?: Date;
    transactionAttributes?: { [index: string]: string };
    transactionComment?: string;
    transactionId?: number;
}

export interface Event {
    id?: number;
    remoteAddress?: string;
    timestamp?: Date;
    type?: EventType;
    userId?: number;
}

export interface EventListRequest extends ListRequest {
    type?: EventType;
    userId?: number;
}

export interface EventListResponse extends ListResponse {
    items?: Event[];
}

export interface FormStep {
    groups?: UserInputGroup[];
}

export interface FreebetData {
    amount?: number;
    bonus_refund?: boolean;
    currency?: string;
    exchange_rate?: string;
    max_cap?: number;
    max_odd?: number;
    max_selection?: number;
    min_odd?: number;
    min_selection?: number;
    type?: string;
}

export interface FreebetDataItem {
    max_odd?: number;
    max_selection?: number;
    min_odd?: number;
    min_selection?: number;
    type?: string;
}

/**
 * Freshchat Configuration
 */
export interface FreshchatConfig {
    headerProperty?: HeaderProperty;
}

export interface GameFeeItem {
    fee?: number;
    id?: number;
    name?: string;
    rate?: number;
    revenue?: number;
}

export interface GameTag {
    display?: boolean;
    id?: number;
    name?: string;
    nameId?: string;
    translations?: { [index: string]: string };
}

export interface GeneralReportRequest extends BaseListRequest {
    from?: Date;
    groupByTracker?: boolean;
    period?: Period;
    to?: Date;
    tracker?: string[];
}

export interface GroupFeeItem {
    fee?: number;
    games?: GameFeeItem[];
    name?: string;
    rate?: number;
    revenue?: number;
}

export interface HeaderProperty {
    /**
     * Logo Url
     */
    appLogo?: string;
    /**
     * Application Name
     */
    appName?: string;
    /**
     * Background Color in CSS syntax: #123456
     */
    backgroundColor?: string;
    /**
     * Background Image Url
     */
    backgroundImage?: string;
    /**
     * Foreground Color in CSS syntax: #123456
     */
    foregroundColor?: string;
}

export interface InstantWithdrawStatus {
    enabledBySettings?: boolean;
    enabledMaster?: boolean;
    lastHandledWithdrawalId?: number;
    lastHandledWithdrawalTimestamp?: Date;
    latencyMs?: number;
}

export interface IntegrationFeeItem {
    fee?: number;
    integration?: string;
    revenue?: number;
}

export interface InternalSettings {
    allowMultiSession?: boolean;
    allowedCountries?: string[];
    blockedCountries?: string[];
    cryptoOnrampProvider?: CryptoOnrampProvider;
    facebookAppSecret?: string;
    freeSpinsEnabledIntegrations?: string;
    matiSecretString?: string;
    maxIpAddressRiskLevel?: number;
    paymentInstantWithdrawalsEnabled?: boolean;
    paymentNotificationEmail?: string;
    paymentNotifyDeposit?: boolean;
    paymentNotifyInstantWithdraw?: boolean;
    paymentNotifyUserLevelUp?: boolean;
    paymentNotifyWithdrawApprovementNeeded?: boolean;
    rateLimitsConfig?: UserRateLimitsConfig;
    reCaptchaBackendSettings?: ReCaptchaBackendSettings;
    registrationIpBlockTime?: number;
    require2FAEmail?: boolean;
    require2FAPhone?: boolean;
    trackUserLevels?: boolean;
    verifyIpAddresses?: boolean;
    weeklySummaryEmail?: boolean;
    weeklySummaryEmailRecipients?: string;
}

export interface IpRule {
    action?: IpRuleAction;
    description?: string;
    id?: number;
    ipAddress?: string;
}

export interface IpRuleListResponse extends ListResponse {
    items?: IpRule[];
}

export interface Jackpot {
    externalId?: string;
    games?: JackpotGame[];
    integration?: GameIntegration;
    name?: string;
    pools?: JackpotPool[];
}

export interface JackpotBalance {
    amount?: number;
    currencyCode?: string;
}

export interface JackpotGame {
    externalId?: string;
    id?: number;
    name?: string;
}

export interface JackpotList {
    jackpots?: Jackpot[];
}

export interface JackpotPool {
    pool?: JackpotBalance[];
    type?: string;
}

export interface JourneyListResponse extends ListResponse {
    items?: UserJourneyWrapper[];
}

export interface JsonDocument {
    akey?: string;
    id?: number;
    json?: string;
    locked?: boolean;
    validated?: boolean;
    version?: number;
}

export interface JsonDocumentUpdateResult {
    messages?: string[];
    updateResult?: UpdateResultCode;
    validationError?: boolean;
    validationResult?: ValidationResultCode;
}

export interface Jurisdiction {
    allowedCountries?: string[];
    blockedCountries?: string[];
    id?: number;
    name?: JurisdictionName;
}

export interface ListAccountsRequest extends ListRequest {
    accountId?: number;
    currencies?: string[];
    statuses?: AccountStatus[];
    subtypes?: string[];
    types?: AccountType[];
    userId?: number;
}

export interface ListAccountsResponse extends ListResponse {
    items?: Account[];
}

export interface ListAdminNotesRequest extends ListRequest {
    type?: string;
    typeId?: string;
}

export interface ListAdminNotesResponse extends ListResponse {
    items?: AdminNote[];
}

export interface ListAdminUsersRequest extends ListRequest {
    email?: string;
    firstName?: string;
    lastName?: string;
    searchString?: string;
    userGroupIds?: number[];
    userId?: number;
}

export interface ListAdminUsersResponse extends ListResponse {
    items?: AdminUser[];
}

export interface ListAuditLogsRequest extends ListRequest {
    actorEmail?: string;
    actorId?: number;
    actorType?: AuditEntityType;
    affectedObjectId?: string;
    affectedObjectType?: AuditObjectType;
    eventName?: AuditEventSubType;
    id?: number;
    targetId?: number;
    targetType?: AuditEntityType;
}

export interface ListAuditLogsResponse extends ListResponse {
    items?: AuditLog[];
}

export interface ListBundlesRequest extends ListRequest {
}

export interface ListBundlesResponse extends ListResponse {
    items?: Bundle[];
}

export interface ListCasinoGameRequest extends ListRequest {
    gameIds?: number[];
    gameTags?: number[];
    includeDisabledGames?: boolean;
    integrations?: GameIntegration[];
    name?: string;
    onlyFreeSpinSupported?: boolean;
    studioNames?: string[];
}

export interface ListCasinoGameResponse extends ListResponse {
    items?: CasinoGame[];
}

export interface ListCasinoGamesUpdateLogRequest extends ListRequest {
    integration?: GameIntegration;
    status?: GamesUpdateStatus;
}

export interface ListCasinoGamesUpdateLogResponse extends ListResponse {
    items?: CasinoGamesUpdateLog[];
}

export interface ListCasinoLobbyCategoryRequest extends ListRequest {
}

export interface ListCasinoLobbyCategoryResponse extends ListResponse {
    items?: CasinoLobbyCategory[];
}

export interface ListCasinoPageRequest extends ListRequest {
}

export interface ListCasinoPageResponse extends ListResponse {
    items?: CasinoPage[];
}

export interface ListChartsRequest extends ListRequest {
    tag?: string;
}

export interface ListChartsResponse extends ListResponse {
    items?: Chart[];
}

export interface ListCryptoAddressLogRequest extends ListRequest {
    address?: string;
    userId?: number;
}

export interface ListCryptoAddressLogResponse extends ListResponse {
    items?: CryptoAddressLog[];
}

export interface ListEntriesRequest extends ListRequest {
    accountId?: number;
}

export interface ListEntriesResponse extends ListResponse {
    items?: Entry[];
}

export interface ListGameTagRequest extends ListRequest {
    name?: string;
}

export interface ListGameTagResponse extends ListResponse {
    items?: GameTag[];
}

export interface ListPaymentsRequest extends ListRequest {
    statuses?: string[];
    types?: string[];
    uid?: string;
    userId?: number;
}

export interface ListPaymentsResponse extends ListResponse {
    items?: Payment[];
}

export interface ListRequest extends UnorderedListRequest {
    ascending?: boolean;
    orderBy?: string;
}

export interface ListResponse {
    nrOfResults?: number;
}

export interface ListRewardTemplateRequest extends ListRequest {
    casinoGameId?: number;
    currencyCode?: string;
    id?: number;
    product?: Product;
    rewardType?: RewardType;
}

export interface ListSportsbookBetsRequest extends ListRequest {
    betId?: number;
    externalId?: string;
    userId?: number;
}

export interface ListSportsbookBetsResponse extends ListResponse {
    items?: SportsbookBet[];
}

export interface ListStudioRequest extends ListRequest {
    name?: string;
}

export interface ListStudioResponse extends ListResponse {
    items?: CasinoStudio[];
}

export interface ListSystemEventLogsRequest extends ListRequest {
    id?: number;
    level?: Level;
    resolved?: EventResolveStatus;
    subType?: SystemEventSubType;
}

export interface ListSystemEventLogsResponse extends ListResponse {
    items?: SystemEventLog[];
}

export interface ListTransactionsRequest extends ListRequest {
    accountId?: number;
    fromDate?: Date;
    idOne?: number;
    idTwo?: number;
    includeBalanceAfterEntry?: boolean;
    includeNrfTotalResults?: boolean;
    key?: string;
    toDate?: Date;
    type?: TransactionSearchType;
    userId?: number;
    userIdOne?: boolean;
    userIdTwo?: boolean;
    value?: string;
}

export interface ListTransactionsResponse extends ListResponse {
    items?: Transaction[];
}

export interface ListTranslationsRequest extends ListRequest {
    locale?: string;
}

export interface ListTranslationsResponse extends ListResponse {
    items?: Translation[];
}

export interface ListUserAttributeDefinitionResponse extends ListResponse {
    items?: UserAttributeDefinition[];
}

export interface ListUserBonusRequest extends ListRequest {
    userId?: number;
}

export interface ListUserGameRewardRequest extends ListRequest {
    excludeExpired?: boolean;
    id?: number;
    removed?: boolean;
    status?: RewardStatus;
    userId?: number;
}

export interface ListUserGroupResponse extends ListResponse {
    items?: UserGroup[];
}

export interface ListUsersRequest extends ListRequest {
    blockedOnly?: boolean;
    emailOrPhone?: string;
    endDate?: string;
    excludedFromAffiliate?: ExcludedFromAffiliate;
    ipSearchEndDate?: string;
    ipSearchStartDate?: string;
    kycStatus?: KycStatus[];
    labels?: string[];
    searchByDate?: boolean;
    searchString?: string;
    startDate?: string;
    userIdOrUid?: string;
    userIp?: string;
}

export interface ListUsersResponse extends ListResponse {
    items?: User[];
}

export interface ListVouchersRequest extends ListRequest {
    code?: string;
    statuses?: VoucherStatus[];
    userId?: number;
}

export interface ListVouchersResponse extends ListResponse {
    items?: Voucher[];
}

export interface LobbyUpdateRequest {
    gameIntegrations?: GameIntegration[];
}

export interface LoginRequest {
    mfaProvider?: string;
    otp?: string;
    password?: string;
    username?: string;
}

export interface LoginResponse {
    otpSecret?: string;
    provider?: AdminMfaProvider;
    qrCodeDataUrl?: string;
    status?: Status;
}

export interface MaintenanceWindow {
    announceBeforeStart?: Date;
    category?: MaintenanceCategory;
    enabled?: boolean;
    id?: number;
    start?: Date;
}

export interface ManualPayoutRequest {
    affiliateId?: number;
    amount?: number;
    currency?: string;
}

export interface Menu {
    footerItems?: MenuItem[];
    items?: MenuItem[];
    settings?: MenuSettings;
}

export interface MenuComponent {
    loggedInComponent?: MenuComponentType;
    loggedOutComponent?: MenuComponentType;
}

export interface MenuItem {
    disableSidebarCollapse?: boolean;
    excludeCountries?: string;
    expandSubmenuOnly?: boolean;
    icon?: string;
    includeCountries?: string;
    includeInDesktopMenu?: boolean;
    includeInMobileMenu?: boolean;
    includeInSidebarMenu?: boolean;
    name?: string;
    subMenu?: SubMenu;
    supportLink?: boolean;
    target?: string;
    translations?: { [index: string]: string };
    type?: MenuItemType;
    url?: string;
}

export interface MenuSettings {
    enabeLogoSideMenu?: boolean;
    enableLogoTopMenu?: boolean;
    iconPack?: string;
    menuComponents?: MenuComponent;
    partialCollapsed?: boolean;
    position?: MenuPosition;
}

export interface Monetary {
    amount?: number;
    currency?: string;
}

export interface MonetaryConfig {
    awardAmountFraction?: number;
    awardType?: AwardType;
    bonusPaymentType?: BonusPaymentType;
    bustThreshold?: number;
    cashRewardTemplateId?: number;
    currencyCode?: string;
    id?: number;
    inputAmountAsBonusMoney?: boolean;
    maxInputAmount?: number;
    maxWagerAmount?: number;
    name?: string;
    removed?: boolean;
    turnoverRequirementFactor?: number;
}

export interface MonetaryConfigListRequest extends ListRequest {
    currencyCode?: string;
    includeRemoved?: boolean;
}

export interface MonetaryConfigListResponse extends ListResponse {
    items?: MonetaryConfig[];
}

export interface Money extends Serializable {
    amount?: number;
    currencyCode?: string;
    fractionalDigits?: number;
}

export interface NextUpcomingCashback {
    amount?: number;
    calculationTime?: Date;
    configId?: number;
    configName?: string;
    currency?: string;
    frequency?: string;
    notEligibleReason?: NotEligibleReason;
    payoutOngoing?: boolean;
    scheduled?: Date;
}

export interface Payment {
    address?: string;
    amount?: number;
    amountEuro?: number;
    approvedBy?: string;
    attributes?: { [index: string]: string };
    created?: Date;
    cryptoTxReference?: string;
    currency?: string;
    email?: string;
    externalPaymentId?: string;
    finalized?: boolean;
    id?: number;
    log?: PaymentLog[];
    mostRecentNoteText?: string;
    notes?: string;
    paymentReverseTxId?: number;
    paymentTxId?: number;
    pinnedNote?: boolean;
    provider?: string;
    status?: string;
    type?: PaymentType;
    uid?: string;
    updated?: Date;
    userId?: number;
}

export interface PaymentDashboardStats {
    failedPayments?: number;
    pendingWithdrawals?: number;
    ts?: Date;
}

export interface PaymentLog {
    data?: string;
    error?: boolean;
    id?: number;
    message?: string;
    paymentId?: number;
    referenceId?: string;
    status?: PaymentStatus;
    timestamp?: Date;
    type?: PaymentLogEventType;
}

export interface PaymentResult {
    message?: string;
    paymentId?: number;
    paymentUid?: string;
    referenceId?: string;
    result?: PaymentResultCode;
    userId?: number;
}

export interface PaymentSummary {
    amount?: number;
    currency?: string;
    euroAmount?: number;
    type?: PaymentType;
}

export interface Payout {
    affiliateId?: number;
    bonusCost?: number;
    created?: Date;
    currency?: string;
    grossRevenue?: number;
    id?: number;
    manualPaid?: number;
    month?: string;
    netRevenue?: number;
    networkCost?: number;
    percentage?: number;
    profit?: number;
    progress?: PayoutProgress;
    status?: PayoutStatus;
}

export interface PayoutsListRequest extends BaseListRequest {
    affiliateId?: number;
    cashoutId?: number;
    month?: Date;
}

export interface PayoutsListResponse extends BaseListResponse<Payout> {
    items?: Payout[];
}

export interface Period extends ChronoPeriod, Serializable {
}

export interface PermissionCategoryInfo {
    category?: PermissionCategory;
    description?: string;
}

export interface PermittedCategories {
    categoryTypes?: PermissionCategory[];
    requiresWriteAccess?: boolean;
}

export interface Player {
    country?: string;
    cpaDepositRequirement?: number;
    cpaPayoutAmount?: number;
    cpaStatus?: CpaPayoutStatus;
    cpaTurnoverRequirement?: number;
    created?: Date;
    id?: number;
    playerId?: number;
    playerUid?: string;
    trackerCode?: string;
    username?: string;
}

export interface PlayerListRequest extends BaseListRequest {
    from?: Date;
    to?: Date;
    tracker?: string;
    username?: string;
    withDeposit?: boolean;
}

export interface PlayerListResponse extends BaseListResponse<Player> {
    items?: Player[];
}

export interface PlayerLookupResponse {
    reports?: ReportedPlayer[];
}

export interface PokerItem {
    allowMultiple?: boolean;
    category?: string;
    description?: string;
    id?: string;
    imageUrl?: string;
    isEquippable?: boolean;
    name?: string;
    nameId?: string;
    operatorId?: number;
    value?: number;
    voucherCurrencyCode?: string;
    voucherValue?: number;
}

export interface PokerItemList {
    items?: PokerItem[];
    nrOfResults?: number;
}

export interface Principal {
    name?: string;
}

/**
 * Rate limits for principal verifications
 */
export interface PrincipalVerificationRateLimitConfig {
    /**
     * Max number of email requests per defined time period
     */
    emailLimit?: number;
    /**
     * Enable rate limits
     */
    enabled?: boolean;
    /**
     * Max number of SMS requests per defined time period
     */
    phoneLimit?: number;
}

export interface ProviderFeeItem {
    fee?: number;
    game?: string;
    gameId?: number;
    groups?: GroupFeeItem[];
    integration?: string;
    notes?: string;
    rate?: number;
    revenue?: number;
    studio?: string;
}

export interface ProviderReport {
    currency?: string;
    from?: string;
    integrations?: { [index: string]: IntegrationFeeItem };
    period?: string;
    revenueSource?: string;
    studios?: ProviderFeeItem[];
    to?: string;
}

export interface ProviderRewardResponse {
    errorCode?: number;
    message?: string;
    referenceCode?: string;
}

export interface PushMessage {
    badge?: string;
    body?: string;
    data?: PushMessageData;
    icon?: string;
    image?: string;
    silent?: boolean;
    timeToLive?: number;
    title?: string;
}

export interface PushMessageData {
    campaignName?: string;
    dateOfArrival?: Date;
    url?: string;
}

export interface ReCaptchaBackendSettings {
    actions?: ReCaptchaAction[];
    enabled?: boolean;
    minScore?: number;
    secretKey?: string;
}

export interface ReCaptchaFrontendSettings {
    actions?: ReCaptchaAction[];
    enabled?: boolean;
    siteKey?: string;
}

export interface ReportedPlayer {
    abuse?: AbuseType[];
    comment?: string;
    deviceIds?: string[];
    fingerprints?: string[];
    ipAddresses?: string[];
    operator?: string;
    playerUid?: string;
    yourOwnReport?: boolean;
}

export interface ResetPasswordResponse {
    code?: string;
    expiry?: Date;
    url?: string;
    userUid?: string;
}

export interface RestrictionEvent {
    category_id?: string;
    event_id?: string;
    sport_id?: string;
    sport_type?: string;
    tournament_id?: string;
}

export interface Restrictions {
    restrictionEvents?: RestrictionEvent[];
    type?: string;
}

export interface RestrictionsList {
    restriction_events?: RestrictionsList[];
    type?: string;
}

export interface RevenueItem {
    amount?: number;
    gameId?: number;
    gameName?: string;
    integration?: string;
    studio?: string;
    subItems?: SubItem[];
}

export interface RevenueReport {
    currency?: string;
    from?: Date;
    items?: RevenueItem[];
    period?: string;
    source?: string;
    to?: Date;
}

export interface RewardTemplate {
    created?: Date;
    currencyCode?: string;
    defaultAmount?: number;
    defaultCasinoGameId?: number;
    defaultNrOfFreeSpins?: number;
    description?: string;
    descriptionKey?: string;
    expireAfterDays?: number;
    iconUrl?: string;
    id?: number;
    imageUrl?: string;
    name?: string;
    product?: Product;
    rewardType?: RewardType;
    templateId?: string;
    titleKey?: string;
    translations?: RewardTemplateTranslation[];
    validForUserUntilDays?: number;
}

export interface RewardTemplateListResponse extends ListResponse {
    items?: RewardTemplate[];
}

export interface RewardTemplateTranslation {
    id?: number;
    imageUrl?: string;
    language?: string;
}

export interface ScriptWidget {
    scriptUrl?: string;
    variableName?: string;
}

export interface Serializable {
}

export interface ServiceEnabledKey {
    category?: ServiceEnablementCategory;
    component?: string;
}

export interface ServiceEnabledStateResponse {
    hardEnabled?: boolean;
    key?: ServiceEnabledKey;
    name?: string;
    softEnabled?: boolean;
}

export interface ServicesEnabledResponse {
    services?: ServiceEnabledStateResponse[];
}

export interface Settings {
    acquisitionScript?: string;
    amplitudeApiKey?: string;
    appInstallButtonsEnabled?: boolean;
    appNativePromptEnabled?: boolean;
    appPopupEnabled?: boolean;
    bonusEnabled?: boolean;
    bundleBonusCurrency?: string;
    canonicalBaseUrl?: string;
    casinoEnabled?: boolean;
    clientManifest?: string;
    contenfulSpaceId?: string;
    contentfulAccessToken?: string;
    countryBlockEnabled?: boolean;
    cssOverrideUrl?: string;
    currencies?: CurrencyDefinition[];
    currencyOrder?: string;
    customScripts?: string;
    defaultFiatExchangeCurrency?: string;
    delayAppInstallPopup?: number;
    displayFiatExchangeByDefault?: boolean;
    emailSignupAvailable?: boolean;
    facebookAppId?: string;
    faqPageEnabled?: boolean;
    fingerprintDomain?: string;
    fingerprintEnabled?: boolean;
    fingerprintPublicKey?: string;
    forceWithdrawCurrency?: string;
    freshChatConfig?: FreshchatConfig;
    freshChatEnabled?: boolean;
    freshChatHost?: string;
    freshChatWidgetSiteId?: string;
    freshChatWidgetToken?: string;
    googleAnalyticsId?: string;
    id?: number;
    iosMediaJSON?: string;
    kycOnDepositRequired?: boolean;
    kycOnPlayRequired?: boolean;
    kycOnWithdrawRequired?: boolean;
    languageOrder?: string;
    loginRedirectUrl?: string;
    loginRequestCoolDown?: number;
    loginRequestInterval?: number;
    loginThrottleEnabled?: boolean;
    loginsRequiredForPushPrompt?: number;
    matiClientId?: string;
    matiEnabled?: boolean;
    maxLoginCount?: number;
    maxVerificationAttempts?: number;
    menu?: Menu;
    mfaEnabled?: boolean;
    minimumAge?: number;
    noOfTimeBeforeHidingPrompt?: number;
    noOfTimeBeforePromptingAgain?: number;
    onramperCurrencies?: string;
    operatorIconUrl?: string;
    operatorName?: string;
    phoneSignupAvailable?: boolean;
    pokerEnabled?: boolean;
    pushNotificationsEnabled?: boolean;
    pwaEnabled?: boolean;
    pwaEnabledAndroid?: boolean;
    pwaEnabledIOS?: boolean;
    pwaEnabledWindows?: boolean;
    racingEnabled?: boolean;
    reCaptchaSettings?: ReCaptchaFrontendSettings;
    requireUserChannelVerification?: boolean;
    requiredUserAttributes?: UserAttributeDefinition[];
    showCurrencySelectOnSignup?: boolean;
    showUserLevel?: boolean;
    skipDepositPageOnRegister?: boolean;
    sportsbookConfig?: SportsbookConfig;
    sportsbookEnabled?: boolean;
    sportsbookProvider?: SportsbookProvider;
    startPokerOnOpen?: boolean;
    strapiApiUrl?: string;
    termsVersion?: string;
    tippingEnabled?: boolean;
    title?: string;
    tokenLoginEnabled?: boolean;
    transferLimits?: TransferLimit[];
    useAmplitude?: boolean;
    useInfoTextSignup?: boolean;
    useStoreInsteadOfDeposit?: boolean;
    vouchersEnabled?: boolean;
    widgets?: ScriptWidget[];
}

export interface SettingsListResponse extends ListResponse {
    items?: Settings[];
}

export interface SingleCurrencyCashbackPayout {
    amount?: number;
    cashbackLogId?: number;
    created?: Date;
    currency?: string;
    id?: number;
    status?: CashbackPayoutStatus;
    userId?: number;
}

export interface SingleCurrencyPayout {
    accountId?: number;
    affiliateId?: number;
    appliedCarryOver?: number;
    appliedCarryOverEuro?: number;
    cpa?: number;
    cpaEuro?: number;
    currency?: string;
    euroRate?: number;
    id?: number;
    manualPaid?: number;
    manualPaidEuro?: number;
    month?: string;
    payable?: number;
    payableEuro?: number;
    profit?: number;
    profitEuro?: number;
    progress?: PayoutProgress;
    status?: PayoutStatus;
}

export interface Site {
    casinoExitUrl?: string;
    enabled?: boolean;
    hosts?: string[];
    id?: number;
    name?: string;
    nameId?: string;
    settingsId?: number;
    sportsbookExitUrl?: string;
}

export interface SiteListResponse extends ListResponse {
    items?: Site[];
}

export interface SportsBookBonusItem {
    activationDate?: Date;
    brandId?: string;
    endingDate?: Date;
    eventScheduled?: number;
    externalPlayerId?: string;
    forceActivated?: boolean;
    freebetData?: FreebetData;
    fromTime?: Date;
    id?: string;
    issueType?: string;
    name?: string;
    playerId?: number;
    playerUId?: string;
    receiptDate?: Date;
    restrictions?: Restrictions;
    status?: string;
    templateId?: string;
    toTime?: Date;
    type?: string;
    viewed?: boolean;
}

export interface SportsBookRevokeBonusesRequest {
    bonusIds?: number[];
}

export interface SportsbookBet {
    attributes?: { [index: string]: string };
    created?: Date;
    currency?: string;
    externalId?: string;
    id?: number;
    live?: boolean;
    matchTime?: Date;
    meta?: SportsbookBetMetadata;
    odds?: SportsbookOdds;
    oddsDecimal?: number;
    provider?: GameIntegration;
    remoteCurrency?: string;
    remoteCurrencyStake?: number;
    result?: SportsbookBetResult;
    stake?: number;
    status?: SportsbookBetStatus;
    type?: SportsbookBetType;
    userId?: number;
}

export interface SportsbookBetAndEvents {
    bet?: SportsbookBet;
    events?: SportsbookBetEvent[];
}

export interface SportsbookBetEvent {
    amount?: number;
    betId?: number;
    data?: string;
    externalEventId?: string;
    id?: number;
    message?: string;
    remoteAmount?: number;
    result?: SportsbookBetResult;
    status?: SportsbookBetStatus;
    timestamp?: Date;
    txId?: number;
    type?: SportsbookBetEventType;
}

export interface SportsbookBetMetadata {
    events?: string[];
    sports?: string[];
}

export interface SportsbookBonusListRequest extends ListRequest {
    userId?: number;
}

export interface SportsbookBonusRequest {
    bonusId?: number;
}

export interface SportsbookConfig {
    enabledConfig?: boolean;
    providers?: SportsbookProviderOption[];
}

export interface SportsbookOdds {
    odds?: number;
    type?: SportsbookOddsType;
}

export interface SportsbookPlayerBonusesResponse extends ListResponse {
    items?: SportsBookBonusItem[];
}

export interface SportsbookProviderOption {
    pathname?: string;
    provider?: SportsbookProvider;
}

export interface SportsbookUpdateBetRequest {
    result?: SportsbookBetResult;
    status?: SportsbookBetStatus;
}

export interface SubItem {
    amount?: number;
    studio?: string;
}

export interface SubMenu {
    defaultCasinoPage?: boolean;
    icon?: string;
    id?: number;
    name?: string;
    tabs?: SubMenuTab[];
}

export interface SubMenuTab {
    icon?: string;
    id?: number;
    name?: string;
    target?: string;
    translations?: { [index: string]: string };
    type?: MenuItemType;
    url?: string;
}

export interface SystemEventLog {
    attributes?: { [index: string]: string };
    id?: number;
    level?: Level;
    message?: string;
    resolved?: boolean;
    subType?: SystemEventSubType;
    timestamp?: Date;
}

export interface SystemEventLogCountResponse {
    errorEventsCount?: number;
    infoEventsCount?: number;
    warnEventsCount?: number;
}

export interface SystemEventTestRequest {
    attributes?: { [index: string]: string };
    level?: Level;
    message?: string;
    subType?: SystemEventSubType;
}

export interface TemplateItem {
    brand_id?: string;
    comboboost_data?: ComboboostDataItem;
    days_to_use?: number;
    event_scheduled?: number;
    events_availability?: boolean;
    freebet_data?: FreebetDataItem;
    from_time?: number;
    id?: string;
    is_active?: boolean;
    max_bonus_number?: number;
    name?: string;
    operator_id?: string;
    restrictions?: RestrictionsList;
    to_time?: number;
    type?: string;
}

export interface TemporalAmount {
    units?: TemporalUnit[];
}

export interface TemporalUnit {
    dateBased?: boolean;
    duration?: Duration;
    durationEstimated?: boolean;
    timeBased?: boolean;
}

export interface Transaction extends Serializable {
    attributes?: { [index: string]: string };
    comment?: string;
    entries?: Entry[];
    externalId?: string;
    id?: number;
    timestamp?: Date;
}

export interface TransactionResult {
    balances?: AccountBalanceResult[];
    errorCode?: number;
    successful?: boolean;
    transactionId?: number;
}

export interface TransactionSearchRequest {
    accountId?: number;
    ascending?: boolean;
    attributeKey?: string;
    attributeValue?: string;
    fromDate?: string;
    includeBalanceAfterEntry?: boolean;
    includeNrfTotalResults?: boolean;
    limit?: number;
    offset?: number;
    toDate?: string;
    txId?: number;
}

export interface TransferLimit {
    currency?: string;
    defaultDepositAmount?: number;
    maxDepositAmount?: number;
    maxWithdrawAmount?: number;
    minDepositAmount?: number;
    minTipAmount?: number;
    minWithdrawAmount?: number;
}

export interface TransferRequest {
    accountId?: number;
    amount?: number;
    comment?: string;
    type?: TransferRequestType;
}

export interface Translation {
    id?: number;
    key?: string;
    locale?: string;
    updated?: boolean;
    value?: string;
}

export interface TranslationBatchUpdateError {
    error?: string;
    key?: string;
    lang?: string;
    line?: number;
    value?: string;
}

export interface TranslationsBatchUpdateResult {
    created?: number;
    deleted?: number;
    errors?: TranslationBatchUpdateError[];
    updated?: number;
}

export interface TurnoverContributions {
    contributions?: Contribution[];
    id?: number;
    name?: string;
}

export interface TurnoverContributionsListRequest extends ListRequest {
}

export interface TurnoverContributionsListResponse extends ListResponse {
    items?: TurnoverContributions[];
}

export interface TxBatchCsvRequest {
    comment?: string;
    csv?: string;
    transactionType?: TransactionType;
}

export interface TxBatchResult {
    batchId?: string;
    comment?: string;
    error?: TxBatchError;
    errorMessage?: string;
    rows?: TxBatchRowResult[];
    status?: TxBatchStatus;
    transactionType?: TransactionType;
}

export interface TxBatchRow {
    amount?: number;
    currency?: string;
    userId?: number;
}

export interface TxBatchRowResult {
    error?: TxBatchError;
    row?: TxBatchRow;
    txId?: number;
}

export interface TxReport {
    activePlayers?: number;
    commission?: number;
    cpaSum?: number;
    date?: Date;
    depositSum?: number;
    depositingPlayers?: number;
    ggr?: number;
    netRevenue?: number;
    newDepositingPlayers?: number;
    signups?: number;
    tracker?: string;
    uniqueClicks?: number;
}

export interface TxResult {
    amount?: Money;
    balance?: Money;
    error?: boolean;
    errorCode?: number;
    errorMessage?: string;
    txId?: number;
    userAccountId?: number;
    userId?: number;
}

export interface UnorderedListRequest {
    limit?: number;
    offset?: number;
}

export interface UpcomingCashbacksInfo {
    amount?: number;
    configName?: string;
    currency?: string;
    isSummarized?: boolean;
    payoutDate?: string;
}

export interface UpcomingCashbacksInfoListResponse extends ListResponse {
    items?: UpcomingCashbacksInfo[];
    status?: CalculationStatus;
}

export interface User {
    affiliate?: boolean;
    affiliateId?: number;
    affiliateName?: string;
    attributes?: { [index: string]: string };
    blocked?: boolean;
    communicationChannel?: CommunicationChannel;
    country?: string;
    created?: Date;
    email?: string;
    emailVerified?: boolean;
    excludeFromAffiliate?: boolean;
    id?: number;
    kycStatus?: KycStatus;
    kycVerified?: boolean;
    labels?: string[];
    lastLogin?: Date;
    loginType?: LoginType;
    maskedPaths?: string[];
    mfaProvider?: MultiFactorAuthProvider;
    mfaStatus?: MultiFactorAuthStatus;
    phoneNumber?: string;
    phoneNumberVerified?: boolean;
    registrationRiskLevel?: RegistrationRiskLevel;
    resetPasswordRequestTimestamp?: Date;
    termsAccepted?: boolean;
    uid?: string;
    userSettings?: { [index: string]: string };
    username?: string;
    verificationAttempts?: number;
    verificationChannel?: CommunicationChannel;
    verificationCode?: string;
    visible?: boolean;
}

export interface UserAttributeDefinition {
    attributeKey?: string;
    descriptionTranslationKey?: string;
    id?: number;
    maxLength?: number;
    minLength?: number;
    nameTranslationKey?: string;
    options?: string;
    ordinal?: number;
    regex?: string;
    required?: boolean;
    type?: AttributeType;
    userWriteAccess?: UserWriteAccess;
}

export interface UserBonusEvent {
    amount?: number;
    contribution?: number;
    currency?: string;
    freespins?: boolean;
    gameId?: number;
    gameName?: string;
    id?: number;
    nanoTxId?: number;
    parachuteBonusBalance?: number;
    parachuteBonusPhase?: boolean;
    product?: Product;
    status?: UserBonusEventStatus;
    timestamp?: Date;
    type?: UserBonusEventType;
    userBalance?: number;
    userBonusId?: number;
}

export interface UserBonusEventListRequest extends ListRequest {
    userBonusId?: number;
}

export interface UserBonusEventListResponse extends ListResponse {
    items?: UserBonusEvent[];
}

export interface UserBonusListResponse extends ListResponse {
    items?: UserBonusView[];
}

export interface UserBonusView {
    bonusConfig?: BonusConfig;
    currency?: string;
    currentTurnover?: number;
    expires?: Date;
    id?: number;
    parachuteBonusBalance?: number;
    parachuteBonusPhase?: boolean;
    status?: UserBonusStatus;
    timestamp?: Date;
    totalBonusAmount?: number;
    turnoverRequirement?: number;
    userId?: number;
}

export interface UserDeposit {
    formSteps?: FormStep[];
    verification?: UserVerificationRequirements;
}

export interface UserGameReward {
    amount?: number;
    claimTime?: Date;
    created?: Date;
    currencyCode?: string;
    expires?: Date;
    gameId?: number;
    id?: number;
    integration?: GameIntegration;
    messageRead?: boolean;
    nrOfFreeSpins?: number;
    providerReference?: string;
    providerResponse?: ProviderRewardResponse;
    removed?: boolean;
    rewardTemplate?: RewardTemplate;
    status?: RewardStatus;
    userId?: number;
}

export interface UserGameRewardQueryResult extends ListResponse {
    items?: UserGameReward[];
    queryLimit?: number;
    queryOffset?: number;
}

export interface UserGroup {
    displaySensitiveInformation?: boolean;
    groupPermissions?: { [P in PermissionCategory]?: boolean };
    id?: number;
    name?: string;
}

export interface UserInput {
    field?: UserPrimaryField;
    hidden?: boolean;
    minAge?: number;
    repeatInput?: boolean;
    required?: boolean;
    type?: InputType;
    userAttributeKey?: string;
}

export interface UserInputGroup {
    inputs?: UserInput[];
    name?: string;
    translationKey?: string;
}

export interface UserJourney {
    deposit?: UserDeposit;
    login?: UserLogin;
    registration?: UserRegistration;
    userLoginPrincipals?: UserLoginPrincipal[];
    withdraw?: UserWithdraw;
}

export interface UserJourneyWrapper {
    classifier?: string;
    enabled?: boolean;
    id?: number;
    journey?: UserJourney;
}

export interface UserLevelAdminView {
    currentLevel?: number;
    currentLevelName?: string;
    currentXp?: number;
    nextLevelName?: string;
    nextLevelProgressionPercentage?: number;
    nextLevelXp?: number;
}

export interface UserLogin {
    verifications?: UserVerificationRequirements;
}

/**
 * Rate limits for principal verifications
 */
export interface UserMfaRateLimitConfig {
    /**
     * Enable rate limits
     */
    enabled?: boolean;
    /**
     * Max number of times you can try to verify mfa code
     */
    limit?: number;
}

export interface UserPerformanceStatsContainer {
    stats?: UserPerformanceStatsRow[];
    ts?: Date;
    userId?: number;
}

export interface UserPerformanceStatsRow {
    bonusWagerType?: string;
    count?: number;
    currency?: string;
    eventType?: string;
    total?: number;
    totalEur?: number;
    wagerType?: string;
}

export interface UserRateLimitsConfig {
    mfa?: UserMfaRateLimitConfig;
    verifyPrincipal?: PrincipalVerificationRateLimitConfig;
}

export interface UserRegistration {
    exclude?: UserLoginPrincipal[];
    formSteps?: FormStep[];
    riskCollectionId?: number;
    verification?: UserVerificationRequirements;
}

export interface UserRiskCollectionListResponse extends ListResponse {
    items?: UserRiskRuleCollection[];
}

export interface UserRiskRule {
    allowMissingData?: boolean;
    id?: number;
    result?: UserRiskResult;
    type?: UserRiskRuleType;
}

export interface UserRiskRuleCollection {
    description?: string;
    id?: number;
    name?: string;
    rules?: UserRiskRule[];
}

export interface UserVerificationRequirements {
    kyc?: boolean;
}

export interface UserWithdraw {
    formSteps?: FormStep[];
    verification?: UserVerificationRequirements;
}

export interface Voucher {
    amount?: number;
    code?: string;
    created?: Date;
    currency?: string;
    expiry?: Date;
    id?: number;
    paymentId?: number;
    reference?: string;
    status?: VoucherStatus;
    updated?: Date;
    userId?: number;
}

export enum AbuseType {
    MULTIPLE_ACCOUNTS = "MULTIPLE_ACCOUNTS",
    BONUS_ABUSE = "BONUS_ABUSE",
    PAYMENT_FRAUD = "PAYMENT_FRAUD",
    INTENTIONAL_ROLLBACK = "INTENTIONAL_ROLLBACK",
    POKER_CHIP_DUMPING = "POKER_CHIP_DUMPING",
    POKER_FREEROLL_ABUSE = "POKER_FREEROLL_ABUSE",
    OTHER = "OTHER",
}

export enum AccountStatus {
    OPEN = "OPEN",
    CLOSED = "CLOSED",
}

export enum AccountType {
    SESSION_ACCOUNT = "SESSION_ACCOUNT",
    STATIC_ACCOUNT = "STATIC_ACCOUNT",
    SYSTEM_ACCOUNT = "SYSTEM_ACCOUNT",
    OPERATOR_ACCOUNT = "OPERATOR_ACCOUNT",
    AFFILIATE_ACCOUNT = "AFFILIATE_ACCOUNT",
    AGENT_ACCOUNT = "AGENT_ACCOUNT",
}

export enum AdminMfaProvider {
    EMAIL = "EMAIL",
    AUTHENTICATOR_APP = "AUTHENTICATOR_APP",
}

export enum AffiliatePayoutModel {
    PLAYER_ACCOUNT = "PLAYER_ACCOUNT",
    DIRECT = "DIRECT",
    INVOICE = "INVOICE",
}

export enum AffiliateStatus {
    PENDING = "PENDING",
    APPROVED = "APPROVED",
    SUSPENDED = "SUSPENDED",
}

export enum AttributeType {
    PUBLIC = "PUBLIC",
    PRIVATE = "PRIVATE",
    SYSTEM = "SYSTEM",
    SYSTEM_INTERNAL = "SYSTEM_INTERNAL",
}

export enum AuditEntityType {
    SYSTEM = "SYSTEM",
    ADMIN = "ADMIN",
    USER = "USER",
    AFFILIATE = "AFFILIATE",
}

export enum AuditEventSubType {
    MANUAL_BALANCE_ADJUSTMENT = "MANUAL_BALANCE_ADJUSTMENT",
    MANUAL_BONUS_AWARD = "MANUAL_BONUS_AWARD",
    PAYMENT_APPROVAL = "PAYMENT_APPROVAL",
    PAYMENT_RESOLVE_REFUND = "PAYMENT_RESOLVE_REFUND",
    PAYMENT_CANCEL = "PAYMENT_CANCEL",
    PAYMENT_FINALIZED = "PAYMENT_FINALIZED",
    PAYMENT_RESOLVE_DEPOSIT_AND_CREDIT = "PAYMENT_RESOLVE_DEPOSIT_AND_CREDIT",
    USER_BLOCK_STATUS_TOGGLED = "USER_BLOCK_STATUS_TOGGLED",
    USER_CREATED_OR_UPDATED = "USER_CREATED_OR_UPDATED",
    AFFILIATE_PROFILE_UPDATED = "AFFILIATE_PROFILE_UPDATED",
    AFFILIATE_SETTINGS_UPDATED = "AFFILIATE_SETTINGS_UPDATED",
    AFFILIATE_STATUS_UPDATED = "AFFILIATE_STATUS_UPDATED",
    USER_KYC_UPDATE = "USER_KYC_UPDATE",
    VOUCHER_CREATED_OR_UPDATED = "VOUCHER_CREATED_OR_UPDATED",
    VISIBILITY_FLAG_CHANGED = "VISIBILITY_FLAG_CHANGED",
    EXCLUDE_AFFILIATE_FLAG_CHANGED = "EXCLUDE_AFFILIATE_FLAG_CHANGED",
    LOGIN = "LOGIN",
    CHANGE_PASSWORD = "CHANGE_PASSWORD",
    RESET_PASSWORD = "RESET_PASSWORD",
    INSTANT_WITHDRAWAL_SETTINGS = "INSTANT_WITHDRAWAL_SETTINGS",
    SERVICE_ENABLEMENT = "SERVICE_ENABLEMENT",
    USER_TAG_UPDATE = "USER_TAG_UPDATE",
    PERMISSIONS_UPDATE = "PERMISSIONS_UPDATE",
    USER_GROUP_CHANGE = "USER_GROUP_CHANGE",
    ADMIN_USER_UPDATED = "ADMIN_USER_UPDATED",
    REPORT_ADDED = "REPORT_ADDED",
    REPORT_REMOVED = "REPORT_REMOVED",
    USER_BLOCK_BY_ABUSE_REPORT = "USER_BLOCK_BY_ABUSE_REPORT",
}

export enum AuditObjectType {
    VOUCHER = "VOUCHER",
    USER = "USER",
    PAYMENT = "PAYMENT",
    AFFILIATE = "AFFILIATE",
}

export enum AwardType {
    CLAIMED = "CLAIMED",
    RUNNING = "RUNNING",
    COMPLETED = "COMPLETED",
}

export enum BonusLogicType {
    PARACHUTE = "PARACHUTE",
    BOUNTY = "BOUNTY",
}

export enum BonusOfferStatus {
    OPEN = "OPEN",
    EXPIRED = "EXPIRED",
    CANCELED = "CANCELED",
    CLAIMED = "CLAIMED",
    BLOCKED = "BLOCKED",
}

export enum BonusPaymentType {
    DIRECT = "DIRECT",
    CASH_REWARD = "CASH_REWARD",
}

export enum BonusStatus {
    DISABLED = "DISABLED",
    ENABLED = "ENABLED",
    ARCHIVED = "ARCHIVED",
}

export enum BonusStatusAlert {
    GREY = "GREY",
    RED = "RED",
    YELLOW = "YELLOW",
    GREEN = "GREEN",
}

export enum BonusVisibilityStatus {
    NON_ARCHIVED = "NON_ARCHIVED",
    ARCHIVED = "ARCHIVED",
}

export enum BundleType {
    purchase = "purchase",
    signOnBonus = "signOnBonus",
}

export enum CalculationStatus {
    SUCCESS = "SUCCESS",
    FAILURE = "FAILURE",
    RUNNING = "RUNNING",
}

export enum CashbackFrequency {
    DAILY = "DAILY",
    WEEKLY = "WEEKLY",
    MONTHLY = "MONTHLY",
}

export enum CashbackLogStatus {
    RUNNING = "RUNNING",
    FAILED = "FAILED",
    CREATED = "CREATED",
    FETCHING_DATA = "FETCHING_DATA",
    FETCHING_DATA_FAILED = "FETCHING_DATA_FAILED",
    HANDLING_PAYOUTS = "HANDLING_PAYOUTS",
    HANDLING_PAYOUTS_PARTIALLY = "HANDLING_PAYOUTS_PARTIALLY",
    COMPLETED = "COMPLETED",
    COMPLETED_WITH_CALCULATION_ERRORS = "COMPLETED_WITH_CALCULATION_ERRORS",
    COMPLETED_WITH_PAYOUT_ERRORS = "COMPLETED_WITH_PAYOUT_ERRORS",
    CANCELED = "CANCELED",
}

export enum CashbackPayoutStatus {
    CREATED = "CREATED",
    COMPLETED = "COMPLETED",
    FAILED = "FAILED",
    CALCULATION_FAILED = "CALCULATION_FAILED",
    PAYOUT_FAILED = "PAYOUT_FAILED",
    NO_PAYOUT = "NO_PAYOUT",
}

export enum CashbackType {
    CASHBACK = "CASHBACK",
    RAKEBACK = "RAKEBACK",
}

export enum CasinoGameAspectRatio {
    R_16_9 = "R_16_9",
    R_4_3 = "R_4_3",
    R_3_4 = "R_3_4",
    R_9_16 = "R_9_16",
}

export enum CasinoGameType {
    ARCADE_GAMES = "ARCADE_GAMES",
    SLOTS = "SLOTS",
    LIVE_CASINO = "LIVE_CASINO",
    TABLE_GAMES = "TABLE_GAMES",
    VIDEO_POKER = "VIDEO_POKER",
    OTHER = "OTHER",
    VIRTUAL_SPORTS = "VIRTUAL_SPORTS",
    BINGO = "BINGO",
}

export enum CasinoLobbyCategoryType {
    GAME_SELECTION = "GAME_SELECTION",
    CONTENT = "CONTENT",
    STUDIO_SELECTION = "STUDIO_SELECTION",
}

export enum CasinoMobileNavigation {
    INHERIT = "INHERIT",
    ENABLED = "ENABLED",
    DISABLED = "DISABLED",
}

export enum ChartType {
    LINE = "LINE",
    BAR = "BAR",
}

export enum CommunicationChannel {
    NONE = "NONE",
    EMAIL = "EMAIL",
    SMS = "SMS",
}

export enum ContributionProduct {
    CASINO = "CASINO",
    SPORTSBOOK = "SPORTSBOOK",
    POKER = "POKER",
    RACING = "RACING",
}

export enum CpaPayoutStatus {
    CAP_REACHED = "CAP_REACHED",
    NOT_PAID = "NOT_PAID",
    QUALIFIED = "QUALIFIED",
    PAID = "PAID",
}

export enum CryptoOnrampProvider {
    NONE = "NONE",
    ONRAMPER = "ONRAMPER",
    BANXA = "BANXA",
    CFX = "CFX",
}

export enum DayOfWeek {
    MONDAY = "MONDAY",
    TUESDAY = "TUESDAY",
    WEDNESDAY = "WEDNESDAY",
    THURSDAY = "THURSDAY",
    FRIDAY = "FRIDAY",
    SATURDAY = "SATURDAY",
    SUNDAY = "SUNDAY",
}

export enum EnvironmentType {
    production = "production",
    test = "test",
}

export enum EventResolveStatus {
    RESOLVED = "RESOLVED",
    NOT_RESOLVED = "NOT_RESOLVED",
}

export enum EventType {
    CREATED = "CREATED",
    LOGIN = "LOGIN",
    DEPOSIT = "DEPOSIT",
    WITHDRAW = "WITHDRAW",
    REVENUE = "REVENUE",
    BONUS = "BONUS",
    LEVEL_UP = "LEVEL_UP",
    PLAYER_TIP_RECEIVED = "PLAYER_TIP_RECEIVED",
}

export enum ExcludedFromAffiliate {
    ALL = "ALL",
    EXCLUDED = "EXCLUDED",
    NOT_EXCLUDED = "NOT_EXCLUDED",
}

export enum GameDisplay {
    FIXED = "FIXED",
    SCALE_OUT = "SCALE_OUT",
    EMBEDDED = "EMBEDDED",
}

/**
 * Values:
 * - `ALL`
 * - `INTERNAL`
 * - `PAYMENTS`
 * - `CUBEIA`
 * - `SONG88`
 * - `BOOONGO`
 * - `LATROBET`
 * - `SA_GAMING`
 * - `NUCLEUS`
 * - `ULTRAPLAY`
 * - `QTECH`
 * - `SPRIBE`
 * - `HUB88`
 * - `ZITRO`
 * - `SABA`
 * - `IMOON`
 * - `FLOW_GAMING`
 * - `PARIPLAY`
 * - `CHERRY`
 * - `REFERENCE_PROVIDER`
 * - `JILI`
 * - `SLOTEGRATOR`
 * - `ST8`
 * - `BIGPOT`
 * - `BETBY`
 * - `EVOLUTION`
 * - `PRAGMATIC_PLAY`
 * - `FA_CHAI`
 * - `MAYAMINIAPP` - @deprecated
 * - `BLINK`
 */
export enum GameIntegration {
    ALL = "ALL",
    INTERNAL = "INTERNAL",
    PAYMENTS = "PAYMENTS",
    CUBEIA = "CUBEIA",
    SONG88 = "SONG88",
    BOOONGO = "BOOONGO",
    LATROBET = "LATROBET",
    SA_GAMING = "SA_GAMING",
    NUCLEUS = "NUCLEUS",
    ULTRAPLAY = "ULTRAPLAY",
    QTECH = "QTECH",
    SPRIBE = "SPRIBE",
    HUB88 = "HUB88",
    ZITRO = "ZITRO",
    SABA = "SABA",
    IMOON = "IMOON",
    FLOW_GAMING = "FLOW_GAMING",
    PARIPLAY = "PARIPLAY",
    CHERRY = "CHERRY",
    REFERENCE_PROVIDER = "REFERENCE_PROVIDER",
    JILI = "JILI",
    SLOTEGRATOR = "SLOTEGRATOR",
    ST8 = "ST8",
    BIGPOT = "BIGPOT",
    BETBY = "BETBY",
    EVOLUTION = "EVOLUTION",
    PRAGMATIC_PLAY = "PRAGMATIC_PLAY",
    FA_CHAI = "FA_CHAI",
    /**
     * @deprecated
     */
    MAYAMINIAPP = "MAYAMINIAPP",
    BLINK = "BLINK",
}

export enum GameLockedField {
    TAGS = "TAGS",
    ATTRIBUTES = "ATTRIBUTES",
    ASPECT_RATIO = "ASPECT_RATIO",
    DISPLAY = "DISPLAY",
    PLAY_FOR_FUN = "PLAY_FOR_FUN",
    STUDIO = "STUDIO",
    DESCRIPTION = "DESCRIPTION",
    GAME_TYPE = "GAME_TYPE",
    BACKGROUND_IMAGE_URL = "BACKGROUND_IMAGE_URL",
    IMAGE_URL = "IMAGE_URL",
    TRANSLATIONS = "TRANSLATIONS",
    ENABLED = "ENABLED",
    NAME = "NAME",
    RELEASE_DATE = "RELEASE_DATE",
    MOBILE_NAVIGATION = "MOBILE_NAVIGATION",
    COMMON_GAME_NAME = "COMMON_GAME_NAME",
    JURISDICTION = "JURISDICTION",
    ALLOWED_COUNTRIES = "ALLOWED_COUNTRIES",
    BLOCKED_COUNTRIES = "BLOCKED_COUNTRIES",
}

export enum GameOrder {
    RELEASE_DATE = "RELEASE_DATE",
    POPULARITY = "POPULARITY",
    ALPHABETICAL = "ALPHABETICAL",
}

export enum GamesUpdateStatus {
    STARTED = "STARTED",
    FINISHED = "FINISHED",
    FAILED = "FAILED",
    TIMED_OUT = "TIMED_OUT",
}

export enum InputType {
    LOGIN_PRINCIPAL = "LOGIN_PRINCIPAL",
    EMAIL = "EMAIL",
    PHONE = "PHONE",
    PASSWORD = "PASSWORD",
    ACCEPT_TERMS = "ACCEPT_TERMS",
    AUTO_ACCEPT_TERMS = "AUTO_ACCEPT_TERMS",
    COUNTRY = "COUNTRY",
    TEXT = "TEXT",
    DATE_OF_BIRTH = "DATE_OF_BIRTH",
    DATE = "DATE",
    CHECKBOX = "CHECKBOX",
    CHECKBOX_CHECKED = "CHECKBOX_CHECKED",
    PROMO_CODE = "PROMO_CODE",
}

export enum IpRuleAction {
    ALLOW = "ALLOW",
    DENY = "DENY",
    NONE = "NONE",
}

export enum JurisdictionName {
    NONE = "NONE",
    UNREGULATED_EUROPE = "UNREGULATED_EUROPE",
    PAGCOR = "PAGCOR",
    UNREGULATED_ASIA = "UNREGULATED_ASIA",
}

export enum KycStatus {
    NOT_STARTED = "NOT_STARTED",
    PENDING = "PENDING",
    REVIEW_NEEDED = "REVIEW_NEEDED",
    REJECTED = "REJECTED",
    VERIFIED = "VERIFIED",
}

export enum Level {
    INFO = "INFO",
    WARN = "WARN",
    ERROR = "ERROR",
}

export enum LoginType {
    MANUAL = "MANUAL",
    FACEBOOK = "FACEBOOK",
    TOKEN = "TOKEN",
    MAYAMINIAPP = "MAYAMINIAPP",
    GLIFE = "GLIFE",
}

export enum MaintenanceCategory {
    ALL = "ALL",
    SPORTSBOOK = "SPORTSBOOK",
    CASINO = "CASINO",
    POKER = "POKER",
    RACING = "RACING",
}

export enum MenuComponentType {
    LOGIN_REGISTER = "LOGIN_REGISTER",
    USER_LEVEL = "USER_LEVEL",
    CASHBACK = "CASHBACK",
    NONE = "NONE",
}

export enum MenuItemType {
    POKER = "POKER",
    CASINO = "CASINO",
    SPORTSBOOK = "SPORTSBOOK",
    RACING = "RACING",
    CUSTOM = "CUSTOM",
}

export enum MenuPosition {
    FIXED = "FIXED",
    ABSOLUTE = "ABSOLUTE",
}

export enum MultiFactorAuthProvider {
    OTP = "OTP",
    EMAIL = "EMAIL",
    SMS = "SMS",
}

export enum MultiFactorAuthStatus {
    DISABLED = "DISABLED",
    PENDING = "PENDING",
    ENABLED = "ENABLED",
}

export enum NotEligibleReason {
    NONE = "NONE",
    NO_AWARD_RATE = "NO_AWARD_RATE",
}

export enum OtpRequirement {
    NOT_REQUIRED = "NOT_REQUIRED",
    PER_DEVICE = "PER_DEVICE",
    ALWAYS = "ALWAYS",
}

export enum PaymentLogEventType {
    INITIATED = "INITIATED",
    CALLBACK_RECEIVED = "CALLBACK_RECEIVED",
    VERIFICATION_FAILED = "VERIFICATION_FAILED",
    DECLINED = "DECLINED",
    INTERNAL_TRANSFER_COMPLETE = "INTERNAL_TRANSFER_COMPLETE",
    INTERNAL_TRANSFER_FAILED = "INTERNAL_TRANSFER_FAILED",
    CANCELED = "CANCELED",
    FAILED = "FAILED",
    COMPLETED = "COMPLETED",
    RESOLVED = "RESOLVED",
    GENERIC = "GENERIC",
}

export enum PaymentResultCode {
    OK = "OK",
    DECLINED = "DECLINED",
    DECLINED_REQUEST_ALREADY_OPEN = "DECLINED_REQUEST_ALREADY_OPEN",
    DECLINED_NO_DEPOSIT_FOUND = "DECLINED_NO_DEPOSIT_FOUND",
    DECLINED_NO_TARGET_ADDRESS_SET = "DECLINED_NO_TARGET_ADDRESS_SET",
    DECLINED_DEPOSITS_WAITING_TO_CLEAR = "DECLINED_DEPOSITS_WAITING_TO_CLEAR",
    DECLINED_AMOUNT_TOO_LOW = "DECLINED_AMOUNT_TOO_LOW",
    DECLINED_BONUS_ACTIVE = "DECLINED_BONUS_ACTIVE",
    DECLINED_KYC_REQUIRED = "DECLINED_KYC_REQUIRED",
    DECLINED_MISSING_AUTH_DATA = "DECLINED_MISSING_AUTH_DATA",
    ERROR = "ERROR",
}

/**
 * Values:
 * - `INITIATED`
 * - `PENDING`
 * - `FAILED`
 * - `FAILED_PERMANENTLY`
 * - `COMPLETED`
 * - `DECLINED` - @deprecated
 * - `CANCELED`
 * - `RESOLVED`
 * - `PLACEHOLDER`
 * - `APPLIED_WAITING_TO_CLEAR` - @deprecated
 */
export enum PaymentStatus {
    INITIATED = "INITIATED",
    PENDING = "PENDING",
    FAILED = "FAILED",
    FAILED_PERMANENTLY = "FAILED_PERMANENTLY",
    COMPLETED = "COMPLETED",
    /**
     * @deprecated
     */
    DECLINED = "DECLINED",
    CANCELED = "CANCELED",
    RESOLVED = "RESOLVED",
    PLACEHOLDER = "PLACEHOLDER",
    /**
     * @deprecated
     */
    APPLIED_WAITING_TO_CLEAR = "APPLIED_WAITING_TO_CLEAR",
}

export enum PaymentType {
    DEPOSIT = "DEPOSIT",
    WITHDRAWAL = "WITHDRAWAL",
    AUTH = "AUTH",
    BONUS = "BONUS",
    CORRECTION = "CORRECTION",
}

/**
 * Values:
 * - `STARTED`
 * - `PENDING_INTERNAL_TRANSACTION`
 * - `FAILED_INTERNAL_TRANSACTION`
 * - `ZERO_AMOUNT`
 * - `NEGATIVE_CARRY_OVER`
 * - `CARRIED_OVER`
 * - `PAID`
 * - `PROCESSED`
 * - `INITIATED_FOR_MANUAL_PAYMENT`
 * - `PAID_BY_INVOICE`
 * - `RECEIVED_CARRY_OVER` - @deprecated
 * - `COMPLETED_INTERNAL_TRANSACTION` - @deprecated
 */
export enum PayoutProgress {
    STARTED = "STARTED",
    PENDING_INTERNAL_TRANSACTION = "PENDING_INTERNAL_TRANSACTION",
    FAILED_INTERNAL_TRANSACTION = "FAILED_INTERNAL_TRANSACTION",
    ZERO_AMOUNT = "ZERO_AMOUNT",
    NEGATIVE_CARRY_OVER = "NEGATIVE_CARRY_OVER",
    CARRIED_OVER = "CARRIED_OVER",
    PAID = "PAID",
    PROCESSED = "PROCESSED",
    INITIATED_FOR_MANUAL_PAYMENT = "INITIATED_FOR_MANUAL_PAYMENT",
    PAID_BY_INVOICE = "PAID_BY_INVOICE",
    /**
     * @deprecated
     */
    RECEIVED_CARRY_OVER = "RECEIVED_CARRY_OVER",
    /**
     * @deprecated
     */
    COMPLETED_INTERNAL_TRANSACTION = "COMPLETED_INTERNAL_TRANSACTION",
}

export enum PayoutStatus {
    FAILED = "FAILED",
    COMPLETED = "COMPLETED",
    RUNNING = "RUNNING",
    PENDING = "PENDING",
    CARRIED_OVER = "CARRIED_OVER",
}

export enum PermissionCategory {
    METRICS = "METRICS",
    USERS = "USERS",
    TEST = "TEST",
    ADMIN_USER_GROUPS = "ADMIN_USER_GROUPS",
    WALLET = "WALLET",
    AUDIT = "AUDIT",
    ADMIN_USERS = "ADMIN_USERS",
    OPERATOR_PROGRESSION = "OPERATOR_PROGRESSION",
    CASINO = "CASINO",
    REQUIRED = "REQUIRED",
    USER_ATTRIBUTE_CONFIG = "USER_ATTRIBUTE_CONFIG",
    ANALYTICS = "ANALYTICS",
    PAYMENTS = "PAYMENTS",
    ADMIN_INSTANT_WITHDRAWALS = "ADMIN_INSTANT_WITHDRAWALS",
    VOUCHERS = "VOUCHERS",
    NOTES = "NOTES",
    AFFILIATES = "AFFILIATES",
    NOTIFICATIONS = "NOTIFICATIONS",
    TRANSLATIONS = "TRANSLATIONS",
    REPORTS = "REPORTS",
    BATCH_WALLET = "BATCH_WALLET",
    BONUS = "BONUS",
    USER_AUTHENTICATION = "USER_AUTHENTICATION",
    USER_VISIBILITY_CONFIG = "USER_VISIBILITY_CONFIG",
    USER_KYC_STATUS = "USER_KYC_STATUS",
    JSON_CONFIG = "JSON_CONFIG",
    USER_JOURNEY = "USER_JOURNEY",
    ADMIN_SECURITY = "ADMIN_SECURITY",
    SETTINGS = "SETTINGS",
    WIDGETS = "WIDGETS",
    IP_RULES = "IP_RULES",
    MAINTENANCE = "MAINTENANCE",
    SPORTSBOOK = "SPORTSBOOK",
    MANUAL_REGISTRATION_APPROVAL = "MANUAL_REGISTRATION_APPROVAL",
    FINANCE = "FINANCE",
    SERVICE_ENABLEMENT = "SERVICE_ENABLEMENT",
    SITES = "SITES",
    TRANSACTIONS = "TRANSACTIONS",
}

export enum Product {
    POKER = "POKER",
    CASINO = "CASINO",
    SPORTSBOOK = "SPORTSBOOK",
    PAYMENT = "PAYMENT",
    RACING = "RACING",
    MAYAMINIAPP = "MAYAMINIAPP",
    GLIFE = "GLIFE",
}

export enum ReCaptchaAction {
    login = "login",
    register = "register",
    recoverPassword = "recoverPassword",
    requestPrincipalVerification = "requestPrincipalVerification",
}

export enum RegistrationRiskLevel {
    OK = "OK",
    FLAGGED = "FLAGGED",
    BLOCKED = "BLOCKED",
    MANUAL_APPROVAL_REQUIRED = "MANUAL_APPROVAL_REQUIRED",
    MANUALLY_APPROVED = "MANUALLY_APPROVED",
}

export enum RewardReason {
    NONE = "NONE",
    CASHBACK = "CASHBACK",
    RAKEBACK = "RAKEBACK",
    LEVEL_UP = "LEVEL_UP",
}

export enum RewardStatus {
    NEW = "NEW",
    CLAIMED = "CLAIMED",
}

export enum RewardType {
    freespins = "freespins",
    cash = "cash",
    freebet = "freebet",
}

export enum RuleTriggerType {
    ABSOLUTE_COUNT = "ABSOLUTE_COUNT",
    EVERY_TIME = "EVERY_TIME",
    MANUAL = "MANUAL",
}

export enum ServiceEnablementCategory {
    ASSIGN_USER_REWARDS = "ASSIGN_USER_REWARDS",
    ASSIGN_BONUS_REWARDS = "ASSIGN_BONUS_REWARDS",
    CLAIM_USER_REWARDS = "CLAIM_USER_REWARDS",
    CLAIM_BONUS_REWARDS = "CLAIM_BONUS_REWARDS",
    USER_REGISTER = "USER_REGISTER",
    USER_LOGIN = "USER_LOGIN",
    USER_RESET = "USER_RESET",
    CASINO = "CASINO",
    MESSAGING_EMAIL = "MESSAGING_EMAIL",
    MESSAGING_PHONE = "MESSAGING_PHONE",
    REWARDS = "REWARDS",
    PAYMENTS = "PAYMENTS",
    CASHBACK = "CASHBACK",
    RECAPTCHA = "RECAPTCHA",
    CUSTOMER_IO = "CUSTOMER_IO",
    POS = "POS",
    VOUCHER = "VOUCHER",
    AGENT_PAYOUT_SYSTEM = "AGENT_PAYOUT_SYSTEM",
    AFFILIATE_PAYOUT_SYSTEM = "AFFILIATE_PAYOUT_SYSTEM",
}

export enum SportsbookBetEventType {
    CREATED = "CREATED",
    CONFIRMED = "CONFIRMED",
    SETTLED = "SETTLED",
    CANCELED = "CANCELED",
    ERROR = "ERROR",
    UNSETTLED = "UNSETTLED",
    ADMIN_UPDATED = "ADMIN_UPDATED",
    LOG = "LOG",
}

export enum SportsbookBetResult {
    OPEN = "OPEN",
    WON = "WON",
    LOST = "LOST",
    CANCELED = "CANCELED",
    CASHED_OUT = "CASHED_OUT",
    REVERT_PENDING = "REVERT_PENDING",
}

export enum SportsbookBetStatus {
    INITIALIZED = "INITIALIZED",
    RUNNING = "RUNNING",
    COMPLETED = "COMPLETED",
}

export enum SportsbookBetType {
    SINGLE = "SINGLE",
    MULTIPLE = "MULTIPLE",
    SYSTEM = "SYSTEM",
    IF = "IF",
    REVERSE = "REVERSE",
    TEASER = "TEASER",
}

export enum SportsbookOddsType {
    DECIMAL = "DECIMAL",
    AMERICAN = "AMERICAN",
    FRACTIONAL = "FRACTIONAL",
    MALAYSIAN = "MALAYSIAN",
    HONGKONG = "HONGKONG",
    INDONESIAN = "INDONESIAN",
    UNKNOWN = "UNKNOWN",
}

export enum SportsbookProvider {
    SONG88 = "SONG88",
    ULTRAPLAY = "ULTRAPLAY",
    SABA = "SABA",
    BETBY = "BETBY",
}

export enum Status {
    SUCCESS = "SUCCESS",
    FAILURE = "FAILURE",
    PASSWORD_CHANGE_REQUIRED = "PASSWORD_CHANGE_REQUIRED",
    PASSWORD_CHANGE_WITH_OTP_REQUIRED = "PASSWORD_CHANGE_WITH_OTP_REQUIRED",
    INVALID_PASSWORD_CHANGE = "INVALID_PASSWORD_CHANGE",
    INVALID_PASSWORD_CHANGE_OTP = "INVALID_PASSWORD_CHANGE_OTP",
    BLOCKED = "BLOCKED",
    INVALID_TOKEN = "INVALID_TOKEN",
    INVALID_OTP = "INVALID_OTP",
    OTP_REQUIRED = "OTP_REQUIRED",
}

export enum Style {
    main = "main",
    alternative = "alternative",
    promo = "promo",
    lite = "lite",
}

export enum SystemEventSubType {
    registrationBlocked = "registrationBlocked",
    payments = "payments",
    sportsbook = "sportsbook",
    wallet = "wallet",
    casinoGameUpdateFailure = "casinoGameUpdateFailure",
    bonusPayoutFailure = "bonusPayoutFailure",
    casinoRollbackFailure = "casinoRollbackFailure",
    casinoPayoutFailure = "casinoPayoutFailure",
    kyc = "kyc",
    affiliateRegistration = "affiliateRegistration",
    betLimits = "betLimits",
    captcha = "captcha",
    userDelete = "userDelete",
}

export enum TransactionSearchType {
    DEFAULT = "DEFAULT",
    ATTRIBUTE = "ATTRIBUTE",
}

/**
 * Values:
 * - `POKER_DEPOSIT`
 * - `POKER_WITHDRAW`
 * - `CASINO_BET`
 * - `CASINO_WIN`
 * - `ROLLBACK`
 * - `MANUAL` - @deprecated
 * - `DEPOSIT`
 * - `WITHDRAWAL`
 * - `WITHDRAWAL_CANCEL`
 * - `CORRECTION`
 * - `DEPOSIT_CANCEL`
 * - `UNKNOWN`
 * - `SPORTSBOOK_BET`
 * - `SPORTSBOOK_SETTLEMENT`
 * - `SPORTSBOOK_CANCELLATION`
 * - `SPORTSBOOK_UNSETTLEMENT`
 * - `SPORTSBOOK_DECLINED`
 * - `SPORTSBOOK_REFUND`
 * - `CASINO_BET_WIN` - @deprecated
 * - `RACING_BET`
 * - `RACING_SETTLEMENT`
 * - `API`
 * - `BONUS`
 * - `CASH_BONUS`
 * - `PLAYER_TRANSFER`
 * - `AFFILIATE_PAYOUT`
 * - `AGENT_TRANSFER`
 */
export enum TransactionType {
    POKER_DEPOSIT = "POKER_DEPOSIT",
    POKER_WITHDRAW = "POKER_WITHDRAW",
    CASINO_BET = "CASINO_BET",
    CASINO_WIN = "CASINO_WIN",
    ROLLBACK = "ROLLBACK",
    /**
     * @deprecated
     */
    MANUAL = "MANUAL",
    DEPOSIT = "DEPOSIT",
    WITHDRAWAL = "WITHDRAWAL",
    WITHDRAWAL_CANCEL = "WITHDRAWAL_CANCEL",
    CORRECTION = "CORRECTION",
    DEPOSIT_CANCEL = "DEPOSIT_CANCEL",
    UNKNOWN = "UNKNOWN",
    SPORTSBOOK_BET = "SPORTSBOOK_BET",
    SPORTSBOOK_SETTLEMENT = "SPORTSBOOK_SETTLEMENT",
    SPORTSBOOK_CANCELLATION = "SPORTSBOOK_CANCELLATION",
    SPORTSBOOK_UNSETTLEMENT = "SPORTSBOOK_UNSETTLEMENT",
    SPORTSBOOK_DECLINED = "SPORTSBOOK_DECLINED",
    SPORTSBOOK_REFUND = "SPORTSBOOK_REFUND",
    /**
     * @deprecated
     */
    CASINO_BET_WIN = "CASINO_BET_WIN",
    RACING_BET = "RACING_BET",
    RACING_SETTLEMENT = "RACING_SETTLEMENT",
    API = "API",
    BONUS = "BONUS",
    CASH_BONUS = "CASH_BONUS",
    PLAYER_TRANSFER = "PLAYER_TRANSFER",
    AFFILIATE_PAYOUT = "AFFILIATE_PAYOUT",
    AGENT_TRANSFER = "AGENT_TRANSFER",
}

export enum TransferRequestType {
    CREDIT = "CREDIT",
    DEBIT = "DEBIT",
}

export enum TranslationExportType {
    INCLUDE_MISSING = "INCLUDE_MISSING",
    ONLY_MISSING = "ONLY_MISSING",
    ONLY_DEFINED = "ONLY_DEFINED",
}

export enum TxBatchError {
    USER_NOT_FOUND = "USER_NOT_FOUND",
    INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS",
    BAD_INPUT = "BAD_INPUT",
    WRONG_CURRENCY = "WRONG_CURRENCY",
    BATCH_RUNNING = "BATCH_RUNNING",
    GENERAL = "GENERAL",
    BATCH_NOT_FOUND = "BATCH_NOT_FOUND",
    PARTIAL = "PARTIAL",
    BAD_STATUS = "BAD_STATUS",
}

export enum TxBatchStatus {
    FAILED = "FAILED",
    PREPARED = "PREPARED",
    RUNNING = "RUNNING",
    FINISHED = "FINISHED",
}

export enum UpdateResultCode {
    SUCCESS = "SUCCESS",
    NO_CHANGES = "NO_CHANGES",
    LOCKED = "LOCKED",
    VALIDATION_ERROR = "VALIDATION_ERROR",
}

export enum UserBonusEventStatus {
    COMPLETED = "COMPLETED",
    PENDING = "PENDING",
    CANCELED = "CANCELED",
    TIMEOUT = "TIMEOUT",
    IGNORE = "IGNORE",
}

export enum UserBonusEventType {
    STARTED = "STARTED",
    GAME = "GAME",
    GAME_WIN = "GAME_WIN",
    GAME_ROLLBACK = "GAME_ROLLBACK",
    PAYOUT = "PAYOUT",
    COMPLETED = "COMPLETED",
    BUSTED = "BUSTED",
    CANCELED = "CANCELED",
    CANCELED_ADMIN = "CANCELED_ADMIN",
    EXPIRED = "EXPIRED",
    ERROR = "ERROR",
    DEPOSIT = "DEPOSIT",
}

export enum UserBonusStatus {
    RUNNING = "RUNNING",
    COMPLETED = "COMPLETED",
    CANCELED = "CANCELED",
    EXPIRED = "EXPIRED",
    BUSTED = "BUSTED",
}

export enum UserLoginPrincipal {
    EMAIL = "EMAIL",
    PHONE_NUMBER = "PHONE_NUMBER",
}

export enum UserPrimaryField {
    loginPrincipal = "loginPrincipal",
    email = "email",
    phoneNumber = "phoneNumber",
    password = "password",
    termsAccepted = "termsAccepted",
}

export enum UserRiskResult {
    SUCCESS = "SUCCESS",
    FLAG = "FLAG",
    VERIFICATION_REQUIRED = "VERIFICATION_REQUIRED",
    BLOCK = "BLOCK",
}

export enum UserRiskRuleType {
    ALWAYS_SUCCESS = "ALWAYS_SUCCESS",
    COUNTRY_MISMATCH = "COUNTRY_MISMATCH",
    IP_ADDRESS = "IP_ADDRESS",
    IP_BLACKLIST = "IP_BLACKLIST",
}

export enum UserWriteAccess {
    NONE = "NONE",
    CREATE = "CREATE",
    CREATE_AND_UPDATE = "CREATE_AND_UPDATE",
}

export enum ValidationResultCode {
    VALIDATED = "VALIDATED",
    NO_SCHEMA = "NO_SCHEMA",
    VALIDATION_ERROR = "VALIDATION_ERROR",
    JSON_ERROR = "JSON_ERROR",
    SKIPPED = "SKIPPED",
}

export enum VoucherStatus {
    CREATED = "CREATED",
    OPEN = "OPEN",
    REDEEMED = "REDEEMED",
    REVOKED = "REVOKED",
    EXPIRED = "EXPIRED",
}
