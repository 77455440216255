import {HttpService} from "../http/HttpService";
import {AdminUser, ChangePasswordLoginRequest, LoginRequest, LoginResponse} from "../http/protocol";


export class UserAuthenticationService   {

    constructor(private http: HttpService) {}

    loadUserInfo() : Promise<AdminUser> {
        return this.http.get<AdminUser>("/user/info");
    }
    logout() : Promise<{}> {
        return this.http.get<{}>("/user/logout");
    }

    login(username : string, password : string, otp? : string) {
        return this.http.post<LoginResponse,LoginRequest>("/user/login",
            { username : username, password: password, otp : otp}) ;
    }
    pingAuthenticated() : Promise<{}> {
        return this.http.get<{}>("/user/ping-authenticated");
    }

    ping() : Promise<{}> {
        return this.http.get<{}>("/user/ping");
    }

    getGroupPermissions() : Promise<{[key:string]:boolean} >{
        return this.http.get<{[key:string]:boolean}>("/user/group-permissions");
    }

    changePasswordAndLogin(username: string, password: string, newPassword: string, otp?: string): Promise<LoginResponse> {
        return this.http.post<LoginResponse,ChangePasswordLoginRequest>("/user/change-password-and-login", {
            oldPassword : password,
            otp: otp,
            loginRequest : { username : username, password : newPassword }
        });
    }

}