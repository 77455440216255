import React, {Fragment} from 'react';
import "./Home.scss";
import {ChartList} from "./ui/charts/ChartList";
import {HomeMetrics} from "./HomeMetrics";
import {AccountService} from "./services/AccountService";
import {getContainer} from "./ioc/IOCSetup";
import {Currency} from "./http/protocol";
import {act} from "react-dom/test-utils";
import {t} from "./i18n";
import { ACTIVE_CURRENCY_KEY } from './ui/CurrencySelector';



interface State {
    currencies: Currency[];
    selectedCurrency: string;
}


export class Home extends React.Component<{},State> {
    accountService: AccountService;

    constructor(props: {}) {
        super(props);
        this.accountService = getContainer().getAccountService();
    }
    componentDidMount(): void {
        let activeCurrency = window.localStorage.getItem(ACTIVE_CURRENCY_KEY);
        if(activeCurrency) {
            this.setState({selectedCurrency:activeCurrency})
        } else {
            this.accountService.getCurrencies().then((data)=>{
                if(data.length > 0 && data[0].code) {
                    this.setState({currencies:data, selectedCurrency: data[0].code})
                }
            })
        }
    }

    render() {
            return (
                <Fragment>
                    <h3>{t(`home.dashboard.pageTitle`)}</h3>
                    {this.state != null && this.state.selectedCurrency && (<HomeMetrics currency={this.state.selectedCurrency}/>)}
                    <ChartList byTag={"home"}/>
                </Fragment>
            );
        }
}