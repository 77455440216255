import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {AdminSession, AdminUser, AuthResult, ListAdminUsersResponse} from "../http/protocol";
import {HttpService} from "../http/HttpService";


export class AdminUserService extends AbstractAdminEntityService<AdminUser, ListAdminUsersResponse> {

    constructor(http: HttpService) {
        super("/admin-user", http);
    }

    resetPassword(userId?: number): Promise<AdminUser> {
        return this.http.get(this.entityPath + "/reset-password/" + userId);
    }

    invalidateSessions(userId: number): Promise<void> {
        return this.http.get(this.entityPath + `/sessions/invalidate/${userId}`);
    }

    getSessions(userId: number): Promise<AdminSession[]> {
        return this.http.get(this.entityPath + `/sessions/${userId}`);
    }

    initiateAppMfa(): Promise<AuthResult> {
        return this.http.get(this.entityPath + "/mfa/init");
    }

    verifyAppMfa(code: string): Promise<AuthResult> {
        return this.http.post(this.entityPath + "/mfa/verify/",
            {provider: "AUTHENTICATOR_APP", verificationCode: code});
    }

    // if users want to reset mfa, they need to provide their current code
    resetAppMfa(code: string): Promise<AuthResult> {
        return this.http.post(this.entityPath + "/mfa/reset",
            {provider: null, verificationCode: code});
    }

    setMfaProvider(provider: string): Promise<AuthResult> {
        return this.http.post(this.entityPath + "/mfa/method",
            {provider: provider, verificationCode: null});
    }

    // used by super admin on behalf of other admins
    resetMfa(userId: number) {
        return this.http.delete(this.entityPath + `/${userId}/mfa`);
    }
}