import { useEffect, useState } from "react";
import { CasinoPage, MenuItem, MenuItemType, SubMenuTab } from "../../http/protocol";
import { useForm } from "react-hook-form";
import _ from "lodash";

import EditCasinoSubmenu from "./EditCasinoSubmenu";
import { AsyncStatus, resolvePendingStatus } from "./utils";
import { Translation } from "./EditUtils";
import EditCustomSubmenu from "./EditCustomSubmenu";
import EditIconPack from "./EditIcon";
import EditIcons from "./EditIcon";
import { MenuType } from "./EditMenuView";

interface Props {
  menuItem: MenuItem;
  setMenuItem?: (item: MenuItem) => void;
  settMenuItemIndex: (index: number | undefined) => void;
  deleteMenuItem: () => void;
  setSubmitItemStatus: (status: AsyncStatus) => void;
  SubmitItemStatus: AsyncStatus;
  menuType: MenuType;
}

export default function EditMenuItem({ menuItem, settMenuItemIndex, setMenuItem, deleteMenuItem, setSubmitItemStatus, SubmitItemStatus, menuType }: Props) {
  const [item, setItems] = useState<MenuItem>();
  const { register, handleSubmit, watch, setValue, reset } = useForm<MenuItem>({ defaultValues: item });

  const selectedType = watch("type");
  const selectedIcon = watch("icon");
  const supportLink = watch("supportLink");

  const [viewIcons, setViewIcons] = useState(false);

  function onSubmit(data: MenuItem) {
    console.log("FORM : {}", data);

    const form = Object.fromEntries(Object.entries(data).filter(([key, value]) => value !== undefined && value !== null && value !== ""));
    if (setMenuItem) {
      setMenuItem(form);
    }
    setSubmitItemStatus(AsyncStatus.pending);
    resolvePendingStatus(setSubmitItemStatus, 300, AsyncStatus.success).then(() => {
      settMenuItemIndex(undefined);
    });
  }

  const saveSubmenu = (submenu: SubMenuTab[], pageId: number, pageName: string, defaultPage: boolean, reset?: boolean) => {
    if (submenu.length > 0) {
      setValue("subMenu.tabs", submenu);
      setValue("subMenu.id", pageId);
      setValue("subMenu.name", pageName);
      setValue("subMenu.defaultCasinoPage", defaultPage);
    }
    if (reset) {
      setValue("subMenu", undefined);
    }
  };

  const saveTranslations = (translations: { [index: string]: string }) => {
    setValue("translations", translations);
  };

  const toggleIconModal = () => {
    setViewIcons((p) => !p);
  };

  const saveIcon = (tag: string) => {
    setValue("icon", tag);
  };

  useEffect(() => {
    if (menuItem) {
      setItems(menuItem);
      reset(menuItem);
    }
  }, [menuItem]);

  useEffect(() => {
    setItems((prev) => ({ ...prev, type: selectedType }));
  }, [selectedType]);

  let showCustom = selectedType == MenuItemType.CUSTOM;
  let showCasino = selectedType == MenuItemType.CASINO;

  let typeList = menuType === MenuType.MENU_MAIN ? [MenuItemType.POKER, MenuItemType.CASINO, MenuItemType.SPORTSBOOK, MenuItemType.RACING, MenuItemType.CUSTOM] : [MenuItemType.CUSTOM];

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="row edit-menu-item">
            <div className="col-lg-12">
              <form
                onSubmit={handleSubmit((formValues) => {
                  onSubmit(formValues);
                })}
              >
                <h4 className=" w-full d-flex justify-content-between">
                  Edit Menu Item
                  <div style={{ gap: "5px" }} className="d-flex">
                    <button onClick={() => deleteMenuItem()} className={"btn btn-danger pull-right"}>
                      Remove
                    </button>
                    <a className={"btn btn-secondary"} onClick={() => settMenuItemIndex(undefined)}>
                      Close
                    </a>
                  </div>
                </h4>
                <div className={"form-group mt-2"}>
                  <label>Type</label>
                  <select {...register("type")} className={"form-control"}>
                    {typeList.map((v, i) => {
                      return (
                        <option key={i} value={v}>
                          {v}
                        </option>
                      );
                    })}
                  </select>
                  <span className={"help-block"}>Select what type of menu item you want to add, you can either choose a predefined product menu item or CUSTOM to add any type of menu item. </span>
                </div>
                {menuType == MenuType.MENU_FOOTER && (
                  <>
                    <div className={"form-group form-check p-0 mt-2"}>
                      <label>
                        <input {...register("supportLink")} type={"checkbox"} /> Support link
                      </label>
                      <span className={"help-block"}> - If you want this item to be a support link</span>
                    </div>
                    <div className={"form-group form-check p-0 mt-2"}>
                      <label>
                        <input {...register("disableSidebarCollapse")} type={"checkbox"} /> Disable for collpased mode
                      </label>
                      <span className={"help-block"}> - If you want this item to only be displayed in expanded mode "Fixed Menu Layout"</span>
                    </div>
                  </>
                )}
                {menuType == MenuType.MENU_MAIN && (
                  <>
                    <div className={"form-group form-check p-0 mt-2"}>
                      <label>
                        <input {...register("includeInMobileMenu")} type={"checkbox"} /> Include In Mobile Menu
                      </label>
                      <span className={"help-block"}> - If you want this item to be included in the bottom menu on mobile</span>
                    </div>
                    <div className={"form-group form-check p-0 mt-2"}>
                      <label>
                        <input {...register("includeInDesktopMenu")} type={"checkbox"} /> Include In Desktop Menu
                      </label>
                      <span className={"help-block"}> - If you want this item to be included in the top menu on desktop</span>
                    </div>
                  </>
                )}

                <div className={"form-group form-check p-0 mt-2"}>
                  <label>
                    <input {...register("includeInSidebarMenu")} type={"checkbox"} /> Include In Sidebar Menu
                  </label>
                  <span className={"help-block"}> - If you want this item to be included in the Sidebar on desktop and mobile</span>
                </div>
                {!supportLink && (
                  <>
                    <div className={"form-group form-check p-0 mt-2"}>
                      <label>Include for Countries</label>
                      <input className={"form-control"} {...register("includeCountries")} type={"text"} />
                      <span className={"help-block"}>Comma separated list of country codes. Example (Germany & Sweden): DE, SE</span>
                    </div>
                    <div className={"form-group form-check p-0 mt-2"}>
                      <label>Exclude for Countries</label>
                      <input className={"form-control"} type={"text"} {...register("excludeCountries")} />
                      <span className={"help-block"}>Comma separated list of country codes. Example (Germany & Sweden): DE, SE</span>
                    </div>
                  </>
                )}
                {(showCustom || showCasino) && (
                  <div className={"form-group mt-2"}>
                    <label>Name</label>
                    <input {...register("name")} className={"form-control"} />
                    <span className={"help-block"}>The default translation, displayed to the user, of the of the menu item if the specific translation does not exist</span>
                  </div>
                )}
                {!supportLink && showCustom && (
                  <div className={"form-group"}>
                    <label>Url</label>
                    <input {...register("url")} className={"form-control"} />
                    <span className={"help-block"}>
                      The Menu items URL, if it starts with / it will be considered to be an internal link and language etc will be appended automatically. (eg /poker) <br />
                      If it's an external URL you can use <span dangerouslySetInnerHTML={{ __html: "{languageCode}" }}></span> in the URL to get the current user language <br />
                      (eg https://test.com/?lang= <span dangerouslySetInnerHTML={{ __html: "{languageCode}" }}></span>
                    </span>
                  </div>
                )}
                {!supportLink && showCustom && (
                  <div className={"form-group"}>
                    <label>Target</label>
                    <input {...register("target")} className={"form-control"} />
                    <span className={"help-block"}>Link target, if you want to open the menu item in a new tab etc</span>
                  </div>
                )}

                {(showCasino || showCustom) && <Translation translations={item?.translations} saveTranslations={saveTranslations} />}

                <div className="icon-select-container">
                  <label className="">Menu Item icon</label>
                  <div className={"form-group icon-preview"}>
                    <button onClick={toggleIconModal} type="button" className={"btn btn-primary"}>
                      Assign icon
                    </button>
                    <div className="form-group d-flex flex-column">
                      <label htmlFor="">Custom icon class</label>
                      <input disabled type="text" value={selectedIcon} />
                    </div>
                    <EditIcons icon={selectedIcon} isOpen={viewIcons} setIsOpen={toggleIconModal} saveIcon={saveIcon} />
                  </div>
                  <span className={"help-block"}>
                    Select Icon for menu item, to view selected: Click "Edit Icon". For more information on icon customization view our <br />
                    <a href="https://docs.cubeia.com/docs/navigation-sidemenu" target="_blank" rel="noopener noreferrer">
                      documentation
                    </a>
                  </span>
                </div>
                {menuType == MenuType.MENU_MAIN && (
                  <>
                    <div className="hr-line-dashed mt-2"></div>
                    {(showCasino || showCustom) && (
                      <div className={"form-group form-check p-0 mt-2"}>
                        <label>
                          <input {...register("expandSubmenuOnly")} type={"checkbox"} /> Disable linking for menu item in sidebar
                        </label>
                        <span className={"help-block"}>- If you want to only expand sub menu and disable linking when clicking on menu item, disable linking.</span>
                      </div>
                    )}
                    <div className="hr-line-dashed mt-1"></div>
                    <div>
                      {(showCasino || showCustom) && <h4 className="pb-4">Submenu</h4>}
                      {showCasino && <EditCasinoSubmenu handleChange={saveSubmenu} selectedType={selectedType} menuItem={menuItem} />}
                      {showCustom && <EditCustomSubmenu handleChange={saveSubmenu} selectedType={selectedType} menuItem={menuItem} />}
                    </div>
                  </>
                )}
                {menuType !== MenuType.MENU_MAIN && <div className="hr-line-dashed mt-2"></div>}
                <div className="form-group">
                  <a className={"btn btn-primary"} onClick={() => settMenuItemIndex(undefined)}>
                    Cancel
                  </a>
                  <button type="submit" className={"btn btn-success"}>
                    {SubmitItemStatus == AsyncStatus.pending ? (
                      <div className="spinner-border spinner-border-sm text-light" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
