import * as React from "react";
import {Modal} from "react-bootstrap";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {getContainer} from "../../ioc/IOCSetup";
import {ColumnType} from "../list/list/ColumnType";
import {CasinoStudio, GameIntegration, ListStudioRequest} from "../../http/protocol";
import {Form, FormListener} from "../form/Form";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Validators} from "../form/Validators";
import {Submit} from "../form/Submit";
import {ListUtils} from "../list/list/ListUtils";
import {IPagerModel, PagerModel} from "../list/list/PagerModel";
import {SelectInput} from "../form/SelectInput";

interface Props {
    showAddStudios?: boolean
    toggleShowAddStudios: (show : boolean) => void;
    addStudio : (studio : CasinoStudio) => void;
    removeStudio : (studio : CasinoStudio) => void;
    addedStudios : CasinoStudio[];
    includeIntegrations? : GameIntegration[] | any
    title?: string;
}
interface State {
    listRequest? : ListStudioRequest;
    form?: ListStudioRequest;
    validationMap? : any;
    pager? : IPagerModel;
}
export class AddCasinoStudio extends React.Component<Props, State> implements FormListener<ListStudioRequest>{
    columns = [
        { property : "id", name : "ID", orderBy : "id"},
        { property : "name", name : "name", orderBy : "name"},
        { property : "integration", name : "Integration"},
        { property : "enabled", name : "Enabled", type : ColumnType.YES_OR_NO},

    ];
    actions = [
        {
            id : "add", name : "Add", className : "btn btn-primary", iconClassName : "fe-plus-square",
                filter : (item : CasinoStudio) => {
                    return !this.props.addedStudios.some( s => s.id == item.id)
                }
        },
        {
            id : "remove", name : "Remove", className : "btn btn-warning", iconClassName : "fe-minus-square",
            filter : (item : CasinoStudio) => {
                return this.props.addedStudios.some( s => s.id == item.id)
            }
        }
    ];

    constructor(props : Props) {
        super(props);
        let pager : IPagerModel = { offset : 0, limit : 20, orderBy : "id", ascending : true};
        let integrations = this.props.includeIntegrations ? this.props.includeIntegrations : undefined;
        this.state = { validationMap : {}, listRequest : {...pager/* , integrations : integrations */ }, pager : pager };
    }

    onAction(item : any, action : string)  : undefined {
        if(action == "add") {
            this.props.addStudio(item);
        } else if(action == "remove") {
            this.props.removeStudio(item)
        }
        return undefined;
    }

    render(){
        if(!this.state.listRequest) {
            return <span></span>
        }
        return (
            <Modal  size="xl" show={this.props.showAddStudios} onHide={ () => { this.props.toggleShowAddStudios(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title ?? "Add Studios To Lobby Category"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className={"form"} formListener={this} modelObject={this.state.form} validationMap={this.state.validationMap}>
                        <FormGroup model={"name"} className={"form-group"}>
                            <label>Studios Name</label>
                            <FormInput className={"form-control"} model={"name"} type={"text"} validators={[Validators.strLength(2,100)]}/>
                        </FormGroup>
                        {this.props.includeIntegrations && (
                            <FormGroup className={"form-group"}>
                                <label>Integration</label>
                                <SelectInput className={"form-control"} model={"integrations"} values={this.props.includeIntegrations} multiple={true} required={true}></SelectInput>
                            </FormGroup>
                        )}
                        <Submit className={"btn btn-primary"}>Search Studio</Submit>
                    </Form>
                    <EntityDataTable service={getContainer().getCasinoStudioService()}
                                     listRequest={this.state.listRequest}
                                     columns={this.columns}
                                     onPagerChange={(pager : PagerModel)=>{ this.pagerChanged(pager)}}
                                     actions={this.actions}
                                     onAction={(item: any, action : string)=> this.onAction(item, action)}/>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={()=>this.props.toggleShowAddStudios(false)}>Close</button>
                </Modal.Footer>
            </Modal>
        )
    }
    pagerChanged(pager : PagerModel) {
        console.log("pager changed!", pager);
        let listRequest = ListUtils.toListRequest<ListStudioRequest>(this.state.listRequest as any,pager);
        this.setState({listRequest : listRequest});
    }
    onSubmit() {
        if(this.state.form && this.state.pager) {
            let listRequest = ListUtils.toListRequest(this.state.form, this.state.pager)
            this.setState({listRequest : listRequest});
        }
    }
    onSubmitError() {

    }

    formDidUpdate(formModel: ListStudioRequest, validationMap?: any): void {
        if(formModel && validationMap) {
            this.setState({ form : formModel, validationMap : validationMap});
        }
    }
}