import * as React from "react";
import {AdminEntityService} from "../../../services/AdminEntityService";
import {ListRequest} from "../../../http/protocol";
import {DataTable, DataTableAction, Renderer} from "./DataTable";
import {PagerModel} from "./PagerModel";
import {toPathParams} from "../../../util/UrlUtils";
import {ColumnType} from "./ColumnType";
import {t} from "../../../i18n";
import {Fragment} from "react";

export type ColumnDefinition = { property : string, secondProperty?:string, name : string, renderer?: Renderer<any>, className? : string, orderBy? : string, type? : ColumnType}

interface Props {
    service : AdminEntityService<any>;
    listRequest : ListRequest;
    columns: Array<ColumnDefinition>;
    onPagerChange : (pager : PagerModel) => void;
    onAction : (item : any, actionId : string) => undefined | Promise<any>;
    actions? : Array<DataTableAction>;
    showIndex? : boolean;
    onResults? : (result : any) => void;
    disableInitialSearch? : boolean;
    title?: string;
    hideTitle? : boolean;
    disableCount? : boolean;
    hidePagination? : boolean;
    setMaxLimit?:number;
}
interface State {
    items? : any[];
    pager? : PagerModel;
    loading? : boolean;
    removed? : number[];
    itemsUpdating? : number[];

}
export class EntityDataTable extends React.Component<Props,State>{

    mounted? : boolean = false;

    constructor(props : Props) {
        super(props);
        var pager = new PagerModel();
        let listRequest = props.listRequest;
        if(listRequest.orderBy) {
            pager.orderBy = listRequest.orderBy;
        }
        if(listRequest.ascending != null) {
            pager.ascending = listRequest.ascending;
        }
        if(listRequest.offset) {
            pager.offset = listRequest.offset;
        }
        if(listRequest.limit) {
            pager.limit = listRequest.limit;
        }


        this.state = { items : [], pager : pager, loading : false, removed : [], itemsUpdating : []};
    }
    componentDidMount() {
        this.mounted = true;
        if(!this.props.disableInitialSearch) {
            this.doSearch();
        }
    }
    componentWillUnmount() {
        this.mounted = false;

    }

    componentDidUpdate(previousProps : Props, previousState : State) {
        let prev = toPathParams(previousProps.listRequest);
        let current = toPathParams(this.props.listRequest);
        if(prev != current ) {
            this.doSearch();
        }
    }
    doSearch() {
        console.log("DO SEARCH ", this.props);
        if(this.props.service == null) {
            return;
        }
        this.setState({loading : true, removed : []});
        if(this.props.listRequest) {
            this.props.service.list(this.props.listRequest).then( (listResponse: any)   => {
                var pager = new PagerModel(this.state.pager, (listResponse.nrOfResults || listResponse["total"]));
                var list : any[] = listResponse["items"] || listResponse["values"];
                if(this.mounted == true) {
                    this.setState({ items : list, pager : pager, loading : false});
                    if(this.props.onResults) {
                        this.props.onResults(listResponse);
                    }
                }
            });
        }


    }

    manualSearch(){
        this.doSearch();
    }

    onAction(item : any, id : string, index : number) : Promise<any> | undefined {
        var result : undefined | Promise<any> = this.props.onAction(item,id);
        if(typeof result !== "undefined" && id == "delete") {
            var promise  = (result as Promise<{}|any>);
            this.state.itemsUpdating && this.state.itemsUpdating.push(index);
            this.setState({itemsUpdating : this.state.itemsUpdating})
            return promise.then( () => {
                this.state.removed && this.state.removed.push(index);
                this.state.itemsUpdating && this.state.itemsUpdating.splice(this.state.itemsUpdating.indexOf(index), 1);
                this.setState( { removed :  this.state.removed, itemsUpdating : this.state.itemsUpdating });
            }).catch(()=>{
                this.state.itemsUpdating  && this.state.itemsUpdating.splice(this.state.itemsUpdating.indexOf(index), 1);
                this.setState( { itemsUpdating : this.state.itemsUpdating });
            });
        }

    }

    onPagerChange(pager : PagerModel) {
        this.setState({pager : pager});
        this.props.onPagerChange && this.props.onPagerChange(pager);
    }
    render() {
        return (
            <Fragment>
                <div className={"card-box"}>

                    {!this.props.hideTitle && (
                        <h4 className={"header-title"}>{this.props.title ? this.props.title : t(`title.results`)}</h4>
                    )}
                    <DataTable  onAction={this.onAction.bind(this)}
                                onPagerChange={this.onPagerChange.bind(this)}
                                items={this.state.items ? this.state.items : []}
                                columns={this.props.columns}
                                pager={this.state.pager ? this.state.pager : new PagerModel()}
                                loading={this.state.loading}
                                actions={this.props.actions}
                                removed={this.state.removed}
                                itemsUpdating={this.state.itemsUpdating ? this.state.itemsUpdating : []}
                                showIndex={this.props.showIndex}
                                setMaxLimit={this.props.setMaxLimit}
                                disableCount={this.props.disableCount}
                                hidePagination={this.props.hidePagination}
                    />

                </div>
            </Fragment>

        );
    }



}
