import * as React from "react";
import {Fragment} from "react";
import {SettingsService} from "../../services/SettingsService";
import {getContainer} from "../../ioc/IOCSetup";
import {LoadingViewState, RouterView} from "../RouterView";
import {Form, FormListener} from "../form/Form";
import {RouteComponentProps, withRouter} from "react-router";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Submit} from "../form/Submit";
import {Settings} from "../../http/protocol";
import {Validators} from "../form/Validators";

interface Props extends RouteComponentProps {

}

interface State extends LoadingViewState{
    settings?: Settings;
    validationMap? : any;
    manifest: Manifest;
}

type Manifest = {
    shortName?:string;
    name?:string;
    description?:string;
    icons?:[];
    start_url?:string;
    display?:string;
    orientation?:string;
    background_color?:string;
    theme_color?:string;
}

export class SettingsPWAView extends RouterView<Props,State> implements FormListener<Settings>{

    settingsService: SettingsService;

    constructor(props:Props) {
        super(props);

        this.settingsService = getContainer().getSettingsService();

        this.state = {validationMap : {}, loading : true, manifest:{}};
    }

    loadContent(): Promise<any> | undefined {
        return this.settingsService.getSettings().then((data:Settings) => {
            let dataFromService = data;
            if(data.clientManifest == null) {
                let title = data.title;
                let redirectUrl = data.loginRedirectUrl;
                dataFromService.clientManifest = this.getManifestForm(title, redirectUrl);
            }
            if(data.iosMediaJSON == null || data.iosMediaJSON.length == 0) {
                dataFromService.iosMediaJSON = this.getIosMediaTemplate();
            }
            this.setState({settings:dataFromService});
        });
    }

    componentDidUpdate(prevProps : RouteComponentProps, prevState : any) {
        super.componentDidUpdate(prevProps, prevState);

    }
    formDidUpdate(formModel:Settings, validationMap : any) {
        this.setState({ settings : formModel, validationMap : validationMap});
    }

    onSubmit():Promise<any> {
        if(!this.state.settings) {
            return Promise.reject("Unable to save settings");
        }


        return this.settingsService.saveSettings(this.state.settings).catch(err=>{
            throw "Unable to create/save settings";
        });
    }

    onSubmitError(){}

    getTitle() : string {
        return "PWA Settings";
    }

    getManifestForm(title?:string, redirectUrl?: string) {
        return (
            `{
  "name": "Nano Casino",
  "short_name": "Nano",
  "description": "Nano Description",
  "lang": "en",
  "start_url": "/app",
  "display": "fullscreen",
  "theme_color": "#222222",
  "background_color": "#222",
  "icons": [
    {
      "src": "",
      "sizes": "96x96",
      "type": "image/png"
    },
    {
      "src": "",
      "sizes": "144x144",
      "type": "image/png"
    },
    {
      "src": "",
      "sizes": "192x192",
      "type": "image/png"
    },
    {
      "src": "",
      "sizes": "512x512",
      "type": "image/png"
    },
    {
      "src": "",
      "sizes": "172x172",
      "type": "image/png"
    },
    {
      "src": "",
      "sizes": "180x180",
      "type": "image/png"
    },
    {
      "src": "",
      "sizes": "152x152",
      "type": "image/png"
    },
    {
      "src": "",
      "sizes": "167x167",
      "type": "image/png"
    }
  ]
}`
        );
    };


    getIosMediaTemplate() {
        return(
            `{
  "icons": [
    {
      "type":"all",
      "size":"180x180",
      "href":""
    }
  ],
  "splashScreens": [
    {
      "type":"iPhone Xs Max (1242px x 2688px)",
      "width":"414px",
      "height":"896px",
      "pixelRatio":"3",
      "href":"https://nano-public.s3-eu-west-1.amazonaws.com/neobet/ios/iphonexsmax_splash.png"
    },
    {
      "type":"iPhone Xr (828px x 1792px)",
      "width":"414px",
      "height":"896px",
      "pixelRatio":"2",
      "href":"https://nano-public.s3-eu-west-1.amazonaws.com/neobet/ios/iphonexr_splash.png"
    },
    {
      "type":"iPhone X, Xs (1125px x 2436px)",
      "width":"375px",
      "height":"812px",
      "pixelRatio":"3",
      "href":"https://nano-public.s3-eu-west-1.amazonaws.com/neobet/ios/iphonex_splash.png"
    },
    {
      "type":"iPhone 8 Plus, 7 Plus, 6s Plus, 6 Plus (1242px x 2208px)",
      "width":"414px",
      "height":"736px",
      "pixelRatio":"3",
      "href": "https://nano-public.s3-eu-west-1.amazonaws.com/neobet/ios/iphoneplus_splash.png"
    },
    {
      "type":"iPhone 8, 7, 6s, 6 (750px x 1334px)",
      "width":"375px",
      "height":"667px",
      "pixelRatio":"2",
      "href":"https://nano-public.s3-eu-west-1.amazonaws.com/neobet/ios/iphone6_splash.png"
    },
    {
      "type":"iPad Pro 12.9” (2048px x 2732px)",
      "width":"1024px",
      "height":"1366px",
      "pixelRatio":"2",
      "href":"https://nano-public.s3-eu-west-1.amazonaws.com/neobet/ios/ipadpro3_splash.png"
    },
    {
      "type":"iPad Pro 11” (1668px x 2388px)",
      "width":"834px",
      "height":"1194px",
      "pixelRatio":"2",
      "href":"https://nano-public.s3-eu-west-1.amazonaws.com/neobet/ios/ipadpro2_splash.png"
    },
    {
      "type":"iPad Pro 10.5” (1668px x 2224px)",
      "width":"834px",
      "height":"1112px",
      "pixelRatio":"2",
      "href":"https://nano-public.s3-eu-west-1.amazonaws.com/neobet/ios/ipadpro1_splash.png"
    },
    {
      "type":"iPad Mini, Air (1536px x 2048px)",
      "width":"768px",
      "height":"1024px",
      "pixelRatio":"2",
      "href":"https://nano-public.s3-eu-west-1.amazonaws.com/neobet/ios/ipad_splash.png"
    }
  ]
}`
        )
    }

    renderContent() {
        const pwaEnabled:boolean = this.state.settings && this.state.settings.pwaEnabled != null ? this.state.settings.pwaEnabled : false;
        if(!this.state.settings) {
            return <span></span>
        }
        return(
            <Fragment>
                <Form className="form-horizontal" formListener={this} modelObject={this.state.settings} validationMap={this.state.validationMap}>
                    <div className={"card"}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <FormSubmitError />
                                    <FormSubmitSuccess text="Settings was updated successfully"/>
                                    <FormGroup className="custom-control custom-checkbox" model="pwaEnabled">
                                        <FormInput className="custom-control-input"  id={"pwaEnabled"} model="pwaEnabled" type="checkbox"/>
                                        <label htmlFor={"pwaEnabled"} className={"custom-control-label"}>PWA Activated - for all devices</label><br/>
                                        <span className="help-block">This toggles pwa on and off for all devices.</span><br/>
                                        <span className="help-block alert-warning">OBS! You still need to enable the specific platforms below for it to work.</span><br/>
                                        <span className="help-block alert-warning">OBS! Setup the manifest json below before activating this.</span>
                                    </FormGroup>
                                    <FormGroup className="custom-control custom-checkbox" model="pwaEnabledAndroid">
                                        <FormInput className="custom-control-input"  id={"pwaEnabledAndroid"} model="pwaEnabledAndroid" type="checkbox"/>
                                        <label htmlFor={"pwaEnabledAndroid"} className={"custom-control-label"}>PWA Enabled - for Android</label><br/>
                                        <span className="help-block">Toggle if you want to allow your users to download the web app to their Android devices.</span><br/>
                                    </FormGroup>
                                    <FormGroup className="custom-control custom-checkbox" model="pwaEnabledIOS">
                                        <FormInput className="custom-control-input"  id={"pwaEnabledIOS"} model="pwaEnabledIOS" type="checkbox"/>
                                        <label htmlFor={"pwaEnabledIOS"} className={"custom-control-label"}>PWA Enabled - for IOS</label><br/>
                                        <span className="help-block">Toggle if you want to allow your users to download the web app to their IOS devices.</span><br/>
                                    </FormGroup>
                                    <FormGroup className="custom-control custom-checkbox" model="pwaEnabledWindows">
                                        <FormInput className="custom-control-input"  id={"pwaEnabledWindows"} model="pwaEnabledWindows" type="checkbox"/>
                                        <label htmlFor={"pwaEnabledWindows"} className={"custom-control-label"}>PWA Enabled - for Windows</label><br/>
                                        <span className="help-block">Toggle if you want to allow your users to download the web app to their Window devices.<p className="alert-danger">NOT YET FULLY SUPPORTED!</p></span><br/>
                                    </FormGroup>
                                    <FormGroup className="form-group mb-3" model="clientManifest">
                                        <label>Manifest</label><br/>
                                        <span className="help-block alert-danger"><strong>Note:</strong> If you update your manifest.json file in the future, those changes won't be automatically picked up by the users unless they re-add your app to their home screen.</span>
                                        <FormInput id="clientManifest" className="form-control" model="clientManifest" type="json" readOnly={pwaEnabled}/>
                                        <span className="help-block alert-info">short_name : This is the name that will be displayed beneath the app icon on the users home screen.</span><br/>
                                        <span className="help-block alert-info">name : The full name that will be shown during the splash screen</span><br/>
                                        <span className="help-block alert-info">start_url : The start url/page for the app.</span><br/>
                                        <span className="help-block alert-info">background_color : The background color of the splashscreen that is shown when loading the app</span><br/>
                                        <span className="help-block alert-info">theme_color: changes the native nav bar color. this is not visible in fullscreen display</span><br/>
                                        <span className="help-block alert-info">icons : This is the icon that will be shown on the home screen. If not set, this wont work.</span><br/>
                                    </FormGroup>
                                    <FormGroup className="form-group mb-3" model="iosMediaJSON">
                                        <label>iOS Media JSON</label><br/>
                                        <FormInput id="iosMediaJSON" className="form-control" model="iosMediaJSON" type="json"/>
                                    </FormGroup>

                                </div>
                            </div>
                        </div>
                        <div className={"card"}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <FormGroup className="custom-control custom-checkbox" model="appPopupEnabled">
                                            <FormInput className="custom-control-input"  id={"appPopupEnabled"} model="appPopupEnabled" type="checkbox"/>
                                            <label htmlFor={"appPopupEnabled"} className={"custom-control-label"}>App Popup Enabled</label><br/>
                                            <span className="help-block">If enabled, a popup will appear on users devices prompting them to install the app when they visit the site. The content for the popup can be set and modified in Contentful!</span><br/>
                                        </FormGroup>
                                        <FormGroup className="custom-control custom-checkbox" model="appInstallButtonsEnabled">
                                            <FormInput className="custom-control-input"  id={"appInstallButtonsEnabled"} model="appInstallButtonsEnabled" type="checkbox"/>
                                            <label htmlFor={"appInstallButtonsEnabled"} className={"custom-control-label"}>App Install Buttons Enabled</label><br/>
                                            <span className="help-block">If disabled, the buttons for installing the app will be hidden.</span><br/>
                                        </FormGroup>
                                        <FormGroup className="form-group mb-3" model={"delayAppInstallPopup"}>
                                            <label>Delay App Install Popup by</label>
                                            <FormInput className="form-control" model={"delayAppInstallPopup"} type="number" validators={[Validators.number()]}/>
                                            <span className={"help-block"}>Number of visits before the popup will appear. 0 or not set means no delay</span>
                                        </FormGroup>
                                        <FormGroup className="form-group mb-3" model={"noOfTimeBeforePromptingAgain"}>
                                            <label>Number Of Times Before Prompting Again</label>
                                            <FormInput className="form-control" model={"noOfTimeBeforePromptingAgain"} type="number" validators={[Validators.number()]}/>
                                            <span className={"help-block"}>Number of visits before starting to show the popup again. 0 or not set means it wont show again.</span>
                                        </FormGroup>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"card"}>
                        <div className={"card-body"}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="ibox float-e-margins">
                                        <div className="ibox-content">
                                            <div className="ibox-title">
                                                <h5>Actions</h5>
                                            </div>
                                            <div className="form-group">
                                                <div className="col-sm-10 col-sm-offset-2">
                                                    <Submit className="btn btn-primary waves-effect waves-light">Save Settings</Submit>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Form>

            </Fragment>
        )
    }

}

export default withRouter(SettingsPWAView);