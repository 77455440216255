import React, {Fragment} from "react";
import {WalletService} from "../../services/WalletService";
import {getContainer} from "../../ioc/IOCSetup";
import {Currency, Payment} from "../../http/protocol";
import {ListView} from "../ListView";
import {LoadingViewState, RouterView} from "../RouterView";
import {RouteComponentProps} from "react-router";
import {formatDate} from "../../util/date";
import {Link} from "react-router-dom";


interface Props {

}

interface State extends LoadingViewState{
    supportedCurrencies: Currency[];
}

export default class SupportedCurrenciesListView extends RouterView<RouteComponentProps,State>{
    walletService: WalletService;
    constructor(props:RouteComponentProps) {
        super(props);

        this.walletService = getContainer().getWalletService();

        this.state = {supportedCurrencies: []};
    }

    loadContent(): void {
        this.walletService.getSupportedCurrencies().then((currencies: Currency[]) => {
            this.setState({supportedCurrencies: currencies})
        })
    }
    getTitle() {
        return "Supported Currencies"
    }

    getCurrenciesTable() {
        let list = this.state.supportedCurrencies;
        let rows:any;


        if(list) {
            rows = list.map(p => {
                return (
                    <tr key={p.code}>
                        <td>{p.code}</td>
                        <td>{p.name}</td>
                        <td>{p.fractionalDigits}</td>
                        <td>{p.cryptoCurrency ? "Yes" : "No"}</td>
                        <td>{p.visible ? "Yes" : "No"}</td>
                        <td><Link to ={`/currencies/edit/${p.code}`}><button className="btn btn-warning float-right"><i className="fe-edit"/> Edit</button></Link></td>
                    </tr>
                );
            });
        }

        return (
            <table className="table m-b-xs table-striped">
                <thead>
                <tr>
                    <th>Code</th>
                    <th>Name</th>
                    <th>Fractional Digits</th>
                    <th>cryptoCurrency</th>
                    <th>Visible</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </table>
        );
    }

    renderContent() {
        if(this.state.supportedCurrencies.length == 0) {
            return <span></span>
        } else {
            return(
                <Fragment>
                <div className="col-lg-12">
                    <div className={"card"}>
                        <div className="card-header">
                            <h4>Currencies</h4>
                        </div>
                        <div className="card-body">
                            <div className="ibox-content">
                                {this.getCurrenciesTable()}
                             </div>
                        </div>
                    </div>
                </div>
                </Fragment>
            )
        }
    }
}