import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UserPerformanceStatsContainer, UserPerformanceStatsRow } from "../../http/protocol";
import { t } from "../../i18n";
import { getContainer } from "../../ioc/IOCSetup";
import { ApplicationState } from "../../user/UserStore";
import { formatDate } from "../../util/date";
import { Collapsible } from "../Collapsible";



export const UserPerfStats = (props: {userId: number}) => {
    const singleCurrency = getContainer().getAdminConfig().userStatsSingleCurrency
    const userId = props.userId
    // const userId = 208
    // const userId = 7407
    const [loading, setLoading] = useState<boolean>(true)
    const [errorMessage, setErrorMessage] = useState<string|undefined>(undefined)
    const [detailsInNativeCurrency, setDetailsInNativeCurrency] = useState<boolean>(false)
    const [perfStats, setPerfStats] = useState<UserPerformanceStatsContainer|undefined>(undefined)
    const currencies = useSelector((state: ApplicationState) => state.wallet.currencies) ?? []
    
    const getValue = (row: UserPerformanceStatsRow, normalized: boolean) => {
        return (normalized ? row.totalEur : row.total) ?? 0;
    }

    useEffect(() => {
        let response: UserPerformanceStatsContainer

        (async () => { 
            response = await getContainer().getDwUserService().getUserPerformanceStats(userId)
            setPerfStats(response)
        })()
        .catch(e => setErrorMessage("Error loading data!"))
        .finally(() => {
            if ((response?.stats?.length ?? 0) == 0) {
                setErrorMessage("No data")
            }

            return setLoading(false);
        })
    }, [userId])

    /** Calculate GGR by the given currency. Use normalized value (EUR) if normalized is true. */
    function calculateGgr(rows: UserPerformanceStatsRow[], currency?: String, normalized = true) {
        const all = !currency
        // const GGR_WAGER_TYPES = ['STANDARD_WIN', 'BET_WIN', 'PLACE_BET', 'SETTLEMENT', 'REFUND', 'STANDARD_BET', 'BONUS_WIN']

        const ggr = rows
            .filter(r => r.eventType == 'REVENUE'  
                &&  r.wagerType != null
                &&  (all  ||  r.currency == currency))
            .map(r => getValue(r, normalized))
            .reduce(
                (acc, cur) => acc + cur,
                0
            )

        return ggr
    }

    /** Calculate bonus payout sum by the given currency or use the normalized (EUR) value if no currency given. */
    function calculateBonusPayouts(rows: UserPerformanceStatsRow[], currency?: String, normalized = true) {
        const all = !currency

        const bonusCost = rows
            .filter(r => 
                (r.eventType == 'BONUS'  ||  r.eventType == 'BONUS_CANCEL')
                &&  (all  ||  r.currency == currency))
            .map(r => {
                    const amount = getValue(r, normalized)
                    return (r.eventType == 'BONUS' ? -amount : amount);
                })
            .reduce(
                (acc, cur) => acc + cur,
                0
            )

        return -bonusCost
    }

    function calculateDepositSum(rows: UserPerformanceStatsRow[], currency?: String, normalized = true) {
        const all = !currency

        const depsRow = rows.find(r => r.eventType == 'DEPOSIT');

        const deps = rows
            .filter(r => r.eventType == 'DEPOSIT'  &&  (all  ||  r.currency == currency))
            .map(r => ({sum: getValue(r, normalized), count: r.count ?? 0}))
            .reduce(
                (acc, cur) => {
                    return {sum: acc.sum + cur.sum, count: acc.count + cur.count }
                },
                {sum: 0, count: 0}
            )

        return deps
    }

    function getUsedCurrencies(rows: UserPerformanceStatsRow[]) {
        return Array.from(new Set(rows.map(r => r.currency))).sort()
    }

    function getCurrencyDigits(code: string | undefined): number {
        return currencies?.find(c => c.code == code)?.fractionalDigits ?? 2
    }

    function renderDetailedRows(rows: UserPerformanceStatsRow[], nativeCurrencyDetails: boolean) {
        const curs = getUsedCurrencies(rows)
        const normalized = !nativeCurrencyDetails

        const curSymbol = normalized ? "€" : ""

        return curs.map(cur => {
            const ggr = calculateGgr(rows, cur, normalized)
            const bonusPayouts = calculateBonusPayouts(rows, cur, normalized)
            const ngr = ggr - bonusPayouts
            const deps = calculateDepositSum(rows, cur, normalized)
            const digits = normalized ? 2 : getCurrencyDigits(cur)

            return (
                <tr key={cur}>
                    <th>{cur}</th>
                    <td>{renderXgr(ggr, digits, curSymbol)}</td>
                    <td>{renderXgr(ngr, digits, curSymbol)}</td>
                    <td>{curSymbol} {deps.sum.toFixed(digits)} ({deps.count})</td>
                </tr>
            )
        })
    }



    const renderXgr = (amount: number, digits: number, prefix: string | undefined) => {
        const houseWin = amount >= 0
        
        return <span className={`${houseWin ? 'green' : 'red'}`}>
            {prefix} {amount.toFixed(digits)}
        </span>
    }



    function renderStats() {
        const rows = perfStats?.stats  ??  []
        const summaryDisplayCurrency = singleCurrency  ||  "€"
        const summaryNormalized = singleCurrency == undefined

        const ggr = calculateGgr(rows, singleCurrency, summaryNormalized)
        const bonusCost = calculateBonusPayouts(rows, singleCurrency, summaryNormalized)
        const ngr = ggr - bonusCost
        const deps = calculateDepositSum(rows, singleCurrency, summaryNormalized)
        const digits = getCurrencyDigits(summaryDisplayCurrency)

        return <>
            <table className="table table-striped">
                <tbody>
                    <tr>
                        <th>GGR</th><td>{renderXgr(ggr, digits, summaryDisplayCurrency)}</td>
                    </tr>
                    <tr>
                        <th>NGR</th><td>{renderXgr(ngr, digits, summaryDisplayCurrency)}</td>
                    </tr>
                    <tr>
                        <th>Deposits</th><td>{summaryDisplayCurrency} {deps.sum.toFixed(digits)} ({deps.count})</td>
                    </tr>
                </tbody>
            </table>
        
            {!singleCurrency  &&
                <Collapsible title={"Breakdown Per Currency"} collapsed={true}>
                    <p className="help">{detailsInNativeCurrency ? "All values shown in source currency" : "All values converted to EUR"}</p>

                    <button className="btn btn-primary btn-xs" onClick={() => setDetailsInNativeCurrency(!detailsInNativeCurrency)}>
                        {detailsInNativeCurrency ? "Show values converted to EUR" : "Show values in source currency"}
                    </button>

                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Currency</th>
                                <th>GGR</th>
                                <th>NGR</th>
                                <th>Deposits</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderDetailedRows(rows, detailsInNativeCurrency ?? false)}
                        </tbody>
                    </table>
                    
                </Collapsible>
            }

        </>
    }


    const ts = formatDate(perfStats?.ts)

    return (
        <div className="col-lg-12">
            <div className={"card"}>
                <div className="card-header">
                    <h4>{t(`title.performanceOverview`)}</h4>
                </div>
                <div className="card-body">
                    <div className="ibox-content">
                            {loading  &&  <>Loading...</>}

                            {!loading}
                            <>
                                {errorMessage  &&
                                    <p>
                                        {errorMessage}
                                    </p>
                                }
                                {!errorMessage  && 
                                    <>
                                        <p className="help small">Generated: {ts} (cached for ~10 minutes)</p>
                                        {renderStats()}
                                    </>
                                }
                            </>
                    </div>
                </div>
            </div>
        </div>
    )
}