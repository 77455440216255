import {HttpService} from "../http/HttpService";
import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {
    BonusConfig,
    BonusConfigListResponse,
    CasinoGame,
    ListCasinoGameResponse, MonetaryConfig, MonetaryConfigListResponse,
    TurnoverContributions,
    TurnoverContributionsListResponse
} from "../http/protocol";


export class BonusConfigService  extends AbstractAdminEntityService<BonusConfig, BonusConfigListResponse>  {

    constructor(http : HttpService) {
        super("/bonus/config", http)
    }

}