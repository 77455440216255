import * as React from "react";

export class LoadingSpinner extends React.Component<{},{}>{
    render() {
        return (
            <div className="sk-spinner sk-spinner-wandering-cubes">
                <div className="sk-cube1"></div>
                <div className="sk-cube2"></div>
            </div>
        );
    }
}