import * as React from "react";

import {getContainer} from "../../ioc/IOCSetup";
import {Chart, ChartType, ListChartsRequest, ListChartsResponse} from "../../http/protocol";
import {AmplitudeLineChart} from "./AmplitudeLineChart";
import {AmplitudeBarChart} from "./AmplitudeBarChart";

interface Props {
    byTag: string;
}
export class ChartList extends React.Component<Props, { charts? : Chart[]}>{
    constructor(props : Props) {
        super(props);
        this.state = { charts : []}
    }
    componentDidMount(): void {
        let listRequest : ListChartsRequest = { tag : this.props.byTag, limit : 10, offset: 0, orderBy : "id"};
        getContainer().getChartService().list(listRequest).then( (response : ListChartsResponse) => {
            this.setState({ charts : response.items})
        })
    }
    getChart(c : Chart) {
        if(c.type == ChartType.LINE) {
           return <AmplitudeLineChart chart={c} />
        } else {
            return <AmplitudeBarChart chart={c} />
        }
    }
    render() {
        if(!this.state.charts) {
            return <span></span>
        }
        console.log("CHARTS = ", this.state.charts);
        return (
           <React.Fragment>
               <div className={"row"}>
                    <div className={"col-lg-12"}>
                        {this.state.charts.map(c => <div className={"card-box"} key={c.id}>{this.getChart(c)}</div>)}
                    </div>
               </div>
           </React.Fragment>
        )
    }
}