import React, {Fragment} from "react";
import {Link} from "react-router-dom";

export class CMSGuide extends React.Component<{},{}> {

    constructor(props: {}) {
        super(props);

    }
    componentDidMount(): void {

    }

    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <div className="page-title-right"></div>
                            <h4 className={"page-title"}>Content Guide</h4>
                        </div>
                    </div>
                </div>
                <GuideIndex/>

                <GuideCardBox title={"Translations vs Content"}>
                    <p>
                        There are two types of text/content that are used on the Cubeia Nano Site; Translations and Content.<br/>
                        <br/>
                        Translations are shorter texts that are used throughout the site which are required for each language/locale you want to support.
                        Translations reside in the Nano database and are managed under <Link to={"/translation"}>{"Translations -> List Translations"}</Link>.<br/>
                        <br/>
                        Content is usually longer texts that can include HTML elements etc. The Cubeia Nano site
                        uses <a href={"https://contentful.com"} target={"_blank"}>Contentful</a> CMS to manage content. This requires you to login in to the Contentful site to create/edit content as well as managing images and other media.
                    </p>
                </GuideCardBox>

                <GuideCardBox title={"CMS - Contentful"}>
                    <p>
                        Cubeia Nano is using <a href={"https://contentful.com"} target={"_blank"}>Contentful</a> as Content Management System.
                        Contentful is a 3rd party CaaS (Content as a Service) provider which is integrated to the Cubeia Nano Site.
                        <GuideAlert>
                            Contentful can be configured under <Link className={"alert-link"} to={"/admin/settings/general"}>{"Settings > General"}</Link> this requires you
                            to have a Contentful account for your site.
                        </GuideAlert>
                    </p>
                </GuideCardBox>



            </Fragment>
        );
    }
}
export const GuideCardBox = (props : { title : string, children : any}) => (
    <div className={"card-box guide-section"}>
        <h4 id={props.title} className={"header-title"}>{props.title}</h4>
        {props.children}
    </div>
)
export const GuideAlert = (props : { children : any }) => (
    <div className={"alert alert-warning guide"}>
        <i className="mdi mdi-alert-circle-outline mr-2"></i>
        {props.children}
    </div>
)

export class GuideIndex extends React.Component<{}, { sections? : string[]}>{
    constructor(props: {}) {
        super(props);
        this.state = { sections : []};
    }
    componentDidMount(): void {
        let sections : string[] = [];
        let elements = document.querySelectorAll(".guide-section .header-title");
        elements.forEach(e=> {
            sections.push(e.innerHTML);
        });
        this.setState({ sections : sections});
    }

    render() {
        return (
            <div className={"card-box"}>
                <h4 className={"header-title"}>Index</h4>
                <ul className="section-nav">
                    { this.state.sections && this.state.sections.map( s => (
                        <li key={s} className="toc-entry toc-h2"><a href={"#"+s}>{s}</a></li>
                    ))}
                </ul>
            </div>
        )
    }
}