import { HttpService } from "../http/HttpService";
import { AbstractAdminEntityService } from "./AbstractAdminEntityService";



export class CurrencyRateService extends AbstractAdminEntityService<any, any> {

    constructor(http : HttpService) {
        super("/currencies", http);
    }

    getCryptoToEurExchangeRates(): Promise<{[key: string]: string}> {
        return this.http.get(this.entityPath + "/eurovalue");
    }
    
    getCryptoAndFiatToEuroExchangeRates(currency: string): Promise<number> {
        return this.http.get(this.entityPath + `/eurovalue/${currency}`);
    }


}