import React, {Fragment} from "react";
import {LoadingViewState, RouterView} from "../RouterView";
import {Form, FormListener} from "../form/Form";
import {AffiliatesSettings, Settings} from "../../http/protocol";
import {RouteComponentProps, withRouter} from "react-router";
import {AffiliatesService} from "../../services/AffiliatesService";
import {getContainer} from "../../ioc/IOCSetup";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Submit} from "../form/Submit";


interface Props extends RouteComponentProps {

}

interface State extends LoadingViewState{
    settings?: AffiliatesSettings;
    validationMap? : any;

}

export class AffiliatesSettingsView extends RouterView<Props,State> implements FormListener<{}> {
    affiliatesService: AffiliatesService;

    constructor(props:Props) {
        super(props);

        this.affiliatesService = getContainer().getAffiliatesService();

        this.state = {validationMap : {}, loading : true};
    }

    loadContent(): Promise<any> | void {
        this.affiliatesService.getSettings().then((res:AffiliatesSettings) => {
            this.setState({settings:res})
        })
    }

    formDidUpdate(formModel:AffiliatesSettings, validationMap : any) {
        this.setState({ settings : formModel, validationMap : validationMap});
    }

    onSubmit():Promise<any> {
        if(!this.state.settings) {
            return Promise.reject("Unable to save settings");
        }


        return this.affiliatesService.saveSettings(this.state.settings).catch(err=>{
            throw "Unable to create/save settings";
        });
    }

    getTitle(): string {
        return "Affiliates Settings";
    }

    renderContent() {
        if(!this.state.settings) {
            return <span></span>
        }
        return(
            <Fragment>
                <Form className="form-horizontal" formListener={this} modelObject={this.state.settings} validationMap={this.state.validationMap}>
                    <div className={"card"}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <FormSubmitError />
                                    <FormSubmitSuccess text="Settings was updated successfully"/>

                                    <FormGroup className="form-group mb-3" model="linkURL">
                                        <label>Link URL</label>
                                        <FormInput className="form-control" model="linkURL" type="text"/>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>

                                    <FormGroup className="form-group mb-3" model="logoUrl">
                                        <label>Logo URL</label>
                                        <FormInput className="form-control" model="logoUrl" type="text"/>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>

                                    <FormGroup className="form-group mb-3" model="supportEmail">
                                        <label>Support Email</label>
                                        <FormInput className="form-control" model="supportEmail" type="text"/>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>

                                    <FormGroup className="form-group mb-3" model="enabledCurrencies">
                                        <label>Enabled Currencies</label>
                                        <FormInput className="form-control" model="enabledCurrencies" type="text"/>
                                        <span className="help-block">Currency codes separated with a single space</span>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>

                                    <FormGroup className="form-group mb-3" model="selectablePayoutCurrencies">
                                        <label>Selectable Affiliate Payout Currencies</label>
                                        <FormInput className="form-control" model="selectablePayoutCurrencies" type="text" required={true}/>
                                        <span className="help-block">Currency codes separated with a single space. List of currencies that affiliates can choose as default payout currency. This should be subset of enabled currencies</span>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>

                                    <FormGroup className="form-group mb-3" model="selectablePayoutNetworks">
                                        <label>Selectable Affiliate Payout Networks</label>
                                        <FormInput className="form-control" model="selectablePayoutNetworks" type="text"/>
                                        <span className="help-block">Network codes separated with a single space (e.g. USDT-TRON USDT-ETH). List of networks that affiliates can choose as default payout network. This should be subset of direct payout config in the advanced settings</span>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>

                                    <FormGroup className="form-group mb-3" model="defaultPayoutCurrency">
                                        <label>Default Payout Currency</label>
                                        <FormInput className="form-control" model="defaultPayoutCurrency" type="text"  required={true}/>
                                        <span className="help-block">Default currency to pay out affiliates commissions. This currency will be used, in case an affiliate has not chosen a currency for payout. This currency should be listed in selectable payout currencies</span>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>

                                    <FormGroup className="form-group mb-3" model="networkCostPercentage">
                                        <label>Network Cost - Fractions</label>
                                        <FormInput className="form-control" model="networkCostPercentage" type="number"/>
                                        <span className="help-block">Example: 0.15 will deduct 15% as network fee</span>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>

                                    <FormGroup className="form-group mb-3" model="defaultAffiliateRevenueSharePercentage">
                                        <label>Default Affiliate Revenue Share - Fractions</label>
                                        <FormInput className="form-control" model="defaultAffiliateRevenueSharePercentage" type="number"/>
                                        <span className="help-block">Example: 0.15 will set 15% as default affiliate share</span>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>
                                    <FormGroup className="form-group mb-3" model="defaultCpaDepositRequirement">
                                        <label>Default Deposit Requirement For CPA Payouts (€)</label>
                                        <FormInput className="form-control" model="defaultCpaDepositRequirement" type="float"/>
                                        <span className="help-block">Minimum deposit in Euro to pay out CPA. Can be overridden for each Affiliate</span>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>
                                    <FormGroup className="form-group mb-3" model="defaultCpaTurnoverRequirement">
                                        <label>Default Turnover Requirement For CPA Payouts (€)</label>
                                        <FormInput className="form-control" model="defaultCpaTurnoverRequirement" type="float"/>
                                        <span className="help-block">Minimum turnover in Euro to pay out CPA. Can be overridden for each Affiliate</span>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>
                                    <FormGroup className="form-group mb-3" model="defaultCpaPayoutAmount">
                                        <label>Default CPA Payout Amount (€)</label>
                                        <FormInput className="form-control" model="defaultCpaPayoutAmount" type="float"/>
                                        <span className="help-block">Default CPA amount in Euro to pay out. Can be overridden for each Affiliate</span>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>
                                    <FormGroup className="form-group mb-3" model="defaultCpaCap">
                                        <label>CPA Cap</label>
                                        <FormInput className="form-control" model="defaultCpaCap" type="number"/>
                                        <span className="help-block">Maximum number of CPAs to be paid each month to the affiliate. Affiliates wont receive CPA payment for additional triggers exceeding the cap. Can be overridden for each Affiliate</span>
                                    </FormGroup>
                                    <FormGroup className="form-group mb-3" model="defaultDepositLimit">
                                        <label>Default Deposit Amount Limit (€)</label>
                                        <FormInput className="form-control" model="defaultDepositLimit" type="float"/>
                                        <span className="help-block">Default deposit amount in Euro to limit for the Affialiate's Agents. Can be overridden for each Affiliate</span>
                                    </FormGroup>
                                    <FormGroup className="form-group mb-3" model="defaultWithdrawLimit">
                                        <label>Default Withdraw Amount Limit (€)</label>
                                        <FormInput className="form-control" model="defaultWithdrawLimit" type="float"/>
                                        <span className="help-block">Default withdraw amount in Euro to limit for the Affialiate's Agents. Can be overridden for each Affiliate</span>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>
                                    <FormGroup className="custom-control custom-checkbox" model="enabled">
                                        <FormInput className="custom-control-input"  id={"enabled"} model="enabled" type="checkbox"/>
                                        <label htmlFor={"enabled"} className={"custom-control-label"}>Affiliates Enabled</label>
                                        <span className={"help-block"}></span>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>

                                    <FormGroup className="custom-control custom-checkbox" model="approvalRequired">
                                        <FormInput className="custom-control-input"  id={"approvalRequired"} model="approvalRequired" type="checkbox"/>
                                        <label htmlFor={"approvalRequired"} className={"custom-control-label"}>Affiliates require to be approved by admin</label>
                                        <span className={"help-block"}></span>
                                    </FormGroup>
                                    <FormGroup className="custom-control custom-checkbox" model="negativeCarryOverEnabled">
                                        <FormInput className="custom-control-input"  id={"negativeCarryOverEnabled"} model="negativeCarryOverEnabled" type="checkbox"/>
                                        <label htmlFor={"negativeCarryOverEnabled"} className={"custom-control-label"}>Deduct negative carry over</label><br/>
                                        <span className={"help-block"}>If checked, negative carry over from previous months will be deducted from affiliate's profit before payout</span>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"card"}>
                        <div className={"card-body"}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="ibox float-e-margins">
                                        <div className="ibox-content">
                                            <div className="ibox-title">
                                                <h5>Actions</h5>
                                            </div>
                                            <div className="form-group">
                                                <div className="col-sm-10 col-sm-offset-2">
                                                    <Submit className="btn btn-primary waves-effect waves-light">Save Settings</Submit>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Form>


            </Fragment>
        )
    }

}

export default withRouter(AffiliatesSettingsView);


