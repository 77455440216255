import {HttpService} from "../http/HttpService";
import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {
    CashbackCasinoTagExclusion,
    CashbackConfig,
    CashbackConfigListResponse,
    NextUpcomingCashback,
    UpcomingCashbacksInfo, UpcomingCashbacksInfoListResponse
} from "../http/protocol";


export class CashbackConfigService extends AbstractAdminEntityService<CashbackConfig, CashbackConfigListResponse> {

    constructor(http: HttpService) {
        super("/cashback/config", http)
    }

    getUpcomingCashbacksForUser(userId: number): Promise<UpcomingCashbacksInfoListResponse> {
        return this.http.get(`${this.entityPath}/upcoming-cashbacks/` + userId);
    }

    getNextUpcomingCashbackForUser(userId: number): Promise<NextUpcomingCashback[]> {
        return this.http.get(`${this.entityPath}/next-upcoming-cashbacks/` + userId);
    }

    triggerUpcomingCashbacksCalculationForUser(userId: number): Promise<void> {
        return this.http.post(`${this.entityPath}/trigger-upcoming-cashbacks-calculation/${userId}`,null)
    }

    getCasinoTagExclusions(): Promise<CashbackCasinoTagExclusion[]> {
        return this.http.get(`${this.entityPath}/casino/exclusion/tag/all`)
    }

    createOrUpdateCasinoTagExclusion(excl: CashbackCasinoTagExclusion): Promise<CashbackCasinoTagExclusion> {
        return this.http.post(`${this.entityPath}/casino/exclusion/tag`, excl);
    }

    removeCasinoTagExclusion(tagNameId: string): Promise<any> {
        return this.http.delete(`${this.entityPath}/casino/exclusion/tag/${tagNameId}`);
    }

}