import React, {Fragment} from "react";
import {
    KycStatus,
    MultiFactorAuthStatus,
    PaymentType,
    PermissionCategory,
    RegistrationRiskLevel,
    User
} from "../../http/protocol";
import {getContainer} from "../../ioc/IOCSetup";
import {RouteComponentProps, withRouter} from "react-router";
import {LoadingViewState, RouterView} from "../RouterView";
import {UserService} from "../../services/UserService";
import {AttributePanel} from "../AttributePanel";
import {formatDate} from "../../util/date";
import {Link} from "react-router-dom";
import {AdminNotes} from "../notes/AdminNotes";
import {UserPaymentHistory} from "./UserPaymentHistory";
import {ProviderService} from "../../services/ProviderService";
import {UserCryptoAddressHistory} from "./UserCryptoAddressHistory";
import UserLabelsComponent from "./UserLabelsComponent";
import {UserBonusComponent} from "./UserBonusComponent";
import UserBonusHistory from "./UserBonusHistory";
import {PlayerAudit} from "../audits/PlayerAudits";
import {UserRewardComponent} from "./UserRewardComponent";
import {UserRewardListComponent} from "./UserRewardListComponent";
import {UserBonusService} from "../../services/UserBonusService";
import {AbuseService} from "../../services/AbuseService";
import {t} from "../../i18n";
import UserLevelComponent from "./UserLevelComponent";
import {PaymentService} from "../../services/PaymentService";
import {PermissionRequired} from "../security/PermissionRequired";
import {UserPerfStats} from "./UserPerfStats";
import {UserAccountsAndTransactions} from "./UserAccountsAndTransactions";
import ReportAbuseDialog from "./ReportAbuseDialog";
import AbuseReportModal from './AbuseReportModal';
import supermanIcon from '../../assets/images/icons8-super-hero-male-96.png'

interface State extends LoadingViewState {
    user? : User;
    totalDepEuro: number;
    totalWithdrawalEuro: number;
    delta: number;
    summaries: UserPaymentSummary[];
    showReportAbuseDialog: boolean;
    abuseReportFound: boolean;
    abuseReportCount: number;
    showAbuseReportModal: boolean
    showSuccessModal: boolean,
    showErrorModal: boolean
    abuseReports: any[];
    showNanoCentralIntelligence: boolean;
}

interface Props extends RouteComponentProps {

}


interface UserPaymentSummary {
    currency?:string;
    deposit?:number;
    withdrawal?:number;
    depositEur?:number;
    withdrawalEur?:number;
}

export class UserView extends RouterView<Props,State> {

    service : UserService;

    providerService: ProviderService;
    bonusService: UserBonusService;
    paymentService: PaymentService;
    abuseService: AbuseService;

    constructor(props : Props){
        super(props);
        this.service = getContainer().getUserService();
        this.providerService = getContainer().getProviderService();
        this.bonusService = getContainer().getUserBonusService();
        this.paymentService = getContainer().getPaymentService();
        this.abuseService = getContainer().getAbuseService();
        this.state = {
            user : {},
            loading : true,
            totalDepEuro:0,
            totalWithdrawalEuro:0,
            delta: 0,
            summaries:[],
            showReportAbuseDialog: false,
            abuseReportFound: false,
            abuseReportCount: 0,
            showAbuseReportModal: false,
            showSuccessModal: false,
            showErrorModal: false,
            abuseReports: [],
            showNanoCentralIntelligence: true,
        };
    }

    async loadContent() : Promise<Promise<any> | undefined> {
        let id = (this.props.match.params as any).id;

        this.checkAbuseReportStatus(id);
        const user = await this.service.getEntity(id);
        await this.paymentService.paymentSummary(id).then(summary => {
            let summaryMap=new Map<String,UserPaymentSummary>();
            let totalDepEur=0;
            let totalWithdrawalEur=0;
            summary.forEach(value => {


                if(!summaryMap.has(value.currency!)){
                    summaryMap.set(value.currency!,{currency:value.currency,deposit:0,withdrawal:0,depositEur:0,withdrawalEur:0})
                }

                if(value.type==PaymentType.DEPOSIT){
                    summaryMap.get(value.currency!)!.deposit=value.amount;
                    summaryMap.get(value.currency!)!.depositEur=value.euroAmount;
                    totalDepEur += value!.euroAmount!;
                }else if(value.type==PaymentType.WITHDRAWAL){
                    summaryMap.get(value.currency!)!.withdrawal=value.amount;
                    summaryMap.get(value.currency!)!.withdrawalEur=value.euroAmount;
                    totalWithdrawalEur += value!.euroAmount!;
                }

            })
            this.setState({user: user,  totalWithdrawalEuro: totalWithdrawalEur,totalDepEuro:totalDepEur, delta: (totalDepEur - totalWithdrawalEur), summaries:Array.from(summaryMap.values())})
        })

    }
    componentDidUpdate(prevProps : RouteComponentProps, prevState : any) {
        if((this.props.match.params as any).id != (prevProps.match.params as any).id) {
            this.refreshContent();
        } else {
            super.componentDidUpdate(prevProps, prevState);
        }
    }

    getTitle() : string {
        return t(`usersManagement.userOverView.pageTitle`);
    }

    renderMultiFactorAuthentication() {
        if (this.state.user && this.state.user.mfaStatus == MultiFactorAuthStatus.ENABLED) {
            return this.state.user.mfaProvider
        } else {
            return (<span className="text-muted">{t(`status.notSet`)}</span>)
        }
    }

    async updateKyc(s: KycStatus) {
        const userId: any = this.state.user!.id
        await this.service.updateKycStatus(userId, s)
        const user = await this.service.getEntity(userId)
        this.setState({user: user})
    }
    async approveRegistration() {
        const userId: any = this.state.user!.id
        await this.service.manuallyApprove(userId)
        const user = await this.service.getEntity(userId)
        this.setState({user: user})
    }

    async checkAbuseReportStatus(userId: string) {
        try {
            console.log("DO LOOKUP")
            const response = await this.abuseService.lookupReports(userId);
            console.log(response)
            const reportCount = response.reports ? response.reports.length : 0;
            this.setState({ abuseReportFound: reportCount > 0, abuseReportCount: reportCount, abuseReports: response.reports });
        } catch (error) {
            this.setState({ showNanoCentralIntelligence: false });
        }
    }

    openAbuseReportModal = () => {
        this.setState({ showAbuseReportModal: true });
    };

    closeAbuseReportModal = () => {
        this.setState({ showAbuseReportModal: false });
    };

    handleReportAbuse = async (report: any) => {
        const { selectedAbuseTypes, comment, blockPlayer } = report;
        const userId = this.state.user?.id;

        const abuseReportRequest = {
            userId,
            comment,
            abuseTypes: selectedAbuseTypes,
            blockUser: blockPlayer
        };

        try {
            const response = await this.abuseService.reportAbuse(abuseReportRequest);
            console.log('Response:', response);
        } catch (error) {
            this.setState({showErrorModal : true})
            console.error('Error submitting report:', error);
        }
        this.setState({ showSuccessModal: true });
        await this.updatePage()
    };
    updatePage = async () => {
        const userId = this.state.user?.id;
        if (userId) {
            await this.checkAbuseReportStatus(userId.toString());
        }
    };

    closeSuccessModal = () => {
        this.setState({ showSuccessModal: false });
    };
    closeErrorModal = () => {
        this.setState({ showErrorModal: false });
    };


    renderSuccessModal() {
        return (
            <div className={`modal ${this.state.showSuccessModal ? 'show' : ''}`} style={{ display: this.state.showSuccessModal ? 'block' : 'none' }}>
                <div className="modal-dialog">
                    <div className="modal-content" style={{ border: '2px solid #007bff', borderRadius: '5px' }}>
                        <div className="modal-header">
                            <h5 className="modal-title">Success</h5>
                            <button type="button" className="close" onClick={this.closeSuccessModal}>
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={supermanIcon} alt="Superman Icon" style={{ width: '50px', marginRight: '10px' }} />
                            <span>Report submitted successfully. Thank you for helping combat fraud and making the world a better place!</span>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={this.closeSuccessModal}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderErrorModal() {
        return (
            <div className={`modal ${this.state.showErrorModal ? 'show' : ''}`} style={{ display: this.state.showErrorModal ? 'block' : 'none' }}>
                <div className="modal-dialog">
                    <div className="modal-content" style={{ border: '2px solid #007bff', borderRadius: '5px' }}>
                        <div className="modal-header">
                            <h5 className="modal-title">Success</h5>
                            <button type="button" className="close" onClick={this.closeErrorModal}>
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ display: 'flex', alignItems: 'center' }}>
                            <span>Report failed to be submitted! Try again</span>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={this.closeErrorModal}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderContent() {
        const userLabels:string[] = this.state.user && this.state.user.labels || [];
        let id = (this.props.match.params as any).id;
        console.log("user: ", this.state.user);
        if(!this.state.user) {
            return <span></span>
        }
        const kycStatus = this.state.user.kycStatus ?? KycStatus.NOT_STARTED
        const hasOwnReport = this.state.abuseReports.some(report => report.yourOwnReport);
        return (

            <Fragment>
                <div className="row">
                    <div className={"col-lg-6"}>
                        <div className={"row"}>
                            <div className="col-lg-12">
                                <div className={"card-box"}>
                                    <PermissionRequired permissions={[PermissionCategory.USERS]} accessLevel="w" showError={false}>
                                        <Link to={"/user/edit/" + this.state.user.id} className={"btn btn-warning"} style={{float : "right"}}><i className={"fe-edit"}></i> {t(`button.editUser`)}</Link>
                                    </PermissionRequired>

                                    <h4>{this.state.user.email}</h4>
                                    <h5>{t(`label.userId`)}: {this.state.user.id} ({this.state.user.uid})</h5>
                                    <UserLabelsComponent userLabels={userLabels} userId={id}/>
                                    <table className={"table table-striped"}>
                                        <tbody>

                                        <tr>
                                            <td>{t(`dataTable.header.username`)}</td>
                                            <td>{this.state.user.username ? this.state.user.username : "N/A"}</td>
                                        </tr>
                                        {this.state.user.attributes && this.state.user.attributes['pokerUserId'] && (
                                            <tr>
                                                <td>{t(`dataTable.header.pokerUserId`)}</td>
                                                <td>
                                                    {this.state.user.attributes['pokerUserId']}
                                                    {this.providerService.getCubeiaDashboardUrl()&& (
                                                        <a href={`${this.providerService.getCubeiaDashboardUrl()}/user/${this.state.user.attributes['pokerUserId']}`} target="_blank" className="btn btn-sm btn-primary float-right">{t(`button.viewInDashboard`)}</a>
                                                    )}
                                                </td>
                                            </tr>
                                        )}
                                        <tr>
                                            <td>{t(`dataTable.header.blocked`)}</td>
                                            <td>{this.state.user.blocked ? <span className={"red"}>{t(`status.yes`)}</span>: <span className={"green"}>{t(`status.no`)}</span>}</td>
                                        </tr>
                                        <tr>
                                            <td>{t(`dataTable.header.excludedFromAffiliate`)}</td>
                                            <td>{this.state.user.excludeFromAffiliate ? <span className={"red"}>{t(`status.yes`)}</span>: <span className={"green"}>{t(`status.no`)}</span>}</td>
                                        </tr>
                                        <tr>
                                            <td>{t(`dataTable.header.kycVerified`)}</td>
                                            <td>
                                                {this.state.user.kycVerified ? <span className={"green"}>{t(`status.yes`)}</span>: <span className={"red"}>{t(`status.no`)}</span>}
                                                &nbsp;<span className="badge badge-blue">{kycStatus != null ? kycStatus : t(`badge.notStart`)}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>KYC Actions</td>
                                            <td colSpan={2}>
                                                <PermissionRequired permissions={[PermissionCategory.USERS, PermissionCategory.USER_KYC_STATUS]} accessLevel="w" showError={false}>
                                                    <span>
                                                        { kycStatus != KycStatus.NOT_STARTED  && <><button onClick={() => this.updateKyc(KycStatus.NOT_STARTED)} className="btn btn-xs btn-primary"><i className="fe-edit"></i> Clear</button>&nbsp;</> }
                                                        { kycStatus != KycStatus.VERIFIED  &&  <><button onClick={() => this.updateKyc(KycStatus.VERIFIED)} className="btn btn-xs btn-success"><i className="fe-edit"></i> Verify</button>&nbsp;</> }
                                                        { kycStatus != KycStatus.REJECTED  &&  <><button onClick={() => this.updateKyc(KycStatus.REJECTED)} className="btn btn-xs btn-danger"><i className="fe-edit"></i> Reject</button>&nbsp;</> }
                                                    </span>
                                                </PermissionRequired>
                                                {this.state.user.attributes?.["Metamap_KYC_link"] && (
                                                    <a className={"btn btn-primary btn-xs"} href={this.state.user.attributes["Metamap_KYC_link"]} target={"_blank"}>
                                                        <i className="fe-external-link"></i> View
                                                    </a>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Registration Risk Level</td>
                                            <td>
                                                {this.state.user.registrationRiskLevel}
                                                {this.state.user.registrationRiskLevel == RegistrationRiskLevel.MANUAL_APPROVAL_REQUIRED &&
                                                    <><button onClick={() => this.approveRegistration()} className="btn btn-xs btn-success"><i className="fe-edit"></i> Approve</button></>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{t(`dataTable.header.emailVerified`)}</td>
                                            <td>{this.state.user.emailVerified ? <span className={"Yes"}>{t(`status.yes`)}</span>: <span className={"red"}>{t(`status.no`)}</span>}</td>
                                        </tr>
                                        <tr>
                                            <td>{t(`dataTable.header.phoneNumberVerified`)}</td>
                                            <td>{this.state.user.phoneNumberVerified ? <span className={"Yes"}>{t(`status.yes`)}</span>: <span className={"red"}>{t(`status.no`)}</span>}</td>
                                        </tr>
                                        <tr>
                                            <td>{t(`dataTable.header.communicationChannel`)}</td>
                                            <td>{this.state.user.communicationChannel ? <span className={"Yes"}>{this.state.user.communicationChannel}</span>: <span className={"red"}>{t(`status.notSet`)}</span>}</td>
                                        </tr>
                                        <tr>
                                            <td>{t(`dataTable.header.created`)}</td>
                                            <td>{formatDate(this.state.user.created)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t(`dataTable.header.lastLogin`)}</td>
                                            <td>{formatDate(this.state.user.lastLogin)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t(`dataTable.header.loginType`)}</td>
                                            <td>{this.state.user.loginType}</td>
                                        </tr>
                                        <tr>
                                            <td>{t(`dataTable.header.affiliateId`)}</td>
                                            <td>{this.state.user.affiliateId}</td>
                                        </tr>
                                        <tr>
                                            <td>{t(`dataTable.header.affiliateName`)}</td>
                                            <td>{this.state.user.affiliateName}</td>
                                        </tr>
                                        <tr>
                                            <td>{t(`dataTable.header.eMail`)}</td>
                                            <td>{this.state.user.email}</td>
                                        </tr>
                                        <tr>
                                            <td>{t(`dataTable.header.phoneNumber`)}</td>
                                            <td>{this.state.user.phoneNumber}</td>
                                        </tr>
                                        <tr>
                                            <td>{t(`dataTable.header.countryCode`)}</td>
                                            <td>{this.state.user.country}</td>
                                        </tr>
                                        <tr>
                                            <td>{t(`dataTable.header.twoFactorAuthentication`)}</td>
                                            <td>{this.renderMultiFactorAuthentication()}</td>
                                        </tr>
                                        <tr>
                                            <td>{t(`dataTable.header.verificationCode`)}</td>
                                            <td>{this.state.user.verificationCode}</td>
                                        </tr>
                                        <tr>
                                            <td>{t(`dataTable.header.verificationChannel`)}</td>
                                            <td>{this.state.user.verificationChannel}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} style={{padding: "10px 0"}}>
                                                <Link to={"/user/cashbacks/" + this.state.user.id}
                                                      className={"btn btn-warning"}>User Upcoming Cashbacks</Link>
                                                <Link to={"/user/next/cashback/" + this.state.user.id}
                                                      className={"btn btn-warning"}>Next Upcoming Cashback</Link>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            {this.state.user.id && (
                                <PermissionRequired componentName={"Balances & Transactions "} permissions={[PermissionCategory.WALLET]} displayBox={true} accessLevel="r" showError={true} requireAll={true}>
                                    <UserAccountsAndTransactions userId={this.state.user.id} />
                                </PermissionRequired>
                            )}
                            <div className="col-lg-12">
                                <UserBonusComponent userId={(this.props.match.params as any).id} />
                            </div>
                            <div className="col-lg-12">
                                <UserRewardComponent userId={(this.props.match.params as any).id} />
                            </div>

                            <div className="col-lg-12">
                                {this.state.user.attributes && (
                                    <AttributePanel attributes={this.state.user.attributes} header={t(`title.userAttributes`)}/>
                                )}
                            </div>

                            <div className="col-lg-12">
                                {this.state.user.userSettings && (
                                    <AttributePanel attributes={this.state.user.userSettings} header={t(`title.userSettings`)}/>
                                )}
                            </div>
                        </div>

                    </div>


                    <div className={"col-lg-6"}>

                        <div className={"row"}>
                            <div className={"col-lg-12"}>
                                <AdminNotes type={"USER"} typeId={""+this.state.user.id} title={t(`title.userNotes`)}/>
                            </div>
                        </div>

                        {this.state.showNanoCentralIntelligence && (
                            <div className={"row"}>
                                <div className={"col-lg-12"}>
                                    <div className={"card"}>
                                        <div className="card-header">
                                            <h4>Nano Central Intelligence</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="ibox-content">
                                                <div className="table-responsive">
                                                    <div className="abuse-report-section">
                                                        <div className="report-info">
                                                            {this.state.abuseReportFound ? (
                                                                <>
                                                                    <i className="fe-alert-triangle icon" style={{color: 'orange'}}></i>
                                                                    {`${this.state.abuseReportCount} Possible Matches Found`}
                                                                </>
                                                            ) : (
                                                                <div className="no-abuse-report">
                                                                    <i className="fe-thumbs-up icon"></i>
                                                                    No central abuse report found
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="report-status">
                                                            <div className="nano-central-intelligence-buttons">
                                                        {this.state.abuseReportFound && (
                                                            <button
                                                                className={"btn btn-primary open-report-button"}
                                                                onClick={this.openAbuseReportModal}
                                                            >
                                                                Open Report
                                                            </button>
                                                        )}
                                                            {this.state.abuseReports.some(report => report.yourOwnReport && report.playerUid === this.state.user?.uid) ? (
                                                                <span className="player-reported">Player Already Reported</span>
                                                            ) : (
                                                                <button
                                                                    className={"btn btn-danger report-abuse-button"}
                                                                    onClick={() => this.setState({showReportAbuseDialog: true})}
                                                                >
                                                                    <i className={"fe-alert-triangle"}></i> {t(`button.reportAbuse`)}
                                                                </button>
                                                            )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className={"row"}>
                            {this.state.user.id &&
                                <UserPerfStats userId={this.state.user.id}/>
                            }
                        </div>


                        <div className={"row"}>
                            <div className={"col-lg-12"}>
                                <div className={"card"}>
                                    <div className="card-header">
                                        <h4>Confirmed Deposits & Withdrawals In Last 24H</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="ibox-content">
                                            <div className="table-responsive">
                                                <table className={"table table-striped"}>
                                                    <thead>
                                                    <th>Currency</th>
                                                    <th>Deposit</th>
                                                    <th>Withdrawal</th>
                                                    <th>Deposit (€)</th>
                                                    <th>Withdrawal (€)</th>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.summaries?.map((s, index) => {
                                                        return (
                                                            <tr>
                                                                <td>{s.currency}</td>
                                                                <td>{s.deposit}</td>
                                                                <td>{s.withdrawal}</td>
                                                                <td>{s.depositEur?.toFixed(2)}</td>
                                                                <td>{s.withdrawalEur?.toFixed(2)}</td>
                                                            </tr>
                                                        );
                                                    })}

                                                    <tr>
                                                        <td>
                                                        Total Deposits: {this.state.totalDepEuro.toFixed(2)} (€)
                                                        </td>
                                                        <td colSpan={2}>
                                                            Total
                                                            Withdrawals: {this.state.totalWithdrawalEuro.toFixed(2)} (€)
                                                        </td>
                                                        <td colSpan={2}
                                                            className={this.state.delta >= 0 ? "table-success" : "table-danger"}>
                                                            Delta:{this.state.delta.toFixed(2)} (€)
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>

                        
                        <div className={"row"}>
                            <UserPaymentHistory params={{id:this.state.user && this.state.user.id ? this.state.user.id.toString() : ""}}/>
                        </div>
                        <div className={"row"}>
                            <UserBonusHistory userId={(this.props.match.params as any).id} />
                        </div>

                        <div className={"row"}>
                            <UserLevelComponent userId={(this.props.match.params as any).id} />
                        </div>

                        <div className={"row"}>
                            <div className="col-lg-12">
                                <UserRewardListComponent userId={(this.props.match.params as any).id} />
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <PlayerAudit userId={this.state.user.id as number} />
                            </div>
                        </div>
                        <div className={"row"}>
                            <UserCryptoAddressHistory params={{id:this.state.user && this.state.user.id ? this.state.user.id.toString() : ""}}/>
                        </div>
                    </div>
                </div>
                <ReportAbuseDialog
                    show={this.state.showReportAbuseDialog}
                    onHide={() => this.setState({ showReportAbuseDialog: false })}
                    onSubmit={this.handleReportAbuse}
                    updatePage={this.updatePage}
                />
                <AbuseReportModal
                    show={this.state.showAbuseReportModal}
                    onHide={this.closeAbuseReportModal}
                    reports={this.state.abuseReports}
                    userId={this.state.user.id!}
                    userUid={this.state.user.uid!}
                    abuseService={this.abuseService}
                    updatePage={this.updatePage}
                />
                {this.renderSuccessModal()}
                {this.renderErrorModal()}
            </Fragment>
        )
    }

}

export default withRouter(UserView);
