import * as React from "react";

import {RouterView, LoadingViewState} from "../RouterView";

import * as _ from "lodash";
import {RouteComponentProps} from "react-router";
import {EndpointPermissions, PermissionCategory, PermissionCategoryInfo, UserGroup} from "../../http/protocol";
import {getContainer} from "../../ioc/IOCSetup";
import {withRouter} from "react-router";
import {UserGroupService} from "../../services/UserGroupService";
import {DeleteButtonModal} from "../../util/DeleteButtonModal";
import {t} from "../../i18n";


export interface State extends LoadingViewState {
    userGroup? : UserGroup;
    categories? : PermissionCategoryInfo[];
    endpoints? : EndpointPermissions[];
    showDetails? : boolean;
}
interface Props extends RouteComponentProps {

}
const initialState : State = { loading : true, userGroup : { groupPermissions : {} }, categories : [], endpoints: [], showDetails: false};
export class UserGroupView  extends RouterView<Props ,State>{
    userGroupService : UserGroupService;
    constructor(props : Props) {
        super(props);
        this.state = initialState;
        this.userGroupService = getContainer().getUserGroupService();
    }
    loadContent() : Promise<any> | undefined{
        console.log("PARAMS = ", this.props.match.params)
        if((this.props.match.params as any).id != "new") {
            return Promise.all<any>([
                    this.userGroupService.getEntity((this.props.match.params as any).id),
                    this.userGroupService.listPermissionCategories(),
                    this.userGroupService.getEndpointPermissions()
                ]
            ).then(results => {
                this.setState({userGroup: (results[0] as UserGroup), categories: results[1] as PermissionCategoryInfo[], endpoints: results[2] as EndpointPermissions[]});
            });
        } else {
            return undefined;
        }
    }
    componentDidUpdate(prevProps : Props, prevState : any) {
        if((prevProps.match.params as any).id != (this.props.match.params as any).id){
            this.setState(initialState);
            this.refreshContent();
        }
    }

    isEnabled(category : string) {

        if(!this.state.userGroup || !this.state.userGroup.groupPermissions) {
            return false;
        }

        for(let cat  in this.state.userGroup.groupPermissions) {
            let gp : boolean = this.state.userGroup.groupPermissions[cat as PermissionCategory] ?? false;
            if(cat == category) {
                return true;
            }
        }
        return false;
    }
    hasWriteAccess(category : string) {
         if(!this.state.userGroup) {
             return false;
         }
         let enabled = this.isEnabled(category);
         if(!enabled) {
             return false;
         }

        var groupAccess = this.state.userGroup.groupPermissions;
        for(var key in groupAccess) {
            let gp : boolean = groupAccess[key as PermissionCategory] ?? false;
            if(key == category && gp == true) {
                return true;
            }
        }
        return false;
    }
    getTitle() {
        return "Edit User Group"
    }
    togglePath( cat : string) {
        if(!this.state.userGroup || !this.state.userGroup.groupPermissions) {
            return;
        }
        var userGroup = _.cloneDeep(this.state.userGroup);

        if(userGroup && userGroup.groupPermissions) {
            if(userGroup.groupPermissions[cat as PermissionCategory] !== undefined) {
                delete userGroup.groupPermissions[cat as PermissionCategory];
            } else {
                userGroup.groupPermissions[cat as PermissionCategory] = false;
            }
        }

        this.setState({userGroup : userGroup});
    }

    toggleWriteAccess( cat : string) {
        if(!this.state.userGroup || !this.state.userGroup.groupPermissions) {
            return;
        }
        var userGroup : UserGroup = _.cloneDeep(this.state.userGroup);

        if(userGroup && userGroup.groupPermissions) {
            if(userGroup.groupPermissions[cat as PermissionCategory]) {
                userGroup.groupPermissions[cat as PermissionCategory] = !userGroup.groupPermissions[cat as PermissionCategory]
            } else {
                userGroup.groupPermissions[cat as PermissionCategory] = true;
            }
        }


        this.setState({userGroup : userGroup});
    }

    toggleDetails() {
        this.setState({showDetails: !this.state.showDetails})
    }

    getCategories() {
        return this.state.categories!
            .sort((c1, c2) => {return (c1.category as string).localeCompare(c2.category as string)})
            .map((category) => {
                const eps = this.state.endpoints!
                    .filter(e => e.categories?.categoryTypes?.includes(category.category!))
                    .map(e => {
                        const txt = `${e.resource}.${e.method}{${e.categories?.requiresWriteAccess ? "w" : "r"}} `

                        const enabled = this.isEnabled(category.category!)
                        const writeAccess = this.hasWriteAccess(category.category!)

                        let clz = "light"

                        if (writeAccess  ||  enabled &&  !e.categories?.requiresWriteAccess) {
                            clz = "success"
                        } 

                        return <span key={txt} className={`badge badge-${clz}`} style={{marginRight: "2px"}}>{txt}</span>
                    })

                const show = this.state.showDetails

                return (

                    <tr key={category.category}>
                        <td><input type="checkbox" onChange={this.togglePath.bind(this,category.category!)} checked={this.isEnabled(category.category!)}/></td>
                        <td><input type="checkbox" onChange={this.toggleWriteAccess.bind(this,category.category!)} checked={this.hasWriteAccess(category.category!)}/></td>
                        <td>{category.category}</td>
                        <td>{category.description}</td>
                        { show  &&  <td>{eps}</td> }
                    </tr>

                );
            })
    }
    clearAll() {
        let ug = _.cloneDeep(this.state.userGroup);
        if(ug && ug.groupPermissions) {
            ug.groupPermissions = {};
            this.setState({userGroup : ug});
        }

    }

    onSubmit(){
        if(this.state.userGroup) {
            return this.userGroupService.save(this.state.userGroup).then( (userGroup : UserGroup) => {
                if((this.props.match.params as any).id == "new") {
                    this.navigate("/admin/user-group/"+userGroup.id);
                } else {
                    this.setState({ userGroup : userGroup })
                }
            }).catch((error)=>{
                console.error("Unable to save user group", this.state.userGroup);
            });
        }
    }
    update(name : string, event : React.FormEvent) {
        var ug = _.clone(this.state.userGroup);
        if(ug) {
            let value;

            if (name === "name") {
                value = (event.target as any).value;
            } else if (name === "displaySensitiveInformation") {
                value = (event.target as any).checked
            }
            _.set(ug, name, value);
            this.setState({ userGroup : ug });
        }

    }
    renderContent() {
        if(!this.state.userGroup) {
            return <span></span>
        }
        return (

                <form className="form-horizontal">

                    <div className="row">
                        <div className="col-lg-12">
                          <div className={"card-box"}>
                              <h5>{this.state.userGroup.name}</h5>
                              <div className="form-group">
                                  <label>Name</label>
                                  <input type="text" value={this.state.userGroup.name ?? ""} onChange={this.update.bind(this,"name")} className="form-control"/>

                              </div>
                              <div className="form-group">
                                  <input type="checkbox" name={"displaySensitiveInformation"} checked={this.state.userGroup.displaySensitiveInformation ?? false} onChange={this.update.bind(this,"displaySensitiveInformation")} />
                                  &nbsp;<label htmlFor={"displaySensitiveInformation"}>Display sensitive information</label>
                                  <br/><span className={"help"}>If the users of this group should be allowed to see unmasked passwords and other sensitive information</span>
                              </div>
                              {this.state.userGroup.id && (
                                  <div className="form-group">
                                      <label>Id</label>
                                      <input type="text" readOnly={true} disabled={true} value={this.state.userGroup.id}  className="form-control"/>
                                  </div>
                              )}
                          </div>
                        </div>
                    </div>



                    {(this.props.match.params as any).id != "new" && (
                        <div className="row">
                            <div className="col-lg-12">
                                <div className={"card-box"}>
                                    <h5>Available Categories</h5>
                                    <a className="btn btn-primary" onClick={this.clearAll.bind(this)}>Clear All</a>&nbsp;
                                    <a className="btn btn-primary" onClick={() => this.toggleDetails()}>{`${this.state.showDetails ? t(`button.hide`) : t(`button.show`)}`} Details</a>
                                    <table className="table table-striped mb-0">
                                        <thead>
                                        <tr>
                                            <th style={{width: "100px"}}>Read Access</th>
                                            <th style={{width: "100px"}}>Write Access</th>
                                            <th>Permission</th>
                                            <th>Description</th>
                                            { this.state.showDetails  &&  <th>Endpoints</th> }
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {this.getCategories()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        )
                    }


                    <div className="row">
                        <div className="col-lg-12">
                          <div className={"card-box"}>
                              <h5>Apply Changes</h5>
                              <div className="form-group">
                                  <DeleteButtonModal className={"btn btn-primary"} onDelete={()=>this.onSubmit()} label={"Save"}/>
                              </div>
                          </div>
                        </div>
                    </div>
                </form>
        );
    }


}

export default withRouter(UserGroupView)