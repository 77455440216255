import * as React from "react";
import "react-datetime/css/react-datetime.css";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { BonusConfig, BonusOffer, BonusOfferStatus, CurrencyDefinition, Settings } from "../../http/protocol";
import { t } from "../../i18n";
import { getContainer } from "../../ioc/IOCSetup";
import { BonusConfigService } from "../../services/BonusConfigService";
import { BonusOfferService } from "../../services/BonusOfferService";
import { ApplicationState } from "../../user/UserStore";
import { Form, FormListener } from "../form/Form";
import { FormDateTimeInput } from "../form/FormDateTimeInput";
import { FormSubmitError, FormSubmitSuccess } from "../form/FormFeedback";
import { FormGroup } from "../form/FormGroup";
import { FormInput } from "../form/FormInput";
import { FormUtils } from "../form/FormUtils";
import { SelectInput } from "../form/SelectInput";
import { Submit } from "../form/Submit";
import { Validators } from "../form/Validators";
import { LoadingViewState, RouterView } from "../RouterView";



interface State extends LoadingViewState {
    tc? : BonusOffer;
    validationMap? : any;
    bonusConfigs? : BonusConfig[];
}

interface Props extends RouteComponentProps {
    currencies? : CurrencyDefinition[];
}



export class EditBonusOffer extends RouterView<Props,State> implements FormListener<BonusOffer>{

    service : BonusOfferService;
    bonusConfigService : BonusConfigService;

    constructor(props : Props){
        super(props);
        this.service = getContainer().getBonusOfferService();
        this.bonusConfigService = getContainer().getBonusConfigService();
        const month:Date = new Date(new Date().setDate(new Date().getDate()+30));

        this.state = { tc : {expires:month }, validationMap : {}, bonusConfigs : [] };
    }

    getTitle(): string {
        return "Edit Bonus Offer";
    }

    componentDidUpdate(prevProps : Props, prevState : State) {
        if((prevProps.match.params as any).id != (this.props.match.params as any).id)  {
            this.refreshContent();
        }
    }

    async loadContent(): Promise<any>  {
        const bonusConfigs = await this.bonusConfigService.list({ offset : 0, limit: 100, orderBy : "name"})
        this.setState({ bonusConfigs : bonusConfigs.items});

        if((this.props.match.params as any).id != "new") {
            const bonusOffer = await this.service.getEntity((this.props.match.params as any).id)
            this.setState({ tc : bonusOffer });
        } 
    }

    onSubmit()  {
        if(!this.state.tc) return;
        return this.service.save(this.state.tc).then((s : BonusOffer)=> {
            this.setState({tc : s});
        })
    }

    formDidUpdate(formModel: BonusOffer, validationMap?: any) {
        this.setState({tc : formModel, validationMap : validationMap})
    }
    getCurrencyLabel(code : string) {
        if(this.props.currencies) {
            let c = this.props.currencies.find( c => c.code == code);
            if(c) {
                return c.name + " (" + c.code + ")";
            }
        }
        return "";
    }

    findBonusById( id : number) : BonusConfig {
        if(!this.state.bonusConfigs) {
            return {}
        }
        let res =  this.state.bonusConfigs.find((b : BonusConfig) => b.id == id)
        if(!res) {
            return {};
        }
        return res;
    }

    renderContent() {
        if(!this.state.tc || !this.props.currencies) {
            return <span></span>
        }

        return (
            <div>
                <div className="wrapper wrapper-content animated fadeInRight">
                    <Form className="form form-horizontal" validationMap={this.state.validationMap} modelObject={this.state.tc} formListener={this}>
                        <div className="row">
                            <div className="col-lg-12">

                                <div className="card-box">
                                    <div className="ibox-content">
                                        {(this.props.match.params as any).id != "new" && (   <div className="hr-line-dashed"></div> )}

                                        {(this.props.match.params as any).id != "new" && (
                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">ID</label>
                                                <div className="col-sm-10">
                                                    <FormInput  model="id" type="integer"  className="form-control" disabled={true}/>
                                                </div>
                                            </FormGroup>
                                        )}

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">User Id</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="userId" validators={[Validators.required()]} type="text"  className="form-control" />
                                                <span className={"help"}>The ID of the user to give the bonus offer to.</span>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Expires</label>
                                            <div className="col-sm-10">
                                                <FormDateTimeInput model={"expires"}/>
                                                <span className={"help"}>When this offer will expire</span>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Amount</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="amount"  type={"float"} className="form-control" validators={[Validators.required(), Validators.number()]} />
                                                <span className={"help"}>The amount of this offer</span>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Currency</label>
                                            <div className="col-sm-10">
                                                <SelectInput  model="currency" values={this.props.currencies.map(c => c.code)} className="form-control" />
                                                <span className={"help"}>Currency of this offer</span>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>

                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Status</label>
                                            <div className="col-sm-10">
                                                <SelectInput values={Object.keys(BonusOfferStatus)}  displayValue={FormUtils.fromEnum} model="status"  className="form-control" />
                                                <span className={"help"}>The status of this offer</span>
                                            </div>
                                        </FormGroup>
                                        <div className="hr-line-dashed"></div>

                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Bonus Config</label>
                                            <div className="col-sm-10">
                                                <SelectInput  model="bonusConfig" values={this.state.bonusConfigs}
                                                              optionModelValue={(m : BonusConfig) => m.id}
                                                              convertOptionValueToModel={(id : number) => this.findBonusById(id) }
                                                              convertModelValueToOption={(b : BonusConfig) => b.id}
                                                              displayValue={(b : BonusConfig) => b.name + " ("+b.id+")"}  className="form-control" />

                                                <span className={"help"}>The bonus config that this offer applies for</span>
                                            </div>
                                        </FormGroup>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card-box">

                                    <FormSubmitError />
                                    <FormSubmitSuccess text={t(`alertInfo.accountSuccessfully`)}/>
                                    <div className="form-group">
                                        <div className="col-sm-12">
                                            <Submit className="btn  btn-primary">Save</Submit>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }

}


const mapStateToProps = (state : ApplicationState) => {
    return { currencies: state.settings.settings?.currencies }
}

export default connect(mapStateToProps)(withRouter(EditBonusOffer))