export interface IPagerModel {
    limit? : number;
    nrOfResults? : number;
    offset? : number;
    orderBy? : any;
    ascending? : boolean;
}
export class PagerModel implements IPagerModel {
    public limit : number = 50;
    public nrOfResults : number = 0;
    public offset : number = 0;
    public orderBy : any = null;
    public ascending : boolean = true;

    constructor(pager? : PagerModel, nrOfResults? : number){
        if(pager) {
            this.limit = pager.limit;
            this.nrOfResults = nrOfResults || 0;
            this.offset = pager.offset;
            this.limit = pager.limit;
            this.orderBy = pager.orderBy;
            this.ascending = pager.ascending;
        }
    }
}