import React, {Fragment} from "react";
import {ActionType} from "../list/list/DataTable";
import {
    ListCasinoLobbyCategoryRequest,
    ListChartsRequest,
    ListUsersRequest, TurnoverContributions,
    TurnoverContributionsListRequest,
    User,
    UserBonusEvent,
    UserBonusEventListRequest
} from "../../http/protocol";
import {getContainer} from "../../ioc/IOCSetup";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {PagerModel} from "../list/list/PagerModel";
import {ListUtils} from "../list/list/ListUtils";
import {withRouter} from "react-router";
import {ListView, ListViewProps, ListViewState} from "../ListView";
import {UserService} from "../../services/UserService";
import {Form, FormListener} from "../form/Form";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Validators} from "../form/Validators";
import {Submit} from "../form/Submit";
import {ColumnType} from "../list/list/ColumnType";
import {CasinoCategoryService} from "../../services/CasinoCategoryService";
import {TurnoverContributionsService} from "../../services/TurnoverContributionsService";
import { RouteComponentProps } from "react-router-dom";
import { UserBonusEventService } from "../../services/UserBonusEventService";


interface State extends ListViewState<UserBonusEventListRequest> {
    // form? : UserBonusEventListRequest;
    validationMap? : any;
}
interface Props extends ListViewProps, RouteComponentProps  {
}

export class BonusEventsListView extends ListView<State, UserBonusEventListRequest> {

    columns = [
        { property : "id", name : "ID", orderBy : "id"},
        { property : "type", name : "Type"},
        { property : "timestamp", name : "Timestamp", type: ColumnType.DATE},
        { property : "currency", name : "Currency"},
        { property : "amount", name : "Amount"},
        { property : "gameName", name : "Game"},
        { property : "nanoTxId", name : "Nano Tx", type : ColumnType.LINK, getUrl : (item : UserBonusEvent) => { return "/transaction/" + item.nanoTxId}},
        { property : "product", name : "Product"},
        { property : "status", name : "Status"},
        { property : "contribution", name : "Contribution"},
        { property : "parachuteBonusBalance", name : "Bonus Balance (parachute)"},
        { property : "parachuteBonusPhase", name : "Bonus Money (parachute)", type: ColumnType.BOOLEAN},
        { property : "userBalance", name : "User Balance (total)"},
        { property : "freespins", name : "Freespins", type: ColumnType.BOOLEAN}
    ];

    actions = [
        // { id : "delete", name : "delete", className : "btn btn-danger", iconClassName : "fa fa-times", type : ActionType.CONFIRMATION},
        // { id : "view", name : "View", className : "btn btn-primary", iconClassName : "fa fa-user", type : ActionType.LINK, getLink : (item : any) => "/bonus/turnover-contributions/" + item.id }

    ];

    service : UserBonusEventService;

    constructor(props : Props){
        super(props, "/bonus/events/" + (props.match.params as any).id);
        console.log("props: ", props)

        const userBonusId = Number((props.match.params as any).id);

        console.log("user bonus id: ", userBonusId)

        this.state = {...this.state,  listRequest: {userBonusId: userBonusId, ...this.state.listRequest}, validationMap : {}};
        this.service = getContainer().getUserBonusEventService();
    }

    onAction(item : TurnoverContributions, action : string) : Promise<any> | undefined {
        return undefined
    }

    pagerChanged(pager : PagerModel) {
        if(this.state.listRequest) {
            console.log("pager changed!", pager);
            let listRequest = ListUtils.toListRequest<any>(this.state.listRequest,pager);
            this.updateListState(listRequest);
        }
    }
    getTitle() {
        return "User Bonus Events";
    }

    renderContent() {
        if(!this.state.listRequest) {
            return <div/>;
        }
        return (
            <Fragment>
                <EntityDataTable service={this.service}
                                 onPagerChange={this.pagerChanged.bind(this)}
                                 columns={this.columns}
                                 listRequest={this.state.listRequest}
                                 onAction={this.onAction.bind(this)}
                                 actions={this.actions}/>
            </Fragment>
        );
    }



}

export default withRouter(BonusEventsListView);
