import React, {Fragment} from "react";
import {ActionType} from "../list/list/DataTable";
import {Affiliate, AffiliatesListRequest, AffiliateStatus, ListAccountsRequest,AffiliatePayoutModel} from "../../http/protocol";
import {getContainer} from "../../ioc/IOCSetup";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {PagerModel} from "../list/list/PagerModel";
import {ListUtils} from "../list/list/ListUtils";
import {withRouter} from "react-router";
import {ListView, ListViewProps, ListViewState} from "../ListView";
import {Form,FormListener} from "../form/Form";
import {AffiliatesService} from "../../services/AffiliatesService";
import {SelectInput} from "../form/SelectInput";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Validators} from "../form/Validators";
import {Submit} from "../form/Submit";
import {t} from "../../i18n";

interface State extends ListViewState<AffiliatesListRequest> {
    listRequest?: ListAccountsRequest,
    form? : AffiliatesListRequest;
    lookupExpanded?: boolean;
    validationMap? : any;
}
interface Props extends ListViewProps  {

}

export class AffiliateListView extends ListView<State, AffiliatesListRequest> implements FormListener<AffiliatesListRequest>{

    columns = [
        { property : "id", name : "ID"},
        { property : "email", name : "Email"},
        { property : "name", name : "Name"},
        { property : "username", name : "Username"},
        { property : "enabled", name : "Enabled", renderer : (p : string,n : string,i : any) => i[p] == true ? "Yes" : "No"},
        { property : "payoutModel", name : "Payout Model"},
        { property : "status", name : "Status"},
    ];

    actions = [
        { id : "view", name : "View", className : "btn btn-primary", iconClassName : "fa fa-user", type : ActionType.LINK, getLink : (item : any) => "/affiliate/user/" + item.id },
        { id : "view", name : "Detailed Report", className : "btn btn-primary", iconClassName : "fa fa-list", type : ActionType.LINK, getLink : (item : any) => "/affiliate/detailed-report/" + item.id },
        { id : "approve", name : "Approve", type: ActionType.APPROVE, isApproved : (item : Affiliate) => item.status==AffiliateStatus.APPROVED},

    ];

    service : AffiliatesService;
    affiliatesService : AffiliatesService;

    constructor(props : Props){
        super(props, "/affiliates");
        this.service = getContainer().getAffiliatesService();
        this.affiliatesService= getContainer().getAffiliatesService();


        let listRequest =  {  limit: 20, ascending: false, orderBy: "id"}
        this.state = {...this.state,  validationMap : {}, form : {...listRequest}, listRequest : listRequest};
    }

    async componentDidMount() {
        super.componentDidMount();
    
           

    }


    onSubmit() : void | Promise<any> {
        
        let listRequest : ListAccountsRequest = Object.assign({},{...this.state.form});
        listRequest.limit= 20;
        listRequest.ascending = false;
         listRequest.orderBy = "id";
        listRequest.offset = 0;
        const userId = (this.state.form as any).userId;
        listRequest.userId =  parseInt(userId);
        this.updateListState(listRequest);
        
    }
    getSearchByStatus() {

        return(
            <div className="card-box expandable-box" style={!this.state.lookupExpanded ? {cursor:"pointer"} : {cursor:"default"}} onClick={() => !this.state.lookupExpanded ? this.setState({lookupExpanded:true}) : null}>
                {this.state.lookupExpanded && (
                    <a className="expand-button float-right" onClick={()=>this.setState({lookupExpanded : false})}><i className="mdi mdi-minus"></i></a>
                )}
                {!this.state.lookupExpanded && (
                    <a className="expand-button float-right" onClick={()=>this.setState({lookupExpanded : true})}><i className="mdi mdi-plus"></i></a>
                )}
                <h4 className="header-title mb-3" onClick={()=>this.setState({lookupExpanded : !this.state.lookupExpanded})}>Search by Status</h4>
                {this.state.lookupExpanded && (
                   <div >
                 <i><strong>Note:</strong> Selecting both filter options will show affiliates matching both filters</i>
                 
                   <Form formListener={this} modelObject={this.state.form} validationMap={this.state.validationMap}>
                       <div className="row"style={{ padding:"10px 22px"}} >
                         

                           <div className="col-lg-6">
                               <FormGroup className="form-group">
                               <label>Payout Model</label>
                                   <SelectInput className="form-control" model="payoutModel" 
                                                values={[...Object.keys(AffiliatePayoutModel)]}
                                                type="text"
                                                optional={true}
                                                label={"Payout Model"}
                                                onFocus={() => this.clearForAdvanced()}>
                                   </SelectInput>
                                   <span className={"help-text"}>Search for affiliate user based on their Payout Model Status.
                                       </span>
                               </FormGroup>
                           </div>
                 
                           <div className="col-lg-6">
                               <FormGroup className="form-group">
                               <label>Status</label>
                                   <SelectInput className="form-control" model="status" 
                                              defaultValue={AffiliateStatus.APPROVED}
                                                values={[AffiliateStatus.APPROVED,AffiliateStatus.PENDING,AffiliateStatus.SUSPENDED]}
                                                optional={true}
                                                type="text"
                                                onFocus={() => this.clearForAdvanced()}>
                                   </SelectInput>
                                   <span className={"help-text"}>Search for affiliate user based on their Account Status.
                                       </span>
                               </FormGroup>
                           </div>

                       </div>
                  
                       <div className="form-group">
                               <Submit className={"btn btn-primary"}>{t(`button.search`)}</Submit>
                               <button onClick={() => this.clearFilter()} className={"btn btn-secondary"}>{t(`button.clear`)}</button>
                       </div>
                   </Form>
                   
               </div>
                )}
            </div>
        )
    }

    onSubmitError() :  void {
        console.log("submit error!")
    }

    formDidUpdate(formModel: AffiliatesListRequest, validationMap?: any): void {
        this.setState({form : formModel, validationMap : validationMap});
    }

    onAction(item: Affiliate, action: string): Promise<any> | undefined {
        if(item.id && (action == "approve" || action == "disapprove")) {
            let x = this.service.updateStatus(item.id, item.status== AffiliateStatus.APPROVED ? AffiliateStatus.PENDING: AffiliateStatus.APPROVED)
                .then(value => this.refreshContent())
            return x;
        }
    }


    pagerChanged(pager : PagerModel) {
        if(!this.state.listRequest){
            return;
        }
        let listRequest = ListUtils.toListRequest<any>(this.state.listRequest,pager);
        this.setState({listRequest : listRequest});
    }
    getTitle() {
        return "List Affiliates"
    }
    clearForText() {
        let searchString:string | undefined = this.state.form && this.state.form.searchString;
        this.setState({form : {searchString:searchString}});
    }
    clearForAdvanced() {
        this.setState({form: {...this.state.form, searchString:""}})

    }
    clearFilter() {
        let listRequest =  {  limit: 20, ascending: false, orderBy: "id"}
        this.setState({form: listRequest})
    }

 

    getSearchByUserId() {
        return(
            <div className="row">
                <div className="col-lg-6">
                    <div className="card-box">
                        <h4>Find By Id</h4>
                        <Form formListener={this} modelObject={this.state.form} validationMap={this.state.validationMap}>
                            <FormGroup className={"form-group"} model="userId">
                                <FormInput model={"userId"} type="number" className={"form-control"} validators={[Validators.integer()]}/>
                                <span className={"help-text"}>Finds a user by user id. Exact match only.</span>
                            </FormGroup>
                            <Submit className={"btn btn-primary"}>{t(`button.findUser`)}</Submit>
                        </Form>
                    </div>

                </div>
                <div className="col-lg-6">
                    <div className="card-box">
                        <h4>Free text search</h4>
                        <Form formListener={this} modelObject={this.state.form} validationMap={this.state.validationMap}>
                            <FormGroup className={"form-group"}>
                                <FormInput model={"searchString"} className={"form-control"} validators={[Validators.strLength(3,255)]} onFocus={()=>this.clearForText()}/>
                                <span className={"help-text"}>Search for email or user attribute values.</span>
                            </FormGroup>
                            <Submit className={"btn btn-primary"}>{t(`button.search`)}</Submit>
                        </Form>
                    </div>

                </div>
                
            </div>
        )
    }
    renderContent() {
        if(!this.state.listRequest) {
            return <div/>;
        }
        return (
            <Fragment>
             
                {this.getSearchByUserId()}
                {this.getSearchByStatus()}
                <EntityDataTable service={this.service}
                                 onPagerChange={this.pagerChanged.bind(this)}
                                 columns={this.columns}
                                 listRequest={this.state.listRequest}
                                 onAction={this.onAction.bind(this)}
                                 actions={this.actions}/>
            </Fragment>
        );
    }



}

export default withRouter(AffiliateListView);
