import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {HttpService} from "../http/HttpService";
import {KycStatus, ListUsersResponse, ResetPasswordResponse, User, UserLevelAdminView} from "../http/protocol";


export class UserService extends AbstractAdminEntityService<User, ListUsersResponse>   {
    constructor(http : HttpService) {
        super("/user", http)
    }

    setPokerId(userId:number): Promise<{}> {
        return this.http.get(this.entityPath + `/setPokerId/${userId}`);
    }

    blockUser(userId: number): Promise<{}> {
        return this.http.put(this.entityPath + `/${userId}/block`,{});
    }

    unblockUser(userId: number): Promise<{}> {
        return this.http.put(this.entityPath + `/${userId}/unblock`,{});
    }
    
    getUserKycStatusCount(status:KycStatus): Promise<number> {
        return this.http.get(this.entityPath + `/kycCount/${status}`)
    }

    addUserLabel(userId:number, label:string):Promise<{}> {
        let encodedLabel= encodeURIComponent(label);
        return this.http.put(this.entityPath + `/${userId}/label/${encodedLabel}`, {});
    }

    deleteUserLabel(userId:number, label:string):Promise<{}> {
        let encodedLabel= encodeURIComponent(label);
        return this.http.delete(this.entityPath + `/${userId}/label/${encodedLabel}`);
    }

    getAllLabels():Promise<string[]> {
        return this.http.get('/userlabels/all');
    }

    disableUser2FA(userId:number | undefined):Promise<{}> {
        return this.http.delete(this.entityPath + `/${userId}/mfa`);
    }

    getUserLevel(userId: number) : Promise<UserLevelAdminView> {
        return this.http.get(this.entityPath + `/${userId}/level`);
    }

    resetPassword(userId: number) : Promise<ResetPasswordResponse> {
        return this.http.put(this.entityPath + `/${userId}/recover-password`, {});
    }

    updateKycStatus(userId: any, kycStatus: KycStatus): Promise<any> {
        return this.http.put(this.entityPath + `/${userId}/kyc/${kycStatus}`, {})
    }

    setUserVisibilityFlag(userId: any, value:boolean): Promise<any> {
        return this.http.post(this.entityPath + `/${userId}/visible/${value}`, {})
    }

    getUserByUid(userUid:string): Promise<User> {
        return this.http.get(this.entityPath + `/uid/${userUid}`)
    }

    manuallyApprove(userId : number) {
        return this.http.get(this.entityPath + `/approve-registration/${userId}`);
    }
}