import React, {Fragment} from "react";
import {ActionType} from "../list/list/DataTable";
import {CasinoStudio, ListCasinoLobbyCategoryRequest, ListChartsRequest, ListStudioRequest} from "../../http/protocol";
import {getContainer} from "../../ioc/IOCSetup";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {PagerModel} from "../list/list/PagerModel";
import {ListUtils} from "../list/list/ListUtils";
import {withRouter} from "react-router";
import {ListView, ListViewProps, ListViewState} from "../ListView";
import {Form, FormListener} from "../form/Form";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Validators} from "../form/Validators";
import {Submit} from "../form/Submit";
import {CasinoGameTagService} from "../../services/CasinoGameTagService";


interface State extends ListViewState<ListCasinoLobbyCategoryRequest> {
    form? : ListStudioRequest;
    validationMap? : any;
}
interface Props extends ListViewProps  {

}

export class CasinoGameTagListView extends ListView<State, ListChartsRequest> implements FormListener<ListStudioRequest>{

    columns = [
        { property : "id", name : "ID", orderBy : "id"},
        { property : "name", name : "Name"},
        { property : "nameId", name : "Name ID"}
    ];

    actions = [
        { id : "view", name : "View", className : "btn btn-primary", iconClassName : "fa fa-user", type : ActionType.LINK, getLink : (item : any) => "/casino-game-tag/" + item.id }

    ];

    service : CasinoGameTagService;

    constructor(props : Props){
        super(props, "/casino-game-tags");
        this.state = {...this.state,  listRequest : {...this.state.listRequest, orderBy : "name"}, validationMap : {}, form : {}};
        this.service = getContainer().getCasinoGameTagService();
    }
    onSubmit() : void | Promise<any> {
        if(this.state.form) {
            let form = this.state.form;
            form.offset = 0;
            this.updateListState(form);
        }
    }
    onSubmitError() :  void {

    }

    formDidUpdate(formModel: ListStudioRequest, validationMap?: any): void {
        this.setState({form : formModel, validationMap : validationMap});
    }

    onAction(item : CasinoStudio, action : string) : Promise<any> | undefined {
        return;
    }

    pagerChanged(pager : PagerModel) {
        if(this.state.listRequest) {
            console.log("pager changed!", pager);
            let listRequest = ListUtils.toListRequest<any>(this.state.listRequest,pager);
            this.updateListState(listRequest);
        }
    }
    getTitle() {
        return "Casino Game Tags"
    }

    renderContent() {
        if(!this.state.listRequest) {
            return <div/>;
        }
        return (
            <Fragment>
                <div className={"card-box"}>
                    <h4>Search Filters</h4>
                    <Form formListener={this} modelObject={this.state.form} validationMap={this.state.validationMap}>
                        <FormGroup className={"form-group"}>
                            <label>Name</label>
                            <FormInput model={"name"} className={"form-control"} validators={[Validators.strLength(1,255)]}/>
                            <span className={"help-text"}>Search by name</span>
                        </FormGroup>
                        <Submit className={"btn btn-primary"}>Search</Submit>
                    </Form>
                </div>
                <EntityDataTable service={this.service}
                                 onPagerChange={this.pagerChanged.bind(this)}
                                 columns={this.columns}
                                 listRequest={this.state.listRequest}
                                 onAction={this.onAction.bind(this)}
                                 actions={this.actions}/>
            </Fragment>
        );
    }



}

export default withRouter(CasinoGameTagListView);
