import { JsonDocument } from "../../http/protocol"
import { RJSFSchema } from '@rjsf/utils';
import { customizeValidator } from '@rjsf/validator-ajv8';
// import Form from '@rjsf/core';
import Form from '@rjsf/bootstrap-4'


export const SettingsAdvancedVisualEditor = (props : { document: JsonDocument, schema: any }) => {
    const validator = customizeValidator<any>();
  
    const onSubmit = ({formData}: any, e: any) => console.log('Data submitted: ', formData);    

    return (
        <div>
            <Form schema={props.schema} validator={validator} formData={JSON.parse(props.document.json!)} 
                onSubmit={onSubmit}
            />
            <br/>
            <pre>{JSON.stringify(JSON.parse(props.document.json!), null, 4)}</pre>

        </div>
    )
}