import {HttpService} from "../http/HttpService";
import {MaintenanceWindow} from "../http/protocol";

export class MaintenanceWindowService {
    http : HttpService;
    constructor(http: HttpService) {
        this.http = http;
    }
    save(mw : MaintenanceWindow) : Promise<MaintenanceWindow> {
        return this.http.post("/maintenance", mw);
    }

    getById(id : string) : Promise<MaintenanceWindow> {
        return this.http.get("/maintenance/" + id);
    }
    get() : Promise<MaintenanceWindow[]> {
        return this.http.get("/maintenance")
    }
}