import {HttpService} from "../http/HttpService";
import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {
    CasinoGame,
    ListCasinoGameResponse,
    TurnoverContributions,
    TurnoverContributionsListResponse
} from "../http/protocol";


export class TurnoverContributionsService  extends AbstractAdminEntityService<TurnoverContributions, TurnoverContributionsListResponse>  {

    constructor(http : HttpService) {
        super("/bonus/turnover-contributions", http)
    }

}