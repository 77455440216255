import { HttpService } from "../http/HttpService";
import { GameIntegration, ServiceEnabledKey, ServicesEnabledResponse } from "../http/protocol";
import { AbstractAdminEntityService } from "./AbstractAdminEntityService";

export class ServiceLevelConfigurationService extends AbstractAdminEntityService<any, any> {
    constructor(http: HttpService) {
        super("/service-enabled", http);
    }

    getServiceConfig(): Promise<ServicesEnabledResponse> {
        return this.http.get(`${this.entityPath}/state`);
    }

    updateServiceConfig(key: ServiceEnabledKey, enabled: boolean): Promise<ServicesEnabledResponse> {
        return this.http.post(`${this.entityPath}/enabled/${enabled}`, key);
    }
    
}
