import {PermissionRequired} from "../security/PermissionRequired";
import {AccountBalanceResult, PermissionCategory, TransactionResult, TxResult, UserBonusView} from "../../http/protocol";
import {AccountBalances} from "./UserAccountBalances";
import {UserTransactionPanel} from "./UserTransactionPanel";
import React, {useEffect, useState} from "react";
import {getContainer} from "../../ioc/IOCSetup";


export const UserAccountsAndTransactions = (props : {userId : number}) => {
    const [accounts, setAccounts] = useState<AccountBalanceResult[]>([]);
    const [activeBonuses, setActiveBonuses] = useState<UserBonusView[]>();
    const wallet = getContainer().getWalletService();
    const bonusService = getContainer().getUserBonusService();
    const refreshBalances = () => {
        return wallet.getAccountBalances("" + props.userId).then(accounts => {
            setAccounts(accounts);
        })
    }

    useEffect(() => {
        bonusService.getActiveBonuses(props.userId).then(r => {
            setActiveBonuses(r);
        });
        refreshBalances();
    }, []);


    return (
        <>
            <div className="col-lg-12">
                <AccountBalances userId={props.userId} accounts={accounts} activeBonuses={activeBonuses}/>

            </div>
            <div className="col-lg-12">
                <UserTransactionPanel
                    accounts={accounts}
                    onTransferComplete={(tr: TxResult) => {
                        refreshBalances()
                    }} transfer={(tr) => wallet.transfer(tr)}
                    activeBonuses={activeBonuses}
                />
            </div>
        </>
    )
}