import * as React from "react";

import {RouteComponentProps, withRouter} from "react-router";
import {LoadingViewState, RouterView} from "../RouterView";
import {
    CasinoGame,
    CasinoGameTranslation,
    CasinoGameType,
    CasinoMobileNavigation,
    GameDisplay,
    GameLockedField,
    GameTag,
} from "../../http/protocol";
import {Form, FormListener} from "../form/Form";
import {getContainer} from "../../ioc/IOCSetup";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Validators} from "../form/Validators";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {Submit} from "../form/Submit";
import {CasinoGameService} from "../../services/CasinoGameService";
import {BindModel, FormList, ModelBinding} from "../form/FormList";
import {formatInstant} from "../../util/date";
import {CasinoGameTagService} from "../../services/CasinoGameTagService";
import {SelectInput} from "../form/SelectInput";


interface State extends LoadingViewState {
    game? : CasinoGame;
    tags?: GameTag[];
    lockedFields?: GameLockedField[]
    validationMap? : any;
    descCharLimit:number;
}

export class EditCasinoGameView extends RouterView<RouteComponentProps,State> implements FormListener<CasinoGame>{

    service : CasinoGameService;
    tagService : CasinoGameTagService;

    constructor(props : RouteComponentProps){
        super(props);
        this.service = getContainer().getCasinoGameService();
        this.tagService = getContainer().getCasinoGameTagService();
        this.state = { game : {}, validationMap : {}, tags : [], descCharLimit:0, lockedFields: [] };
    }

    getTitle(): string {
        return "Edit Casino Game";
    }

    componentDidUpdate(prevProps : RouteComponentProps, prevState : State) {
        if((prevProps.match.params as any).id != (this.props.match.params as any).id)  {
            this.refreshContent();
        }
    }
    sortTranslations(s : CasinoGame) {
        if(s.translations) {
            s.translations.sort((a,b)=>{
                if(!a.language) {
                    return 1;
                } else if(!b.language) {
                    return 1;
                } else  if(!a && !b) {
                    return 0;
                }
                return a.language.localeCompare(b.language);
            })
        }
    }
    async loadContent(): Promise<any>  {
        const gameId = (this.props.match.params as any).id
        const game = await this.service.getEntity(gameId)
        this.sortTranslations(game);
        this.setState({ game : game });
        console.log("loaded game with tags", game);


        const lockedFields = await this.service.getLockedFields(gameId)
        this.setState({ lockedFields : lockedFields });

        const tags = (await this.tagService.list({offset : 0, limit: 1000, orderBy: "name", ascending : true} as any)).items ?? []
        this.setState({ tags : tags });

        // return this.service.getEntity((this.props.match.params as any).id).then((s : CasinoGame) => {
        //     this.sortTranslations(s);
        //     this.setState({ game : s });
        //     console.log("loaded game with tags", s);
        //     return this.tagService.list({offset : 0, limit: 1000, orderBy: "name", ascending : true} as any).then( (response)=>{
        //         this.setState({ tags : response.items });
        //     })
        // });
    }

    async onSubmit()  {
        if(!this.state.game) return;

        const g = await this.service.save(this.state.game)
        const lockedFields = await this.service.getLockedFields(g.id!)

        this.sortTranslations(g);
        this.setState({game: g, lockedFields: lockedFields});
    }

    formDidUpdate(formModel: CasinoGame, validationMap?: any) {
        let limit:number = this.state.game?.description?.length || 0;
        this.setState({game : formModel, validationMap : validationMap, descCharLimit:limit})
    }

    addTranslation() {
        if(this.state.game && this.state.game.translations) {
            let translations = this.state.game.translations.concat([{}])
            let game = {...this.state.game,  translations : translations };
            this.sortTranslations(game);
            this.setState({ game : game})
        } else {
            let game = {...this.state.game,  translations : [{}] };
            this.setState({ game : game})
        }
    }

    async lockField(field: GameLockedField, locked: boolean) {
        const lockedFields = await this.service.setFieldLock(this.state.game!.id as number, field, locked)
        this.setState({lockedFields: lockedFields})
    }

    renderFieldLock(field: GameLockedField) {
        const locked = (this.state.lockedFields ?? []).indexOf(field) >= 0
        console.log("locked: %s, locked field: %s, field: %s", locked, this.state.lockedFields, field)

        if (locked) {
            return <div>
                    <i className="fa fa-lock text-warning"></i> <span className="help">This fields is locked from automatic updates. </span>
                    <a className="text-primary" onClick={() => this.lockField(field, false)}>Unlock.</a>
                </div>
        } else {
            return <div>
                    <i className="fa fa-unlock text-success"></i> <span className="help">This fields will be automatically updated. </span>
                    <a className="text-primary" onClick={() => this.lockField(field, true)}>Lock.</a>
                </div>
        }
    }

    viewStyledImage() {
        if(this.state.game?.imageVariantLinks && Object.keys(this.state.game?.imageVariantLinks).length){
            return Object.entries(this.state.game?.imageVariantLinks).map(([key,val])=>{
                return (
                    <div>
                        <label className="col-sm-2 control-label">{key}</label>
                        <div className="col">
                            <img src={val} height={300}/>
                        </div>
                    </div>
                )
            })
        } else {
            return <span className={"help-block px-2"}>No styled image available for this game..</span>
        }
    }

    renderContent() {

        if(!this.state.game || !this.state.tags) {
            return <span></span>
        }

        return (
            <div>


                <div className="wrapper wrapper-content animated fadeInRight">
                    <Form className="form form-horizontal" validationMap={this.state.validationMap} modelObject={this.state.game} formListener={this}>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card-box">
                                    <h5>Casino Game</h5>
                                    <p className="help">NOTE: Editing a property will lock it from automatic updates by the system. You can toggle this lock by clicking lock/unlock.</p>
                                    <div className="ibox-content">

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">ID</label>
                                            <div className="col-sm-10">
                                                <FormInput model="id" type="integer" className="form-control"
                                                           disabled={true}/>
                                            </div>
                                        </FormGroup>
                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">External ID</label>
                                            <div className="col-sm-10">
                                                <FormInput model="externalId" type="integer" className="form-control"
                                                           disabled={true}/>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Name</label>
                                            <div className="col-sm-10">
                                                <FormInput model="name" type="text" className="form-control"/>
                                                {this.renderFieldLock(GameLockedField.NAME)}
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Image URL</label>
                                            <div className="col-sm-10">
                                                <FormInput model="imageUrl" type="text" className="form-control"/>
                                                {this.renderFieldLock(GameLockedField.IMAGE_URL)}
                                            </div>
                                        </FormGroup>
                                        <FormGroup className="form-group">
                                            <div className="col-sm-10">
                                                <img src={this.state.game.imageUrl} height={200}/>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Description</label>
                                            <div className="col-sm-10">
                                                <FormInput model="description" type="textarea"
                                                           className="form-control"/>
                                                {this.renderFieldLock(GameLockedField.DESCRIPTION)}
                                                <span className="help-block">Character limit: <span
                                                    style={this.state.descCharLimit > 1024 ? {color: "red"} : {}}> {this.state.descCharLimit}</span>/1024</span>
                                            </div>
                                        </FormGroup>
                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Release Date</label>
                                            <div className="col-sm-10">
                                                <span>{formatInstant(this.state.game.releaseDate, "YYYY-MM-DD")}</span>
                                            </div>
                                        </FormGroup>


                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Integration</label>
                                            <div className="col-sm-10">
                                                <FormInput model="integration" type="text" className="form-control"
                                                           disabled={true}/>
                                            </div>
                                        </FormGroup>
                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Studio</label>
                                            <div className="col-sm-10">
                                                <FormInput model="studio.name" type="text" className="form-control"
                                                           disabled={true}/>
                                            </div>
                                        </FormGroup>
                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Jurisdictions</label>
                                            <div className="col-sm-10">
                                                {this.state.game?.jurisdictions && this.state.game.jurisdictions.length > 0 ? (
                                                    <ul>
                                                        {this.state.game.jurisdictions.map((jurisdiction, index) => (
                                                            <li key={index}>{jurisdiction.name}</li>
                                                        ))}
                                                    </ul>
                                                ) : (
                                                    <span>No Jurisdictions.</span>
                                                )}
                                            </div>
                                        </FormGroup>
                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group mb-3" model="display">
                                            <label>Display Mode</label>
                                            <SelectInput optional={false} multiple={false}
                                                         values={Object.keys(GameDisplay)} className="form-control"
                                                         model="display"
                                            />
                                            {this.renderFieldLock(GameLockedField.DISPLAY)}

                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group mb-3" model="mobileNavigation">
                                            <label>Mobile navigation</label>
                                            <SelectInput optional={false} multiple={false}
                                                         values={Object.keys(CasinoMobileNavigation)}
                                                         className="form-control" model="mobileNavigation"
                                            />
                                            {this.renderFieldLock(GameLockedField.MOBILE_NAVIGATION)}

                                        </FormGroup>
                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group mb-3" model="gameType">
                                            <label>Game Type</label>
                                            <SelectInput optional={false} multiple={false}
                                                         size={Object.keys(CasinoGameType).length}
                                                         values={Object.keys(CasinoGameType)} className="form-control"
                                                         model="gameType"
                                            />
                                            {this.renderFieldLock(GameLockedField.GAME_TYPE)}

                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group mb-3" model="gameTags">
                                            <label>Game Tags</label>
                                            <SelectInput optional={true} multiple={true}
                                                         convertOptionValueToModel={(str) => {
                                                             if (this.state.tags) {
                                                                 return this.state.tags.find(t => "" + t.id == str)
                                                             } else {
                                                                 return undefined;
                                                             }
                                                         }}
                                                         convertModelValueToOption={(tags: GameTag[]) => tags.map((t: GameTag) => t.id)}
                                                         size={this.state.tags.length}
                                                         values={this.state.tags} className="form-control"
                                                         model="gameTags"
                                                         displayValue={(t) => t.name}
                                                         optionModelValue={(t) => t.id}
                                            />
                                            {this.renderFieldLock(GameLockedField.TAGS)}

                                        </FormGroup>


                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Enabled</label>
                                            <div className="col-sm-10">
                                                <FormInput model="enabled" type="checkbox"/>
                                                {this.renderFieldLock(GameLockedField.ENABLED)}
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Allowed Countries</label>
                                            <div className="col-sm-10">
                                                <FormInput model="allowedCountries" type="text"
                                                           className="form-control"/>
                                                <span className="help-block">Comma separated list of country code. Only allow this game for the specified countries, example: SE,US</span>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Blocked Countries</label>
                                            <div className="col-sm-10">
                                                <FormInput model="blockedCountries" type="text"
                                                           className="form-control"/>
                                                <span className="help-block">Comma separated list of country code. Block this game for the specified countries, example: SE,US</span>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Removed</label>
                                            <div className="col-sm-10">
                                                <span>{this.state.game.removed ? "Yes" : "No"}</span>
                                            </div>
                                        </FormGroup>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Supports Free Spins</label>
                                            <div className="col-sm-10">
                                                <span>{this.state.game.freeSpinsSupported ? "Yes" : "No"}</span>
                                            </div>
                                        </FormGroup>
                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Background Image URL</label>
                                            <div className="col-sm-10">
                                                <FormInput className="form-control" model={"backgroundImageUrl"}/>
                                            </div>
                                        </FormGroup>
                                        <FormGroup className="form-group">
                                            <div className="col-sm-10">
                                                <img src={this.state.game.backgroundImageUrl} height={200}/>
                                                {this.renderFieldLock(GameLockedField.BACKGROUND_IMAGE_URL)}
                                            </div>
                                        </FormGroup>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card-box">
                                    <h5>Translations</h5>
                                    <div className="ibox-content">

                                        <FormList model={"translations"}>
                                            <div className="hr-line-dashed"></div>
                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">Language</label>
                                                <div className="col-sm-10">
                                                    <FormInput model="language" validators={[Validators.required()]}
                                                               type="text" className="form-control"/>
                                                </div>
                                            </FormGroup>
                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">Name</label>
                                                <div className="col-sm-10">
                                                    <FormInput model="name" type="text" className="form-control"/>
                                                </div>
                                            </FormGroup>
                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">Image Url</label>
                                                <div className="col-sm-10">
                                                    <FormInput  model="imageUrl" type="text"  className="form-control" />
                                                </div>
                                            </FormGroup>
                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">Description</label>
                                                <div className="col-sm-10">
                                                    <FormInput  model="description" type="textarea"  className="form-control" />
                                                </div>
                                            </FormGroup>
                                            <div className={"col-sm-10"}>
                                                <BindModel render={(b : ModelBinding<CasinoGameTranslation>)=>{
                                                    return <a onClick={()=>b.removeCurrentItem()} className={"btn btn-danger"} style={{ float : "right"}}>Remove</a>
                                                }}/>
                                            </div>
                                        </FormList>
                                        <div className="hr-line-dashed"></div>
                                        <div>
                                            <p>

                                            { this.renderFieldLock(GameLockedField.TRANSLATIONS) }
                                            </p>

                                            <a onClick={()=>this.addTranslation()} className={"btn btn-primary"}>Add Translation</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card-box">
                                    <label className="control-label">Styled Image</label> <br />
                                    <span className={"help-block"}>
                                    Image styles available for this game. You can change the template URL for each style under settings / advanced / config.central.images
                                    </span>
                                    <div className="row pt-3">
                                        {this.viewStyledImage()}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card-box">

                                    <FormSubmitError />
                                    <FormSubmitSuccess text="Casino Game was updated successfully"/>
                                    <div className="form-group">
                                        <div className="col-sm-12">
                                            <Submit className="btn  btn-primary">Save</Submit>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }

}

export default withRouter(EditCasinoGameView);