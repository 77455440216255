
import moment from 'moment';
import "moment-timezone"

export const formatDate = (timestamp? : string|number|Date) => {
    if(!timestamp) {
        return "N/A"
    }
    if(typeof(timestamp)=="string") {
       timestamp = moment(timestamp,"YYYY-MM-DD HH:mm:ss.SSS").toDate().getTime();
    }

    return moment(timestamp).tz("UTC").format("YYYY-MM-DD HH:mm:ss UTC");
}
export const formatInstant = (timestamp? :number | Date, format? : string) => {
    if(!timestamp) {
        return "N/A"
    }
    if(!format) {
        format = "YYYY-MM-DD HH:mm:ss.SSS";
    }
    timestamp = Math.floor((timestamp as number));
    if(typeof(timestamp)=="string") {
        timestamp = moment(timestamp,format).toDate().getTime();
    }

    return moment(timestamp).tz("UTC").format(format);
}
export const fromNow = (timestamp? : number|Date) => {
    if(!timestamp) {
        return "N/A"
    }
    return moment(timestamp).tz("UTC").fromNow();
}


export const formatDateInterval = (timestampA : number, timestampB : number) => {
    if(!timestampA || !timestampB) {
        return "N/A"
    }
    let dateA = moment(timestampA).tz("UTC").format("YYYY-MM-DD");
    let dateB = moment(timestampB).tz("UTC").format("YYYY-MM-DD");
    let timeA = moment(timestampA).tz("UTC").format("HH:mm");
    let timeB = moment(timestampB).tz("UTC").format("HH:mm")
    let sameDate = false;
    if(dateA == dateB) {
        sameDate = true;
    }

    let final = dateA
    final+= " " + timeA + " - "
    if(!sameDate) {
        final+= dateB + " ";
    }
    final += timeB + " UTC";

    return final;


}