import * as React from "react";



interface State {
    title? : string;
    collapsed? : boolean;
}
interface Props {title: string, isSmall?: boolean, collapsed: boolean, onToggle?: (collapsed: boolean) => void}
export class Collapsible extends React.Component<Props,State>{

    constructor(props  : Props) {
        super(props);
        console.log("Props: ", props);
        this.state = { collapsed : this.props.collapsed};
    }

    toggleCollapsed() {
        console.log("Before toogle - Collapsed: "+this.state.collapsed);

        const collapsed = !this.state.collapsed

        this.setState({collapsed : collapsed});
        console.log("After toogle - Collapsed: "+this.state.collapsed);

        if (this.props.onToggle) {
            this.props.onToggle(collapsed)
        }
    }

    getContent() {
        if (this.state.collapsed) {
            return <div></div>
        } else {
            return (
                <div className="ibox-content">
                    {this.props.children}
                </div>
            )
        }
    }

    getIcon() {
        if (this.state.collapsed) {
            return "right"
        } else {
            return "down"
        }
    }

    render() {
        return (
            <div className={this.props.isSmall ? "card-box p-1 my-1" : "card-box"}>
                {this.props.isSmall
                    ? <div style={{fontSize: '0.95em'}} className="pointer pl-1 py-1" onClick={this.toggleCollapsed.bind(this)}>
                        <i className={"fa fa-chevron-" + this.getIcon()}></i> {this.props.title}</div>
                    : <h4 className="pointer" onClick={this.toggleCollapsed.bind(this)}><i
                        className={"fa fa-chevron-" + this.getIcon()}></i> {this.props.title}</h4>
                }
                {this.getContent()}
            </div>
        )
    }
}
