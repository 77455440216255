import React, {Fragment} from "react";
import {LoadingViewState, RouterView} from "../RouterView";
import {Form, FormListener} from "../form/Form";
import {getContainer} from "../../ioc/IOCSetup";
import {KycStatus, PermissionCategory, PushMessage} from "../../http/protocol";
import {RouteComponentProps, withRouter} from "react-router";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Validators} from "../form/Validators";
import {Submit} from "../form/Submit";
import {NotificationsService} from "../../services/NotificationsService";
import {SelectInput} from "../form/SelectInput";
import {t} from "../../i18n";
import { PermissionRequired } from "../security/PermissionRequired";


interface Props extends RouteComponentProps {

}

interface State extends LoadingViewState{
    message?:PushMessage;
    validationMap?:any;
    userId?:number;
    broadcast?:boolean;
    subCount?:number
}

export class NotificationsView extends RouterView<Props,State> implements FormListener<PushMessage>{
    service:NotificationsService;

    URLS = ['poker', 'casino', 'sportsbook'];

    constructor(props:Props) {
        super(props);
        let dateNow = Date.now();
        this.service = getContainer().getNotificationsService();
        this.state = { message : {timeToLive:(60*60*24), icon: "https://", badge: "https://"}, validationMap : {}, loading : true, broadcast:false, userId:undefined, subCount: 0};
    }

    loadContent(): Promise<any> | void {
        //get badge and icon from localstorage
        this.service.getSubCount().then((res) => {
            if(res) {
                this.setState({subCount:res});
            }
        });
        let localBadge = localStorage.getItem('pushBadgeURL');
        let localIcon = localStorage.getItem('pushIconURL');
        let initialMessage = this.state.message ? this.state.message : {};
        initialMessage.badge = localBadge != null ? localBadge.toString() : "https://";
        initialMessage.icon = localIcon != null ? localIcon.toString() : "https://";
        this.setState({message:initialMessage});

        return undefined;
    }

    onSubmitError(){}

    onSubmit() {
        //Save badge and icon to localstorage.
        if(this.state.message && this.state.message.badge) {
            localStorage.setItem('pushBadgeURL', this.state.message.badge);
        }
        if(this.state.message && this.state.message.icon) {
            localStorage.setItem('pushIconURL', this.state.message.icon);
        }

        if(this.state.message !=undefined) {
            if(this.state.broadcast) {
                return this.service.notifyAllUsers(this.state.message).then((res) => {
                    console.log("Notify all users response: ", res);
                })
            }
            let userId = this.state.userId;
            if(userId !== undefined && userId.toString() !== "" && !this.state.broadcast) {
                return this.service.notifyUser(userId, this.state.message).then((res) => {
                    console.log("Notify user response: ", res);
                })
            }
        }
    }

    formDidUpdate(formModel:PushMessage, validationMap : any) {
        this.setState({ message : formModel, validationMap : validationMap});
    }

    onUserIdChange(e:any) {
        this.setState({userId:e.target.value});
    }

    onBroadcastChange(e:any) {
        console.log(this.state.message)
        this.setState({broadcast:!this.state.broadcast})
    }


    getTitle() : string {
        return t(`usersManagement.sendNotification.pageTitle`);
    }

    renderContent() {
        return (
            <PermissionRequired permissions={[PermissionCategory.NOTIFICATIONS]} accessLevel="r" showError={true}>

            <Fragment>
                <div className={"card"}>
                    <div className="card-body">
                        {t(`usersManagement.sendNotification.numberSubscribed`)} {this.state.subCount}
                    </div>
                </div>
                <Form className="form-horizontal" formListener={this} modelObject={this.state.message} validationMap={this.state.validationMap}>
                    <div className={"card"}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <FormSubmitError />
                                    <FormSubmitSuccess text="Message was sent successfully"/>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <FormGroup className="form-group mb-3" model="title">
                                                <label>{t(`label.title`)}</label>
                                                <FormInput className="form-control" model="title" type="text" validators={[Validators.required()]}/>
                                                <span className={"help-block"}>{t(`helpText.title`)}</span>
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-8">
                                            <FormGroup className="form-group mb-3" model="body">
                                                <label>{t(`label.message`)}</label>
                                                <FormInput className="form-control" model="body" type="text" validators={[Validators.required()]}/>
                                                <span className={"help-block"}></span>
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <FormGroup className="form-group mb-3" model="badge">
                                                <label>{t(`label.badge`)}</label>
                                                <FormInput className="form-control" model="badge" type="text" validators={[Validators.required()]}/>
                                                <span className={"help-block"}>{t(`helpText.badge`)}</span>
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-6">
                                            <FormGroup className="form-group mb-3" model="icon">
                                                <label>{t(`label.icon`)}</label>
                                                <FormInput className="form-control" model="icon" type="text"/>
                                                <span className={"help-block"}>{t(`helpText.icon`)}</span>
                                            </FormGroup>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <FormGroup className="form-group">
                                                <label>{t(`label.redirectOnOpen`)}</label>
                                                <SelectInput className="form-control" model="data.url" size={this.URLS.length} multiple={false}
                                                             values={this.URLS}
                                                             optional={false}>
                                                </SelectInput>
                                                <span className={"help-text"}>{t(`helpText.redirectOnOpen`)}</span>
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-6">
                                            <FormGroup className="form-group mb-3" model="timeToLive">
                                                <label>{t(`label.timeToLive`)}</label>
                                                <FormInput className="form-control" model="timeToLive" type="number" validators={[Validators.required()]}/>
                                                <span className={"help-block"}>{t(`helpText.timeToLive`)}</span>
                                            </FormGroup>
                                            <div className="custom-control custom-checkbox" >
                                                <FormInput model="silent" id="silent" className={"custom-control-input fm-blocked"} type="checkbox" />
                                                <label className={"custom-control-label"} htmlFor={"silent"}>
                                                    {t(`label.messageShouldSilent`)}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"card"}>
                        <div className={"card-body"}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="ibox float-e-margins">
                                        <div className="ibox-content">
                                            <div className="ibox-title">
                                                <h5>{t(`title.recipient`)}</h5>
                                            </div>
                                            <div className="form-group">
                                                <label>{t(`label.userId`)}</label>
                                                <input className="form-control" type="number" value={this.state.userId} onChange={(e) => this.onUserIdChange(e)}/>
                                            </div>
                                            <div className="custom-control custom-checkbox" >
                                                <input id="broadcast" className={"custom-control-input fm-blocked"} type="checkbox" checked={this.state.broadcast} onClick={(e) => this.onBroadcastChange(e)}/>
                                                <label className={"custom-control-label"} htmlFor={"broadcast"}>
                                                    {t(`label.sendMessageToAll`)}
                                                </label>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"card"}>
                        <div className={"card-body"}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="ibox float-e-margins">
                                        <div className="ibox-content">
                                            <div className="ibox-title">
                                                <h5>{t(`title.actions`)}</h5>
                                            </div>
                                            <div className="form-group">
                                                <div className="col-sm-10 col-sm-offset-2">
                                                    <Submit className="btn btn-primary waves-effect waves-light" 
                                                        disabled={!this.state.broadcast && (this.state.userId === undefined || this.state.userId.toString() === "")}>{t(`button.sendNotification`)}</Submit>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Form>
            </Fragment>
            </PermissionRequired>
        )
    }

}

export default withRouter(NotificationsView);