import * as React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

interface MonthlyData {
  data: Array<{ month: string; value: number }>;
  title: string;
}

export interface MetricHighChartProps {
  data: MonthlyData[];
  header: string;
  containerClassName?: string;
}

interface State {
  sum?: number;
  config?: any;
}

export class MetricHighChart extends React.Component<
  MetricHighChartProps,
  State
> {
  chartOptions: any;
  constructor(props: MetricHighChartProps) {
    super(props);
    this.state = { sum: 0 };
  }

  componentDidMount(): void {
    if (this.props.data.length > 0) {
      this.updateChart(this.props);
    }
  }

  componentDidUpdate(
    prevProps: Readonly<MetricHighChartProps>,
    nextContext: any
  ): void {
    if (this.props != prevProps) {
      this.updateChart(this.props);
    }
  }

  async updateChart(propsData: MetricHighChartProps) {
    let sum = 0;

    if (this.props.data) {
      let series = [];

      // Loop through all operators in the data-set
      for (let i = 0; i < propsData.data.length; i++) {
        let data = propsData.data[i] && propsData.data[i].data;

        let dataArray = [];
        if (data) {
          for (let j = 0; j < data.length; j++) {
            if (data[j]) {
              sum += Number(data[j].value);
              let point = {
                x: new Date(data[j].month),
                y: data[j].value,
                currency: ":val €",
                name: "Month:" + data[j].month,
              };
              dataArray.push(point);
            }
          }
        }

        let plot = {
          data: dataArray,
          name: propsData.data[i] && propsData.data[i].title,
        };

        series.push(plot);
      }

      let options = {
        series: series,
        chart: {
          type: "column",
          height: 280
        },
        credits: {
          enabled: false,
        },

        yAxis: {
          type: "currency",
          title: {
            text: "(€) Euro",
          },
          allowDecimals: true
        },
        xAxis: {
          type: "datetime",
        },
        legend: {
          align: "right",
          verticalAlign: "top",
          layout: "vertical",
          x: 0,
          y: 100,
        },
        dataLabels: {
          enabled: true,
          format: "€{point.y:.0f}",
        },
        tooltip: {
          pointFormat: "Commission: <b>€ {point.y}</b><br>",
        },
        
        title: {
          text: this.props.header
        },
      };

      sum = Math.round(sum * 100) / 100;
      if (sum > 1000) {
        sum = Math.round(sum);
      }
      this.setState({ config: options });
    }
  }

  render() {
    if (!this.state.config) {
      return <div>Loading...</div>;
    }
    return (
      <div
        className={
          this.props.containerClassName
            ? this.props.containerClassName
            : "col-md-6 col-xl-12"
        }
      >
        <div className="widget-rounded-circle card-box">
          <HighchartsReact
            highcharts={Highcharts}
            options={this.state.config}
          />
        </div>
      </div>
    );
  }
}
