import * as React from "react";

import {RouteComponentProps, withRouter} from "react-router";
import {LoadingViewState, RouterView} from "../RouterView";
import {CasinoGame, RewardStatus, RewardType, UserGameReward} from "../../http/protocol";
import {getContainer} from "../../ioc/IOCSetup";
import {SettingsService} from "../../services/SettingsService";
import {RewardsConfigService} from "../../services/RewardsConfigService";
import {CasinoGameService} from "../../services/CasinoGameService";
import {formatDate} from "../../util/date";
import {UserGameRewardService} from "../../services/UserGameRewardService";
import {NavLink} from "react-router-dom";
import {DeleteButtonModal} from "../../util/DeleteButtonModal";

interface State extends LoadingViewState {
    reward? : UserGameReward;
    casinoGame? : CasinoGame;
}

export class AssignRewardToUser extends RouterView<RouteComponentProps,State> {

    service : RewardsConfigService;
    userRewardService : UserGameRewardService;
    settingsService : SettingsService;
    gameService : CasinoGameService;

    constructor(props : RouteComponentProps){
        super(props);
        this.service = getContainer().getRewardsConfigService();
        this.settingsService = getContainer().getSettingsService();
        this.gameService = getContainer().getCasinoGameService();
        this.userRewardService = getContainer().getUserGameRewardService();
        this.state = {};
    }

    getTitle(): string {
        return "Show Reward";
    }

    componentDidUpdate(prevProps : RouteComponentProps, prevState : State) {
    }

    loadContent(): Promise<any>  {
        return this.userRewardService.getEntity((this.props.match.params as any).id).then( r => {
            this.setState({ reward : r});
            if(r.rewardTemplate?.rewardType == RewardType.freespins) {
                return this.gameService.getEntity(""+r.gameId).then(c => {
                    this.setState({casinoGame : c});
                })
            }

        })
    }


    remove(reward : UserGameReward) {
        return this.userRewardService.removeAward(""+reward.id).then(() => this.loadContent());
    }

    renderContent() {
        if(!this.state.reward || (!this.state.casinoGame &&  this.state.reward.rewardTemplate?.rewardType == RewardType.freespins)) {
            return <span/>
        }

        return (
            <div>
                <div className="wrapper wrapper-content animated fadeInRight">
                    <div className="form form-horizontal">
                        <div className="row">
                            <div className="col-lg-12">
                                <div style={{width : "100%", textAlign : "right", marginBottom : "10px"}}>
                                    {this.state.reward.status == RewardStatus.NEW && !this.state.reward.removed && ( <DeleteButtonModal className={"btn btn-danger"} label={"Remove"} description={"reward from user"} onDelete={()=> this.state.reward && this.remove(this.state.reward)}/>)}
                                </div>
                                {this.state.reward.removed &&    <div className={"alert alert-danger"}>This reward has been removed from use by admin</div>}
                                <div className="card-box">
                                    <div className="ibox-content">

                                        <div className="form-group">
                                            <label className="col-sm-2 control-label">Reward Template</label>
                                            <div className="col-sm-10">
                                               <div className={"form-control"}>
                                                   <NavLink to={"/rewards/config/" + this.state.reward.rewardTemplate?.id ?? ""}>{this.state.reward.rewardTemplate?.id} - {this.state.reward.rewardTemplate?.name}</NavLink>
                                               </div>
                                            </div>
                                        </div>
                                        <div className="hr-line-dashed"/>
                                        <div className="form-group">
                                            <label className="col-sm-2 control-label">User ID</label>
                                            <div className="col-sm-10">
                                               <div className={"form-control"}>
                                                   <NavLink to={"/user/"+this.state.reward.userId}>{this.state.reward.userId}</NavLink>
                                               </div>
                                            </div>
                                        </div>
                                        <div className="hr-line-dashed"/>
                                        <div className="form-group">
                                            <label className="col-sm-2 control-label">Currency</label>
                                            <div className="col-sm-10">
                                                <div className={"form-control"}>
                                                    {this.state.reward?.currencyCode}
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.reward.rewardTemplate?.rewardType == RewardType.freespins && (
                                            <>
                                                <div className="hr-line-dashed"/>
                                                <div className="form-group">
                                                    <label className="col-sm-2 control-label">Game</label>
                                                    <div className="col-sm-10">
                                                        <div className={"form-control"}>
                                                            <NavLink to={"/casino-game/"+this.state.reward.gameId}>{this.state.reward.gameId} - {this.state.casinoGame?.name}</NavLink>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="hr-line-dashed"/>
                                                <div className="form-group">
                                                    <label className="col-sm-2 control-label">Nr Of Freespins</label>
                                                    <div className="col-sm-10">
                                                        <div className={"form-control"}>
                                                            {this.state.reward.nrOfFreeSpins}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="hr-line-dashed"/>
                                                <div className="form-group">
                                                    <label className="col-sm-2 control-label">Casino Integration</label>
                                                    <div className="col-sm-10">
                                                        <div className={"form-control"}>
                                                            {this.state.reward.integration}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {this.state.reward.rewardTemplate?.rewardType == RewardType.cash && (
                                            <>
                                                <div className="hr-line-dashed"/>
                                                <div className="form-group">
                                                    <label className="col-sm-2 control-label">Amount</label>
                                                    <div className="col-sm-10">
                                                        <div className={"form-control"}>
                                                            {this.state.reward.amount}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        <div className="hr-line-dashed"/>
                                        <div className="form-group">
                                            <label className="col-sm-2 control-label">Status</label>
                                            <div className="col-sm-10">
                                                <div className={"form-control"}>
                                                    {this.state.reward.status}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="hr-line-dashed"/>
                                        <div className="form-group">
                                            <label className="col-sm-2 control-label">Claimed</label>
                                            <div className="col-sm-10">
                                                <div className={"form-control"}>
                                                    {formatDate(this.state.reward.claimTime)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="hr-line-dashed"/>
                                        <div className="form-group">
                                            <label className="col-sm-2 control-label">Expires</label>
                                            <div className="col-sm-10">
                                                <div className={"form-control"}>
                                                    {formatDate(this.state.reward.expires)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="hr-line-dashed"/>
                                        <div className="form-group">
                                            <label className="col-sm-2 control-label">Claim Time</label>
                                            <div className="col-sm-10">
                                                <div className={"form-control"}>
                                                    {formatDate(this.state.reward.claimTime)}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="hr-line-dashed"/>
                                        {this.state.reward.providerResponse && (
                                            <>
                                                <h3>Provider Response</h3>
                                                <div className="form-group">
                                                    <label className="col-sm-2 control-label">Error Cod</label>
                                                    <div className="col-sm-10">
                                                        <div className={"form-control"}>
                                                            {this.state.reward.providerResponse.errorCode}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="hr-line-dashed"/>
                                                <div className="form-group">
                                                    <label className="col-sm-2 control-label">Message</label>
                                                    <div className="col-sm-10">
                                                        <div className={"form-control"}>
                                                            {this.state.reward.providerResponse.message}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="hr-line-dashed"/>
                                                <div className="form-group">
                                                    <label className="col-sm-2 control-label">Reference Code</label>
                                                    <div className="col-sm-10">
                                                        <div className={"form-control"}>
                                                            {this.state.reward.providerResponse.referenceCode}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="hr-line-dashed"/>
                                            </>
                                        )}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default withRouter(AssignRewardToUser);