import { useEffect, useRef, useState } from "react";
import { CasinoPage, MenuItem, MenuItemType, SubMenuTab } from "../../http/protocol";
import _ from "lodash";
import DOMPurify from "dompurify";

export function useDragAndDrop<T>(menuList: T[], setMenuList: (menuOrder: T[]) => void) {
    const [draggedItem, setDraggedItem] = useState<any>();

    function onDragEnd(event: React.DragEvent<HTMLDivElement>) {
        // Prevent the default behavior to disable the "snap-back" effect
        event.preventDefault();
        setDraggedItem(undefined);
    }

    function onDragOver(event: React.DragEvent<HTMLLIElement>, index: number) {
        event.preventDefault();
        const draggedOverItem = menuList[index];
        // if the item is dragged over itself, ignore
        if (draggedItem === draggedOverItem) return;
        // filter out the currently dragged item
        let placedItems = menuList.filter((menuItem) => menuItem !== draggedItem);
        // add the dragged item after the dragged over item
        placedItems.splice(index, 0, draggedItem);
        setMenuList(placedItems);
    }

    function onDragStart(event: React.DragEvent<HTMLDivElement>, index: number) {
        setDraggedItem(menuList[index]);
        event.dataTransfer.effectAllowed = "move";
        event.dataTransfer.setData("text/html", event.currentTarget.outerHTML);
        event.dataTransfer.setDragImage(event.currentTarget, 20, 20);
    }

    return {
        onDragEnd,
        menu: menuList,
        onDragOver,
        onDragStart,
        draggedItem,
    };
}

/* --------------------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------------- */
/**
 *
 * @returns return the current casino page at index position 0, to be used in list.
 */
export const movePageToIndex = (pages: CasinoPage[], id: number): CasinoPage[] => {
    const index = pages.findIndex((element) => element.id === id);

    if (index !== -1) {
        const elementToMove = pages.splice(index, 1)[0];
        pages.unshift(elementToMove);
    }

    return pages;
};

/**
 *
 * @returns page and tabs from casino lobby or from saved menu settings. Decides if page exist, append at first index position from fetch list.
 */
export const populateMenuSettings = async (item: MenuItem, pages: CasinoPage[]) => {
    if (!_.isUndefined(item) && !_.isUndefined(pages)) {
        if (!_.isUndefined(item.subMenu) && !_.isUndefined(item.subMenu?.id) && !_.isUndefined(item.subMenu?.tabs)) {
            let pagelist = movePageToIndex(pages, item.subMenu.id);
            if (item.subMenu.tabs && item.subMenu.tabs.length) {
                pagelist.push();
                pagelist[0].tabs = [...item.subMenu.tabs];
                pagelist[0].name = item.subMenu.name;
            }
            return pagelist;
        } else {
            return pages;
        }
    }
};

type Callback = () => void;

export const useClickOutside = (callback: Callback) => {
    const ref = useRef<any>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback();
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [callback]);

    return ref;
};

export const IconNames: string[] = ["tag-roulette", "tag-arcade_games", "tag-baccarat", "tag-black_jack", "tag-jackpot", "tag-live_casino", "tag-megaways", "tag-provably_fair", "tag-slots", "tag-table_games", "tag-video_poker", "tag-virtual_sports", "tag-buy_feature", "tag-baccarat-2", "tag-ball", "tag-basket", "tag-bet", "tag-fire", "tag-live", "tag-lobby", "tag-play", "tag-present", "tag-provider", "tag-rocket", "tag-shake", "tag-shuttle", "tag-space", "tag-splash", "tag-target", "tag-trof"];

export const resolvePendingStatus = async (callback: any, time: number, type: string | AsyncStatus) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(callback(type));
        }, time);
    });
};

export enum AsyncStatus {
    idle,
    success,
    pending,
    fail,
}

export function addUniqueId(prevArray: SubMenuTab[]) {
    let newId: any;
    do {
        newId = Math.floor(Math.random() * 1000000);
    } while (prevArray.some((item) => item.id === newId));
    return newId;
}

export const setType = (submenu: SubMenuTab) => {
    if (submenu.type == undefined) {
        return MenuItemType.CASINO;
    } else if (submenu.type == MenuItemType.CUSTOM) {
        return submenu.type;
    } else if (submenu.type == MenuItemType.CASINO) {
        return submenu.type;
    }
};

const isLinkInjected = () => {
    const iconLink = document.getElementById("icon-pack");
    if(iconLink != null){
        return false;
    } else {
        return true;
    }
}


/* export async function addStylesheet(href: string, onload?: () => void, onerror?: () => void) {
    href = DOMPurify.sanitize(href);

    if(!isLinkInjected()){
        console.log("Css link is already injected.")
        return;
    }

    if (!isValidUrl(href)) {
        console.error("Invalid URL provided for stylesheet.");
        return;
    }
    

    let css: HTMLLinkElement = document.createElement("link");
    css.href = href;
    css.rel = "stylesheet";
    css.id = "icon-pack";
    css.media = "all";
    if (onload) {
        css.onload = () => {   
            onload();
        };
    }
    if (onerror) {
        css.onerror = () => {
            onerror();
        };
    }
    try {
        if (css.href.length > 0) {
        }
        // Get the first child node of the head element
        const firstHeadChild = document.head.lastElementChild
        // Insert the new link element before the first child node of the head
        document.head.insertBefore(css, firstHeadChild);
    } catch (e) {}
} */
export async function addStylesheet(href: string, onload?: () => void, onerror?: () => void) {
    href = DOMPurify.sanitize(href);

    if (!isLinkInjected()) {
        console.log("Css link is already injected.")
        return;
    }

    if (!isValidUrl(href)) {
        console.error("Invalid URL provided for stylesheet.");
        return;
    }

    let css: HTMLLinkElement = document.createElement("link");
    css.href = href;
    css.rel = "stylesheet";
    css.id = "icon-pack";
    css.media = "all";
    if (onload) {
        css.onload = () => {
            onload();
        };
    }
    if (onerror) {
        css.onerror = () => {
            onerror();
        };
    }
    try {
        document.head.appendChild(css);
    } catch (e) {}
}

export const extractClassnames = (css: string): any => {
    const cssContent = css;
    const classNames = cssContent.match(/\.custom-icon[\w-]*/g);

    let names: string[] = [];

    if (classNames) {
        classNames.forEach((className: string) => {
            names.push(className.replace(".", ""));
        });
    }

    return names;
};

export function isValidUrl(url: string) {
    try {
        new URL(url);
        return true;
    } catch (error) {
        return false;
    }
}

export const validateContentType2 = async (url: string) => {
    fetch(url).then((response) => {
        const contentType = response.headers.get("content-type");

        if (!contentType || !contentType.includes("text/css")) {
            throw new Error("Invalid content type. Expected text/css.");
        }

        return response.text();
    });
};

export const validateContentType = async (url: string) => {
    try {
        const response = await fetch(url);
        const contentType = response.headers.get("content-type");

        if (!contentType || !contentType.includes("text/css")) {
            throw new Error("Invalid content type. Expected text/css.");
        }

        return response.text();
    } catch (error: any) {
        throw new Error("Error validating content type: " + error.message);
    }
};
