import * as React from "react";
import {Fragment} from "react";
import {SettingsService} from "../../services/SettingsService";
import {getContainer} from "../../ioc/IOCSetup";
import {CardBox, LoadingViewState, RouterView} from "../RouterView";
import {FieldSetListener, Form, FormListener, SimpleFieldSet} from "../form/Form";
import {RouteComponentProps, withRouter} from "react-router";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {FormGroup} from "../form/FormGroup";
import {FormInput, FormMap} from "../form/FormInput";
import {Submit} from "../form/Submit";
import {Menu, MenuItem, MenuItemType, Settings, SportsbookConfig, SportsbookProvider, SportsbookProviderOption, SubMenu} from "../../http/protocol";
import "./SettingsGeneralView.scss";
import {Modal} from "react-bootstrap";
import {SelectInput} from "../form/SelectInput";
import {Case, Switch} from "../form/Switch";
import {Validators} from "../form/Validators";
import {Link} from "react-router-dom";
import SportsbookSettings from "./SportsbookSettings";




interface Props extends RouteComponentProps<{id?: string, copyFrom? : string}> {

}

interface State extends LoadingViewState{
    settings?: Settings;
    validationMap? : any;
    menuOrder: MenuItem[];
    editMenuIndex? : number;
    showEditSportsbookConfig: boolean;
}

export class SettingsGeneralView extends RouterView<Props,State> implements FormListener<Settings>{

    settingsService: SettingsService;
    defaultMenuOrder: MenuItem[];
    draggedItem:any;
    draggedIdx:any;

    constructor(props:Props) {
        super(props);

        this.settingsService = getContainer().getSettingsService();
        this.defaultMenuOrder = [{ type : MenuItemType.POKER},{ type : MenuItemType.CASINO}, { type : MenuItemType.SPORTSBOOK}, { type : MenuItemType.RACING}]
        this.draggedItem = "";
        this.draggedIdx = null;
        this.showEditSportsbookConfig = this.showEditSportsbookConfig.bind(this);
        this.saveProviderConfig = this.saveProviderConfig.bind(this);
        this.state = {validationMap : {}, loading : true, menuOrder: [] , editMenuIndex : -1, showEditSportsbookConfig: false};
    }

    loadContent(): Promise<any> | undefined {
        if(!this.props.match?.params?.id || this.props.match?.params?.id == "new") {
            if(this.props.match.params?.copyFrom) {
                return this.settingsService.getEntity(this.props.match.params.copyFrom).then(data => {
                    data.id = undefined;
                    data.racingEnabled = false;
                    let menu : Menu | undefined = data.menu;
                    if(menu &&  menu.items) {
                        this.setState({ settings:data, menuOrder : menu.items });
                    } else {
                        this.setState( { settings:data, menuOrder : this.defaultMenuOrder});
                    }
                })
            }
            return Promise.resolve({});
        } else {
            return this.settingsService.getEntity(this.props.match.params.id).then((data : Settings) => {
                data.racingEnabled = false;
                let menu : Menu | undefined = data.menu;
                if(menu &&  menu.items) {
                    this.setState({ settings:data, menuOrder : menu.items });
                } else {
                    this.setState( { settings:data, menuOrder : this.defaultMenuOrder});
                }
            });
        }

    }

    componentDidUpdate(prevProps : RouteComponentProps, prevState : any) {
        super.componentDidUpdate(prevProps, prevState);

    }
    formDidUpdate(formModel:Settings, validationMap : any) {
        this.setState({ settings : formModel, validationMap : validationMap});
    }

    onSubmit():Promise<any> {
        if(!this.state.settings) {
            return Promise.reject("Unable to save settings");
        }
        let settings = this.state.settings;
        if(settings.menu) {
            settings.menu.items = this.state.menuOrder;
        } else {
            settings.menu = {};
            settings.menu.items = this.state.menuOrder;
        }

        return this.settingsService.saveSettings(settings).catch(err=>{
            throw "Unable to create/save settings";
        });
    }
    onDragStart = (e:any, index:any) => {
        this.draggedItem = this.state.menuOrder[index];
        e.dataTransfer.effectAllowed = "move";
        e.dataTransfer.setData("text/html", e.target.parentNode);
        e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
    };

    onDragOver = (index:any )=> {
        const draggedOverItem = this.state.menuOrder[index];

        // if the item is dragged over itself, ignore
        if (this.draggedItem === draggedOverItem) {
            return;
        }

        // filter out the currently dragged item
        let items = this.state.menuOrder.filter(menuItem => menuItem !== this.draggedItem);

        // add the dragged item after the dragged over item
        items.splice(index, 0, this.draggedItem);

        this.setState({ menuOrder:items });
    };

    onDragEnd = () => {
        this.draggedIdx = null;
    };
    edit(index : number) {
        this.setState({ editMenuIndex : index });
    }
    save(item : MenuItem) {
        if(typeof(this.state.editMenuIndex) != "undefined") {
            let menuOrder =  this.state.menuOrder.concat();
            menuOrder[this.state.editMenuIndex] = item;
            let settings = Object.assign({}, this.state.settings, { menu : { items : menuOrder }});
            this.setState({ editMenuIndex : -1, menuOrder : menuOrder, settings : settings});
        }

    }
    remove(){
        let menuOrder = this.state.menuOrder.concat();
        if(typeof(this.state.editMenuIndex) != "undefined") {
            menuOrder.splice(this.state.editMenuIndex, 1);
        }
        let settings = Object.assign({}, this.state.settings, { menu : { items : menuOrder }});
        this.setState({ settings : settings, editMenuIndex : -1, menuOrder : menuOrder});
    }
    add() {
        this.setState({ editMenuIndex : this.state.menuOrder.length});
    }
    cancel() {
        this.setState({editMenuIndex : -1});
    }


    showEditSportsbookConfig(){
        this.setState({showEditSportsbookConfig: !this.state.showEditSportsbookConfig})
    }

    saveProviderConfig(config: SportsbookConfig){
        this.setState({settings: {...this.state.settings, sportsbookConfig: config}})

    }

    onSubmitError(){}

    getTitle() : string {
        return "General Settings";
    }

    renderContent() {
        let sportsbookConfig: SportsbookConfig | undefined = {};
        if(this.state.settings){
            sportsbookConfig = this.state.settings.sportsbookConfig;
        }
            if(!this.state.settings) {
                return <span></span>
            }
        return(
            <Fragment>
                { this.state.settings.id && (
                    <CardBox>
                        <Link className="btn btn-primary" to={"/admin/settings/transferLimits/"+this.state.settings.id}>Transfer Limits</Link>
                    </CardBox>
                )}
                <Form className="form-horizontal" formListener={this} modelObject={this.state.settings} validationMap={this.state.validationMap}>
                    <div className={"card"}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <FormSubmitError/>
                                    <FormSubmitSuccess text="Settings was updated successfully"/>
                                    <FormGroup className="form-group mb-3" model="operatorName">
                                        <label>Brand Name</label>
                                        <FormInput className="form-control" model="operatorName" type="text"/>
                                        <span
                                            className={"help-block"}>The brand name of the site, e.g. 'Nano Casino'</span>
                                    </FormGroup>
                                    <FormGroup className="form-group mb-3" model="operatorIconUrl">
                                        <label>Brand Icon Logo</label>
                                        <FormInput className="form-control" model="operatorIconUrl" type="text"/>
                                        <span className={"help-block"}>Icon with your logo. Recommended size: 96 x 96px. Full URL, HTTPS required.</span>
                                    </FormGroup>
                                    <FormGroup className="form-group mb-3" model="title">
                                        <label>Title</label>
                                        <FormInput className="form-control" model="title" type="text"/>
                                        <span className={"help-block"}>Set title of casino frontend</span>
                                    </FormGroup>

                                    <FormGroup className="form-group mb-3" model="cssOverrideUrl">
                                        <label>CSS Override Url</label>
                                        <FormInput className="form-control" model="cssOverrideUrl" type="text"/>
                                        <span className={"help-block"}>Set CSS Override</span>
                                    </FormGroup>

                                    <FormGroup className="form-group mb-3" model="contenfulSpaceId">
                                        <label>Contenful Space Id</label>
                                        <FormInput className="form-control" model="contenfulSpaceId" type="text"/>
                                        <span className={"help-block"}>Contenful Space Id - This can be found at <a
                                            href={"https://app.contentful.com"}
                                            target="_blank">https://app.contentful.com</a></span>
                                    </FormGroup>

                                    <FormGroup className="form-group mb-3" model="contentfulAccessToken">
                                        <label>Contentful Access Token</label>
                                        <FormInput className="form-control" model="contentfulAccessToken" type="text"/>
                                        <span className={"help-block"}>Contentful Access Token - This can be found at <a
                                            href={"https://app.contentful.com"}
                                            target="_blank">https://app.contentful.com</a></span>
                                    </FormGroup>
                                    <FormGroup className="form-group mb-3" model="contentfulAccessToken">
                                        <label>Strapi API URL</label>
                                        <FormInput className="form-control" model="strapiApiUrl" type="text"/>
                                        <span className={"help-block"}>Where the strapi API server is located. If specified it will use strapi instead of contentful, example: https://cms.neobet.io/api</span>
                                    </FormGroup>

                                    <FormGroup className="form-group mb-3" model="acquisitionScript">
                                        <label>Acquisition Script</label>
                                        <FormInput className="form-control" model="acquisitionScript" type="textarea"/>
                                        <span className={"help-block"}>Add script urls that loads when the user has succesfully registered a new user account. ex. https://testscript.nano.com NOTE: One script per row</span>
                                    </FormGroup>

                                    <FormGroup className="form-group mb-3" model="customScripts">
                                        <label>Custom Scripts/CSS/Fonts</label>
                                        <FormInput style={{height: "200px"}} className="form-control"
                                                   model="customScripts" type="textarea"/>
                                        <span className={"help-block"}>Add script/css urls that loads one time when the page is loaded. ex.<br/>
                                        script:https://testscript.nano.com<br/>
                                        font:https://fonts.googleapis.com/css?family=Rubik<br/>
                                        css:https://cubeia.com/custom-css.css
                                        </span>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>
                                    <FormGroup className="form-group mb-3" model="termsVersion">
                                        <label>Terms & Conditions Version</label>
                                        <FormInput className="form-control" model="termsVersion" type="text"/>
                                        <span className={"help-block"}>Terms & Conditions version string. Can be anything. Will trigger a terms accept form if this differns from the user's accepted version.
                                            Empty to turn off terms version accept procedure.
                                            Examples: <code>v1.1</code>, <code>2022-01-01</code>
                                        </span>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>
                                    <FormGroup className="form-group mb-3" model="loginRedirectUrl">
                                        <label>Login Redirect</label>
                                        <FormInput className="form-control" model="loginRedirectUrl" type="text"/>
                                        <span className={"help-block"}>Path to redirect after successful login. ex. /poker, /poker/play (direct to poker client), /casino, /sportsbook etc.</span>
                                    </FormGroup>
                                    <div className={"form-group form-check p-0"}>
                                        <label>Canonical Base Url</label>
                                        <FormInput className={"form-control"} type={"text"} model={"canonicalBaseUrl"}/>
                                        <span className={"help-block"}>Optional url that will be used as canonical base url for all pages</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <label>Products </label>
                                    <span className={"help-block"}>&nbsp; - Toggle products on and off</span>
                                    <FormGroup className="custom-control custom-checkbox" model="casinoEnabled">
                                        <FormInput className="custom-control-input"  id={"casinoEnabled"} model="casinoEnabled" type="checkbox"/>
                                        <label htmlFor={"casinoEnabled"} className={"custom-control-label"}>Casino</label>
                                        <span className=""></span>
                                    </FormGroup>
                                    <FormGroup className="custom-control custom-checkbox" model="pokerEnabled">
                                        <FormInput className="custom-control-input" model="pokerEnabled" id={"pokerEnabled"} type="checkbox"/>
                                        <label htmlFor={"pokerEnabled"} className={"custom-control-label"}>Poker</label>
                                    </FormGroup>
                                    <FormGroup className="custom-control custom-checkbox" model="sportsbookEnabled">
                                        <FormInput className="custom-control-input" model="sportsbookEnabled" id={"sportsbookEnabled"} type="checkbox"/>
                                        <label htmlFor={"sportsbookEnabled"} className={"custom-control-label"}>Sportsbook</label>
                                    </FormGroup>

                                    <FormGroup className="custom-control custom-checkbox" model="racingEnabled">
                                        <FormInput className="custom-control-input" model="racingEnabled" id={"racingEnabled"} type="checkbox"
                                        checked={false} 
                                        disabled={true} />
                                        <label htmlFor={"racingEnabled"} className={"custom-control-label"}>Racing</label>
                                        <span className="racing-deprecated-text">
                                             - This option is deprecated. All racing bets will be shown under the Sportsbook tab.
                                        </span>
                                    </FormGroup>

                                    <FormGroup className="custom-control custom-checkbox" model="bonusEnabled">
                                        <FormInput className="custom-control-input" model="bonusEnabled" id={"bonusEnabled"} type="checkbox"/>
                                        <label htmlFor={"bonusEnabled"} className={"custom-control-label"}>Bonuses</label>
                                    </FormGroup>

                                    <FormGroup className="custom-control custom-checkbox" model="vouchersEnabled">
                                        <FormInput className="custom-control-input" model="vouchersEnabled" id={"vouchersEnabled"} type="checkbox"/>
                                        <label htmlFor={"vouchersEnabled"} className={"custom-control-label"}>Vouchers</label>
                                    </FormGroup>
                                    <FormGroup className="form-group mb-3" model="sportsbookProvider">
                                        <label>Sportsbook Provider</label>
                                        <SelectInput className="form-control" model="sportsbookProvider" type="text" values={Object.keys(SportsbookProvider)}/>
                                        <span className={"help-block"}>Make sure you have valid integration credentials from the provider if you change this. Note - To use multiple providers, configre the providers in 
                                        <span style={{cursor: "pointer"}} onClick={()=>this.showEditSportsbookConfig()} className="text-primary"> Configure multiple providers</span>
                                        </span>
                                    </FormGroup>
                                    <button type="button" onClick={()=>this.showEditSportsbookConfig()} className="btn btn-primary">Configure multiple sportsbook</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <label>Currency</label>
                                    <FormGroup className="form-group mb-3" model="currencyOrder">
                                        <label>Currency Order</label>
                                        <FormInput className="form-control" model="currencyOrder" type="text"/>
                                        <span className={"help-block"}>Use the comma separated list to sort in which order currencies appear ex. EUR,BTC,SEK (note: no spaces)</span>
                                    </FormGroup>
                                    <FormGroup className="form-group mb-3" model="languageOrder">
                                        <label>Language Order</label>
                                        <FormInput className="form-control" model="languageOrder" type="text"/>
                                        <span className={"help-block"}>Use the comma separated list to sort in which order languages appear in the dropdown menu for the player ex. en,sv,fil,th. see ISO 639 Language Codes (note: no spaces)</span>
                                    </FormGroup>
                                    <FormGroup className="form-group mb-3" model="bundleBonusCurrency">
                                        <label>Bundle Bonus Currency</label>
                                        <FormInput className="form-control" model="bundleBonusCurrency" type="text"/>
                                        <span className={"help-block"}>Show "Free Bonus" instead of "Bundle Purchase" for this currency in the player transaction list</span>
                                    </FormGroup>

                                    <FormGroup className="form-group mb-3" model="forceWithdrawCurrency">
                                        <label>Force Withdraw Currency</label>
                                        <FormInput className="form-control" model="forceWithdrawCurrency" type="text"/>
                                        <span className={"help-block"}>If set then withdraw will always use this Currency and not the one currently selected. Used when you have a play money and real money currency.</span>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <label>User Interaction </label>
                                        <FormGroup className="custom-control custom-checkbox" model="useStoreInsteadOfDeposit">
                                            <FormInput className="custom-control-input" model="useStoreInsteadOfDeposit" id={"useStoreInsteadOfDeposit"} type="checkbox"/>
                                            <label htmlFor={"useStoreInsteadOfDeposit"} className={"custom-control-label"}>Use Store Instead of Deposit</label><br/>
                                            <span className={"help-block"}>Show the bundles purchases instead of regular deposit dialog. Disables deposits.</span>
                                        </FormGroup>
                                        <FormGroup className="custom-control custom-checkbox" model="showCurrencySelectOnSignup">
                                            <FormInput className="custom-control-input" model="showCurrencySelectOnSignup" id={"showCurrencySelectOnSignup"} type="checkbox"/>
                                            <label htmlFor={"showCurrencySelectOnSignup"} className={"custom-control-label"}>Show Currency Select on Signup</label><br/>
                                            <span className={"help-block"}>Shows or hides the drop down where a user selects currency for deposit in the signup flow</span>
                                        </FormGroup>
                                        <FormGroup className="custom-control custom-checkbox" model="useInfoTextSignup">
                                            <FormInput className="custom-control-input" model="useInfoTextSignup" id={"useInfoTextSignup"} type="checkbox"/>
                                            <label htmlFor={"useInfoTextSignup"} className={"custom-control-label"}>Use Specific Info Text Terms on Registration</label><br/>
                                            <span className={"help-block"}>Specific terms that needs to be accepted before registering a new account</span>
                                        </FormGroup>
                                        <FormGroup className="custom-control custom-checkbox" model="showUserLevel">
                                            <FormInput className="custom-control-input" model="showUserLevel" id={"showUserLevel"} type="checkbox"/>
                                            <label htmlFor={"showUserLevel"} className={"custom-control-label"}>Show User Levels</label><br/>
                                            <span className={"help-block"}>Show wager XP & Level. Don't forget to enable tracking in Internal Settings.</span>
                                        </FormGroup>
                                        <FormGroup className="custom-control custom-checkbox" model="tippingEnabled">
                                            <FormInput className="custom-control-input" model="tippingEnabled" id={"tippingEnabled"} type="checkbox"/>
                                            <label htmlFor={"tippingEnabled"} className={"custom-control-label"}>Enable Player Tipping</label><br/>
                                            <span className={"help-block"}>If enabled, Players can send transactions between each other using the "tip" feature</span>
                                        </FormGroup>
                                        <FormGroup className="custom-control custom-checkbox" model="startPokerOnOpen">
                                            <FormInput className="custom-control-input" model="startPokerOnOpen" id={"startPokerOnOpen"} type="checkbox"/>
                                            <label htmlFor={"startPokerOnOpen"} className={"custom-control-label"}>Start Poker On Open</label><br/>
                                            <span className={"help-block"}>Toggle whether navigation to the poker page should launch the product immediately or not.</span>
                                        </FormGroup>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className={"card"}>
                        <div className={"card-body"}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="ibox float-e-margins">
                                        <div className="ibox-content">
                                            <div className="ibox-title">
                                                <h5>Actions</h5>
                                            </div>
                                            <div className="form-group">
                                                <div className="col-sm-10 col-sm-offset-2">
                                                    <Submit className="btn btn-primary waves-effect waves-light">Save Settings</Submit>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
                <SportsbookSettings config={sportsbookConfig as any} setShow={this.showEditSportsbookConfig} show={this.state.showEditSportsbookConfig} onSave={this.saveProviderConfig}/>
            </Fragment>
        )
    }

}
interface EditMenuItemState {
    form? : MenuItem;
    validationMap? : any;
}
interface EditMenuItemProps{
    item : MenuItem,
    cancel : ()=> void
    onSave : (item : MenuItem)=>void;
    onDelete : () => void;
}
class EditMenuItem extends React.Component<EditMenuItemProps, EditMenuItemState> implements FieldSetListener<MenuItem> {

    constructor(props : EditMenuItemProps) {
        super(props);
        let form = Object.assign({}, props.item);
        if(!form.translations) {
            form.translations = {};
        }
        this.state = { form : form, validationMap : {}};
    }


    removeTranslation(lang? : string) {
        if(lang && this.state.form && this.state.form.translations) {
            let trans = Object.assign({}, this.state.form.translations);
            delete trans[lang];
            this.setState({ form : Object.assign({}, this.state.form, { translations : trans})})
        }
    }
    addTranslation(lang : string, name : string) {
        if(this.state.form && this.state.form.translations) {
            let trans = Object.assign({}, this.state.form.translations);
            trans[lang] = name;
            this.setState({ form : Object.assign({}, this.state.form, { translations : trans})})
        }
    }
    save() {
        this.state.form && this.props.onSave(this.state.form);
    }
    
    saveSubmenu(submenu?: SubMenu){
        this.setState({form: {...this.state.form, subMenu: submenu}})
    }

    render() {
        if(!this.state.form){
           return <span/>
        }
        return (
            <Modal show={true}  size="lg" onHide={()=>this.props.cancel()}>
                <Modal.Body>
                    <h4 className="p-2">Edit Menu Item <a className={"btn btn-danger pull-right"} onClick={()=>this.props.onDelete()}>Remove</a></h4>
                    <div className={"form p-2"}>
                        <SimpleFieldSet formListener={this} modelObject={this.state.form}
                                        validationMap={this.state.validationMap}>
                            <FormGroup className={"form-group"}>
                                <label>Type</label>
                                <SelectInput className={"form-control"} model={"type"}
                                             values={[MenuItemType.POKER, MenuItemType.CASINO, MenuItemType.SPORTSBOOK, MenuItemType.RACING, MenuItemType.CUSTOM]}/>
                                <span className={"help-block"}>Select what type of menu item you want to add, you can either choose a predefined product menu item or CUSTOM to add any type of menu item. </span>
                            </FormGroup>
                            <div className={"form-group form-check p-0"}>

                                <label><FormInput type={"checkbox"} model={"includeInMobileMenu"}/> Include In Mobile
                                    Menu</label>
                                <span className={"help-block"}> - If you want this item to be included in the bottom menu on mobile</span>
                            </div>
                            <div className={"form-group form-check p-0"}>
                                <label><FormInput type={"checkbox"} model={"includeInDesktopMenu"}/> Include In Desktop
                                    Menu</label>
                                <span className={"help-block"}> - If you want this item to be included in the top menu on desktop</span>
                            </div>
                            <div className="hr-line-dashed"></div>
                            <Switch value={this.state.form.type}>
                                <Case value={[MenuItemType.CUSTOM]}>
                                    <FormGroup className={"form-group"}>
                                        <label>Name</label>
                                        <FormInput className={"form-control"} model={"name"}
                                                   validators={[Validators.required()]}/>
                                        <span className={"help-block"}>The default translation, displayed to the user, of the of the menu item if the specific translation does not exist</span>
                                    </FormGroup>
                                    <FormGroup className={"form-group"}>
                                        <label>Url</label>
                                        <FormInput className={"form-control"} model={"url"}
                                                   validators={[Validators.required()]}/>
                                        <span className={"help-block"}>The Menu items URL, if it starts with / it will be considered to be an internal link and language etc will be appended automatically. (eg /poker) <br/>
                                        If it's an external URL you can use <span
                                                dangerouslySetInnerHTML={{__html: "{languageCode}"}}></span> in the URL to get the current user language <br/>(eg https://test.com/?lang= <span
                                                dangerouslySetInnerHTML={{__html: "{languageCode}"}}></span></span>
                                    </FormGroup>

                                    <FormGroup className={"form-group"}>
                                        <label>Target</label>
                                        <FormInput className={"form-control"} model={"target"}/>
                                        <span className={"help-block"}>Link target, if you want to open the menu item in a new tab etc</span>
                                    </FormGroup>
                                    <FormMap meta={{title: "Translations", key: "Language", value: "Name"}}
                                             removeAttribute={(key) => this.removeTranslation(key)}
                                             addAttribute={(key, value) => this.addTranslation(key, value)}
                                             mapModel={"translations"}/>

                                </Case>
                            </Switch>
                            <div className={"form-group form-check p-0"}>
                                <label>Include for Countries</label>
                                <FormInput className={"form-control"} type={"text"} model={"includeCountries"}/>
                                <span className={"help-block"}>Comma separated list of country codes. Example (Germany & Sweden): DE, SE</span>
                            </div>
                            <div className={"form-group form-check p-0"}>
                                <label>Exclude for Countries</label>
                                <FormInput className={"form-control"} type={"text"} model={"excludeCountries"}/>
                                <span className={"help-block"}>Comma separated list of country codes. Example (Germany & Sweden): DE, SE</span>
                            </div>
                            <div className="d-flex " style={{marginTop: "10px", gap: "5px"}}>
                                <a className={"btn btn-primary"} onClick={() => this.props.cancel()}>Cancel</a>
                                <a className={"btn btn-success"} onClick={() => this.save()}>Save</a>
                            </div>
                        </SimpleFieldSet>
                    </div>

                </Modal.Body>
            </Modal>
        );
    }

    formDidUpdate(formModel: MenuItem, validationMap?: any): void {

        this.setState({form: formModel, validationMap: validationMap});
    }
}

export default withRouter(SettingsGeneralView);

