import React, {Fragment} from "react";
import {ActionType} from "../list/list/DataTable";
import {
    BonusConfig, BonusConfigListRequest, BonusStatus, BonusStatusAlert, BonusVisibilityStatus,
    CashbackConfigListRequest,
    ListCasinoLobbyCategoryRequest,
    ListChartsRequest, ListRequest,
    ListUsersRequest, TurnoverContributions,
    TurnoverContributionsListRequest,
    User
} from "../../http/protocol";
import {getContainer} from "../../ioc/IOCSetup";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {PagerModel} from "../list/list/PagerModel";
import {ListUtils} from "../list/list/ListUtils";
import {withRouter} from "react-router";
import {ListView, ListViewProps, ListViewState} from "../ListView";
import {UserService} from "../../services/UserService";
import {Form, FormListener} from "../form/Form";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Validators} from "../form/Validators";
import {Submit} from "../form/Submit";
import {ColumnType} from "../list/list/ColumnType";
import {CasinoCategoryService} from "../../services/CasinoCategoryService";
import {TurnoverContributionsService} from "../../services/TurnoverContributionsService";
import {BonusConfigService} from "../../services/BonusConfigService";
import {SelectInput} from "../form/SelectInput";
import {log} from "util";
import { CashbackConfigService } from "../../services/CashbackConfigService";




interface State extends ListViewState<TurnoverContributionsListRequest> {
    form? : BonusConfigListRequest;
    validationMap? : any;
}
interface Props extends ListViewProps  {

}

export class CashbackConfigListView extends ListView<State, CashbackConfigListRequest> implements FormListener<CashbackConfigListRequest>{

    columns = [
        {
            property: "status",
            name: "Status",
            renderer: (property: string, name: string, item: any) => {

                let backgroundColor = '';
                if (item["status"] === BonusStatus.ENABLED) {
                    backgroundColor = '#28A745';
                } else if (item["status"] === BonusStatus.ARCHIVED) {
                    backgroundColor = '#6C757D';
                } else if (item["status"] === BonusStatus.DISABLED) {
                    backgroundColor = '#F1556C';
                } 
                const backgroundColorCSS = {
                    backgroundColor: backgroundColor
                };

                return <span style={backgroundColorCSS} className={"dot"} title={item["bonusAlertDescription"]}/>
            }
        },
        { property : "id", name : "ID", orderBy : "id"},
        { property : "name", name : "Name"},
        { property:  "type" , name : "Type"},
        { property : "frequency", name : "Frequency"}
    ];

    actions = [
        { id : "delete", name : "delete", className : "btn btn-danger", iconClassName : "fa fa-times", type : ActionType.CONFIRMATION},
        { id : "view", name : "View", className : "btn btn-primary", iconClassName : "fa fa-user", type : ActionType.LINK, getLink : (item : any) => "/cashback/config/" + item.id }

    ];


    service : CashbackConfigService;

    constructor(props : Props){
        super(props, "/cashback/configs/list");
        this.state = {...this.state, validationMap : {}, form : {...this.state.listRequest } as any};
        if(!(this.state.listRequest as any).bonusVisibilityStatus) {
            let lr = {...this.state.listRequest, bonusVisibilityStatus : BonusVisibilityStatus.NON_ARCHIVED} as any;
            this.state = {...this.state, listRequest : lr, form : lr};
        }
        this.service = getContainer().getCashbackConfigService();
    }
    onSubmit() : void | Promise<any> {
        if(this.state.form) {
            this.updateListState(this.state.form);
        }
    }
    onSubmitError() :  void {

    }

    formDidUpdate(formModel: ListRequest, validationMap?: any): void {
        this.setState({form : formModel, validationMap : validationMap});
    }

    onAction(item : BonusConfig, action : string) : Promise<any> | undefined {
        if(action == "delete") {
            return  this.service.remove(""+item.id);
        }
    }

    pagerChanged(pager : PagerModel) {
        if(this.state.listRequest) {
            console.log("pager changed!", pager);
            let listRequest = ListUtils.toListRequest<any>(this.state.listRequest,pager);
            this.updateListState(listRequest);
        }
    }
    getTitle() {
        return "List Cashback Configurations";
    }

    renderContent() {
        if(!this.state.listRequest) {
            return <div/>;
        }
        return (
            <Fragment>
                <EntityDataTable service={this.service}
                                 onPagerChange={this.pagerChanged.bind(this)}
                                 columns={this.columns}
                                 listRequest={this.state.listRequest}
                                 onAction={this.onAction.bind(this)}
                                 actions={this.actions}/>
            </Fragment>
        );
    }



}

export default withRouter(CashbackConfigListView);
