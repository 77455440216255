import React, {Fragment} from "react";
import {AdminUserService} from "../../services/AdminUserService";
import {ActionType} from "../list/list/DataTable";
import {AdminUser, ListAdminUsersRequest, UserGroup} from "../../http/protocol";
import {getContainer} from "../../ioc/IOCSetup";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {PagerModel} from "../list/list/PagerModel";
import {ListUtils} from "../list/list/ListUtils";
import {withRouter} from "react-router";
import {createInitialState, ListView, ListViewProps, ListViewState} from "../ListView";
import { ColumnType } from "../list/list/ColumnType";
import { UserGroupService } from "../../services/UserGroupService";
import { Link } from "react-router-dom";
import {Form, FormListener} from "../form/Form";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Validators} from "../form/Validators";
import {Submit} from "../form/Submit";
import {t} from "../../i18n";
import {SelectInput} from "../form/SelectInput";

interface State extends ListViewState<ListAdminUsersRequest> {
    groups?: UserGroup[];
    listRequest? : ListAdminUsersRequest;
    form? : ListAdminUsersRequest;
    validationMap? : any;
    labels?: string[];
}
interface Props extends ListViewProps  {

}

const blockedComp = (item: AdminUser) => <><span className="text-danger">{item.blocked ? "Blocked" : ""}</span></>
//const groupsComp = (item: AdminUser) => <>{item.groups?.join(", ")}</>

// const createGroupsComp = ()
export type ColumnDefinition = { property : string, secondProperty?:string, name : string, renderer?: Renderer<any>, className? : string, orderBy? : string, type? : ColumnType}

export interface Renderer<t> {
    (property: string, name: string, item: any): t
}

export class AdminUserListView extends ListView<State, ListAdminUsersRequest> implements FormListener<ListAdminUsersRequest>{

    columns = [
        { property : "id", name : "ID", orderBy : "ID"},
        { property : "email", name : "E-mail", orderBy : "email"},
        { property : "firstName", name : "First Name" },
        { property : "lastName", name : "Last Name" },
        { property : "groups", name : "Groups", type: ColumnType.COMPONENT, component: (item: AdminUser) => <>{this.renderGroups(item)}</> },
        { property : "blocked", name: "Blocked", type: ColumnType.COMPONENT, component: blockedComp },
    ];

    actions = [
        { id : "view", name : "View", className : "btn btn-primary", iconClassName : "fa fa-eye", type : ActionType.LINK, getLink : (item : any) => "/admin/admin-user/" + item.id }
    ];

    adminDashboardUserService : AdminUserService;
    groupService : UserGroupService;

    constructor(props : Props){
        super(props, "/admin/admin-users");
        this.adminDashboardUserService = getContainer().getAdminUserService();
        this.groupService = getContainer().getUserGroupService();
        let initialListRequest: ListAdminUsersRequest = {
            limit: 20,
            orderBy: "id",
            ascending: false,
            searchString: "",
            userId: undefined,
            userGroupIds: undefined,
        };
        this.state = createInitialState(initialListRequest, {...this.state, groups : []})
    }

    onSubmit(): void {
        let updatedListRequest = { ...this.state.listRequest };
    
        if (this.state.form?.userId) {
            if (!isNaN(this.state.form?.userId)) {
                updatedListRequest.userId = this.state.form?.userId;
                updatedListRequest.searchString = ""
                updatedListRequest.userGroupIds = undefined;
            }
        } else if (this.state.form?.searchString) {
            updatedListRequest.searchString = this.state.form.searchString;
            updatedListRequest.userId = undefined;
            updatedListRequest.userGroupIds = undefined;
        }
        else if (this.state.form?.userGroupIds && this.state.form.userGroupIds.length > 0) {
            updatedListRequest.userGroupIds = this.state.form.userGroupIds;
            updatedListRequest.userId = undefined;
            updatedListRequest.searchString = ""
        }
        this.setState({ listRequest: updatedListRequest });
    }

    onSubmitError() :  void {
        console.log("submit error!")
    }

    formDidUpdate(formModel: ListAdminUsersRequest, validationMap?: any): void {
        this.setState({form : formModel, validationMap : validationMap});
    }

    renderGroups(item: AdminUser) {
        if (!this.state.groups) {
            return undefined
        }

        const groupName = (id: number) => this.state.groups?.find(gr => gr.id == id)?.name ?? `${id}`
        return item.groups?.map(gid => <Link className="badge badge-light" style={{marginLeft: "2px"}} to={`/admin/user-group/${gid}`}>{groupName(gid)}</Link>)  ?? <></>
    }

    async loadContent() {
        const groups = (await this.groupService.list({limit: 1000, offset: 0, orderBy: "id", ascending: false})).items
        this.setState({groups: groups})
    }

    onAction(item : AdminUser, action : string) : undefined | Promise<any> {
        return undefined;
    }

    pagerChanged(pager : PagerModel) {
        if(this.state.listRequest) {
            console.log("pager changed!", pager);
            let listRequest = ListUtils.toListRequest<any>(this.state.listRequest,pager);
            this.updateListState(listRequest);
        }
    }

    getTitle() {
        return "List Admin Users"
    }

    clearForText() {
        let searchString:string | undefined = this.state.form && this.state.form.searchString;
        this.setState({form : {searchString:searchString}});
    }

    clearForUserId = () => {
        this.setState({
            form: {
                ...this.state.form,
                searchString: '',
                userGroupIds: []
            }
        });
    }

    clearForUserGroupIds = () => {
        this.setState({
            form: {
                ...this.state.form,
                searchString: '',
                userId: undefined
            }
        });
    }

    getSearchByUserId() {
        return(
            <div className="row">
                <div className={"col-lg-6"}>
                    <div className="card-box">
                        <h4>{t(`title.findByAdminId`)}</h4>
                        <Form formListener={this} modelObject={this.state.form}
                              validationMap={this.state.validationMap}>
                            <FormGroup className={"form-group"} model="userId">
                                <FormInput model={"userId"} className={"form-control"} onFocus={this.clearForUserId}/>
                                <span className={"help-text"}>{t(`helpText.findByAdminId`)}</span>
                            </FormGroup>
                            <Submit className={"btn btn-primary"}>{t(`button.findUser`)}</Submit>
                        </Form>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card-box">
                        <h4>{t(`title.search`)}</h4>
                        <Form formListener={this} modelObject={this.state.form}
                              validationMap={this.state.validationMap}>
                            <FormGroup className={"form-group"}>
                                <FormInput model={"searchString"} className={"form-control"}
                                           validators={[Validators.strLength(3, 255)]}
                                           onFocus={() => this.clearForText()}/>
                                <span className={"help-text"}>{t(`helpText.adminSearch`)}</span>
                            </FormGroup>
                            <Submit className={"btn btn-primary"}>{t(`button.search`)}</Submit>
                        </Form>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="card-box">
                        <h4>{t(`title.searchByGroup`)}</h4>
                        <Form formListener={this} modelObject={this.state.form}
                              validationMap={this.state.validationMap}>
                            <FormGroup className={"form-group"}>
                                <label>User Groups</label>
                                <SelectInput type={"string"}
                                             optionModelValue={(value:any) => value?.id}
                                             displayValue={(value:any) => value?.name}
                                             size={this.state.groups ? (this.state.groups.length > 6 ? 6 : this.state.groups.length) : 0}
                                             className="form-control"
                                             model="userGroupIds"
                                             optional={true}
                                             multiple={true}
                                             values={this.state.groups || []}
                                             onFocus={this.clearForUserGroupIds} />
                                <span className={"help-text"}>{t(`helpText.groupSearch`)}</span>
                            </FormGroup>
                            <Submit className={"btn btn-primary"}>{t(`button.search`)}</Submit>
                        </Form>
                    </div>
                </div>
            </div>
        )
    }

    clearFilters() {
        let initialListRequest: ListAdminUsersRequest = {
            limit: 20,
            orderBy: "id",
            ascending: false,
        };
        this.setState({
            listRequest: initialListRequest,
            form: {
                userId: undefined,
                searchString: ''
            }
        });
    }

    renderContent() {
        if (!this.state.listRequest) {
            return <div/>;
        }
        return (
            <Fragment>
                {this.getSearchByUserId()}
                <EntityDataTable service={this.adminDashboardUserService}
                                 onPagerChange={this.pagerChanged.bind(this)}
                                 columns={this.columns}
                                 listRequest={this.state.listRequest}
                                 onAction={this.onAction.bind(this)}
                                 actions={this.actions}/>
                <button className="btn btn-secondary" onClick={() => this.clearFilters()}>
                    {t(`button.clearfilters`)}
                </button>
            </Fragment>
        );
    }
}

export default withRouter(AdminUserListView);
