import {HttpService} from "../http/HttpService";
import {AdminSecuritySettings} from "../http/protocol";


export class SecuritySettingsService {

    constructor(private http: HttpService) {
    }

    fetchSecuritySettings() : Promise<AdminSecuritySettings> {
        return this.http.get("/admin-security/settings");
    }
    saveSettings(settings : AdminSecuritySettings) :  Promise<AdminSecuritySettings> {
        return this.http.post("/admin-security/settings", settings);
    }
}