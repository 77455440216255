import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {CasinoPage, ListCasinoPageRequest, ListCasinoPageResponse} from "../http/protocol";
import {HttpService} from "../http/HttpService";



export class AdminCasinoPageService extends AbstractAdminEntityService<CasinoPage, ListCasinoPageResponse> {

    constructor(http : HttpService) {
        super("/casino-page",http);
    }
}