import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {Site, SiteListResponse} from "../http/protocol";
import {HttpService} from "../http/HttpService";


export class AdminSiteService extends AbstractAdminEntityService<Site, SiteListResponse> {

    constructor(http: HttpService) {
        super("/site", http);
    }

}