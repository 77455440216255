import React from "react";
import { RouterViewTemplate, useLoadingState } from "../RouterView";
import EnablementSettings from "./EnablementSettings";
import { PermissionRequired } from "../security/PermissionRequired";
import { PermissionCategory } from "../../http/protocol";

export default function ConfigurationView() {
    const status = useLoadingState(() => {
        return Promise.resolve();
    }, []);

    const onAction = () => {
        return Promise.resolve();
    };

    return (
        <RouterViewTemplate status={status} title={"Service Enablement Configuration"}>
            <PermissionRequired permissions={[PermissionCategory.SERVICE_ENABLEMENT]} accessLevel="r">
                <EnablementSettings />
            </PermissionRequired>
        </RouterViewTemplate>
    );
}
