import {LoadingViewState, RouterView} from "../RouterView";
import {RouteComponentProps, withRouter} from "react-router";
import {AdminMfaProvider, AdminUser} from "../../http/protocol";
import {AdminUserService} from "../../services/AdminUserService";
import {Form, FormListener} from "../form/Form";
import {getContainer} from "../../ioc/IOCSetup";
import {ApplicationState} from "../../user/UserStore";
import {connect} from "react-redux";
import React from "react";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {SelectInput} from "../form/SelectInput";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {Submit} from "../form/Submit";
import {ActionPerformedConfirmationModal} from "../../util/ActionPerformedConfirmationModal";

interface Props extends RouteComponentProps {
    userId?: number;
}

interface State extends LoadingViewState {
    user?: AdminUser;
    givenOtp?: string;
    mfaSecret?: string;
    codeInputMessage?: string;
    validationMap?: any;
    successText?: string;
    tempPassword?: string;
    qrCodeDataUrl?: string;
    authAppMfaVerified?: boolean;
    clickedOnResetAppMfa?: boolean;
    resetPasswordInProgress?: boolean;
    form_mfaProvider?: AdminMfaProvider;
    showMfaResetConfirmationModal?: boolean;
    showMfaSetupConfirmationModal?: boolean;
}

class AdminUserProfile extends RouterView<Props, State> implements FormListener<AdminUser> {
    service: AdminUserService;
    INSERT_6_DIGIT_CODE = 'Insert 6-digit code';
    CODE_IS_NOT_VALID = 'Code is not valid';

    constructor(props: Props) {
        super(props);
        this.service = getContainer().getAdminUserService();
        this.state = {
            user: {},
            loading: true,
            successText: "",
            tempPassword: "",
            validationMap: {},
            loadingFailed: false,
            resetPasswordInProgress: false
        };
    }



    async loadContent() {
        const user = await this.service.getEntity(this.props.userId as unknown as string);
        this.setState({
            ...this.state,
            user: user,
            loading: false,
            givenOtp: undefined,
            mfaSecret: undefined,
            loadingFailed: false,
            successText: undefined,
            qrCodeDataUrl: undefined,
            clickedOnResetAppMfa: false,
            showMfaResetConfirmationModal: false,
            showMfaSetupConfirmationModal: false,
            form_mfaProvider: user.adminMfaProvider,
            codeInputMessage: this.INSERT_6_DIGIT_CODE,
            authAppMfaVerified: user.authAppMfaVerified
        });
    }

    getTitle(): string {
        return "Profile page";
    }

    onSubmit(): void | Promise<any> {
        if (!this.state.user) {
            return Promise.resolve();
        }

        return this.service.setMfaProvider(this.state.form_mfaProvider as AdminMfaProvider).then((response) => {
            this.setState({...this.state, successText: "User was updated successfully"});
        }).catch(err => {
            console.log(err);
            this.setState({...this.state, successText: "Error updating user"});
        });
    }

    onSubmitError(): void {
    }

    formDidUpdate(formModel: any, validationMap?: any): void {
        this.setState({...this.state, form_mfaProvider: formModel.form_mfaProvider, validationMap: validationMap})
    }

    private resetPassword() {
        this.setState({...this.state, resetPasswordInProgress: true});
        if (this.state.user) {
            this.service.resetPassword(this.state.user.id).then((user) => {
                this.setState({...this.state, resetPasswordInProgress: false, tempPassword: user.password});
            }).catch(err => {
                this.setState({...this.state, resetPasswordInProgress: false})
            });
        }
    }

    private async initiateAppMfa() {
        this.service.initiateAppMfa().then((data) => {
            this.setState({
                ...this.state,
                mfaSecret: data.loginResp?.otpSecret,
                qrCodeDataUrl: data.loginResp?.qrCodeDataUrl
            });
        }).catch(err => {
            this.setState({...this.state, codeInputMessage: "Error initiating app mfa"})
            console.log(err);
        });
    }

    private removeSpaces(inputString: string): string {
        return inputString.replace(/\s/g, '');
    }

    private isSixDigitCode(input: any): boolean {
        return typeof input === 'string' && /^\d{6}$/.test(input);
    }

    private handleInputForSetupAppMfa = (event : React.FormEvent) => {
        const target = event.target as HTMLInputElement;
        const inputWithNoSpaces = this.removeSpaces(target.value);
        if (this.isSixDigitCode(inputWithNoSpaces)) {
            this.service.verifyAppMfa(inputWithNoSpaces).then((data) => {
                if (data.loginResp?.status != "SUCCESS") {
                    this.setState({
                        ...this.state,
                        givenOtp: inputWithNoSpaces,
                        codeInputMessage: this.CODE_IS_NOT_VALID} as Pick<State, keyof State>);
                    return;
                } else {
                    this.setState({
                        ...this.state,
                        givenOtp: "",
                        codeInputMessage: this.INSERT_6_DIGIT_CODE,
                        authAppMfaVerified: true,
                        showMfaSetupConfirmationModal: true,
                        form_mfaProvider: AdminMfaProvider.AUTHENTICATOR_APP, } as Pick<State, keyof State>);
                }

            }).catch(err => {
                console.log(err);
                this.setState({...this.state, codeInputMessage: this.CODE_IS_NOT_VALID} as Pick<State, keyof State>)
            });
        } else{
            this.setState({...this.state, codeInputMessage: this.INSERT_6_DIGIT_CODE, givenOtp: inputWithNoSpaces} as Pick<State, keyof State>);
        }
    };

    private handleInputForResetAppMfa = (event : React.FormEvent) => {
        const target = event.target as HTMLInputElement;
        const inputWithNoSpaces = this.removeSpaces(target.value);
        if (this.isSixDigitCode(inputWithNoSpaces)) {
            this.service.resetAppMfa(inputWithNoSpaces).then((data) => {
                this.setState({...this.state, showMfaResetConfirmationModal: true} as Pick<State, keyof State>)
            }).catch(err => {
                this.setState({...this.state, givenOtp: inputWithNoSpaces, codeInputMessage: this.CODE_IS_NOT_VALID} as Pick<State, keyof State>)
                console.log(err);
            });
        } else {
            this.setState({...this.state, codeInputMessage: this.INSERT_6_DIGIT_CODE, givenOtp: inputWithNoSpaces} as Pick<State, keyof State>);
        }
    }

    private AuthAppEnabledComponent() {
        return(
            <div className={'col-12'}>
                <div className={'widget-rounded-circle mb-0 py-3 px-0'}>
                    <div className="row align-items-center">
                        <div className="col-4 pl-0">
                            <div className="avatar-lg">
                                <div className="avatar-lg rounded-circle border bg-soft-success" >
                                    <i className="fe-check-circle font-22 avatar-title text-success"></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-8">
                            <div className="text-right">
                                <h3 className="text-dark mt-1">{"Authenticator App"}</h3>
                                <p className="text-muted mb-1 text-truncate">
                                    {"Enabled"}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderContent(): JSX.Element {
        return (
            <Form className="form-horizontal" formListener={this} modelObject={this.state} validationMap={this.state.validationMap}>
                <div className={"card"}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-12">
                                <FormSubmitError />
                                <FormSubmitSuccess text={this.state.successText} onClose={()=>this.onSuccessClose()}/>
                                <FormSubmitSuccess text={'User was successfully updated'}/>

                                {/*code from the userAdmin details page*/}
                                <FormGroup className="form-group" model="password">
                                    <label>Password</label>
                                    <div className="form-control" style={{paddingTop: "6px"}}>
                                        {(this.props.match.params as any).id == "new" && "Will be generated"}
                                        {(this.props.match.params as any).id != "new" && this.state.resetPasswordInProgress == true && "Please Wait..."}
                                        {(this.props.match.params as any).id != "new" && this.state.resetPasswordInProgress == false && this.state.tempPassword == "" &&
                                            <a href="#" onClick={() => this.resetPassword()}>Reset Password</a>}
                                        {(this.props.match.params as any).id != "new" && this.state.resetPasswordInProgress == false && this.state.tempPassword != "" && "Temporary password (valid for 24h): " + this.state.tempPassword}
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"card"}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-12">
                                <FormGroup className="form-group mb-1" model="form_mfaProvider">
                                    <label>{"Two-factor authentication"}</label>
                                    <div className="alert">
                                        Authenticator apps and browser extensions like
                                       <a href="https://support.1password.com/one-time-passwords/" target="_blank" rel="noopener noreferrer"> 1Password,</a>
                                       <a href="https://authy.com/guides/github/" target="_blank" rel="noopener noreferrer">Authy,</a>
                                       <a href="https://www.microsoft.com/en-us/security/mobile-authenticator-app" target="_blank" rel="noopener noreferrer">Microsoft Authenticator,</a>
                                       etc., generate one-time passwords that are used as a second factor to verify your identity when prompted during sign-in.
                                    </div>

                                    <label>{"Preferred method"}</label>
                                    <div className={'alert'}>Select preferred two-factor authentication method. Email is the default setting, authenticator app can be selected once setup.</div>

                                    <SelectInput
                                        values={this.state.authAppMfaVerified ? [AdminMfaProvider.EMAIL, AdminMfaProvider.AUTHENTICATOR_APP] : [[AdminMfaProvider.EMAIL]]}
                                        className="form-control" model="form_mfaProvider" type="text" readOnly={false}/>
                                </FormGroup>

                                {this.state.authAppMfaVerified &&
                                    <>
                                    {this.AuthAppEnabledComponent()}
                                    </>
                                }
                                <div className="form-group">
                                    <Submit className="btn btn-primary waves-effect waves-light mt-2">Save</Submit>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Mfa Setup Confirmation*/}
                <ActionPerformedConfirmationModal
                    onOk={() => {this.setState({...this.state, showMfaSetupConfirmationModal: false})}}
                    onCancel={() => {this.setState({...this.state, showMfaSetupConfirmationModal: false})}}
                    show={this.state.showMfaSetupConfirmationModal}
                    heading={"Authenticator App successfully setup"}
                    description={"Authenticator app was successfully setup"}
                    okLabel={"Ok"}
                />

                {/*Mfa Reset Confirmation*/}
                <ActionPerformedConfirmationModal
                    onOk={() => {this.loadContent()}}
                    onCancel={() => {this.loadContent()}}
                    show={this.state.showMfaResetConfirmationModal}
                    heading={"Authenticator App successfully removed"}
                    description={"Authenticator app was successfully removed"}
                    okLabel={"Ok"}
                />

                <div className={"card"}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-12">
                                {!this.state.authAppMfaVerified ?
                                    <>
                                        <h5>{"Setup Auth App Mfa"}</h5>
                                        <button className="btn btn-primary mb-2" type="button" disabled={this.state.qrCodeDataUrl != undefined}
                                                onClick={() => this.initiateAppMfa()}>{"Setup Authenticator App"}</button>
                                        <br/>

                                        {this.state.qrCodeDataUrl &&
                                            <>
                                                <div>Scan the qr-code with your authenticator app.</div>
                                                <div className={'mb-2'}>Or copy the secret below into your desktop authenticator app.</div>
                                                <div>
                                                    <img className={"mb-2"} src={this.state.qrCodeDataUrl} alt="qr code"/>
                                                    <FormGroup className="form-group mb-3" model="mfaSecret">
                                                        <label>{"Secret:"}</label>
                                                        <FormInput className="form-control" model="mfaSecret" type="text" readOnly={true}/>
                                                    </FormGroup>
                                                        <br/>
                                                        {this.state.codeInputMessage && <div className={'text-danger'}>{this.state.codeInputMessage}</div>}
                                                        <input placeholder={'6-digit 2fa code'} type="text"
                                                               className={"form-control"}
                                                               onChange={e => this.handleInputForSetupAppMfa(e)}
                                                               value={this.state.givenOtp}
                                                        />
                                                </div>
                                            </>
                                        }


                                    </>
                                    // if auth app mfa is verified
                                    : <div>
                                        <h5>{"Remove Auth App Mfa"}</h5>
                                        <button className="btn btn-danger mb-2" type="button"
                                                onClick={() => this.setState({...this.state, clickedOnResetAppMfa: true})}>{"Remove App mfa"}
                                        </button>
                                        {this.state.clickedOnResetAppMfa &&
                                            <>
                                            {this.state.codeInputMessage && <div className={'text-danger'}>{this.state.codeInputMessage}</div>}
                                                <input placeholder={'Confirm 6-digit 2Fa code'}
                                                       type="text"
                                                       className={"form-control mb-2"}
                                                       onChange={e => this.handleInputForResetAppMfa(e)}
                                                       value={this.state.givenOtp}
                                                />
                                            </>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>


            </Form>);
    }

    private onSuccessClose() {
        this.setState({...this.state, successText: ""});
    }
}

// get userId from redux store instead of url
const mapStateToProps = (state: ApplicationState) => {
    return {userId: state.user.user?.id}
}

export default connect(mapStateToProps)(withRouter(AdminUserProfile))