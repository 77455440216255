import * as React from "react";
import {getContainer} from "../../ioc/IOCSetup";
import {AmplitudeChart} from "../../http/Types";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {formatDate} from "../../util/date";
import {Chart} from "../../http/protocol";

interface Props { chart? : Chart}
export class AmplitudeBarChart extends React.Component<Props, { chart? : AmplitudeChart}> {

    constructor(props : Props) {
        super(props);
        this.state = {};
    }
    componentDidMount(): void {
        if(this.props.chart && this.props.chart.id) {
            getContainer().getChartService().getChart(""+this.props.chart.externalId).then(c => {
                this.setState({chart : c});
            })
        } else {
            console.log("No chart id set");
        }
    }
    getSeriesLabel(seriesLabels? : any[][], index? : number) : string {
        if(seriesLabels && typeof(index) != "undefined") {
            if(seriesLabels[index] && seriesLabels[index].length > 1) {
                return ""+ seriesLabels[index][1]
            }
        }
        if(typeof(index) != "undefined" && this.props.chart &&  this.props.chart.segmentTitles && this.props.chart.segmentTitles != "" ) {
            if(this.props.chart.segmentTitles.split(",").length > index) {
                return this.props.chart.segmentTitles.split(",")[index]

            }
        }
        return "N/A";
    }
    render() {
        if(!this.state.chart || !this.state.chart.data) {
            return <span></span>
        }

        let chart = this.state.chart;
        let values = chart.data.seriesCollapsed.map(
            (s,index) => {
                    return {
                        data : s.map( v => v.value),
                        name : this.getSeriesLabel(chart.data.seriesLabels, index)
                    }
            }

        );


        const options = {
            credits : false,
            title: {
                text: this.props.chart ? this.props.chart.name : "N/A"
            },
            chart: {
                type: 'column'
            },
            subtitle: {
                text: formatDate(chart.timeComputed),
                align: 'right',
                x: -10
            },
            xAxis: {
                categories: [""]
            },

            series: values
        }

        return (
            <div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options as any}/>
            </div>
        );
    }

}