import * as React from "react";
import {FieldSet, FieldSetStatus, FormComponentProps, KeyValue, ListItemFieldSet, ValidationResult} from "./Form";
import {FormUtils} from "./FormUtils";
import * as _ from "lodash";
import {FormElement} from "./FormInput";
import {DragDropContext, Draggable, Droppable, DropResult, ResponderProvided} from "react-beautiful-dnd";


interface FormListProps extends React.HTMLProps<HTMLElement>, FormComponentProps {
    children? : any;
    model : string;
    key? : string;
}

export class FormList<E extends FormListProps> extends React.Component<E,{}> {


    constructor(props : E) {
        super(props);
    }

    componentDidMount() {
        if (this.props.fieldSet && typeof this.getModelValue() == "undefined") {
            this.props.fieldSet.updateModel(this.props.model, null, true, {errorName : undefined, pristine : true});
        }
    }
    getModelValue() {
        return this.props.fieldSet && this.props.fieldSet.getModelValue(this.props.model);
    }
    updateEntry(index : number, model : string, value : any, pristine : boolean, validationResult : ValidationResult) {
        if(!model) {
            console.warn("Attribute model not set in list structure FormComponent");
        }
        this.props.fieldSet && this.props.fieldSet.updateModel(this.getListItemModel(index, model), value, true, validationResult);
    }
    updateEntries(index : number, values : KeyValue<any>[]) {
       values.forEach( kvp => {
           kvp.key = this.getListItemModel(index, kvp.key);
       });
        this.props.fieldSet &&this.props.fieldSet.updateModels(values);
    }
    getListItemModel(index : number, model : string) {
        if(model != "") {
            model = "." + model;
        }
        return this.props.model + "["+index +"]"+ model;
    }

    isModelValid(index : number, model : string) {
        if(!this.props.fieldSet)  {
            return false;
        }
        var listItemModel = this.getListItemModel(index,model);
        return  this.props.fieldSet.isModelValid(listItemModel);
    }
    removeModel(index : number, model : string) {
        this.props.fieldSet && this.props.fieldSet.removeModel(this.getListItemModel(index, model));
    }
    removeValidation(index : number, model : string) {
        this.props.fieldSet && this.props.fieldSet.removeValidation(this.getListItemModel(index, model));
    }
    removeItem(index : number) {
        if(!this.props.fieldSet) {
            return;
        }
        console.log("remove structure with index = " + index);
        var list : any[] = this.props.fieldSet.getModelValue(this.props.model);
        if(!list || list.length == 0) {
            return;
        }
        this.props.fieldSet && this.props.fieldSet.removeModel(this.props.model + "[" + index + "]");
    }
    setInitialModel(index : number, model: string, value: any, pristine: boolean, validationResult?: ValidationResult) {
        if(!model) {
            console.warn("Attribute model not set in list structure FormComponent");
        }
        this.props.fieldSet && this.props.fieldSet.setInitialModel(this.getListItemModel(index,model), value, true, validationResult);
    }
    move(index : number, back : boolean) {
        if(!this.props.fieldSet) {
            return;
        }
        if(back && index == 0) {
            return;
        } else if(this.props.fieldSet && !back && index == this.props.fieldSet.getModelValue(this.props.model).length-1 ){
            return;
        }

        var list : any[] = this.props.fieldSet.getModelValue(this.props.model);
        if(!list || list.length == 0) {
            return;
        }
        list = list.concat();
        list.splice(index, 0, list.splice(back ? index-1 : index + 1, 1)[0]);
        this.props.fieldSet.updateModel(this.props.model, list, false, {errorName : undefined, pristine : false});
    }
    moveFromTo(fromIndex : number, toIndex : number) {
        if(!this.props.fieldSet) {
            return;
        }

        var list : any[] = this.props.fieldSet.getModelValue(this.props.model);
        if(!list || list.length == 0) {
            return;
        }
        list = list.concat();
        let el = list[fromIndex];
        list.splice(fromIndex, 1);
        list.splice(toIndex, 0, el);
        this.props.fieldSet.updateModel(this.props.model, list, false, {errorName : undefined, pristine : false});
    }
    renderList() : React.ReactNode {
        if(!this.props.fieldSet) {
            return <span></span>;
        }
        var list : any[] = this.props.fieldSet.getModelValue(this.props.model);
        if(!list || list.length == 0) {
            return [];
        }
        var results : React.ReactNode[] = [];
        var index : number = 0;
        console.log("Form list render list items = ", this.props)
        list.forEach( item => {
            results.push(this.renderListItem(index, item));
            index++;
        });

        return results;

    }
    renderListItem(index : number, item : any) {

        return (
            <FormListItem key={this.props.key ? this.getListItemModel(index, this.props.key ?? "") : index}
                          index={index}
                          item={item}
                          elements={this.props.children}
                          removeModel={this.removeModel.bind(this, index)}
                          onListItemUpdate={this.updateEntry.bind(this, index)}
                          onRemoveItem={this.removeItem.bind(this, index)}
                          onMoveItem={this.move.bind(this, index)}
                          isModelValid={this.isModelValid.bind(this,index)}
                          onListItemsUpdate={this.updateEntries.bind(this, index)}
                          parentFieldSet={this.props.fieldSet}
                          setInitialModel={this.setInitialModel.bind(this,index)}
                          removeValidation={this.removeValidation.bind(this, index)}
            />
        )
    }
    render() {
        return <>{this.renderList()}</>
    }
}
export interface DragFormListProps extends FormListProps {
    droppableId: string;
    draggableClass?: string;
}
export class DragFormList extends FormList<DragFormListProps> {

    renderListItem(index: number, item: any): JSX.Element {
        return (
            <Draggable key = {this.props.droppableId + "tab"+index} draggableId={this.props.droppableId + "tab"+index} index={index}>
                {(provided,snapshot)=> (
                    <div className={"draggable-container-" + (this.props.draggableClass ?? "") } ref={provided.innerRef} {...provided.draggableProps}>
                        <div {...provided.dragHandleProps}>
                            <i className="fe-menu" draggable="false"></i>
                        </div>
                        {super.renderListItem(index,item)}
                    </div>
                )}
            </Draggable>
        )
    }

    render() {
        const getListStyle = (isDraggingOver : boolean)  => {
            return {
                background: isDraggingOver ? "lightblue" : "transparent",
                width: "100%"
            }
        }
        let onDragEnd = (result: DropResult, provided: ResponderProvided) => {
            // dropped outside the list
            if (!result.destination || !result.source) {
                return;
            }
            if(result.destination.index === result.source.index) {
                return;
            }

            let from = result.source.index;
            let to = result.destination.index;

            this.moveFromTo(from,to);
        }
        return (

                <DragDropContext onDragEnd={onDragEnd}>

                    <Droppable droppableId={this.props.droppableId}>

                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                <div className={"container-fluid"}>
                                    {this.renderList()}
                                </div>
                                {provided.placeholder}
                            </div>
                        )}

                    </Droppable>

                </DragDropContext>


        )
    }
}
interface FormListItemProps {
    index? : number;
    item? : any;
    elements? : any,
    onListItemUpdate? : (model : string, value : any, pristine : boolean, validationResult : ValidationResult) => void;
    onRemoveItem? : () => void;
    onAddItem? : () => void;
    setInitialValidation? : (model : string, validationError : string, pristine : boolean) => void;
    setInitialModel : (model : string, value : any, pristine : boolean, validationResult? : ValidationResult) => void;
    isModelValid : (model : string) => boolean;
    onListItemsUpdate : (kvps : KeyValue<any>[]) => void;
    onMoveItem : (back : boolean) => void;
    parentFieldSet? : FieldSet;
    removeModel? : (model : string) => void;
    removeValidation? : (model : string) => void;


}

export class FormListItem extends React.Component<FormListItemProps,{}> implements ListItemFieldSet {

    resetSubmitStatus(): void {
        this.props.parentFieldSet && this.props.parentFieldSet.resetSubmitStatus();
    }

    setInitialModel(model: string, value: any, pristine: boolean, validationResult?: ValidationResult) {
        this.props.setInitialModel(model, value,pristine, validationResult);
    }

    modelDidUpdate(model : string) {
        this.props.onRemoveItem && this.props.onRemoveItem();
    }
    removeValidation(model : string) {
        this.props.removeValidation && this.props.removeValidation(model);
    }

    isSubmitting(): boolean {
        if(!this.props.parentFieldSet){
            return false;
        }
        return this.props.parentFieldSet.isSubmitting();
    }

    getStatus(): FieldSetStatus | undefined{
        if(!this.props.parentFieldSet){
            return undefined;
        }
        return this.props.parentFieldSet.getStatus();
    }

    getFormError(): string | undefined {
        if(!this.props.parentFieldSet){
            return undefined;
        }
        return this.props.parentFieldSet.getFormError();
    }
    removeModel(model: string) {
        this.props.removeModel && this.props.removeModel(model);
    }
    updateModel(model : string, value : any, pristine : boolean, validationResult : ValidationResult) {
        if(!model) {
            console.warn("Attribute model is not set on list structure");
        }
        this.props.onListItemUpdate && this.props.onListItemUpdate(model,value,pristine, validationResult);
    }
    updateModels(kvp : KeyValue<any>[]) {
        this.props.onListItemsUpdate(kvp);
    }

    isModelValid(model? : string) {
        if (model == "" || model == null || typeof model == "undefined" || model.indexOf("[")== 0) {
            model = "";
        }
        return this.props.isModelValid(model);
    }
    removeCurrentItem() {
        this.props.onRemoveItem && this.props.onRemoveItem();
    }
    move(back : boolean) {
        this.props.onMoveItem && this.props.onMoveItem(back);
    }
    addItem() {
        this.props.onAddItem && this.props.onAddItem();
    }



    getModelObject() {
        return this.props.item;
    }
    getModelValue(model:string):any {
        if (model == "" || model == null || typeof model == "undefined" || model.indexOf("$")== 0) {
            return this.props.item;
        } else {
            return _.get(this.props.item, model);
        }
    }
    componentDidMount() {
    }

    render() {
        return <>{FormUtils.renderChildren(this.props.elements,this,undefined, this.props.index)}</>
    }

    isReadOnly(): boolean {
        if(!this.props.parentFieldSet) {
            return false;
        }
        return this.props.parentFieldSet.isReadOnly();
    }
}


export class ModelBinding<M> {

    fieldSet : FieldSet | ListItemFieldSet;
    model : string;


    constructor(fieldSet : FieldSet | ListItemFieldSet, model : string) {
        this.fieldSet = fieldSet;
        this.model = model;
    }

    getValue() : M {
       return this.fieldSet.getModelValue(this.model);
    }

    setValue (value : M) {
        this.fieldSet.updateModel(this.model, value, true);
    }
    remove() {
        this.fieldSet.updateModel(this.model, null, true);
    }
    removeCurrentItem() : void {
        this.fieldSet.removeCurrentItem();
    }
}
export class ListItemBinding<M> extends ModelBinding<M> {
    index : number;
    constructor(index : number, fieldSet : FieldSet | ListItemFieldSet, model : string) {
        super(fieldSet,model);
        this.index = index;
    }
    moveItem(back : boolean) {
        (this.fieldSet as ListItemFieldSet).move(back);
    }
}
export class ListModelBinding<M> extends ModelBinding<M[]> {

    addItem( item : M ) {
        if(!this.getValue()) {
            this.fieldSet.updateModel(this.model, [item],true, { errorName : undefined, pristine : true});
        } else {
            var list = _.clone(this.getValue())
            list.push(item);
            this.fieldSet.updateModel(this.model,list,true,{ errorName : undefined, pristine : true});
        }
    }
    removeFirst() {
        var list : any[] = _.clone(this.getValue())

        if(list && list.length > 0) {
            this.fieldSet.removeModel(this.model + "[0]");
        }
    }
    removeLast() {
        var list : any[] = _.clone(this.getValue())
        if(list && list.length > 0) {
            var index = list.length-1;
            this.fieldSet.removeModel(this.model + "["+ index + "]");
        }

    }
    getLast() : M | undefined{
        var value = this.getValue();
        if(value == null || value.length == 0) {
            return undefined;
        }
        return value[value.length-1];
    }

}
interface BindProps extends FormElement<any,React.HTMLAttributes<any>> {
    render : ( binding : ModelBinding<any> ) => JSX.Element;
    binding? : ModelBinding<any>;
    model? : string;
    type? : string;
}

export class BindModel extends React.Component<BindProps,{}>{

    render() {
        if(!this.props.binding) {
            return <span></span>
        }
        return  this.props.render && this.props.render(this.props.binding);
    }
}

