import {HttpService} from "../http/HttpService";
import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {CashbackSingleCurrencyPayoutListResponse, SingleCurrencyCashbackPayout} from "../http/protocol";


export class CashbackSingleCurrencyPayoutService extends AbstractAdminEntityService<SingleCurrencyCashbackPayout, CashbackSingleCurrencyPayoutListResponse> {

    constructor(http: HttpService) {
        super("/cashback/single-currency-payout", http)
    }
}