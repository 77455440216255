import {HttpService} from "../http/HttpService";
import {AmplitudeChart} from "../http/Types";
import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {Chart, ListChartsResponse} from "../http/protocol";


export class ChartService  extends AbstractAdminEntityService<Chart, ListChartsResponse>  {

    constructor(http : HttpService) {
        super("/chart", http)
    }
    getChart(chartId : string) : Promise<AmplitudeChart>{
        return this.http.get<any>("/chart/remote-chart/" + chartId);
    }




}