import {CardBox, RouterViewTemplate, useLoadingState} from "../RouterView";
import {getContainer} from "../../ioc/IOCSetup";
import * as React from "react";
import {ListRequest, Site} from "../../http/protocol";
import {Link} from "react-router-dom";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {ActionType} from "../list/list/DataTable";
import {ColumnType} from "../list/list/ColumnType";
import {PagerModel} from "../list/list/PagerModel";
import {useState} from "react";


export const ListSitesView = () => {

    const service = getContainer().getAdminSiteService();

    const status = useLoadingState(()=>{
        return Promise.resolve();
    },[]);

    const [listRequest, setListRequest]  = useState<ListRequest>({
        limit: 20,
        offset : 0,
        orderBy : "id",
        ascending : false
    });
    const pagerChanged = (pager : PagerModel) => {
        setListRequest({
            offset : pager.offset,
            limit : pager.limit,
            orderBy : pager.orderBy,
            ascending : pager.ascending
        })
    }
    const onAction = (item : any, actionId : string) => {
        return Promise.resolve();
    }


    return (
        <RouterViewTemplate status={status} title={"List Sites"}>
            <CardBox>
                <Link className={"btn btn-primary"} to={"/admin/site/new"}>Create Site</Link>
            </CardBox>
            <EntityDataTable
                service={service}
                listRequest={listRequest}
                columns={[
                    { property : "id", name : "ID" },
                    { property : "name", name : "Name" },
                    { property : "enabled", name : "Enabled", type : ColumnType.YES_OR_NO },
                    { property : "hosts", name : "Hosts", renderer : (p,s,item : Site) => item.hosts?.join(", ")}
                ]}
                actions={[
                    { type : ActionType.LINK, className : "btn btn-primary", id : "view", name : "View", getLink : (item) => `/admin/site/${item.id}`}
                ]}
                onPagerChange={pagerChanged}
                onAction={onAction}
            />
        </RouterViewTemplate>
    )
}
