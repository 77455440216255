import * as React from "react";

export interface MetricsValuePanelProps {
    header:string;
    mainTitle:string;
    subTitle:string;
    mainValue:any;
    subValue?:any;
    currency?: string;
    type:string;
}

export class MetricsValuePanel extends React.Component<MetricsValuePanelProps, {}> {

    //Move to its own component when finished.
    renderIcon(type:string, value?:number) {
        value == undefined ? value = 0 : value = value;
        if(type === "LOGIN") {
            return(
                <div className="avatar-lg rounded-circle bg-soft-blue border-blue border" style={{marginTop:"15px"}}>
                    <i className="fe-users font-22 avatar-title text-blue"></i>
                </div>
            )
        } else if(type === "DEPOSIT"){
            return(
                <div className="avatar-lg rounded-circle bg-soft-warning border-warning border" style={{marginTop:"15px"}}>
                    <i className="far fa-credit-card font-22 avatar-title text-warning"></i>
                </div>
            );
        } else if(type === "REVENUE"){
            return(
                <div className="avatar-lg rounded-circle bg-soft-success border-success border" style={{marginTop:"15px"}}>
                    <i className="fas fa-dollar-sign font-22 avatar-title text-success"></i>
                </div>
            );
        }
    }


    render() {
        return(
            <div className="col-md-6 col-xl-3">
                <div className="widget-rounded-circle card-box">
                    <div className="row">
                        <div className="col-6">
                            <div className="text-left">
                                <h3 className="text-muted mt-1">{this.props.header}</h3>
                                {this.renderIcon(this.props.type,Number(this.props.mainValue))}
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="text-right">
                                <h3 className="text-dark mt-1"><span data-plugin="counterup">{this.props.mainValue}</span><small className="text-muted"> {this.props.currency ? this.props.currency : ""}</small></h3>
                                <p className="text-muted mb-1 text-truncate">{this.props.mainTitle}</p>
                            </div>
                            <div className="text-right" style={{marginTop:"25px"}}>
                                <h5 className="text-dark mt-1"><span data-plugin="counterup">{this.props.subValue}</span><small className="text-muted"> {this.props.currency ? this.props.currency : ""}</small></h5>
                                <small className="text-muted mb-1 text-truncate">{this.props.subTitle}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}