import * as React from "react";
import {ColumnType} from "../list/list/ColumnType";
import {Entry, ListEntriesRequest, Transaction} from "../../http/protocol";
import {TransactionEntriesService} from "../../services/TransactionEntriesService";
import {PagerModel} from "../list/list/PagerModel";
import {ListUtils} from "../list/list/ListUtils";
import {EntityDataTable} from "../list/list/EntityDataTable";




const EntryProd = (item: Entry) => {
    return <>{`${item.transactionAttributes?.PRODUCT ?? "n/a"}`}</>
}

const EntryType = (item: Entry) => {
    return <>{`${item.transactionAttributes?.TYPE ?? "n/a"}`}</>
}

const EntryMetaDataComp = (item: Entry) => {
    const atrs = item.transactionAttributes
    const txType = atrs?.TYPE ?? "n/a"
    const prod = atrs?.PRODUCT ?? "n/a"

    if (prod == 'PAYMENT'  &&  txType != 'BONUS') {
        return <>{`${atrs?.PAYMENT_PROVIDER} (${atrs?.PAYMENT_METHOD ?? "missing method"})`}</>
    } else if (prod == 'CASINO') {
        return <>{`${atrs?.CASINO_GAME_TITLE} (${atrs?.GAME_STUDIO ?? "missing studio"})`}</>
    } else if (prod == 'POKER') {
        return <>{`${atrs?.POKER_TABLE_NAME}: ${atrs?.POKER_TRANSACTION_TYPE ?? "missing type"}`}</>
    } else if (prod == 'SPORTSBOOK') {
        return <>{`${atrs?.GAME_PROVIDER ?? "n/a"}`}</>
    } else if (prod == 'PAYMENT'  &&  txType == 'BONUS') {
        return <>{`${atrs?.BONUS_ACTION}: ${item.transactionComment ?? "missing comment"}`}</>
    } else if (txType == 'CORRECTION') {
        return <>{`${item.transactionComment ?? "missing comment"}`}</>
    } else {
        return <>n/a</>
    }
}

let columns = [
    { property : "transactionId", name : "Transaction ID", orderBy : "id", type : ColumnType.LINK, getUrl : (item : Entry) => { return `/transaction/${item.transactionId}`} },
    { property : "timestamp", name : "Date", type : ColumnType.DATE},
    { property : "", name: "Product", type: ColumnType.COMPONENT, component: EntryProd},
    { property : "", name: "Type", type: ColumnType.COMPONENT, component: EntryType},
    { property : "", name: "Information", type: ColumnType.COMPONENT, component: EntryMetaDataComp},
    // { property : "transactionComment", name : "Comment",  },
    { property : "amount.amount" , name : "Amount", type : ColumnType.BIG, orderBy: "amount.amount"},
    { property : "resultingBalance.amount", name : "Resulting Balance", type : ColumnType.BIG},
    { property : "amount.currencyCode", name : "Currency"}
];
interface Props { accountId : string, service : TransactionEntriesService}

export class TransactionEntriesList extends React.Component<Props,{ pager? : any, listRequest? : ListEntriesRequest}> {

    constructor(props : Props) {
        super(props);
        this.state = { listRequest : { accountId : parseInt(props.accountId), limit : 20, offset : 0, orderBy : "id", ascending : false} };
    }

    pagerChanged(pager : PagerModel) {
        if(!this.state.listRequest) {
            return;
        }
        var listRequest = ListUtils.toListRequest<ListEntriesRequest>(this.state.listRequest,pager);
        this.setState({listRequest : listRequest});
    }

    render() {
        if(!this.state.listRequest) {
            return <span/>
        }

        return <EntityDataTable
            columns={columns}
            service={this.props.service}
            listRequest={this.state.listRequest}
            onPagerChange={this.pagerChanged.bind(this)}
            onAction={()=>undefined}
        />
    }

}