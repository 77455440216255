import React, {Fragment} from "react";
import {Bundle, ListBundlesRequest, ListRequest, UserJourneyWrapper} from "../../http/protocol";
import {ListView, ListViewProps, ListViewState} from "../ListView";
import {ActionType} from "../list/list/DataTable";
import {getContainer} from "../../ioc/IOCSetup";
import {PagerModel} from "../list/list/PagerModel";
import {ListUtils} from "../list/list/ListUtils";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {withRouter} from "react-router";
import {UserJourneyService} from "../../services/UserJourneyService";
import {ColumnType} from "../list/list/ColumnType";

interface State extends ListViewState<ListRequest> {
    listRequest?: ListBundlesRequest;
}

interface Props extends ListViewProps {

}

export class BundlesListView extends ListView<State, ListRequest> {

    columns = [
        { property : "id", name : "ID", orderBy : "id"},
        { property : "classifier", name : "Classifier", renderer : (property:string, name:string, item : any) => !item.classifier ? "Default" :  item.classifier},
        { property:  "enabled", name : "Enabled", type : ColumnType.YES_OR_NO}
    ];

    actions = [
        { id : "view", name : "View", className : "btn btn-primary", iconClassName : "fa fa-eye", type : ActionType.LINK, getLink : (item : any) => "/user-journey/" + item.id },
        { id : "delete", name : "Delete", className: "btn btn-danger", iconClassName: "fa fa-trash", type : ActionType.CONFIRMATION }
    ];

    userJourneyService : UserJourneyService;

    constructor(props : Props){
        super(props, "/user-journeys");
        this.userJourneyService = getContainer().getUserJourneyService();

    }

    onAction(item : UserJourneyWrapper, action : string) : undefined|Promise<any> {
        if(action == "delete") {
            return this.userJourneyService.remove(""+item.id);
        }
    }

    pagerChanged(pager : PagerModel) {
        if(this.state.listRequest) {

            let listRequest = ListUtils.toListRequest<any>(this.state.listRequest,pager);
            this.updateListState(listRequest);
        }
    }
    getTitle() {
        return "List User Journey"
    }

    renderContent() {

        if(!this.state.listRequest) {
            return <div/>;
        }
        return (
            <Fragment>
                <EntityDataTable service={this.userJourneyService}
                                 onPagerChange={this.pagerChanged.bind(this)}
                                 columns={this.columns}
                                 listRequest={this.state.listRequest}
                                 onAction={this.onAction.bind(this)}
                                 actions={this.actions}/>
            </Fragment>
        );
    }

}

export default withRouter(BundlesListView);