import React, {Fragment} from "react";
import {Affiliate, ListAccountsRequest, PermissionCategory} from "../../http/protocol";
import {getContainer} from "../../ioc/IOCSetup";
import {RouteComponentProps, withRouter} from "react-router";
import {LoadingViewState, RouterView} from "../RouterView";
import {formatDate} from "../../util/date";
import {Link} from "react-router-dom";
import {AffiliatesService} from "../../services/AffiliatesService";
import {LoadingSpinner} from "../../util/LoadingSpinner";
import {MetricHighChart} from "../metrics/MetricHighChart";
import {AccountService} from "../../services/AccountService";
import {AffiliateTransactionPanel} from "./AffiliateTransactionPanel";
import { PermissionRequired } from "../security/PermissionRequired";
import { ACTIVE_CURRENCY_KEY } from "../CurrencySelector";
import { EntityDataTable } from "../list/list/EntityDataTable";
import { PagerModel } from "../list/list/PagerModel";
import { ListUtils } from "../list/list/ListUtils";
import Pagination from "../list/list/Pagination";

interface State extends LoadingViewState {
    affiliate? : Affiliate;
    revenue : MonthlyData[];
    noCurrency:boolean;
    playerListDetails? : any;
    listRequest?: ListAccountsRequest,
    form? : any;
    payoutCurrency:string;

}
interface MonthlyData {
    data: Array<{month:string, value:number}>;
    title: string
}

interface Props extends RouteComponentProps {

}

export class AffiliateView extends RouterView<Props,State> {

    service : AffiliatesService;
    accountService: AccountService;




    constructor(props : Props){
        super(props);
        this.service = getContainer().getAffiliatesService();
        this.accountService = getContainer().getAccountService();
        this.state = {revenue: [], playerListDetails : {}, loading: true, noCurrency:false, payoutCurrency:""};
        let listRequest =  {  limit: 30, ascending: false, orderBy: "id", offset:0};
        this.state = {...this.state,   form : {...listRequest}, listRequest : listRequest};
    }

    loadContent() : Promise<any> | undefined {
        let id = (this.props.match.params as any).id;
        let cur=window.localStorage.getItem(ACTIVE_CURRENCY_KEY);
        return this.service.getEntity(id).then((a : Affiliate) => {

            this.service.getPayoutCurrency(a.id).then(cur => {
                this.setState({payoutCurrency:cur})
            });

            if(cur) {
                a.id && this.service.getAffiliateRevenue(cur,a.id).then(data => {
                    console.log("DATA: ", data);
                    if(data){
                        let profits=[];
                        let combineProfitCpa
                        for (let d of data) {
                            if(d.profitEuro && d.cpaEuro ){
                                combineProfitCpa = d.profitEuro + d.cpaEuro;
                            }
                            if (d.month) {
                                profits.push({month: d.month, value:combineProfitCpa ? combineProfitCpa  :  d.profitEuro ?  d.profitEuro: 0});
                            }
                        }
                        console.log("prof: ", profits)
                        this.setState({ affiliate : a, revenue: [{data: profits, title: "Affiliate  " + a.id}]});

                    } else {
                        this.setState({affiliate:a});
                    }

                }).catch((e)=>{
                    this.setState({ affiliate : a});
                    console.log("failed to get affiliate revenue`: ",e )
                })
            } else {
                this.setState({affiliate:a, noCurrency:true});
            }

            a.id && this.service.getAffiliatePlayersAll(a.id, this.state.form).then((data) => {
                console.log(data);
                this.setState({playerListDetails : data});
            }).catch((e)=>{
                console.log("failed to get affiliate player list`: ",e );
            });

        });

    }
    componentDidUpdate(prevProps : RouteComponentProps, prevState : any) {
        if((this.props.match.params as any).id != (prevProps.match.params as any).id) {
            this.refreshContent();
        } else {
            super.componentDidUpdate(prevProps, prevState);
        }
        if(this.state.form !== prevState.form) {
            this.service.getAffiliatePlayersAll((this.props.match.params as any).id, this.state.form).then((data) => {
          
                this.setState({playerListDetails : data});
            }).catch((e)=>{
                console.log("failed to get affiliate player list`: ",e );
            });
        }
    }

    getPlayersList() {
        if(Object.keys(this.state.playerListDetails).length === 0) {
            return <LoadingSpinner/>;
        }

        let playerListInfo = this.state.playerListDetails["items"];

        return (
            <div className="row">
                <div className="col-lg-6">
                    <div className={"card-box"}>
                        <h5>Player List</h5>
                        <table className={"table table-striped"}>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Player UID</th>
                                    <th>Date Created</th>
                                </tr>
                            </thead>
                            <tbody>
                                {playerListInfo.map((playerInfo : any) => {
                                    return <tr>
                                        <td><a href={"/user/"+playerInfo.playerId}>{playerInfo.playerId}</a></td>   
                                        <td>{playerInfo.username}</td>
                                        <td>{formatDate(playerInfo.created)}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        <Pagination paginate={this.state.form} setPaginate={(form: any) => this.setState({ form })} nrOfResults={this.state.playerListDetails && this.state.playerListDetails.nrOfResults} />
       
                    </div>
                </div>
            </div>
        )
    }

    getAffiliateInfo() {
        if(!this.state.affiliate) {
            return <LoadingSpinner/>
        }
        return (
            <div className={"row"}>
                <div className="col-lg-6">
                    <div className={"card-box"}>
                        <Link to={"edit/" + this.state.affiliate.id} className={"btn btn-warning"} style={{float : "right"}}><i className={"fe-edit"}></i> Edit User</Link>
                        <h4>{this.state.affiliate.email}</h4>
                        <h5>User Id: {this.state.affiliate.id}</h5>
                        <table className={"table table-striped"}>
                            <tbody>
                            <tr>
                                <td>Enabled</td>
                                <td>{this.state.affiliate.enabled ? <span className={"green"}>Yes</span> : <span className={"red"}>No</span>} </td>
                            </tr>
                            <tr>
                                <td>Payout Model</td>
                                <td>{this.state.affiliate.payoutModel}</td>
                            </tr>
                            <tr>
                                <td>Created</td>
                                <td>{formatDate(this.state.affiliate.created)}</td>
                            </tr>
                            <tr>
                                <td>Status</td>
                                <td>{this.state.affiliate.status}</td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td>{this.state.affiliate.email}</td>
                            </tr>
                            <tr>
                                <td>Name</td>
                                <td>{this.state.affiliate.name}</td>
                            </tr>
                            <tr>
                                <td>Username</td>
                                <td>{this.state.affiliate.username}</td>
                            </tr>
                            <tr>
                                <td>CashOut Player ID</td>
                                <td>{this.state.affiliate.cashoutUserId}</td>
                            </tr>
                            <tr>
                                <td>CashOut Crypto Address</td>
                                <td>{this.state.affiliate.recipientAddress}</td>
                            </tr>
                            <tr>
                                <td>Cashout Username</td>
                                <td>{this.state.affiliate.cashoutUsername}</td>
                            </tr>
                            <tr>
                                <td>Payout Currency</td>
                                <td>{this.state.affiliate.payoutCurrency}</td>
                            </tr>
                            <tr>
                                <td>Default Revenue Share</td>
                                <td>{this.state.affiliate.defaultRevenueShare}</td>
                            </tr>
                            <tr>
                                <td>CPA Deposit Requirement</td>
                                <td>{this.state.affiliate.cpaDepositRequirement !=null ?"€":""}{this.state.affiliate.cpaDepositRequirement}</td>
                            </tr>
                            <tr>
                                <td>CPA Turnover Requirement</td>
                                <td>{this.state.affiliate.cpaTurnoverRequirement !=null ?"€":""}{this.state.affiliate.cpaTurnoverRequirement}</td>
                            </tr>
                            <tr>
                                <td>CPA Payout Amount</td>
                                <td>{this.state.affiliate.cpaPayoutAmount !=null ?"€":""}{this.state.affiliate.cpaPayoutAmount}</td>
                            </tr>
                            <tr>
                                <td>CPA Cap</td>
                                <td>{this.state.affiliate.cpaCap}</td>
                            </tr>

                            <tr>
                                <td>Agents Enabled</td>
                                <td>{""+this.state.affiliate.agentsEnabled}</td>
                            </tr>

                            <tr>
                                <td>Agents KYC Enabled</td>
                                <td>{""+this.state.affiliate.agentsKycEnabled}</td>
                            </tr>

                            <tr>
                                <td>Agents Deposit Limit</td>
                                <td>{this.state.affiliate.depositLimit !=null ?"€":""}{this.state.affiliate.depositLimit}</td>
                            </tr>

                            <tr>
                                <td>Agents Withdraw Limit</td>
                                <td>{this.state.affiliate.withdrawLimit !=null ?"€":""}{this.state.affiliate.withdrawLimit}</td>
                            </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
                <PermissionRequired permissions={[PermissionCategory.AFFILIATES]} accessLevel={"w"}>
                    <div className="col-lg-6">
                        <div className={"card-box"}>
                                <AffiliateTransactionPanel affiliateId={this.state.affiliate.id} payoutCurrency={this.state.payoutCurrency} transfer={(req) => this.service.manualPayout(req)} />
                        </div>
                    </div>
                </PermissionRequired>
            </div>
        )
    }

    getTitle() : string {
        return "Affiliate Overview";
    }

    renderContent() {
        if(!this.state.affiliate) {
            return <LoadingSpinner />
        }
        return (

            <Fragment>
                <div className="row">
                    <div className={"col-lg-12"}>
                        {!this.state.noCurrency && <MetricHighChart data={this.state.revenue}
                                          header={"Affiliate's Monthly Commission (Currency: (€) Euro)"}/>}
                        {this.state.noCurrency && (
                            <div>
                                <h5>No currency selected. please selected one from the drop down in the right-hand side menu "Change currency"</h5>
                            </div>
                        )}
                        {this.getAffiliateInfo()}
                        {this.getPlayersList()}
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default withRouter(AffiliateView);
