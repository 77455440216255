import {AdminUser, ContentfulGlobalModel, LoginRequest, LoginResponse, Settings} from "../http/protocol";

import {getContainer} from "../ioc/IOCSetup";
import { createAndDispatch } from "../RootReducer";
import {Dispatch} from "redux";

export const CURRENCIES = "CURRENCIES";
export async function fetchCurrencies() {
    const curs = await getContainer().getWalletService().getSupportedCurrencies()
    return createAndDispatch(CURRENCIES, curs);
}

