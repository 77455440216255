import React, {Fragment} from "react";
import {ActionType} from "../list/list/DataTable";
import {Transaction, TransactionSearchRequest} from "../../http/protocol";
import {getContainer} from "../../ioc/IOCSetup";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {PagerModel} from "../list/list/PagerModel";
import {ListUtils} from "../list/list/ListUtils";
import {withRouter} from "react-router";
import {createInitialState, ListView, ListViewProps, ListViewState} from "../ListView";
import {Form, FormListener} from "../form/Form";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Submit} from "../form/Submit";
import {t} from "../../i18n";
import {TransactionService} from "../../services/TransactionService";
import {ColumnType} from "../list/list/ColumnType";


interface State extends ListViewState<TransactionSearchRequest> {
    listRequest?: TransactionSearchRequest;
    form?: TransactionSearchRequest;
    validationMap?: any;
    lookupExpanded?: boolean;
}


export class TransactionListView extends ListView<State, TransactionSearchRequest> implements FormListener<TransactionSearchRequest> {

    columns = [
        {property: "id", name: "ID"},
        {property: "comment", name: "Comment"},
        {property: "timestamp", name: "Date", type: ColumnType.DATE}
    ];

    actions = [
        {
            id: "view",
            name: t(`button.view`),
            className: "btn btn-primary",
            iconClassName: "fa fa-user",
            type: ActionType.LINK,
            getLink: (item: any) => "/transaction/" + item.id
        }

    ];
    service: TransactionService;

    constructor(props: ListViewProps) {
        super(props, "/transactions");
        this.service = getContainer().getTransactionService();

        let locProp = this.props.location as any;
        if (this.state.listRequest){

            let locPropState = locProp.state as any;
            let lookupExpanded:boolean = false;

            if(locPropState && locPropState.lookupExpanded != undefined) {
                lookupExpanded = locPropState.lookupExpanded;
            }
            let lr = this.state.listRequest;

            this.state = createInitialState(
                {
                    limit: 20,
                    orderBy: "id",
                    ascending: false
                },
                {...this.state, listRequest: lr, lookupExpanded:lookupExpanded}
            )
        }
    }


    onSubmit(): void {
        let listRequest: TransactionSearchRequest = Object.assign({}, this.state.form);

        listRequest.offset = 0;
        this.updateListState(listRequest);
    };


    onSubmitError(): void {
        console.log("submit error!")
    }

    formDidUpdate(formModel: TransactionSearchRequest, validationMap?: any): void {
        this.setState({form: formModel, validationMap: validationMap});
    }

    pagerChanged(pager: PagerModel) {
        if (this.state.listRequest) {
            let listRequest = ListUtils.toListRequest<any>(this.state.listRequest, pager);
            this.updateListState(listRequest);
        }
    }

    getTitle() {
        return "Transactions"
    }

    onAction(item: Transaction, action: string): Promise<any> | undefined {
        return;
    }


    renderContent() {
        if (!this.state.listRequest) {
            return <div/>;
        }
        return (
            <Fragment>
                <div className="row">
                    <div className={"col-lg-6"}>
                        <div className="card-box">
                            <h4>Find By Transaction ID:</h4>
                            <Form formListener={this} modelObject={this.state.form}
                                  validationMap={this.state.validationMap}>
                                <FormGroup className={"form-group"} model="txId">
                                    <FormInput model={"txId"}
                                               className={"form-control"} onFocus={() => this.clearForById()}/>
                                </FormGroup>
                                <Submit className={"btn btn-primary"}>Find</Submit>
                            </Form>
                        </div>
                    </div>
                </div>
                <div className="card-box expandable-box" style={!this.state.lookupExpanded ? {cursor:"pointer"} : {cursor:"default"}} onClick={() => !this.state.lookupExpanded ? this.setState({lookupExpanded:true}) : null}>
                    {this.state.lookupExpanded && (
                        <a className="expand-button float-right" onClick={()=>this.setState({lookupExpanded : false})}><i className="mdi mdi-minus"></i></a>
                    )}
                    {!this.state.lookupExpanded && (
                        <a className="expand-button float-right" onClick={()=>this.setState({lookupExpanded : true})}><i className="mdi mdi-plus"></i></a>
                    )}
                    <h4 className="header-title mb-3" onClick={()=>this.setState({lookupExpanded : !this.state.lookupExpanded})}>Advance Search</h4>
                    {this.state.lookupExpanded &&( <div className="row">
                    <div className={"col-lg-6"}>
                        <div className="card-box">
                            <h4>Search By Attribute & Account ID:</h4>
                            <Form formListener={this} modelObject={this.state.form}
                                  validationMap={this.state.validationMap}>
                                <FormGroup className={"form-group"} model="accountId">
                                    <label htmlFor={"ipSearchStartDate"}>Account ID:</label>
                                    <FormInput model={"accountId"}
                                               className={"form-control"} onFocus={() => this.clearForAccountAndAttributes()}/>
                                </FormGroup>
                                <FormGroup className={"form-group"} model="attributeKey">
                                    <label htmlFor={"ipSearchStartDate"}>Attribute Key:</label>
                                    <FormInput model={"attributeKey"}
                                               className={"form-control"} onFocus={() => this.clearForAccountAndAttributes()}/>
                                </FormGroup>
                                <FormGroup className={"form-group"} model="attributeValue">
                                    <label htmlFor={"ipSearchStartDate"}>Attribute Value:</label>
                                    <FormInput model={"attributeValue"}
                                               className={"form-control"} onFocus={() => this.clearForAccountAndAttributes()}/>
                                </FormGroup>
                                <Submit className={"btn btn-primary"}>Search</Submit>
                            </Form>
                        </div>
                    </div>
                        <div className={"col-lg-6"}>
                            <div className="card-box">
                                <h4>Search By Transaction Date:</h4>
                                <Form formListener={this} modelObject={this.state.form}
                                      validationMap={this.state.validationMap}>
                                    <FormGroup className={"form-group"}>
                                        <label htmlFor={"startDate"}>{t(`label.fromDate`)}:</label>
                                        <FormInput model={"fromDate"} className={"form-control"} type={"date"} onFocus={() => this.clearForDates()}/>
                                        <label htmlFor={"endDate"}>{t(`label.toDate`)}:</label>
                                        <FormInput model={"toDate"} className={"form-control"} type={"date"} onFocus={() => this.clearForDates()}/>
                                        <span className={"help-text"}>{t(`helpText.dateInput`)}</span>
                                    </FormGroup>
                                    <Submit className={"btn btn-primary"}>Search</Submit>
                                </Form>
                            </div>
                        </div>
                </div>)}
                </div>
                <EntityDataTable service={this.service}
                                 onPagerChange={this.pagerChanged.bind(this)}
                                 columns={this.columns}
                                 listRequest={this.state.listRequest}
                                 onAction={this.onAction.bind(this)}
                                 disableCount={true}
                                 actions={this.actions}/>
            </Fragment>
        );
    }


    private clearForById() {
        this.setState({form: {...this.state.form, attributeKey:undefined, attributeValue:undefined, fromDate:undefined, toDate:undefined, accountId:undefined}})
    }
    private clearForDates() {
        this.setState({form: {...this.state.form, txId:undefined,attributeKey:undefined, attributeValue:undefined, accountId:undefined}})
    }
    private clearForAccountAndAttributes() {
        this.setState({form: {...this.state.form,txId:undefined, fromDate:undefined, toDate:undefined}})
    }
}

export default withRouter(TransactionListView);
