import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {HttpService} from "../http/HttpService";
import {
    AssignRewardToUserRequest,
    ListUserGameRewardRequest,
    RewardTemplate,
    RewardTemplateListResponse,
    TemplateItem,
    UserGameRewardQueryResult,
} from "../http/protocol";

export class RewardsConfigService extends AbstractAdminEntityService<RewardTemplate, RewardTemplateListResponse> implements RewardsConfigService {

    constructor(http: HttpService) {
        super("/reward-template", http);
    }

    saveRewardTemplate(reward: RewardTemplate): Promise<any> {
        return this.http.post(this.entityPath + '/create/', reward);
    }
    removeRewardFromUser(id : number) : Promise<{}>{
        return this.http.delete("/user-game-reward/"+id);
    }

    updateRewardTemplate(reward: RewardTemplate): Promise<any> {
        return this.http.put(this.entityPath + '/update/' + reward.id, reward);
    }

    assignRewardToUser(form: AssignRewardToUserRequest) {
        return this.http.post("/user-game-reward/assign-reward-to-user", form);
    }

    getReward(id: number): Promise<RewardTemplate> {
        return this.http.get(this.entityPath + "/" + id.toString());
    }

    listUserRewards(req: ListUserGameRewardRequest): Promise<UserGameRewardQueryResult> {
        return this.http.post("/user-game-reward/list", req)
    }

    getBetbyTemplates(): Promise<TemplateItem[]> {
        return this.http.get(this.entityPath + '/betby/bonus/templates');
    }
}
