import * as React from "react";
import {RouterView, LoadingViewState} from "./RouterView";
import {ListRequest, UnorderedListRequest} from "../http/protocol";
import {extractPathParams, toPathParams} from "../util/UrlUtils";
import {RouteComponentProps} from "react-router";
import {PagerModel} from "./list/list/PagerModel";
import {ListUtils} from "./list/list/ListUtils";
import * as _ from "lodash";


export interface ListViewState<E extends UnorderedListRequest> extends LoadingViewState {
    listRequest? : E;
    path: string;
}


export interface ListViewProps extends RouteComponentProps {

}

/**
 * Creates initial state where both the form and list request have the same values,
 * The form and list request will be populated with path params and takes precedence
 * over the initialForm
 * @param initialForm
 * @param initialListViewState
 */
export function createInitialState<E extends ListRequest>(initialForm : E, initialListViewState : any){
    console.log("Creating initial state");
    let initial = Object.assign({}, initialForm, initialListViewState.listRequest);
    return Object.assign({}, initialListViewState, { validationMap : {}, form : initial, listRequest : initial });
}


export abstract class ListView<S extends ListViewState<E>,E extends UnorderedListRequest> extends RouterView<ListViewProps,S> {

    path : string;
    initial? : any;

    constructor(props : ListViewProps, path : string) {
        super(props);

        let state = {path : path, listRequest : { offset : 0, limit : 20, ascending : false } };
        extractPathParams(state.listRequest, props.location.pathname.replace(path,""));
        this.state = state as any;
        this.path = path;


    }

    componentDidMount() {
        this.initial = this.state.listRequest;
        console.log("Initial state = ", this.initial);
        super.componentDidMount();
    }
    componentDidUpdate(prevProps : ListViewProps, prevState : S) {
        console.log("componentDidUpdate", prevProps, prevState);

        let splat = this.props.location.pathname.replace(this.state.path,"");
        if(prevProps.location.pathname.toString() === splat) {
            console.log("this.props.params.splat " + splat);
            if(!splat || splat == "") {
                this.setState(({listRequest : this.initial} as any));
            } else {
                let req = {};

                extractPathParams(req, this.props.location.pathname.replace(this.state.path,""));
                this.updateListState(req as any);
            }

        }
    }

    loadContent() : Promise<any> | undefined {
        return undefined;
    }

    pagerChanged(pager : PagerModel) {
        if(this.state.listRequest) {
            console.log("pager changed!", pager);
            let listRequest = ListUtils.toListRequest<E>(this.state.listRequest as any,pager);
            this.updateListState(listRequest);
        }
    }

    updateListState(listRequest : E) {
        if(_.isEqual(listRequest, this.initial)) {
            this.updatePathParams(this.state.path, {});
        } else {
            this.updatePathParams(this.state.path, listRequest);
        }
        if(toPathParams(listRequest) != toPathParams(this.state.listRequest)) {
            setTimeout(()=> this.setState(({listRequest : listRequest, form : listRequest} as any)), 0);
        }
    }

    abstract renderContent():JSX.Element;

}