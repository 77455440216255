import * as React from "react";

import {RouteComponentProps, withRouter} from "react-router";
import {LoadingViewState, RouterView} from "../RouterView";
import {Account, AccountConstraints} from "../../http/protocol";
import {Form, FormListener} from "../form/Form";
import {WalletService} from "../../services/WalletService";
import {TransactionEntriesService} from "../../services/TransactionEntriesService";
import {AccountService} from "../../services/AccountService";
import {getContainer} from "../../ioc/IOCSetup";
import {Link} from "react-router-dom";
import {FormGroup} from "../form/FormGroup";
import {FormInput, FormMap} from "../form/FormInput";
import {SelectInput} from "../form/SelectInput";
import {Validators} from "../form/Validators";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {Submit} from "../form/Submit";
import {t} from "../../i18n";
import _ from "lodash";



interface State extends LoadingViewState {
    account? : Account;
    constraints? : AccountConstraints;
    validationMap? : any;
}

export const ACCOUNT_ROLES = ["MAIN", "BONUS", "RAKE", "PROMOTION", "WALLET"]
export class EditAccountView extends RouterView<RouteComponentProps,State> implements FormListener<Account>{

    walletService : WalletService;
    transactionEntriesService : TransactionEntriesService;
    accountService : AccountService;

    constructor(props : RouteComponentProps){
        super(props);
        this.walletService = getContainer().getWalletService();
        this.transactionEntriesService =getContainer().getTransactionEntriesService();
        this.accountService = getContainer().getAccountService();
        this.state = { account : { attributes : { }, information : {}}, validationMap : {}};
    }

    getTitle(): string {
        return t(`editCreateAccount.pageTitle`);
    }

    componentDidUpdate(prevProps : RouteComponentProps, prevState : State) {
        if((prevProps.match.params as any).id != (this.props.match.params as any).id)  {
            this.refreshContent();
        }
    }

    loadContent(): Promise<any>  {
        if((this.props.match.params as any).id == "new") {
            return this.accountService.getAccountConstraints().then( c => this.setState({constraints : c, account : { attributes : { }, information : {}}}));
        } else {
            return Promise.all<any>([
                this.walletService.getAccount((this.props.match.params as any).id),
                this.accountService.getAccountConstraints()
            ]).then((res : any[])=> {
                let a : Account = res[0];
                let c : AccountConstraints = res[1];
                this.setState({account : a, constraints : c});
            });
        }

    }

    onSubmit()  {
        if(!this.state.account) return;
        return this.accountService.save(this.state.account).then((a : Account)=> {
            if((this.props.match.params as any).id== "new") {
                this.navigate("/account/edit/"+ a.id);
            } else {
                this.setState({account : a});
            }
        })
    }

    formDidUpdate(formModel: Account, validationMap?: any) {
        this.setState({account : formModel, validationMap : validationMap})
    }
    addAttribute(key:string,value:string) {
        if(!this.state.account) return;
        let kvp : any= {};
        kvp[key] = value;
        let attributes = Object.assign({}, this.state.account.attributes, kvp);;
        this.setState({ account : Object.assign({}, this.state.account , { attributes : attributes})})
    }
    removeAttribute(key : string) {
        if(!this.state.account) return;
        let attributes = Object.assign({}, this.state.account.attributes);
        delete attributes[key];
        this.setState({ account : Object.assign({}, this.state.account , { attributes : attributes})})
    }

    renderContent() {
        if(!this.state.account || !this.state.constraints) {
            return <span></span>
        }

        return (
            <div>


                <div className="wrapper wrapper-content animated fadeInRight">
                    <Form className="form form-horizontal" validationMap={this.state.validationMap} modelObject={this.state.account} formListener={this}>
                        {
                            (this.props.match.params as any).id != "new" && (
                                <div className="card-box">
                                    <Link className="btn btn-primary" to={"/account/" + (this.props.match.params as any).id}>{t(`button.showAccount`)}</Link>
                                </div>
                            )
                        }
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card-box">
                                    <h5>{t(`title.accountInfo`)}</h5>
                                    <div className="ibox-content">

                                            {(this.props.match.params as any).id != "new" &&
                                                <div>
                                                    <div className="hr-line-dashed"></div>
                                                    <FormGroup className="form-group">
                                                        <label className="col-sm-2 control-label">ID</label>
                                                        <div className="col-sm-10">
                                                            <FormInput  model="id" type="integer"  className="form-control" disabled={true}/>
                                                        </div>
                                                    </FormGroup>
                                                </div>
                                            }

                                            <div className="hr-line-dashed"></div>
                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">{t(`label.accountName`)}</label>
                                                <div className="col-sm-10">
                                                    <FormInput  model="information.name" type="text"  className="form-control" />
                                                </div>
                                            </FormGroup>

                                            { (this.props.match.params as any).id != "new" && (
                                                <div>
                                                    <div className="hr-line-dashed"></div>
                                                    <FormGroup className="form-group">
                                                        <label className="col-sm-2 control-label">{t(`label.created`)}</label>
                                                        <div className="col-sm-10">
                                                            <FormInput  model="created"  type="integer" className="form-control"/>
                                                            <span className="help-block m-b-none">{t(`helpText.createdAccount`)}</span>
                                                        </div>
                                                    </FormGroup>

                                                    <div className="hr-line-dashed"></div>
                                                    <FormGroup className="form-group">
                                                        <label className="col-sm-2 control-label">{t(`label.closed`)}</label>
                                                        <div className="col-sm-10">
                                                            <FormInput  model="closed"  type="integer" className="form-control"/>
                                                            <span className="help-block m-b-none">{t(`helpText.closedAccount`)}</span>
                                                        </div>
                                                    </FormGroup>
                                                </div>
                                            )}



                                            <div className="hr-line-dashed"></div>
                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">{t(`label.type`)}</label>
                                                <div className="col-sm-10">
                                                    <SelectInput  model="type" values={this.state.constraints.types} className="form-control"/>
                                                </div>
                                            </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">{t(`label.subType`)}</label>
                                            <div className="col-sm-10">
                                                <SelectInput optional={true} model="subtype" values={this.state.constraints.subtypes} className="form-control"/>
                                            </div>
                                        </FormGroup>

                                            <div className="hr-line-dashed"></div>

                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">{t(`label.role`)}</label>
                                                <div className="col-sm-10">
                                                    <SelectInput  model="attributes.ROLE" optional={true} values={ACCOUNT_ROLES} className="form-control"/>
                                                </div>
                                            </FormGroup>

                                            <div className="hr-line-dashed"></div>


                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">{t(`label.currency`)}</label>
                                                <div className="col-sm-10">
                                                    <FormInput  model="currencyCode"  validators={[Validators.required(), Validators.strLength(3,4)]}  type="text" className="form-control"/>
                                                </div>
                                            </FormGroup>

                                            <div className="hr-line-dashed"></div>

                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">{t(`label.userId`)}</label>
                                                <div className="col-sm-10">
                                                    <FormInput  model="userId"  type="integer" className="form-control"/>
                                                    <span className="help-block m-b-none">{t(`helpText.userId`)}</span>
                                                </div>
                                            </FormGroup>

                                            <div className="hr-line-dashed"></div>

                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">{t(`label.operatorId`)}</label>
                                                <div className="col-sm-10">
                                                    <FormInput  model="operatorId"  type="integer" className="form-control"/>
                                                    <span className="help-block m-b-none">{t(`helpText.operatorId`)}</span>
                                                </div>
                                            </FormGroup>

                                            <div className="hr-line-dashed"></div>

                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">{t(`label.affiliateId`)}</label>
                                                <div className="col-sm-10">
                                                    <FormInput  model="affiliateId"  type="integer" className="form-control"/>
                                                    <span className="help-block m-b-none">{t(`helpText.affiliateId`)}</span>
                                                </div>
                                            </FormGroup>
                                            <div className="hr-line-dashed"></div>

                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">{t(`label.status`)}</label>
                                                <div className="col-sm-10">
                                                    <SelectInput  model="status" values={this.state.constraints.statuses} className="form-control"/>
                                                </div>
                                            </FormGroup>
                                            <div className="hr-line-dashed"></div>
                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">{t(`label.negativeBalanceAllowed`)}</label>
                                                <div className="col-sm-10">
                                                    <FormInput  model="negativeAmountAllowed" type="checkbox" />
                                                    <span className="help-block m-b-none">{t(`helpText.negativeBalanceAllowed`)}</span>
                                                </div>
                                            </FormGroup>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {(this.props.match.params as any).id != "new" && (
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card-box">
                                        <h5>{t(`title.accountAttributes`)}</h5>
                                        <FormMap mapModel="attributes"
                                                 removeAttribute={(key? : string)=> key && this.removeAttribute(key)}
                                                 addAttribute={(key?, value?)=> key && value && this.addAttribute(key,value)}/>
                                        <div className="hr-line-dashed"></div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card-box">

                                    <FormSubmitError />
                                    <FormSubmitSuccess text={t(`alertInfo.accountSuccessfully`)}/>
                                    <div className="form-group">
                                        <div className="col-sm-12">
                                            <Submit className="btn  btn-primary">{t(`button.save`)}</Submit>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }

}

export default withRouter(EditAccountView);