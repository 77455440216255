import React, {Fragment} from "react";
import {ActionType} from "../list/list/DataTable";
import {BonusConfig, BonusOffer, BonusOfferListRequest, BonusOfferStatus, ListRequest} from "../../http/protocol";
import {getContainer} from "../../ioc/IOCSetup";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {PagerModel} from "../list/list/PagerModel";
import {ListUtils} from "../list/list/ListUtils";
import {withRouter} from "react-router";
import {ListView, ListViewProps, ListViewState} from "../ListView";
import {Form, FormListener} from "../form/Form";
import {FormGroup} from "../form/FormGroup";
import {Submit} from "../form/Submit";
import {ColumnType} from "../list/list/ColumnType";
import {SelectInput} from "../form/SelectInput";
import {BonusOfferService} from "../../services/BonusOfferService";
import {FormInput} from "../form/FormInput";
import {Validators} from "../form/Validators";
import {t} from "../../i18n";



interface State extends ListViewState<BonusOfferListRequest> {
    form? : BonusOfferListRequest;
    validationMap? : any;
}
interface Props extends ListViewProps  {

}

export class BonusOfferListView extends ListView<State, BonusOfferListRequest> implements FormListener<BonusOfferListRequest>{

    columns = [
        { property : "id", name : "ID", orderBy : "id"},
        { property : "userId", name : "User Id", type : ColumnType.LINK, getUrl : (item : BonusOffer) => "/user/"+item.userId},
        { property:  "bonusConfig.id" , name : "Bonus Config Id", type:  ColumnType.LINK, getUrl : (item : BonusOffer) => { return item.bonusConfig ? "/bonus/config/"+item.bonusConfig?.id : false}},
        { property:  "bonusConfig.name" , name : "Bonus Name"},
        { property: "expires", name : "Expires", type : ColumnType.DATE},
        { property: "status", name : "Status"}
    ];

    actions = [
        { id : "delete", name : "Delete", className : "btn btn-danger", iconClassName : "fa fa-times", type : ActionType.CONFIRMATION},
        { id : "view", name : "View", className : "btn btn-primary", iconClassName : "fa fa-user", type : ActionType.LINK, getLink : (item : any) => "/bonus/offer/" + item.id }

    ];

    service : BonusOfferService;

    constructor(props : Props){
        super(props, "/bonus/offers/list");
        this.state = {...this.state, validationMap : {}, form : {...this.state.listRequest} as any};
        this.service = getContainer().getBonusOfferService();
    }

    onSubmit() : void | Promise<any> {
        if(this.state.form) {
            let form = this.state.form;
            form.offset = 0;
            this.updateListState(form);
        }
    }
    
    onSubmitError() :  void {

    }

    formDidUpdate(formModel: ListRequest, validationMap?: any): void {
        this.setState({form : formModel, validationMap : validationMap});
    }

    onAction(item : BonusConfig, action : string) : Promise<any> | undefined {
        if(action == "delete") {
            return  this.service.remove(""+item.id);
        }
    }

    pagerChanged(pager : PagerModel) {
        if(this.state.listRequest) {
            console.log("pager changed!", pager);
            let listRequest = ListUtils.toListRequest<any>(this.state.listRequest,pager);
            this.updateListState(listRequest);
        }
    }
    getTitle() {
        return t(`listBonusOffers.pageTitle`);
    }

    renderContent() {
        if(!this.state.listRequest) {
            return <div/>;
        }
        return (
            <Fragment>
                <div className={"card-box"}>
                    <h4>{t(`title.searchFilters`)}</h4>
                    <Form formListener={this} modelObject={this.state.form} validationMap={this.state.validationMap}>
                        <FormGroup className={"form-group"}>
                            <label>{t(`label.userId`)}</label>
                            <FormInput model={"userId"} className={"form-control"} type={"integer"} validators={[Validators.integer()]}/>
                        </FormGroup>
                        <FormGroup className={"form-group"}>
                            <label>{t(`label.status`)}</label>
                            <SelectInput optional={true} model={"status"} className={"form-control"} values={Object.keys(BonusOfferStatus)}/>
                            <span className={"help-text"}>{t(`helpText.searchBonusWithStatus`)}</span>
                        </FormGroup>
                        <Submit className={"btn btn-primary"}>{t(`button.search`)}</Submit>
                    </Form>
                </div>
                <EntityDataTable service={this.service}
                                 onPagerChange={this.pagerChanged.bind(this)}
                                 columns={this.columns}
                                 listRequest={this.state.listRequest}
                                 onAction={this.onAction.bind(this)}
                                 actions={this.actions}/>
            </Fragment>
        );
    }



}

export default withRouter(BonusOfferListView);
