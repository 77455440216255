import React, {Fragment} from "react";
import {ActionType} from "../list/list/DataTable";
import {
    BonusConfig,
    BonusOffer, MonetaryConfig,
    MonetaryConfigListRequest,
    TurnoverContributions,
    TurnoverContributionsListRequest
} from "../../http/protocol";
import {getContainer} from "../../ioc/IOCSetup";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {PagerModel} from "../list/list/PagerModel";
import {ListUtils} from "../list/list/ListUtils";
import {withRouter} from "react-router";
import {ListView, ListViewProps, ListViewState} from "../ListView";
import {FormListener} from "../form/Form";
import {MonetaryConfigService} from "../../services/MonetaryConfigService";
import {ColumnType} from "../list/list/ColumnType";
import { BonusConfigService } from "../../services/BonusConfigService";
import { Link } from "react-router-dom";


interface State extends ListViewState<MonetaryConfigListRequest> {
    form? : MonetaryConfigListRequest;
    validationMap? : any;
    bonusConfigs?: BonusConfig[];
}
interface Props extends ListViewProps  {

}

export class MonetaryConfigListView extends ListView<State, MonetaryConfigListRequest> implements FormListener<MonetaryConfigListRequest>{

    columns = [
        { property : "id", name : "ID", orderBy : "id"},
        { property : "name", name : "Name"},
        { property: "currencyCode", name : "Currency", orderBy: "currency"},
        { property: "awardType", name : "Award Type" },
        { property: "bonusPaymentType", name : "Payment Type" },
        { property: "cashRewardTemplateId", name : "Reward Template ID",  type:  ColumnType.LINK, getUrl : (item : MonetaryConfig) => { return item.cashRewardTemplateId ? "/rewards/config/"+item.cashRewardTemplateId : false} },
        { property: "turnoverRequirementFactor", name : "Turnover Requirement" },
        { property: "awardAmountFraction", name : "Award Amount Fraction"},
        { property: "configs", name : "Bonus Configurations", type: ColumnType.COMPONENT, component: (item: MonetaryConfig) => <>{this.renderBonusConfigs(item)}</> },
    ];

    actions = [
        { id : "delete", name : "Delete", className : "btn btn-danger", iconClassName : "fa fa-times", type : ActionType.CONFIRMATION, show: (mc: MonetaryConfig) => this.canDelete(mc)  },
        { id : "view", name : "View", className : "btn btn-primary", iconClassName : "fa fa-user", type : ActionType.LINK, getLink : (item : any) => "/bonus/monetary-config/" + item.id }

    ];

    service : MonetaryConfigService;
    bonusService: BonusConfigService;

    constructor(props : Props){
        super(props, "/bonus/monetary-configs/list");
        this.state = {...this.state, bonusConfigs: [], validationMap : {}, form : {...this.state.listRequest}};
        this.service = getContainer().getMonetaryConfigService();
        this.bonusService = getContainer().getBonusConfigService();
    }

    async loadContent() {
        const bc = await this.bonusService.list({limit: 1000, offset: 0})
        this.setState({bonusConfigs: bc.items})
    }
  
    onSubmit() : void | Promise<any> {
        if(this.state.form) {
            this.updateListState(this.state.form);
        }
    }
    onSubmitError() :  void {

    }

    formDidUpdate(formModel: TurnoverContributionsListRequest, validationMap?: any): void {
        this.setState({form : formModel, validationMap : validationMap});
    }

    onAction(item : TurnoverContributions, action : string) : Promise<any> | undefined {
        if(action == "delete") {
            return  this.service.remove(""+item.id);
        }
    }

    pagerChanged(pager : PagerModel) {
        if(this.state.listRequest) {
            console.log("pager changed!", pager);
            let listRequest = ListUtils.toListRequest<any>(this.state.listRequest,pager);
            this.updateListState(listRequest);
        }
    }
    getTitle() {
        return "Monetary Configs";
    }

    canDelete(mc: MonetaryConfig) {
        const c = !this.state.bonusConfigs?.some(bc => bc.monetaryConfig?.id == mc.id)
        return c
    }

    renderBonusConfigs(mc: MonetaryConfig) {
        const bcs =this.state.bonusConfigs
            ?.filter(bc => bc.monetaryConfig?.id == mc.id)
            .map(bc => <li key={bc.id}><Link to={`/bonus/config/${bc.id}`}>{bc.name}</Link></li>);

        return <ul>{bcs}</ul>
    }

    renderContent() {
        if(!this.state.listRequest) {
            return <div/>;
        }
        return (
            <Fragment>
                <EntityDataTable service={this.service}
                                 onPagerChange={this.pagerChanged.bind(this)}
                                 columns={this.columns}
                                 listRequest={this.state.listRequest}
                                 onAction={this.onAction.bind(this)}
                                 actions={this.actions}/>
            </Fragment>
        );
    }



}

export default withRouter(MonetaryConfigListView);
