import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {Account, AccountConstraints, Currency, ListAccountsResponse} from "../http/protocol";
import {HttpService} from "../http/HttpService";


export class AccountService extends AbstractAdminEntityService<Account, ListAccountsResponse> implements AccountService{



    constructor(http : HttpService) {
        super("/wallet/account", http);
    }

    closeAccount(id: number): Promise<{}> {
        return this.http.get(this.entityPath + "/close/" + id);
    }

    closeAccountAndTransferToUser(id: number): Promise<{}> {
        return this.http.get(this.entityPath + "/close-and-transfer/" + id);
    }

    openAccount(id: number): Promise<{}> {
        return this.http.get(this.entityPath + "/open/" + id);
    }


    getAccountConstraints(): Promise<AccountConstraints> {
        return this.http.get(this.entityPath + "/constraints/all");
    }
    getCurrencies() : Promise<Currency[]> {
        return this.http.get(this.entityPath + "/currencies/list");
    }

}