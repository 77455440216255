import {CardBox, RouterViewTemplate, useLoadingState} from "../RouterView";
import {getContainer} from "../../ioc/IOCSetup";
import * as React from "react";
import {useState} from "react";
import {MaintenanceWindow} from "../../http/protocol";
import {Link} from "react-router-dom";
import {toCategoryName} from "./MaintenanceView";
import {formatDate} from "../../util/date";


export const ListMaintenanceView = () => {


    const [maintenanceWindows, setMaintenanceWindows] = useState<MaintenanceWindow[]>([]);
    const status = useLoadingState(()=>{
        return getContainer().getMaintenanceService().get().then((mw) => {
            setMaintenanceWindows(mw);
        })

    },[]);

    return (
        <RouterViewTemplate status={status}>
            <CardBox>
                <Link className={"btn btn-primary"} to={"/maintenance/new"}>Create Maintenance</Link>
            </CardBox>
            <CardBox>
                <table className={"table table-striped"}>
                    <tr>
                        <th>Category</th>
                        <th>Enabled</th>
                        <th>Announce</th>
                        <th>Start</th>
                        <th>Action</th>
                    </tr>
                    <tbody>
                    {maintenanceWindows.map(mw => (
                        <tr key={mw.id}>
                            <td>{toCategoryName(mw.category)}</td>
                            <td className={mw.enabled ? "green" : "red"}>{mw.enabled ? "YES" : "NO"}</td>
                            <td>{formatDate(mw.announceBeforeStart)}</td>
                            <td>{formatDate(mw.start)}</td>
                            <td><Link className="btn btn-danger" to={"/maintenance/"+mw.id}>Edit</Link></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </CardBox>
        </RouterViewTemplate>
    )
}
