import React from "react";
import {getContainer} from "../../ioc/IOCSetup";
import {ListRequest, UserBonusStatus, UserBonusView, UserLevelAdminView} from "../../http/protocol";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {ColumnType} from "../list/list/ColumnType";
import {ActionType} from "../list/list/DataTable";
import {UserBonusService} from "../../services/UserBonusService";
import { Link } from "react-router-dom";
import {t} from "../../i18n";
import { UserService } from "../../services/UserService";
import { Collapsible } from "../Collapsible";

interface State  {
    userLevel?: UserLevelAdminView
}

interface Props {
    userId: number
}

export class UserLevelComponent extends React.Component<Props,State> {
    userService: UserService

    constructor(props:Props) {
        super(props);
        this.userService = getContainer().getUserService()
        this.state = {}
    }

    async componentDidMount() {
        const level = await this.userService.getUserLevel(this.props.userId)
        this.setState({userLevel: level})
    }

    render() {
        const level = this.state.userLevel

        return(
            <div className="col-lg-12">
                <Collapsible title={t(`title.userLevel`)} collapsed={true}>
                    { level  &&
                        <table className="table table-striped">
                            <tbody>
                                <tr><th>{t('userLevelComponent.level')}</th><td>{level.currentLevelName ?? 'N/A'}</td></tr>
                                <tr><th>{t('userLevelComponent.xpProgression')}</th><td>{level.currentXp} / {level.nextLevelXp} ({level.nextLevelProgressionPercentage}%)</td></tr>
                                <tr><th>{t('userLevelComponent.nextLevel')}</th><td>{level.nextLevelName}</td></tr>
                            </tbody>
                        </table>
                    }   
                </Collapsible>
            </div>
        )
    }
}

export default UserLevelComponent;