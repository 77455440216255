import {HttpService} from "../http/HttpService";
import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {CasinoLobbyCategory, CasinoStudio, ListCasinoLobbyCategoryResponse, ListStudioResponse} from "../http/protocol";


export class CasinoStudioService  extends AbstractAdminEntityService<CasinoStudio, ListStudioResponse>  {

    constructor(http : HttpService) {
        super("/casino-studio", http)
    }

}