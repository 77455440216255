import React, {Fragment} from "react";
import {Form, FormListener} from "../form/Form";
import {AdminMfaProvider, AdminSession, AdminUser, PermissionCategory, UserGroup} from "../../http/protocol";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Submit} from "../form/Submit";
import {AdminUserService} from "../../services/AdminUserService";
import {getContainer} from "../../ioc/IOCSetup";
import {UserGroupService} from "../../services/UserGroupService";
import {RouteComponentProps, withRouter} from "react-router";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {LoadingViewState, RouterView} from "../RouterView";
import * as _ from "lodash";
import {Validators} from "../form/Validators";
import {Link} from "react-router-dom";
import {DeleteButtonModal} from "../../util/DeleteButtonModal";
import {PermissionRequired} from "../security/PermissionRequired";
import {formatInstant} from "../../util/date";
import {ConfirmModal} from "../../util/ConfirmModal";

interface State extends LoadingViewState {
    user? : AdminUser;
    userGroups? : UserGroup[];
    validationMap? : any;
    successText?: string;
    tempPassword? : string;
    resetPasswordInProgress? : boolean;
    sessions?: AdminSession[];
    showMfaResetModal?: boolean;
}

interface Props extends RouteComponentProps {

}

export class AdminUserView extends RouterView<Props,State> implements FormListener<AdminUser>{

    service : AdminUserService;
    groupService : UserGroupService;
    constructor(props : Props){
        super(props);
        this.service = getContainer().getAdminUserService();
        this.groupService = getContainer().getUserGroupService();
        this.state = {showMfaResetModal: false, validationMap : {}, loading : true, user : {}, userGroups : [], successText : "", tempPassword : "", resetPasswordInProgress : false};
    }

    async loadContent()  {
        const id = (this.props.match.params as any).id;
        const groups = await this.groupService.list({limit: 1000, offset: 0, orderBy: "id", ascending: false})
        let user;

        if(id  &&  id != "new") {
            user = await this.service.getEntity(id)
        } else {
            user = { groups: [] }
        }
        

        this.setState({loading: false, user: user, userGroups: groups.items, sessions: undefined });
    }
    componentDidUpdate(prevProps : RouteComponentProps, prevState : any) {
        if((this.props.match.params as any).id != (prevProps.match.params as any).id) {
            this.refreshContent();
        } else {
            super.componentDidUpdate(prevProps, prevState);
        }
    }

    onSubmit():Promise<any> {
        if(!this.state.user) {
            return Promise.resolve();
        }
        this.state.user.adminMfaProvider = AdminMfaProvider.EMAIL // this enum is not used in the UI, so we set it here
        return this.service.save(this.state.user).then( (user : AdminUser) => {
            let pass = user.password;
            user.password = undefined;

            if((this.props.match.params as any).id == "new") {
                this.setState({user : user, successText : "User was created with password: " + pass})
            } else {
                this.setState({user : user, successText : "User was updated"})
            }

        }).catch(err=>{
            throw "Unable to create/save user";
        });
    }
    onSuccessClose() {
        if((this.props.match.params as any).id == "new") {
            this.setState({successText : ""});
            if(this.state.user) {
                this.navigate("/admin/admin-user/"+this.state.user.id);
            }
        }
    }
    onSubmitError(){}

    formDidUpdate(formModel:AdminUser, validationMap : any) {
        this.setState({user : formModel, validationMap : validationMap});
    }

    getFormModel():AdminUser|undefined {
        return this.state.user;
    }

    getTitle() : string {
        return "Edit/Create Admin User";
    }

    toggleNameId(id?: number) {
        if(!id) {
            return;
        }
        var user = this.state.user;
        if(user && user.groups) {
            user  = _.clone(user) as AdminUser;
            var index : number = -1;
            if(user.groups) {
                index = user.groups.indexOf(id);
            }
            if(index != -1) {
                user.groups && user.groups.splice(index,1)
            } else {
                user.groups && user.groups.push(id);
            }
            this.setState({ user : user });
            console.log(user.groups);
        }
    }
    isEnabled(id : number | undefined) : boolean {
        if(!id || !this.state.user || !this.state.user.groups) {
            return false;
        }
        return this.state.user.groups.indexOf(id) != -1
    }
    resetPassword() {
        if((this.props.match.params as any).id != "new") {
            this.setState({resetPasswordInProgress : true});
            if(this.state.user) {
                this.service.resetPassword(this.state.user.id).then( (u : AdminUser) => {
                    this.setState({resetPasswordInProgress : false, tempPassword : u.password})
                }).catch(e => {
                    this.setState({resetPasswordInProgress : false})
                })
            }

        }
    }
    getUserGroups(){
        if(!this.state.userGroups) {
            return <span></span>
        }
        return this.state.userGroups.map( (g : UserGroup) => {
            return (
                <tr key={g.id}>
                    <td><input type="checkbox" checked={this.isEnabled(g.id)} onChange={this.toggleNameId.bind(this,g.id)}/></td>
                    <td>{g.id}</td>
                    <td><Link className="" style={{marginLeft: "2px"}} to={`/admin/user-group/${g.id}`}>{g.name}</Link></td>
                </tr>
            );
        });
    }

    async invalidateSessions() {
        this.service.invalidateSessions(this.state.user!.id!)
        return this.loadContent()
    }


    async loadSessions() {
        const sessions = await this.service.getSessions(this.state.user!.id!)
        this.setState({sessions: sessions})
    }


    renderSessionRows() {

        if (!this.state.sessions) {
            this.loadSessions()
            return []
        }

        const sessionRows = this.state.sessions?.map(s => <tr key={s.toString()}>
                <td>{new Date(s.created!).toString()}</td>
                <td>{formatInstant(s.lastAccess!)}</td>
                <td>{s.remoteAddr}</td>
                <td>{s.agent}</td>
            </tr>)
        return sessionRows
    }

        async resetMfa() {
        try {
            await this.service.resetMfa(this.state.user!.id!)
            this.setState({showMfaResetModal: false})
            return this.loadContent()
        } catch (e) {
            console.error(e)
        }
    }

    renderContent() {
        return (
            <Fragment>
                    <Form className="form-horizontal" formListener={this} modelObject={this.state.user} validationMap={this.state.validationMap}>
                        <div className={"card"}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <FormSubmitError />
                                        <FormSubmitSuccess text="User was updated successfully"/>

                                        <FormGroup className="form-group mb-3" model="email">
                                            <label>E-mail</label>
                                            <FormInput className="form-control" model="email" type="text" validators={[Validators.required(), Validators.email()]}/>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group mb-3" model="firstName">
                                            <label>First Name</label>
                                            <FormInput className="form-control" model="firstName" type="text" validators={[Validators.required()]}/>
                                        </FormGroup>
                                        <div className="hr-line-dashed"></div>

                                        <FormGroup className="form-group mb-3" model="lastName">
                                            <label>Last Name</label>
                                            <FormInput className="form-control" model="lastName" type="text" validators={[Validators.required()]}/>
                                        </FormGroup>
                                        <div className="hr-line-dashed"></div>

                                        <FormGroup className="custom-control custom-checkbox" model="blocked">
                                            <FormInput id={"blocked"} className="custom-control-input" model="blocked" type="checkbox"/>
                                            <label htmlFor={"blocked"} className={"custom-control-label"}>Blocked</label>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>

                                        <FormGroup className="form-group" model="password">
                                            <label>Password</label>
                                            <div className="form-control" style={{paddingTop : "6px"}}>
                                                {(this.props.match.params as any).id == "new" && "Will be generated"}
                                                {(this.props.match.params as any).id != "new" && this.state.resetPasswordInProgress == true && "Please Wait..."}
                                                {(this.props.match.params as any).id != "new" && this.state.resetPasswordInProgress == false && this.state.tempPassword == "" && <a href="#" onClick={()=> this.resetPassword()}>Reset Password</a>}
                                                {(this.props.match.params as any).id != "new" && this.state.resetPasswordInProgress == false && this.state.tempPassword != "" && "Temporary password (valid for 24h): " + this.state.tempPassword}
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"card"}>
                            <div className={"card-body"}>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="ibox float-e-margins">
                                            <div className="ibox-title">
                                                <h5>User Groups</h5>
                                            </div>
                                            <div className="ibox-content">
                                                <table className="table table-striped dt-responsive nowrap dataTable no-footer dtr-inline">
                                                    <thead>
                                                    <tr>
                                                        <th>Include</th>
                                                        <th>Name Id</th>
                                                        <th>Name</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.getUserGroups()}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <PermissionRequired permissions={[PermissionCategory.ADMIN_USERS]} accessLevel="w">
                            <div className={"card"}>
                                <div className={"card-body"}>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="ibox float-e-margins">
                                                <div className="ibox-content">
                                                    <div className="ibox-title">
                                                        <h5>Sessions</h5>
                                                    </div>

                                                    <table className={"table table-striped"}>
                                                        <thead>
                                                            <tr>
                                                                <th>Created</th>
                                                                <th>Last Access</th>
                                                                <th>Remote Address</th>
                                                                <th>Agent</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.renderSessionRows()}
                                                        </tbody>
                                                    </table>

                                                    <div className="form-group">
                                                        <DeleteButtonModal className="btn btn-danger waves-effect waves-light"
                                                                           onDelete={() => this.invalidateSessions()}
                                                                           label="Logout All Sessions"
                                                                           description=". This will invalidate all sessions for this admin user."/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </PermissionRequired>

                        <div className={'form-group'}>
                            <ConfirmModal
                                show={this.state.showMfaResetModal}
                                heading="Reset MFA"
                                description={`Are you sure you want to reset MFA for ${this.state.user?.email}?`}
                                okLabel="Reset MFA"
                                okClassName="btn-danger"
                                onOk={() => this.resetMfa()}
                                onCancel={() => this.setState({showMfaResetModal: false})}
                            />
                        </div>
                        <PermissionRequired permissions={[PermissionCategory.ADMIN_USERS]} accessLevel="w">

                            <div className={"card"}>
                                <div className={"card-body"}>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="ibox float-e-margins">
                                                <div className="ibox-content">
                                                    <div className="ibox-title">
                                                        <h5>Two Factor Authentication</h5>
                                                    </div>
                                                    <div>
                                                            <div className="form-group mt-3">
                                                                <div className="col-sm-10 col-sm-offset-2">
                                                                    <p>
                                                                        When Two Factor Authentication (2FA) is reset, the authentication method will revert to the default, utilizing email. <br/>
                                                                        If the user has already set up an authentication app, it will be removed. <br/>
                                                                        The user has the option to reconfigure 2FA, including setting up the authentication app, by following the necessary steps, such as scanning a QR code.
                                                                    </p>
                                                                    {this.state.user?.adminMfaProvider == AdminMfaProvider.EMAIL && <strong><p>Current 2FA method: Email ✉️</p></strong>}
                                                                    {this.state.user?.adminMfaProvider == AdminMfaProvider.AUTHENTICATOR_APP && <strong><p>Current 2FA method: Authenticator App 📱</p></strong>}
                                                                    {this.state.user?.authAppMfaVerified
                                                                        ? <strong><p>User has Authenticator App is verified ✅</p></strong>
                                                                        : <strong><p>User has not Authenticator App is verified ❌</p></strong>
                                                                    }

                                                                    <DeleteButtonModal
                                                                        className="btn btn-danger waves-effect waves-light"
                                                                        onDelete={() => this.resetMfa()}
                                                                        label="Reset 2FA"
                                                                        description=". Are you sure you want to revert the 2FA method to the default, using Email?"
                                                                    />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </PermissionRequired>

                        <div className={"card"}>
                            <div className={"card-body"}>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="ibox float-e-margins">
                                            <div className="ibox-content">
                                                <div className="ibox-title">
                                                    <h5>Actions</h5>
                                                </div>
                                                <FormSubmitError />
                                                <FormSubmitSuccess text={this.state.successText} onClose={()=>this.onSuccessClose()}/>
                                                <div className="form-group">

                                                    <div className="col-sm-10 col-sm-offset-2">
                                                        <p><strong>IMPORTANT:</strong> Updating an admin will log out that admin from all sessions</p>
                                                        <Submit className="btn btn-primary waves-effect waves-light">Save User</Submit>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Form>


            </Fragment>
        )
    }

}

export default withRouter(AdminUserView);