import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {HttpService} from "../http/HttpService";
import {MetabaseDashboard} from "../ui/report/MetabaseDashboardListView";
import {Credentials} from "../http/protocol";


export class MetabaseService extends AbstractAdminEntityService<any,any> implements MetabaseService {
    constructor(http: HttpService) {
        super("/metabase", http);
    }

    async getMetabaseDashboardList():Promise<MetabaseDashboard[]> {
        return this.http.get(this.entityPath + '/dashboards')
    }

    async getMetabaseCardsList():Promise<MetabaseDashboard[]> {
        return this.http.get(this.entityPath + '/cards')
    }

    async getCredentials():Promise<Credentials> {
        return this.http.get(this.entityPath + '/credentials');
    }
}