import * as React from "react";
import {
    Account,
    AccountConstraints,
    AccountStatus,
    AccountType,
    Currency,
    ListAccountsRequest
} from "../../http/protocol";
import {ListView, ListViewProps, ListViewState} from "../ListView";
import {Form, FormListener} from "../form/Form";
import {AccountService} from "../../services/AccountService";
import {ColumnType} from "../list/list/ColumnType";
import {ActionType} from "../list/list/DataTable";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {SelectInput} from "../form/SelectInput";
import {ListUtils} from "../list/list/ListUtils";
import {PagerModel} from "../list/list/PagerModel";
import {getContainer} from "../../ioc/IOCSetup";
import {Alert} from "../form/Alert";
import {withRouter} from "react-router";



interface State extends ListViewState<ListAccountsRequest> {
    listRequest?: ListAccountsRequest,
    form?: ListAccountsRequest,
    availableCurrencies?: string[];
    validationMap? : any;
    operatorError?: boolean;
    constraints? : AccountConstraints;
}


export const STATUSES = [
    "OPEN",
    "CLOSED"
];

export const TYPES = [
    "SESSION_ACCOUNT",
    /** Long lived static account (normal user account) */
    "STATIC_ACCOUNT",
    /** Internal system account */
    "SYSTEM_ACCOUNT",
    /** Operator account */
    "OPERATOR_ACCOUNT",
    /** Affiliate account */
    "AFFILIATE_ACCOUNT"
]


export class AccountListView extends ListView<State, ListAccountsRequest> implements FormListener<ListAccountsRequest> {


    accountService : AccountService;

    columns = [
        { property : "id", name : "ID", orderBy : "id"},
        { property : "type", name : "Type", orderBy : "type", type: ColumnType.ENUM },
        { property : "subtype", name : "Subtype", orderBy : "type", type: ColumnType.ENUM },
        { property : "attributes.ROLE", name : "Role", type: ColumnType.ENUM },
        { property : "status", name : "Status", orderBy : "status", type: ColumnType.ENUM},
        { property : "created", name : "Created", orderBy : "created", type: ColumnType.DATE },
        { property : "userId", name : "User ID", orderBy : "userId", type : ColumnType.LINK, getUrl : (item : Account) => "/user/" + item.userId},
        { property : "operatorId", name : "Operator ID", orderBy : "operatorId" },
        { property : "currencyCode", name : "Currency", orderBy : "currency" }
    ];

    actions = [
        { id : "view", name : "View", className : "btn btn-primary", iconClassName : "fa fa-eye", type : ActionType.LINK, getLink : (item : Account)=> "/account/" + item.id}
    ];

    constructor(props : ListViewProps) {
        super(props,"/accounts");
        let listRequest =  {  limit: 50, ascending: false, orderBy: "id", statuses: [AccountStatus.OPEN],  types : [AccountType.STATIC_ACCOUNT], subtypes : []}
        this.state =  {...this.state, validationMap : {}, form : {...listRequest}, listRequest : listRequest, availableCurrencies : [], };

        this.accountService = getContainer().getAccountService();
    }

    loadContent(): Promise<any> {
        return  this.accountService.getAccountConstraints().then( c => {
            return this.accountService.getCurrencies().then((currencies : Currency[]) => {
                let codes : string[] = currencies.map( c=> c.code ) as string[];
                this.setState({ constraints : c, availableCurrencies : codes });
            });
        });


    }

    formDidUpdate(formModel: ListAccountsRequest, validationMap : any): void {
        this.setState({ form : formModel, validationMap : validationMap});
    }

    onSubmitError(): void {

    };

    onSubmit(): void {
        let listRequest : ListAccountsRequest = Object.assign({},this.state.form);
        listRequest.offset = 0;
        this.updateListState(listRequest);
    };

    filterEmpty(o: any): any {
        if (o == null  ||  o == undefined  ||  o == "") {
            return undefined;
        } else {
            return o;
        }
    }


    pagerChanged(pager : PagerModel) {
        if(!this.state.listRequest){
            return;
        }
        var listRequest = ListUtils.toListRequest(this.state.listRequest, pager);
        this.setState({listRequest : listRequest});
    }

    onAction(item : Account, action : string) : undefined | Promise<any> {
        return;
    }

    getTitle() {
        return "Accounts";
    }

    renderContent() {
        if(!this.state.constraints || !this.state.availableCurrencies || !this.state.listRequest) {
            return <span></span>
        }

        return (
            <div>
                <div className={"card-box"}>
                    <div className="ibox-content">

                        <Form className="form-horizontal" formListener={this} modelObject={this.state.form} validationMap={this.state.validationMap}>

                            <FormGroup className="form-group">
                                <label className="col-lg-2 control-label">User ID</label>
                                <div className="col-lg-10">
                                    <FormInput className="form-control" model="userId" type={"text"}/>
                                </div>
                            </FormGroup>

                            <FormGroup className="form-group">
                                <label className="col-lg-2 control-label">Account ID</label>
                                <div className="col-lg-10">
                                    <FormInput className="form-control" model="accountId" />
                                </div>
                            </FormGroup>



                            <FormGroup className="form-group">
                                <label className="col-lg-2 control-label">Types</label>
                                <div className="col-lg-10">
                                    <SelectInput size={5} className="form-control" model="types" values={this.state.constraints.types} multiple={true} />
                                </div>
                            </FormGroup>




                            <FormGroup className="form-group">
                                <label className="col-lg-2 control-label">Status</label>
                                <div className="col-lg-10">
                                    <SelectInput size={2} className="form-control" model="statuses" multiple={true} values={this.state.constraints.statuses}  />
                                </div>
                            </FormGroup>

                            {/*
                                  <FormGroup className="form-group">
                                <label className="col-lg-2 control-label">Subtypes</label>
                                <div className="col-lg-10">
                                    <SelectInput size={5} className="form-control" model="subtypes" values={this.state.constraints.subtypes}  multiple={true} />
                                </div>
                            </FormGroup>

                            */}
                            <FormGroup className="form-group">
                                <label className="col-lg-2 control-label">Currencies</label>
                                <div className="col-lg-10">
                                    <SelectInput size={this.state.availableCurrencies.length} className="form-control" model="currencies" multiple={true} values={this.state.availableCurrencies} />
                                </div>
                            </FormGroup>

                            <div className="form-group">
                                <div className="col-lg-offset-2 col-lg-10">
                                    <input className="btn btn-primary" value="List Accounts" type="submit" />
                                </div>
                            </div>
                        </Form>
                        {this.state.operatorError && (
                            <Alert type="danger" text={"One of user id or account id is required"}/>
                        )}
                    </div>
                </div>


                <EntityDataTable
                    service={this.accountService}
                    onPagerChange={this.pagerChanged.bind(this)}
                    columns={this.columns}
                    listRequest={this.state.listRequest}
                    onAction={this.onAction.bind(this)}
                    actions={this.actions}
                />
            </div>
        );
    }

}

export default withRouter(AccountListView)