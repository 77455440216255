import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {HttpService} from "../http/HttpService";
import {
    ListRequest,
    SportsBookBonusItem,
    SportsbookPlayerBonusesResponse,
} from "../http/protocol";

export interface UserIdListRequest extends ListRequest {
    userId: string
}

export class SportsbookBonusService extends AbstractAdminEntityService<any, SportsbookPlayerBonusesResponse> {

    constructor(http: HttpService) {
        super("/sportsbook/bonus", http)
    }

    getBonusDetails(bonusId: string): Promise<SportsBookBonusItem> {
        return this.http.post(`${this.entityPath}`, {bonusId: bonusId})
    }

    revokeBonus(bonusId: number): Promise<SportsBookBonusItem[]> {
        return this.http.post(`${this.entityPath}/revoke_bonus`, {bonusIds: [bonusId]})
    }

}