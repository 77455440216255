import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {AffiliatesSettings, BaseListResponse, GeneralReportRequest, ListResponse, TxReport} from "../http/protocol";
import {HttpService} from "../http/HttpService";


export class AffiliatesReportService extends AbstractAdminEntityService<GeneralReportRequest,BaseListResponse<TxReport>> implements AffiliatesReportService{
    constructor(http: HttpService) {
        super("/affiliates/detailed-report", http);
    }

    getAffiliateDetailedReport(affiliateId: number | undefined,request:{}):Promise<{}> {
        return this.http.post(`${this.entityPath}/${affiliateId}`, request);
    }

}