import * as React from 'react';
import {CSVLink, CSVDownload} from 'react-csv';


interface Props {
    items? : Array<any>;
    columns? : Array<any>;
    disableMargin? : boolean;
}

export class ExportDataTable extends React.Component<Props, {}> {

    downloadAsCSV(): any {
        let items:Array<any> = this.props.items ? this.props.items : [];
        let columns:Array<any> = this.props.columns ? this.props.columns : [];
        let label:Array<object> = [];
        let row:Array<object> = [];
        let rows:Array<object> = [];

        //Grab labels and push them to label array then push them to rows array:
        columns.map((k, index) => {
            label.push(k.name);
        });
        rows.push(label);

        //Grab rows and push them into rows array:
        items.map((k, index) => {
            row = [];
            for(let i =0; i < columns.length; i++) {
                if(columns[i].property.includes('.')) {
                    let property = columns[i].property;
                    let properties = property.split('.');

                    try {
                        row.push(items[index][properties[0]][properties[1]]);
                    } catch (e) {
                        row.push({})
                    }

                } else {
                    try {
                        row.push(items[index][columns[i].property]);
                    } catch (e) {
                        row.push({})
                    }
                }
            }
            rows.push(row);
        });
        console.log("ROWS: ", rows);

        return(
            <CSVLink
                data={rows}
                filename={`table_data_.csv`}
                className="btn btn-icon waves-effect waves-light btn-primary m-b-0"
                style={{marginLeft: this.props.disableMargin ? '0px' : '20px', marginBottom: '0px', padding: '1px 10px 1px 10px'}}
                target="_blank"
                title="Download as CSV"
            >
                <i className="fa fa-download"></i>
            </CSVLink>
        );
    }

    render() {
        return (
            <span>
                {this.downloadAsCSV()}
            </span>
        )
    }
}