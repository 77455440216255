
import * as React from "react";


import {AccountService} from "../../services/AccountService";
import {getContainer} from "../../ioc/IOCSetup";
import {DeleteButtonModal} from "../../util/DeleteButtonModal";
import {Account, AccountStatus} from "../../http/protocol";
import {NavLink} from "react-router-dom";
interface Props { account? : Account, onAccountUpdated : ()=> void}
export class AccountActions extends React.Component<Props, {}> {

    accountService : AccountService;
    constructor(props : Props) {
        super(props);
        this.accountService = getContainer().getAccountService();
    }
    render() {
        if(!this.props.account) {
            return <span></span>
        }
       return (
           <div className="card-box">
               <h5>Account Actions</h5>


                   { this.props.account.status == AccountStatus.OPEN &&
                        <DeleteButtonModal className="btn btn-danger" onDelete={()=>{
                            if(!this.props.account || !this.props.account.id) return;
                            return this.accountService.closeAccount(this.props.account.id).then(()=>{
                                this.props.onAccountUpdated();
                            })
                        }} label="Close Account"/>
                   }
                   &nbsp;
                   { this.props.account.status == "CLOSED" &&
                        <DeleteButtonModal className="btn btn-primary" onDelete={()=>{
                            if(!this.props.account || !this.props.account.id) return;
                            return this.accountService.openAccount(this.props.account.id).then(()=>{
                                this.props.onAccountUpdated();
                            })
                        }} label="Open Account"/>
                   }
                   <NavLink className="btn btn-warning" to={"/account/edit/" + this.props.account.id}>
                       Edit Account
                   </NavLink>


           </div>
       )
    }
}