/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MenuItem, MenuItemType, MenuPosition, MenuSettings, Settings } from "../../http/protocol";

import { Button, Modal } from "react-bootstrap";
import { getContainer } from "../../ioc/IOCSetup";

import { RouterViewTemplate, useLoadingState } from "../RouterView";
import EditMenuItem from "./EditMenuItem";
import EditMenuOrder from "./EditMenuOrder";
import { AsyncStatus, validateContentType, resolvePendingStatus } from "./utils";
import EditGeneralMenu from "./EditGeneralMenu";
import { setIconSettings } from "../../settings/SettingsActions";
import SelectMenuEdit from "./SelectMenuEdit";

export enum MenuType {
  MENU_MAIN = "Main menu",
  MENU_FOOTER = "Sidebar footer menu",
}

export default function EditMenuView() {
  const params = useParams<{ siteId: string }>();
  const [settings, setSettings] = useState<Settings>({});
  const [menuList, setMenuList] = useState<MenuItem[]>([{ type: MenuItemType.POKER }, { type: MenuItemType.CASINO }, { type: MenuItemType.SPORTSBOOK }, { type: MenuItemType.RACING }]);
  const [menuFooterList, setMenuFooterList] = useState<MenuItem[]>([]);
  const [menuItemIndex, settMenuItemIndex] = useState<number | undefined>(undefined);
  const [submitItemStatus, setSubmitItemStatus] = useState<AsyncStatus>(AsyncStatus.idle);
  const [statusText, setStatusText] = useState<string>("");
  const [saveSettingsStatus, setSaveSettingsStatus] = useState<AsyncStatus>(AsyncStatus.idle);

  const [selectedMenu, setSelectedMenu] = useState<MenuType>(MenuType.MENU_MAIN);

  async function loadSettings() {
    try {
      const response = await getContainer().getSettingsService().getEntity(params.siteId);
      if (response) {
        setSettings(response);
        setIconSettings({ url: response.menu?.settings?.iconPack ?? "" });
      }
      if (response.menu && response.menu.items) {
        setMenuList(response.menu?.items);
      }
      if (response.menu && response.menu.footerItems) {
        setMenuFooterList(response.menu?.footerItems);
      }
    } catch (error) {}
  }

  useEffect(() => {
    loadSettings();
  }, []);

  const status = useLoadingState(() => {
    return Promise.resolve();
  }, []);

  async function saveSettings() {
    if (!settings) return;

    const iconPack = settings.menu?.settings?.iconPack ?? "";

    try {
      if (iconPack.length) {
        await validateContentType(iconPack);
      }

      setSaveSettingsStatus(AsyncStatus.pending);
      const updatedSettings = {
        
        ...settings,
        menu: {
          items: menuList,
          footerItems: menuFooterList,
          settings: settings.menu?.settings ?? undefined,
        },
      };

      await getContainer().getSettingsService().saveSettings(updatedSettings);

      resolvePendingStatus(setSaveSettingsStatus, 300, AsyncStatus.success).then(() => {
        setStatusText("Menu Settings is now saved!");
        setIconSettings({ url: iconPack });
      });
    } catch (error) {
      console.error("Error saving settings:", error);
      setStatusText("Failed to save settings, confirm if url is valid");
      setSaveSettingsStatus(AsyncStatus.fail);
    }
  }

  function saveGeneralSettings(values: MenuSettings) {
    console.log("Settings: {}", values);
    setSettings((prev) => ({ ...prev, menu: { items: prev.menu?.items, settings: values, footerItems: prev.menu?.footerItems } }));
  }

  function deleteMenuItem() {
    if (selectedMenu == MenuType.MENU_MAIN) {
      if (menuItemIndex) {
        setMenuList((prevMenuList) => {
          const updatedMenuList = [...prevMenuList];
          updatedMenuList.splice(menuItemIndex, 1);
          return updatedMenuList;
        });
        settMenuItemIndex(undefined);
      }
    } else {
      if (menuItemIndex !== undefined) {
        setMenuFooterList((prevMenuList) => {
          const updatedMenuList = [...prevMenuList];
          updatedMenuList.splice(menuItemIndex, 1);
          return updatedMenuList;
        });
        settMenuItemIndex(undefined);
      }
    }
  }

  function addMenuItem() {
    if (selectedMenu == MenuType.MENU_MAIN) {
      setMenuList((prev) => {
        return [...prev, { type: MenuItemType.CASINO }];
      });
      settMenuItemIndex(menuList.length);
    } else {
      setMenuFooterList((prev) => {
        return [...prev, { type: MenuItemType.CUSTOM }];
      });
      settMenuItemIndex(menuFooterList.length);
    }
  }

  function saveMenuItem(item: MenuItem) {
    if (selectedMenu == MenuType.MENU_MAIN) {
      if (menuItemIndex !== undefined) {
        setMenuList((prevMenuList) => {
          const updatedMenuList = [...prevMenuList];
          updatedMenuList[menuItemIndex] = item;
          return updatedMenuList;
        });
      }
    } else {
      if (menuItemIndex !== undefined) {
        setMenuFooterList((prevMenuList) => {
          const updatedMenuList = [...prevMenuList];
          updatedMenuList[menuItemIndex] = item;
          return updatedMenuList;
        });
      }
    }
  }

  function selectMenu(value: MenuType) {
    setSelectedMenu(value);
  }

  // Variables Based on selected menu
  let menu = selectedMenu == MenuType.MENU_MAIN ? menuList : menuFooterList;

  return (
    <RouterViewTemplate status={status} title={"Edit Menu"}>
      <EditGeneralMenu setSettings={saveGeneralSettings} settings={settings} />
      <SelectMenuEdit selected={selectedMenu} setSelected={selectMenu} />
      {menuItemIndex == undefined && <EditMenuOrder menuList={menu} setMenuList={selectedMenu == MenuType.MENU_MAIN ? setMenuList : setMenuFooterList} settMenuItemIndex={settMenuItemIndex} addMenuItem={addMenuItem} />}
      {menuItemIndex !== undefined && <EditMenuItem menuType={selectedMenu} deleteMenuItem={deleteMenuItem} setMenuItem={saveMenuItem} menuItem={menu[menuItemIndex]} settMenuItemIndex={settMenuItemIndex} setSubmitItemStatus={setSubmitItemStatus} SubmitItemStatus={submitItemStatus} />}
      <SaveMenuSettings status={saveSettingsStatus} saveSettings={saveSettings} />
      <SubmitFeedback status={saveSettingsStatus} resolveStatus={setSaveSettingsStatus} text={statusText} />
    </RouterViewTemplate>
  );
}

/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------------------------------------------- */

interface SubmitFeedbackProps {
  status: AsyncStatus;
  resolveStatus: (status: AsyncStatus) => void;
  text: string;
}

function SubmitFeedback({ status, resolveStatus, text }: SubmitFeedbackProps) {
  let alertStatus = status == AsyncStatus.success ? "alert alert-success" : status == AsyncStatus.fail ? "alert alert-danger" : "";
  let alertText = status == AsyncStatus.success ? "Success!" : status == AsyncStatus.fail ? "Fail!" : "";

  return (
    <Modal show={Boolean(status == AsyncStatus.success || status == AsyncStatus.fail)}>
      <Modal.Header>{alertText}</Modal.Header>
      <Modal.Body>
        <div className={alertStatus}>{text}</div>
        <button
          onClick={() => {
            resolveStatus(AsyncStatus.idle);
          }}
          className={"btn btn-primary"}
        >
          Continue
        </button>
      </Modal.Body>
    </Modal>
  );
}
/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------------------------------------------- */

interface SaveMenuSettingsProps {
  saveSettings: () => void;
  status: AsyncStatus;
}

function SaveMenuSettings(props: SaveMenuSettingsProps) {
  return (
    <div className={"card"}>
      <div className={"card-body"}>
        <div className="row">
          <h4 className=" p-2 w-full d-flex justify-content-between">Save Menu To Settings</h4>
          <div className="col-lg-12">
            <button className="btn btn-primary waves-effect waves-light" onClick={() => props.saveSettings()}>
              {props.status == AsyncStatus.pending ? (
                <div className="spinner-border spinner-border-sm text-light" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Save Settings"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
