import * as React from "react";
import * as _ from "lodash";
import {Fragment} from "react";

export class FormIf extends React.Component<{ model? : string; value? : any, values?: any[] },{}> {
    render() {
        return this.props.children;
    }
}

export class FormIfNot extends React.Component<{model? : string; value : any},{}> {
    render() {
        return this.props.children;
    }
}

interface SwitchProps {
    value : any;
    children? : any;
}
export class Switch extends React.Component<SwitchProps,{}> {

    getChildren() {
        let match = false;
        let result = React.Children.map(this.props.children, (child : React.ReactElement<any>, index : number) => {
            var c = (child as any);
            if (c.props && c.props.value && c.type == Case) {
                if(c.props.value instanceof Array) {
                    if(c.props.value.some((v : any) => _.isEqual(v, this.props.value))) {
                        match = true;
                        return c;
                    } else {
                        return null;
                    }

                } else if (_.isEqual(c.props.value, this.props.value)) {
                    match = true;
                    return c;
                } else {
                    return null;
                }
            } else  {
                return null;
            }
        });
        if(match) {
            return result;
        }
        result = React.Children.map(this.props.children, (child : React.ReactElement<any>, index : number) => {
            var c = (child as any);
            if (c.type == Default) {
                return c;
            } else {
                return null;
            }
        });
        return result;
    }
    render() {
        return <Fragment>{this.getChildren()}</Fragment>

    }
}


export class Default extends React.Component<{ children? : any; },{}>{
    render(){
        return (
            <Fragment>
                {this.props.children}
            </Fragment>
        );
    }
}

export class Case extends React.Component<{ value? : any, children? : any; },{}>{
    render(){
        return (
            <Fragment>
                {this.props.children}
            </Fragment>
        );
    }
}