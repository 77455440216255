import {HttpService} from "../http/HttpService";
import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {
    CasinoLobbyCategory,
    CasinoStudio,
    GameTag,
    ListCasinoLobbyCategoryResponse, ListGameTagResponse,
    ListStudioResponse
} from "../http/protocol";


export class CasinoGameTagService  extends AbstractAdminEntityService<GameTag, ListGameTagResponse>  {

    constructor(http : HttpService) {
        super("/casino-game-tag", http)
    }

}