import * as React from "react";
import * as _ from "lodash";
import {AbstractInputElement, FormElement} from "./FormInput";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from "moment";

export interface Props extends FormElement<number,React.TextareaHTMLAttributes<any>>{
    timeFormat?: boolean | string;
    locale?: string;
    dateFormat?: boolean | string;
}
export class FormDateTimeInput extends AbstractInputElement<number,Props, {  }> {

    getDefaultValue() : number {
        return Date.now();
    }
    dateChanged(value: moment.Moment | string) {
        if(typeof(value) != "string") {
            let time = value.toDate().getTime();
            this.onChange(time);
        }

    }

    render() {
        let selectedDateTime = new Date(this.getModelValue());
        let rest : React.TextareaHTMLAttributes<any> = _.omit(this.props,['validators',`model`, `fieldSet`, `modelValue`, `valid`, 'validationMap']);
        return <Datetime  locale={this.props.locale} dateFormat={this.props.dateFormat} timeFormat={this.props.timeFormat} value={selectedDateTime} onChange={(e) => this.dateChanged(e)}/>
   }

}

