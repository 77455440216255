import * as React from "react";

import {RouteComponentProps, withRouter} from "react-router";
import {LoadingViewState, RouterView} from "../RouterView";
import {AssignRewardToUserRequest, CasinoGame, GameIntegration, RewardTemplate, RewardType, TemplateItem} from "../../http/protocol";
import {Form, FormListener} from "../form/Form";
import {getContainer} from "../../ioc/IOCSetup";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {Submit} from "../form/Submit";
import {Validators} from "../form/Validators";
import {SelectInput} from "../form/SelectInput";
import {SettingsService} from "../../services/SettingsService";
import {RewardsConfigService} from "../../services/RewardsConfigService";
import {CasinoGameService} from "../../services/CasinoGameService";
import {AddCasinoGames} from "../casino/AddCasinoGames";
import {t} from "../../i18n";
import {InternalSettingsService} from "../../services/InternalSettingsService";
import { CurrencyRateService } from "../../services/CurrencyRateService";
import UserRewardVerification, { RequestFeedback, VerificationType } from "../user/UserRewardVerification";


interface State extends LoadingViewState {
    request? : AssignRewardToUserRequest;
    templates? : RewardTemplate[];
    validationMap? : any;
    selectedGame? : CasinoGame;
    showAddGamesDialog?: boolean;
    loading?: boolean;
    freeSpinsIntegrations?: GameIntegration[]
    verificationModal: boolean
    currencyToEuroRate: number | undefined
    rewardTemplate: RewardTemplate;
    requestFeedback: boolean
    isTemplateActive: boolean;
    templateItems: TemplateItem[]
}

export class AssignRewardToUserPage extends RouterView<RouteComponentProps, { }> {
    constructor(props : RouteComponentProps) {
        super(props);
        this.state = { loading : true };
    }
    getTitle(): string {
        return "Assign/Award Reward";
    }
    loadContent(): Promise<any>  {
      return Promise.resolve();
    }
    renderContent() {
       return (
           <div className="wrapper wrapper-content animated fadeInRight">
               <AssignRewardToUser />
           </div>
       )
    }
}
export class AssignRewardToUser extends React.Component<{userId?:number},State> implements FormListener<AssignRewardToUserRequest>{

    service : RewardsConfigService;
    settingsService : SettingsService;
    gameService : CasinoGameService;
    internalSettingsService: InternalSettingsService;
    currencyRateService: CurrencyRateService;
    rewardsConfigService: RewardsConfigService
    constructor(props : { }){
        super(props);
        this.service = getContainer().getRewardsConfigService();
        this.settingsService = getContainer().getSettingsService();
        this.gameService = getContainer().getCasinoGameService();
        this.internalSettingsService = getContainer().getInternalSettingsService();
        this.currencyRateService = getContainer().getCurrencyRateService();
        this.rewardsConfigService = getContainer().getRewardsConfigService();
        this.toggleVerificationModal = this.toggleVerificationModal.bind(this);
        this.requestFeedback = this.requestFeedback.bind(this);
        let request= {};
        if(typeof(this.props.userId) != "undefined") {
            request = { userId : this.props.userId};
        }
        this.state = { request : request, templates : [], templateItems: [], validationMap : {}, loading : true, verificationModal: false, currencyToEuroRate: undefined, rewardTemplate: {}, requestFeedback: false, isTemplateActive: true};
    }

    componentDidMount() {
        this.service.getBetbyTemplates().then((response) => {
            this.setState({templateItems: response ?? []})
        })
        return this.service.list({ limit : 1000, offset: 0, orderBy : "name"}).then((list) => {
            let gi : GameIntegration[]=[];

            this.internalSettingsService.getSettings().then(settings => {
                if(settings.freeSpinsEnabledIntegrations != null){
                    settings.freeSpinsEnabledIntegrations?.split(",").forEach(integrationName => {
                        Object.values(GameIntegration).forEach(enumName => {
                            if(enumName===integrationName){
                                gi.push(enumName);
                            }
                        });
                    })

                }
            })

            this.setState({ templates : list.items, loading : false, freeSpinsIntegrations:gi});
        })
    }


    toggleAddShowAddGames() {
        this.setState({ showAddGamesDialog :   !this.state.showAddGamesDialog  });
    }

    async getExchangeRate(currency: string){
        this.currencyRateService.getCryptoAndFiatToEuroExchangeRates(currency).then((rate)=>{
            this.setState({currencyToEuroRate: rate})
        })
    }

    onSubmit()  {
        this.setState({verificationModal: true})
        if(this.state.request?.rewardTemplateId){
            this.rewardsConfigService.getReward(this.state.request?.rewardTemplateId).then((response)=>{
               
                this.setState({rewardTemplate: response})
                this.getExchangeRate(this.state.request?.currencyCode ? this.state.request?.currencyCode : response.currencyCode as any);
            })
        }
    }

    toggleVerificationModal(){
        this.setState({verificationModal: false}) 
    }


    requestFeedback(type: string){
        if(type == "open"){
            this.setState({requestFeedback: true})
        }
        if(type == "close"){
            this.setState({requestFeedback: false})
            this.setState({request: {...this.state.request, nrOfFreeSpins: undefined, amount: undefined, currencyCode: undefined} })
        }
    }

    formDidUpdate(formModel: AssignRewardToUserRequest, validationMap?: any) {
        console.log("form modal = ", formModel);
        let rewardTemplate = this.state.templates?.find((t) => t.id == formModel.rewardTemplateId)
        let templateItems = this.state.templateItems;
        if(rewardTemplate?.rewardType == RewardType.freebet){
            if(templateItems.length){
                this.state.templateItems.forEach((template) => {
                    if(template.id == rewardTemplate?.templateId){
                        if(template.is_active){
                            this.setState({isTemplateActive: true})
                        } else {
                            this.setState({isTemplateActive: false})
                        }
                    }
                })
            } else {
                this.setState({isTemplateActive: false})
            }
        }
        this.setState({request : formModel, validationMap : validationMap})

    }
    selectGame(game : CasinoGame) {
        this.setState({ request : {...this.state.request, gameId : game.id}, showAddGamesDialog : false, selectedGame : game})
    }


    getVerificationModal(){
        return (
            <UserRewardVerification
                type={VerificationType.user_reward}
                userRewardRequest={this.state.request}
                userRewardTemplate={this.state.rewardTemplate}
                toggleVerificationModal={this.toggleVerificationModal}
                isVerificationModalOpen={this.state.verificationModal}
                euroExchangeRate={this.state.currencyToEuroRate}
                requestFeedback={this.requestFeedback}

            />
        )
    }

    getSuccessModal(){
        if(this.state.requestFeedback){
            return (
                <RequestFeedback  type={VerificationType.user_reward} requestFeedback={this.requestFeedback} isOpen={this.state.requestFeedback} />
                )
        }
    }

    render() {
        if(!this.state.request || this.state.loading) {
            return <span/>
        }
        let currentTemplate;
        if(this.state.request?.rewardTemplateId && this.state.templates) {
            currentTemplate = this.state.templates.find(t => t.id == this.state.request?.rewardTemplateId);
        }
        let targetCurrency = currentTemplate?.currencyCode;
        if(this.state.request.currencyCode && this.state.request.currencyCode.length > 0) {
            targetCurrency = this.state.request.currencyCode;
        }
        
        return (
            <div>
                {this.getVerificationModal()}
                {this.getSuccessModal()}
                <Form className="form form-horizontal" validationMap={this.state.validationMap} modelObject={this.state.request} formListener={this}>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card-box">
                                <div className="ibox-content">
                                    <FormGroup className="form-group">
                                        <label className="col-sm-4 control-label">{t(`label.rewardTemplate`)}</label>
                                        <div className="col-sm-10">
                                            <SelectInput model="rewardTemplateId" optional={false} values={this.state.templates} optionModelValue={(e) => e.id} displayValue={(e) => e.name + " - " + e.id + " ("+e.currencyCode+")"} className="form-control"/>
                                            {currentTemplate?.rewardType == RewardType.freebet && !this.state.isTemplateActive && <span className={"help text-danger"}>Current sportsbook template for this config is not active. Active sportsbook template in betby backoffice</span>
                                        }
                                        </div>
                                    </FormGroup>
                                    <div className="hr-line-dashed"/>
                                    {!this.props.userId && (
                                       <>
                                           <FormGroup className="form-group">
                                               <label className="col-sm-4 control-label">{t(`label.userId`)}</label>
                                               <div className="col-sm-10">
                                                   <FormInput model="userId" validators={[Validators.required()]} type="integer" className="form-control" />
                                                   <span className={"help"}>{t(`helpText.userIdReward`)}</span>
                                               </div>
                                           </FormGroup>
                                           <div className="hr-line-dashed"/>
                                       </>
                                    )}
                                    {currentTemplate?.rewardType == RewardType.freespins && (
                                        <>
                                            <div className="form-group">
                                                <label className="col-sm-2 control-label">{t(`label.game`)}</label>
                                                <div className="col-sm-10">
                                                    {!this.state.request.gameId && <span className={"alert alert-info"} style={{display : "block"}}>{t(`alertInfo.selectGame`)}</span>}
                                                    {this.state.request.gameId && <span className={"form-control"}>{this.state.selectedGame?.id} - {this.state.selectedGame?.name} -  {this.state.selectedGame?.integration}</span>}
                                                    <a style={{marginTop : "5px"}} className={"btn btn-primary"}  onClick={()=>  this.toggleAddShowAddGames()}>{t(`button.selectGame`)}</a>
                                                    <span className={"help"}>{t(`helpText.selectGame`)}</span>
                                                </div>
                                            </div>
                                            <FormGroup className="form-group">
                                                <label className="col-sm-4 control-label">{t(`label.nrOfFreespins`)}</label>
                                                <div className="col-sm-10">
                                                    <FormInput model="nrOfFreeSpins" type="integer" className="form-control" />
                                                    <span className={"help"}>{t(`helpText.nrOfFreespinsFirstPart`)} ({currentTemplate.defaultNrOfFreeSpins}) {t(`helpText.nrOfFreespinsSecondPart`)}</span>
                                                </div>
                                            </FormGroup>
                                        </>
                                    )}
                                    {currentTemplate !=null && (
                                        <>
                                            <FormGroup className="form-group">
                                                <label className="col-sm-4 control-label">{t(`label.currencyCode`)}</label>
                                                <div className="col-sm-10">
                                                    <FormInput model="currencyCode" type="float" className="form-control" />
                                                    <span className={"help"}>{t(`helpText.currencyCode`)} ({currentTemplate.currencyCode})</span>
                                                </div>
                                            </FormGroup>
                                            <div className="hr-line-dashed"/>
                                            <FormGroup className="form-group">
                                                <label className="col-sm-4 control-label">{t(`label.amount`)}</label>
                                                <div className="col-sm-10">
                                                    <FormInput model="amount" validators={[Validators.number()]} type="float" className="form-control" />
                                                    {/* <span className={"help"}>The Amount in ({targetCurrency}) to reward, if left empty {currentTemplate.defaultAmount} {targetCurrency} will be rewarded</span> */}
                                                    <div  className={"alert alert-warning guide"}>
                                                    <span>{t(`helpText.amountReward`, {targetCurrency : targetCurrency, defaultAmount : currentTemplate.defaultAmount})}</span><br/>
                                                    <span>CASH rewards: the default amount to reward to the player</span><br/>
                                                    <span>FREESPINS rewards: the bet amount for every spin. Not all integrations support this (will be ignored if not supported by the game integration)</span><br/>
                                                    <span>FREEBET rewards: total amount. Exchanged value will be awarded if currency is not supported by integration.</span>
                                                    </div>
                                                </div>
                                            </FormGroup>
                                        </>
                                    )}
                                    <div className="hr-line-dashed"/>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card-box">
                                <FormSubmitError />
                                <div className="form-group">
                                    <div className="col-sm-12">
                                        <Submit disabled={currentTemplate?.rewardType == RewardType.freebet ? !this.state.isTemplateActive : false} className="btn  btn-primary">{t(`button.award`)}</Submit>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Form>
                <AddCasinoGames title="Select Casino Game" onlyFreeSpinSupported={true} includeIntegrations={this.state.freeSpinsIntegrations} showAddGames={this.state.showAddGamesDialog} toggleShowAddGames={()=> this.toggleAddShowAddGames()} addGame={(game : CasinoGame)=>{ this.selectGame(game)}} removeGame={()=>{}} addedGames={[]} />
            </div>
        );
    }

}

export default withRouter(AssignRewardToUserPage);

/* this.service.getBetbyTemplates().then((templates) => {
    let template = templates.find((template) => template.id == response.templateId)
    if(template !== undefined){
        this.setState({isTemplateActive: template.is_active ? true : false})
    }
}) */