import React, {Fragment} from "react";
import {ActionType} from "../list/list/DataTable";
import {ListChartsRequest, ListUsersRequest, User} from "../../http/protocol";
import {getContainer} from "../../ioc/IOCSetup";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {PagerModel} from "../list/list/PagerModel";
import {ListUtils} from "../list/list/ListUtils";
import {withRouter} from "react-router";
import {ListView, ListViewProps, ListViewState} from "../ListView";
import {UserService} from "../../services/UserService";
import {Form, FormListener} from "../form/Form";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Validators} from "../form/Validators";
import {Submit} from "../form/Submit";
import {ColumnType} from "../list/list/ColumnType";
import {ChartService} from "../../services/ChartService";


interface State extends ListViewState<ListUsersRequest> {
    form? : ListChartsRequest;
    validationMap? : any;
}
interface Props extends ListViewProps  {

}

export class AdminChartListView extends ListView<State, ListChartsRequest> implements FormListener<ListChartsRequest>{

    columns = [
        { property : "id", name : "ID"},
        { property : "name", name : "name"},
        { property : "tags", name : "Tags", type : ColumnType.LIST},
        { property : "externalId", name : "External Id"}
    ];

    actions = [
        { id : "delete", name : "delete", className : "btn btn-danger", iconClassName : "fa fa-times", type : ActionType.CONFIRMATION},
        { id : "view", name : "View", className : "btn btn-primary", iconClassName : "fa fa-user", type : ActionType.LINK, getLink : (item : any) => "/admin-chart/" + item.id }

    ];

    service : ChartService;

    constructor(props : Props){
        super(props, "/admin-charts");
        this.state = {...this.state,  validationMap : {}, form : {}};
        this.service = getContainer().getChartService();
    }
    onSubmit() : void | Promise<any> {
        if(this.state.form) {
            let form = this.state.form;
            form.offset = 0;
            this.updateListState(form);
        }
    }
    onSubmitError() :  void {

    }

    formDidUpdate(formModel: ListUsersRequest, validationMap?: any): void {
        this.setState({form : formModel, validationMap : validationMap});
    }

    onAction(item : User, action : string) : Promise<any> | undefined {
        if(action == "delete") {
            return  this.service.remove(""+item.id);
        }
    }

    pagerChanged(pager : PagerModel) {
        if(this.state.listRequest) {
            console.log("pager changed!", pager);
            let listRequest = ListUtils.toListRequest<any>(this.state.listRequest,pager);
            this.updateListState(listRequest);
        }
    }
    getTitle() {
        return "Admin Charts"
    }

    renderContent() {
        if(!this.state.listRequest) {
            return <div/>;
        }
        return (
            <Fragment>
                <div className={"card-box"}>
                    <h4>Search Filters</h4>
                    <Form formListener={this} modelObject={this.state.form} validationMap={this.state.validationMap}>

                        <FormGroup className={"form-group"}>
                            <label>Free text</label>
                            <FormInput model={"tag"} className={"form-control"} validators={[Validators.strLength(1,255)]}/>
                            <span className={"help-text"}>Search for tags </span>
                        </FormGroup>
                        <Submit className={"btn btn-primary"}>Search</Submit>
                    </Form>
                </div>
                <EntityDataTable service={this.service}
                                 onPagerChange={this.pagerChanged.bind(this)}
                                 columns={this.columns}
                                 listRequest={this.state.listRequest}
                                 onAction={this.onAction.bind(this)}
                                 actions={this.actions}/>
            </Fragment>
        );
    }



}

export default withRouter(AdminChartListView);
