import {HttpService} from "../http/HttpService";
import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {CasinoLobbyCategory, GameIntegration, ListCasinoLobbyCategoryResponse} from "../http/protocol";


export class CasinoLobbyService  extends AbstractAdminEntityService<any, any>  {

    constructor(http : HttpService) {
        super("/casino-lobby", http)
    }

    refreshLobbyCache(): Promise<{}> {
        return this.http.delete(`${this.entityPath}/cache/refresh`);
    }


    lobbyRefresh(gameIntegrations?: GameIntegration[]): Promise<{}> {
        return this.http.put(
            `${this.entityPath}/refresh`,
            {
                gameIntegrations: gameIntegrations,
            });

    }

    refreshCentralImages(integration?: string, studio?: string): Promise<{}> {
        return this.http.put(`${this.entityPath}/images?integration=${integration}&studio=${studio}`, {})
    }

    async getEnabledIntegrations() {
        return this.http.get<Set<GameIntegration>>(`${this.entityPath}/integrations`)
    }

    async getStudioNamesByIntegration(integration: string): Promise<string[]> {
        return this.http.get(`${this.entityPath}/studios/${integration}`)
    }

}