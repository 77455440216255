import { useSelector } from "react-redux";
import { PermissionCategory } from "../../http/protocol";
import { ApplicationState } from "../../user/UserStore";

export type AccessLevel = "r" | "w"

const checkPermission = (userPermissions: { [key: string]: boolean }, permission: PermissionCategory, access: AccessLevel): boolean => {
    const hasWrite = userPermissions?.[permission as string] === true ?? false
    const hasRead = hasWrite || (userPermissions?.[permission as string] === false ?? false)

    const canAccess = access === "w" && hasWrite || access === "r" && hasRead

    return canAccess;
}

export type PermissionRequiredProps = {
    /** Required permissions. Will show content if any of the given permissions matches. */
    permissions: PermissionCategory[],
    /** If read or write permission is required. */
    accessLevel: AccessLevel,
    children: JSX.Element | JSX.Element[],
    /** If true render which permissions are required. Otherwise display nothing. Defaults to false. */
    showError?: boolean;
    /** Will show content if ALL permissions matches */
    requireAll?: boolean;

    componentName? : string;

    displayBox? : boolean;
}

/**
 * Wraps content and show it if the user has ANY of the required permissions.
 * @param props 
 * @returns 
 */
export const PermissionRequired = (props: PermissionRequiredProps) => {

    const userPermissions = useSelector((state: ApplicationState) => state.user.permissions) ?? {};
    let haveAccess = false;
    if(props.requireAll === true) {
        haveAccess =  !props.permissions.some(p => !checkPermission(userPermissions, p, props.accessLevel));
    } else {
        haveAccess =  props.permissions.some(p => checkPermission(userPermissions, p, props.accessLevel));
    }
    const getContent = () => {
        return <span className="badge badge-danger">{props.componentName ?? <span>{props.componentName} </span>}Permission denied, requires: {props.permissions.join(", ")} [{props.accessLevel == "r" ? "READ" : "WRITE"}]</span>
    }
    if (haveAccess) {
        return <>{props.children}</>
    } else if (props.showError && props.displayBox) {
        return (<div className={"col-lg-12"}><div className={"card-box"}>{getContent()}</div></div>)
    } else if (props.showError) {
        return (<>{getContent()}</>)
    } else {
        return <span dangerouslySetInnerHTML={{__html: `<!-- Requires permisson: ${props.permissions} [${props.accessLevel == "r" ? "READ" : "WRITE"}] -->` }} />
    }
}














