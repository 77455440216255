import React, {Fragment} from "react";
import {ActionType} from "../list/list/DataTable";
import {
    CasinoPage,
    ListCasinoLobbyCategoryRequest,
    ListCasinoPageRequest,
    ListChartsRequest
} from "../../http/protocol";
import {getContainer} from "../../ioc/IOCSetup";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {PagerModel} from "../list/list/PagerModel";
import {ListUtils} from "../list/list/ListUtils";
import {withRouter} from "react-router";
import {ListView, ListViewProps, ListViewState} from "../ListView";
import {FormListener} from "../form/Form";
import {AdminCasinoPageService} from "../../services/AdminCasinoPageService";
import { Collapsible } from "../Collapsible";


interface State extends ListViewState<ListCasinoLobbyCategoryRequest> {
    form? : ListCasinoPageRequest;
    validationMap? : any;
}
interface Props extends ListViewProps  {

}

export class CasinoPageListView extends ListView<State, ListChartsRequest> implements FormListener<ListCasinoPageRequest>{

    columns = [
        { property : "id", name : "ID", orderBy : "id"},
        { property : "name", name : "Name"},
        { property : "defaultPage", name : "defaultPage"}
    ];

    actions = [
        { id : "delete", name : "delete", className : "btn btn-danger", iconClassName : "fa fa-times", type : ActionType.CONFIRMATION},
        { id : "view", name : "View", className : "btn btn-primary", iconClassName : "fa fa-user", type : ActionType.LINK, getLink : (item : any) => "/casino-page/" + item.id }

    ];

    service : AdminCasinoPageService;

    constructor(props : Props){
        super(props, "/casino-pages");
        this.state = {...this.state,  listRequest : {...this.state.listRequest}, validationMap : {}, form : {}};
        this.service = getContainer().getCasinoPageService();
    }
    onSubmit() : void | Promise<any> {
        if(this.state.form) {
            let form = this.state.form;
            form.offset = 0;
            this.updateListState(form);
        }
    }
    onSubmitError() :  void {

    }

    formDidUpdate(formModel: ListCasinoPageRequest, validationMap?: any): void {
        this.setState({form : formModel, validationMap : validationMap});
    }

    onAction(item : CasinoPage, action : string) : Promise<any> | undefined {
        if(action == "delete") {
            return  this.service.remove(""+item.id);
        }
    }

    pagerChanged(pager : PagerModel) {
        if(this.state.listRequest) {
            let listRequest = ListUtils.toListRequest<any>(this.state.listRequest,pager);
            this.updateListState(listRequest);
        }
    }
    getTitle() {
        return "Casino Pages";
    }

    renderContent() {
        if(!this.state.listRequest) {
            return <div/>;
        }
        return (
            <Fragment>
                <EntityDataTable service={this.service}
                                 onPagerChange={this.pagerChanged.bind(this)}
                                 columns={this.columns}
                                 listRequest={this.state.listRequest}
                                 onAction={this.onAction.bind(this)}
                                 actions={this.actions}/>
            </Fragment>
        );
    }



}

export default withRouter(CasinoPageListView);
