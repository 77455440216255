import {HttpService} from "../http/HttpService";
import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {
    CasinoLobbyCategory,
    ListCasinoLobbyCategoryResponse,
    UserRiskCollectionListResponse,
    UserRiskRuleCollection
} from "../http/protocol";


export class UserRiskCollectionService  extends AbstractAdminEntityService<UserRiskRuleCollection, UserRiskCollectionListResponse>  {

    constructor(http : HttpService) {
        super("/user-journey/risk-collection", http)
    }

}