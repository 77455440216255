import React from "react";
import {BonusOfferService} from "../../services/BonusOfferService";
import {getContainer} from "../../ioc/IOCSetup";
import {BonusConfigService} from "../../services/BonusConfigService";
import {Form, FormListener} from "../form/Form";
import {AwardBonusOfferRequest, BonusConfig, BonusStatus} from "../../http/protocol";
import {Collapsible} from "../Collapsible";
import {SelectInput} from "../form/SelectInput";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {Submit} from "../form/Submit";
import {FormInput} from "../form/FormInput";
import {t} from "../../i18n";
import { CurrencyRateService } from "../../services/CurrencyRateService";
import UserRewardVerification, { RequestFeedback, VerificationType } from "./UserRewardVerification";


interface State {
    form:AwardBonusOfferRequest;
    validationMap: any;
    configs: BonusConfig[];
    selectedBonus: BonusConfig;
    verificationModal: boolean
    currencyToEuroRate: number | undefined
    requestFeedback: boolean
}

interface Props {
    userId:number;
}


export class UserBonusComponent extends React.Component<Props, State> implements FormListener<AwardBonusOfferRequest>{
    bonusAwardService:BonusOfferService;
    bonusConfigService:BonusConfigService;
    currencyRateService: CurrencyRateService;
    constructor(props:Props) {
        super(props);
        this.toggleVerificationModal = this.toggleVerificationModal.bind(this);
        this.requestFeedback = this.requestFeedback.bind(this);
        this.bonusAwardService = getContainer().getBonusOfferService();
        this.bonusConfigService = getContainer().getBonusConfigService();
        this.currencyRateService = getContainer().getCurrencyRateService();
        this.state = { form : {expiryInDays: 0}, validationMap : {}, configs: [], selectedBonus:{}, verificationModal: false, currencyToEuroRate: undefined, requestFeedback: false};

    }

    componentDidMount() {
        this.bonusConfigService.list({ limit: 500, offset: 0, status : BonusStatus.ENABLED}).then((resp) => {
            if(resp.items && resp.items.length > 0) {
                let bonuses:BonusConfig[] = [];
                resp.items.map((bonus) => {
                    if(bonus.status === "ENABLED") {
                        bonuses.push(bonus)
                    }
                });
                const bonusConfigId = bonuses && bonuses[0] && bonuses[0].id || null;
                const initForm = this.state.form;
                if(bonusConfigId != null) {initForm.bonusConfigId = bonusConfigId}
                this.setState({configs:bonuses, form:initForm, selectedBonus:bonuses[0]})
            }
        })

    }

    formDidUpdate(formModel: AwardBonusOfferRequest, validationMap?: any) {
        formModel.userId = this.props.userId;
        const bonusConfig:BonusConfig = this.state.configs.find(bonus => bonus.id === formModel.bonusConfigId) ||{};
        this.setState({form : formModel, validationMap : validationMap, selectedBonus:bonusConfig});
    }

    onSubmit() {
        if(this.state.selectedBonus){
            this.currencyRateService.getCryptoAndFiatToEuroExchangeRates(this.state.selectedBonus?.monetaryConfig?.currencyCode ?? 'cad').then((response)=>{
                this.setState({currencyToEuroRate: response, verificationModal: true})
            })
        }
    }

    toggleVerificationModal(){
        this.setState({verificationModal: false}) 
    }

    requestFeedback(type: string){
        if(type == "open"){
            this.setState({requestFeedback: true})
        }
        if(type == "close"){
            this.setState({requestFeedback: false})
            this.setState({form: {...this.state.form, amount: undefined}, selectedBonus: this.state.selectedBonus})
        }
    }

    toDisplayValue(a : BonusConfig){
        if(a.name)
        {
            return a.name.toString();
        } else {

            return "";
        }
    }


    getVerificationModal(){
        return (
            <UserRewardVerification
                type={VerificationType.bonus_reward}
                selectedBonus={this.state.selectedBonus}
                bonusRewardRequest={this.state.form}
                toggleVerificationModal={this.toggleVerificationModal}
                isVerificationModalOpen={this.state.verificationModal}
                euroExchangeRate={this.state.currencyToEuroRate}
                requestFeedback={this.requestFeedback}
            />
        )
    }

    getSuccessModal(){
        if(this.state.requestFeedback){
            return (
                <RequestFeedback  type={VerificationType.bonus_reward} requestFeedback={this.requestFeedback} isOpen={this.state.requestFeedback} />
                )
        }
    }


    getBonusDetails() {


        if(this.state.selectedBonus && this.state.selectedBonus.monetaryConfig) {
            const conf = this.state.selectedBonus.monetaryConfig;
            return (
                <tbody>
                <tr>
                    <td>{t(`dataTable.header.currency`)}</td>
                    <td>{conf.currencyCode}</td>
                </tr>
                <tr>
                    <td>{t(`dataTable.header.awardAmountFactor`)}</td>
                    <td>{conf.awardAmountFraction}</td>
                    <span className="help-block">{t(`helpText.awardAmountFraction`)}</span>
                </tr>
                <tr>
                    <td>{t(`dataTable.header.turnoverAmountFactor`)}</td>
                    <td>{conf.turnoverRequirementFactor}</td>
                    <span className="help-block">{t(`helpText.turnoverRequirementFactor`)}</span>
                </tr>
                </tbody>
            )
        } else {
            return <tbody/>;

        }

    }


    getForm() {
        let amount = this.state.form.amount && this.state.selectedBonus.monetaryConfig && this.state.selectedBonus.monetaryConfig.awardAmountFraction && (this.state.form.amount * this.state.selectedBonus.monetaryConfig.awardAmountFraction)

        const readyToAwardOnNextDeposit = this.state.form.claimableOnNextDeposit && this.state.form.expiryInDays && this.state.form.expiryInDays > 0
        const readyToAwardInstantly = this.state.form.amount && this.state.form.amount > 0 && !this.state.form.claimableOnNextDeposit
        const isReadyToAward = readyToAwardOnNextDeposit || readyToAwardInstantly

        const buttonText = this.state.form.claimableOnNextDeposit ? t(`button.award`) : t(`button.award`) + " " + amount

        return(
            <Form className="manual-adjustment-form container-fluid" formListener={this} validationMap={this.state.validationMap} modelObject={this.state.form}>
                <div className="row">
                    <div className="col-sm-12">
                        <SelectInput className="form-control" model="bonusConfigId" values={this.state.configs} displayValue={this.toDisplayValue} optionModelValue={ (a : BonusConfig)=> { return a.id; }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                    <label style={{paddingTop:"10px"}}>{t(`label.bonusDetails`)}</label>
                    <table className={"table table-striped"}>
                        {this.getBonusDetails()}
                    </table>
                    </div>
                </div>
                <div className="row">
                    {!this.state.form.claimableOnNextDeposit &&
                    <div className="col-sm-6">
                        <label>{t(`label.inputAmount`)}</label>
                        <FormInput className="form-control" model="amount" type="number" />
                        <span className="help-block">{t(`helpText.inputAmount`)}</span>
                    </div>
                    }
                    {this.state.form.claimableOnNextDeposit &&
                        <div className="col-sm-6">
                            <label>Expiry in days</label>
                            <FormInput className="form-control" model="expiryInDays" type="number" />
                        </div>
                    }
                    <div className="col-sm-6">
                        <div className="custom-control custom-checkbox">
                            <FormInput className="custom-control-input" model="claimableOnNextDeposit"
                                       id={"claimableOnNextDeposit"} type="checkbox"/>
                            <label htmlFor={"claimableOnNextDeposit"}
                                   className={"custom-control-label"}>Claimable on next deposit</label>
                            <span className="help-block">If checked, it will be claimable by user on next deposit otherwise will be claimable instantly</span>
                        </div>
                    </div>
                     <div className="col-sm-6">
                        <label></label>
                        <FormSubmitError/>
                         <Submit disabled={!isReadyToAward}
                                 className="btn btn-primary btn-block">
                             {buttonText}
                         </Submit>
                    </div>
                    </div>
            </Form>
    )

    }

    render() {
        return (
            <Collapsible title={t(`title.manualBonusAward`)} collapsed={true}>
                {this.getForm()}
                {this.getVerificationModal()}
                {this.getSuccessModal()}
            </Collapsible>
        )
    }
}