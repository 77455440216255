import * as React from "react";
import {
    EventResolveStatus,
    Level,
    ListSystemEventLogsRequest,
    SystemEventLog,
    SystemEventSubType
} from "../../http/protocol";
import {ListView, ListViewProps, ListViewState} from "../ListView";
import {Form, FormListener} from "../form/Form";
import {ActionType} from "../list/list/DataTable";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {ListUtils} from "../list/list/ListUtils";
import {PagerModel} from "../list/list/PagerModel";
import {getContainer} from "../../ioc/IOCSetup";
import {withRouter} from "react-router";
import {ColumnType} from "../list/list/ColumnType";
import {SystemEventService} from "../../services/SystemEventService";
import {FormGroup} from "../form/FormGroup";
import {SelectInput} from "../form/SelectInput";
import {FormInput} from "../form/FormInput";


interface State extends ListViewState<ListSystemEventLogsRequest> {
    listRequest?: ListSystemEventLogsRequest,
    form?: ListSystemEventLogsRequest,
    validationMap?: any;
}


export class SystemEventListView extends ListView<State, ListSystemEventLogsRequest> implements FormListener<ListSystemEventLogsRequest> {


    systemEventService: SystemEventService;

    columns = [
        {property: "id", name: "ID"},
        {property: "timestamp", name: "Time", type: ColumnType.DATE},
        {property: "subType", name: "Type"},
        {property: "level", name: "Level"},
        {
            property: "resolved", name: "Resolved",
            renderer: (property: string, name: string, item: any) => {
                let backgroundColor = '#F1556C';
                if (item["resolved"]) {
                    backgroundColor = '#28A745';
                }
                const backgroundColorCSS = {
                    backgroundColor: backgroundColor
                };

                return <span style={backgroundColorCSS} className={"dot"}/>
            }
        }
    ];

    actions = [
        {
            id: "view",
            name: "Detail",
            className: "btn btn-primary",
            iconClassName: "fa fa-eye",
            type: ActionType.LINK,
            getLink: (item: SystemEventLog) => "/system-event/" + item.id
        }
    ];

    constructor(props: ListViewProps) {
        super(props, "/system-events");
        let listRequest = {limit: 50, offset: 0, resolved: EventResolveStatus.NOT_RESOLVED, level: Level.ERROR}
        this.state = {...this.state, validationMap: {}, form: {...listRequest}, listRequest: listRequest};
        this.systemEventService = getContainer().getSystemEventService();
    }

    formDidUpdate(formModel: ListSystemEventLogsRequest, validationMap: any): void {
        this.setState({form: formModel, validationMap: validationMap});
    }

    onSubmitError(): void {
        console.log("submit error!")
    };

    onSubmit(): void {
        let listRequest: ListSystemEventLogsRequest = Object.assign({}, this.state.form);
        listRequest.offset = 0;
        this.updateListState(listRequest);
    };

    filterEmpty(o: any): any {
        if (o == null || o == "") {
            return undefined;
        } else {
            return o;
        }
    }

    pagerChanged(pager: PagerModel) {
        if (!this.state.listRequest) {
            return;
        }
        let listRequest = ListUtils.toListRequest(this.state.listRequest, pager);
        this.setState({listRequest: listRequest});
    }

    onAction(item: SystemEventLog, action: string): undefined | Promise<any> {
        return;
    }

    getTitle() {
        return "System Event List";
    }

    renderContent() {
        if (!this.state.listRequest) {
            return <span/>
        }

        return (
            <div>
                <div className={"card-box"}>
                    <div className="ibox-content">

                        <Form className="form-horizontal" formListener={this} modelObject={this.state.form}
                              validationMap={this.state.validationMap}>
                            <FormGroup className="form-group">
                                <label className="col-lg-2 control-label">ID</label>
                                <div className="col-lg-10">
                                    <FormInput className="form-control" model="id" type={"number"}/>
                                </div>
                            </FormGroup>
                            <FormGroup className="form-group">
                                <label className="col-lg-2 control-label">Type</label>
                                <div className="col-lg-10">
                                    <SelectInput size={1} className="form-control" model="subType" optional={true}
                                                 values={Object.values(SystemEventSubType)} multiple={false}/>
                                </div>
                            </FormGroup>
                            <FormGroup className="form-group">
                                <label className="col-lg-2 control-label">Level</label>
                                <div className="col-lg-10">
                                    <SelectInput size={1} className="form-control" model="level" optional={true}
                                                 values={Object.values(Level)} multiple={false}/>
                                </div>
                            </FormGroup>
                            <FormGroup className="form-group">
                                <label className="col-lg-2 control-label">Resolved</label>
                                <div className="col-lg-10">
                                    <SelectInput size={1} className="form-control" model="resolved" optional={true}
                                                 values={Object.values(EventResolveStatus)} multiple={false}/>
                                </div>
                            </FormGroup>
                            <div className="form-group">
                                <div className="col-lg-offset-2 col-lg-10">
                                    <input className="btn btn-primary" value="List System Events" type={"submit"}/>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                <EntityDataTable
                    service={this.systemEventService}
                    onPagerChange={this.pagerChanged.bind(this)}
                    columns={this.columns}
                    listRequest={this.state.listRequest}
                    onAction={this.onAction.bind(this)}
                    actions={this.actions}
                />
            </div>
        );
    }

}

export default withRouter(SystemEventListView)
