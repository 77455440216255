import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { SportsbookConfig, SportsbookProvider, SportsbookProviderOption } from "../../http/protocol";
import { Modal } from "react-bootstrap";
import { useDragAndDrop } from "../menu/utils";
import { t } from "../../i18n";

interface Props {
  config?: SportsbookConfig;
  onSave?: (item: SportsbookConfig) => void;
  setShow: () => void;
  show: boolean;
}

export default function SportsbookSettings(props: Props) {
  const [providers, setproviders] = useState<SportsbookProviderOption[]>([]);
  const [enableConfig, setEnableConfig] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string>("");
  const { register, handleSubmit, reset } = useForm<SportsbookProviderOption>({
    defaultValues: { pathname: "", provider: SportsbookProvider.BETBY },
  });

  useEffect(() => {
    if (props.config) {
      setproviders(props.config.providers ?? []);
      setEnableConfig(props.config.enabledConfig ?? false);
    }
  }, [props.config]);

  const setOrder = (providers: SportsbookProviderOption[]) => {
    setproviders([...providers]);
  };

  const { onDragEnd, onDragOver, onDragStart } = useDragAndDrop<SportsbookProviderOption>(providers ?? [], setOrder);

  const onSubmit = (values: SportsbookProviderOption) => {
    if (!values.pathname) {
      setSubmitError("Pathname is required for each provider");
      return;
    } else if (providers.some((p) => p.pathname == values.pathname)) {
      setSubmitError("Pathname already exists");
      return;
    }
    if (values.pathname && values.provider) {
      if (providers.some((p) => p.provider == values.provider)) {
        setSubmitError("Provider already in config");
        return;
      }
      setSubmitError("");
      reset();
      setproviders((p) => [...p, values]);
    }
  };

  const deleteProvider = (id?: string) => {
    if (providers.length) {
      setproviders((p) => p.filter((c) => c.pathname !== id));
    }
  };

  const onSave = () => {
    if (props.onSave && props.setShow) {
      props.onSave({ enabledConfig: enableConfig, providers: providers });
      props.setShow();
    }
  };

  return (
    <Modal show={props.show} onHide={() => props.setShow()}>
      <Modal.Header>
        <h4>{t('helpText.sportsbookConfigTitle')}</h4>
      </Modal.Header>
      <Modal.Body className="px-3 py-3">
        <div className="">
          <section>
            <div>
              <h5>{t('helpText.sportsbookGuide')}</h5>
              <span className={"help-block"}>
                {t('helpText.sportsbookConfigGuide')}
              </span>
            </div>
            <div className="hr-line-dashed mt-2"></div>

            <div className="form-group">
              <input checked={enableConfig} onChange={() => setEnableConfig((p) => !p)} type="checkbox" className="" />
              <label className="px-2 mb-1">{t("helpText.enableSportsConfig")}</label>
              <span className={"help-block"}>{t("helpText.enableSportsConfigInfo")}</span>
            </div>
            <div className="hr-line-dashed mt-2"></div>
          </section>
          <section className="display-added-providers">
            <h4>{t("helpText.currentSportsConfig")}</h4>
            <ul className="list-group p-2">
              {Boolean(providers.length) &&
                providers.map((c, i) => {
                  return (
                    <li key={i} className="row tab-row justify-content-between provider-item" onDragOver={(e) => onDragOver(e, i)}>
                      <div className="provider-item-wrapper" draggable onDragStart={(e) => onDragStart(e, i)} onDragEnd={(e) => onDragEnd(e)}>
                        <div className="casino-tab-drag-name-section">
                          <i className="fe-menu" />
                          <span className="content">
                            <span className={"content-container"}>
                              <span className="badge badge-info p-2">Provider: {c.provider}</span>
                            </span>
                          </span>
                          <span className="content">
                            <span className={"content-container"}>
                              <span className="badge badge-secondary p-2">Path: {c.pathname}</span>
                            </span>
                          </span>
                        </div>
                        <div className="cta-section">
                          <button onClick={() => deleteProvider(c.pathname)} className="btn btn-danger mx-1" type="button">
                            Delete
                          </button>
                        </div>
                      </div>
                    </li>
                  );
                })}
              {!Boolean(providers.length) && <li className="row tab-row"> No Sportsbooks configured </li>}
            </ul>
          </section>
          <div className="hr-line-dashed mt-2"></div>

          <section className="create-provder">
            <h4 className="mb-3">Create Sportsbook</h4>
            {submitError && <div className="badge bg-danger p-2">{submitError}</div>}
            <form action="submit" onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <label>Sportsbook Provider</label>
                <select {...register("provider")} className={"form-control"}>
                  {Object.keys(SportsbookProvider).map((v) => {
                    return (
                      <option key={v} value={v}>
                        {v}
                      </option>
                    );
                  })}
                </select>
                <span className={"help-block"}>{t("helpText.chooseSportsProviderInfo")} </span>
              </div>
              <div className="form-group">
                <label>Sports Page Pathname</label>
                <input {...register("pathname")} className={"form-control"} type={"text"} />
                <span className={"help-block"}>{t("helpText.sportsConfigPathInfo")}</span>
              </div>
              <div className="form-group">
                <button className="btn btn-success" type="submit">
                  {t("helpText.createProvider")}
                </button>
              </div>
            </form>
          </section>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="form-group">
          <button onClick={() => props.setShow && props.setShow()} type="button" className={"btn btn-primary"}>
            Cancel
          </button>
          <button onClick={() => onSave()} type="button" className={"btn btn-success"}>
            Save
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
