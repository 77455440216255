import * as React from "react";
import {Fragment} from "react";
import {SettingsService} from "../../services/SettingsService";
import {getContainer} from "../../ioc/IOCSetup";
import {LoadingViewState, RouterView} from "../RouterView";
import {Form, FormListener} from "../form/Form";
import {RouteComponentProps, withRouter} from "react-router";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Submit} from "../form/Submit";
import {Validators} from "../form/Validators";
import {Settings, TransferLimit} from "../../http/protocol";
import './SettingsTransfeLimitsView.scss';
import {type} from "os";
import {FormList} from "../form/FormList";
import {FormTransferContainer} from "../form/FormTransferContainer";

interface Props extends RouteComponentProps<{id?: string}> {

}

interface TL extends TransferLimit {
    expanded?: boolean
}
interface LimitsForm { transferLimits:TL[]; }
interface State extends LoadingViewState{
    settings?: Settings;
    limits : LimitsForm;
    validationMap? : any;
    atBottom:boolean;

}

export class SettingsTransferLimitsView extends RouterView<Props,State> implements FormListener<LimitsForm>{

    settingsService: SettingsService;

    constructor(props:Props) {
        super(props);
        this.settingsService = getContainer().getSettingsService();


        this.state = { validationMap : {}, loading : true, atBottom:false,  limits : { transferLimits:[] }};
    }
    componentDidMount() {
        super.componentDidMount();
        window.onscroll = (ev:any) => {
            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                this.setState({atBottom:true})
            }

            if ((window.innerHeight + window.scrollY) <= document.body.offsetHeight) {
                this.setState({atBottom:false})
            }
        };
    }


    loadContent(): Promise<any> | undefined {
        return this.settingsService.getSettings().then(s => {
            let currencies: any[] = s?.currencies?.map((c) => c.code) ?? [];
            if(!this.props.match?.params?.id) {
                return Promise.resolve();
            }
            return this.settingsService.getEntity(this.props.match?.params?.id).then((settings) => {

                if(settings.currencies && settings.transferLimits) {
                    //Remove archived currencies from view
                    settings.transferLimits = settings.transferLimits.filter(tl => currencies.includes(tl.currency));
                    const tl:TL[] = settings.transferLimits.map((tl:any) => {
                        tl.expanded = false;
                        return tl;
                    });
                    if(currencies) {
                        currencies.forEach((code : string) => {
                            if(tl.findIndex(x => x.currency == code) === -1) {
                                tl.push({currency : code, maxDepositAmount : undefined, maxWithdrawAmount : undefined, minDepositAmount : undefined, minWithdrawAmount : undefined, expanded : false});
                            } else {
                                console.log("transfer limits for that currency already exist!")
                            }
                        });
                    }
                    this.setState({limits : { transferLimits:tl }, settings:settings});
                }
            })
        })

    }
    componentDidUpdate(prevProps : RouteComponentProps, prevState : any) {
        super.componentDidUpdate(prevProps, prevState);

    }

    formDidUpdate(formModel: LimitsForm, validationMap : any) {
        this.setState({ limits : formModel, validationMap : validationMap ?? {}});
    }

    onSubmit():Promise<any> {
        if(!this.state.settings) {
            return Promise.reject("Unable to save settings");
        }
        let settings = this.state.settings;
        settings.transferLimits = this.checkForEmptyStringAndSetToNull();
        console.log("Settings to save!: ", settings)
        return this.settingsService.saveSettings(settings).catch(err=>{
            throw "Unable to create/save settings";
        });
    }

    onSubmitError(){}

    getTitle() : string {
        return "Transfer Limits Settings";
    }


    checkForEmptyStringAndSetToNull() {


        if(this.state.limits?.transferLimits) {

            let limits : TransferLimit[] = this.state.limits.transferLimits.concat().map(tl => {
                let tmp : TL = {...tl};
                delete tmp["expanded"];
                Object.keys(tmp).forEach(key => {
                    if((tmp as any)[key] === "") {
                        (tmp as any)[key] = null;
                    }
                })
                return tmp as TransferLimit;
            });

            return limits as TransferLimit[];
        }
        return [];

    }
    toggleCollapsed(currency?:string) {
        let tl:TL[] = this.state.limits.transferLimits;
        if(currency) {
            tl.map((tl) => {
                if(tl.currency === currency) {
                    tl.expanded = !tl.expanded;
                }
                return tl;
            })
            this.setState({ limits : {transferLimits:tl}});
        }


    }

    getTransferLimitsSettings():any[] | undefined {
        if(this.state.limits.transferLimits) {
            let tl = this.state.limits.transferLimits.sort((a,b) => (a.currency && b.currency && a.currency > b.currency) ? 1 : -1)
            let result = tl.map((obj: TL, index) => {
                if(!obj.expanded) {
                    return (
                        <div className={"card"}>
                            <div className="card-header">
                                <h4 className="pointer" onClick={() => this.toggleCollapsed(obj.currency)}><i className={"fa fa-chevron-right"}></i> {obj.currency}</h4>
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div className={"card"}>
                            <div className="card-header">
                                <h4 className="pointer" onClick={() => this.toggleCollapsed(obj.currency)}><i className={"fa fa-chevron-down"}></i> {obj.currency}</h4>
                            </div>
                            <div className="card-body">
                                <FormTransferContainer model={`transferLimits[${index}]`}>
                                    <h4>DEPOSIT</h4>
                                    <FormGroup className="form-group mb-3" model={`minDepositAmount`}>
                                        <label>MIN Amount</label>
                                        <FormInput className="form-control"
                                                   model={`minDepositAmount`} type="number"
                                                   validators={[Validators.number()]}/>
                                        <span
                                            className={"help-block"}>Set minimum Deposit amount - Empty field equals NO LIMIT</span>
                                    </FormGroup>
                                    <FormGroup className="form-group mb-3"
                                               model={`maxDepositAmount`}>
                                        <label>MAX Amount</label>
                                        <FormInput className="form-control"
                                                   model={`maxDepositAmount`} type="number"
                                                   validators={[Validators.number()]}/>
                                        <span
                                            className={"help-block"}>Set maximum Deposit amount - Empty field equals NO LIMIT</span>
                                    </FormGroup>
                                    <FormGroup className="form-group mb-3"
                                               model={`defaultDepositAmount`}>
                                        <label>DEFAULT Amount</label>
                                        <FormInput className="form-control"
                                                   model={`defaultDepositAmount`} type="number"
                                                   validators={[Validators.number()]}/>
                                        <span className={"help-block"}>For payments that has prefilled amounts, this will be the middle preset deposit amount upon first deposit</span>
                                    </FormGroup>
                                    <h4>WITHDRAW</h4>
                                    <FormGroup className="form-group mb-3"
                                               model={`minWithdrawAmount`}>
                                        <label>MIN Amount</label>
                                        <FormInput className="form-control"
                                                   model={`minWithdrawAmount`} type="number"
                                                   validators={[Validators.number()]}/>
                                        <span
                                            className={"help-block"}>Set minimum Withdraw amount - Empty field equals NO LIMIT</span>
                                    </FormGroup>
                                    <FormGroup className="form-group mb-3"
                                               model={`maxWithdrawAmount`}>
                                        <label>MAX Amount</label>
                                        <FormInput className="form-control"
                                                   model={`maxWithdrawAmount`} type="number"
                                                   validators={[Validators.number()]}/>
                                        <span
                                            className={"help-block"}>Set maximum Withdraw amount - Empty field equals NO LIMIT</span>
                                    </FormGroup>

                                    <h4>TIPPING</h4>
                                    <FormGroup className="form-group mb-3" model={`minTipAmount`}>
                                        <label>MIN Amount</label>
                                        <FormInput className="form-control" model={`minTipAmount`}
                                                   type="number" validators={[Validators.number()]}/>
                                        <span className={"help-block"}>Minimum amount for a player to player tip transaction - Empty field equals NO LIMIT</span>
                                    </FormGroup>
                                </FormTransferContainer>
                            </div>
                        </div>
                    )
                }

            });
            return result;
        }

    }

    goToEnd() {
        if(this.state.atBottom) {
            window.scrollTo(0, 0);
            this.setState({atBottom:false})
        } else {
            window.scrollTo(0,document.body.scrollHeight);
            this.setState({atBottom:true})
        }
    }

    renderContent() {
        if(!this.state.limits.transferLimits) {
            return <span></span>
        }

        let limitsComponent = this.getTransferLimitsSettings();
        if(limitsComponent != undefined) {
            return(
                <Fragment>
                    {/*{this.state.transferLimits && this.state.transferLimits.length > 3 && (*/}
                    {/*    <div className={"goToEndButton"} onClick={() => this.goToEnd()}>{this.state.atBottom ? <i className="fe-chevron-up " style={{position:"relative", top:"4px"}}/> : <i className="fe-chevron-down " style={{position:"relative", top:"8px"}}/>}</div>*/}
                    {/*)}*/}
                    <Form className="form-horizontal" formListener={this} modelObject={this.state.limits} validationMap={this.state.validationMap}>
                        {limitsComponent}
                        <FormSubmitError/>
                        <FormSubmitSuccess text="Settings was updated successfully"/>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="ibox float-e-margins">
                                            <div className="ibox-content">
                                                <div className="ibox-title">
                                                    <h5>Actions</h5>
                                                </div>
                                                <div className="form-group">
                                                    <div className="col-sm-10 col-sm-offset-2">
                                                        <Submit className="btn btn-primary waves-effect waves-light">Save Settings</Submit>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Form>


                </Fragment>
            )
        }else {
            return (<div></div>)
        }

    }

}

export default withRouter(SettingsTransferLimitsView);