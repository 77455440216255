import React, {Fragment} from "react";
import {CashbackPayoutRequest, ListUsersRequest, SingleCurrencyCashbackPayout} from "../../http/protocol";
import {ListView, ListViewProps, ListViewState} from "../ListView";
import {Form, FormListener} from "../form/Form";
import {ColumnType} from "../list/list/ColumnType";
import {PagerModel} from "../list/list/PagerModel";
import {ListUtils} from "../list/list/ListUtils";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {withRouter} from "react-router";
import {getContainer} from "../../ioc/IOCSetup";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Validators} from "../form/Validators";
import {Submit} from "../form/Submit";
import {CashbackSingleCurrencyPayoutService} from "../../services/CashbackSingleCurrencyPayoutService";

interface State extends ListViewState<CashbackPayoutRequest> {
    form?: CashbackPayoutRequest;
    validationMap?: any;
}

interface Props extends ListViewProps {
}


export class CashbackSingleCurrencyPayoutListView extends ListView<State, CashbackPayoutRequest> implements FormListener<CashbackPayoutRequest> {

    columns = [
        {property: "id", name: "Id", orderBy: "id"},
        {
            property: "userId",
            name: "User ID",
            type: ColumnType.LINK,
            getUrl: (item: SingleCurrencyCashbackPayout) => "/user/" + item.userId
        },
        {property: "cashbackLogId", name: "Cashback Log ID"},
        {property: "currency", name: "Currency"},
        {property: "amount", name: "Amount"},
        {property: "status", name: "Status"},
        {property: "created", name: "Created", type: ColumnType.DATE},
    ];


    service: CashbackSingleCurrencyPayoutService;

    constructor(props: Props) {
        super(props, "/cashback/single-currency-payout/list");
        this.state = {...this.state, validationMap: {}, form: {...this.state.listRequest} as any};
        this.service = getContainer().getCashbackSingleCurrencyPayoutService();
    }


    formDidUpdate(formModel: ListUsersRequest, validationMap: any): void {
        this.setState({form: formModel, validationMap: validationMap});
    }

    onSubmitError(): void {

    };

    onSubmit(): void | Promise<any> {
        if (this.state.form) {
            let form = this.state.form;
            form.offset = 0;
            this.updateListState(form);
        }
    }


    pagerChanged(pager: PagerModel) {
        if (this.state.listRequest) {
            var listRequest = ListUtils.toListRequest(this.state.listRequest, pager);
            this.setState({listRequest: listRequest});
        }
    }


    getTitle() {
        return "Cashback Single Currency Payouts";
    }

    renderContent() {
        if (!this.state.listRequest) {
            return <div></div>;
        }
        return (
            <Fragment>
                <div>
                    <div className="wrapper wrapper-content">
                        <div className={"card-box"}>
                            <h4>Search Filters</h4>
                            <Form formListener={this} modelObject={this.state.form}
                                  validationMap={this.state.validationMap}>
                                <FormGroup className={"form-group"}>
                                    <label>User Id</label>
                                    <FormInput model={"userId"} className={"form-control"} type={"integer"}
                                               validators={[Validators.integer()]}/>
                                </FormGroup>
                                <FormGroup className={"form-group"}>
                                    <label>Cashback Log Id</label>
                                    <FormInput model={"cashbackLogId"} className={"form-control"} type={"integer"}
                                               validators={[Validators.integer()]}/>
                                </FormGroup>
                                <Submit className={"btn btn-primary"}>Search</Submit>
                            </Form>
                        </div>
                        <EntityDataTable service={this.service}
                                         onPagerChange={this.pagerChanged.bind(this)}
                                         columns={this.columns}
                                         listRequest={this.state.listRequest}
                                         onAction={() => {
                                             return new Promise<any>(() => console.log(""));
                                         }}/>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(CashbackSingleCurrencyPayoutListView);