import React, { useCallback, useEffect, useState } from "react";
import { MenuItem, MenuItemType, SubMenu, SubMenuTab } from "../../http/protocol";
import { addUniqueId, useDragAndDrop } from "./utils";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import { Translation } from "./EditUtils";
import { useForm } from "react-hook-form";
import SubmenuItem from "./SubmenuItem";
import EditIcons from "./EditIcon";

interface Props {
    menuItem?: MenuItem;
    selectedType?: MenuItemType;
    handleChange: (submenu: SubMenuTab[], pageId: number, pageName: string, defaultPage: boolean, reset?: boolean) => void;
}
export default function EditCustomSubmenu(props: Props) {
    const [subMenu, setSubMenu] = useState<SubMenu>({});

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [editTab, setEditTab] = useState<SubMenuTab | undefined>(undefined);

    const setsubmenuTabs = (tabs: SubMenuTab[]) => {
        setSubMenu((prev) => ({ ...prev, tabs: tabs }));
    };

    const { onDragEnd, onDragOver, onDragStart } = useDragAndDrop<SubMenuTab>(subMenu.tabs ?? [], setsubmenuTabs);

    function deleteMenuTab(id: number | string | undefined) {
        if (subMenu && subMenu.tabs) {
            let tabs = subMenu.tabs.filter((t) => t.id !== id);
            setSubMenu((prev) => ({ ...prev, tabs: tabs }));
        }
    }

    function resetSubmenu() {
        props.handleChange([], 0, "", false, true);
        setSubMenu({});
    }

    function addNewSubMenuTab(tab: SubMenuTab) {
        setSubMenu((prev) => ({ ...prev, tabs: [...(prev.tabs ?? []), { ...tab, type: MenuItemType.CUSTOM, id: addUniqueId(prev.tabs ?? []) }] }));
    }

    function saveEditedSubMenuTab(tab: SubMenuTab) {
        let tabs = subMenu.tabs?.map((t) => (t.id == tab.id ? tab : t));
        setSubMenu((prev) => ({ ...prev, tabs: tabs }));
    }

    function toggleEditView() {
        setIsOpen((p) => !p);
    }

    function editMenuTab(id: number | string) {
        if (subMenu && subMenu.tabs) {
            let tab = subMenu.tabs[id as any];
            if (tab) {
                setIsOpen((p) => !p);
                setEditTab(tab);
            }
        }
    }

    const saveSubmenu = (submenu: SubMenuTab[]) => {
        props.handleChange(submenu, subMenu.id ?? Math.floor(Math.random() * 10), subMenu.name ?? "", false);
    };

    useEffect(() => {
        saveSubmenu(subMenu.tabs ?? []);
    }, [subMenu]);

    useEffect(() => {
        if (!_.isUndefined(props.menuItem) && !_.isUndefined(props.menuItem.subMenu)) {
            setSubMenu(props.menuItem.subMenu ?? {});
        }
        return () => {
            setSubMenu({});
        };
    }, [props.menuItem]);

    return (
        <div>
            <div>
                <label>SubMenu tabs</label>

                <ul className="list-group p-1">
                    {subMenu && subMenu.tabs && subMenu.tabs.map((item, idx) => <SubmenuItem key={idx} editSubMenuTab={editMenuTab} deleteSubMenuTab={deleteMenuTab} onDragEnd={onDragEnd} onDragOver={onDragOver} onDragStart={onDragStart} index={idx} item={item} />)}
                    {!Object.keys(subMenu).length && (
                        <li className="row tab-row w-100">
                            <span className={"help-block"}>Empty List, import casino tabs or create custom tab</span>
                        </li>
                    )}
                </ul>
            </div>
            <div className="d-flex flex-column mt-2 mb-2">
                <button
                    onClick={() => {
                        toggleEditView();
                        setEditTab(undefined);
                    }}
                    className="btn btn-success no-wrap"
                    type="button"
                >
                    Add New Tab
                </button>
            </div>
            <div className="d-flex flex-column ">
                <button
                    onClick={() => {
                        resetSubmenu();
                    }}
                    className="btn btn-secondary no-wrap"
                    type="button"
                >
                    Clear Sub Menu
                </button>
            </div>
            <div className="hr-line-dashed mt-4"></div>
            <EditSubMenuItem isOpen={isOpen} setIsOpen={toggleEditView} subMenu={subMenu} subMenuTab={editTab} onSaveNew={addNewSubMenuTab} onSave={saveEditedSubMenuTab} />
        </div>
    );
}

/* --------------------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------------- */

interface EditSubMenuItemProps {
    subMenuTab?: SubMenuTab | undefined;
    subMenu?: SubMenu;
    isOpen: boolean;
    onSave: (item: SubMenuTab) => void;
    onSaveNew?: (tab: SubMenuTab) => void;
    setIsOpen: () => void;
    pageId?: string | number;
}

export const EditSubMenuItem = (props: EditSubMenuItemProps) => {
    const [subMenuTab, setsubMenuTab] = useState<SubMenuTab>({});
    const { register, handleSubmit, setValue, reset, watch } = useForm<SubMenuTab>({ values: subMenuTab });
    const [translation, setTranslation] = useState<{ [index: string]: string }>({});
    const [viewIcons, setViewIcons] = useState(false);

    let customIcon = watch("icon");

    const onSubmit = (form: SubMenuTab) => {
        if (props.subMenuTab == undefined && props.onSaveNew) {
            props.onSaveNew({ ...form });
        } else if (props.subMenuTab !== undefined && props.onSave) {
            props.onSave({ ...form });
        }
        props.setIsOpen();
    };

    const saveTranslations = (t: { [index: string]: string }) => {
        setValue("translations", t);
    };

    const toggleIconModal = () => {
        setViewIcons((p) => !p);
    };

    const saveIcon = (tag: string) => {
        setValue("icon", tag);
    };

    const cleanUp = () => {
        setsubMenuTab({});
        setTranslation({});
        reset({ name: "", url: "", icon: "", translations: {}, target: "", id: undefined, type: undefined });
    };

    useEffect(() => {
        if (props.subMenuTab) {
            setsubMenuTab(props.subMenuTab);
            setTranslation(props.subMenuTab.translations ?? {});
        } else {
            cleanUp();
        }
    }, [props.subMenuTab, props.isOpen, props]);

    useEffect(() => {
        return () => {
            cleanUp();
        };
    }, [props.subMenuTab]);

    return (
        <Modal show={props.isOpen} onHide={() => props.setIsOpen()}>
            <Modal.Header>
                <h4>Edit Sub Menu Tab</h4>
            </Modal.Header>
            <Modal.Body className="px-3 py-3">
                <form action="">
                    <div className={"form-group form-check p-0"}>
                        <label>Name</label>
                        <input disabled={subMenuTab.type == MenuItemType.CASINO} className={"form-control"} type={"text"} {...register("name")} />
                        <span className={"help-block"}>Select a title for the submenu tab. You'll have the option to translate it into different languages later in the form.</span>
                    </div>
                    <div className={"form-group form-check p-0"}>
                        <label>Url</label>
                        <input disabled={subMenuTab.type == MenuItemType.CASINO} className={"form-control"} type={"text"} {...register("url")} />
                        <span className={"help-block"}>The sub menu URL, if it starts with / it will be considered to be an internal link and language etc will be appended automatically. (eg /poker) If link has not / it will be considerd an external link</span>
                    </div>
                    <div className={"form-group"}>
                        <label>Target</label>
                        <input {...register("target")} className={"form-control"} />
                        <span className={"help-block"}>Link target: If not specified, the default behavior will open the link in the same window. Use '_blank' to open the menu item in a new window or tab.</span>
                    </div>
                    <div className="hr-line-dashed mt-2"></div>
                    <div className="icon-select-container">
                        <div className={"form-group icon-preview"}>
                            <button onClick={toggleIconModal} type="button" className={"btn btn-primary"}>
                                Assign icon
                            </button>
                            <div className="form-group d-flex flex-column">
                                <label htmlFor="">Custom icon class</label>
                                <input disabled type="text" value={customIcon} />
                            </div>
                            <EditIcons icon={customIcon} isOpen={viewIcons} setIsOpen={toggleIconModal} saveIcon={saveIcon} />
                        </div>
                        <span className={"help-block"}>
                            Select Icon for Sub menu tab, to view selected: Click "Edit Icon". For more information on icon customization view our docs{" "}
                            <a href="https://docs.cubeia.com/docs/navigation-sidemenu" target="_blank" rel="noopener noreferrer">
                                Documentation
                            </a>
                        </span>
                    </div>
                    <Translation saveTranslations={saveTranslations} translations={translation} className="justify-content-between" />
                </form>
            </Modal.Body>
            <Modal.Footer>
                <div className="form-group">
                    <button onClick={() => props.setIsOpen()} type="button" className={"btn btn-primary"}>
                        Cancel
                    </button>
                    <button onClick={handleSubmit(onSubmit)} type="button" className={"btn btn-success"}>
                        Save
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};
