import {CardBox, RouterViewTemplate, useLoadingState} from "../RouterView";
import {getContainer} from "../../ioc/IOCSetup";
import * as React from "react";
import {InstantWithdrawStatus, ListRequest, PermissionCategory, Site} from "../../http/protocol";
import {Link} from "react-router-dom";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {ActionType} from "../list/list/DataTable";
import {ColumnType} from "../list/list/ColumnType";
import {PagerModel} from "../list/list/PagerModel";
import {useState} from "react";
import { PermissionRequired } from "../security/PermissionRequired";


export const InstantWithdrawalsView = () => {

    const service = getContainer().getInstantWithdrawalService()

    const [iwStatus, setIwStatus] = useState<InstantWithdrawStatus|undefined>()

    const loadingState = useLoadingState(()=>{
        return service.getStatus().then((s: InstantWithdrawStatus) => {
            setIwStatus(s)
        })
    },[]);

    const refresh = async () => {
        const s = await service.getStatus()
        setIwStatus(s)
    }

    const setEnabled = async (enable: boolean) => {
        const s = await service.setEnabled(enable)
        setIwStatus(s)
    }
    

    const enabled = (iwStatus?.enabledBySettings  &&  iwStatus?.enabledMaster);

    return (
        <RouterViewTemplate status={loadingState} title={"Instant Withdrawals"}>
            <div className="row">
                <div className="col-lg-12">
                    <PermissionRequired permissions={[PermissionCategory.ADMIN_INSTANT_WITHDRAWALS, PermissionCategory.SETTINGS]} accessLevel="r">
                        <div className="card-box">
                            <div className="ibox-title">
                                <h5>Service Status</h5>
                                <div className="help-text">NOTE: Enabling or disabling the service could take a couple of minutes before taking effect</div>
                            </div>
                            <div className="ibox-content">
                                <table className="table m-b-xs table-striped">
                                    <tbody>
                                        <tr>
                                            <th>Enabled</th>
                                            <td>{enabled ? "Yes" : "No"}</td>
                                        </tr>
                                        <tr>
                                            <th>Enabled By Settings</th><td>{iwStatus?.enabledBySettings ? "Yes" : "No"}
                                                &nbsp;
                                                <PermissionRequired permissions={[PermissionCategory.ADMIN_INSTANT_WITHDRAWALS, PermissionCategory.SETTINGS]} accessLevel="w">
                                                    <>
                                                        {!enabled  &&  <button className="btn btn-xs btn-success float-right" onClick={() => setEnabled(true)}>Enable</button>}
                                                        {enabled  &&  <button className="btn btn-xs btn-danger float-right" onClick={() => setEnabled(false)}>Disable</button>}
                                                    </>
                                                </PermissionRequired>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Enabled By Master Property</th><td>{iwStatus?.enabledMaster ? "Yes" : "No"}</td>
                                        </tr>
                                        <tr>
                                            <th>Latest handled withdrawal id</th><td>{iwStatus?.lastHandledWithdrawalId}</td>
                                        </tr>
                                        <tr>
                                            <th>Request timestamp of latest handled withdrawal</th><td>{iwStatus?.lastHandledWithdrawalTimestamp && new Date(iwStatus?.lastHandledWithdrawalTimestamp ?? 0).toString()}</td>
                                        </tr>
                                        <tr>
                                            <th>Latency (time between withdrawal requested and handled)</th><td>{iwStatus?.latencyMs  &&  <>{(iwStatus.latencyMs! / 1000).toFixed(2)}s</>}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button className="btn btn-primary" onClick={() => refresh()}>Refresh</button>
                            </div>
                        </div>
                    </PermissionRequired>
                </div>
            </div>

        </RouterViewTemplate>
    )
}
