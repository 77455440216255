import * as React from "react";
import {FormComponentProps} from "./Form";
import {FormUtils} from "./FormUtils";
import * as _ from "lodash";


interface FormGroupProps extends React.HTMLProps<HTMLDivElement>, FormComponentProps {
    children? : any;
    model? : string;
    models? : string[];
}

export class FormGroup extends React.Component<FormGroupProps, {}>   {
    constructor(props : FormGroupProps) {
        super(props);
    }
    getClassNames() {
        return FormUtils.getClassName(this.props.className, this.props.errorClass, this.isValid());
    }

    isModelValid(model : string) : boolean {
        return typeof(this.props.fieldSet) != "undefined" && this.props.fieldSet.isModelValid(model);
    }

    isValid() {
        if(this.props.model != null) {
            return this.isModelValid(this.props.model);
        } else if(this.props.models != null) {
            return this.props.models.every( (m : string) => { return  this.isModelValid(m) });
        }
    }
    render(){
        let rest = _.omit(this.props,['model', 'models',`fieldSet`, `modelValue`, `valid`, 'validationMap']);
        return (
            <div {...rest} className={this.getClassNames()}>
                {this.props.children}
            </div>
        )
    }
}


