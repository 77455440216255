import * as React from "react";
import {Modal} from "react-bootstrap";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {getContainer} from "../../ioc/IOCSetup";
import {ColumnType} from "../list/list/ColumnType";
import {CasinoGame, GameIntegration, ListCasinoGameRequest} from "../../http/protocol";
import {Form, FormListener} from "../form/Form";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Validators} from "../form/Validators";
import {Submit} from "../form/Submit";
import {ListUtils} from "../list/list/ListUtils";
import {IPagerModel, PagerModel} from "../list/list/PagerModel";
import {SelectInput} from "../form/SelectInput";

interface Props {
    showAddGames?: boolean
    toggleShowAddGames: (show : boolean) => void;
    addGame : (game : CasinoGame) => void;
    removeGame : (game : CasinoGame) => void;
    addedGames : CasinoGame[];
    includeIntegrations? : GameIntegration[]
    title?: string;
    onlyFreeSpinSupported?: boolean
}
interface State {
    listRequest? : ListCasinoGameRequest;
    form?: ListCasinoGameRequest;
    validationMap? : any;
    pager? : IPagerModel;
}
export class AddCasinoGames extends React.Component<Props, State> implements FormListener<ListCasinoGameRequest>{
    columns = [
        { property : "id", name : "ID", orderBy : "id"},
        { property : "name", name : "name", orderBy : "name"},
        { property : "integration", name : "Integration"},
        { property : "studio.name", name : "Studio", type : ColumnType.TEXT},
        { property : "enabled", name : "Enabled", type : ColumnType.YES_OR_NO},
        { property : "removed", name : "Removed", type : ColumnType.YES_OR_NO, invertColors : true},
        { property : "freeSpinsSupported", name : "freeSpinsSupported", type : ColumnType.YES_OR_NO},
        { property : "studio.enabled", name : "Studio Enabled", type : ColumnType.YES_OR_NO},

    ];
    actions = [
        {
            id : "add", name : "Add", className : "btn btn-primary", iconClassName : "fe-plus-square",
                filter : (item : CasinoGame) => {
                    return !this.props.addedGames.some( g => g.id == item.id)
                }
        },
        {
            id : "remove", name : "Remove", className : "btn btn-warning", iconClassName : "fe-minus-square",
            filter : (item : CasinoGame) => {
                return this.props.addedGames.some( g => g.id == item.id)
            }
        }
    ];

    constructor(props : Props) {
        super(props);
        let pager : IPagerModel = { offset : 0, limit : 20, orderBy : "id", ascending : true};
        let integrations = this.props.includeIntegrations ? this.props.includeIntegrations : undefined;
        this.state = { validationMap : {}, listRequest : {...pager, integrations : integrations, onlyFreeSpinSupported: this.props.onlyFreeSpinSupported}, form : {onlyFreeSpinSupported: this.props.onlyFreeSpinSupported}, pager : pager };
    }

    onAction(item : any, action : string)  : undefined {
        if(action == "add") {
            this.props.addGame(item);
        } else if(action == "remove") {
            this.props.removeGame(item);
        }
        return undefined;
    }
    render(){
        if(!this.state.listRequest) {
            return <span></span>
        }
        return (
            <Modal  size="xl" show={this.props.showAddGames} onHide={ () => { this.props.toggleShowAddGames(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title ?? "Add Games To Lobby Category"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className={"form"} formListener={this} modelObject={this.state.form} validationMap={this.state.validationMap}>
                        <FormGroup model={"name"} className={"form-group"}>
                            <label>Game Name</label>
                            <FormInput className={"form-control"} model={"name"} type={"text"} validators={[Validators.strLength(2,100)]}/>
                        </FormGroup>
                        {this.props.includeIntegrations && (
                            <FormGroup className={"form-group"}>
                                <label>Integration</label>
                                <SelectInput className={"form-control"} model={"integrations"} values={this.props.includeIntegrations} multiple={true} required={true}></SelectInput>
                            </FormGroup>
                        )}
                        <Submit className={"btn btn-primary"}>Search Game</Submit>
                    </Form>
                    <EntityDataTable service={getContainer().getCasinoGameService()}
                                     listRequest={this.state.listRequest}
                                     columns={this.columns}
                                     onPagerChange={(pager : PagerModel)=>{ this.pagerChanged(pager)}}
                                     actions={this.actions}
                                     onAction={(item: any, action : string)=> this.onAction(item, action)}/>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={()=>this.props.toggleShowAddGames(false)}>Close</button>
                </Modal.Footer>
            </Modal>
        )
    }
    pagerChanged(pager : PagerModel) {
        console.log("pager changed!", pager);
        let listRequest = ListUtils.toListRequest<ListCasinoGameRequest>(this.state.listRequest as any,pager);
        this.setState({listRequest : listRequest});
    }
    onSubmit() {
        if(this.state.form && this.state.pager) {
            let listRequest = ListUtils.toListRequest(this.state.form, this.state.pager)
            this.setState({listRequest : listRequest});
        }
    }
    onSubmitError() {

    }

    formDidUpdate(formModel: ListCasinoGameRequest, validationMap?: any): void {
        if(formModel && validationMap) {
            this.setState({ form : formModel, validationMap : validationMap});
        }
    }
}