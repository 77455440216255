import React, {Fragment} from "react";
import {getContainer} from "../../ioc/IOCSetup";
import {GameIntegration, CasinoStudio, Settings} from "../../http/protocol";
import {ProviderService} from "../../services/ProviderService";
import {LoadingViewState, RouterView} from "../RouterView";
import {RouteComponentProps, withRouter} from "react-router";
import {SettingsService} from "../../services/SettingsService";
import {CasinoStudioService} from "../../services/CasinoStudioService";
import {PaymentService} from "../../services/PaymentService";
import "./ProviderLogos.scss"


interface State extends LoadingViewState{
    settings:Settings;
    casinoStudios:CasinoStudio[];
    paymentProviders:string[]
}

interface Props extends RouteComponentProps{

}

export class ProviderInfoView extends RouterView<Props,State> {
    providerService:ProviderService;
    settingsService: SettingsService;
    casinoStudioService: CasinoStudioService;
    paymentsService: PaymentService;

    constructor(props:Props) {
        super(props);
        this.providerService = getContainer().getProviderService();
        this.settingsService = getContainer().getSettingsService();
        this.casinoStudioService = getContainer().getCasinoStudioService();
        this.paymentsService = getContainer().getPaymentService();
        this.state={settings:{}, casinoStudios: [], paymentProviders:[]};
    }

    loadContent(): Promise<any> | void {
        return Promise.all([
            this.settingsService.getSettings(),
            this.casinoStudioService.list({}),
            this.providerService.listProviders()
        ]).then((res) => {
            const items = res[1].items;
            let enabledStudios:CasinoStudio[] = [];

            items && items.map((studio) => {
                studio != undefined && studio.enabled && enabledStudios.push(studio);
            });
            let paymentProviders = res[2] != undefined ? res[2] : [];
            this.setState({casinoStudios:enabledStudios, settings:res[0], paymentProviders:paymentProviders})
        });
    }

    isStudioIntegrationEnabled(integration:GameIntegration):boolean {
        const studios = this.state.casinoStudios;
        let enabled = false;
        if(this.state.settings.casinoEnabled) {
            studios.forEach((studio) => {
                if(studio.integration == integration) {
                    enabled = true;
                }
            });
        }
        return enabled;
    }

    isPaymentProviderEnabled(provider:string):boolean {
        return this.state.paymentProviders.includes(provider);
    }

    integrationCard(name:string, info:string, url?:string, enabled?:boolean) {
        let nameConcat = name ? name.toLowerCase().replace(" ", "_").replace(" ", "_") : "";
        let logoUrl:string = "";

        if(enabled) {
            return (
                <div className="card mb-3"key={name}>
                    <div className="row no-gutters">
                        <div className="col-md-1" style={{textAlign:"center", marginTop:"auto", marginBottom:"auto"}}>
                            <div className={"provider-logo " + nameConcat + "-logo"} />
                        </div>
                        <div className="col-md-8">
                            <div className="card-body">
                                <h5 className="card-title">{name}</h5>
                                <p className="card-text">{info}</p>
                                {url && url.length > 0 && <a href={url} target="_blank" className="btn btn-outline-primary">Navigate to</a>}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    getTitle(): string {
        return "Integrations";
    }

    renderContent(){
        const settings = this.state.settings;
        const facebookEnabled:boolean = settings && settings.facebookAppId != undefined && settings.facebookAppId.length > 0;
        const gAnalyticsEnabled:boolean = settings && settings.googleAnalyticsId != undefined && settings.googleAnalyticsId.length > 0;
        let cubeiaEnabled:boolean = false;
        if(settings.pokerEnabled || (settings.casinoEnabled && this.isStudioIntegrationEnabled(GameIntegration.CUBEIA))) {cubeiaEnabled = true}

        return (
            <Fragment>
                <div>
                    <div>
                        <h5>Payment </h5>
                        {
                            this.integrationCard("Skrill",
                                "Being at the forefront of digital payments for years and trusted by millions globally, Skrill offers advanced payment solutions to suit all your business needs and requirements",
                                this.providerService.getIntegrationBOUrl("skrill"),
                                this.isPaymentProviderEnabled("skrill")
                            )
                        }
                        {
                            this.integrationCard("Coinpayments",
                                "Integrated payment gateway for cryptocurrencies such as Bitcoin and Litecoin.",
                                this.providerService.getIntegrationBOUrl("coinpayments"),
                                this.isPaymentProviderEnabled("coinpayments")
                            )
                        }
                        {
                            this.integrationCard("Apco",
                                "Apcopay is an all-in-one platform that provides clients with all they need to cater for multi-channel and multi-currency payment options.",
                                this.providerService.getIntegrationBOUrl("apco"),
                                this.isPaymentProviderEnabled("apco")
                            )
                        }
                        {
                            this.integrationCard("Trustly",
                                "Offer streamline payments and payouts to customers. No apps, no registration, no cards! Bank-level security. Multi-currency functionality. Streamlined payment experience. Bank-level security. Multi-currency. Simple integration. Seamless payments",
                                this.providerService.getIntegrationBOUrl("trustly"),
                                this.isPaymentProviderEnabled("trustly")
                            )
                        }
                        {
                            this.integrationCard("Bitgo",
                                "The only institutional-grade, multi-signature, multi-coin transactional wallet.",
                                this.providerService.getIntegrationBOUrl("bitgo"),
                                this.isPaymentProviderEnabled("bitgo")
                            )
                        }
                    </div>
                    <div>
                        <h5>Games</h5>
                        {
                            this.integrationCard("Cubeia",
                                "This is the back office for Cubeia Poker and Casino",
                                this.providerService.getIntegrationBOUrl("cubeia"),
                                cubeiaEnabled
                            )
                        }
                        {
                            this.integrationCard("Song88",
                                "This is the back office for Song88's sportbook",
                                this.providerService.getIntegrationBOUrl("song88"),
                                settings.sportsbookEnabled
                            )
                        }
                        {
                            this.integrationCard("Flowgaming",
                                "This is the back office for Slot aggregator Flowgaming. Slots from the following developers are accessed from here: Playngo, Netent, Microgaming & Maverick",
                                this.providerService.getIntegrationBOUrl("flowgaming"),
                                this.isStudioIntegrationEnabled(GameIntegration.FLOW_GAMING)
                            )
                        }
                        {
                            this.integrationCard("Booongo",
                                "This is the back office for Slot aggregator Booongo. Slots from the following developers are accessed from here: Booongo, Playson",
                                this.providerService.getIntegrationBOUrl("booongo"),
                                this.isStudioIntegrationEnabled(GameIntegration.BOOONGO)
                            )
                        }
                    </div>
                    <div>
                    <h5>Tools</h5>
                        {
                            this.integrationCard("Contentful",
                                "Contentful lets you create, manage and distribute content to your site.",
                                this.providerService.getIntegrationBOUrl("contentful"),
                                true
                            )
                        }
                        {
                            this.integrationCard("Mati",
                                "Mati verifies your users on the fly. It will help you reduce fraud, become compliant in any country of the world and build a powerful user verification process",
                                this.providerService.getIntegrationBOUrl("mati"),
                                settings.matiEnabled
                            )
                        }
                        {
                            this.integrationCard("Freshchat",
                                "Freshdesk is a cloud-based customer support software and helpdesk solution. It is a platform with a comprehensive set of tools and features to help businesses support all the needs of their customers",
                                this.providerService.getIntegrationBOUrl("freshchat"),
                                settings.freshChatEnabled
                            )
                        }
                        {
                            this.integrationCard("Amplitude",
                                "Leverage user behavior tracking to drive user engagement with Amplitude. Product Analytics. Improve User Engagement. Drive Growth & Retention. Understand User Behavior",
                                this.providerService.getIntegrationBOUrl("amplitude"),
                                settings.useAmplitude
                            )
                        }
                        {
                            this.integrationCard("Metabase",
                                "",
                                this.providerService.getIntegrationBOUrl("metabase"),
                                this.providerService.getIntegrationBOUrl("metabase").length > 0
                            )
                        }
                        {
                            this.integrationCard("Facebook",
                                "A secure, quick, and easy way to log users into your app or website.",
                                this.providerService.getIntegrationBOUrl("facebook"),
                                facebookEnabled
                            )
                        }
                        {
                            this.integrationCard("Google Analytics",
                                "Google's free web analytics service that allows you to analyze in-depth detail about the visitors on your website",
                                this.providerService.getIntegrationBOUrl("googleAnalytics"),
                                gAnalyticsEnabled
                            )
                        }
                    </div>
                </div>
            </Fragment>
        )
    }
}



export default withRouter(ProviderInfoView);