import {IntegrationBackOffices} from "../http/Types";


export class ProviderService {
    readonly cubeiaDashboardBaseUrl : string | undefined;
    readonly integrationBackOffices : IntegrationBackOffices | undefined;
    readonly enabledPaymentProviders : string[] | undefined;


    constructor(cubeiaDashboardBaseUrl : string | undefined, integrationBackOffices : IntegrationBackOffices, enabledPaymentProviders: string[]) {
        this.cubeiaDashboardBaseUrl = cubeiaDashboardBaseUrl;
        this.integrationBackOffices = integrationBackOffices;
        this.enabledPaymentProviders = enabledPaymentProviders;

    }

    getCubeiaDashboardUrl(): string | undefined {
        return this.cubeiaDashboardBaseUrl;
    }

    getIntegrationBOUrl(provider:string):string {
        if(this.integrationBackOffices && this.integrationBackOffices[provider]) {
            return this.integrationBackOffices[provider];
        }
        return "";
    }

    listProviders():string[] | undefined {
        return this.enabledPaymentProviders;
    }

}
