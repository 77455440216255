import React, {Fragment} from "react";
import {Form, FormListener} from "../form/Form";
import {
    CasinoGame,
    CasinoGameType,
    CasinoLobbyCategory,
    GameOrder,
    GameTag,
    ListCasinoGameRequest
} from "../../http/protocol";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Submit} from "../form/Submit";
import {getContainer} from "../../ioc/IOCSetup";
import {RouteComponentProps, withRouter} from "react-router";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {LoadingViewState, RouterView} from "../RouterView";
import {Validators} from "../form/Validators";
import {SelectInput} from "../form/SelectInput";
import {CasinoCategoryService} from "../../services/CasinoCategoryService";
import {CasinoGameService} from "../../services/CasinoGameService";
import {AddCasinoGames} from "./AddCasinoGames";
import {changePosition, removeItemFromArray} from "../../util/ArrayUtils";
import {CasinoGameTagService} from "../../services/CasinoGameTagService";
import EditCasinoCategoryComponent from "./EditCasinoCategoryComponent";

interface State extends LoadingViewState {
    category? : CasinoLobbyCategory;
    validationMap? : any;
    gameTypes? : CasinoGameType[];
    showAddGames?: boolean;
    gamesCache? : CasinoGame[];
    tags? : GameTag[];
}

interface Props extends RouteComponentProps<{id?: string}> {

}

export class CasinoCategoryView extends RouterView<Props,State>{



    constructor(props : Props){
        super(props);
        this.state = {loading : true};
    }

    componentDidUpdate(prevProps : RouteComponentProps, prevState : any) {

    }

    onSubmitSuccess(c : CasinoLobbyCategory) {
        if((this.props.match.params as any).id == "new") {
            this.navigate("/casino-category/" + c.id);
        }
    }

    onSubmitError(){}



    getTitle() : string {
        return "Edit/Create Category";
    }


    renderContent() {
        return (

            <Fragment>
                <EditCasinoCategoryComponent onSaveSuccess={(c) => this.onSubmitSuccess(c)} id={this.props.match.params.id}/>
            </Fragment>
        )
    }

    loadContent(): Promise<any> | void {
        return Promise.resolve();
    }

}
export default withRouter(CasinoCategoryView);