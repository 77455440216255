import {HttpService} from "../http/HttpService";
import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {CasinoGame, GameLockedField, ListCasinoGameResponse} from "../http/protocol";


export class CasinoGameService  extends AbstractAdminEntityService<CasinoGame, ListCasinoGameResponse>  {

    constructor(http : HttpService) {
        super("/casino-game", http)
    }

    getLockedFields(gameId: number): Promise<GameLockedField[]> {
        return this.http.get(`${this.entityPath}/${gameId}/locked-fields`);
    }

    setFieldLock(gameId: number, field: GameLockedField, locked: boolean): Promise<GameLockedField[]> {
        return this.http.put(`${this.entityPath}/${gameId}/locked-fields/${field}/${locked}`, {})        
    }


}