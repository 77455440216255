import React, {Fragment} from "react";
import {ActionType} from "../list/list/DataTable";
import {
    CasinoGamesUpdateLog,
    CasinoStudio, GameIntegration, GamesUpdateStatus,
    ListCasinoGamesUpdateLogRequest,
    ListCasinoLobbyCategoryRequest,
    ListChartsRequest,
    ListStudioRequest
} from "../../http/protocol";
import {getContainer} from "../../ioc/IOCSetup";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {PagerModel} from "../list/list/PagerModel";
import {ListUtils} from "../list/list/ListUtils";
import {withRouter} from "react-router";
import {ListView, ListViewProps, ListViewState} from "../ListView";
import {Form, FormListener} from "../form/Form";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Validators} from "../form/Validators";
import {Submit} from "../form/Submit";
import {CasinoStudioService} from "../../services/CasinoStudioService";
import {AdminCasinoGameUpdateLogService} from "../../services/AdminCasinoGameUpdateLogService";
import {SelectInput} from "../form/SelectInput";
import {ColumnType} from "../list/list/ColumnType";
import {formatDate} from "../../util/date";


interface State extends ListViewState<ListCasinoLobbyCategoryRequest> {
    form? : ListCasinoGamesUpdateLogRequest;
    validationMap? : any;
}
interface Props extends ListViewProps  {

}
const toDuration = (item : any) => {
    if (!item.started || item.started == 0 || !item.finished || item.finished == 0) {
        return "N/A";
    }
    try {
        return ((item.finished - item.started) / 1000 / 60).toFixed(1) + " minutes";
    } catch (e) {
        return "N/A"
    }
}
export class CasinoUpdateLogListView extends ListView<State, ListCasinoGamesUpdateLogRequest> implements FormListener<ListCasinoGamesUpdateLogRequest>{

    columns = [
        { property : "id", name : "ID", orderBy : "id"},
        { property : "status", name : "Status"},
        { property : "integration", name : "Integration"},
        { property : "batchId", name : "Batch Id"},
        { property : "started", name : "Started", type : ColumnType.DATE},
        { property : "finished", name : "Finished", type : ColumnType.DATE},
        { property : "duration", name : "Duration", renderer : (prop : string, name : string, item : any) => {
                return <>{toDuration(item)}</>
            }}
    ];

    actions = [
        { id : "view", name : "View", className : "btn btn-primary", iconClassName : "fa fa-user", type : ActionType.LINK, getLink : (item : any) => "/casino-update-log/id/" + item.id }

    ];

    service : AdminCasinoGameUpdateLogService;

    constructor(props : Props){
        super(props, "/casino-update-log/list");
        this.state = {...this.state,  listRequest : {...this.state.listRequest, orderBy : "id", ascending : false}, validationMap : {}, form : {}};
        this.service = getContainer().getAdminCasinoUpdateLogService();
    }
    onSubmit() : void | Promise<any> {
        if(this.state.form) {
            let form = this.state.form;
            form.offset = 0;
            this.updateListState(form);
        }
    }
    onSubmitError() :  void {

    }

    formDidUpdate(formModel: ListStudioRequest, validationMap?: any): void {
        this.setState({form : formModel, validationMap : validationMap});
    }

    onAction(item : CasinoStudio, action : string) : Promise<any> | undefined {
        return;
    }

    pagerChanged(pager : PagerModel) {
        if(this.state.listRequest) {
            console.log("pager changed!", pager);
            let listRequest = ListUtils.toListRequest<any>(this.state.listRequest,pager);
            this.updateListState(listRequest);
        }
    }
    getTitle() {
        return "Update Logs"
    }

    renderContent() {
        if(!this.state.listRequest) {
            return <div/>;
        }
        return (
            <Fragment>
                <div className={"card-box"}>
                    <h4>Search Filters</h4>
                    <Form formListener={this} modelObject={this.state.form} validationMap={this.state.validationMap}>
                        <FormGroup>
                            <label>Status</label>
                            <SelectInput className={"form-control"} optional={true} values={Object.keys(GamesUpdateStatus)} model={"status"}/>
                            <span className={"help-text"}>Search by status, if you're trying to find an update that is stuck search for STARTED</span>
                        </FormGroup>

                        <FormGroup>
                            <label>Integration</label>
                            <SelectInput className={"form-control"} optional={true} values={Object.keys(GameIntegration)} model={"integration"}/>
                            <span className={"help-text"}>Game Integration</span>
                        </FormGroup>
                        <Submit className={"btn btn-primary"}>Search</Submit>
                    </Form>
                </div>
                <EntityDataTable service={this.service}
                                 onPagerChange={this.pagerChanged.bind(this)}
                                 columns={this.columns}
                                 listRequest={this.state.listRequest}
                                 onAction={this.onAction.bind(this)}
                                 actions={this.actions}/>
            </Fragment>
        );
    }



}

export default withRouter(CasinoUpdateLogListView);
