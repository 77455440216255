import * as React from "react";
import {Modal, FormGroup} from "react-bootstrap";

interface Props {
    show?: boolean,
    okLabel: string,
    heading: string|JSX.Element,
    description?: string|JSX.Element,
    okClassName?: string,
    onOk: () => void,
    onCancel?: () => void
}

export class ConfirmModal extends React.Component<Props, {}>{

    constructor(props : Props){
        super(props);
    }

    async onCancel() {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    async doOk() {
        this.props.onOk();
    }

    getActions() {
        let btnClass = this.props.okClassName ? this.props.okClassName : "btn-danger";

        return (
            <FormGroup className="form-group">
                <div>
                    {this.props.onCancel  &&  <a className="btn btn-primary" onClick={this.onCancel.bind(this)}>Cancel</a>}
                    <a className={`btn ${btnClass}`} onClick={this.doOk.bind(this)}>{this.props.okLabel}</a>
                </div>
            </FormGroup>
        );
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.onCancel.bind(this)} aria-labelledby="contained-modal-title">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title">{this.props.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.description}
                </Modal.Body>
                <Modal.Footer>
                    {this.getActions()}
                </Modal.Footer>
            </Modal>
       );
    }
}