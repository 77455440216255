export function extractPathParams(target : any, params : any) {
    console.log("PARAMS = ", target,params);
    if(!params || params == ""){
        return;
    }
    if(params.indexOf("/") == 0) {
        params = params.substr(1);
    }
    if(params.charAt(params.length-1) == "/") {
        params = params.substr(0,params.length-1);
    }

    params = decodeURIComponent(params)

    var split = params.split("/");
    if(split.length%2==0) {
        for(let i = 0; i<split.length; i=i+2) {

            let key : string = split[i];
            let value : string = split[i+1];
            if(value.startsWith("array:")) {
                let array = value.replace(/array:/g,"").split(",");
                target[key] = array;
            } else if(""+parseInt(value)==value){
                target[key] = parseInt(value);
            } else if(value == "true" || value == "false") {
                target[key] = (value == "true" ? true : false);
            } else {
                target[key] = value;
            }
        }

    } else {
        console.warn("Params supplied not evenly divided by 2 (key/value)");
    }

    return target;
}
export function toPathParams(source : any) : string {
    var str = "";
    for(var x in source) {
        if(typeof(source[x]) == "boolean" || typeof(source[x]) == "number" || (typeof(source[x]) !=  "undefined" && source[x] != "" && source[x] != null))  {
            let value = source[x];
            if(typeof(value) == "object" && value.length) {
                value = "array:" + (value as Array<any>).map(v => "" + v).join(",");
            }
            value = encodeURIComponent(value);
            str += "/" + x + "/" + value;
        }
    }
    console.log("Returning path params " + str);
    return str;
}