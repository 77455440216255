import React from "react";
import './UserLabelsComponent.scss';
import {UserService} from "../../services/UserService";
import {getContainer} from "../../ioc/IOCSetup";
import { WithContext as ReactTags } from "react-tag-input";


type Tag = {
    id:string,
    text:string,
}

interface Props {
    userLabels: string[];
    userId:number;
}

interface State {
    tags: Tag[];
    suggestions: Tag[];
}

const KeyCodes = {
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export class UserLabelsComponent extends React.Component<Props, State> {
    userService:UserService;
    constructor(props:Props) {
        super(props);

        this.userService = getContainer().getUserService();
        this.state = {
            tags: [],
            suggestions: []
        };
    }

    componentDidMount() {
        let ul:Tag[] = this.props.userLabels.map((label) => {
            return {id:label, text:label};
        })
        //Get all labels:
        this.userService.getAllLabels().then((e) => {
            let suggestionObject:Tag[] = []
            if(e.length > 0) {
                e.map((label:string) => {
                    suggestionObject.push({id:label, text:label});
                })
            }
            this.setState({suggestions:suggestionObject, tags:ul})
        })

    }

    async handleDelete(i:number) {
        console.log("DELETE!")
        const { tags } = this.state;
        if(this.state.tags.length > 0) {
            let labelToDelete = this.state.tags[i].text;
            await this.userService.deleteUserLabel(this.props.userId, labelToDelete).then(()=> {
                this.setState({
                    tags: tags.filter((tag, index) => index !== i),
                });
            })
        }
    }

    async handleAddition(tag:Tag) {
        await this.userService.addUserLabel(this.props.userId, tag.text).then(() => {
            this.setState(state => ({ tags: [...state.tags, tag] }));

        });
    }

    handleDrag(tag:Tag, currPos:number, newPos:number) {
        const tags = [...this.state.tags];
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        this.setState({ tags: newTags });
    }

    render() {
        const { tags, suggestions } = this.state;
        console.log("TAGS = ", suggestions);
        return (
            <div id="tagMaker">
                <ReactTags tags={tags}
                           suggestions={suggestions}
                           handleDelete={(e:number) => this.handleDelete(e)}
                           handleAddition={(e:Tag) => this.handleAddition(e)}
                           delimiters={delimiters}
                           allowDragDrop={false}
                           minQueryLength={0}
                           autofocus={false}/>
            </div>
        )
    }

}


//Search user attributes cant find labels, because they are currently stored as a text array. either create a new attribute for each label or change how we search attributes.
//Pros for creating a new attribute for every label are: editing labels will be easier and searching for them. cons; attributes list can get messy.
//Pros for keeping them in one attribute: clean attribute list. Cons: Search need to be reworked and editing a string array can be tricky but not impossible.


export default UserLabelsComponent;