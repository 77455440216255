import {HttpService} from "../http/HttpService";
import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {CashbackPayout, CashbackPayoutListResponse} from "../http/protocol";


export class CashbackPayoutService extends AbstractAdminEntityService<CashbackPayout, CashbackPayoutListResponse> {

    constructor(http: HttpService) {
        super("/cashback/payout", http)
    }
}