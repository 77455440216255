import * as React from "react";
import {FormComponentProps} from "./Form";
import {FormUtils} from "./FormUtils";


export interface FormTransferContainerProps extends React.HTMLProps<HTMLElement>, FormComponentProps {
    children? : any;
    model? : string;
    ref? : any;
}
export class FormTransferContainer extends React.Component<FormTransferContainerProps, {}>   {

    render(){
        var children : any;
        if(!this.props.children) {
            return <span/>;
        } else if(!this.props.children.length) {
            children =[this.props.children];
        } else {
            children = this.props.children;
        }
        if(this.props.fieldSet) {
            return <>{FormUtils.renderChildren(children, this.props.fieldSet, this.props.model)}</>
        } else {
            return <></>
        }
    }
}


