import * as React from "react";
import {User} from "../../http/protocol";
import {UserService} from "../../services/UserService";
import {getContainer} from "../../ioc/IOCSetup";
import {NavLink} from "react-router-dom";

export interface UserLinkPanelProps {
    userId: number;
    header: string;
}

interface State {
    user? : User;
    userId?: number;
}

export class UserLinkPanel extends React.Component<UserLinkPanelProps, State>{

    userService : UserService;

    constructor(props : UserLinkPanelProps){
        super(props);
        this.userService = getContainer().getUserService();
        this.state = {  userId: props.userId};
        console.log("UserLinkPanel constructed with props ", props)
    }

    componentDidMount() {
        this.loadUser()
    }

    componentWillUpdate(nextProps : UserLinkPanelProps, nextState : State) {
        nextState.userId = nextProps.userId
    }

    componentDidUpdate(prevProps : UserLinkPanelProps, prevState: State) {
        if (this.state.userId != prevProps.userId) {
            console.log("Load user ")
            this.loadUser()
        }
    }

    loadUser() {
        if (this.state.userId) {
            this.userService.getEntity(""+this.state.userId).then( u => {
                this.setState({user:  u});
            })
        }
    }

    render() {
        if (!this.state.user || !this.state.user.attributes) {
            return <div></div>
        }

        return(
            <div className="card-box">

                <h4>{this.props.header}</h4>
                <div className="feed-element">
                    <div className="media-body ">

                        <strong>{this.state.user.email}</strong><br/>
                        <span className="text-muted">ID: <NavLink to={"/user/"+this.state.user.id}>{this.state.user.id}</NavLink>
                      </span>

                    </div>
                </div>


            </div>
        );
    }

}