import * as React from "react";
import {getContainer} from "../../ioc/IOCSetup";
import {Translation} from "../../http/protocol";
interface Props {
    akey? : string;
    locale?: string;
    onUpdate? : (locale : string) => void;

    translation?: Translation;
}
export class EditTranslation extends React.Component<Props, { text? : string, saving?: boolean, edit?: boolean}>{
    constructor(props : Props) {
        super(props);
        this.state = { text : this.props.translation ? this.props.translation.value : "", saving : false, edit : false}
    }
    edit() {
        this.setState({ edit : true })
    }
    cancel () {
        this.setState( { edit : false })
    }
    async saveTranslation() {
        if (this.state.saving) {
           return;
        }
        this.setState({ saving : true })
        
        const res = await getContainer().getTranslationsService().save({
            id : this.props.translation ? this.props.translation.id : undefined,
            key : this.props.akey,
            locale : this.props.locale,
            value : this.state.text
        })
        
        this.setState({ edit : false, saving : false})

        if (this.props.onUpdate) {
            this.props.onUpdate(res.locale!);
        }
        
    }

    async deleteTranslation() {
        if (this.state.saving) {
            return;
        }
 
        this.setState({ saving : true })

        await getContainer().getTranslationsService().delete(this.props.akey!, this.props.locale!);

        this.setState({ edit : false, saving : false })
        
        if (this.props.onUpdate) {
            this.props.onUpdate(this.props.locale!)
        }

    }


    render() {
        if(this.props.translation && !this.state.edit) {
            return (
                <div className={"row"}>
                    <div className={"col-10"}>
                        {this.props.translation.value}
                    </div>
                    <div className={"col-2"}>
                        <i className={"fa fa-pen"} onClick={()=>this.edit()}></i>
                    </div>
                </div>

            )
        }
        return (
            <div className={"form-group form-inline"}>
                <input type={"text"} disabled={this.state.saving} className={"form-control col-8"} value={this.state.text} onChange={(e)=> this.setState({ text : e.target.value})}/>
                <a className={"btn btn-primary col-1"} onClick={()=>this.saveTranslation()}><i className={"fa fa-save"}></i></a>
                <a className={"btn btn-danger col-1"} onClick={()=>this.deleteTranslation()}><i className={"fa fa-trash"}></i></a>
                {this.props.translation && <a className={"btn btn-secondary col-1"} onClick={()=>this.cancel()}><i className={"fa fa-times"}></i></a>}
            </div>
        )
    }
}