import { combineReducers } from "redux";
import {AdminMfaProvider, AdminUser, ContentfulGlobalModel} from "../http/protocol";
import { fetchAdminSettings } from "../settings/SettingsActions";
import { AdminSettingsStoreState, settingsReducer } from "../settings/SettingsStore";
import { walletReducer, WalletStoreState } from "../wallet/WalletStore";
import {
    CONTENT_GLOBAL,
    HANDLE_FORBIDDEN_REQUEST,
    HANDLE_FORCE_CHANGE_PASSWORD,
    HANDLE_LOGIN,
    HANDLE_LOGIN_ERROR,
    HANDLE_LOGIN_SUCCESS,
    HANDLE_NOT_LOGGED_IN, HANDLE_OTP_INVALID, HANDLE_OTP_REQUIRED,
    HANDLE_PASSWORD_CHANGE_IN_PROGRESS,
    HANDLE_RESET_FORBIDDEN_STATUS,
    INVALID_PASSWORD_CHANGE,
    TOGGLE_MENU
} from "./UserActions";
import { isMobile } from "react-device-detect";

export enum UserStatus {
    LOGGED_OUT,
    IN_PROGRESS,
    LOGGED_IN,
    LOGIN_ERROR
}
export enum UserPasswordStatus {
    NONE,
    FORCE_CHANGE_PASSWORD,
    INVALID_PASSWORD_CHANGE,
    IN_PROGRESS,
    OTP_REQUIRED,
    INVALID_OTP,
    FORCE_CHANGE_PASSWORD_OTP,
    INVALID_PASSWORD_CHANGE_OTP
}
export interface UserStoreState {
    user? : AdminUser;
    status? : UserStatus;
    passwordStatus?: UserPasswordStatus;
    displayRequestForbidden? : boolean;
    activeRole? : string;
    activeOperator? : number;
    permissions? : { [key:string] : boolean };
    expandedMenu?: boolean;
    globalModel? : ContentfulGlobalModel;
    username?: string;
    password?: string;
    mfaProvider?: AdminMfaProvider;
}
export interface ReduxAction<T> {
    type? : string;
    payload: T;
    error?: boolean;
    meta?: any;
    createdTime: number;
}
export interface ApplicationState {
    user: UserStoreState;
    settings: AdminSettingsStoreState;
    wallet: WalletStoreState;
}

export const appReducer = combineReducers<ApplicationState>({
    user: userReducer,
    settings: settingsReducer,
    wallet: walletReducer
});


export function createAction<T>(type : any, payload : T) {
    return {
        type : type,
        payload : payload,
        createdTime : Date.now()
    }
}

export function userReducer(state : UserStoreState = {expandedMenu : !isMobile, status : UserStatus.LOGGED_OUT, passwordStatus : UserPasswordStatus.NONE}, action : ReduxAction<any>) {
    switch (action.type) {
        case CONTENT_GLOBAL:
            return Object.assign({}, state, { globalModel : action.payload})
        case TOGGLE_MENU:
            return Object.assign({}, state, { expandedMenu : action.payload})
        case HANDLE_PASSWORD_CHANGE_IN_PROGRESS:
            return Object.assign({}, state, { status : UserStatus.LOGGED_OUT, passwordStatus : UserPasswordStatus.IN_PROGRESS});
        case HANDLE_FORCE_CHANGE_PASSWORD:
            return Object.assign({}, state, { status : UserStatus.LOGGED_OUT, mfaProvider: action.payload.mfaProvider, passwordStatus : !action.payload.otpRequired ? UserPasswordStatus.FORCE_CHANGE_PASSWORD : UserPasswordStatus.FORCE_CHANGE_PASSWORD_OTP});
        case HANDLE_OTP_REQUIRED:
            return Object.assign({}, state, { status : UserStatus.LOGGED_OUT, mfaProvider: action.payload.mfaProvider, passwordStatus : UserPasswordStatus.OTP_REQUIRED, username : action.payload.username, password : action.payload.password});
        case HANDLE_OTP_INVALID:
            return Object.assign({}, state, { status : UserStatus.LOGGED_OUT, passwordStatus : UserPasswordStatus.INVALID_OTP});
        case INVALID_PASSWORD_CHANGE:
            return Object.assign({}, state, { status : UserStatus.LOGGED_OUT, passwordStatus : !action.payload.otpRequired ? UserPasswordStatus.INVALID_PASSWORD_CHANGE : UserPasswordStatus.INVALID_PASSWORD_CHANGE_OTP});
        case HANDLE_LOGIN:
            return Object.assign({}, state, { status : UserStatus.IN_PROGRESS});
        case HANDLE_LOGIN_ERROR:
            return Object.assign({}, state, {
                status : UserStatus.LOGIN_ERROR
            });
        case HANDLE_NOT_LOGGED_IN:
            return Object.assign({}, state, {
                status : UserStatus.LOGGED_OUT,
                user : null,
                permissions : null
            })
        case HANDLE_FORBIDDEN_REQUEST:
            return Object.assign({}, state, { displayRequestForbidden : true });
        case HANDLE_LOGIN_SUCCESS:
            fetchAdminSettings()

            let usr = {
                status : UserStatus.LOGGED_IN,
                user : action.payload.user,
                permissions : action.payload.permissions,
                passwordStatus : UserPasswordStatus.NONE
            };

            return Object.assign({}, state , usr , { username : undefined, password : undefined });
        case HANDLE_RESET_FORBIDDEN_STATUS:
            return Object.assign({}, state, { displayRequestForbidden : false });

        default:
            return state;

    }
}


