import React, {Fragment} from "react";
import {Form, FormListener} from "../form/Form";
import {
    CommunicationChannel,
    KycStatus,
    MultiFactorAuthStatus,
    PermissionCategory, RegistrationRiskLevel,
    ResetPasswordResponse,
    User,
    UserAttributeDefinition
} from "../../http/protocol";
import {FormGroup} from "../form/FormGroup";
import {FormInput, FormMap} from "../form/FormInput";
import {Submit} from "../form/Submit";
import {getContainer} from "../../ioc/IOCSetup";
import {RouteComponentProps, withRouter} from "react-router";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {LoadingViewState, RouterView} from "../RouterView";
import {Validators} from "../form/Validators";
import {UserService} from "../../services/UserService";
import {SelectInput} from "../form/SelectInput";
import {getStore} from "../../RootReducer";
import {t} from "../../i18n";
import {PermissionRequired} from "../security/PermissionRequired";


interface State extends LoadingViewState {
    user? : User;
    validationMap? : any;
    resetPasswordData? : ResetPasswordResponse;
}

interface Props extends RouteComponentProps {

}


export class EditUserView extends RouterView<Props,State> implements FormListener<User>{

    service : UserService;
    constructor(props : Props){
        super(props);
        this.service = getContainer().getUserService();

        this.state = { user : {}, validationMap : {}, loading : true};
    }

    loadContent() : Promise<any> | undefined {
        var id = (this.props.match.params as any).id;
        return this.service.getEntity(id).then((a : User) => {
            replaceInAttributeKeys(a, ".", "::");
            this.setState({ user : a });
        })

    }
    componentDidUpdate(prevProps : RouteComponentProps, prevState : any) {
        if((this.props.match.params as any).id != (prevProps.match.params as any).id) {
            this.refreshContent();
        } else {
            super.componentDidUpdate(prevProps, prevState);
        }
    }

    onSubmit():Promise<any> {
        if(!this.state.user) {
           return Promise.reject(t(`alertInfo.rejectSaveAttribute`));
        }
        let email = this.state.user.email;
        let phoneNumber = this.state.user.phoneNumber;
        if(!email && email?.trim().length == 0 && !phoneNumber && phoneNumber?.trim().length == 0 ) {
            return Promise.reject(t(`alertInfo.emailPhoneRequired`));
        }

        replaceInAttributeKeys(this.state.user , "::","." )
        return this.service.save(this.state.user).then( (a : User) => {
            replaceInAttributeKeys(a, ".", "::");
            this.setState({ user : a });
        }).catch(err=>{
            throw t(`alertInfo.unableSaveUser`);
        });
    }

    onSubmitError(){}

    formDidUpdate(formModel:UserAttributeDefinition, validationMap : any) {
        this.setState({ user : formModel, validationMap : validationMap});
    }


    getTitle() : string {
        return t(`editUser.pageTitle`);
    }

    addAttribute(key:string,value:string) {
        if(!this.state.user) return;
        key=key.replaceAll(".","::")
        let kvp : any= {};
        kvp[key] = value;
        let attributes = Object.assign({}, this.state.user.attributes, kvp);
        this.setState({ user : Object.assign({}, this.state.user , { attributes : attributes})})
    }
    removeAttribute(key : string) {
        if(!this.state.user) return;
        key=key.replaceAll(".","::")
        let attributes = Object.assign({}, this.state.user.attributes);
        delete attributes[key];
        this.setState({ user : Object.assign({}, this.state.user , { attributes : attributes})})
    }

    hasPermission(category:PermissionCategory, writeAccess:boolean) {
        var permissions = getStore().getState().user.permissions;
        const hasWrite = permissions?.[category as string] === true ?? false
        const hasRead = hasWrite || (permissions?.[category as string] === false ?? false)

        return  writeAccess && hasWrite || !writeAccess && hasRead

    }

    renderMultiFactorAuthentication() {
        if (this.state.user && this.state.user.mfaStatus == MultiFactorAuthStatus.ENABLED) {
            if (this.hasPermission(PermissionCategory.USER_AUTHENTICATION, true)) {
                return (<button className="btn btn-danger" type="button" onClick={this.on2FADisableClick.bind(this)}>{t(`button.disable2Fa`)}</button>)
            } else {
                return (<button className="btn btn-secondary" type="button" disabled>{t(`button.disable2Fa`)}</button>)
            }
        } else {
            return (<button className="btn btn-secondary" type="button" disabled>{t(`button.notSet2Fa`)}</button>)
        }
    }

    renderUserVisibilityButton() {
        if (this.state.user && this.hasPermission(PermissionCategory.USER_VISIBILITY_CONFIG, true)) {
                return (<button className="btn btn-danger" type="button" onClick={this.onUserVisibilityClick.bind(this)}>{this.state.user.visible ? t(`button.makeNotVisible`): t(`button.makeVisible`)}</button>)
        }
    }

    on2FADisableClick() {
        this.service.disableUser2FA(this.state.user && this.state.user.id).then(() => {
            this.refreshContent();
        });
    }

    onUserVisibilityClick() {
        if(this.state.user){
            this.service.setUserVisibilityFlag(this.state.user.id, !this.state.user?.visible).then(() => {
                this.refreshContent();
            });
        }

    }

    isReadOnly(path: string) {
        return this.state.user?.maskedPaths?.includes(path) ?? false
    }

    async resetPassword() {
        this.setState({resetPasswordData: {}})
        const rpd = await this.service.resetPassword(this.state.user!.id!)
        this.setState({resetPasswordData: rpd})
    }

    renderContent() {
        if(!this.state.user) {
            return <span></span>
        }
        return (

            <Fragment>
                    <Form className="form-horizontal" formListener={this} modelObject={this.state.user} validationMap={this.state.validationMap}>
                        <div className={"card"}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <FormSubmitError />
                                        <FormSubmitSuccess text="User Attribute was updated successfully"/>

                                        <FormGroup className="form-group mb-3" model="email">
                                            <label>{t(`label.email`)}</label>
                                            <FormInput className="form-control" model="email" type="text" readOnly={this.isReadOnly("email")}/>
                                        </FormGroup>
                                        <div className="hr-line-dashed"></div>

                                        <FormGroup className="form-group mb-3" model="phoneNumber">
                                            <label>{t(`label.phoneNumber`)}</label>
                                            <FormInput className="form-control" model="phoneNumber" type="text" readOnly={this.isReadOnly("phoneNumber")}/>

                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group mb-3" model="country">
                                            <label>{t(`label.countryCode`)}</label>
                                            <FormInput className="form-control" model="country" type="text" readOnly={this.isReadOnly("country")}/>
                                        </FormGroup>
                                        <div className="hr-line-dashed"></div>

                                        {this.hasPermission(PermissionCategory.USER_VISIBILITY_CONFIG, false ) && <div>
                                            <div className="custom-control custom-checkbox">
                                                <FormInput className="custom-control-input"  id={"visible"} model="visible" type="checkbox" disabled={true} />
                                                <label htmlFor={"visible"} className={"custom-control-label"}>{t(`label.visible`)}</label><br/>
                                                <span className={"help"}>{t(`helpText.userIsVisible`)}</span>
                                            </div>
                                                {this.hasPermission(PermissionCategory.USER_VISIBILITY_CONFIG, true ) && this.renderUserVisibilityButton()}

                                                <div className="hr-line-dashed"></div></div>
                                        }

                                        <div className="custom-control custom-checkbox">
                                            <FormInput className="custom-control-input"  id={"blocked"} model="blocked" type="checkbox" readOnly={this.isReadOnly("blocked")}/>
                                            <label htmlFor={"blocked"} className={"custom-control-label"}>{t(`label.blocked`)}</label>
                                        </div>

                                        <div className="hr-line-dashed"></div>

                                        <div className="custom-control custom-checkbox">
                                            <FormInput className="custom-control-input"  id={"excludeFromAffiliate"} model="excludeFromAffiliate" type="checkbox" readOnly={this.isReadOnly("excludeFromAffiliate")}/>
                                            <label htmlFor={"excludeFromAffiliate"} className={"custom-control-label"}>{t(`label.excludeFromAffiliate`)}</label><br/>
                                            <span className={"help"}>{t(`helpText.excludeFromAffiliate`)}</span>
                                        </div>

                                        <div className="hr-line-dashed"></div>

                                        <div className="hr-line-dashed"></div>

                                        <div className="custom-control custom-checkbox">
                                            <FormInput className="custom-control-input" model="emailVerified" id={"emailVerified"} type="checkbox" readOnly={this.isReadOnly("emailVerified")}/>
                                            <label htmlFor={"emailVerified"} className={"custom-control-label"}>{t(`label.emailVerified`)}</label>
                                        </div>

                                        <div className="hr-line-dashed"></div>

                                        <div className="custom-control custom-checkbox">
                                            <FormInput className="custom-control-input" model="phoneNumberVerified" id={"phoneNumberVerified"} type="checkbox" readOnly={this.isReadOnly("phoneNumberVerified")}/>
                                            <label htmlFor={"phoneNumberVerified"} className={"custom-control-label"}>{t(`label.phoneVerified`)}</label>
                                        </div>

                                        <div className="hr-line-dashed"></div>
                                        <div className="custom-control custom-checkbox">
                                            <FormInput className="custom-control-input" model="termsAccepted" id={"termsAccepted"} type="checkbox" readOnly={this.isReadOnly("termsAccepted")}/>
                                            <label htmlFor={"termsAccepted"} className={"custom-control-label"}>{t(`label.termsAccepted`)}</label>
                                        </div>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group mb-3" model="kycStatus">
                                            <label>{t(`label.KycStatus`)}</label>
                                            <SelectInput values={[KycStatus.NOT_STARTED, KycStatus.PENDING, KycStatus.REVIEW_NEEDED, KycStatus.VERIFIED, KycStatus.REJECTED]} className="form-control" model="kycStatus" type="text" validators={[Validators.required()]} readOnly={this.isReadOnly("kycStatus")}/>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group mb-3" model="registrationRiskLevel">
                                            <label>Registration Risk Level</label>
                                            <SelectInput optional={true} values={Object.values(RegistrationRiskLevel)} className="form-control" model="registrationRiskLevel" type="text" readOnly={this.isReadOnly("registrationRiskLevel")}/>
                                        </FormGroup>


                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group mb-3" model="communicationChannel">
                                            <label>{t(`label.communicationChannel`)}</label>
                                            <SelectInput values={[CommunicationChannel.EMAIL, CommunicationChannel.SMS, CommunicationChannel.NONE]} className="form-control" model="communicationChannel" type="text" readOnly={this.isReadOnly("communicationChannel")}/>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group mb-3" model="kycStatus">
                                            <label>{t(`label.twoFactorAuthentication`)}</label>
                                            <br/>
                                            {this.renderMultiFactorAuthentication()}
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        <PermissionRequired permissions={[PermissionCategory.USER_AUTHENTICATION]} accessLevel="w" showError={false}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card-box">
                                        <h5>{t(`title.resetPassword`)}</h5>

                                        <p>
                                        {t(`helpText.resetPassword`)} 
                                        </p>

                                        { !this.state.resetPasswordData  &&
                                            <button className="btn btn-primary" type="button" onClick={() => this.resetPassword()}>{t(`button.resetPassword`)}</button>
                                        }

                                        { (this.state.resetPasswordData  &&  this.state.resetPasswordData.url)  &&
                                            <div>
                                                <code>{this.state.resetPasswordData.url}</code>&nbsp;
                                                <a className="btn btn-xs btn-primary" onClick={() => navigator.clipboard.writeText(this.state.resetPasswordData!.url!)}>
                                                    <i className="fa fa-clipboard"></i>
                                                </a>

                                                <p className="help">
                                                    {t(`helpText.resetPasswordExpiration`, 
                                                        {expiryDate: new Date(this.state.resetPasswordData.expiry!)})}
                                                </p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </PermissionRequired>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card-box">
                                    <h5>{t(`title.accountAttributes`)}</h5>
                                    <FormMap mapModel="attributes"
                                             removeAttribute={(key? : string)=> key && this.removeAttribute(key)}
                                             addAttribute={(key?, value?)=> key && value && this.addAttribute(key,value)} 
                                             readOnlyKeys={this.state.user.maskedPaths ?? []} />
                                    <div className="hr-line-dashed"></div>
                                </div>
                            </div>
                        </div>


                        <div className={"card"}>
                            <div className={"card-body"}>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="ibox float-e-margins">
                                            <div className="ibox-content">
                                                <div className="ibox-title">
                                                    <h5>{t(`title.actions`)}</h5>
                                                </div>
                                                <div className="form-group">
                                                    <div className="col-sm-10 col-sm-offset-2">
                                                        <Submit className="btn btn-primary waves-effect waves-light">{t(`button.save`)}</Submit>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Form>


            </Fragment>
        )
    }

}

export default withRouter(EditUserView);

/**
 * Replaces all occurrences of a character by another character in a user attribute keys
 * @param a
 * @param oldCharacter
 * @param newCharacter
 */
function replaceInAttributeKeys(a: User, oldCharacter: string, newCharacter: string) {
    let mapped : any = {};
    Object.keys(a.attributes ?? {}).forEach( k => {
        if(k && a.attributes) {
            mapped[k.replaceAll(oldCharacter,newCharacter)] = a.attributes[k];
        }
    })
    a.attributes = mapped;
}
