import * as React from "react";

import {RouteComponentProps, withRouter} from "react-router";
import {LoadingViewState, RouterView} from "../RouterView";
import {
    BonusConfig,
    CasinoStudio,
    Contribution,
    ContributionProduct,
    GameTag,
    TurnoverContributions
} from "../../http/protocol";
import {Form, FormListener} from "../form/Form";
import {getContainer} from "../../ioc/IOCSetup";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {Submit} from "../form/Submit";
import {Validators} from "../form/Validators";
import {TurnoverContributionsService} from "../../services/TurnoverContributionsService";
import {BindModel, FormList, ListItemBinding, ListModelBinding, ModelBinding} from "../form/FormList";
import {SelectInput} from "../form/SelectInput";
import {FormIf} from "../form/Switch";
import {t} from "../../i18n";



interface State extends LoadingViewState {
    tc? : TurnoverContributions;
    validationMap? : any;
}

export class EditTurnoverContributions extends RouterView<RouteComponentProps,State> implements FormListener<TurnoverContributions>{

    service : TurnoverContributionsService;

    constructor(props : RouteComponentProps){
        super(props);
        this.service = getContainer().getTurnoverContributionsService();
        this.state = { tc : {}, validationMap : {} };
    }

    getTitle(): string {
        return "Edit Turnover Contributions";
    }

    componentDidUpdate(prevProps : RouteComponentProps, prevState : State) {
        if((prevProps.match.params as any).id != (this.props.match.params as any).id)  {
            this.refreshContent();
        }
    }

    loadContent(): Promise<any>  {
        if((this.props.match.params as any).id != "new") {
            return this.service.getEntity((this.props.match.params as any).id).then((s : TurnoverContributions) => {
                this.setState({ tc : s });
            });
        }
        return Promise.resolve();

    }

    onSubmit()  {
        if(!this.state.tc) return;
        return this.service.save(this.state.tc).then((s : CasinoStudio)=> {
            this.setState({tc : s});
        })
    }

    formDidUpdate(formModel: GameTag, validationMap?: any) {
        this.setState({tc : formModel, validationMap : validationMap})
    }

    addContribution() {
        let tc = Object.assign({},this.state.tc);
        if(!tc.contributions) {
            tc.contributions = [];
        }
        tc.contributions = tc.contributions.concat([{}]);
        this.setState({ tc : tc});
    }
    addTranslation() {

    }
    renderContent() {
        if(!this.state.tc) {
            return <span></span>
        }

        return (
            <div>


                <div className="wrapper wrapper-content animated fadeInRight">
                    <Form className="form form-horizontal" validationMap={this.state.validationMap} modelObject={this.state.tc} formListener={this}>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className={"alert alert-info"}>
                                    Turnover Contributions decides how much bets and rake contributes to a players bonus turnover requirements.
                                    When you create a new bonus configuration you select the Turnover Contribution you want to apply for that bonus.<br/>
                                    <br/>
                                    If you have a bonus that allows the player to play ANY game/product, you normally want
                                    different contributions depending on the product/game the user is playing.<br/>
                                    <br/>
                                    You can also set up Turnover Contributions that only applies to one product, this would normally be used for a Sportsbook or Slots specific bonus or similar.

                                </div>
                                <div className="card-box">
                                    <div className="ibox-content">
                                        {(this.props.match.params as any).id != "new" && (   <div className="hr-line-dashed"></div> )}

                                        {(this.props.match.params as any).id != "new" && (
                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">ID</label>
                                                <div className="col-sm-10">
                                                    <FormInput  model="id" type="integer"  className="form-control" disabled={true}/>
                                                </div>
                                            </FormGroup>
                                        )}


                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Name</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="name" validators={[Validators.required()]} type="text"  className="form-control" />
                                                <span className={"help"}>Internal name of this turnover contribution</span>
                                            </div>
                                        </FormGroup>

                                    </div>
                                </div>

                                <h4>Contributions</h4>
                                <FormList model={"contributions"}>
                                    <div className="card-box">
                                        <div className="ibox-content">
                                            <div className={"form-group"}>
                                                <BindModel render={( m : ModelBinding<Contribution>)=>{
                                                    return <a className={"btn btn-danger"} style={{ float: "right"}} onClick={()=>m.removeCurrentItem()}>Remove</a>
                                                }}/>
                                            </div>

                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">Product</label>
                                                <div className="col-sm-10">
                                                    <SelectInput  model="product" values={Object.keys(ContributionProduct)} validators={[Validators.required()]}  className="form-control" />
                                                    <span className={"help"}>The product this contribution applies to</span>
                                                </div>
                                            </FormGroup>
                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">Contribution Factor</label>
                                                <div className="col-sm-10">
                                                    <FormInput  model="contributionFactor" validators={[Validators.required()]} type="float"  className="form-control" />
                                                    <span className={"help"}>
                                                        A factor that is multiplied to the bet/spin amount when calculating how much a bet/spin will contribute to the bonus turnover.
                                                    </span>
                                                </div>
                                            </FormGroup>
                                            <div className="hr-line-dashed"></div>
                                            <FormIf model={"product"} value={ContributionProduct.CASINO}>
                                                <FormGroup className="form-group">
                                                    <label className="col-sm-2 control-label">Include Casino Tags</label>
                                                    <div className="col-sm-10">
                                                        <FormInput  model="includeCasinoTags"  type="text"  className="form-control" />
                                                        <span className={"help"}>Comma separated list of tags. Apply only to games with these casino tags</span>
                                                    </div>
                                                </FormGroup>
                                                <div className="hr-line-dashed"></div>
                                                <FormGroup className="form-group">
                                                    <label className="col-sm-2 control-label">Exclude Casino Tags</label>
                                                    <div className="col-sm-10">
                                                        <FormInput  model="excludeCasinoTags"  type="text"  className="form-control" />
                                                        <span className={"help"}>Comma separated list of tags. Exclude games with these casino tags</span>
                                                    </div>
                                                </FormGroup>
                                            </FormIf>

                                            <FormIf model={"product"} values={[ContributionProduct.SPORTSBOOK, ContributionProduct.RACING]}>
                                                <FormGroup className="form-group">
                                                    <label className="col-sm-2 control-label">Min Odds</label>
                                                    <div className="col-sm-10">
                                                        <FormInput  model="minOdds" validators={[Validators.number()]} type="float"  className="form-control" />
                                                        <span className={"help"}>Only include bets with odds greater or equal to this</span>
                                                    </div>
                                                </FormGroup>
                                                <div className="hr-line-dashed"></div>
                                                <FormGroup className="form-group">
                                                    <label className="col-sm-2 control-label">Max Odds</label>
                                                    <div className="col-sm-10">
                                                        <FormInput  model="maxOdds" validators={[Validators.number()]} type="float"  className="form-control" />
                                                        <span className={"help"}>Only include bets with odds less or equal to this</span>
                                                    </div>
                                                </FormGroup>
                                            </FormIf>
                                            <FormIf model={"product"} value={ContributionProduct.SPORTSBOOK}>
                                                <div className="hr-line-dashed"></div>
                                                <FormGroup className="form-group">
                                                    <label className="col-sm-2 control-label">Include Live Betting</label>
                                                    <div className="col-sm-10">
                                                        <FormInput  model="liveBetting" type="checkbox"  />

                                                    </div>
                                                </FormGroup>
                                                <div className="hr-line-dashed"></div>
                                                <FormGroup className="form-group">
                                                    <label className="col-sm-2 control-label">Include Pre-match Betting</label>
                                                    <div className="col-sm-10">
                                                        <FormInput  model="preMatchBetting" type="checkbox" />
                                                    </div>
                                                </FormGroup>
                                            </FormIf>


                                            <div className="hr-line-dashed"></div>
                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">Description</label>
                                                <div className="col-sm-10">
                                                    <FormInput  model="description" validators={[Validators.required()]} type="text"  className="form-control" />
                                                    <span className={"help"}>Short text displayed on the site describing this contribution (ie Sportsbook contributes x10)</span>
                                                </div>
                                            </FormGroup>

                                            <FormList model={"translations"}>
                                                <div className="hr-line-dashed"></div>
                                                <FormGroup className="form-group">
                                                    <label className="col-sm-2 control-label">Language</label>
                                                    <div className="col-sm-10">
                                                        <FormInput  model="language" validators={[Validators.required()]} type="text"  className="form-control" />
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="form-group">
                                                    <label className="col-sm-2 control-label">Description</label>
                                                    <div className="col-sm-10">
                                                        <FormInput  model="description" validators={[Validators.required()]} type="text"  className="form-control" />
                                                        <span className={"help"}>Short text displayed on the site describing this contribution (ie Sportsbook contributes x10)</span>
                                                    </div>
                                                </FormGroup>
                                                <div className={"col-sm-10"}>
                                                    <BindModel render={(b : ModelBinding<BonusConfig>)=>{
                                                        return <a onClick={()=>b.removeCurrentItem()} className={"btn btn-danger"} style={{ float : "right"}}>Remove</a>
                                                    }}/>
                                                </div>
                                            </FormList>
                                            <div className="hr-line-dashed"></div>
                                            <div>
                                                <BindModel model="translations" type="list" render={(b : ModelBinding<BonusConfig>)=>{
                                                    return <a onClick={()=>(b as ListModelBinding<BonusConfig>).addItem({})} className={"btn btn-primary"}>Add Translation</a>
                                                }}/>
                                            </div>

                                        </div>
                                    </div>
                                </FormList>
                                <div className={"form-group"}>
                                    <a className={"btn btn-primary"} onClick={()=>this.addContribution()}>Add Contribution</a>
                                </div>

                            </div>

                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card-box">

                                    <FormSubmitError />
                                    <FormSubmitSuccess text={t(`alertInfo.accountSuccessfully`)}/>
                                    <div className="form-group">
                                        <div className="col-sm-12">
                                            <Submit className="btn  btn-primary">Save</Submit>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }

}

export default withRouter(EditTurnoverContributions);