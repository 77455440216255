import * as React from "react";
import {Form, InputValidation, SimpleFieldSet, FieldSet, ListItemFieldSet} from "./Form";
import * as _ from "lodash";
import {FormList, BindModel, ModelBinding, ListModelBinding, ListItemBinding} from "./FormList";
import {Big} from "big.js";
import {FormTransferContainer} from "./FormTransferContainer";
import {FormIf, FormIfNot} from "./Switch";


/**
 * Renderes children and nested children and passes the supplied form values to them.
 *
 * @param deep - if you want to pass the form values down to nested childrens
 * @param children
 * @param formView
 * @param modelObject
 * @param listener
 * @returns {ReactElement<any>|ReactElement<any>|React.ReactElement<any>[]}
 */


export class FormUtils  {
    static renderChildren(
        elements : React.ReactElement<any>,
        fieldSet : FieldSet | ListItemFieldSet,
        parentModel? : string,
        index? : number) :  React.ReactElement<any>[]  {
        if(!elements) {
            return [];
        }

        return React.Children.map(elements ,  (child : React.ReactElement<any>) : React.ReactElement<any>  => {

            if(typeof child == "undefined" || child == null) {
                return child;
            }
            var props : any = child.props;
            var children = props ? props.children : null;

            var model = (props && props.model) ? props.model : "";
            if(typeof parentModel != "undefined" && parentModel != null && parentModel != "") {
                model = parentModel + "." +  model;
            }

            if(child.type == FormIf){
                let modelValue = fieldSet.getModelValue(model);
                if(props.values) {
                    let matching = (props.values as any[]).some((v : any) => v == modelValue);
                    if(!matching) {
                        return null as any;
                    }
                } else if(modelValue != props.value) {
                    return null as any;
                }
            }
            if(child.type == FormIfNot){
                let modelValue = fieldSet.getModelValue(model);
                if(modelValue == props.value) {
                    return null as any;
                }
            }

            if(this.isBindingType(child.type)) {
                var binding = FormUtils.createBinding(fieldSet, model, props.type, index);
                return React.cloneElement(child, _.extend({}, { binding : binding }, props, children))
            } else if(this.isFieldSet(child.type)) {
                return child;
            } else if(FormUtils.isListType(child.type) || child.type == FormTransferContainer) {
                return React.cloneElement(child,
                    _.extend({}, props, { fieldSet : fieldSet,model : model, modelValue : fieldSet.getModelValue(model), valid : fieldSet.isModelValid(model)}),children);
            } else if(props && !(props)["fieldSet"]  ) {
                var childElements = FormUtils.renderChildren(children, fieldSet, parentModel,index);
                let passDown : any = { fieldSet : fieldSet, model : model, modelValue : fieldSet.getModelValue(model), valid : fieldSet.isModelValid(model)};
                if(typeof child.type == "string") {
                    passDown = {};
                }
                return React.cloneElement(child,
                    _.extend({},props,passDown),
                    children != null ? childElements : null);
            } else if(typeof child.type !== "undefined")  {
                var childElements = FormUtils.renderChildren(children, fieldSet, parentModel, index);
                return React.cloneElement(child, props,
                    children != null ? childElements : null);
            } else {
                return child;
            }

        });
    }
    static createBinding(fieldSet : FieldSet | ListItemFieldSet , model : string, type : string, index? : number) : ModelBinding<any> {
        if(type == "list") {
            return new ListModelBinding<any>(fieldSet, model);
        } else if(type == "list-item" && typeof(index)!= "undefined") {
            return new ListItemBinding(index, fieldSet,model)
        }
        return new ModelBinding<any>(fieldSet, model)
    }

    static isBindingType(type: any) {
        return type == BindModel;
    }
    static isFieldSet(type: any) {
        return type == SimpleFieldSet || type == Form;
    }
    static isListType(type : any) {
        return FormList === type;
    }

    static isFormModelValid(map : { [model : string] : InputValidation}, model : string, includePristine? : boolean) {
        if (includePristine && !( typeof map[model] === "undefined" || map[model].errorName == null )) {
            return false;
        } if(!map[model] || map[model].displayError == false) {
            return true;
        } else {
            return false;
        }
    }
    static toBig(val : string, defaultVal? : number) {
        try {
            return new Big(val);
        } catch(e) {
            return new Big(defaultVal ? defaultVal : 0);
        }
    }
    static getClassName(className? : string, errorClass?: string, valid? : boolean) : string {

        if(!className || className == null) {
            className = "";
        }

        if(valid == false ) {
            var errorClass = errorClass;
            if(!errorClass || errorClass == null) {
               errorClass = "error";
            }
            className = className + " " +  errorClass;
        }
        return className;
    }

    static fromEnum(s : string) : string {
        let arr =  s.split("_");
        arr = arr.map(s => {
            if(s.length >= 2) {
                let final = s.substr(0,1).toUpperCase();
                final += s.substr(1,s.length - 1).toLowerCase();
                return final;
            } else {
                return s;
            }

        });

        return arr.join(" ");
    }
}

