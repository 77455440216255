import React, { useState } from "react";
import { Menu, MenuItem, MenuItemType } from "../../http/protocol";
import { useDragAndDrop } from "./utils";

interface Props {
    menuList: MenuItem[];
    setMenuList: (menuOrder: MenuItem[]) => void;
    settMenuItemIndex: (index: number) => void;
    addMenuItem: () => void;
}

export default function EditMenuOrder({ menuList, setMenuList, settMenuItemIndex, addMenuItem }: Props) {
    const { onDragEnd, onDragOver, onDragStart, menu } = useDragAndDrop<MenuItem>(menuList, setMenuList);

    function editMenuItem(idx: any) {
        settMenuItemIndex(idx);
    }

    function menuItemName(item: MenuItem) {
        if (item.type == MenuItemType.CASINO) {
            return item.name ? item.name : item.type;
        } else if (item.type == MenuItemType.CUSTOM) {
            return item.name ? item.name : item.type;
        } else {
            return item.type;
        }
    }

    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <h4 className=" p-2 w-full d-flex justify-content-between">Menu Order</h4>
                    <div className="col-lg-12">
                        <div className="drag-n-drop-container menu">
                            <main>
                                <ul>
                                    {menu.map((item, idx) => (
                                        <li key={item.type != MenuItemType.CUSTOM ? item.name + idx.toString() : item.type + idx.toString()} onDragOver={(e) => onDragOver(e, idx)}>
                                            <div className="drag" draggable onDragStart={(e) => onDragStart(e, idx)} onDragEnd={onDragEnd}>
                                                <i className="fe-menu" />
                                            </div>
                                            <span className="content">
                                                <span className={"content-container"}>
                                                    <span className={"item-content"}>
                                                        {menuItemName(item)}
                                                        <a onClick={() => editMenuItem(idx)}>
                                                            <i className={"fe-edit"}></i>
                                                        </a>
                                                        {(item.includeCountries || item.excludeCountries || item.subMenu) && (
                                                            <span className={"countries"}>
                                                                {item.excludeCountries && <span className={"danger"}>{item.excludeCountries}</span>}
                                                                {item.includeCountries && <span className={"success"}>{item.includeCountries}</span>}
                                                                {item.subMenu && <span className={"info"}>{item.subMenu.tabs?.length} tabs</span>}
                                                            </span>
                                                        )}
                                                    </span>
                                                </span>
                                            </span>
                                        </li>
                                    ))}
                                    <li className="px-0 new-item-li">
                                        <button onClick={() => addMenuItem()} style={{ gap: "10px" }} className={"btn btn-primary d-flex w-100 align-items-center new-item-btn"}>
                                            NEW <i className={"fe-plus-circle"}></i>
                                        </button>
                                    </li>
                                </ul>
                            </main>
                        </div>
                    </div>
                    <span className={"help-block p-2"}>If you want to reorder the menu, drag and drop menu item</span>
                </div>
            </div>
        </div>
    );
}
