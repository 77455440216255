import { HttpService } from "../http/HttpService";
import {
    CreateVoucherRequest,
    ListVouchersResponse,
    Voucher
} from "../http/protocol";
import { AbstractAdminEntityService } from "./AbstractAdminEntityService";


export class VoucherService extends AbstractAdminEntityService<Voucher, ListVouchersResponse> {
    constructor(http : HttpService) {
        super("/voucher", http)
    }


    createVoucher(req: CreateVoucherRequest): Promise<Voucher> {
        return this.http.post(this.entityPath + "/create", req);
    }

    getVoucherById(id: number|string): Promise<Voucher> {
        return this.http.get(`${this.entityPath}/id/${id}`);
    }

    setUserId(voucherId: number|string, userId: number|string): Promise<Voucher> {
        return this.http.post(`${this.entityPath}/${voucherId}/set-user/${userId}`, {});
    }

    revokeVoucher(id: number|string): Promise<Voucher> {
        return this.http.delete(`${this.entityPath}/id/${id}`)
    }

    openVoucher(id: string): Promise<Voucher> {
        return this.http.put(`${this.entityPath}/${id}/open`, {});
    }

}