import * as React from "react";
import {Fragment, useEffect, useState} from "react";

import {LoadingSpinner} from "../util/LoadingSpinner";
import {RouteComponentProps} from "react-router";
import {toPathParams} from "../util/UrlUtils";
import {usePrevious} from "./widget/hook-utils";
import {useHistory} from "react-router-dom";


export interface LoadingViewState {
    loading? : boolean;
    loadingFailed? : boolean;
    initialized? : boolean;
    mounted? : boolean;
}
export abstract class RouterView<P extends RouteComponentProps,S extends LoadingViewState> extends React.Component<P, S> {

    constructor(props : P){
        super(props);
    }

    componentDidMount() {
        this.refreshContent();
    }
    refreshContent() {
        this.setState({ loading : true, mounted : true} as any);
        let promise = this.loadContent() as Promise<any> ;
        if(promise) {
            promise.then(()=>{
                setTimeout(()=>this.setState({loading : false, initialized : true} as any),0);
            }).catch( err => {
                setTimeout(()=>  this.setState({loading : false, loadingFailed : true} as any));
            });
        } else {
            setTimeout(()=>this.setState({loading : false} as any),0);
        }
    }
    abstract loadContent() : Promise<any> | void;

    abstract getTitle() : string;

    componentDidUpdate(prevProps : P, prevState : S) {

    }
    componentWillUnmount() {

    }

    navigate ( path : string) {
        this.props.history.push(path);
    }

    setLoading(loading : boolean){
        this.setState({loading : loading, loadingFailed : false});

    }
    setErrorLoading() {
        this.setState({loading : false, loadingFailed : true});
    }

    load<L>(p : Promise<L>) : Promise<L> {
        this.setLoading(true);
        var promise = new Promise<L>((resolve,reject)=>{
            p.then((res : L)=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
                console.log("ERROR LOADING", err);
                this.setErrorLoading();
            });
        });
        return promise;
    }

    toPathParams(target : any) : string {
      return toPathParams(target);
    }
    updatePathParams(base : string, target : any) {
        let params = this.toPathParams(target);
        this.navigate(base + params);
    }
    abstract renderContent() : JSX.Element;

    isShowContentWhenOnRefresh() : boolean{
        return false;
    }

    render() {
        let showLoading = this.state.loading;
        if(this.isShowContentWhenOnRefresh() == true && this.state.initialized == true) {
            showLoading = false;
        }
        if(!this.state.mounted) {
            showLoading = true;
        }
        if(this.state.loadingFailed == true) {
               return (
                   <div className="wrapper wrapper-content animated">
                       <div className="middle-box text-center fadeInDown">
                           <h1>500</h1>
                           <h2 className="font-bold danger">Oops! Something went wrong...</h2>

                           <div className="error-desc">
                                Try reloading, if the error persists please contact support
                           </div>
                       </div>
                   </div>
               );
        } else if(showLoading == true) {
            return (
                <div className="wrapper wrapper-content animated">
                    <div className="row">
                        <div className="col-lg-12 loading-content">
                            <LoadingSpinner />
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
               <Fragment>
                   <div className="row">
                       <div className="col-12">
                           <div className="page-title-box">
                               <div className="page-title-right">

                               </div>
                               <h4 className="page-title">{this.getTitle()}</h4>
                           </div>
                       </div>
                   </div>
                   {this.renderContent()}
               </Fragment>
            )

        }
    }

}

export const CardBox = ( props : { title? : string, children : any }) => {
    return (
        <div className={"card"}>
            <div className={"card-body"}>
                <div className="ibox float-e-margins">
                    <div className="ibox-content">
                        {props.title && (
                            <div className="ibox-title">
                                <h5 className={"header-title"}>{props.title}</h5>
                            </div>
                        )}
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export enum LoadingStatus {  LOADING, DONE, ERROR}


export  function  useLoadingState<E>(fn : () => Promise<E>, deps : any[]) {
    const [status, setStatus] = useState(LoadingStatus.LOADING);
    useEffect(()=>{
        let cancel = false;
        fn().then(() => {
            if(cancel) return;
            setStatus(LoadingStatus.DONE);
        }).catch((e)=>{
            if(cancel) return;
            setStatus(LoadingStatus.ERROR);
        })
        return () => {
            cancel = true;
        }
    // eslint-disable-next-line
    },deps);
    return status;
}

export const RouterViewTemplate = ( props : { children : any, status : LoadingStatus[] | LoadingStatus, title? : string, path? :string}) => {
    const hasStatus = (status : LoadingStatus | LoadingStatus[], compareWith : LoadingStatus) => {
        if(status instanceof Array) {
            return status.some((s) => s === compareWith);
        } else {
            return status === compareWith;
        }
    }
    const history = useHistory();
    const prevPath = usePrevious(props.path);
    useEffect(()=>{
        if(prevPath != props.path && props.path) {
            history.push(props.path);
        }
    },[props.path, history, prevPath])
    if(hasStatus(props.status, LoadingStatus.LOADING)) {
        return <RouterViewLoading />
    }
    if(hasStatus(props.status,LoadingStatus.ERROR)) {
        return (
            <div className="wrapper wrapper-content animated">
                <div className="middle-box text-center fadeInDown">
                    <h1>500</h1>
                    <h2 className="font-bold danger">Oops! Something went wrong...</h2>

                    <div className="error-desc">
                        Try reloading, if the error persists please contact support
                    </div>
                </div>
            </div>
        )
    }
    return (
        <Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box">
                        <div className="page-title-right">

                        </div>
                        <h4 className="page-title">{props.title ?? ""}</h4>
                    </div>
                </div>
            </div>
            {props.children}
        </Fragment>
    )
}
export const RouterViewLoading = () =>{
    return (
        <div className="wrapper wrapper-content animated">
            <div className="row">
                <div className="col-lg-12 loading-content">
                    <LoadingSpinner />
                </div>
            </div>
        </div>
    )
}