import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {HttpService} from "../http/HttpService";
import {InternalSettings, ListResponse} from "../http/protocol";

export class InternalSettingsService extends AbstractAdminEntityService<InternalSettings,ListResponse> implements InternalSettingsService{
    constructor(http: HttpService) {
        super("/settings", http);
    }

    getSettings(): Promise<InternalSettings> {
        return this.http.get(`${this.entityPath}/internal`);
    }


    saveSettings(settings:InternalSettings):Promise<any> {
        return this.http.put<InternalSettings,InternalSettings>(`${this.entityPath}/internal`, settings);
    }
}