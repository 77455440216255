/* eslint-disable prefer-const */
import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';



interface PaginateProps {
    nrOfResults?: number;
    paginate:any;
    setPaginate?:  any;

}

const Pagination: React.FC<PaginateProps> = (props) => {
   
    function handlePageClick(e:any) {
        props.setPaginate({...props.paginate, offset: e.selected * props.paginate.limit, currentIndex: e.selected + 1 });
    }

    let pageCount = 0;
    if (props.nrOfResults !== undefined) {
        pageCount = Math.ceil(props.nrOfResults / props.paginate.limit);
    }

    const paginateStatus = () => {
        let status: Record<string, number> = {};
        try {
            if (props.nrOfResults == undefined) {
                status.to = 0;
                status.from = 0;
                status.total = 0;
                return status;
            }
            status.from = props.paginate.limit * props.paginate.currentIndex - props.paginate.limit;
            if (props.paginate.limit * props.paginate.currentIndex >= props.nrOfResults) {
                status.to = props.nrOfResults;
            } else {
                status.to = props.paginate.limit * props.paginate.currentIndex;
            }
            status.total = props.nrOfResults;
            return status;
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div style={{ boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 3px' }} className="card mt-5">
            <div className="d-flex justify-content-center py-1">
                <div style={{ minHeight: '35px' }} className="paginate-custom-container">
                    { props.nrOfResults !== 0 && (
                        <>
                          

                            <ReactPaginate
                                pageCount={pageCount}
                                onPageChange={handlePageClick}
                                breakClassName={'page-item'}
                                breakLinkClassName={'page-link'}
                                containerClassName={'pagination'}
                                pageClassName={'page-item'}
                                pageLinkClassName={'page-link'}
                                previousClassName={'page-item'}
                                previousLinkClassName={'page-link'}
                                nextClassName={'page-item'}
                                nextLinkClassName={'page-link'}
                                activeClassName={'active'}
                                previousLabel={
                                    props.nrOfResults ? (
                                    
                                           "Prev" 
                                      
                                    ) : null
                                }
                                nextLabel={
                                    props.nrOfResults ? (
                                       
                                            "Next"
                                   
                                    ) : null
                                }
                            />
                              <div className="d-flex justify-content-center">
                                <p >Showing {paginateStatus()?.from ? paginateStatus()?.from : paginateStatus()?.total} to {paginateStatus()?.to ? paginateStatus()?.to:paginateStatus()?.total} of {paginateStatus()?.total}</p>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Pagination;
