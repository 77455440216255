import React, {Fragment} from "react";
import {ActionType} from "../list/list/DataTable";
import {ExcludedFromAffiliate, KycStatus, ListUsersRequest, User} from "../../http/protocol";
import {getContainer} from "../../ioc/IOCSetup";
import {ColumnDefinition, EntityDataTable} from "../list/list/EntityDataTable";
import {PagerModel} from "../list/list/PagerModel";
import {ListUtils} from "../list/list/ListUtils";
import {withRouter} from "react-router";
import {createInitialState, ListView, ListViewProps, ListViewState} from "../ListView";
import {UserService} from "../../services/UserService";
import {Form, FormListener} from "../form/Form";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Validators} from "../form/Validators";
import {Submit} from "../form/Submit";
import {SelectInput} from "../form/SelectInput";
import {ColumnType} from "../list/list/ColumnType";
import {t} from "../../i18n";
import {AdminConfig} from "../../http/Types";
import {useHistory} from "react-router-dom";


interface State extends ListViewState<ListUsersRequest> {
    listRequest? : ListUsersRequest;
    form? : ListUsersRequest;
    validationMap? : any;
    lookupExpanded?: boolean;
    blockedUsers: boolean;
    labels?: string[];
    columns: ColumnDefinition[];
    findByIpExpanded?: boolean;
}

export enum UserColumnKey {
    ID = "ID",
    UID = "UID",
    CREATED = "CREATED",
    EMAIL = "EMAIL",
    PHONE = "PHONE",
    USERNAME = "USERNAME",
    FIRST_NAME = "FIRST_NAME",
    SCREEN_NAME = "SCREEN_NAME",
    LAST_NAME = "LAST_NAME",
    BLOCKED = "BLOCKED",
    KYC_STATUS = "KYC_STATUS",
    DOB = "DOB",
    AFFILIATE_ID = "AFFILIATE_ID",
    AFFILIATE_NAME = "AFFILIATE_NAME",
    GENDER = "GENDER",
    ADDRESS_1 = "ADDRESS_1",
    ADDRESS_2 = "ADDRESS_2",
    CITY = "CITY",
    ZIP_CODE = "ZIP_CODE",
    COUNTRY = "COUNTRY",
}


const DEFAULT_COLS: UserColumnKey[] = [UserColumnKey.ID, UserColumnKey.UID, UserColumnKey.CREATED, UserColumnKey.EMAIL, UserColumnKey.PHONE, 
    UserColumnKey.USERNAME, UserColumnKey.BLOCKED, UserColumnKey.KYC_STATUS]

export class UserListView extends ListView<State, ListUsersRequest> implements FormListener<ListUsersRequest>{

    columnMappings = {
        [UserColumnKey.ID]:             { property: "id", name: "ID", type: ColumnType.LINK, getUrl: (item : any) => `/user/${item.id}`, linkClass: "list-link", icon: true},
        [UserColumnKey.UID]:            { property: "uid", name: "UID"},
        [UserColumnKey.CREATED]:        { property: "created", name: "Created", type : ColumnType.DATE},
        [UserColumnKey.EMAIL]:          { property: "email", name: "email"},
        [UserColumnKey.PHONE]:          { property: "phoneNumber", name: "Phone Number"},
        [UserColumnKey.USERNAME]:       { property: "username", name: "Username"},
        [UserColumnKey.FIRST_NAME]:     { property: "attributes.firstName", name: "First Name"},
        [UserColumnKey.LAST_NAME]:      { property: "attributes.lastName", name: "Last Name"},
        [UserColumnKey.SCREEN_NAME]:    { property: "attributes.screenName", name: "Screen Name"},
        [UserColumnKey.BLOCKED]:        { property: "blocked", name: "Blocked", type: ColumnType.YES_OR_NO, invertColors: true},
        [UserColumnKey.KYC_STATUS]:     { property: "kycStatus", name: "KYC Status", type: ColumnType.LINK, getExternalUrl: (item : any) => item.attributes['MATI_KYC_link']},
        [UserColumnKey.DOB]:            { property: "attributes.dateOfBirth", name: "Date of Birth", type: ColumnType.TEXT},
        [UserColumnKey.GENDER]:         { property: "attributes.gender", name: "Gender", type: ColumnType.TEXT},
        [UserColumnKey.AFFILIATE_ID]:   { property: "affiliateId", name: "Affiliate Id", type: ColumnType.TEXT},
        [UserColumnKey.AFFILIATE_NAME]: { property: "affiliateName", name: "Affiliate Name", type: ColumnType.TEXT},
        [UserColumnKey.ADDRESS_1]:      { property: "attributes.addressLine1", name: "Address 1", type: ColumnType.TEXT},
        [UserColumnKey.ADDRESS_2]:      { property: "attributes.addressLine2", name: "Address 1", type: ColumnType.TEXT},
        [UserColumnKey.CITY]:           { property: "attributes.city", name: "City", type: ColumnType.TEXT},
        [UserColumnKey.ZIP_CODE]:       { property: "attributes.zipCode", name: "Zip Code", type: ColumnType.TEXT},
        [UserColumnKey.COUNTRY]:        { property: "attributes.country", name: "Country", type: ColumnType.TEXT},
    }

    actions = [
        { id : "view", name : t(`button.view`), className : "btn btn-primary", iconClassName : "fa fa-user", type : ActionType.LINK, getLink : (item : any) => "/user/" + item.id },
        { id : "block", name : t(`button.block`), type: ActionType.BLOCK, isBlocked : (item : any) => item.blocked},

    ];
    service : UserService;
    settings : AdminConfig;

    constructor(props : ListViewProps){
        super(props, "/users");
        this.service = getContainer().getUserService();
        this.settings = getContainer().getAdminConfig();

        let locProp = this.props.location as any;
        if (this.state.listRequest){
            let kycStatus:[] = [];
            let locPropState = locProp.state as any;
            if(locPropState && locPropState.listRequest) {
                kycStatus = locPropState.listRequest.kycStatus && locPropState.listRequest.kycStatus;
            }
            let lookupExpanded:boolean = false;
            if(locPropState && locPropState.lookupExpanded != undefined) {
                lookupExpanded = locPropState.lookupExpanded;
            }
            //decode searchString:
            let lr = this.state.listRequest;
            lr.searchString = decodeURIComponent(lr.searchString || "");

            this.state = createInitialState(
                {
                    limit: 20,
                    orderBy: "id",
                    kycStatus: kycStatus,
                    startDate: null,
                    endDate: null,
                    ascending: false,
                    blockedUsers: false
                },
                {...this.state, lookupExpanded: lookupExpanded, listRequest: lr, columns: []}
            )
        }
    }

    async componentDidMount() {
        super.componentDidMount();
        this.service.getAllLabels().then(value => {
            this.setState({labels:value})
        })

        const labels = await this.service.getAllLabels()
        const userColKeys = this.settings.userListColumns ?? DEFAULT_COLS
        const columns = userColKeys.map(uc => this.columnMappings[uc]).filter(c => c != undefined)

        this.setState({labels: labels, columns: columns})
    }

    onSubmit(): void {
        let listRequest : ListUsersRequest = Object.assign({},this.state.form);

        listRequest.offset = 0;
        const idOrUid = (this.state.form as any).userIdOrUid;
        // delete (listRequest as any).userIdOrUid

        this.updateListState(listRequest);
    };




    onSubmitError() :  void {
        console.log("submit error!")
    }

    formDidUpdate(formModel: ListUsersRequest, validationMap?: any): void {
        this.setState({form : formModel, validationMap : validationMap});
    }

    async onAction(item : User, action: string) : Promise<Promise<any> | undefined> {
        if (action === "block") {
            if (item.blocked) {
                await this.service.unblockUser(item.id!).then(value => {
                    this.refreshContent();
                })
            } else {
                await this.service.blockUser(item.id!).then(value => {
                    this.refreshContent();
                })
            }

        }
    }

    pagerChanged(pager : PagerModel) {
        if(this.state.listRequest) {
            let listRequest = ListUtils.toListRequest<any>(this.state.listRequest,pager);
            this.updateListState(listRequest);
        }
    }
    getTitle() {
        return t(`usersManagement.listUsers.pageTitle`)
    }

    preset(preset: "day" | "week" | "month") {

        let filter: ListUsersRequest|undefined = this.state.listRequest;
        let today = new Date(new Date().setDate(new Date().getDate())).toISOString().slice(0,10);
        let yesterday = new Date(new Date().setDate(new Date().getDate()-1)).toISOString().slice(0,10);
        let sevenDaysAgo = new Date(new Date().setDate(new Date().getDate()-7)).toISOString().slice(0,10);
        let thirtyDaysAgo = new Date(new Date().setDate(new Date().getDate()-30)).toISOString().slice(0,10);

        switch(preset) {
            case "day":
                filter = {...this.state.form, startDate: yesterday as any, endDate: today as any, searchString: undefined};
                break;

            case "week":
                filter = {...this.state.form, startDate: sevenDaysAgo as any, endDate: today as any, searchString: undefined};
                break;

            case "month":
                filter = {...this.state.form, startDate: thirtyDaysAgo as any, endDate: today as any, searchString: undefined};
                break;
        }


        // this.updateListState({...filter});
        this.setState({form:filter})
    }

    getSearchByUserId() {
        return(
            <div className="row">
                <div className={"col-lg-4"}>
                    <div className="card-box">
                        <h4>{t(`title.findById`)}</h4>
                        <Form formListener={this} modelObject={this.state.form}
                              validationMap={this.state.validationMap}>
                            <FormGroup className={"form-group"} model="userIdOrUid">
                                <FormInput model={"userIdOrUid"}
                                           className={"form-control"} /*validators={[Validators.integer()]}*//>
                                <span className={"help-text"}>{t(`helpText.findById`)}</span>
                            </FormGroup>
                            <Submit className={"btn btn-primary"}>{t(`button.findUser`)}</Submit>
                        </Form>
                    </div>

                </div>
                <div className="col-lg-4">
                    <div className="card-box">
                        <h4>{t(`title.searchEmailOrPhone`)}</h4>
                        <Form formListener={this} modelObject={this.state.form}
                              validationMap={this.state.validationMap}>
                            <FormGroup className={"form-group"}>
                                <FormInput model={"emailOrPhone"} className={"form-control"}
                                           validators={[Validators.strLength(3, 255)]}
                                           onFocus={() => this.clearForEmailOrPhoneText()}
                                           />
                                <span className={"help-text"}>{t(`helpText.searchEmailOrPhone`)}</span>
                            </FormGroup>
                            <Submit className={"btn btn-primary"}>{t(`button.search`)}</Submit>
                        </Form>
                    </div>

                </div>
                <div className="col-lg-4">
                    <div className="card-box">
                        <h4>{t(`title.search`)}</h4>
                        <Form formListener={this} modelObject={this.state.form}
                              validationMap={this.state.validationMap}>
                            <FormGroup className={"form-group"}>
                                <FormInput model={"searchString"} className={"form-control"}
                                           validators={[Validators.strLength(3, 255)]}
                                           onFocus={() => this.clearForText()}/>
                                <span className={"help-text"}>{t(`helpText.search`)}</span>
                            </FormGroup>
                            <Submit className={"btn btn-primary"}>{t(`button.search`)}</Submit>
                        </Form>
                    </div>

                </div>
            </div>
        )
    }

    getSearchByIp() {

        return (
            <div className="card-box expandable-box"
                 style={!this.state.findByIpExpanded ? {cursor: "pointer"} : {cursor: "default"}}
                 onClick={() => !this.state.findByIpExpanded ? this.setState({findByIpExpanded: true}) : null}>
                {this.state.findByIpExpanded && (
                    <a className="expand-button float-right" onClick={() => this.setState({findByIpExpanded: false})}><i
                        className="mdi mdi-minus"></i></a>
                )}
                {!this.state.findByIpExpanded && (
                    <a className="expand-button float-right" onClick={() => this.setState({findByIpExpanded: true})}><i
                        className="mdi mdi-plus"></i></a>
                )}
                <h4 className="header-title mb-3" onClick={() => this.setState({findByIpExpanded : !this.state.findByIpExpanded})}>{t(`title.findByIp`)}</h4>
                {this.state.findByIpExpanded && (
                    <div className="row">
                        <div className={"col-lg-4"} style={{paddingTop : "20px"}}>
                            <Form formListener={this} modelObject={this.state.form} validationMap={this.state.validationMap}>
                                <FormGroup className={"form-group"} model="userIp">
                                    <FormInput model={"userIp"} className={"form-control"}/>
                                    <span className={"help-text"}>{t(`helpText.findByIp`)}</span>
                                </FormGroup>
                                <FormGroup className={"form-group"}>
                                    <label htmlFor={"ipSearchStartDate"}>Active From:</label>
                                    <FormInput model={"ipSearchStartDate"} className={"form-control"} type={"date"}/>
                                    <label htmlFor={"ipSearchEndDate"}>Active to:</label>
                                    <FormInput model={"ipSearchEndDate"} className={"form-control"} type={"date"}/>
                                    <span className={"help-text"}>{t(`helpText.dateInput`)}. Default values: from 30 days ago until now</span>
                                </FormGroup>
                                <Submit className={"btn btn-primary"}>{t(`button.findUser`)}</Submit>
                            </Form>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    getSearchByFilter() {

        return(
            <div className="card-box expandable-box" style={!this.state.lookupExpanded ? {cursor:"pointer"} : {cursor:"default"}} onClick={() => !this.state.lookupExpanded ? this.setState({lookupExpanded:true}) : null}>
                {this.state.lookupExpanded && (
                    <a className="expand-button float-right" onClick={()=>this.setState({lookupExpanded : false})}><i className="mdi mdi-minus"></i></a>
                )}
                {!this.state.lookupExpanded && (
                    <a className="expand-button float-right" onClick={()=>this.setState({lookupExpanded : true})}><i className="mdi mdi-plus"></i></a>
                )}
                <h4 className="header-title mb-3" onClick={()=>this.setState({lookupExpanded : !this.state.lookupExpanded})}>{t(`title.advanceSearch`)}</h4>
                {this.state.lookupExpanded && (
                    <div>
                        <div className="row">
                        <Form formListener={this} modelObject={this.state.form} validationMap={this.state.validationMap}>
                            <div className="row" style={{ padding:"10px 22px"}}>
                                <div className="col-lg-3">
                                    <FormGroup className="form-group">
                                        <label>{t(`label.userCreateDate`)}</label>
                                        <h5>{t(`title.presets`)}</h5>
                                        <div>
                                            <span className="btn btn-sm btn-primary" style={{marginRight:'10px'}} onClick={() => this.preset("day")}>{t(`button.day`)}</span>
                                            <span className="btn btn-sm btn-primary" style={{marginRight:'10px'}} onClick={() => this.preset("week")}>{t(`button.week`)}</span>
                                            <span className="btn btn-sm btn-primary" style={{marginRight:'10px'}} onClick={() => this.preset("month")}>{t(`button.month`)}</span>
                                        </div>
                                    </FormGroup>
                                    <FormGroup className={"form-group"}>
                                        <label htmlFor={"startDate"}>{t(`label.fromDate`)}:</label>
                                        <FormInput model={"startDate"} className={"form-control"} type={"date"} onFocus={() => this.clearForAdvanced()} required={true}/>
                                        <label htmlFor={"endDate"}>{t(`label.toDate`)}:</label>
                                        <FormInput model={"endDate"} className={"form-control"} type={"date"} onFocus={() => this.clearForAdvanced()} required={true}/>
                                        <span className={"help-text"}>{t(`helpText.dateInput`)}</span>
                                    </FormGroup>
                                </div>

                                <div className="col-lg-3">
                                    <FormGroup className="form-group">
                                        <label>{t(`label.kycStatus`)}</label>
                                        <SelectInput className="form-control" model="kycStatus" size={[...Object.keys(KycStatus)].length} multiple={true}
                                                     values={[...Object.keys(KycStatus)]}
                                                     optional={true}
                                                     onFocus={() => this.clearForAdvanced()}>
                                        </SelectInput>
                                        <span className={"help-text"}>{t(`helpText.kycStatus`)}</span>
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3">
                                    <FormGroup className="form-group">
                                        <label>{t(`label.tags`)}</label>
                                        <SelectInput className="form-control" model="labels"
                                                     size={5} multiple={true}
                                                     values={this.state.labels}
                                                     optional={true}
                                                     onFocus={() => this.clearForAdvanced()}>
                                        </SelectInput>
                                        <span className={"help-text"}>{t(`helpText.findByTag`)}</span>
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3">
                                    <FormGroup className="form-group">
                                        <label>Excluded From Affiliate</label>
                                        <SelectInput  defaultValue={ExcludedFromAffiliate.ALL} values={[ExcludedFromAffiliate.ALL,ExcludedFromAffiliate.EXCLUDED, ExcludedFromAffiliate.NOT_EXCLUDED]} className="form-control" model="excludedFromAffiliate" type="text"/>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="col-lg-6" style={{marginTop:"15px"}}>
                                <FormGroup className="form-group custom-control custom-checkbox" model="blockedOnly">
                                    <FormInput className="custom-control-input"  id={"blockedOnly"} model="blockedOnly" type="checkbox" onFocus={() => this.clearForAdvanced()}/>
                                    <label htmlFor={"blockedOnly"} className={"custom-control-label"}>{t(`usersManagement.checkBox.blockedPlayer`)}</label>
                                    <span className=""></span>
                                </FormGroup>
                            </div>
                            <div className="form-group">
                                    <Submit className={"btn btn-primary"}>{t(`button.search`)}</Submit>
                                    <button onClick={() => this.clearFilter()} className={"btn btn-secondary"}>{t(`button.clear`)}</button>
                            </div>
                        </Form>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    clearForText() {
        let searchString:string | undefined = this.state.form && this.state.form.searchString;
        this.setState({form : {searchString:searchString}});
    }
    clearForEmailOrPhoneText() {
        let emailOrPhone:string | undefined = this.state?.form?.emailOrPhone;
        this.setState({form : { emailOrPhone:emailOrPhone }});
    }

    clearForAdvanced() {
        this.setState({form: {...this.state.form, searchString:"", emailOrPhone: ""}})

    }

    clearFilter() {
        let thirtyDaysAgo = new Date(new Date().setDate(new Date().getDate()-30)).toISOString().slice(0,10);
        this.setState({form: {startDate: thirtyDaysAgo, endDate: new Date().toISOString().slice(0,10)}})
    }

    renderContent() {
        if(!this.state.listRequest) {
            return <div/>;
        }
        return (
            <Fragment>
                {this.getSearchByUserId()}
                {this.getSearchByFilter()}
                {this.getSearchByIp()}
                <EntityDataTable service={this.service}
                                 onPagerChange={this.pagerChanged.bind(this)}
                                 columns={this.state.columns}
                                 listRequest={this.state.listRequest}
                                 onAction={this.onAction.bind(this)}
                                 actions={this.actions}/>
            </Fragment>
        );
    }



}

export default withRouter(UserListView);
