import React, {useState} from "react";
import {RouteComponentProps} from "react-router";
import {Button} from "react-bootstrap";
import {PermissionRequired} from "../security/PermissionRequired";
import {PermissionCategory} from "../../http/protocol";
import {CardBox} from "../RouterView";

interface Props extends RouteComponentProps {
}

const ProviderReportsView: React.FC<Props> = (props) => {

    const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedMonth(event.target.value);
    };

    const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedYear(event.target.value);
    };

    const handleProviderFeesDownload = (uri: string) => {
        const monthNumber = String(monthOptions.indexOf(selectedMonth) + 1).padStart(2, "0");
        const fromDate = `${selectedYear}-${monthNumber}-01`;

        const toDate = new Date(`${selectedYear}-${monthNumber}-01`);
        toDate.setMonth(toDate.getMonth() + 1);
        const nextMonth = String(toDate.getMonth() + 1).padStart(2, "0");
        const toDateString = `${toDate.getFullYear()}-${nextMonth}-01`;

        window.location.href = uri + `?from=${fromDate}&to=${toDateString}`;
    };

    const currentYear = new Date().getFullYear();
    const yearRange = 5;
    const yearOptions = [];

    for (let i = 0; i <= yearRange; i++) {
        const year = currentYear - i;
        yearOptions.push(<option key={year} value={year.toString()}>{year}</option>);
    }

    const currentMonth = new Date().toLocaleString('default', {month: 'long'});

    const [selectedYear, setSelectedYear] = useState(currentYear.toString());
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);


    const monthOptions = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    return (
         <PermissionRequired
            permissions={[PermissionCategory.FINANCE]}
            accessLevel="r"
            showError={true}
        > 
            <>

                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <div className="page-title-right"></div>
                            <h4 className="page-title">Financial Reports</h4>
                        </div>
                    </div>
                </div>

                <CardBox title={"Provider License Fees"}>
                    <div>
                        Download license fees per integration, provider, and specific games
                        where applicable.
                    </div>
                    <div className="row">
                        <div className={"col-lg-3"}>
                            <div className="form-group">
                                <label>Year</label>
                                <select
                                    className="form-control"
                                    value={selectedYear}
                                    onChange={handleYearChange}
                                    required={true}
                                >
                                    {yearOptions}
                                </select>
                            </div>
                        </div>
                        <div className={"col-lg-3"}>
                            <div className="form-group">
                                <label>Month</label>
                                <select
                                    className="form-control"
                                    value={selectedMonth}
                                    onChange={handleMonthChange}
                                    required={true}
                                >
                                    {monthOptions.map((month) => (
                                        <option key={month} value={month}>
                                            {month}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <Button
                        onClick={() => {
                            handleProviderFeesDownload('/rest/finance/providers/excel')
                        }}
                    >
                        <i className="fe-download"></i> &nbsp; Provider Fees
                    </Button>
                </CardBox>

                <CardBox title={"Provider Gross Revenue"}>
                    <div>Download gross gaming revenue report per provider.</div>
                    <br/>
                    <div className="row">
                        <div className={"col-lg-3"}>
                            <div className="form-group">
                                <label>Year</label>
                                <select
                                    className="form-control"
                                    value={selectedYear}
                                    onChange={handleYearChange}
                                >
                                    {yearOptions}
                                </select>
                            </div>
                        </div>
                        <div className={"col-lg-3"}>
                            <div className="form-group">
                                <label>Month</label>
                                <select
                                    className="form-control"
                                    value={selectedMonth}
                                    onChange={handleMonthChange}
                                >
                                    {monthOptions.map((month) => (
                                        <option key={month} value={month}>
                                            {month}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <Button
                        onClick={() => {
                            handleProviderFeesDownload('/rest/finance/ggr/studio/excel')
                        }}
                    >
                        <i className="fe-download"></i> &nbsp; Provider GGR
                    </Button>
                </CardBox>

                <CardBox title={"Provider License Rates"}>
                    <div>
                        Download license fee rates, as % revenue share, per game provider
                        and game where applicable.
                    </div>
                    <br/>
                    <Button
                        onClick={() => {
                            window.location.href = "/rest/finance/providers/rates/excel";
                        }}
                    >
                        <i className="fe-download"></i> &nbsp; Provider Rates
                    </Button>
                </CardBox>
            </>
         </PermissionRequired> 
    );
};

export default ProviderReportsView;
