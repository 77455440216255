import React, { useEffect, useState } from "react";
import { AssignRewardToUserRequest, AwardBonusOfferRequest, BonusConfig, RewardTemplate, RewardType } from "../../http/protocol";
import { getContainer } from "../../ioc/IOCSetup";
import { Modal, Spinner } from "react-bootstrap";
import { t } from "../../i18n";
import { Alert } from "../form/Alert";
import { AxiosError } from "axios";

export enum VerificationType {
    bonus_reward,
    user_reward,
}

interface UserRewardVerificationProps {
    type?: VerificationType;
    selectedBonus?: BonusConfig;
    bonusRewardRequest?: AwardBonusOfferRequest;
    euroExchangeRate?: number;
    toggleVerificationModal?: () => void;
    isVerificationModalOpen?: boolean;
    userRewardRequest?: AssignRewardToUserRequest;
    userRewardTemplate?: RewardTemplate;
    requestFeedback?: (action: string) => void;
}

export default function UserRewardVerification(props: UserRewardVerificationProps) {
    const [status, setStatus] = useState<string>("idle");
    const [statusCode, setStatusCode] = useState<number>();

    const [eurAmount, setEurAmount] = useState<number>(0);
    const [freespinsEuro, setFreespinsEuro] = useState<number>(0);
    const [thresholdReached, setThresholdReached] = useState<boolean>(false);
    const [delaySubmit, setDelaySubmit] = useState<boolean>(false);

    const formater = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
    });

    function calculateAmountToEuro() {
        if (props.type === VerificationType.bonus_reward) {
            if (props.bonusRewardRequest && props.bonusRewardRequest.amount && props.euroExchangeRate) {
                let amount = props.bonusRewardRequest.amount * props.euroExchangeRate;
                setEurAmount(amount);
                isThresholdReached(amount, setThresholdReached);
            }
        }
        if (props.type === VerificationType.user_reward) {
            if (props && props.userRewardRequest && props.userRewardRequest.amount && props.euroExchangeRate) {
                let amount = props.userRewardRequest.amount * props.euroExchangeRate;
                setEurAmount(amount);
                isThresholdReached(amount, setThresholdReached);
                if (props.userRewardRequest.nrOfFreeSpins) {
                    setFreespinsEuro(amount * props.userRewardRequest.nrOfFreeSpins);
                    isThresholdReached(amount * props.userRewardRequest.nrOfFreeSpins, setThresholdReached);

                }
            }
        }
    }

    function handleRequestSuccess() {
        if (props.requestFeedback && props.toggleVerificationModal) {
            setStatus("success");
            props.requestFeedback("open");
            props.toggleVerificationModal();
        }
    }

    function handleRequestError(error: AxiosError | Error | any) {
        setStatus("fail");
        if(error.status !== undefined){
            setStatusCode(error.status)
        }
    }

    function onSubmit() {
        if (props.type === VerificationType.user_reward && props.userRewardRequest && props.requestFeedback) {
            setStatus("pending");
            assignRewardToUser(props.userRewardRequest)
                .then(() => handleRequestSuccess())
                .catch((error) => handleRequestError(error));
        }
        if (props.type === VerificationType.bonus_reward && props.bonusRewardRequest && props.requestFeedback) {
            setStatus("pending");
            assignBonusToUser(props.bonusRewardRequest)
                .then(() => handleRequestSuccess())
                .catch((error) => handleRequestError(error));
        }
    }

    useEffect(() => {
        let timeoutId: string | number | NodeJS.Timeout | undefined;
    
        if (thresholdReached) {
            timeoutId = setTimeout(() => {
                setDelaySubmit(true);
            }, 1000);
        }
    
        return () => clearTimeout(timeoutId);
    }, [props, thresholdReached]);
    

    useEffect(() => {
        calculateAmountToEuro();

        return () => {
            setStatus("idle");
            setDelaySubmit(false);
            setThresholdReached(false);
        };
    }, [props]);

    const claimableOnNextDeposit = props.bonusRewardRequest?.claimableOnNextDeposit || false;

    return (
        <Modal show={props.isVerificationModalOpen} onHide={props.toggleVerificationModal}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Bonus Award</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.type === VerificationType.bonus_reward && (
                    <BonusDetails claimableOnNextDeposit={claimableOnNextDeposit} euroAmount={eurAmount} selectedAmount={props.bonusRewardRequest?.amount} selectedBonus={props.selectedBonus} formater={formater} />
                )}
                {props.type === VerificationType.user_reward && (
                    <AwardDetails formater={formater} amountEuro={eurAmount} rewardRequest={props.userRewardRequest} rewardTemplate={props.userRewardTemplate} spinAmountEur={freespinsEuro} />
                )}
                {thresholdReached && status !== "fail" && (
                    <div>
                        <div style={{ whiteSpace: "nowrap" }} className="alert alert-danger p-1 m-0 text-center">
                            {t("helpText.BonusRewardWarning")}
                        </div>
                    </div>
                )}
                {status === "fail" && (
                    <div>
                        <div style={{ whiteSpace: "nowrap" }} className="alert alert-danger p-1 m-0 text-center">
                            {statusCode == 503 ? t("alertInfo.enabledmentFaild"): t("alertInfo.rewardFailed")}
                        </div>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <button type="button" onClick={props.toggleVerificationModal} className="btn btn-secondary mx-2">
                    {t("button.cancel")}
                </button>
                {!props.bonusRewardRequest?.claimableOnNextDeposit &&
                <button type="button" onClick={onSubmit} className="btn btn-primary">
                    {status === "pending" || (thresholdReached && !delaySubmit) ? <Spinner size="sm" role="status" animation={"border"} /> : `Award ${freespinsEuro ? formater.format(freespinsEuro as number) : formater.format(eurAmount as number)}`}
                </button>}
                {props.bonusRewardRequest?.claimableOnNextDeposit &&
                    <button type="button" onClick={onSubmit} className="btn btn-primary">
                        {status === "pending" || (thresholdReached && !delaySubmit) ? <Spinner size="sm" role="status" animation={"border"} /> : `Award`}
                    </button>}
            </Modal.Footer>
        </Modal>
    );
}

/* ------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------ */

async function assignRewardToUser(request: AssignRewardToUserRequest) {
    try {
        return await getContainer().getRewardsConfigService().assignRewardToUser(request);
    } catch (error) {
        throw error;
    }
}

async function assignBonusToUser(request: AwardBonusOfferRequest) {
    try {
        return await getContainer().getBonusOfferService().awardUserBonus(request);
    } catch (error) {
        throw error;
    }
}

function isThresholdReached(amount: number, callback: (isReached: boolean) => void) {
    if (amount > 1000) {
        callback(true);
    }
}

/* ------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------ */

interface getBonusDetailsProps {
    selectedBonus?: BonusConfig;
    euroAmount?: number;
    selectedAmount?: number;
    formater: Intl.NumberFormat;
    claimableOnNextDeposit?: boolean
}

export function BonusDetails(props: getBonusDetailsProps) {
    if (props && props.selectedBonus && props.selectedBonus.monetaryConfig) {
        const conf = props.selectedBonus.monetaryConfig;
        return (
            <div className="col-sm-12">
                <label style={{ paddingTop: "10px" }}>{t(`label.bonusDetails`)}</label>
                <table className={"table table-striped"}>
                    <tbody>
                        <tr>
                            <td>{t(`rewardVerification.bonusReward.bonusName`)}</td>
                            <td>{props.selectedBonus.name}</td>
                        </tr>
                        <tr>
                            <td>{t(`rewardVerification.bonusReward.bonusType`)}</td>
                            <td>{props.selectedBonus.type}</td>
                        </tr>
                        {!props.claimableOnNextDeposit && <tr>
                            <td>{t(`dataTable.header.currency`)}</td>
                            <td>{conf.currencyCode}</td>
                        </tr>}
                        {!props.claimableOnNextDeposit && <tr>
                            <td>{`${t("rewardVerification.bonusReward.amountIn")} ${conf.currencyCode}`}</td>

                            <td>{props.selectedAmount}</td>
                        </tr>}
                        {!props.claimableOnNextDeposit && <tr>
                            <td>{t(`rewardVerification.bonusReward.euroAmount`)}</td>
                            <td className="d-flex align-items-center">{props.formater.format(props.euroAmount ? props.euroAmount : 0)}</td>
                        </tr>}
                        <tr>
                            <td>{t(`rewardVerification.bonusReward.bonusPayment`)}</td>
                            <td>{conf.bonusPaymentType}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    } else {
        return <tbody />;
    }
}

/* ------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------ */

interface AwardDetailsProps {
    rewardRequest?: AssignRewardToUserRequest;
    rewardTemplate?: RewardTemplate;
    spinAmountEur?: number;
    amountEuro?: number;
    formater: Intl.NumberFormat;
}

export function AwardDetails(props: AwardDetailsProps) {
    if (props && props.rewardRequest) {
        const currency = props.rewardRequest.currencyCode ? props.rewardRequest.currencyCode : props.rewardTemplate?.currencyCode;
        return (
            <div className="col-sm-12">
                <label style={{ paddingTop: "10px" }}>{t(`label.bonusDetails`)}</label>
                <table className={"table table-striped"}>
                    <tbody>
                        <tr>
                            <td>{t(`rewardVerification.userReward.rewardType`)}</td>
                            <td>{props.rewardTemplate?.rewardType}</td>
                        </tr>
                        <tr>
                            <td>{t(`dataTable.header.currency`)}</td>
                            <td>{currency}</td>
                        </tr>
                        <tr>
                            <td>{`${t("rewardVerification.userReward.amountIn")} ${currency}`}</td>
                            <td>{props.rewardRequest.amount}</td>
                        </tr>
                        {currency?.toLocaleLowerCase() !== "eur" && (
                            <tr>
                                <td>{t(`rewardVerification.userReward.euroAmount`)}</td>
                                <td className="d-flex align-items-center">{props.formater.format(props.amountEuro ? props.amountEuro : 0)}</td>
                            </tr>
                        )}
                        {props.rewardTemplate?.rewardType == RewardType.freespins && <tr>
                            <td>{t(`rewardVerification.userReward.nrOfspins`)}</td>
                            <td>{props.rewardRequest.nrOfFreeSpins}</td>
                        </tr>}
                        {props.rewardTemplate?.rewardType == RewardType.freespins && <tr>
                            <td>{t(`rewardVerification.userReward.euroAmountSpins`)}</td>
                            <td>{props.formater.format(props.spinAmountEur ? props.spinAmountEur : 0)}</td>
                        </tr>}
                    </tbody>
                </table>
            </div>
        );
    } else {
        return <tbody />;
    }
}

interface FormSuccessProps {
    type: VerificationType;
    status?: "success" | "danger";
    requestFeedback: (action: string) => void;
    isOpen: boolean;
}

export const RequestFeedback = (props: FormSuccessProps) => {
    let text: string = props.type === VerificationType.bonus_reward ? t(`alertInfo.bonusAwardedToPlayer`) : t(`alertInfo.bonusAwardedToPlayer`);

    return (
        <Modal show={props.isOpen} onHide={() => props.requestFeedback("close")}>
            <Modal.Header closeButton>
                <Modal.Title>{t(`alertInfo.weDidIt`)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert type={"success"} text={text} />
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-primary" onClick={() => props.requestFeedback("close")}>
                    {t(`button.close`)}
                </button>
            </Modal.Footer>
        </Modal>
    );
};
