import React from 'react';
import { Modal, Button, Card } from 'react-bootstrap';
import { ReportedPlayer } from '../../http/protocol';
import { t } from '../../i18n';
import { AbuseService } from "../../services/AbuseService";

interface AbuseReportModalProps {
    show: boolean;
    onHide: () => void;
    reports: ReportedPlayer[];
    userId: number;
    userUid: string;
    abuseService: AbuseService;
    updatePage: () => void;
}

const handleRemove = async (userId: number, abuseService: AbuseService, onHide: () => void, updatePage: () => void) => {
    try {
        await abuseService.removeReport(userId.toString());
        console.log(`Removed report with ID: ${userId}`);
        onHide();
        updatePage();
    } catch (error) {
        console.error(`Failed to remove report with ID: ${userId}`, error);
    }
};

const AbuseReportModal: React.FC<AbuseReportModalProps> = ({ show, onHide, reports, userId, userUid, abuseService , updatePage}) => {
    return (
        <Modal show={show} onHide={onHide} size="lg" >
            <Modal.Header closeButton>
                <Modal.Title className="modal-title">{t('title.nanoCentralIntelligenceReport')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-full">
                {reports.map((report, index) => (
                    <Card key={index} className="mb-3">
                        <Card.Body>   
                            <div className="mb-3">
                            <h5>{t('label.operator')}: <span>{report.operator}{report.yourOwnReport ? ' (You)' : ''}</span></h5>
                            <h5>{t('label.playerUid')}:  
                                {report.yourOwnReport && report.playerUid !== userUid ? (
                                    <a href={`/users/limit/20/orderBy/id/ascending/false/blockedUsers/false/offset/0/userIdOrUid/${report.playerUid}`}> {report.playerUid}</a>
                                ) : (
                                    <span> {report.playerUid}</span>
                                )}
                            </h5>
                        </div>
                            <div className="abuse-types mb-3">
                                {report.abuse && report.abuse.map((abuseType, idx) => (
                                    <span key={idx} className="badge badge-secondary abuse-label mr-1">{t(`abuseType.${abuseType}`)}</span>
                                ))}
                            </div>
                            <p className="abuse-comment mb-3">
                                {report.comment ? report.comment : t('label.noCommentSubmitted')}
                            </p>
                            {report.deviceIds && report.deviceIds.length > 0 && (
                                <div className="mb-3">
                                    <strong>{t('label.matchingDeviceIds')}:</strong>
                                    <div className="device-ids">
                                        {report.deviceIds.map((deviceId, idx) => (
                                            report.yourOwnReport ? (
                                                <div key={idx}>
                                                    <a href={`/users/searchString/${deviceId}/offset/0`}>{deviceId}</a>
                                                </div>
                                            ) : (
                                                <div key={idx}>{deviceId}</div>
                                            )
                                        ))}
                                    </div>
                                </div>
                            )}
                            {report.ipAddresses && report.ipAddresses.length > 0 && (
                                <div className="mb-3">
                                    <strong>{t('label.matchingIps')}:</strong>
                                    <div className="ip-addresses">
                                        {report.ipAddresses.map((ip, idx) => (
                                            report.yourOwnReport ? (
                                                <div key={idx}>
                                                    <a href={`/users/searchString/${ip}/offset/0`}>{ip}</a>
                                                </div>
                                            ) : (
                                                <div key={idx}>{ip}</div>
                                            )
                                        ))}
                                    </div>
                                </div>
                            )}
                               {report.fingerprints && report.fingerprints.length > 0 && (
                            <div className="mb-3">
                                <strong>{t('label.matchingFingerPrints')}:</strong>
                                <div className="fingerprints">
                                    {report.fingerprints && report.fingerprints.map((fingerprint, idx) => (
                                        <div key={idx}>{fingerprint}</div>
                                    ))}
                                </div>
                            </div>
                            )}
                            <div className="d-flex justify-content-end">
                                {report.yourOwnReport && report.playerUid === userUid && (
                                    <Button variant="danger" onClick={() => handleRemove(userId, abuseService, onHide, updatePage)}>
                                        {t('button.remove')}
                                    </Button>
                                )}
                            </div>
                        </Card.Body>
                    </Card>
                ))}
            </Modal.Body>
            <Modal.Footer  className="d-flex justify-content-end">
            <Button variant="secondary" className="ml-auto" onClick={onHide}>
                    {t('button.close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AbuseReportModal;