import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {
    Affiliate,
    AffiliatesSettings,
    AffiliateStatus,
    ListResponse,
    ManualPayoutRequest,
    SingleCurrencyPayout
} from "../http/protocol";
import {HttpService} from "../http/HttpService";


export class AffiliatesService extends AbstractAdminEntityService<AffiliatesSettings,ListResponse> implements AffiliatesService{
    constructor(http: HttpService) {
        super("/affiliates", http);
    }


    getSettings(): Promise<AffiliatesSettings> {
        return this.http.get(`${this.entityPath}/settings`);
    }


    saveSettings(settings: AffiliatesSettings):Promise<AffiliatesSettings> {
        return this.http.put<{},{}>(`${this.entityPath}/settings`, settings);
    }


    //Reports
    getPayoutsAll(request:{}):Promise<{}> {
        return this.http.post(this.entityPath +'/payouts/all', request);
    }

    getAffiliateRevenue(currency:string, affiliateId:number):Promise<SingleCurrencyPayout[]> {
        return this.http.get(`${this.entityPath}/revenue/player/month/${currency}/${affiliateId}`);
    }

    updateStatus(affiliateId?: number, s?:AffiliateStatus):Promise<Affiliate> {
        return this.http.put(`${this.entityPath}/${affiliateId}`, {status: s});
    }

    manualPayout(manualPayoutRequest: ManualPayoutRequest):Promise<void> {
        return this.http.post(this.entityPath + "/manual-payout", manualPayoutRequest);
    }

    getPayoutCurrency(affiliateId: any) {
        return this.http.get<string>(this.entityPath + '/payout-currency?affiliateId=' + affiliateId);
    }

    getAffiliatePlayersAll(affiliateId: number | undefined,request:{}):Promise<{}> {
        return this.http.post(`${this.entityPath}/list-players/${affiliateId}`, request);
    }
}