import * as React from "react";
import {Modal} from "react-bootstrap";

interface Props { 
    show: boolean,
    heading: string,
    data?: string, 
    onClose: () => void
}


export class DataModal extends React.Component<Props, {}>{

    constructor(props : Props){
        super(props);
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onClose.bind(this)} aria-labelledby="contained-modal-title">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title">{this.props.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <pre>{this.props.data}</pre>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <a className="btn btn-primary" onClick={this.props.onClose.bind(this)}>Close</a>
                    </div>
                </Modal.Footer>
            </Modal>
       );
    }
}