import { HttpService } from "../http/HttpService";
import {
    UserPerformanceStatsContainer
} from "../http/protocol";


const entityPath = 'dw/user'

export class DwUserService {


    constructor(private http: HttpService) {
    }

    async getUserPerformanceStats(userId: number): Promise<UserPerformanceStatsContainer> {
        return this.http.get(`/${entityPath}/perf-stats/${userId}`);
    };
    
}