import * as React from "react";

import {RouteComponentProps, withRouter} from "react-router";
import {LoadingViewState, RouterView} from "../RouterView";
import {CasinoStudio, GameTag} from "../../http/protocol";
import {Form, FormListener} from "../form/Form";
import {getContainer} from "../../ioc/IOCSetup";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {Submit} from "../form/Submit";
import {CasinoGameTagService} from "../../services/CasinoGameTagService";
import {Validators} from "../form/Validators";
import {t} from "../../i18n";



interface State extends LoadingViewState {
    tag? : GameTag;
    validationMap? : any;
}

export class EditCasinoGameTag extends RouterView<RouteComponentProps,State> implements FormListener<GameTag>{

    service : CasinoGameTagService;

    constructor(props : RouteComponentProps){
        super(props);
        this.service = getContainer().getCasinoGameTagService();
        this.state = { tag : {}, validationMap : {} };
    }

    getTitle(): string {
        return "Edit Game Tag";
    }

    componentDidUpdate(prevProps : RouteComponentProps, prevState : State) {
        if((prevProps.match.params as any).id != (this.props.match.params as any).id)  {
            this.refreshContent();
        }
    }

    loadContent(): Promise<any>  {
        if((this.props.match.params as any).id != "new") {
            return this.service.getEntity((this.props.match.params as any).id).then((s : GameTag) => {
                this.setState({ tag : s });
            });
        }
        return Promise.resolve();

    }

    onSubmit()  {
        if(!this.state.tag) return;
        console.log("Save this tag: ", this.state.tag);
        return this.service.save(this.state.tag).then((s : CasinoStudio)=> {
            this.setState({tag : s});
        })
    }

    formDidUpdate(formModel: GameTag, validationMap?: any) {
        this.setState({tag : formModel, validationMap : validationMap})
    }

    addTranslation(lang : string, value : string) {
        if(this.state.tag) {
            let trans = Object.assign({}, this.state.tag.translations);
            trans[lang] = value;
            this.setState({ tag : {...this.state.tag,  translations : trans }});
        }
    }
    removeTranslation(lang : string) {
        if(this.state.tag) {
            let trans = Object.assign({}, this.state.tag.translations);
            delete trans[lang];
            this.setState({ tag : {...this.state.tag,  translations : trans }});
        }
    }

    renderContent() {
        if(!this.state.tag) {
            return <span></span>
        }

        return (
            <div>


                <div className="wrapper wrapper-content animated fadeInRight">
                    <Form className="form form-horizontal" validationMap={this.state.validationMap} modelObject={this.state.tag} formListener={this}>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card-box">
                                    <h5>Tag</h5>
                                    <div className="ibox-content">
                                        {(this.props.match.params as any).id != "new" && (   <div className="hr-line-dashed"></div> )}

                                        {(this.props.match.params as any).id != "new" && (
                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">ID</label>
                                                <div className="col-sm-10">
                                                    <FormInput  model="id" type="integer"  className="form-control" disabled={true}/>
                                                </div>
                                            </FormGroup>
                                        )}


                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Name ID</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="nameId" validators={[Validators.required()]} type="text"  className="form-control" />
                                                <span className={"help"}> Id that can not be changed after created</span>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Name</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="name" validators={[Validators.required()]}  type="text"  className="form-control" />
                                            </div>
                                        </FormGroup>


                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Display</label>
                                            <div className="col-sm-10">
                                                <FormInput  id="display" name="display" model="display" type="checkbox"  />
                                                <label htmlFor={"display"} className={"help"}>&nbsp; If this tag should be displayed to the player </label>
                                            </div>
                                        </FormGroup>




                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className={"card"}>
                            <div className="card-body">
                                <div className={"row"}>
                                    <div className={"col-lg-12"}>
                                        <h4>Translations</h4>
                                        {this.state.tag && this.state.tag.translations && Object.keys(this.state.tag.translations).map( (k,i) => {
                                            return (
                                                <div className={"form-inline"} key={"tag-trans-"+i}>
                                                    {k &&   <input className={"form-control"} value={k} type={"text"} disabled={true}/> }
                                                    {this.state.tag && this.state.tag.translations &&  <input value={ this.state.tag.translations[k]} className={"form-control"}  type={"text"} disabled={true}/>}
                                                    <a className={"btn btn-danger"} onClick={()=>this.removeTranslation(k)}>Remove</a>
                                                </div>
                                            )
                                        })}
                                        <br/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 form-inline">
                                        <div className="hr-line-dashed"></div>
                                        <AddTranslation onAdd={(lang,value) => this.addTranslation(lang,value)}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card-box">

                                    <FormSubmitError />
                                    <FormSubmitSuccess text={t(`alertInfo.accountSuccessfully`)}/>
                                    <div className="form-group">
                                        <div className="col-sm-12">
                                            <Submit className="btn  btn-primary">Save</Submit>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }

}

interface AddTranslationProps { onAdd : (lang: string, val : string) => void }

class AddTranslation extends React.Component<AddTranslationProps, {lang : string, value : string}> {
    constructor(props : AddTranslationProps) {
        super(props);
        this.state = { lang : "", value : ""}
    }
    add() {
        // @ts-ignore
        if(this.props.onAdd && this.state.lang.length > 0 && this.state.value.length > 0) {
            this.props.onAdd(this.state.lang, this.state.value);
            this.setState({ lang : "", value : ""});
        }
    }
    render() {
        return (
            <div className={"form-group"}>
                <label className={"col-3"}>Add Translation</label>
                <div className={"col-4"}>
                    <input type={"text"} value={this.state.lang} onChange={(e)=>this.setState({ lang : e.target.value, value : this.state.value})} className={"form-control"} placeholder={"Language code"}/>
                </div>
                <div className={"col-4"}>
                    <input type={"text"} value={this.state.value} onChange={(e)=>this.setState({ lang : this.state.lang, value : e.target.value})} className={"form-control"} placeholder={"Name in Language"}/>
                </div>
                <div className={"col-1"}>
                    <a className={"btn btn-primary"} onClick={()=>this.add()}>Add</a>
                </div>

            </div>
        )
    }
}
export default withRouter(EditCasinoGameTag);