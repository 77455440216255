import * as React from "react";
import {getContainer} from "../../ioc/IOCSetup";
import {AdminNote} from "../../http/protocol";
import {formatDate} from "../../util/date";

interface Props {
    note: AdminNote;
    show? : boolean;
}
interface State {
    pinned?: boolean;
}
export class AdminNoteComp extends React.Component<Props,State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            pinned: this.props.note.pinned
        };
    }
    handlePin(note: AdminNote) {
        let n = note;
        n.pinned = !n.pinned;
        getContainer().getAdminNotesService().save(n).then( res => {
            this.setState({pinned: n.pinned});
        });
    }

    render() {
        if (!this.props.show) {
            return <span/>
        }
        return (
            <li className="clearfix">
                <div className="conversation-text">
                    <div className="ctext-wrap">
                        <span onClick={() => this.handlePin(this.props.note)} className={"badge badge-pill float-right " + (this.state.pinned? "badge-danger": "badge-light")}>
                            <i className="dripicons-pin"></i>
                        </span>
                        <span>{this.props.note.created && formatDate(Number(this.props.note.created.toString().split(".").join("").substring(0,13)))}</span>
                        <i>{this.props.note.authorName}</i>
                        <p>
                            {this.props.note.comment}
                        </p>
                    </div>
                </div>
            </li>
        );
    }
}
