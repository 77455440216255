import React, {Fragment} from "react";
import {getContainer} from "../../ioc/IOCSetup";
import {RouteComponentProps, withRouter} from "react-router";
import {LoadingViewState, RouterView} from "../RouterView";
import {CashbackConfigService} from "../../services/CashbackConfigService";
import {NextUpcomingCashback} from "../../http/protocol";
import moment from "moment";

interface State extends LoadingViewState {
    cashBacksText: string;
}

interface Props extends RouteComponentProps {

}

export class UserNextCashbackView extends RouterView<Props, State> {

    service: CashbackConfigService;

    constructor(props: Props) {
        super(props);
        this.service = getContainer().getCashbackConfigService();
        this.state = {cashBacksText: 'Fetching data...'};
    }

    async loadContent() {
        const id = (this.props.match.params as any).id;
        this.service.getNextUpcomingCashbackForUser(id).then(cashbackList => {
            this.setState({cashBacksText: this.buildCashbackText(cashbackList)});
        }).catch(err => {
            console.log(err);
            this.setState({cashBacksText: 'Error while fetching upcoming cashback information'});
        });
    }

    buildCashbackText(cashbackList: NextUpcomingCashback[]): string {
        let str: string = '';
        let payoutOngoing = false;
        if(cashbackList.length==0){
            str = "This functionality is disabled";
        }
        else {
            str = "Next upcoming cashback:";
            cashbackList.forEach(cashback => {
                if(cashback.payoutOngoing) {
                    payoutOngoing = true;
                }
                str += "\n\nPayout time: " + moment(cashback.scheduled).format('YYYY-MM-DD HH:mm:ss');
                str += "\nCalculated at: " + moment(cashback.calculationTime).format('YYYY-MM-DD HH:mm:ss');
                if ("undefined" != typeof cashback.amount && cashback.amount > 0) {
                    str += "\nConfig ID: " + cashback.configId + ", Name: " + cashback.configName + ", Frequency: " + cashback.frequency;
                    str += "\nAmount: " + cashback.amount + ", Currency: " + cashback.currency;
                }
                else {
                    str += "\nAt " + moment(cashback.calculationTime).format('HH:mm');
                    str += " there was no cashback for the next payout at " + moment(cashback.scheduled).format('YYYY-MM-DD HH:mm:ss');
                }
                if ("undefined" != typeof cashback.notEligibleReason) {
                    str += "\nUser cashback limitations: " + cashback.notEligibleReason;
                }
            })
        }
        if (payoutOngoing) {
            str = "Payout is ongoing\n\n" + str;
        }
        return str;
    }

    getTitle(): string {
        return "Next Upcoming Cashback";
    }

    renderContent() {
        return (
            <Fragment>
                <div>
                    <div className={"card"}>
                        <div className="card-body">
                            <button onClick={(e) => {
                                navigator.clipboard.writeText(this.state.cashBacksText)
                            }} className="btn btn-primary">Copy Data
                            </button>
                        </div>
                        <div className="card-body">
                            <div className="help-block">Format: Payout Date, Cashback Type, Amount Currency</div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <textarea spellCheck={false} className="form-control" style={{height: "350px"}}
                                              disabled={true}
                                              value={this.state.cashBacksText}/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Fragment>
        )
    }

}

export default withRouter(UserNextCashbackView);