import * as React from "react";
import {SettingsService} from "../../services/SettingsService";
import {getContainer} from "../../ioc/IOCSetup";
import {LoadingViewState, RouterView} from "../RouterView";
import {Form, FormListener} from "../form/Form";
import {RouteComponentProps, withRouter} from "react-router";
import {Fragment} from "react";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Submit} from "../form/Submit";
import {InternalSettings, Settings, CryptoOnrampProvider, GameIntegration} from "../../http/protocol";
import {InternalSettingsService} from "../../services/InternalSettingsService";
import {Validators} from "../form/Validators";
import { SelectInput } from "../form/SelectInput";


interface Props extends RouteComponentProps {

}

type InternalSettingsPlus = InternalSettings & { blockedCountriesPlaceholder?: string, allowedCountriesPlaceholder?: string }

interface State extends LoadingViewState{
    settings?: InternalSettingsPlus;
    validationMap? : any;
    emailRequired? : boolean;

}

export class SettingsInternalView extends RouterView<Props,State> implements FormListener<InternalSettings>{

    settingsService: InternalSettingsService;

    constructor(props:Props) {
        super(props);

        this.settingsService = getContainer().getInternalSettingsService();

        this.state = {validationMap : {}, loading : true, emailRequired : false};
    }

    async loadContent() {
        const settings: InternalSettingsPlus = await this.settingsService.getSettings();

        let emailRequired:boolean = false;

        if (settings.paymentNotifyDeposit || settings.paymentNotifyInstantWithdraw || settings.paymentNotifyWithdrawApprovementNeeded) {
            emailRequired = true;
        }

        settings.blockedCountriesPlaceholder = settings.blockedCountries?.join(", ")
        settings.allowedCountriesPlaceholder = settings.allowedCountries?.join(", ")

        this.setState({settings:settings, emailRequired: emailRequired})
    }

    componentDidUpdate(prevProps : RouteComponentProps, prevState : any) {
        super.componentDidUpdate(prevProps, prevState);

    }

    formDidUpdate(formModel:InternalSettings, validationMap : any) {
        let emailRequired:boolean = false;
        if(formModel.paymentNotifyInstantWithdraw || formModel.paymentNotifyDeposit || formModel.paymentNotifyWithdrawApprovementNeeded) {
            emailRequired = true;
        }
        this.setState({ settings : formModel, validationMap : validationMap, emailRequired : emailRequired});
    }

    stringToArray(s: string | undefined) {
        return s == undefined ? [] : s.split(/[ ,]+/).filter(c => c != "")
    }

    async onSubmit():Promise<any> {
        if (!this.state.settings) {
            return Promise.reject("Unable to save settings");
        }

        this.setState({loading: true})
        
        const {blockedCountriesPlaceholder, allowedCountriesPlaceholder, ...settings} = this.state.settings
        settings.blockedCountries = this.stringToArray(blockedCountriesPlaceholder)
        settings.allowedCountries = this.stringToArray(allowedCountriesPlaceholder)
        
        await this.settingsService.saveSettings(settings)
        await this.loadContent()

        this.setState({loading: false})
    }

    onSubmitError(){}

    getTitle() : string {
        return "Internal Settings";
    }

    renderContent() {
        if(!this.state.settings || this.state.emailRequired == undefined) {
            return <span></span>
        }
        return(
            <Fragment>
                <Form className="form-horizontal" formListener={this} modelObject={this.state.settings} validationMap={this.state.validationMap}>
                    <div className={"card"}>
                        <div className="card-header">
                            <h4>General Settings</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <FormSubmitError />
                                    <FormSubmitSuccess text="Settings was updated successfully"/>

                                    <FormGroup className="form-group mb-3" model={"paymentNotificationEmail"}>
                                        <label>Notification email</label>
                                        <FormInput className="form-control" model={"paymentNotificationEmail"} type="text" id="paymentNotificationEmail"/>
                                        <span className={"help-block"}>Email for receiving payment notifications separated with a single space</span>
                                    </FormGroup>
                                    
                                    <FormGroup className="custom-control custom-checkbox" model="paymentNotifyDeposit">
                                        <FormInput className="custom-control-input" model={"paymentNotifyDeposit"} id="paymentNotifyDeposit" type="checkbox"/>
                                        <label htmlFor="paymentNotifyDeposit" className={"custom-control-label"}>Deposit Notification</label>
                                    </FormGroup>

                                    <FormGroup className="custom-control custom-checkbox" model="paymentNotifyInstantWithdraw">
                                        <FormInput className="custom-control-input" model={"paymentNotifyInstantWithdraw"} id="paymentNotifyInstantWithdraw" type="checkbox"/>
                                        <label htmlFor="paymentNotifyInstantWithdraw" className={"custom-control-label"}>Instant Withdraw Notification</label>
                                    </FormGroup>

                                    <FormGroup className="custom-control custom-checkbox" model="paymentNotifyWithdrawApprovementNeeded">
                                        <FormInput className="custom-control-input" model={"paymentNotifyWithdrawApprovementNeeded"} id="paymentNotifyWithdrawApprovementNeeded" type="checkbox"/>
                                        <label htmlFor="paymentNotifyWithdrawApprovementNeeded" className={"custom-control-label"}>Withdraw Approval Notification</label>
                                    </FormGroup>

                                    <FormGroup className="custom-control custom-checkbox" model="paymentNotifyUserLevelUp">
                                        <FormInput className="custom-control-input" model={"paymentNotifyUserLevelUp"} id="paymentNotifyUserLevelUp" type="checkbox"/>
                                        <label htmlFor="paymentNotifyUserLevelUp" className={"custom-control-label"}>User Level Up</label>
                                    </FormGroup>

                                    <FormGroup className="custom-control custom-checkbox" model="weeklySummaryEmail">
                                        <FormInput className="custom-control-input" model={"weeklySummaryEmail"} id="weeklySummaryEmail" type="checkbox"/>
                                        <label htmlFor="weeklySummaryEmail" className={"custom-control-label"}>Weekly Summary Email</label>
                                    </FormGroup>

                                    <FormGroup className="form-group mb-3" model={"weeklySummaryEmailRecipients"}>
                                        <label>Weekly summary email recipients</label>
                                        <FormInput className="form-control" model={"weeklySummaryEmailRecipients"} type="text" id="weeklySummaryEmailRecipients"/>
                                        <span className={"help-block"}>Emails to receive weekly summary separated with a single space</span>
                                    </FormGroup>

                                    <FormGroup className="form-group mb-3" model={"blockedCountriesPlaceholder"}>
                                        <label>Blocked Countries</label>
                                        <FormInput className="form-control" model={"blockedCountriesPlaceholder"} type="text" id="blockedCountriesPlaceholder"/>
                                        <span className={"help-block"}>Block is applied when loading the site. Country codes in ISO-3166-1 Alpha-2 format. Separate with space or comma. Example (USA, Germany & Sweden): US, DE, SE</span>
                                    </FormGroup>

                                    <FormGroup className="form-group mb-3" model={"allowedCountriesPlaceholder"}>
                                        <label>Allowed Countries</label>
                                        <FormInput className="form-control" model={"allowedCountriesPlaceholder"} type="text" id="allowedCountriesPlaceholder"/>
                                        <span className={"help-block"}>Explicitly allowed countries. If this list is not empty ONLY those countries will be allowed regardless of blocked countries list. Country codes in ISO-3166-1 Alpha-2 format. Separate with space or comma. Example: US, DE, SE</span>
                                    </FormGroup>

                                    <FormGroup className="custom-control custom-checkbox" model="verifyIpAddresses">
                                        <FormInput className="custom-control-input" model={"verifyIpAddresses"} id="verifyIpAddresses" type="checkbox"/>
                                        <label htmlFor="verifyIpAddresses" className={"custom-control-label"}>Check IP Address</label><br/>
                                        <span className={"help-block"}>Check the IP address against abuse & fraud IP lists when a user registers. If above risk level then the registration will be blocked.</span>
                                    </FormGroup>

                                    <FormGroup className="form-group mb-3" model={"maxIpAddressRiskLevel"}>
                                        <label>Max IP Address Risk Level</label>
                                        <FormInput className="form-control" model={"maxIpAddressRiskLevel"} type="text" id="maxIpAddressRiskLevel"/>
                                        <span className={"help-block"}>Max acceptable risk level for IP addresses. 1 to 5 where 1 is lowest risk. Only applicable if 'Check IP Address' is enabled. If set to 5 then all risk levels are allowed. 1 is most stringent. Recommended: 3</span>
                                    </FormGroup>

                                    <FormGroup className="form-group mb-3" model={"registrationIpBlockTime"}>
                                        <label>Registration IP Address Block Time</label>
                                        <FormInput className="form-control" model={"registrationIpBlockTime"} type="text" id="registrationIpBlockTime"/>
                                        <span className={"help-block"}>When a new user signs up, block his IP for this many minutes for new signups to avoid spam registrations. Set to 0 to disable. Max: 60. Recommended: 15</span>
                                    </FormGroup>

                                    <FormGroup className="form-group mb-3" model={"freeSpinsEnabledIntegrations"}>
                                        <label>Game Integrations To Enable Free Spins</label>
                                        <FormInput className="form-control" model={"freeSpinsEnabledIntegrations"} type="text" id="freeSpinsEnabledIntegrations"/>
                                        <span className={"help-block"}>A comma separated list of game integrations to enable free spins. Free spins will be available for the games of these integrations</span><br/>
                                        <span className={"help-block"}>Possible values: {Object.values(GameIntegration).join(", ")}</span>
                                    </FormGroup>

                                    <FormGroup className="custom-control custom-checkbox" model="trackUserLevels">
                                        <FormInput className="custom-control-input" model={"trackUserLevels"} id="trackUserLevels" type="checkbox"/>
                                        <label htmlFor="trackUserLevels" className={"custom-control-label"}>Track User XP & Levels</label><br/>
                                        <span className={"help-block"}>Will enable tracking of wagering xp and user levels. Don't forget to enable show user levels in Settings as well.</span>
                                    </FormGroup>

                                    <FormGroup className="custom-control custom-checkbox" model="require2FAEmail">
                                        <FormInput className="custom-control-input" model={"require2FAEmail"} id="require2FAEmail" type="checkbox"/>
                                        <label htmlFor="require2FAEmail" className={"custom-control-label"}>Require 2FA for Email</label><br/>
                                        <span className={"help-block"}>If checked then 2FA will automatically be enabled for users registering with email. Meaning they will need to provide
                                        a 2FA login code from email every time they login.</span>
                                    </FormGroup>

                                    <FormGroup className="custom-control custom-checkbox" model="require2FAPhone">
                                        <FormInput className="custom-control-input" model={"require2FAPhone"} id="require2FAPhone" type="checkbox"/>
                                        <label htmlFor="require2FAPhone" className={"custom-control-label"}>Require 2FA for Phone</label><br/>
                                        <span className={"help-block"}>If checked then 2FA will automatically be enabled for users registering with phone number. Meaning they will need to provide
                                        a 2FA login code from SMS every time they login.</span>
                                    </FormGroup>

                                    <FormGroup className="custom-control custom-checkbox" model="allowMultiSession">
                                        <FormInput className="custom-control-input" model={"allowMultiSession"} id="allowMultiSession" type="checkbox"/>
                                        <label htmlFor="allowMultiSession" className={"custom-control-label"}>Allow Multiple Sessions</label><br/>
                                        <span className={"help-block"}>If checked users can be logged in to the site from multiple browsers at the same time. If disabled users will be logged out from all other browsers when logging in. This also affects the PWA.
                                        </span>
                                    </FormGroup>

                                    <FormGroup className="custom-control custom-checkbox" model="paymentInstantWithdrawalsEnabled">
                                        <FormInput className="custom-control-input" model={"paymentInstantWithdrawalsEnabled"} id="paymentInstantWithdrawalsEnabled" type="checkbox"/>
                                        <label htmlFor="paymentInstantWithdrawalsEnabled" className={"custom-control-label"}>Instant Withdrawals Enabled</label><br/>
                                        <span className={"help-block"}>Enable/disable the Instant Withdrawal automatic approval service. Allow for a couple of minutes to take effect. 
                                        </span>
                                    </FormGroup>
            
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"card"}>
                        <div className="card-header">
                            <h4>Integrations</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">

                                    <FormGroup className={"form-group"}>
                                        <label>Crypto Onramp Provider</label>
                                        <SelectInput className={"form-control"} model={"cryptoOnrampProvider"} values={Object.keys(CryptoOnrampProvider)} />
                                        <span className={"help-block"}>Which provide to use for crypto buy/sell. Make sure account credentials are setup properly before enabling.</span>
                                    </FormGroup>

                                    <FormGroup className="form-group mb-3" model={"matiSecretString"}>
                                        <label>Metamap Webhook Secret</label>
                                        <FormInput className="form-control" model={"matiSecretString"} type="text" id="matiSecretString" />
                                        <span className={"help-block"}>Metamap Webhook Secret</span>
                                    </FormGroup>

                                    <FormGroup className="form-group mb-3" model={"facebookAppSecret"}>
                                        <label>Facebook App Secret</label>
                                        <FormInput className="form-control" model={"facebookAppSecret"} type="text" id="paymentNotificationEmail" />
                                        <span className={"help-block"}>API key that is used to call the Facebook API from the backend. This can be found in Facebook Developer Console</span>
                                    </FormGroup>
            
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"card"}>
                        <div className={"card-body"}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="ibox float-e-margins">
                                        <div className="ibox-content">
                                            <div className="ibox-title">
                                                <h5>Actions</h5>
                                            </div>
                                            <div className="form-group">
                                                <div className="col-sm-10 col-sm-offset-2">
                                                    <Submit className="btn btn-primary waves-effect waves-light">Save Settings</Submit>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Form>


            </Fragment>
        )
    }

}

export default withRouter(SettingsInternalView);