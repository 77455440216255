import React from "react";
import {getContainer} from "../../ioc/IOCSetup";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {LoadingViewState, RouterView} from "../RouterView";
import {BatchTransactionService} from "../../services/BatchTransactionService";
import {TxBatchResult, TxBatchRowResult, TxBatchStatus} from "../../http/protocol";
import {LoadingSpinner} from "../../util/LoadingSpinner";


interface State extends LoadingViewState {
    batch? : TxBatchResult;
    inProgress? : boolean;
}

interface Props extends RouteComponentProps<{id? : string}>{

}

export class BatchTransactionView extends RouterView<Props, State>{

    batchService: BatchTransactionService;


    constructor(props : Props) {
        super(props);
        this.batchService = getContainer().getBatchTransactionService();
        this.state = { inProgress : false };
    }

    loadContent() : Promise<any> {
        if(this.props.match.params.id) {
            this.setState({ inProgress : true });
            return this.batchService.getBatchTransferResults(this.props.match.params.id).then((r)=>{
                this.setState( {batch : r, inProgress : false });
            })
        }
        return Promise.resolve();

    }

    getTitle(): string {
        return "View Batch Transaction";
    }
    perform() {
        if(!this.state.batch || !this.state.batch.batchId) {
            return;
        }
        this.setState({ inProgress : true });
        this.batchService.performBatchTransfer(this.state.batch.batchId).then( r => {
            this.setState({ batch : r, inProgress : false});
        });
    }
    cancel() {
        if(!this.state.batch || !this.state.batch.batchId) {
            return;
        }
        this.setState({ inProgress : true });

        this.batchService.cancelPreparedBatchTransfer(this.state.batch.batchId).then( r => {
            this.props.history.push("/create-batch");
        })
    }

    renderContent() {
            return (
                <div className={"card"}>
                    <div className="card-body">
                        {!this.state.inProgress && (
                            <div>
                                {this.state.batch && this.state.batch.status == TxBatchStatus.PREPARED && (
                                    <div>
                                        <div className={"alert alert-info"}>
                                            The batch has been prepared. Click Perform if everything looks good to perform the transactions or cancel to go back
                                        </div>
                                        <button onClick={()=>this.cancel()}className={"btn btn-danger"}>Remove Batch</button> <button onClick={()=>this.perform()}className={"btn btn-primary"}>Perform Batch Transactions</button>

                                    </div>
                                )}

                                {this.state.batch && this.state.batch.status == TxBatchStatus.RUNNING && (
                                    <div>
                                        <div className={"alert alert-warning"}>
                                            Batch in progress, press refresh to see the current status
                                        </div>
                                        <button onClick={()=>this.loadContent()} className={"btn btn-secondary"}>Refresh</button>
                                    </div>
                                )}

                                {this.state.batch && this.state.batch.status == TxBatchStatus.FINISHED && (
                                    <div>
                                        <div className={"alert alert-success"}>
                                           Batch transactions was successfully completed!
                                        </div>
                                    </div>
                                )}
                                {this.state.batch && this.state.batch.status == TxBatchStatus.FAILED && (
                                    <div>
                                        <div className={"alert alert-danger"}>
                                            The batch transaction failed, see bellow for more information.
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        {this.state.inProgress && (
                            <button className={"btn btn-primary"}>
                               <LoadingSpinner />
                            </button>
                        )}
                        {this.state.batch && (

                            <table className={"table table-striped"}>
                                <thead>
                                <tr>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>ID</td>
                                        <td>{this.state.batch.batchId && this.state.batch.batchId}</td>
                                    </tr>
                                    <tr>
                                        <td>Status</td>
                                        <td>{this.state.batch.status}</td>
                                    </tr>
                                    <tr>
                                        <td>Number Of transactions</td>
                                        <td>{this.state.batch.rows && this.state.batch.rows.length}</td>
                                    </tr>
                                    <tr>
                                        <td>Type</td>
                                        <td>{this.state.batch.transactionType && this.state.batch.transactionType}</td>
                                    </tr>
                                    <tr>
                                        <td>Comment</td>
                                        <td>{this.state.batch.comment && this.state.batch.comment}</td>
                                    </tr>
                                    {this.state.batch.error && (
                                        <tr>
                                            <td>Error</td>
                                            <td>{this.state.batch.error}</td>
                                        </tr>
                                    )}
                                    {this.state.batch.errorMessage && (
                                        <tr>
                                            <td>Error Message</td>
                                            <td>{this.state.batch.errorMessage}</td>
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                        )}


                        <h5>Transactions</h5>
                        <table className={"table table-striped"}>
                            <thead>
                            <tr>
                                <th>User Id</th>
                                <th>Amount</th>
                                <th>Currency</th>
                                <th>Transaction ID</th>
                            </tr>
                            </thead>
                            <tbody>
                            { this.state.batch && this.state.batch.rows && this.state.batch.rows.map((r : TxBatchRowResult,index : number) => {
                                return (
                                    <tr key={"indx"+index}>

                                        <td>{r.row && r.row.userId}</td>
                                        <td>{r.row && r.row.amount}</td>
                                        <td>{r.row && r.row.currency}</td>
                                        <td>{r.txId != 0 && r.txId}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
        );
    }

}

export default withRouter(BatchTransactionView);
