import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {HttpService} from "../http/HttpService";


export class MetricsService extends AbstractAdminEntityService<any,any> implements MetricsService {
    constructor(http: HttpService) {
        super("/analytics", http);
    }

    async getCount(type:string,product?:string|null,currency?:string|null, from?:string, to?:string) {
        let prod = product != undefined ? product : null;
        let curr = currency != undefined ? currency : null;

        if(from === undefined && to === undefined && prod === null && curr === null) {
            return this.http.get(`${this.entityPath}/count/${type}`);
        }

        if(from !== undefined && to !== undefined && prod != null) {
            return this.http.get(`${this.entityPath}/count/${type}?product=${prod}&currency=${curr}&from=${from}&to=${to}`);
        } else if(from !== undefined && to !== undefined && prod === null) {
            return this.http.get(`${this.entityPath}/count/${type}?from=${from}&to=${to}`);
        } else {
            return this.http.get(`${this.entityPath}/count/${type}?currency=${curr}`);
        }
    }

    async getUniqueUsersCount(type: string, from?: string, to?: string) {

        if (from !== undefined && to !== undefined) {
            return this.http.get(`${this.entityPath}/users/${type}?from=${from}&to=${to}`);
        } else {
            return this.http.get(`${this.entityPath}/users/${type}`);
        }
    }

    async getSum(type:string,product?:string|null,currency?:string|null, from?:string, to?:string) {
        let prod = product != undefined ? product : null;
        let curr = currency != undefined ? currency : null;

        if(from !== undefined && to !== undefined && prod != null) {
            return this.http.get(`${this.entityPath}/sum/${type}?product=${prod}&currency=${curr}&from=${from}&to=${to}`);
        } else if(from !== undefined && to !== undefined && prod === null) {
            return this.http.get(`${this.entityPath}/sum/${type}?currency=${curr}&from=${from}&to=${to}`);
        } else {
            return this.http.get(`${this.entityPath}/sum/${type}?currency=${curr}`);
        }
    }
}