import React, {useEffect} from "react";
import {Form, useForm} from "../form/Form";
import {BonusConfig, UserRiskResult, UserRiskRuleCollection, UserRiskRuleType} from "../../http/protocol";
import {CardBox, RouterViewTemplate, useLoadingState} from "../RouterView";
import {getContainer} from "../../ioc/IOCSetup";
import {Link, useHistory, useParams} from "react-router-dom";
import {FormInput} from "../form/FormInput";
import {FormGroup} from "../form/FormGroup";
import {Validators} from "../form/Validators";
import {BindModel, FormList, ModelBinding} from "../form/FormList";
import {SelectInput} from "../form/SelectInput";
import {Submit} from "../form/Submit";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {DeleteButtonModal} from "../../util/DeleteButtonModal";

export const EditUserRiskCollection = () => {
    const service = getContainer().getUserRiskCollectionService();
    const [form, formListener, setForm] = useForm<UserRiskRuleCollection>({}, ()=>{
        return service.save(form.model);
    })
    const params = useParams<{id : string}>()
    const edit = params.id == "new";
    const history = useHistory();
    const status = useLoadingState(()=>{
        if(params.id == "new") {
            return Promise.resolve();
        } else {
            return service.getEntity(params.id).then(r => {
                setForm({ model : r, validationMap : {}});
            })
        }
    },[])
    const addRule = () => {
        let rules = (form.model.rules ?? []).concat([{}]);
        setForm({model : {...form.model, rules : rules}, validationMap : form.validationMap });
    }
    const deleteCollection = () => {
        if(form.model.id) {
            return getContainer().getUserRiskCollectionService().remove("" + form.model.id).then(r => {
                history.push("/user-risk-rules");
            });
        }
        return Promise.reject()
    }

    const selectableTypes = Object.values(UserRiskRuleType).filter(t => t != "ALWAYS_SUCCESS").sort()

    return (
        <RouterViewTemplate status={status} title={edit ? "Edit Rules" : "Create Rules"}>
            <div style={{display: "flex", justifyContent : "end", margin : "10px 0"}}>
                <DeleteButtonModal onDelete={deleteCollection} label={"Delete Rules Collection"} className={"btn btn-danger pull-right"}/>
            </div>
            <Form formListener={formListener} modelObject={form.model} validationMap={form.validationMap}>
                <FormSubmitError />
                <FormSubmitSuccess text={"Success!"} onClose={() => history.push("/user-risk-rules")}/>
                <CardBox>
                    {!edit && (
                        <>
                            <FormGroup model={"id"}>
                                <label>Id</label>
                                <FormInput  className={"form-control"} disabled={true} model={"id"} />
                            </FormGroup>
                            <div className="hr-line-dashed"></div>
                        </>
                    )}
                    <FormGroup model={"Name"}>
                        <label>Name</label>
                        <FormInput className={"form-control"}  model={"name"} validators={[Validators.required()]}/>
                    </FormGroup>
                    <div className="hr-line-dashed"></div>
                    <FormGroup model={"description"}>
                        <label>Description</label>
                        <FormInput className={"form-control"}model={"description"} />
                        <span className={"help-block"}>
                            Optional description
                        </span>
                    </FormGroup>
                </CardBox>

                    <h4>Rules</h4>
                        <div className="hr-line-dashed"></div>
                        <FormList model={"rules"}>
                            <CardBox>
                            <div className={"col-sm-10"}>
                                <BindModel render={(b : ModelBinding<UserRiskResult>)=>{
                                    return <a onClick={()=>b.removeCurrentItem()} className={"btn btn-danger"} style={{ float : "right"}}>Remove</a>
                                }}/>
                            </div>
                            <div className="hr-line-dashed"></div>
                            <FormGroup>
                                <label>Type</label>
                                <SelectInput className={"form-control"} validators={[Validators.required()]} values={selectableTypes} model={"type"}/>
                            </FormGroup>
                            <div className="hr-line-dashed"></div>
                            <FormGroup>
                                <label>Result</label>
                                <SelectInput className={"form-control"} validators={[Validators.required()]} values={Object.values(UserRiskResult)} model={"result"}/>
                                <span className={"help-block"}>
                                        If this rule matches user data what should be the result
                                    </span>
                            </FormGroup>
                            <div className="hr-line-dashed"></div>
                            <FormGroup>
                                <div className={"col-12"}>
                                    <label>
                                        <FormInput id="allowMissingData" type={"checkbox"} model={"allowMissingData"}/>
                                        Allow missing data
                                    </label>


                                </div>
                                <span className={"help-block"}>
                                        If the rule should be bypassed if user data it needs to evaluate is missing.
                                    </span>
                            </FormGroup>
                            <div className="hr-line-dashed"></div>
                        </CardBox>
                    </FormList>
                    <FormGroup>
                        <a className={"btn btn-primary"} onClick={addRule}>
                            Add Rule
                        </a>
                    </FormGroup>
                    <br/>
                <CardBox>
                    <Submit className={"btn btn-primary"}>Save Rules</Submit>
                </CardBox>
            </Form>
        </RouterViewTemplate>
    )
}