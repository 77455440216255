import * as React from "react";

import {AccountBalanceResult, UserBonusView} from "../../http/protocol";
import {NavLink} from "react-router-dom";
import { UserBonusService } from "../../services/UserBonusService";
import {getContainer} from "../../ioc/IOCSetup";
import {t} from "../../i18n";


interface Props {
    userId? : number
    accounts?: AccountBalanceResult[]
    activeBonuses?: UserBonusView[]
}
export class AccountBalances extends React.Component<Props,{ }>{

    constructor(props : Props) {
        super(props);
        this.state = { activeBonuses: [] };
    }

    async componentDidMount(): Promise<void> {
    }

    findParachateBonusBalance(balance: AccountBalanceResult) {
        const role = balance.accountAttributes?.['ROLE']

        if (role !== 'MAIN') {
            return null;
        }

        const matchingBonus = this.props.activeBonuses?.find(b => b.currency == balance.balance?.currencyCode)
        return matchingBonus?.parachuteBonusBalance
    }


    getAccounts(targetAccounts:AccountBalanceResult[], color : string) {
        if(!targetAccounts  || targetAccounts.length == 0) {
            return <div className={"col-12"}><span>No accounts found for user</span></div>
        }
        return targetAccounts.map( account => {
            if(!account.balance) {
                return <span>{t(`status.na`)}</span>
            }

            const parachuteBalance = this.findParachateBonusBalance(account)

            return (
                <div key={account.accountId} className="col-6">

                    <NavLink to={"/account/"+account.accountId} className={"account-balance-button " + color}>
                            <div className={"row"}>
                                <div className="col-3">
                                    <span style={{marginTop : "0"}} className="font-bold">{account.balance.currencyCode}</span>
                                </div>
                                <div className="col-9 text-right">
                                    <span style={{marginTop : "0"}} className="font-bold">
                                        {account.balance.amount && account.balance.amount.toLocaleString("en",{maximumFractionDigits:8, minimumFractionDigits:2})}
                                        {parachuteBalance  &&
                                            <span>
                                                &nbsp;({parachuteBalance})
                                            </span>
                                        }
                                    </span>
                                </div>
                            </div>

                    </NavLink>
                </div>

            )
        })
    }

    render() {
        var mainAccounts = this.getAccountsByRole("MAIN");
        return (
            <React.Fragment>
                <div className={"card-box"}>
                    <h4>{t(`title.mainAccountBalances`)}</h4>
                    <p className="help">{t(`helpText.mainAccountBalances`)}</p>
                    <div className="hr-line-dashed"></div>
                    <div className="row">
                        {this.getAccounts(mainAccounts, "bg-success")}
                    </div>
                </div>

                {this.getBonusAccounts()}
            </React.Fragment>
        );
    }

    private getBonusAccounts():any {
        var bonusAccounts = this.getAccountsByRole("BONUS");
        if (bonusAccounts.length == 0) {
            return false;
        }
        return (

            <div className="card-box">
                <h4>{t(`title.bonusAccountBalances`)}</h4>
                <div className="hr-line-dashed"></div>
                <div className="row">
                    {this.getAccounts(bonusAccounts, "bg-warning")}
                </div>
            </div>


        )
    }


    private getAccountsByRole(role:string): AccountBalanceResult[] {
        var found : AccountBalanceResult[] = [];
        if(!this.props.accounts){
            return [];
        }
        this.props.accounts.forEach( (account) => {
            if (account.accountAttributes != null && account.accountAttributes["ROLE"] == role) {
                found.push(account);
            }})
        return found;
    }
}
