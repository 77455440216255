import {LoadingViewState, RouterView} from "../RouterView";
import {FieldSetListener, Form, FormListener, SimpleFieldSet} from "../form/Form";
import {CasinoLobbyCategory, CasinoPage, CasinoPageTab} from "../../http/protocol";
import {RouteComponentProps} from "react-router";
import {AdminCasinoPageService} from "../../services/AdminCasinoPageService";
import {getContainer} from "../../ioc/IOCSetup";
import * as React from "react";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Validators} from "../form/Validators";
import {CasinoCategoryService} from "../../services/CasinoCategoryService";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

import {useState} from "react";
import {Submit} from "../form/Submit";
import {Modal} from "react-bootstrap";
import EditCasinoCategoryComponent from "./EditCasinoCategoryComponent";

interface Translation { [index: string]: string }
export const TranslationPanel = (props : { translations? : Translation, addTranslation : (key : string, value : string)  => void, removeTranslation : (key : string) => void}) => {
    const [translation, setTranslation] = useState({ key : "", value : ""})

    const add = () => {
        if(translation.key.length > 0 && translation.value.length > 0) {
            props.addTranslation(translation.key, translation.value);
            setTranslation({key : "", value:  ""});
        }
    }

    return (
        <div className={"tab-translations"}>
            <h5>Translations</h5>
            {props.translations && Object.entries(props.translations).map((e) => (
                <div className={"form-group row"}>
                   <div className={"col-md-3"}>
                       <div className={"form-control"}>
                           {e[0]}
                       </div>
                   </div>
                    <div className={"col-md-5"}>
                        <div className={"form-control"}>
                            {e[1]}
                        </div>
                    </div>
                    <div className={"col-md-4"}>
                        <a className={"btn btn-danger"} onClick={() => props.removeTranslation(e[0])}>Remove</a>
                    </div>
                </div>
            ))}
            <div className={"form-group row"}>
                <div className={"col-md-3"}>
                    <input className={"form-control"} placeholder="Language Code" type={"text"} value={translation.key} onChange={(e) => setTranslation({ key : e.target.value, value:  translation.value})}/>
                </div>
                <div className={"col-md-5"}>
                    <input className={"form-control"} placeholder="Translated Name" type={"text"} value={translation.value} onChange={(e) => setTranslation({ key : translation.key, value:  e.target.value})}/>
                </div>
                <div className={"col-md-4"}>
                    <a className={"btn btn-primary"} onClick={()=>add()}>Add</a>
                </div>
            </div>
        </div>
    )
}

interface Props extends RouteComponentProps {

}
interface State extends LoadingViewState {
    cp? : CasinoPage;
    validationMap? : any;
    categories? : CasinoLobbyCategory[];
    submitting? : boolean;
    error?: boolean;
    success?: boolean;
}

export class EditCasinoPage extends RouterView<Props,State> implements FormListener<CasinoPage>, FieldSetListener<CasinoPage>{

    service : AdminCasinoPageService;
    casinoCategoryService : CasinoCategoryService;
    constructor(props : Props) {
        super(props);
        this.state = { loading : true, validationMap : {}, cp : {}, categories : [], submitting : false, error : false, success : false};
        this.service = getContainer().getCasinoPageService();
        this.casinoCategoryService = getContainer().getCasinoCategoryService();

    }
    getTitle(): string {
        return "Casino Page";
    }
    formDidUpdate(formModel : CasinoPage, validationMap? : any)  {
        this.setState({cp : formModel, validationMap : validationMap });
    }
    loadContent(): Promise<any> | void {
        let id = (this.props.match.params as any).id;
        if(id === "new") {
            return this.casinoCategoryService.list({limit : 1000, offset: 0}).then((list) => {
                this.setState({ categories : list.items ?? [] });
            })
        } else {
            return this.service.getEntity(id).then((cp) => {
                return this.casinoCategoryService.list({limit : 1000, offset: 0}).then((list) => {
                    this.setState({ cp : cp, categories : list.items ?? [] });
                })

            })
        }
    }

    onSubmit(): void | Promise<any> {

    }
    submit() {
        if(this.state.cp) {
            this.setState({ submitting : true });
            this.service.save(this.state.cp)
                .then(s => {
                    this.setState({ cp : s, submitting : false, success : true, error : false})
                })
                .catch((e) => {
                    this.setState({submitting : false, error : true, success : false})
                });
        }

    }
    addTab(tab : CasinoPageTab) {
        let tabs : CasinoPageTab[] = this.state.cp?.tabs?.concat() ?? [];
        tabs.push(tab);

        this.setState({...this.state, cp: { ...this.state.cp, tabs : tabs}})
    }
    addCategory(c : CasinoLobbyCategory, index : number) {

        let tab = this.state.cp?.tabs?.[index];
        console.log("Add category = ", c, index, tab);
        if(this.state.cp && tab) {
            tab = {...tab};
            tab.categories = (tab.categories ?? []).concat();
            tab.categories?.push(c);
            let tabs : CasinoPageTab[] = (this.state.cp.tabs ?? []).concat();
            tabs.splice(index, 1, tab);
            this.setState({ cp : {...this.state.cp, tabs : tabs}});
        }
    }
    removeCategory(tabIndex : number, categoryIndex : number) {
        let tab = this.state.cp?.tabs?.[tabIndex];

        if(this.state.cp && tab) {
            tab = {...tab};
            tab.categories = (tab.categories ?? []).concat();
            tab.categories?.splice(categoryIndex,1);
            let tabs : CasinoPageTab[] = (this.state.cp.tabs ?? []).concat();
            tabs.splice(tabIndex, 1, tab);
            this.setState({ cp : {...this.state.cp, tabs : tabs}});
        }
    }
    removeTab(tabIndex : number) {
        let tabs = (this.state.cp?.tabs ?? []).concat();
        tabs.splice(tabIndex,1);
        this.setState({ cp : {...this.state.cp, tabs : tabs}});
    }
    onDragEnd(result : any)  {
        // dropped outside the list
        if (!result.destination || !result.source) {
            return;
        }
        if(result.destination.index === result.source.index) {
            return;
        }
        if(!this.state.cp?.tabs || this.state.cp.tabs.length < 2) {
            return;
        }
        let from = result.source.index;
        let to = result.destination.index;

        let tabs = this.state.cp.tabs.concat();
        let toElement = tabs[to];
        tabs[to] = tabs[from];
        tabs[from] = toElement;
        this.setState({ cp : {...this.state.cp, tabs : tabs}});

    }

    updateTab(tab : CasinoPageTab, index : number) {
        let tabs = (this.state.cp?.tabs ?? []).concat();
        tabs[index] = tab;
        this.setState({ cp : {...this.state.cp, tabs : tabs}});
    }
    renderContent(): JSX.Element {


       return (
           <div>
               {this.state.error && (
                   <Modal show={true}>
                       <Modal.Header>
                           Something went wrong
                       </Modal.Header>
                       <Modal.Body>
                            <div className={"alert alert-danger"}>Unable to save casino page</div>
                            <a className={"btn btn-primary"} onClick={()=> this.setState({ error : false })}>Continue</a>
                       </Modal.Body>
                   </Modal>
               )}
               {this.state.success && (
                   <Modal  show={true}>
                       <Modal.Header>
                           Success!
                       </Modal.Header>
                       <Modal.Body>
                           <div className={"alert alert-info"}>Casino Page is now saved!</div>
                           <a className={"btn btn-primary"} onClick={()=> this.setState({ error : false, success : false })}>Continue</a>
                       </Modal.Body>
                   </Modal>
               )}
               <div className="wrapper wrapper-content animated fadeInRight">
                   <Form id={"mainForm"} className="form form-horizontal" validationMap={this.state.validationMap} modelObject={this.state.cp} formListener={this}>
                       <div className="row">
                           <div className="col-lg-12">
                               <div className="card-box">
                                   <h5>Meta Data</h5>
                                   <div className="ibox-content">
                                       <FormGroup className="form-group">
                                           <label className="col-sm-2 control-label">Name</label>
                                           <div className="col-sm-10">
                                               <FormInput  model="name" validators={[Validators.required()]} type="text"  className="form-control" />
                                           </div>
                                       </FormGroup>
                                       <div className="hr-line-dashed"></div>
                                       <FormGroup className="form-group">
                                           <label className="col-sm-2 control-label">Default Page</label>
                                           <div className="col-sm-10">
                                               <FormInput  model="defaultPage" type="checkbox" />
                                           </div>
                                       </FormGroup>
                                       <div className="hr-line-dashed"></div>
                                       <FormGroup className="form-group">
                                           <label className="col-sm-12 control-label">Hide Country Restricted Games and Providers</label>
                                           <div className="col-sm-10">
                                               <FormInput  model="hideCountryRestrictedGames" type="checkbox" />
                                           </div>
                                       </FormGroup>
                                       <div className="hr-line-dashed"></div>
                                       <Submit style={{display: "none"}} type={"submit"} id={"submitButton"}></Submit>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </Form>
                   <div className="row">
                       <div className="col-lg-12">
                           <div className="card-box">
                               <h5>Add Casino Page Tab</h5>
                               <div className="ibox-content">
                                   <AddCasinoPageTab add={(tab) => this.addTab(tab)}/>
                               </div>
                           </div>
                       </div>
                   </div>

                   <div className="row">
                       <div className="col-lg-12">
                           <div className="card-box">
                               <h5>Tabs</h5>
                               <div className="ibox-content">
                                   <DragParent onDragEnd={(res) => this.onDragEnd(res)} id={"tabsDrop"}>
                                       {this.state.cp?.tabs?.map((tab,index) => (
                                           <Draggable key = {"tab"+index} draggableId={"tab"+index} index={index}>
                                               {(provided,snapshot)=> (
                                                   <div ref={provided.innerRef} {...provided.draggableProps}>
                                                       <EditCasinoTab  tab={tab} categories={this.state.categories}
                                                                       dragHandleProps={provided.dragHandleProps}
                                                                       index={index}
                                                                       addCategory={(c)=> this.addCategory(c, index)}
                                                                       removeCategory={(i) => this.removeCategory(index,i)}
                                                                       onChange={(t)=>this.updateTab(t,index)}
                                                                       removeTab={()=>this.removeTab(index)}
                                                       />
                                                   </div>

                                               )}
                                           </Draggable>



                                       ))}
                                   </DragParent>
                                   {(this.state.cp?.tabs?.length ?? 0) == 0 && (
                                       <div className={"alert alert-info"}>Currently no tabs for this casino page.</div>
                                   )}
                               </div>
                           </div>
                       </div>
                   </div>

                   <div className="row">
                       <div className="col-lg-12">
                           <div className="card-box">
                               <h5>Add Casino Page Tab</h5>
                               <div className="ibox-content">
                                   {!this.state.submitting &&  <button className={"btn btn-primary"} onClick={()=> this.submit()}>Save Page</button>}
                                   {this.state.submitting &&  <button className={"btn btn-primary"}>Please wait...</button>}
                               </div>
                           </div>
                       </div>
                   </div>




               </div>
           </div>
       )
    }

}
export const DragItem = (props : {children : any, index : number}) => {
    let index = props.index;
    const getItemStyle = (isDragging : boolean, draggableStyle : any) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        ...draggableStyle
    });
    return (
        <Draggable key = {"tab"+index} draggableId={"tab"+index} index={index}>
            {(provided,snapshot)=> (
                <div ref={provided.innerRef}
                     {...provided.draggableProps}
                     {...provided.dragHandleProps}
                     style={getItemStyle(
                         snapshot.isDragging,
                         provided.draggableProps.style
                     )}>

                </div>

            )}
        </Draggable>
    )
}

export const DragParent = ( props : {children : any, onDragEnd : (res : any) => void, id : string}) => {
    const getListStyle = (isDraggingOver : boolean)  => {
        return {
            background: isDraggingOver ? "lightblue" : "transparent",
            width: "100%"
        }
    }
    return (
        <DragDropContext onDragEnd={props.onDragEnd}>
            <Droppable droppableId={props.id}>
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                    >
                        <div className={"container-fluid"}>
                            {props.children}
                        </div>
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}
interface EditCasinoTabState {
    expanded?: boolean;
    selected?: string;
    editTranslations?: boolean;
    newCategoryToTab?: string;
}
interface EditCasinoTabProps {
    tab? : CasinoPageTab;
    index : number;
    categories? : CasinoLobbyCategory[];
    addCategory?: (c : CasinoLobbyCategory) => void;
    removeCategory? : (index : number) => void;
    removeTab? : () => void;
    onChange? : (tab :CasinoPageTab) => void;
    dragHandleProps ? : any;
}
export class EditCasinoTab extends React.Component<EditCasinoTabProps, EditCasinoTabState> {
    constructor(props :EditCasinoTabProps) {
        super(props);
        this.state = { expanded : false, editTranslations : false, newCategoryToTab : undefined};
    }
    toggleEditTranslations() {
        this.setState({ editTranslations : !this.state.editTranslations });
    }
    addCategory() {
        if(!this.state.selected) {
            return;
        }
        let category = this.props.categories?.find(c => ""+c.id == this.state.selected);
        if(category) {
            this.props.addCategory?.(category);
        }

    }
    updateSelected(value : string) {
        this.setState({ selected : value});
    }
    removeCategory(index : number) {
        this.props.removeCategory?.(index);
    }
    onNameChange(e : any) {
        let name = e.target.value;
        let tab = {...this.props.tab, name : name};
        this.props.onChange?.(tab);
    }
    onDragEnd(result : any) {
        // dropped outside the list
        if (!result.destination || !result.source) {
            return;
        }
        if(result.destination.index === result.source.index) {
            return;
        }
        if(!this.props.tab?.categories || this.props.tab.categories.length < 2) {
            return;
        }

        let items = this.props.tab.categories
        const [removed] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, removed);

        let tab = {...this.props.tab, categories : items};
        this.props.onChange?.(tab);
    }
    addTranslation(key : string, value : string) {
        let tab = {...this.props.tab};
        if(!tab.translations) {
            tab.translations = {};
        }
        tab.translations[key] = value;
        this.props.onChange?.(tab);
    }
    removeTranslation(key : string) {
        let tab = {...this.props.tab};
        if(tab?.translations?.[key]) {
            delete tab.translations[key];
        }
        this.props.onChange?.(tab);
    }
    onCategoryAdded(c : CasinoLobbyCategory) {
        let tab = {...this.props.tab};
        if(!tab.translations) {
            tab.translations = {};
        }
        tab.categories = (tab.categories ?? []).concat();
        if(this.state.newCategoryToTab == "new") {
            tab.categories.push(c);
        } else {
            let catIndex = tab.categories.findIndex(c => c.id == this.state.newCategoryToTab);
            tab.categories.splice(catIndex,1,c);
        }
        this.setState({ newCategoryToTab : undefined})
        this.props.onChange?.(tab);
    }
    render() {
        return (
            <>
                {this.state.newCategoryToTab && (
                   <Modal show={true} size={"xl"} onHide={()=> this.setState({ newCategoryToTab : undefined })}>
                       <Modal.Body>
                           <EditCasinoCategoryComponent id={this.state.newCategoryToTab} onSaveSuccess={(c) => this.onCategoryAdded(c)} />
                       </Modal.Body>
                   </Modal>
                )}
                {!this.state.expanded && (
                    <div className={" row tab-row"}>
                        <div className={"expand col-1"} >
                            <i className={"fe-menu"} {...this.props.dragHandleProps}></i>
                        </div>
                        <div className={"name col-6"} onClick={()=> this.setState({expanded : true})}>
                            <i className={"fe-chevron-right"}></i> {this.props.tab?.name}
                        </div>
                        <div className={"action col-5"} style={{textAlign : "right"}}>
                            <a className={"btn btn-danger"} onClick={()=>this.props.removeTab?.()}>Remove</a>
                        </div>
                    </div>
                )}
                {this.state.expanded && (
                    <div className={"row tab-row"}>
                        <div className={"col-12"}>
                            <div className={"row tab-row-inner"}>
                                <div className={"expand col-1"} >
                                    <i className={"fe-menu"} {...this.props.dragHandleProps}></i>
                                </div>
                                <div className={"name col-6"} onClick={()=> this.setState({expanded : false})}>
                                    <i className={"fe-chevron-down"}></i> {this.props.tab?.name}
                                </div>
                                <div className={"action col-3"}>
                                    <a className={"btn btn-danger"}  onClick={()=>this.props.removeTab?.()}>Remove</a>
                                </div>
                            </div>
                            <div className={"tab-container"} style={{ padding: "20px"}}>
                                <div className="form-group">
                                    <label className="col-sm-2 control-label">Name</label>
                                    <div className={"row"}>
                                        <div className="col-sm-8">
                                            <input value={this.props.tab?.name} type="text"  className="form-control" onChange={(e)=> this.onNameChange(e)}/>
                                            {this.state.editTranslations && <TranslationPanel
                                                translations={this.props.tab?.translations}
                                                addTranslation={(key,value) => this.addTranslation(key,value)}
                                                removeTranslation={(key) => this.removeTranslation(key)}/> }
                                        </div>
                                        <div className={"col-sm-4"}>
                                            <a className={"btn btn-secondary"} onClick={()=> this.toggleEditTranslations()}>Translations {this.state.editTranslations ? <i className={"fe-arrow-up-circle"}></i> : <i className={"fe-arrow-down-circle"}></i>}</a>
                                        </div>

                                    </div>

                                </div>
                                <div className="hr-line-dashed"></div>
                                <h4>Categories</h4>
                                <div className={"category-container"}>
                                    <DragParent onDragEnd={(res) => this.onDragEnd(res)} id={"categoryDrop"+this.props.index}>
                                        {this.props.tab?.categories?.map((c,index)=> (
                                            <Draggable key = {"tab"+index} draggableId={"tab"+index} index={index}>
                                                {(provided,snapshot)=> (
                                                    <div ref={provided.innerRef} {...provided.draggableProps}>
                                                        <div key={c.id} className={"row category-row"}>
                                                            <div className={"col-1"}>
                                                                <i className={"fe-menu"} {...provided.dragHandleProps}></i>
                                                            </div>
                                                            <div className={"col-6"}>
                                                               {c.name} ({c.id})
                                                            </div>
                                                            <div className={"col-5"} style={{ textAlign : "right"}}>
                                                                <a className={"btn btn-primary"} target={"_blank"} onClick={()=> this.setState({newCategoryToTab : ""+c.id})} style={{marginRight : "5px"}}>Edit</a>
                                                                <a className={"btn btn-danger"} onClick={()=>this.removeCategory(index)}>Remove</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    )}
                                            </Draggable>

                                        ))}
                                    </DragParent>

                                </div>
                                <div className={"add-category-container row"}>
                                    <div className={"col-md-4"}>
                                        <select className={"form-control"} onChange={(e)=>this.updateSelected(e.target.value)}>
                                            <option>Select Category</option>
                                            {this.props.categories?.map(c => (
                                                <option value={c.id}>{c.name} ({c.id})</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className={"col-md-4"}>
                                        <a className={"btn btn-primary"} onClick={()=> this.addCategory()}>Add</a>
                                        <a className={"btn btn-primary"} style={{marginLeft : "10px"}} onClick={()=> this.setState({newCategoryToTab : "new"})}>New Category</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                )}
            </>
        )
    }

}
interface AddCasinoPageTabState {
    tab? : CasinoPageTab;
    validationMap? : any;
}
interface AddCasinoPageProps {
    add? : (tab : CasinoPageTab) => void;
}
export class AddCasinoPageTab extends  React.Component<AddCasinoPageProps, AddCasinoPageTabState> implements FieldSetListener<CasinoPageTab> {
    constructor(props : {}) {
        super(props);
        this.state = {tab : {}, validationMap : {}}
    }
    formDidUpdate(formModel: CasinoPageTab, validationMap?: any): void {
        this.setState({ tab : formModel, validationMap : validationMap});
    }
    add() {
        if(this.state.tab) {
            this.props.add?.(this.state.tab);
        }
    }
    render() {
        return (
            <SimpleFieldSet formListener={this} modelObject={this.state.tab} validationMap={this.state.validationMap}>
                <div className="row">
                    <div className="col-4">
                        <FormInput placeholder={"Tab Name"} model="name" validators={[Validators.required()]} type="text"  className="form-control" />
                    </div>
                    <div className={"col-4"}>
                        <a className={"btn btn-primary"} onClick={() => this.add() }>Add</a>
                    </div>
                </div>
            </SimpleFieldSet>
        )
    }
}
