import * as React from "react";
import {Modal} from "react-bootstrap";
import {Alert} from "../ui/form/Alert";


interface State {
    show? : boolean
    error? : string;
    inProgress? : boolean;
    errorMessage?: string;
}
interface Props {
    id?: string;
    iconClassName? : string;
    label? : string;
    description? : string,
    onDelete : () => Promise<any> | undefined,
    onCancel? : () => void,
    className?:string,
    show?: boolean,
    disabled?: boolean
}
export class DeleteButtonModal extends React.Component<Props,State>{

    unmounted? : boolean;
    key: string = `delete-${Math.floor(Math.random() * 100000)}`;
    constructor(props : Props){
        super(props);
        this.state = {show:false, inProgress : false};
        this.unmounted = false;
    }

    cancel() {
        if(!this.unmounted) {
            this.setState({show : false, error : undefined });
        }
        if(this.props.onCancel) {
            this.props.onCancel();
        }
    }
    componentWillUnmount() {
      this.unmounted = true;
    }

    doDelete() {
        if(this.state.inProgress == true) {
            return;
        }
        console.log("doDelete before", this.props.onDelete);
        if(this.props.onDelete) {
            this.setState({ inProgress: true });
            let promise = this.props.onDelete();
            if(promise) {
                promise.then((closeCallback? : ()=>void)=>{
                    console.log("doDelete resolve");

                    if(typeof closeCallback === "function") {
                        setTimeout(()=>closeCallback(), 50);
                    }
                    if(!this.unmounted) {
                        this.setState({show : false, error : undefined, inProgress : false});
                    }
                }).catch(err => {
                    console.log("reject resolve", err);
                    if(!this.unmounted) {
                        let errMsg = typeof err === "string" ? err : typeof err.message === "string" ? err.message : undefined;
                        this.setState({show : true, error : err, inProgress : false, errorMessage : errMsg});
                    }
                });
            }

        }
    }
 
    getDeleteInfo() : any{
        if(this.state.error != null)  {
            return false;
        }
        return <span>You are a about to {this.getLabel()} {this.props.description}</span>
    }
    getDeleteError() : any {
        if(this.state.error == null)  {
            return false;
        }
        let text = "Unable to " + this.getLabel() + this.props.description;
        if(this.state.errorMessage) {
            text = this.state.errorMessage;
        }
        return <Alert type="danger" text={text} />
    }
    getActions() {
        if(this.state.error != null) {
            return (
                <a className="btn btn-default" onClick={this.cancel.bind(this)}>Continue</a>
            );
        }
        return (
            <div style={{gap: "10px"}} className="d-flex">
                <button className="btn btn-secondary" onClick={this.cancel.bind(this)} disabled={this.state.inProgress}>Cancel</button>
                <button className="btn btn-danger" onClick={this.doDelete.bind(this)} disabled={this.state.inProgress}>{this.getLabel()}</button>
            </div>
        );
    }
    getLabel() {
        if(this.props.label) {
            return this.props.label;
        }
        return "Delete";
    }
    render(){
       return <>
            { (this.props.show == undefined  ||  this.props.show) &&
                <button type="button"
                        key={this.key}
                        disabled={this.props.disabled}
                        onClick={(e) => { e.stopPropagation(); this.setState({show : true } )}}
                        id={this.props.id ? this.props.id : ""}
                         className={this.props.className}>
                    <i className={this.props.iconClassName}></i> {this.getLabel()}
                </button> 
            }
            <Modal key={this.key} show={this.state.show} onHide={this.cancel.bind(this)}
                    aria-labelledby="contained-modal-title">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title">Are sure you want to {this.getLabel()}?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.getDeleteInfo()}
                    {this.getDeleteError()}
                </Modal.Body>
                <Modal.Footer>
                    {this.getActions()}
                </Modal.Footer>
            </Modal>
        </>
    }
}