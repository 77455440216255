import * as React from "react";
import {Fragment} from "react";
import {ApplicationState, UserPasswordStatus, UserStatus, UserStoreState} from "../user/UserStore";
import {connect} from "react-redux";
import {UserAuthenticationService} from "../services/UserAuthenticationService";
import {getContainer} from "../ioc/IOCSetup";
import {handleInvalidPasswordChange, UserActions} from "../user/UserActions";
import {LoadingSpinner} from "../util/LoadingSpinner";
import {enabledLanguages, t} from "../i18n";
import {getStore} from "../RootReducer";
import {AdminMfaProvider} from "../http/protocol";

interface State {
    username? : string;
    password? : string;
    status? : UserStatus;
    submitted? : boolean;
    loading? : boolean;
    repeatNewPassword?: string;
    newPassword?: string;
    showLanguageSelector?: boolean;
    otp?: string;
    mfaProvider?: AdminMfaProvider;
}
const selectLanguage = (code : string) => {
    document.location.search = "?lng="+code;
}
const mapStateToProps = (state : ApplicationState) : UserStoreState => {
    return state.user;
};

export class LoginForm extends React.Component<UserStoreState,State>{
    dashboardUserService : UserAuthenticationService;
    constructor(props : UserStoreState) {
        super(props);
        this.dashboardUserService = getContainer().getUserAuthenticationService();
        this.state = { loading : false, showLanguageSelector : false};
    }

    componentDidMount(): void {
        new UserActions().getUserInfo(false);
        this.showLoginBG();
        this.toggleSidebarState();
    }
    componentDidUpdate(prevProps: Readonly<UserStoreState>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.status != this.props.status) {
            if(this.props.status != UserStatus.LOGGED_IN) {
               this.showLoginBG();
            } else {
              this.hideLoginBG();
            }

        }
        if(prevProps.expandedMenu != this.props.expandedMenu) {
            this.toggleSidebarState();
        }
    }

    toggleSidebarState() {
        const bodyEl = document.getElementById("mainBody");
        if(!bodyEl) return;
        
        if(this.props.expandedMenu) {
            bodyEl.classList.remove("desktop-collapse-sidebar")
            bodyEl.classList.add("sidebar-enable");
        } else {
            bodyEl.classList.add("desktop-collapse-sidebar")
            bodyEl.classList.remove("sidebar-enable");
        }
    }

    showLoginBG() {
        let bodyEl = document.getElementById("mainBody");
        if(bodyEl) {
            bodyEl.classList.add("authentication-bg")
            bodyEl.classList.add("authentication-bg-pattern");
        }
    }
    hideLoginBG() {
        let bodyEl = document.getElementById("mainBody");
        if(bodyEl) {
            bodyEl.classList.remove("authentication-bg")
            bodyEl.classList.remove("authentication-bg-pattern");
        }
    }

    onSubmit(e : any) {
        e.preventDefault();
        if(this.state.username != null && this.state.password != null) {
          new UserActions().login(this.state.username, this.state.password);
        }
    }
    changePasswordAndLogin(e : any) {
        e.preventDefault();
        if(this.state.username != null && this.state.password != null && this.state.newPassword != null) {
            if(this.state.newPassword != this.state.repeatNewPassword) {
                handleInvalidPasswordChange();
            } else {
                new UserActions().changePasswordAndLogin(this.state.username, this.state.password, this.state.newPassword, this.state.otp);
            }
        }
    }
    loginWithVerificationCode(e : any ) {
        e.preventDefault();
        if(this.state.otp != null) {
            let user = getStore().getState().user;
            if(user.username && user.password) {
                new UserActions().login(user.username, user.password, this.state.otp);
            }
        }
    }

    changeUsername(e : React.FormEvent) {
        var value : string = (e.target as any).value;
        this.setState({ username : value });
    }
    updateRepeatNewPassword(e : React.FormEvent) {
        var value : string = (e.target as any).value;
        this.setState({ repeatNewPassword : value });
    }
    updateNewPassword(e : React.FormEvent) {
        var value : string = (e.target as any).value;
        this.setState({ newPassword : value });
    }
    updateVerificationCode(e : React.FormEvent) {
        var value : string = (e.target as any).value;
        this.setState({ otp : value });
    }
    changePassword(e : React.FormEvent) {
        var value : string = (e.target as any).value;
        this.setState({ password : value });
    }


    getErrorMessage() {
        if(this.props.status == UserStatus.LOGIN_ERROR) {
            return <div className="alert alert-warning">{t("login.error")}</div>
        }
        return <div/>;
    }

    isMailAdress(str : string) {
        return str && str.trim().indexOf("@") > -1;
    }

    getLoginForm() {
        if(this.props.passwordStatus == UserPasswordStatus.IN_PROGRESS) {
            return <LoadingSpinner />
        }
        const potentialEmail = (this.props.username && this.isMailAdress(this.props.username)) ? this.props.username : null;
        if(this.props.passwordStatus == UserPasswordStatus.OTP_REQUIRED || this.props.passwordStatus == UserPasswordStatus.INVALID_OTP) {
            return (
                <form className="m-t" role="form" onSubmit={this.loginWithVerificationCode.bind(this)}>
                    <div className="alert alert-warning">
                        Two-factor authentication code is required
                    </div>
                    {this.props.mfaProvider == AdminMfaProvider.EMAIL &&
                        <div className="alert alert-info">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div style={{wordBreak: 'break-word'}}>
                                        <div>We've sent a code to your email</div>
                                        <strong>{potentialEmail}</strong>
                                        <div>Please check your inbox.</div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-envelope fa-2x"></i>
                                </div>
                            </div>
                        </div>
                    }
                    {this.props.mfaProvider === AdminMfaProvider.AUTHENTICATOR_APP && (
                        <div className="alert alert-info">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div style={{wordBreak: 'break-word'}}>
                                        <div>Please enter the code from your authenticator app.</div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-shield-alt fa-2x"></i>
                                </div>
                            </div>
                        </div>
                    )
                    }
                    <div style={{display : "none"}}>
                        <input type={"text"} name={"username"} />
                        <input type={"password"} name={"password"}/>
                    </div>
                    {this.props.passwordStatus == UserPasswordStatus.INVALID_OTP && (
                        <div className={"alert alert-danger"}>
                           Invalid verification code, please try again
                        </div>
                    )}
                    <div className="form-group mb-3">
                        <label>Verification Code</label>
                        <input type="text" name="verificationCode"  id="verificationCode" autoComplete="off" className="form-control" onChange={this.updateVerificationCode.bind(this)} placeholder="verification code" />
                    </div>

                    <div className={"form-group mb-0 text-center"}>
                        <button type="submit" className="btn btn-primary btn-block">Login</button>
                    </div>

                </form>
            )
        } else if(this.props.passwordStatus != UserPasswordStatus.NONE) {
            const potentialEmail = (this.props.username && this.isMailAdress(this.props.username)) ? this.props.username : null;
            return (
                <form className="m-t" role="form" onSubmit={this.changePasswordAndLogin.bind(this)}>
                    <div className="alert alert-warning">
                        You are required to change your password
                    </div>
                    {
                        this.props.mfaProvider == AdminMfaProvider.EMAIL &&
                        <div className="alert alert-info">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div style={{wordBreak: 'break-word'}}>
                                        <div>We've sent a code to your email</div>
                                        <strong>{potentialEmail}</strong>
                                        <div>Please check your inbox.</div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-envelope fa-2x"></i>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.props.mfaProvider === AdminMfaProvider.AUTHENTICATOR_APP && (
                            <div className="alert alert-info">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <div style={{ wordBreak: 'break-word' }}>
                                            <div>Please enter the code from your authenticator app.</div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-shield-alt fa-2x"></i>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    {(this.props.passwordStatus == UserPasswordStatus.INVALID_PASSWORD_CHANGE || this.props.passwordStatus == UserPasswordStatus.INVALID_PASSWORD_CHANGE_OTP) && (
                        <div className={"alert alert-danger"}>
                            Invalid password, make sure your new password follow the password requirements
                        </div>
                    )}
                    <input type="text"  value="" style={{display:"none"}}/>
                    <input type="text" value="" style={{display:"none"}}/>
                    <input type="password" value="" style={{display:"none"}}/>
                    <div className="form-group mb-3">
                        <label>Password</label>
                        <input type="password" autoComplete="false" className="form-control" onChange={this.updateNewPassword.bind(this)} placeholder="New Password" required={true}/>
                        <span className={"help"}>
                            Password must be between 8-50 characters and at least one of the following: lowercase letter, uppercase letter and number
                        </span>
                    </div>
                    <div className="form-group mb-3">
                        <label>Repeat new password</label>
                        <input type="password" autoComplete="false"  className="form-control" onChange={this.updateRepeatNewPassword.bind(this)} placeholder="Repeat New Password" required={true}/>
                    </div>

                    {(this.props.mfaProvider == AdminMfaProvider.EMAIL || this.props.mfaProvider == AdminMfaProvider.AUTHENTICATOR_APP) && (
                        <div className="form-group mb-3">
                            <label>Verification Code</label>
                            <input type="text" autoComplete="false"  className="form-control" onChange={this.updateVerificationCode.bind(this)} placeholder="Verification code" required={true}/>
                        </div>
                    )}
                    <div className={"form-group mb-0 text-center"}>
                        <button type="submit" className="btn btn-primary btn-block">Change Password & Login</button>
                    </div>

                </form>
            )
        }

        if(this.state.loading == true) {
            return <LoadingSpinner/>;
        }
        return (
            <form className="m-t" role="form" onSubmit={this.onSubmit.bind(this)} style={{opacity : this.props.status == UserStatus.IN_PROGRESS ? 0.5 : 1}}>
                {this.getErrorMessage()}
                <div className="form-group mb-3">
                    <label>{t("login.email")}</label>
                    <input type="text" id={"username"} className="form-control" required={true} onChange={this.changeUsername.bind(this)}/>
                </div>
                <div className="form-group mb-3">
                    <label>
                        {t("login.password")}
                    </label>
                    <input type="password" id="password"  className="form-control" onChange={this.changePassword.bind(this)} required={true}/>
                </div>
                <div className={"form-group mb-0 text-center"}>
                    <button type="submit" id={"loginButton"} className="btn btn-primary btn-block">
                        <span>{t("login.login")}</span>
                    </button>
                </div>

            </form>
        );
    }
    render() {
        if(this.props.status == UserStatus.LOGGED_IN) {
            return <div/>;
        }
        let logoImage = undefined;
        console.log("this.props.globalModel", this.props.globalModel);
        let globalModel = this.props.globalModel;
        if(globalModel?.logo) {
            logoImage = globalModel.logo;
        }
        return (

            <Fragment>
                <div className="account-pages mt-5 mb-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-6 col-lg-5 col-xl-4">
                                {logoImage && (
                                    <div className={"login-logo"} style={{ backgroundImage : `url(${logoImage})`}}></div>
                                )}
                                <div className="card" style={{minWidth: '385px'}}>
                                    <div className="card-body p-4">
                                        {this.getLoginForm()}

                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-12 text-center">
                                        <p>
                                            <a className="text-white-50 ml-1" onClick={()=>this.setState({showLanguageSelector : true})}>Change language?</a>
                                            {this.state.showLanguageSelector && (
                                                <div  className={"card language-selector"}>
                                                    <div onClick={()=>this.setState({showLanguageSelector : false})}className={"close"} dangerouslySetInnerHTML={{__html : "&times"}}></div>
                                                    {enabledLanguages.map(l => <a key={l.code} href="#" onClick={()=> selectLanguage(l.code)}>{l.name}</a>)}
                                                </div>
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </Fragment>
        );
    }
}

export default connect(mapStateToProps)(LoginForm);