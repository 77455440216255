import {HttpService} from "../http/HttpService";
import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {CasinoLobbyCategory, ListCasinoLobbyCategoryResponse} from "../http/protocol";


export class CasinoCategoryService  extends AbstractAdminEntityService<CasinoLobbyCategory, ListCasinoLobbyCategoryResponse>  {

    constructor(http : HttpService) {
        super("/casino-category", http)
    }

}