import * as React from "react";

import {Form, FormListener} from "../form/Form";
import {FormInput} from "../form/FormInput";
import {Validators} from "../form/Validators";
import {Submit} from "../form/Submit";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {Collapsible} from "../Collapsible";
import {Currency, ManualPayoutRequest, Settings, TransferRequest} from "../../http/protocol";
import {getContainer} from "../../ioc/IOCSetup";
import {SelectInput} from "../form/SelectInput";
import {AffiliatesService} from "../../services/AffiliatesService";

interface Props {
    transfer : (manualPayoutRequest : ManualPayoutRequest) => Promise<void>;
    affiliateId?:number,
    payoutCurrency: string
}
interface State {
    transactionForm? : ManualPayoutRequest;
    validationMap? : any;
    affiliateId?:number
}

export class AffiliateTransactionPanel extends React.Component<Props, State> implements FormListener<ManualPayoutRequest> {
    affiliatesService: AffiliatesService;
    constructor(props : Props) {
        super(props);
        this.affiliatesService = getContainer().getAffiliatesService();
        this.state = {
            transactionForm: {},
            validationMap: {}
        };
        console.log("this.props.affiliateId " + this.props.affiliateId)
    }

    onSubmit(): Promise<any> {
        if (this.state.transactionForm) {
            let request={...this.state.transactionForm,affiliateId:this.props.affiliateId, currency:this.props.payoutCurrency}
            return  this.props.transfer(request).then((tr) => {
                this.setState({
                    transactionForm: Object.assign({}, this.state.transactionForm, {
                        amount: ""
                    })
                })
            }).catch(err => {
                throw err.message;
            });
        }else {
            throw "Unable to perform action";
        }
    }

    formDidUpdate(formModel: TransferRequest, validationMap?: any) {
        this.setState({transactionForm : formModel, validationMap : validationMap});
    }


    getForm() {
        return (
            <Form className="manual-adjustment-form container-fluid" formListener={this} validationMap={this.state.validationMap} modelObject={this.state.transactionForm}>

                <div className="row">
                    <div className="col-sm-6">
                        <label>Amount:</label>
                        <FormInput className="form-control"  model="amount" type="float" validators={[Validators.required(), Validators.number()]}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <label>Currency:</label>
                        <input className="form-control"  value={this.props.payoutCurrency} disabled={true}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-8">
                        <label>affiliateId:</label>
                        <input className="form-control"  value={this.props.affiliateId} disabled={true}/>

                    </div>
                    <div className="col-sm-4">
                        <label>&nbsp;</label>
                        <FormSubmitError />
                        <FormSubmitSuccess text={"Transfer successful!"}/>
                        <Submit className="btn btn-primary btn-block">Transfer</Submit>
                    </div>
                </div>
            </Form>
        );
    }

   render() {
        return (
            <Collapsible title="Register Manual Affiliate Payout" collapsed={false}>
                {this.getForm()}
            </Collapsible>
        );
    }
}