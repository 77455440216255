import i18n from "i18next"
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

/**
 * Get the translation for the given key and apply parameters to it.
 * @param key key
 * @param params parameters to key (optional)
 * @param defaultValue default value in case key is not found (optional)
 * @returns 
 */
 export function t(key : string, params? : any, defaultValue?: string | undefined) {
    const t = i18n.t(key, params);

    if (defaultValue !== undefined  &&  t === key) {
        return defaultValue
    } 
    
    return t
}

export interface Language { code : string, name : string};
export const enabledLanguages : Language[] = [
    { code : "en", name : "English"},
    { code : "zh", name : "简化字"}
]
export async function setupI18n()  {
    try {
        await i18n
            .use(HttpApi)
            .use(LanguageDetector)
            .init({
                debug: true,
                fallbackLng: "en",
                detection : {
                    order: ['querystring', 'cookie',  'navigator'],
                    caches: ['cookie'],
                    lookupQuerystring: 'lng',
                },
                backend: {
                    crossDomain: true,
                    loadPath: '/translations/{{lng}}.json',
                },
            });
    } catch (e) {
        console.error("Unable to initialize i18n", e)
    }
}