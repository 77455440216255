import React, {Fragment} from "react";
import {Form, FormListener} from "../form/Form";
import {AdminUser, AttributeType, UserAttributeDefinition, UserWriteAccess} from "../../http/protocol";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Submit} from "../form/Submit";
import {getContainer} from "../../ioc/IOCSetup";
import {RouteComponentProps, withRouter} from "react-router";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {LoadingViewState, RouterView} from "../RouterView";
import {Validators} from "../form/Validators";
import {UserAttributeService} from "../../services/UserAttributeService";
import {SelectInput} from "../form/SelectInput";
import {Case, Switch} from "../form/Switch";
import {t} from "../../i18n";


interface State extends LoadingViewState {
    userAttribute? : UserAttributeDefinition;
    validationMap? : any;
    loading: boolean;
    showModal: boolean;
    redirectPath?: string;
}

interface Props extends RouteComponentProps {

}

export class UserAttributeView extends RouterView<Props,State> implements FormListener<UserAttributeDefinition>{

    service : UserAttributeService;
    constructor(props : Props){
        super(props);
        this.service = getContainer().getUserAttributeService();

        this.state = { userAttribute : {}, validationMap : {}, loading : true, showModal: false};
    }

    loadContent() : Promise<any> | undefined {
        var id = (this.props.match.params as any).id;
        if(id && id!= "new") {
            return this.service.getEntity(id).then((a : UserAttributeDefinition) => {
                this.setState({ userAttribute : a});
            })
        } else {
            return undefined;
        }

    }
    componentDidUpdate(prevProps : RouteComponentProps, prevState : any) {
        if((this.props.match.params as any).id != (prevProps.match.params as any).id) {
            this.refreshContent();
        } else {
            super.componentDidUpdate(prevProps, prevState);
        }
    }

    onSubmit(): Promise<any> {
        if (!this.state.userAttribute) {
            return Promise.reject("Unable to save attribute");
        }
    
        return this.service.save(this.state.userAttribute).then((a: UserAttributeDefinition) => {
            this.setState({ showModal: true });
    
            if ((this.props.match.params as any).id == "new") {
                this.setState({ redirectPath: "/user-attribute/" + a.id });
            } else {
                this.setState({ userAttribute: a });
            }
        }).catch(err => {
            throw "Unable to create/save user";
        });
    }

    onSubmitError(){}

    formDidUpdate(formModel:UserAttributeDefinition, validationMap : any) {
        this.setState({ userAttribute : formModel, validationMap : validationMap});
    }


    getTitle() : string {
        return t(`usersManagement.createUserAttribute.pageTitle`);
    }

    handleModalClose = () => {
        this.setState({ showModal: false }, () => {
            if (this.state.redirectPath) {
                this.navigate(this.state.redirectPath);
                this.setState({ redirectPath: undefined }); // Clear the redirect path
            }
        });
    }



    renderContent() {
        if(!this.state.userAttribute) {
            return <span></span>
        }
        return (

            <Fragment>
                    <Form className="form-horizontal" formListener={this} modelObject={this.state.userAttribute} validationMap={this.state.validationMap}>
                        <div className={"card"}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <FormSubmitError />
                                        <FormSubmitSuccess text="User Attribute was updated successfully"
                                            onClose={this.handleModalClose}
                                        />

                                        <FormGroup className="form-group mb-3" model="attributeKey">
                                            <label>{t(`label.attributeKey`)}</label>
                                            <FormInput className="form-control" model="attributeKey" type="text" validators={[Validators.required()]}/>
                                        </FormGroup>
                                        <div className="hr-line-dashed"></div>

                                        <FormGroup className="form-group mb-3" model="type">
                                            <label>{t(`label.attributeType`)}</label>
                                            <SelectInput values={[AttributeType.SYSTEM_INTERNAL, AttributeType.SYSTEM, AttributeType.PRIVATE, AttributeType.PUBLIC]} className="form-control" model="type" type="text" validators={[Validators.required()]}/>
                                            <span className={"help-block"}>
                                                 <Switch value={this.state.userAttribute.type}>
                                                     <Case value={AttributeType.SYSTEM_INTERNAL}>
                                                        {t(`helpText.typeSystemInternal`)}
                                                     </Case>
                                                      <Case value={AttributeType.SYSTEM}>
                                                        {t(`helpText.typeSystem`)}
                                                     </Case>
                                                     <Case value={AttributeType.PRIVATE}>
                                                        {t(`helpText.typePrivate`)}
                                                     </Case>
                                                     <Case value={AttributeType.PUBLIC}>
                                                        {t(`helpText.typePublic`)}
                                                     </Case>
                                                 </Switch>
                                            </span>
                                        </FormGroup>
                                        <div className="hr-line-dashed"></div>

                                        <FormGroup className="form-group mb-3" model="userWriteAccess">
                                            <label>{t(`label.userWriteAccess`)}</label>
                                            <SelectInput values={[UserWriteAccess.NONE, UserWriteAccess.CREATE, UserWriteAccess.CREATE_AND_UPDATE]} className="form-control" model="userWriteAccess" type="text" validators={[Validators.required()]}/>
                                            <span className={"help-block"}>
                                                 <Switch value={this.state.userAttribute.userWriteAccess}>
                                                     <Case value={UserWriteAccess.NONE}>
                                                        {t(`helpText.userWriteAccessNone`)}
                                                     </Case>
                                                      <Case value={UserWriteAccess.CREATE_AND_UPDATE}>
                                                        {t(`helpText.userWriteAccessCreateUpdate`)}
                                                     </Case>
                                                      <Case value={UserWriteAccess.CREATE}>
                                                        {t(`helpText.userWriteAccessCreate`)}
                                                     </Case>
                                                 </Switch>
                                            </span>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>

                                        <div className="custom-control custom-checkbox" >
                                            <FormInput model="required" id="required" className={"custom-control-input fm-blocked"} type="checkbox" />
                                            <label className={"custom-control-label"} htmlFor={"required"}>
                                                {t(`label.ifAttributeRequired`)}
                                            </label>
                                        </div>

                                        <div className="hr-line-dashed"></div>

                                        <FormGroup className="form-group mb-3" model="ordinal">
                                            <label>{t(`label.sortOrder`)}</label>
                                            <FormInput className="form-control" model="ordinal" type="integer" />
                                            <span className={"help-block"}>{t(`helpText.sortOrder`)}</span>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group mb-3" model="options">
                                            <label>{t(`label.selectableValues`)}</label>
                                            <FormInput className="form-control" model="options" type="text" />
                                            <span className={"help-block"}
                                            dangerouslySetInnerHTML={{ __html: t(`helpText.selectableValues`).replace("|name|", `${this.state.userAttribute.nameTranslationKey}`) }}>
                                            </span>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>

                                        <FormGroup className="form-group mb-3" model="minLength">
                                            <label>{t(`label.minLength`)}</label>
                                            <FormInput className="form-control" model="minLength" type="integer" />
                                            <span className={"help-block"}>{t(`helpText.minLength`)}</span>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>

                                        <FormGroup className="form-group mb-3" model="maxLength">
                                            <label>{t(`label.maxLength`)}</label>
                                            <FormInput className="form-control" model="maxLength" type="integer" />
                                            <span className={"help-block"}>{t(`helpText.maxLength`)}</span>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>

                                        <FormGroup className="form-group mb-3" model="regex">
                                            <label>{t(`label.regexValidation`)}</label>
                                            <FormInput className="form-control" model="regex" type="integer" validators={[Validators.strLength(0,255)]}/>
                                            <span className={"help-block"}>{t(`helpText.regexValidation`)} <strong>^[a-zA-Z0-9].*$</strong></span>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>

                                        <FormGroup className="form-group mb-3" model="nameTranslationKey">
                                            <label>{t(`label.nameTranslationKey`)}</label>
                                            <FormInput className="form-control" model="nameTranslationKey" type="text"/>
                                            <span className={"help-block"}>{t(`helpText.nameTranslationKey`)} <strong>user.attributes.birth-year</strong></span>
                                        </FormGroup>

                                        <FormGroup className="form-group mb-3" model="descriptionTranslationKey">
                                            <label>{t(`label.descriptionTranslationKey`)}</label>
                                            <FormInput className="form-control" model="descriptionTranslationKey" type="text"/>
                                            <span className={"help-block"}>{t(`helpText.descriptionTranslationKey`)} <strong>user.attributes.birth-year-description</strong></span>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className={"card"}>
                            <div className={"card-body"}>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="ibox float-e-margins">
                                            <div className="ibox-content">
                                                <div className="ibox-title">
                                                    <h5>{t(`title.actions`)}</h5>
                                                </div>
                                                <div className="form-group">
                                                    <div className="col-sm-10 col-sm-offset-2">
                                                        <Submit className="btn btn-primary waves-effect waves-light">{t(`button.save`)}</Submit>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Form>

            </Fragment>
        )
    }

}

export default withRouter(UserAttributeView);