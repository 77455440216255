import {withRouter} from "react-router";
import {getContainer} from "../../ioc/IOCSetup";
import {CardBox, RouterViewTemplate, useLoadingState} from "../RouterView";
import {useForm} from "../form/Form";
import React, {useState} from "react";
import {ActionType} from "../list/list/DataTable";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {PagerModel} from "../list/list/PagerModel";
import {UserIdListRequest} from "../../services/SportsbookBonusService";
import {t} from "../../i18n";
import {ColumnType} from "../list/list/ColumnType";
import {SportsbookPlayerBonusesResponse} from "../../http/protocol";
import {useHistory, useLocation} from "react-router-dom";

const SportsbookBonusListView = () => {
    const service = getContainer().getSportsbookBonusService();

    const history = useHistory();
    const location = useLocation();

    const initialUserId = location.search.search('userId') !== -1 ? location.search.split('=')[1] : '';

    const [userIdState, setUserIdState] = useState<string>(initialUserId);

    const handleSearchClick = () => {
        const url = `/sportsbook/freebets/list?userId=${encodeURIComponent(userIdState)}`;
        history.push(url);

        setUserIdListRequest({
            ...userIdListRequest,
            userId: userIdState
        });
    };

    const status = useLoadingState(() => Promise.resolve(), []);

    const handleUserIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserIdState(event.target.value);
    };

    const [userIdListRequest, setUserIdListRequest]  = useState<UserIdListRequest>({
        limit: 20,
        offset : 0,
        orderBy : "id",
        ascending : false,
        userId : userIdState,
    });

    const [form, formListener, setFormState ] = useForm<SportsbookPlayerBonusesResponse>({}, () => {
        return service.list(userIdListRequest).then(response => setFormState({ model: response, validationMap: form.validationMap }));
    });

    const pagerChanged = (pager : PagerModel) => {
        setUserIdListRequest({
            offset : pager.offset,
            limit : pager.limit,
            orderBy : pager.orderBy,
            ascending : pager.ascending,
            userId : userIdState,
        })
    }

    const onAction = (item: any, action: string): Promise<any> | undefined => {
        if (action == "delete") {
            return service.revokeBonus(item.id)
        }
    }

    function renderStatus(status: string) {
        if (status === 'active') {
            return <span className="badge badge-success">{t(status)}</span>;
        } else if (status === 'revoked') {
            return <span className="badge badge-danger">{t(status)}</span>;
        } else {
            return <span className="badge badge-info">{t(status)}</span>;
        }
    }

    return (
        <RouterViewTemplate status={status} title={"List Free Bets"}>
            <CardBox>
                <h4>Find By User Id</h4>
                <div className={''}>
                    <div className="form-group">
                        <input
                            className={'form-control fm-userIdOrUid'}
                            type="text"
                            value={userIdState}
                            onChange={handleUserIdChange}
                            placeholder="Enter user Id"
                            autoComplete={'off'}
                        />
                    </div>
                    <button className={'btn btn-primary'} onClick={handleSearchClick}>Search</button>
                </div>
            </CardBox>

            <EntityDataTable
                service={service}
                listRequest={userIdListRequest}
                columns={[
                    { property : "name", name : 'Name' },
                    { property : "status", name : "Status", renderer : (property, name, item) => renderStatus(item.status) },
                    { property : "activation_date", name : "activationDate", renderer : (property, name, item) => new Date((item.activationDate || 0)).toDateString()},
                    { property : "ending_date", name : "endingDate", renderer: (property, name, item) => new Date((item.endingDate || 0)).toDateString() },
                    { property : "freebetData.amount", name : "Amount", renderer: (property, name, item) => Number((item.freebetData.amount || 0) ).toFixed(2), type : ColumnType.MONEY},
                    { property : "freebetData.currency", name : "Currency" },
                    { property : "viewed", name : "Viewed", type : ColumnType.YES_OR_NO },
                ]}
                actions={[
                    { type : ActionType.LINK, className : "btn btn-primary", id : "view", name : "View", getLink : (item) => `/sportsbook/freebets/${item.id}`},
                    { id : "delete", name : "Revoke", className : "btn btn-danger", iconClassName : "fa fa-times",
                        type : ActionType.CONFIRMATION, show: item => item.status === 'active' || item.status === 'new' },
                ]}
                onPagerChange={pagerChanged}
                onAction={onAction}
                showIndex={false}
                disableCount
                hideTitle={true}
                hidePagination={true}
            />
        </RouterViewTemplate>
    );
}

export default withRouter(SportsbookBonusListView);