import React, {Fragment} from "react";
import { ListBundlesRequest, Bundle} from "../../http/protocol";
import { ListViewProps, ListViewState, ListView } from "../ListView";
import { ActionType } from "../list/list/DataTable";
import { getContainer } from "../../ioc/IOCSetup";
import { PagerModel } from "../list/list/PagerModel";
import { ListUtils } from "../list/list/ListUtils";
import { EntityDataTable } from "../list/list/EntityDataTable";
import { BundleService } from "../../services/BundleService";
import { withRouter } from "react-router";

interface State extends ListViewState<ListBundlesRequest> {
    listRequest?: ListBundlesRequest;
}

interface Props extends ListViewProps {

}

export class BundlesListView extends ListView<State, ListBundlesRequest> {

    columns = [
        { property : "id", name : "ID", orderBy : "ID"},
        { property : "name", name : "Name"},
        { property : "label", name : "Label"},
        { property : "price.amount", name : "Price Amount"},
        { property : "price.currency", name : "Price Currency"}
    ];

    actions = [
        { id : "view", name : "View", className : "btn btn-primary", iconClassName : "fa fa-eye", type : ActionType.LINK, getLink : (item : any) => "/bundles/" + item.id }
    ];

    bundleService : BundleService;

    constructor(props : Props){
        super(props, "/bundles");
        this.bundleService = getContainer().getBundleService();
        console.log("Created list bundles");
    }

    onAction(item : Bundle, action : string) : undefined {
        return undefined;
    }

    pagerChanged(pager : PagerModel) {
        if(this.state.listRequest) {
            console.log("pager changed!", pager);
            let listRequest = ListUtils.toListRequest<any>(this.state.listRequest,pager);
            this.updateListState(listRequest);
        }
    }
    getTitle() {
        return "Bundles"
    }

    renderContent() {
        console.log("Render list bundles");
        if(!this.state.listRequest) {
            console.log("Nothing to show here");
            return <div/>;
        }
        return (
            <Fragment>
                <EntityDataTable service={this.bundleService}
                                 onPagerChange={this.pagerChanged.bind(this)}
                                 columns={this.columns}
                                 listRequest={this.state.listRequest}
                                 onAction={this.onAction.bind(this)}
                                 actions={this.actions}/>
            </Fragment>
        );
    }

}

export default withRouter(BundlesListView);