import * as React from "react";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {
    CasinoGame,
    CurrencyDefinition,
    GameIntegration,
    Product,
    RewardTemplate,
    RewardType,
    TemplateItem
} from "../../http/protocol";
import {t} from "../../i18n";
import {getContainer} from "../../ioc/IOCSetup";
import {CasinoGameService} from "../../services/CasinoGameService";
import {RewardsConfigService} from "../../services/RewardsConfigService";
import {ApplicationState} from "../../user/UserStore";
import {formatDate} from "../../util/date";
import {AddCasinoGames} from "../casino/AddCasinoGames";
import {Form, FormListener} from "../form/Form";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {SelectInput} from "../form/SelectInput";
import {Submit} from "../form/Submit";
import {Validators} from "../form/Validators";
import {LoadingViewState, RouterView} from "../RouterView";
import {InternalSettingsService} from "../../services/InternalSettingsService";


interface State extends LoadingViewState {
    tc : RewardTemplate;
    validationMap? : any;
    selectedIntegrationName:string;
    selectedGame? : CasinoGame;
    showAddGamesDialog?: boolean;
    freeSpinsIntegrations?: GameIntegration[]
    templateItems: TemplateItem[];
    templateIds: string[];
}

interface Props extends RouteComponentProps {
    currencies? : CurrencyDefinition[];
}

export class EditRewardTemplate extends RouterView<Props,State> implements FormListener<RewardTemplate>{

    service : RewardsConfigService;
    gameService : CasinoGameService;
    internalSettingsService: InternalSettingsService;

    constructor(props : RouteComponentProps){
        super(props);
        this.service = getContainer().getRewardsConfigService();
        this.gameService = getContainer().getCasinoGameService();
        this.internalSettingsService = getContainer().getInternalSettingsService();

        let gi : GameIntegration[]=[];
        this.internalSettingsService.getSettings().then(settings => {
            if(settings.freeSpinsEnabledIntegrations != null){
                settings.freeSpinsEnabledIntegrations?.split(",").forEach(integrationName => {
                    Object.values(GameIntegration).forEach(enumName => {
                        if(enumName===integrationName){
                            gi.push(enumName);
                        }
                    });
                })

            }
        })

        let items : TemplateItem[] = [];
        let ids : string[] = [];
        this.state = { tc : {}, validationMap : {}, selectedIntegrationName:Object.keys(GameIntegration)[0],
            freeSpinsIntegrations:gi,templateItems:items,templateIds:ids};
    }

    storeTemplateIds(items: TemplateItem[], currentTemplateId?: string) {
        let currentTemplateIdAvailable = false
        if(items != null) {
            let ids : string[] = []
            let filtredItems = this.filterActiveTemplates(items)
            filtredItems.forEach(template => {
                if (template.id) {
                    ids.push(template.id);
                    if (template.id == currentTemplateId) {
                        currentTemplateIdAvailable = true
                    }
                }
            })
            if (!currentTemplateIdAvailable && currentTemplateId) {
                // A currentTemplateId, that is no longer avaialble from Betby, will be added to the list of id's to display
                ids.push(currentTemplateId);
            }
            this.setState({templateIds: ids, templateItems: this.filterActiveTemplates(items)})
        }
    }

    getTitle(): string {
        return "Create/Edit Reward template";
    }

    componentDidUpdate(prevProps : RouteComponentProps, prevState : State) {
        if((prevProps.match.params as any).id != (this.props.match.params as any).id)  {
            this.refreshContent();
        }
    }

    loadContent() {
        if((this.props.match.params as any).id != "new") {
            return this.service.getReward((this.props.match.params as any).id).then((reward : RewardTemplate) => {
                if(reward.defaultCasinoGameId && reward.rewardType == RewardType.freespins) {
                    return this.gameService.getEntity(""+reward.defaultCasinoGameId).then( g => {
                        this.setState({ selectedGame : g, tc: reward });
                    })
                } else if(reward.rewardType == RewardType.freebet) {
                    return this.service.getBetbyTemplates().then( items => {
                        this.storeTemplateIds(items, reward.templateId)
                        return this.setState({ tc: reward });
                    })
                } else {
                    return this.setState({ tc: reward });
                }
            });
        } else {
            return this.service.getBetbyTemplates().then( items => {
                this.storeTemplateIds(items, undefined)
                const cur = this.props.currencies  &&  this.props.currencies.length > 0 ? this.props.currencies[0].code : undefined;
                return this.setState({tc:{currencyCode: cur, ...this.state.tc}})
            })
        }
    }

    filterActiveTemplates(templates: TemplateItem[]): TemplateItem[]{
        return templates.filter((template) => template.is_active)
    }

    toggleAddShowAddGames() {
        this.setState({ showAddGamesDialog :   !this.state.showAddGamesDialog  });
    }

    onSubmit()  {
        console.log("Saving following form: ", this.state.tc);
        if(!this.state.tc.defaultCasinoGameId && this.state.tc.rewardType == RewardType.freespins) {
            return;
        }
        let template = this.state.tc;
        if((this.props.match.params as any).id == "new") {
            return this.service.saveRewardTemplate(template);
        } else {
            return this.service.updateRewardTemplate(template);
        }
    }

    formDidUpdate(formModel: RewardTemplate, validationMap?: any) {
        console.log("form modal = ", formModel);
        this.setState({tc : formModel, validationMap : validationMap})

    }
    getCurrencyLabel(code : string) {
        if(this.props.currencies) {
            let c = this.props.currencies.find( c => c.code == code);
            if(c) {
                return c.name + " (" + c.code + ")";
            }
        }
        return "";
    }
    selectGame(game : CasinoGame) {
        this.setState({ tc : {...this.state.tc, defaultCasinoGameId : game.id}, showAddGamesDialog : false, selectedGame : game})
    }

    getTemplateDisplayNameById(id : string) : string {
        if(!this.state.templateItems) {
            return id + " - NOT AVAILABLE";
        }
        let res =  this.state.templateItems.find((ti : TemplateItem) => ti.id == id)

        const name = res ? res.name : " - NO LONGER AVAILABLE AT BETBY";
        return id + " - " + name;
    }

    renderContent() {
        if(!this.state.tc || !this.props.currencies) {
            return <span/>
        }

        return (
            <div>
                <div className="wrapper wrapper-content animated fadeInRight">
                    <Form className="form form-horizontal" validationMap={this.state.validationMap} modelObject={this.state.tc} formListener={this}>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card-box">
                                    <div className="ibox-content">
                                        {(this.props.match.params as any).id != "new" && (   <div className="hr-line-dashed"/> )}

                                        {(this.props.match.params as any).id != "new" && (
                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">ID</label>
                                                <div className="col-sm-10">
                                                    <FormInput  model="id" type="integer"  className="form-control" disabled={true}/>
                                                </div>
                                            </FormGroup>
                                        )}


                                        {(this.props.match.params as any).id == "new" && (
                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">Reward Type</label>
                                                <div className="col-sm-10">
                                                    <SelectInput  model="rewardType" optional={false} values={Object.keys(RewardType)} className="form-control"/>
                                                    <span className={"help"}/>
                                                </div>
                                            </FormGroup>
                                        )}
                                        {(this.props.match.params as any).id != "new" && (
                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">Reward Type</label>
                                                <div className="col-sm-10">
                                                    <FormInput  model="rewardType" type="text"  className="form-control" disabled={true}/>
                                                    <span className={"help"}/>
                                                </div>
                                            </FormGroup>
                                        )}

                                        {this.state.tc.rewardType == RewardType.freespins && (
                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">Product</label>
                                                <div className="col-sm-10">
                                                    <SelectInput  model="product" optional={false} values={[Product.CASINO]} className="form-control"/>
                                                    <span className={"help"}/>
                                                </div>
                                            </FormGroup>
                                        )}
                                        {(this.props.match.params as any).id != "new" && (
                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">Created</label>
                                                <div className="col-sm-10">
                                                    <span className="form-control">{formatDate(this.state.tc.created)}</span>
                                                </div>
                                            </FormGroup>
                                        )}
                                        {this.state.tc.rewardType == RewardType.freespins && (
                                            <div className="form-group">
                                                <label className="col-sm-2 control-label">Game</label>
                                                <div className="col-sm-10">
                                                    {!this.state.tc.defaultCasinoGameId && <span className={"alert alert-warning"} style={{display : "block"}}>Please Select Game</span>}
                                                    {this.state.tc.defaultCasinoGameId && <span className={"form-control"}>{this.state.selectedGame?.id} - {this.state.selectedGame?.name} -  {this.state.selectedGame?.integration}</span>}
                                                    <a style={{marginTop : "5px"}} className={"btn btn-primary"}  onClick={()=>  this.toggleAddShowAddGames()}>Select Game</a>
                                                    <span className={"help"}/>
                                                </div>
                                            </div>
                                        )}

                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Name</label>
                                            <div className="col-sm-10">
                                                <FormInput model="name" validators={[Validators.required()]} type="text" className="form-control" />
                                                <span className={"help"}>Internal Name only</span>
                                            </div>
                                        </FormGroup>

                                        {this.state.tc.rewardType == RewardType.freebet && (
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Template ID</label>
                                            <div className="col-sm-10">
                                                <SelectInput  model="templateId" bigInteger={true} optional={false} values={this.state.templateIds}
                                                              displayValue={(id : string) => this.getTemplateDisplayNameById(id)}  className="form-control" />
                                                <span className={"help"}>Templated id in Betby backoffice</span>
                                            </div>
                                        </FormGroup>
                                        )}

                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Description</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="description" validators={[Validators.required()]} type="text"  className="form-control" />
                                                <span className={"help"}>Internal Short description about the template. Ex. 10 USD Free-spins</span>
                                            </div>
                                        </FormGroup>
                                        <div className="hr-line-dashed"/>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Title Key</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="titleKey" type="text"  className="form-control" />
                                                <span className={"help"}>Optional translation key to use for title on the site.</span>
                                            </div>
                                        </FormGroup>
                                        <div className="hr-line-dashed"/>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Description Key</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="descriptionKey" type="text"  className="form-control" />
                                                <span className={"help"}>Optional translation key to use for description on the site.</span>
                                            </div>
                                        </FormGroup>
                                        <div className="hr-line-dashed"/>

                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Icon URL</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="iconUrl" type="text"  className="form-control" />
                                                <span className={"help"}>Optional. If set then this image will be displayed in the list of claimable rewards in the client. If empty the default icon will be used (Push Notification → reward_received)</span>
                                            </div>
                                        </FormGroup>
                                        <div className="hr-line-dashed"/>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Image URL</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="imageUrl" type="text"  className="form-control" />
                                                <span className={"help"}>Optional. Will be used in push notifications and message for new reward. If left empty the default image in Contentful will be used (Push Notification → reward_received)</span>
                                            </div>
                                        </FormGroup>
                                        <div className="hr-line-dashed"/>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Currency</label>
                                            <div className="col-sm-10">
                                                <SelectInput model="currencyCode" optional={false} values={["Select currency...",...this.props.currencies]} optionModelValue={(e) => e.code} displayValue={(e) => e.name} className="form-control"/>
                                            </div>
                                        </FormGroup>
                                        {this.state.tc.rewardType == RewardType.freespins && (
                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">Default Nr Of Free-spins</label>
                                                <div className="col-sm-10">
                                                    <FormInput  model="defaultNrOfFreeSpins" validators={[Validators.required(), Validators.integer()]} type="integer"  className="form-control" />
                                                    <span className={"help"}>The default value for Number of free-spins to reward. This can be overridden when awarding a reward to a user.</span>
                                                </div>
                                            </FormGroup>
                                        )}
                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">Default Amount</label>
                                                <div className="col-sm-10">
                                                    <FormInput  model="defaultAmount" validators={[Validators.required(), Validators.number()]} type="float"  className="form-control" />
                                                    <div  className={"alert alert-warning guide"}>
                                                    <span className={"help"}>CASH rewards: the default amount to reward to the player</span><br/>
                                                    <span className={"help"}>FREESPINS rewards: the default bet amount for every spin. Not all integrations support this (will be ignored if not supported by the game integration)</span><br/>
                                                    <span className={"help"}>FREEBET rewards: total amount. Exchanged value will be awarded if currency is not supported by integration.</span>
                                                    </div>
                                                </div>
                                            </FormGroup>
                                        {this.state.tc.rewardType == RewardType.freespins && (
                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">Expires After Days</label>
                                                <div className="col-sm-10">
                                                    <FormInput  model="expireAfterDays" validators={[Validators.required(), Validators.integer()]} type="integer"  className="form-control" />
                                                    <span className={"help"}>Number of days the user has to play the freespins after he has claimed the reward</span>
                                                </div>
                                            </FormGroup>
                                        )}
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Valid For User Until Days</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="validForUserUntilDays" validators={[Validators.required(), Validators.integer()]} type="integer"  className="form-control" />
                                                <span className={"help"}>Number of days before the reward expires and can no longer be claimed.</span>
                                            </div>
                                        </FormGroup>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card-box">
                                    <FormSubmitError />
                                    <FormSubmitSuccess text={t(`alertInfo.accountSuccessfully`)}/>
                                    <div className="form-group">
                                        <div className="col-sm-12">
                                            <Submit className="btn  btn-primary">Save</Submit>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Form>
                    <AddCasinoGames title={"Select Casino Game"} onlyFreeSpinSupported={true} includeIntegrations={this.state.freeSpinsIntegrations} showAddGames={this.state.showAddGamesDialog} toggleShowAddGames={()=> this.toggleAddShowAddGames()} addGame={(game : CasinoGame)=>{ this.selectGame(game)}} removeGame={()=>{}} addedGames={[]} />
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state : ApplicationState) => {
    return { currencies: state.settings.settings?.currencies }
}

export default connect(mapStateToProps)(withRouter(EditRewardTemplate))
