import * as React from "react";

import {RouteComponentProps, withRouter} from "react-router";
import {LoadingViewState, RouterView} from "../RouterView";
import {
    AwardType,
    BonusPaymentType,
    CurrencyDefinition,
    MonetaryConfig,
    RewardTemplate,
    RewardType,
} from "../../http/protocol";
import {Form, FormListener} from "../form/Form";
import {getContainer} from "../../ioc/IOCSetup";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {Submit} from "../form/Submit";
import {Validators} from "../form/Validators";
import {SelectInput} from "../form/SelectInput";
import {MonetaryConfigService} from "../../services/MonetaryConfigService";
import {RewardsConfigService} from "../../services/RewardsConfigService";
import {t} from "../../i18n";
import {ApplicationState} from "../../user/UserStore";
import {connect} from "react-redux";


interface Props extends RouteComponentProps {
    currencies?: CurrencyDefinition[]
}

interface State extends LoadingViewState {
    tc? : MonetaryConfig;
    validationMap? : any;
    rewardTemplates?: RewardTemplate[]
    defaultTemplateId?: number
    defaultCurrencyCode?: string
}

export class EditTurnoverContributions extends RouterView<Props,State> implements FormListener<MonetaryConfig>{

    service : MonetaryConfigService;
    rewardsConfigService: RewardsConfigService;

    constructor(props : Props){
        super(props);
        this.service = getContainer().getMonetaryConfigService();
        this.rewardsConfigService=getContainer().getRewardsConfigService();
        this.state = {rewardTemplates: [], tc : {}, validationMap : {}, defaultTemplateId:undefined, defaultCurrencyCode:undefined};
    }

    getTitle(): string {
        return "Edit Monetary Config";
    }

    componentDidUpdate(prevProps : RouteComponentProps, prevState : State) {
        if((prevProps.match.params as any).id != (this.props.match.params as any).id)  {
            this.refreshContent();
        }
    }

    async loadContent(): Promise<any>  {
        this.rewardsConfigService.list({limit : 1000, offset: 0, orderBy : "name",  rewardType : RewardType.cash }).then(value => {this.setState({rewardTemplates:value.items})})

        if((this.props.match.params as any).id != "new") {
            const tc = await this.service.getEntity((this.props.match.params as any).id)
            this.setState({ tc: tc, defaultTemplateId: tc.cashRewardTemplateId, defaultCurrencyCode: tc.currencyCode });
        }
    }

    onSubmit()  {
        if(!this.state.tc) return;
        return this.service.save(this.state.tc).then((s : MonetaryConfig)=> {
            this.setState({tc : s, defaultCurrencyCode:s.currencyCode, defaultTemplateId:s.cashRewardTemplateId});
        })
    }

    formDidUpdate(formModel: MonetaryConfig, validationMap?: any) {
        if(formModel.currencyCode!= this.state.tc?.currencyCode){
            if(formModel.currencyCode==this.state.defaultCurrencyCode){
                formModel.cashRewardTemplateId=this.state.defaultTemplateId;
            }else {
                formModel.cashRewardTemplateId=undefined;
            }
        }
        this.setState({tc : formModel, validationMap : validationMap})
    }

    getCurrencyLabel(code : string) {
        if(this.props.currencies) {
            let c = this.props.currencies.find( c => c.code == code);
            if(c) {
                return c.name + " (" + c.code + ")";
            }
        }
        return "";
    }

    renderContent() {
        if(!this.state.tc || !this.props.currencies) {
            return <span></span>
        }

        return (
            <div>


                <div className="wrapper wrapper-content animated fadeInRight">
                    <Form className="form form-horizontal" validationMap={this.state.validationMap} modelObject={this.state.tc} formListener={this}>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card-box">
                                    <div className="ibox-content">
                                        {(this.props.match.params as any).id != "new" && (   <div className="hr-line-dashed"></div> )}

                                        {(this.props.match.params as any).id != "new" && (
                                            <FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">ID</label>
                                                <div className="col-sm-10">
                                                    <FormInput  model="id" type="integer"  className="form-control" disabled={true}/>
                                                </div>
                                            </FormGroup>
                                        )}


                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Name</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="name" validators={[Validators.required()]} type="text"  className="form-control" />
                                                <span className={"help"}>Internal name of this turnover contribution</span>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Currency Code</label>
                                            <div className="col-sm-10">
                                                <SelectInput  model="currencyCode"
                                                              optional={false} values={ this.props.currencies.map( c => c.code)}  displayValue={(code : string) => this.getCurrencyLabel(code)} className="form-control" />
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Award Type</label>
                                            <div className="col-sm-10">
                                                <SelectInput  model="awardType" optional={false} /*values={Object.keys(AwardType)}*/ values={[AwardType.COMPLETED]} className="form-control"/>
                                                <span className={"help"}>When the bonus money is awarded to the player. It can be awarded as both bonus money or real money depending on the bonus. <br/>
                                                COMPLETED - Money are paid out when the user completes the requirements of the bonus
                                                </span>
                                            </div>
                                        </FormGroup>
                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Payment Type</label>
                                            <div className="col-sm-10">
                                                <SelectInput  model="bonusPaymentType" optional={false} values={Object.keys(BonusPaymentType)} className="form-control"/>
                                                <span className={"help"}>How bonus will be payed to the user <br/>
                                                DIRECT - Bonus amount will be added to the user's account directly<br/>
                                                CASH_REWARD - A cash reward will be created and user have to claim it to get the amount in the account<br/>
                                                </span>
                                            </div>
                                        </FormGroup>
                                        {this.state.tc.bonusPaymentType==BonusPaymentType.CASH_REWARD && this.state.tc.currencyCode &&(

                                            (<FormGroup className="form-group">
                                                <label className="col-sm-2 control-label">Cash Reward Template</label>
                                                <div className="col-sm-10">
                                                    <SelectInput model="cashRewardTemplateId" optional={true} validators={[Validators.required(), Validators.number()]} values={this.getTemplates(this.state.tc.currencyCode)}  optionModelValue={(e) => e.id} displayValue={(e) => e.name + " - " + e.id + " ("+e.currencyCode+")"} className="form-control"/>
                                                </div>
                                                {this.getTemplates(this.state.tc.currencyCode).length==0 && (
                                                    <span className="text-danger"> You need to create a cash reward for currency {this.state.tc.currencyCode} before you can use this option<br/></span>
                                                )
                                                }
                                            </FormGroup>)

                                        )
                                        }


                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-4 control-label">Max Input Amount</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="maxInputAmount" validators={[Validators.required(), Validators.number()]} type="float"  className="form-control" />
                                                <span className={"help"}>At what amount this bonus i capped. 1000 in max input amount for a deposit bonus would mean the bonus is only counted up to 1000.</span>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-4 control-label"><FormInput  model="inputAmountAsBonusMoney"  type="checkbox"  /> Input Amount as Bonus Money</label>
                                            <div className="col-sm-10">

                                                <span className={"help"}>
                                                    If you want the input amount (deposit amount) to be counted as bonus money. The bonus will be in bonus money phase and start turnover when activated.
                                                    This is only applicable to parachute bonuses.
                                                </span>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-4 control-label">Award Amount Fraction</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="awardAmountFraction" validators={[Validators.required(), Validators.number()]} type="float"  className="form-control" />
                                                <span className={"help"}>Multiplied with the input amount (ie deposit amount) to set how much bonus the player will receive. If set to 2 the user will receive 200% bonus.</span>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-4 control-label">Turnover Requirement Factor</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="turnoverRequirementFactor" validators={[Validators.required(), Validators.number()]} type="float"  className="form-control" />
                                                <span className={"help"}>How many times the bonus a player is awarded he needs to turnover the bonus for it to be completed</span>
                                            </div>
                                        </FormGroup>
                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-4 control-label">Max Wager Amount</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="maxWagerAmount" validators={[Validators.number()]} type="float"  className="form-control" />
                                                <span className={"help"}>The maximum wager amount that will contribute to a bonus turnover. The wagers will be capped at this amount</span>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-4 control-label">Min Bonus Balance Threshold (Parachute)</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="bustThreshold" validators={[Validators.required()]} type="text"  className="form-control" />
                                                <span className={"help"}>Bust bonus if bonus balance gets below this amount. Only applicable for Parachute bonuses.<br/>
                                                    This is a precatuion to avoid bonuses getting stuck with below the minimum bet amount. Set this to a value just above the minumum bet.
                                                </span>
                                            </div>
                                        </FormGroup>

                                    </div>
                                </div>



                            </div>

                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card-box">

                                    <FormSubmitError />
                                    <FormSubmitSuccess text={t(`alertInfo.accountSuccessfully`)}/>
                                    <div className="form-group">
                                        <div className="col-sm-12">
                                            <Submit className="btn  btn-primary">Save</Submit>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }

    private getTemplates(currencyCode: string ) {
        return this.state.rewardTemplates?.filter(o => (o?.currencyCode== currencyCode) ) ?? [];
    }
}

const mapStateToProps = (state : ApplicationState) => {
    return { currencies: state.settings.settings?.currencies }
}

export default connect(mapStateToProps)(withRouter(EditTurnoverContributions))