import * as React from "react";

import {RouteComponentProps, withRouter} from "react-router";
import {LoadingViewState, RouterView} from "../RouterView";
import {Currency} from "../../http/protocol";
import {Form, FormListener} from "../form/Form";
import {WalletService} from "../../services/WalletService";
import {getContainer} from "../../ioc/IOCSetup";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Validators} from "../form/Validators";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {Submit} from "../form/Submit";
import {t} from "../../i18n";
import _ from "lodash";


interface State extends LoadingViewState {
    currency? : Currency;
    validationMap? : any;
    createSystemAccounts: boolean;
}

export const ACCOUNT_ROLES = [t(`option.accountRoles.${_.camelCase("MAIN")}`),
t(`option.accountRoles.${_.camelCase("BONUS")}`),
t(`option.accountRoles.${_.camelCase("RAKE")}`),
t(`option.accountRoles.${_.camelCase("PROMOTION")}`),
t(`option.accountRoles.${_.camelCase("WALLET")}`)]

export class CreateOrUpdateCurrencyView extends RouterView<RouteComponentProps,State> implements FormListener<Currency>{

    walletService : WalletService;

    constructor(props : RouteComponentProps){
        super(props);
        this.walletService = getContainer().getWalletService();
        this.state = { currency : {}, validationMap : {}, createSystemAccounts: false};
    }

    getTitle(): string {
        return (this.props.match.params as any).id != "new" ? "Edit Currency" : "Create Currency";
    }

    getSuccessText(): string {
        return (this.props.match.params as any).id != "new"?
            "Currency has been updated successfully.":
            "Currency has been added successfully.";
    }

    componentDidUpdate(prevProps : RouteComponentProps, prevState : State) {
        if((prevProps.match.params as any).id != (this.props.match.params as any).id)  {
            this.refreshContent();
        }
    }

    loadContent(): any {
        if((this.props.match.params as any).id != "new") {
            return this.walletService.getCurrency((this.props.match.params as any).id).then((res:Currency) => {
                this.setState({currency:res})
            })
        } else {
            this.setState({currency:{}})
        }
    }

    onSubmit()  {
        if(!this.state.currency) return;

        return this.walletService.createOrUpdateCurrency(this.state.currency,  this.state.createSystemAccounts).then((c : Currency)=> {
            this.setState({currency : c});
        })
    }

    formDidUpdate(formModel: Currency, validationMap?: any) {
        this.setState({currency : formModel, validationMap : validationMap})
    }


    renderContent() {
        if(!this.state.currency) {
            return <span></span>
        }

        return (
            <div>


                <div className="wrapper wrapper-content animated fadeInRight">
                    <Form className="form form-horizontal" validationMap={this.state.validationMap} modelObject={this.state.currency} formListener={this}>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card-box">
                                    <h5>Currency details</h5>
                                    <div className="ibox-content">
                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Code</label>
                                            <div className="col-sm-10">
                                                <FormInput disabled={(this.props.match.params as any).id != "new"}
                                                 model="code" type="text"  className="form-control" validators={[Validators.strLength(1,8)]}/>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Name</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="name" type="text"  className="form-control" />
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">fractionalDigits</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="fractionalDigits" type="number"  className="form-control" validators={[Validators.number()]} />
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">prefixSymbol</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="prefixSymbol" type="text"  className="form-control" />
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">postfixSymbol</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="postfixSymbol" type="text"  className="form-control" />
                                            </div>
                                        </FormGroup>

                                          <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Fixed Value in EUR</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="eurExchangeRate" type="text"  className="form-control" />
                                                <span className={"help-block"}>Optional. Use this factor for conversion to EUR for system internal functions. Only specify if the currency is not an official FIAT currency or does not have available real time crypto conversion.</span>
                                            </div>
                                        </FormGroup>

                                        {(this.props.match.params as any).id === "new" &&
                                        <div className="hr-line-dashed"/> &&
                                        <FormGroup className="custom-control custom-checkbox"
                                                   model="createSystemAccounts">
                                            <input id={"createSystemAccounts"} className="custom-control-input"
                                                   type="checkbox" checked={this.state.createSystemAccounts}
                                                   onChange={() =>
                                                       this.setState({createSystemAccounts: !this.state.createSystemAccounts})}
                                            />
                                            <label htmlFor={"createSystemAccounts"}
                                                   className={"custom-control-label"}>Create System
                                                Accounts</label><br/>
                                            <span className={"help-block"}>Should system accounts for this currency be created?</span>
                                        </FormGroup>}

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="custom-control custom-checkbox" model="cryptoCurrency">
                                            <FormInput className="custom-control-input" model="cryptoCurrency" id={"cryptoCurrency"} type="checkbox"/>
                                            <label htmlFor={"cryptoCurrency"} className={"custom-control-label"}>Crypto Currency</label><br/>
                                            <span className={"help-block"}>Is this a Crypto Currency?</span>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="custom-control custom-checkbox" model="visible">
                                            <FormInput className="custom-control-input" model="visible" id={"visible"} type="checkbox"/>
                                            <label htmlFor={"visible"} className={"custom-control-label"}>Visible</label><br/>
                                            <span className={"help-block"}>Should the currency be visible on the site</span>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="custom-control custom-checkbox" model="cryptoExchangeEnabled">
                                            <FormInput className="custom-control-input" model="cryptoExchangeEnabled" id={"cryptoExchangeEnabled"} type="checkbox"/>
                                            <label htmlFor={"cryptoExchangeEnabled"} className={"custom-control-label"}>Available for Crypto to Fiat Exchange</label><br/>
                                            <span className={"help-block"}>If this currency should be offered as a fiat exchange currency. NOTE: Only applicable for official fiat currency codes.</span>
                                        </FormGroup>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card-box">

                                    <FormSubmitError />
                                    <FormSubmitSuccess text={this.getSuccessText()} onClose={() => this.navigate("/currencies/list")}/>
                                    <div className="form-group">
                                        <div className="col-sm-12">
                                            <Submit className="btn  btn-primary">{(this.props.match.params as any).id != "new" ? "Save Currency" : "Create Currency"}</Submit>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }

}

export default withRouter(CreateOrUpdateCurrencyView);