import React, {Fragment} from "react";
import {Form, FormListener} from "../form/Form";
import {AdminUser, ListRequest, ListUserGroupResponse, UserGroup, Bundle, BundleType} from "../../http/protocol";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Submit} from "../form/Submit";
import {SelectInput} from "../form/SelectInput";
import {AdminUserService} from "../../services/AdminUserService";
import {getContainer} from "../../ioc/IOCSetup";
import {UserGroupService} from "../../services/UserGroupService";
import {RouteComponentProps, withRouter} from "react-router";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {LoadingViewState, RouterView} from "../RouterView";
import * as _ from "lodash";
import {Validators} from "../form/Validators";
import { BundleService } from "../../services/BundleService";

interface State extends LoadingViewState {
    bundle? : Bundle;
    validationMap? : any;
    successText?: string;
}

interface Props extends RouteComponentProps {

}

export class BundleView extends RouterView<Props,State> implements FormListener<AdminUser>{

    service : BundleService;
    
    constructor(props : Props){
        super(props);
        this.service = getContainer().getBundleService();
        this.state = { validationMap : {}, loading : true, bundle : {}, successText : "" };
    }

    loadContent() : Promise<any> | undefined {
        var id = (this.props.match.params as any).id;
        if(id && id!= "new") {
            return Promise.all<any>([
                this.service.getEntity(id)

            ]).then( res => {
                let bundle = res[0];
                this.setState({loading : false, bundle : bundle });
            });
        } else {
            this.setState({bundle : {}});
            return undefined;
        }

    }
    componentDidUpdate(prevProps : RouteComponentProps, prevState : any) {
        if((this.props.match.params as any).id != (prevProps.match.params as any).id) {
            this.refreshContent();
        } else {
            super.componentDidUpdate(prevProps, prevState);
        }
    }

    onSubmit():Promise<any> {
        if(!this.state.bundle) {
            return Promise.resolve();
        }
        return this.service.save(this.state.bundle).then( (bundle : Bundle) => {
            console.log("Bundle saved ", bundle);
            if((this.props.match.params as any).id == "new") {
                this.setState({bundle : bundle, successText : "Bundle was created with id: " + bundle.id})
            } else {
                this.setState({bundle : bundle, successText : "Bundle "+bundle.id+" was updated"})
            }

        }).catch(err=>{
            console.error("Error when saving bundle ", err);
            throw "Unable to create/save bundle";
        });
    }

    onSuccessClose() {
        if((this.props.match.params as any).id == "new") {
            this.setState({successText : ""});
            if(this.state.bundle) {
                this.navigate("/bundles/"+this.state.bundle.id);
            }
        }
    }

    onSubmitError(){}

    formDidUpdate(formModel:Bundle, validationMap : any) {
        this.setState({bundle : formModel, validationMap : validationMap});
    }

    getFormModel():Bundle|undefined {
        return this.state.bundle;
    }

    getTitle() : string {
        return "Edit/Create Bundle";
    }

    renderContent() {
        let bundle = this.state.bundle;
        return (
            
            <Fragment>
                    <div className={"card"}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-2">
                                    <img alt={"bundle"} style={{maxWidth:'128px'}} src={bundle && bundle.imageUrl}></img>
                                </div>
                                <div className="col-md-4">
                                    <h3>{bundle && bundle.price && bundle.price.amount} {bundle && bundle.price && bundle.price.currency}</h3>
                                    <div><b>{bundle && bundle.name}</b> - {bundle && bundle.description}</div>
                                    <div className="badge badge-success">{bundle && bundle.label}</div>
                                    <br/>
                                    <div>+{bundle && bundle.awardFunds1 && bundle.awardFunds1.amount} {bundle && bundle.awardFunds1 && bundle.awardFunds1.currency}</div>
                                    <div>+{bundle && bundle.awardFunds2 && bundle.awardFunds2.amount} {bundle && bundle.awardFunds2 && bundle.awardFunds2.currency}</div>
                                    <div>+{bundle && bundle.awardPokerItemId}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Form className="form-horizontal" formListener={this} modelObject={bundle} validationMap={this.state.validationMap}>
                        <div className={"card"}>
                            <div className="card-header">
                                <h4>Bundle Definition</h4>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <FormSubmitError />
                                        <FormSubmitSuccess text="Bundle was updated successfully"/>

                                        <FormGroup className="form-group mb-3" model="name">
                                            <label>Name</label>
                                            <FormInput className="form-control" model="name" type="text"  validators={[Validators.required()]} />
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group mb-3" model="description">
                                            <label>Description</label>
                                            <FormInput className="form-control" model="description" type="text" validators={[Validators.required()]} />
                                        </FormGroup>
                                        <div className="hr-line-dashed"></div>

                                        <FormGroup className="form-group mb-3" model="label">
                                            <label>Label</label>
                                            <FormInput className="form-control" model="label" type="text" />
                                        </FormGroup>

                                        <FormGroup className="form-group mb-3" model="imageUrl">
                                            <label>Image URL</label>
                                            <FormInput className="form-control" model="imageUrl" type="text" />
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>

                                        <div className="custom-control custom-checkbox">
                                            <FormInput id={"enabled"} className="custom-control-input" model="enabled" type="checkbox"/>
                                            <label htmlFor={"enabled"} className={"custom-control-label"}>Enabled</label>
                                        </div>

                                        <div className="hr-line-dashed"></div>

                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Type</label>
                                            <div className="col-sm-10">
                                                <SelectInput  model="type" values={Object.values(BundleType)} className="form-control"/>
                                            </div>

                                        </FormGroup>

                                        <div className="custom-control custom-checkbox">
                                            <FormInput id={"featured"} className="custom-control-input" model="featured" type="checkbox"/>
                                            <label htmlFor={"featured"} className={"custom-control-label"}>Featured</label>
                                            <span className={"help-block"}>Featured bundles are shown on the landing page.</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"card"}>
                            <div className="card-header">
                                <h4>Price</h4>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12">

                                        <FormGroup className="form-group mb-3" model="price.amount">
                                            <label>Price - Amount</label>
                                            <FormInput className="form-control" model="price.amount" type="number"  validators={[Validators.number()]} />
                                        </FormGroup>
                                        <FormGroup className="form-group mb-3" model="price.currency">
                                            <label>Price - Currency</label>
                                            <FormInput className="form-control" model="price.currency" type="text"  validators={[Validators.textInput()]} />
                                        </FormGroup>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"card"}>
                            <div className="card-header">
                                <h4>Awards</h4>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12">

                                        <FormGroup className="form-group mb-3" model="awardFunds1.amount">
                                            <label>Award Funds 1 - Amount</label>
                                            <FormInput className="form-control" model="awardFunds1.amount" type="number"  validators={[Validators.number()]} />
                                        </FormGroup>
                                        <FormGroup className="form-group mb-3" model="awardFunds1.currency">
                                            <label>Award Funds 1 - Currency</label>
                                            <FormInput className="form-control" model="awardFunds1.currency" type="text"  validators={[Validators.textInput()]} />
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>

                                        <FormGroup className="form-group mb-3" model="awardFunds2.amount">
                                            <label>Award Funds 2 - Amount</label>
                                            <FormInput className="form-control" model="awardFunds2.amount" type="number"  validators={[Validators.number()]} />
                                        </FormGroup>
                                        <FormGroup className="form-group mb-3" model="awardFunds2.currency">
                                            <label>Award Funds 2 - Currency</label>
                                            <FormInput className="form-control" model="awardFunds2.currency" type="text"  validators={[Validators.textInput()]} />
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>

                                        <FormGroup className="form-group mb-3" model="awardPokerItemId">
                                            <label>Poker Item name-id</label>
                                            <FormInput className="form-control" model="awardPokerItemId" type="text"  validators={[Validators.textInput()]} />
                                        </FormGroup>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"card"}>
                            <div className="card-header">
                                <h4>Actions</h4>
                            </div>
                            <div className={"card-body"}>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="ibox float-e-margins">
                                            <div className="ibox-content">
                                                <FormSubmitError />
                                                <FormSubmitSuccess text={this.state.successText} onClose={()=>this.onSuccessClose()}/>
                                                <div className="form-group">
                                                    <div className="col-sm-10 col-sm-offset-2">
                                                        <Submit className="btn btn-primary waves-effect waves-light">Save Bundle</Submit>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Form>


            </Fragment>
        )
    }

}

export default withRouter(BundleView);