import {getStore} from "../RootReducer";
import {ApplicationState, UserStatus} from "../user/UserStore";
import {connect} from "react-redux";
import React, {Fragment, useEffect, useRef} from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import {toggleMenu, UserActions} from "../user/UserActions";
import SideBar from "./SideBar";
import {Routes} from "./Routes";
import CurrencySelector from "./CurrencySelector";
import { Link } from 'react-router-dom';
import {getContainer} from "../ioc/IOCSetup";
import { isMobile } from "react-device-detect";

const stateToProps  = (app : ApplicationState)=> {
    let email = "";
    if(app.user.user && app.user.user.email) {
        email = app.user.user.email;
    }
    return (
        {
            loggedIn : app.user.status == UserStatus.LOGGED_IN,
            email : email,
            expandedMenu : app.user.expandedMenu,
            userId : app.user.user && app.user.user.id,
            logoUrl : app.user.globalModel?.logo
        }
    )
}
const PING_TIMEOUT = 60*1000;
export const keepAlive = () => {
    let http = getContainer().getHttpService();
    if(http.hasSeenActivitySince(30)) {
        getContainer().getUserAuthenticationService().ping().then(() => {});
    } else {
        console.log("User inactive past 30 min");
    }
}
export const SessionBackgroundKeepAlive = (props : {}) => {
    useEffect(() => {

        let interval = setInterval(()=> {
           keepAlive();
        },PING_TIMEOUT);
        return () => {
            clearInterval(interval);
        }
    }, []);
    return <></>
}
export const LoggedInContent = (props : {loggedIn?: boolean, email?: string, expandedMenu?: boolean, userId?: number, logoUrl?: string}) => {
    const sideMenuRef = useRef<HTMLDivElement>(null);
    const toggleMenuButton = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
          if (!props.expandedMenu) {
            return;
          }
    
          const shouldCloseSideBar =
            sideMenuRef.current &&
            !sideMenuRef.current.contains(event.target as Node) &&
            toggleMenuButton.current &&
            !toggleMenuButton.current.contains(event.target as Node);
    
          if (shouldCloseSideBar && isMobile) {
            toggleMenu(false);
          }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [sideMenuRef, toggleMenuButton, props.expandedMenu]);
    
    return (
        <Fragment>
            {props.loggedIn && (
                <Fragment>
                    <SessionBackgroundKeepAlive />
                    <div id="app-container" className={"wrapper"}>

                        <div className="navbar-custom" style={{ marginTop: "-70px" }}>
                            <div className="logo-box">
                                <span className={"logo"} style={{ backgroundImage: `url(${props.logoUrl ?? ""})` }}>
                                </span>
                            </div>
                            <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
                                <li>
                                    <button ref={toggleMenuButton} className="button-menu-mobile waves-effect waves-light" onClick={() => toggleMenu(!getStore().getState().user.expandedMenu)}>
                                        <i className="fe-menu"></i>
                                    </button>
                                </li>
                            </ul>
                            <Nav className="list-unstyled topnav-menu justify-content-end flex-nowrap mb-0">
                                <CurrencySelector />
                                <NavDropdown title={<span>{props.email}<i className="mdi mdi-chevron-down"></i></span>} id="basic-nav-dropdown">
                                    <NavDropdown.Item href={`admin/admin-user/${props.userId}`}>
                                        <i className={"fe-settings"}></i> Settings
                                    </NavDropdown.Item>

                                    <NavDropdown.Item as={Link} to={'/admin/profile/'}>
                                        <i className={"fe-user"}></i> Profile
                                    </NavDropdown.Item>

                                    <NavDropdown.Item onClick={() => new UserActions().logout()}>
                                        <i className={"fe-log-out"}></i> Logout
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Nav>

                        </div>
                        <div ref={sideMenuRef}>
                            <SideBar />
                        </div>
                        <div className={"content-page"}>
                            <div className={"content"}>
                                <div className={"container-fluid"}>
                                    <Routes />
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};

export default connect(stateToProps)(LoggedInContent);