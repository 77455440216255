import * as React from "react";
import {FieldSetListener, SimpleFieldSet} from "../form/Form";
import {Translation} from "../../http/protocol";
import {FormInput} from "../form/FormInput";
import {getContainer} from "../../ioc/IOCSetup";
import {LoadingSpinner} from "../../util/LoadingSpinner";
interface State {
    form : Translation;
    validationMap?: any;
    saving?: boolean;
}
interface Props {
    locale: string
    onAdded? : () => void;
}
export class AddTranslationKeyForm extends React.Component<Props, State> implements FieldSetListener<Translation>{
    constructor(props: Props) {
        super(props);
        this.state = { form : { locale : this.props.locale}, validationMap : {}}
    }
    add() {
        if(this.state.saving) {
            return;
        }
        if(this.state.form.value && this.state.form.key && this.state.form.key.trim().length > 0) {
            getContainer().getTranslationsService().save(this.state.form).then(t => {
                this.props.onAdded && this.props.onAdded();
                this.setState({ form : { locale: this.props.locale }, saving : false})
            })
        }
    }
    render() {
        return (
            <div className="col-lg-12">
                <h5>Add Translation Key</h5>
                <SimpleFieldSet formListener={this} validationMap={this.state.validationMap} modelObject={this.state.form} >
                   <div className={"form form-inline"}>
                       <FormInput placeholder={"Key"}  className={"form-control col-lg-5"}type={"text"} model={"key"}/>
                       <FormInput  placeholder={"Value"} className={"form-control col-lg-5"} type={"text"} model={"value"}/>
                       { !this.state.saving &&  <a className={"btn btn-primary col-lg-1 offset-lg-1"} onClick={()=>this.add()}>Add</a>}
                       { this.state.saving &&  <a className={"btn btn-primary col-lg-1 offset-lg-1"}><LoadingSpinner/></a>}
                   </div>
                </SimpleFieldSet>
            </div>
        )
    }

    formDidUpdate(formModel: Translation, validationMap?: any): void {
        this.setState({ form : formModel, validationMap : validationMap});
    }
}