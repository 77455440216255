
import { Big } from "big.js";

import JSONBig from 'json-bigint';
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { GameIntegration, PermissionCategory, SportsbookBet, SportsbookBetAndEvents, SportsbookBetEvent, SportsbookBetEventType, SportsbookBetResult, SportsbookBetStatus } from "../../http/protocol";
import { t } from "../../i18n";
import { getContainer } from "../../ioc/IOCSetup";
import { SportsbookService } from "../../services/SportsbookService";
import { DataModal } from "../../util/DataModal";
import { formatDate } from "../../util/date";
import { LoadingViewState, RouterView } from "../RouterView";
import { PermissionRequired } from "../security/PermissionRequired";


interface State extends LoadingViewState {
    bet?: SportsbookBet,
    betEvents?: SportsbookBetEvent[],
    selectedEvent?: SportsbookBetEvent
}


export class SportsbookBetView extends RouterView<RouteComponentProps,State> {


    sportsbookService: SportsbookService;

    constructor(props : RouteComponentProps){
        super(props);
        this.sportsbookService = getContainer().getSportsbookService();

        this.state = { };
    }

    getTitle(): string {
        return t('sportsbookBetDetails.pageTitle')
    }

    componentDidUpdate(prevProps : RouteComponentProps, prevState : State) {
    }

    async loadContent() {
        const betId = (this.props.match.params as any).id
        const bae = await this.sportsbookService.getBetAndEvents(betId)

        this.setState({ bet: bae.bet, betEvents: bae.events })
    }



    async updateStatusAndResult(status: SportsbookBetStatus, result: SportsbookBetResult) {
        this.setState({loading: true})
        const bae = await this.sportsbookService.updateBet(this.state.bet?.id!, {status: status, result: result})
        this.setState({loading: false, bet: bae.bet, betEvents: bae.events})
    }



    renderEventRows() {
        return (this.state.betEvents ?? []).map(e => {
            return (
                <tr key={e.id}>
                    <td>{e.type}</td>
                    <td>{formatDate(e.timestamp)}</td>
                    <td>{e.status}</td>
                    <td>{e.result}</td>
                    <td> {e.txId && <Link to={`/transaction/${e.txId}`}>{e.amount  &&  e.amount} ({e.txId})</Link>}</td>
                    <td><code>{e.externalEventId}</code></td>
                    <td>{e.message}</td>
                    <td>{e.data && <button className="btn btn-xs btn-primary" onClick={() => this.setState({selectedEvent: e})} >View Data</button>}</td>
                </tr>
            )
        });
    }

    renderExternalId() {
        const b = this.state.bet!;

        switch (b.provider) {
            case GameIntegration.SABA:
                const txns = (this.state.betEvents ?? [])
                    .filter(e => e.type == SportsbookBetEventType.CONFIRMED)
                    .flatMap(e => JSONBig.parse(e.data!)["message"]["txns"])
                    .map(e => e["txId"]);

                return <>{b.externalId}, {txns.join(", ")}</>

            default:
                return <>{b.externalId}</>
        }
    }

    renderAttributeRows() {
        return Object.entries(this.state.bet?.attributes ?? []).map(
            ([key, value]) => <tr key={key}><td><pre>{key} = </pre></td><td><pre>{value}</pre></td></tr>
        );
    }


    renderContent() {
        if(!this.state.bet) {
            return <></>
        }

        const bet = this.state.bet;

        const txSummary = this.state.betEvents
            ?.filter(e => e.amount)
            .map(e => new Big(e.amount!))
            .reduce((sum, amt) => sum.add(amt), new Big(0))

        return (
            <div className="wrapper wrapper-content animated fadeInRight">

                <DataModal heading="Event Data" show={this.state.selectedEvent != undefined} data={this.state.selectedEvent?.data} onClose={() => this.setState({selectedEvent: undefined})} ></DataModal>

                <div className={"col-lg-12 col-md-12"}>
                    <div className="row">
                        <div className={"col-lg-12"}>
                            <div className={"card-box"}>
                                <h4>{t('sportsbookBetDetails.data.title')}</h4>
                                <table className="table table-striped">
                                    <thead>
                                    </thead>
                                    <tbody>
                                        <tr><th>{t('sportsbookBetDetails.data.betId')}</th><td>{bet.id}</td></tr>
                                        <tr><th>{t('sportsbookBetDetails.data.userId')}</th><td><Link to={`/user/${bet.userId}`}>{bet.userId}</Link></td></tr>
                                        <tr><th>{t('sportsbookBetDetails.data.type')}</th><td>{bet.type}</td></tr>
                                        <tr><th>{t('sportsbookBetDetails.data.live')}</th><td>{t(`status.${bet.live ? 'yes' : 'no'}`)}</td></tr>
                                        <tr><th>{t('sportsbookBetDetails.data.odds')}</th><td>{bet.odds == null ? '' : `${bet.odds.odds} / ${bet.odds.type}`}</td></tr>
                                        <tr><th>{t('sportsbookBetDetails.data.oddsDecimal')}</th><td>{bet.oddsDecimal}</td></tr>
                                        <tr><th>{t('sportsbookBetDetails.data.externalId')}</th><td>{this.renderExternalId()}</td></tr>
                                        <tr><th>{t('sportsbookBetDetails.data.provider')}</th><td>{bet.provider}</td></tr>
                                        <tr><th>{t('sportsbookBetDetails.data.stake')}</th><td>{bet.currency} {bet.stake}</td></tr>
                                        <tr><th>{t('sportsbookBetDetails.data.remoteStake')}</th><td>{bet.remoteCurrency} {bet.remoteCurrencyStake}</td></tr>
                                        <tr><th>{t('sportsbookBetDetails.data.status')}</th><td>{bet.status}</td></tr>
                                        <tr><th>{t('sportsbookBetDetails.data.result')}</th><td>{bet.result}</td></tr>
                                        <tr><th>{t('sportsbookBetDetails.data.created')}</th><td>{formatDate(bet.created)}</td></tr>
                                        <tr><th>{t('sportsbookBetDetails.data.matchTime')}</th><td>{formatDate(bet.matchTime)}</td></tr>
                                        <tr><th>{t('sportsbookBetDetails.data.sports')}</th><td>{bet.meta?.sports?.join(", ")}</td></tr>
                                        <tr><th>{t('sportsbookBetDetails.data.events')}</th><td>{bet.meta?.events?.join(", ")}</td></tr>
                                    </tbody>
                                </table>

                                {bet.attributes  && 
                                    <>
                                        <h4>{t('sportsbookBetDetails.data.attributes')}</h4>
                                        <table className="table table-striped">
                                            <thead>
                                            </thead>
                                            <tbody>
                                                {this.renderAttributeRows()}
                                            </tbody>
                                        </table>
                                    </>
                                }
                            </div>
                        </div>


                        <div className="col-lg-12">
                            <div className={"card-box"}>
                                <h4>{t('sportsbookBetDetails.event.title')}</h4>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>{t('sportsbookBetDetails.event.type')}</th>
                                            <th>{t('sportsbookBetDetails.event.ts')}</th>
                                            <th>{t('sportsbookBetDetails.event.status')}</th>
                                            <th>{t('sportsbookBetDetails.event.result')}</th>
                                            <th>{t('sportsbookBetDetails.event.txId')} ({this.state.bet.currency})</th>
                                            <th>{t('sportsbookBetDetails.event.externalEventId')}</th>
                                            <th>{t('sportsbookBetDetails.event.message')}</th>
                                            <th>{t('sportsbookBetDetails.event.data')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderEventRows()}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th>{txSummary?.toString()}</th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className={"card-box"}>
                                <h4>{t('sportsbookBetDetails.actions.title')}</h4>
                                <p>{t('sportsbookBetDetails.actions.desc')}</p>

                                <PermissionRequired accessLevel="w" permissions={[PermissionCategory.SPORTSBOOK]} showError={true}>
                                    <>
                                        <p><i className="fa fa-exclamation-triangle"></i> {t('sportsbookBetDetails.actions.important1')}</p>
                                        <p><i className="fa fa-exclamation-triangle"></i> {t('sportsbookBetDetails.actions.important2')}</p>

                                        <button className="btn btn-warning" onClick={() => this.updateStatusAndResult(SportsbookBetStatus.COMPLETED, SportsbookBetResult.LOST)}>{t('sportsbookBetDetails.actions.button.completeAsLost')}</button>&nbsp;
                                        <button className="btn btn-warning" onClick={() => this.updateStatusAndResult(SportsbookBetStatus.COMPLETED, SportsbookBetResult.WON)}>{t('sportsbookBetDetails.actions.button.completeAsWon')}</button>&nbsp;
                                        <button className="btn btn-warning" onClick={() => this.updateStatusAndResult(SportsbookBetStatus.COMPLETED, SportsbookBetResult.CANCELED)}>{t('sportsbookBetDetails.actions.button.completeAsCanceled')}</button>
                                    </>
                                </PermissionRequired>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        )
    }

}

export default withRouter(SportsbookBetView);