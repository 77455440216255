import React, {Fragment} from "react";
import {ActionType} from "../list/list/DataTable";
import {ListCasinoLobbyCategoryRequest, ListChartsRequest, User} from "../../http/protocol";
import {getContainer} from "../../ioc/IOCSetup";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {PagerModel} from "../list/list/PagerModel";
import {ListUtils} from "../list/list/ListUtils";
import {withRouter} from "react-router";
import {ListView, ListViewProps, ListViewState} from "../ListView";
import {Form, FormListener} from "../form/Form";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Validators} from "../form/Validators";
import {Submit} from "../form/Submit";
import {CasinoCategoryService} from "../../services/CasinoCategoryService";
import { ConfirmModal } from "../../util/ConfirmModal";


interface State extends ListViewState<ListCasinoLobbyCategoryRequest> {
    form? : ListCasinoLobbyCategoryRequest;
    showErrorOnDelete: boolean;
    validationMap? : any;
}
interface Props extends ListViewProps  {

}

export class CasinoCategoryListView extends ListView<State, ListChartsRequest> implements FormListener<ListCasinoLobbyCategoryRequest>{

    columns = [
        { property : "id", name : "ID", orderBy : "id"},
        { property : "name", name : "Name"},
        { property : "lobbyOrder", name : "Order", orderBy : "lobbyOrder"}
    ];

    actions = [
        { id : "delete", name : "delete", className : "btn btn-danger", iconClassName : "fa fa-times", type : ActionType.CONFIRMATION},
        { id : "view", name : "View", className : "btn btn-primary", iconClassName : "fa fa-user", type : ActionType.LINK, getLink : (item : any) => "/casino-category/" + item.id }

    ];

    service : CasinoCategoryService;

    constructor(props : Props){
        super(props, "/casino-categories");
        this.state = {...this.state,  listRequest : {...this.state.listRequest, orderBy : "lobbyOrder"}, validationMap : {}, form : {}, showErrorOnDelete: false};
        this.service = getContainer().getCasinoCategoryService();
    }
    onSubmit() : void | Promise<any> {
        if(this.state.form) {
            let form = this.state.form;
            form.offset = 0;
            this.updateListState(form);
        }
    }
    onSubmitError() :  void {

    }

    formDidUpdate(formModel: ListCasinoLobbyCategoryRequest, validationMap?: any): void {
        this.setState({form : formModel, validationMap : validationMap});
    }

    async onAction(item : User, action : string) : Promise<Promise<any> | undefined> {
        if(action == "delete") {
            try {
                await this.service.remove(""+item.id);
            } catch (error) {
                this.setState({showErrorOnDelete: true})
                throw error;
            }
        }
    }

    pagerChanged(pager : PagerModel) {
        if(this.state.listRequest) {
            console.log("pager changed!", pager);
            let listRequest = ListUtils.toListRequest<any>(this.state.listRequest,pager);
            this.updateListState(listRequest);
        }
    }
    getTitle() {
        return "Lobby Categories"
    }

    renderContent() {
        if(!this.state.listRequest) {
            return <div/>;
        }
        return (
            <Fragment>
                <ConfirmModal
                    show={this.state.showErrorOnDelete}
                    heading="Unable to Delete Category"
                    description={ `Make sure the category is not in use by a lobby page` }
                    okLabel="Ok"
                    onOk={ () => this.setState({ showErrorOnDelete: false }) }
                />

                <div className={"card-box"}>
                    <h4>Search Filters</h4>
                    <Form formListener={this} modelObject={this.state.form} validationMap={this.state.validationMap}>
                        <FormGroup className={"form-group"}>
                            <label>Name</label>
                            <FormInput model={"name"} className={"form-control"} validators={[Validators.strLength(1,255)]}/>
                            <span className={"help-text"}>Search for tags </span>
                        </FormGroup>
                        <Submit className={"btn btn-primary"}>Search</Submit>
                    </Form>
                </div>
                <EntityDataTable service={this.service}
                                 onPagerChange={this.pagerChanged.bind(this)}
                                 columns={this.columns}
                                 listRequest={this.state.listRequest}
                                 onAction={this.onAction.bind(this)}
                                 actions={this.actions}/>
            </Fragment>
        );
    }



}

export default withRouter(CasinoCategoryListView);
