import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AsyncStatus, addStylesheet, extractClassnames } from "./utils";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../user/UserStore";
import { setIconSettings } from "../../settings/SettingsActions";
import Axios from "axios";
import iconIndex from "../../assets/icons/index.json";

interface EditIconProps {
    saveIcon: (tag: string) => void;
    isOpen: boolean;
    setIsOpen: () => void;
    icon?: string;
}

type IconPack = "custom" | "default";

export default function EditIcons(props: EditIconProps) {
    const iconSettings = useSelector((app: ApplicationState) => app.settings.iconSettings);
    const [status, setStatus] = useState<AsyncStatus>(AsyncStatus.idle);

    const [iconNames, setIconNames] = useState<string[]>([]);
    const [iconOption, setIconOption] = useState<IconPack>("default");

    const loadIconPack = async (url: string) => {
        try {
            const response = await Axios.get(url);

            if (response.status !== 200) {
                throw new Error(`Failed to load icon pack. Status code: ${response.status}`);
            }

            await addStylesheet(url);
            setStatus(AsyncStatus.success);
            let classNames = extractClassnames(response.data);
            setIconSettings({ url: url, classes: classNames });
            setIconNames(classNames);
        } catch (error) {
            console.error("Error loading icon pack:", error);
            setStatus(AsyncStatus.fail);
        }
    };

    const { register, watch, setValue, reset, handleSubmit } = useForm<{ customIcon: string }>();
    let customIcon = watch("customIcon");

    const setIcons = (val: string) => {
        setValue("customIcon", val);
    };

    useEffect(() => {
        if (props.icon) {
            setValue("customIcon", props.icon);
        }
    }, [props.icon, props.isOpen]);

    const onSubmit = (values: { customIcon: string }) => {
        if (values.customIcon) {
            props.saveIcon(values.customIcon);
        }
        props.setIsOpen();
        reset({ customIcon: "" });
    };

    const ClearIcon = (values: { customIcon: string }) => {
        props.saveIcon("");
        props.setIsOpen();
        reset({ customIcon: "" });
    };

    useEffect(() => {
        loadIconPack(iconSettings?.url ?? "");
    }, []);

    return (
        <Modal show={props.isOpen} onHide={() => props.setIsOpen()}>
            <div className="icon-modal-container">
                <Modal.Header>
                    <h4>Assign Icon</h4>
                </Modal.Header>
                <Modal.Body className="custom-icon-modal">
                    <section className="mt-2">
                        {iconSettings && iconSettings.url ? (
                            <div className="alert alert-info w-100 mt-2">
                                Custom icon pack is <a className="text-success">active</a>
                            </div>
                        ) : (
                            <div className="alert alert-info w-100 mt-2">
                                To use custom icon pack read <a href="https://docs.cubeia.com/docs/how-to-override-icon-pack" style={{ color: "blue" }}>documentation</a>
                            </div>
                        )}
                        <div className="icon-preview-list">
                            {iconIndex &&
                                iconIndex.fileIndex &&
                                iconIndex.fileIndex.map((b) => {
                                    return (
                                        <div key={b} onClick={() => setIcons(b)} className={`d-flex w-100 justify-content-center icon-grid-cell ${customIcon === b ? "selected" : ""}`}>
                                            <i key={b} className={`custom-icon-pack ` + b} />
                                        </div>
                                    );
                                })}
                        </div>
                    </section>
                    <div className="hr-line-dashed mt-3"></div>
                    <div className="pb-1 px-2">
                        <span className="help-block">
                            Select icon to be displayed with link. To customize styling and color, check our documentation for instructions on overriding the default classname. <br />
                            <a href="https://docs.cubeia.com/docs/navigation-sidemenu" target="_blank" rel="noopener noreferrer">
                                Documentation
                            </a>
                        </span>
                    </div>
                    <div className="hr-line-dashed mt-1"></div>

                    <div className={"form-group mt-2 px-2"}>
                        <label>Custom Icon Class</label>
                        <input {...register("customIcon")} className={"form-control"} />
                        <span className={"help-block"}>To customize your own icons provide a classname, check our documentation for instructions on overriding and custom icons. </span>
                        <br />
                        <a href="https://docs.cubeia.com/docs/navigation-sidemenu" target="_blank" rel="noopener noreferrer">
                            Documentation
                        </a>
                    </div>
                    <div className={"form-group px-2"}>
                        <button onClick={handleSubmit(ClearIcon)} type="button" className={"btn btn-primary"}>
                            Clear Icon
                        </button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="form-group">
                        <button
                            onClick={() => {
                                props.setIsOpen();
                                reset({ customIcon: "" });
                            }}
                            type="button"
                            className={"btn btn-primary"}
                        >
                            Cancel
                        </button>
                        <button onClick={handleSubmit(onSubmit)} type="button" className={"btn btn-success"}>
                            Save
                        </button>
                    </div>
                </Modal.Footer>
            </div>
        </Modal>
    );
}
