import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {HttpService} from "../http/HttpService";
import {AdminScriptWidget, AdminScriptWidgetListResponse} from "../http/protocol";


export class ScriptWidgetService extends AbstractAdminEntityService<AdminScriptWidget, AdminScriptWidgetListResponse>   {
    

    constructor(http : HttpService) {
        super("/widgets", http)
    }



}