import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {HttpService} from "../http/HttpService";
import {JourneyListResponse, KycStatus, ListUsersResponse, User, UserJourneyWrapper} from "../http/protocol";


export class UserJourneyService extends AbstractAdminEntityService<UserJourneyWrapper, JourneyListResponse>   {
    

    constructor(http : HttpService) {
        super("/user-journey", http)
    }



}