import React from "react";
import {getContainer} from "../../ioc/IOCSetup";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {LoadingViewState, RouterView} from "../RouterView";
import {SettingsService} from "../../services/SettingsService";
import {Settings} from "../../http/protocol";
import {LoadingSpinner} from "../../util/LoadingSpinner";
import {ProviderService} from "../../services/ProviderService";


interface State extends LoadingViewState {
    loading:boolean;
    settings?:Settings;
    url?:string;
}

interface Props extends RouteComponentProps{

}

export class AmplitudeManagerView extends RouterView<Props,State> {
    settingsService:SettingsService;
    providerService:ProviderService;

    constructor(props : Props) {
        super(props);
        this.settingsService = getContainer().getSettingsService();
        this.providerService = getContainer().getProviderService();
        this.state = {loading:true}
    }

    loadContent(): Promise<any>  {
        console.log("loading manager datatt")
        return this.settingsService.getSettings().then((settings:Settings) => {

            const url = this.providerService.getIntegrationBOUrl("amplitude");
            url.length > 0 && window.open(url);
            this.setState({settings:settings, loading: false, url:url});
        })
    }

    getTitle(): string {
        return "Amplitude";
    }

    renderContent() {
        if(this.state.settings == undefined) {
            return <LoadingSpinner />
        } else {
            if(!this.state.url) {
                return <div>No URL set for Amplitude</div>
            } else {
                return (
                    <div>
                        <div className={"card-box"}>
                            <div className="ibox-content">
                                Amplitude is an analytics tool where you can access and explore data and metrics with easy-to-use segmentation. Get instant, meaningful insights into what is and isn't effective.<br/><br/>

                                Here is a <a href="https://www.youtube.com/watch?time_continue=15&v=cBntU_cTF1E&feature=emb_logo" target="_blank">introduction video on how  to use Amplitude’s segmentation feature.</a><br/><br/>

                                Events are pushed to Amplitude in near-real time from Nano so all the latest data is readily available for analysis.<br/><br/>

                                Depending on the setup of your system you might have multiple projects setup (or not). The naming might differ but this would be the typical naming conventions:<br/><br/>

                                <strong>Nano</strong> - Server to server events from the Nano platform<br/><br/>

                                <strong>Web Site</strong> - Client to server events to track client features and behavior. This data is not 100% reliable as some adblockers will block outgoing events.<br/><br/>

                                <strong>Poker</strong> - Cubeia Poker specific events.<br/><br/>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }

}

export default withRouter(AmplitudeManagerView);
