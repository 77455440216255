import {AdminUser, ContentfulGlobalModel, LoginRequest, LoginResponse, Settings} from "../http/protocol";
import { IconSettings } from "../http/Types";

import {getContainer} from "../ioc/IOCSetup";
import { createAndDispatch } from "../RootReducer";
import {Dispatch} from "redux";

export const ADMIN_SETTINGS = "ADMIN_SETTINGS";
export async function fetchAdminSettings() {
    const settings = await getContainer().getSettingsService().getAdminSettings()
    return createAndDispatch(ADMIN_SETTINGS, settings);
}

export const ICON_SETTINGS = "ICON_SETTINGS";
export const setIconSettings = (settings: IconSettings) => {
    return createAndDispatch(ICON_SETTINGS, settings);
}