import {AdminMfaProvider, AdminUser, ContentfulGlobalModel, LoginRequest, LoginResponse} from "../http/protocol";

import {getContainer} from "../ioc/IOCSetup";
import { createAndDispatch } from "../RootReducer";
import {Dispatch} from "redux";
import { fetchAdminSettings } from "../settings/SettingsActions";

export const HANDLE_LOGIN = "HANDLE_LOGIN";
export function handleLogin() {
    return createAndDispatch(HANDLE_LOGIN, {});
}

export const HANDLE_LOGIN_ERROR = "HANDLE_LOGIN_ERROR";
export function handleLoginError() {
    return createAndDispatch(HANDLE_LOGIN_ERROR, {});
}
export const HANDLE_NOT_LOGGED_IN = "HANDLE_NOT_LOGGED_IN";
export function handleNotLoggedIn() {
    return createAndDispatch(HANDLE_NOT_LOGGED_IN, {});
}
export const HANDLE_FORBIDDEN_REQUEST  = "HANDLE_FORBIDDEN_REQUEST";
export function handleForbiddenRequest() {
    return createAndDispatch(HANDLE_FORBIDDEN_REQUEST, {});
}
export const HANDLE_RESET_FORBIDDEN_STATUS = "HANDLE_RESET_FORBIDDEN_STATUS";
export function handleResetForbiddenStatus() {
    return createAndDispatch(HANDLE_RESET_FORBIDDEN_STATUS, {});
}

export const HANDLE_LOGIN_SUCCESS = "HANDLE_LOGIN_SUCCESS";
export function handleLoginSuccess(w : { user : AdminUser, permissions : any}) {
    return createAndDispatch(HANDLE_LOGIN_SUCCESS, w);
}

export const HANDLE_FORCE_CHANGE_PASSWORD = "HANDLE_FORCE_CHANGE_PASSWORD"
export function handleForceChangePassword(otpRequired?: boolean, mfaProvider?: AdminMfaProvider) {
    return createAndDispatch(HANDLE_FORCE_CHANGE_PASSWORD, { otpRequired : otpRequired, mfaProvider});
}
export const HANDLE_OTP_REQUIRED = "HANDLE_OTP_REQUIRED"
export function handleOtpRequired(username: string, password: string, mfaProvider: AdminMfaProvider | undefined) {
    return createAndDispatch(HANDLE_OTP_REQUIRED, { username, password, mfaProvider });
}
export const HANDLE_OTP_INVALID = "HANDLE_OTP_INVALID";
export function handleInvalidOtp() {
    return createAndDispatch(HANDLE_OTP_INVALID, {});
}

export const INVALID_PASSWORD_CHANGE = "INVALID_PASSWORD_CHANGE"
export function handleInvalidPasswordChange(otpRequired?: boolean) {
    return createAndDispatch(INVALID_PASSWORD_CHANGE, { otpRequired : otpRequired });
}

export const TOGGLE_MENU = "TOGGLE_MENU";
export function toggleMenu(show : boolean) {
    return createAndDispatch(TOGGLE_MENU, show);
}

export const CONTENT_GLOBAL = "CONTENT_GLOBAL";
export const CONTENT_GLOBAL_PROGRESS = "CONTENT_GLOBAL_PROGRESS";
export const CONTENT_GLOBAL_ERROR = "CONTENT_GLOBAL_ERROR";
export function fetchLogoUrl() {
    createAndDispatch(CONTENT_GLOBAL_PROGRESS, {});
    getContainer().getSettingsService().getContentfulGlobalModel().then((r : ContentfulGlobalModel) => {
        createAndDispatch(CONTENT_GLOBAL, r);
    }).catch((e)=>{
        createAndDispatch(CONTENT_GLOBAL_ERROR,{});
    })
}
export const HANDLE_PASSWORD_CHANGE_IN_PROGRESS = "HANDLE_PASSWORD_CHANGE_IN_PROGRESS";
export function handlePasswordChangeInProgress() {
    return createAndDispatch(HANDLE_PASSWORD_CHANGE_IN_PROGRESS, {});
}
export class UserActions  {





    changePasswordAndLogin(username : string, password : string, newPassword : string, otp?: string){
        handlePasswordChangeInProgress();
        getContainer().getUserAuthenticationService().changePasswordAndLogin(username, password, newPassword, otp).then( (r : LoginResponse) => {
            if(r.status == "SUCCESS") {
                this.loginResponse(r, username, password);
            } else {
                handleInvalidPasswordChange();
            }
        }).catch(e => {
            handleInvalidPasswordChange();
        });
    }
    login(username : string, password : string, otp?: string) {
        handleLogin();
        getContainer().getUserAuthenticationService().login(username,password, otp).then(response => {
            this.loginResponse(response, username, password);
        }).catch( e => {
            console.log("Login failed", e);
            this.loginFailed();
        })
        return true;
    }
    logout() {
        getContainer().getUserAuthenticationService().logout().then(()=>{
            this.notLoggedIn();
        }).catch(()=>{

        });
        return true;
    }
    loginResponse(response : LoginResponse, username : string, password  : string) {
        // INVALID_TOKEN, INVALID_OTP, OTP_REQUIRED
        if(response.status == "PASSWORD_CHANGE_REQUIRED") {
            handleForceChangePassword();
        } else if(response.status == "INVALID_PASSWORD_CHANGE") {
            handleInvalidPasswordChange();
        } else if(response.status == "INVALID_PASSWORD_CHANGE_OTP") {
            handleInvalidPasswordChange(true);
        } else if(response.status == "PASSWORD_CHANGE_WITH_OTP_REQUIRED") {
            handleForceChangePassword(true, response.provider);
        } else if(response.status == "SUCCESS") {
            this.getUserInfo();
        } else if(response.status == "OTP_REQUIRED") {
            handleOtpRequired(username,password, response.provider);
        } else if(response.status == "INVALID_OTP") {
            handleInvalidOtp();
        } else {
            this.loginFailed();
        }
        return response;

    }
    getUserInfo( afterLogin? : boolean) {

        getContainer().getUserAuthenticationService().loadUserInfo().then(user => {
            getContainer().getUserAuthenticationService().getGroupPermissions().then(map=>{
                this.loginSuccess({user : user, permissions : map});
            });
        }).catch(e => {
            console.log("get user info error", this);
            console.log("Login error", e);
            handleNotLoggedIn();
        });
        return true;
    }
    requestForbidden() {
        handleForbiddenRequest();
    }
    resetForbiddenStatus() {
        handleResetForbiddenStatus();
    }
    loginSuccess(wrapper : {user : AdminUser, permissions : { [key:string] : boolean}}) {
        handleLoginSuccess(wrapper);
    }
    loginFailed() {
       handleLoginError();
    }
    notLoggedIn() {
        handleNotLoggedIn();
    }


}

