import * as React from "react";

export interface AlertProps {
    text? : string;
    type?: string;
}

export class Alert extends React.Component<AlertProps,{}> {

    constructor(props : AlertProps) {
        super(props);

    }

    getTexts() {
        if(!this.props.text) {
            return "";
        }
        return this.props.text.split("\n").map((text: string,index) => {
            let type = this.props.type;
            if (type === "variable") {
                type = text.endsWith("Ok") ? "success" : "danger";
            }
            return (
                <div key={index} className={'alert alert-' + type}>{text}</div>
            );
        });
    }
    render() {
        if(this.props.text == null || this.props.text == "") {
            return <div/>
        } else {
            return <div>{this.getTexts()}</div>;
        }
    }
}