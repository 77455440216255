import React, {Fragment} from "react";
import {
    ListRewardTemplateRequest,
    ListUserGameRewardRequest,
    ListUsersRequest,
    RewardStatus
} from "../../http/protocol";
import {createInitialState, ListView, ListViewProps, ListViewState} from "../ListView";
import {Form, FormListener} from "../form/Form";
import {ColumnType} from "../list/list/ColumnType";
import {PagerModel} from "../list/list/PagerModel";
import {ListUtils} from "../list/list/ListUtils";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {ActionType} from "../list/list/DataTable";
import {withRouter} from "react-router";
import {getContainer} from "../../ioc/IOCSetup";
import {UserGameRewardService} from "../../services/UserGameRewardService";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Validators} from "../form/Validators";
import {SelectInput} from "../form/SelectInput";
import {Submit} from "../form/Submit";

interface State extends ListViewState<ListRewardTemplateRequest> {
    listRequest?: ListUserGameRewardRequest;
    form?: ListUserGameRewardRequest;
    validationMap?: any;
}

interface Props extends ListViewProps{
}


export class RewardTemplateListView extends ListView<State, ListUserGameRewardRequest> implements FormListener<ListUserGameRewardRequest> {

    columns = [
        { property : "id", name : "Id", orderBy : "id"},
        { property: "rewardTemplate.name", name: "Reward Template Name"},
        { property: "rewardTemplate.id", name: "Reward Template ID"},
        { property : "userId", name : "User ID" },
        { property : "created", name : "Created", type : ColumnType.DATE},
        { property : "claimTime", name : "Claimed" , type:  ColumnType.DATE },
        { property: "nrOfFreeSpins", name : "Nr Of Freespins"},
        { property: "removed", name : "Removed", renderer : (prop : string, name : string, item : any) => item.removed ?  <span className={"badge badge-danger"}>Yes</span> : "No"},
        { property: "expires", name : "Expires", type: ColumnType.DATE},
    ];


    service : UserGameRewardService;

    constructor(props : Props){
        super(props, "/user-rewards/list");
        this.service = getContainer().getUserGameRewardService();

        if (this.state.listRequest){
            let initialForm = {
                limit: 20,
                orderBy: "id",
                ascending: false
            };
            this.state = createInitialState(
                initialForm,
                this.state = {...this.state});
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.service.list({}).then((res) => {
            this.setState({})
        })
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    formDidUpdate(formModel: ListUsersRequest, validationMap : any): void {
        this.setState({ form : formModel, validationMap : validationMap});
    }

    onSubmitError(): void {

    };

    onSubmit() : void | Promise<any> {
        if(this.state.form) {
            let form = this.state.form;
            form.offset = 0;
            this.updateListState(form);
        }
    }

    onStatusesChanged(statuses: string[]) {
        let newReq = Object.assign({}, this.state.listRequest, { statuses: statuses });
        this.setState({ listRequest: newReq });
    }

    pagerChanged(pager : PagerModel) {
        if (this.state.listRequest) {
            var listRequest = ListUtils.toListRequest(this.state.listRequest, pager);
            this.setState({listRequest : listRequest});
        }
    }


    getTitle() {
        return "User Game Rewards";
    }

    onAction (item : any,action : string)  {
        if(action === "delete") {
            return this.service.removeAward(""+item.id)
        }
    }
    renderContent() {
        if (!this.state.listRequest) {
            return <div />;
        }
        return (
            <Fragment>
                <div>
                    <div className="wrapper wrapper-content">
                        <div className={"card-box"}>
                            <h4>Search Filters</h4>
                            <Form formListener={this} modelObject={this.state.form} validationMap={this.state.validationMap}>
                                <FormGroup className={"form-group"}>
                                    <label>User Id</label>
                                    <FormInput model={"userId"} className={"form-control"} type={"integer"} validators={[Validators.integer()]}/>
                                </FormGroup>
                                <FormGroup className={"form-group"}>
                                    <label>Status</label>
                                    <SelectInput optional={true} model={"status"} className={"form-control"} values={Object.keys(RewardStatus)}/>
                                    <span className={"help-text"}>Status of the reward</span>
                                </FormGroup>
                                <Submit className={"btn btn-primary"}>Search</Submit>
                            </Form>
                        </div>
                        <EntityDataTable service={this.service}
                                         onPagerChange={this.pagerChanged.bind(this)}
                                         columns={this.columns}
                                         listRequest={this.state.listRequest}
                                         onAction={this.onAction.bind(this)}
                                         actions={
                                             [
                                                 { id : "delete", name : "Remove", className : "btn btn-danger", iconClassName : "fa fa-times", type : ActionType.CONFIRMATION },
                                                 { id: "details", name: "Details", className: "btn btn-primary", type: ActionType.LINK, getLink: (item: any) => {
                                                         return "/user-reward/" + item.id
                                                     } },
                                             ]
                                         }/>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default withRouter(RewardTemplateListView);