import React from "react";
import {ActionType} from "./DataTable";
import {t} from "../../../i18n";

interface Props {
    akey:any
    onAction:()=>any;
    disabled:boolean;
    type:ActionType;
}
interface State {
    disabled?:boolean
}

export class ToggleButton extends React.Component<Props,State>{
    constructor(props:Props) {
        super(props);
        this.state = {disabled : this.props.disabled};
    }

    onClick() {
        this.props.onAction();
        this.setState({disabled:!this.state.disabled});
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        if(this.props.type == ActionType.BLOCK) {
            if(this.state.disabled) {
                return <a key={"action-"+this.props.akey} onClick={() => this.onClick()} className="btn btn-danger"><i className="fa fa-thumbs-up"></i> {t(`button.unblock`)}</a>;
            } else {
                return <a key={"action-"+this.props.akey} onClick={() => this.onClick()} className="btn btn-success"><i className="fa fa-thumbs-down"></i> {t(`button.block`)}  </a>;
            }
        }
        if(this.props.type == ActionType.APPROVE) {
            if(!this.state.disabled) {
                return <a key={"action-"+this.props.akey} onClick={() => this.onClick()} className="btn btn-success" ><i className="fa fa-thumbs-up"></i>{t(`button.approve`)}</a>;
            } else {
                return <a key={"action-"+this.props.akey} onClick={() => this.onClick()} className="btn btn-danger"><i className="fa fa-thumbs-down"></i>{t(`button.disapprove`)}</a>;
            }
        }
        if(this.props.type == ActionType.DEFAULT) {
            if(this.state.disabled) {
                return <a key={"action-"+this.props.akey} onClick={() => this.onClick()} className="btn btn-danger"><i className="fa fa-thumbs-down"></i> {t(`button.enable`)}</a>;
            } else {
                return <a key={"action-"+this.props.akey} onClick={() => this.onClick()} className="btn btn-success"><i className="fa fa-thumbs-up"></i> {t(`button.disable`)}</a>;
            }
        }
    }

}