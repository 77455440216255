import {AdminEntityService} from "./AdminEntityService";
import {ListResponse, UnorderedListRequest} from "../http/protocol";
import {HttpService} from "../http/HttpService";


export class AbstractAdminEntityService<E, V extends ListResponse> implements AdminEntityService<E> {
    http : HttpService;

    entityPath : string;

    constructor(entityPath : string, httpService : HttpService) {
        this.http = httpService;
        this.entityPath = entityPath;
    }


    save(entity:E):Promise<E> {
        return this.http.post(this.entityPath, JSON.stringify(entity));
    }

    list<E extends UnorderedListRequest>(listRequest : E):Promise<V> {
        return this.http.post(this.entityPath + "/list",listRequest);
    }

    getEntity(id:string):Promise<E> {
        return this.http.get(this.entityPath + "/"+id);
    }

    remove(id:string) : Promise<{}> {
        return this.http.delete(this.entityPath + "/" + id);
    }

}
