/* eslint-disable prefer-const */
import React, { useRef, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { capitalizeFirstLetter, useClickOutside } from "../../../util/utils";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import Form from "react-bootstrap/Form";
import { t } from "../../../i18n";
export enum ListFilters {
  LIMIT = "limit",
  SORT = "sort",
  ORDER = "order",
  VIEW = "view",
  ORDERBY= "orderBy"
}

export type FilterOptions = {
  [ListFilters.LIMIT]?: number[];
  [ListFilters.ORDER]?: string[];
  [ListFilters.VIEW]?: string[];
  [ListFilters.ORDERBY]?:string[];

};

interface ListFilterPorps {
  setFilter?: any;
  viewBy?: boolean;
  setViewBy?: any;
  filterOptions?: FilterOptions;
  setTracker?: any;
  currentTracker?: any;
  trackers?: any;
  setHadDeposit?: any;
  hadDeposit?: any;
  setGroupByTracker?: any;
  groupByTracker?: boolean;
  setOrderBy?:any;
}

const initialFilterState = {
  limit: 30,
  ascending: undefined,
  orderBy: undefined,
  offset: 0,
  currentIndex:1,
};
const animatedComponents = makeAnimated();
const ListFilter: React.FC<ListFilterPorps> = (props) => {
  const menuRef = useRef(null);
  const [show, setshow] = useState(false);
  const [filter, setFilter] = useState<typeof initialFilterState>(initialFilterState);
  const [view, setView] = useState<string>("Daily");
  const [orderBy, setOrderBy] = useState<string>("");
  const setToggleState = () => setshow(false);
  useClickOutside(menuRef, setToggleState);

  const handleChange = (e: any, v: string | number | boolean) => {
    if (!e || !v) return;
    setFilter((prev) => ({ ...prev, [e.currentTarget.id]: v }));
  };

  const applyFilter = () => {
    if (props.setFilter) {
      props.setFilter({ ...filter });
      if (view !== undefined) {
        if (props.setViewBy) {
          props.setViewBy(view);
        }
      }
      props.setOrderBy(orderBy);
    }
    setshow(false);
  };

  const resetFilter = () => {
    if (props.setFilter) {
      props.setFilter((p: any) => ({ ...p, ...initialFilterState }));
      if (props.setTracker) {
        props.setTracker("");
      }
      if (props.setHadDeposit) {
        props.setHadDeposit(false);
      }
    }
    if (props.setViewBy) {
      props.setViewBy("Daily");
      setView("Daily");
    }
    setshow(false);
    setFilter(initialFilterState);
  };

  const getTranslation = (val: string) => {
    return val;
  };

  return (
    <div ref={menuRef} className="table-list-filter-container dropdown">
      <div>
        <button
          onClick={() => setshow((p) => !p)}
          className="btn fs-6 btn-primary btn-sm px-7 d-flex align-items-center gap-3"
        >
          <i className="ki-duotone ki-filter-tick fs-1">
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
          Filters
        </button>
      </div>

      {show && (
        <div className="card table-list-filter-dropdown dropdown-menu" aria-labelledby="dropdownMenuButton">

          <div className="card-body">
            <div className="d-flex gap-4">
              <div className="form-group">
                <label className="form-label text-gray-600 fs-7">
                  {view === "Daily" && !props.filterOptions?.[ListFilters.ORDER]
                    ? "Days per Page"
                    : view === "Monthly"
                    ? "Months per Page"
                    : "Result"}
                </label>
                <Dropdown>
                  <Dropdown.Toggle
                    className="btn btn-secondary dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    id="dropdown-basic"
                  >
                    {filter.limit}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {props.filterOptions && props.filterOptions[ListFilters.LIMIT]
                      ? [...props.filterOptions[ListFilters.LIMIT]].map((v) => {
                          return (
                            <Dropdown.Item
                              className="text-gray-600"
                              id="limit"
                              key={v}
                              onClick={(e) => handleChange(e, v)}
                            >
                              {v}
                            </Dropdown.Item>
                          );
                        })
                      : null}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {props.filterOptions?.[ListFilters.ORDER] && (
                <div className="form-group">
                  <label className="form-label text-gray-600 fs-7">Order By</label>
                  <Dropdown>
                    <Dropdown.Toggle
                      className="btn btn-secondary dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      id="dropdown-basic"
                    >
                      {filter.orderBy ? getTranslation(filter.orderBy) : "---"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {[...props.filterOptions[ListFilters.ORDER]].map((c) => {
                        return (
                          <Dropdown.Item
                            className="text-gray-600"
                            id="orderBy"
                            onClick={(e) => handleChange(e, c)}
                            key={c}
                          >
                            {capitalizeFirstLetter(c)}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </div>

            <hr className="separator bg-secondary mt-1 mb-1"></hr>
            {props.viewBy && (
              <div className="mt-1 mb-1">
                <div className="form-group">
                  <label className="form-label text-gray-600">View reports by time interval</label>
                  <Dropdown>
                    <Dropdown.Toggle
                      className="btn btn-secondary dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      id="dropdown-basic"
                    >
                      {view ? view : "---"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {props && props.filterOptions && props.filterOptions[ListFilters.VIEW]
                        ? [...props.filterOptions[ListFilters.VIEW]].map((v) => {
                            return (
                              <Dropdown.Item className="text-gray-600" id="limit" key={v} onClick={() => setView(v)}>
                                {v}
                              </Dropdown.Item>
                            );
                          })
                        : null}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <hr className="separator bg-secondary mt-1 mb-1"></hr>
              </div>
            )}
            {props.filterOptions?.[ListFilters.ORDERBY] && (
                                <div className="mt-4 mb-5">
                                    <div className="form-group">
                                        <Dropdown>
                                            <label className="form-label text-gray-600">Order By</label>   <br/>
                                            <Dropdown.Toggle
                      className="btn btn-secondary dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      id="dropdown-basic"
                    >
                                            {orderBy ? t(`common.list-filter.${orderBy}`) : "---"}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {props &&
                                                    [...props.filterOptions[ListFilters.ORDERBY]].map((v) => {
                                                        return (
                                                            <Dropdown.Item className="text-gray-600" id="limit" key={v} onClick={() => setOrderBy(v)}>
                                                                {t(`common.list-filter.${v}`)}
                                                            </Dropdown.Item>
                                                        );
                                                    })}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className="separator border-gray-200 mt-7 mb-5"></div>
                                </div>
                            )}
            {props.trackers && (
              <div className="mt-1 mb-1">
                <label className="form-label text-gray-600">Trackers</label>
                <div className="form-group">
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isClearable
                    isSearchable
                    defaultValue={props.currentTracker}
                    onChange={(v) => props.setTracker(v)}
                    isMulti
                    options={props.trackers}
                  />
                </div>
                <div className="form-group mt-4">
                  <Form.Check
                    type="switch"
                    id="groupByTracker"
                    onChange={(e) => {
                      props.setGroupByTracker(e.target.checked);
                    }}
                    label={"Group by trackers"}
                    checked={props.groupByTracker}
                  />
                </div>
                <div className="separator bg-secondary mt-1 mb-1"></div>
              </div>
            )}
            {props.hadDeposit !== undefined ? (
              <div className="mt-1 mb-1">
                <div className="form-group">
                  <Form.Check
                    type="switch"
                    id="hadDeposit"
                    onChange={(e) => {
                      props.setHadDeposit(e.target.checked);
                    }}
                    label={"Had Deposit"}
                    checked={props.hadDeposit}
                  />
                </div>
                <div className="separator bg-secondary mt-1 mb-1"></div>
              </div>
            ) : null}
            <div className="d-flex justify-content-start mt-7 gap-2">
              <Button
                onClick={() => resetFilter()}
                type="reset"
                variant="light"
                className="btn-sm btn-active-light-primary "
                data-kt-menu-dismiss="true"
              >
                Reset
              </Button>

              <Button
                onClick={() => applyFilter()}
                type="submit"
                variant="primary"
                className="btn-sm "
                data-kt-menu-dismiss="true"
              >
                Apply
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListFilter;
