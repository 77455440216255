import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import {t} from "../../i18n";
import { useForm } from "react-hook-form";
import { CasinoPage, ListCasinoPageResponse, MenuItem, MenuItemType, SubMenu, SubMenuTab } from "../../http/protocol";

import { getContainer } from "../../ioc/IOCSetup";

import { Modal } from "react-bootstrap";
import { Translation } from "./EditUtils";
import { AsyncStatus, addUniqueId, resolvePendingStatus, setType, useDragAndDrop } from "./utils";
import SubmenuItem from "./SubmenuItem";
import EditIcons from "./EditIcon";

interface Props {
    menuItem?: MenuItem;
    selectedType?: MenuItemType;
    handleChange: (submenu: SubMenuTab[], pageId: number, pageName: string, defaultPage: boolean, reset?: boolean) => void;
}

export default function EditCasinoSubmenu(props: Props) {
    const [pages, setPages] = useState<CasinoPage[]>([]);
    const [selectedPage, setSelectedPage] = useState<CasinoPage>({});
    const [subMenu, setSubMenu] = useState<SubMenu>({});
    const [importStatus, setimportStatus] = useState<AsyncStatus>(AsyncStatus.idle);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [editTab, setEditTab] = useState<SubMenuTab | undefined>(undefined);

    const setsubmenuTabs = (tabs: SubMenuTab[]) => {
        setSubMenu((prev) => ({ ...prev, tabs: tabs }));
    };

    const { onDragEnd, onDragOver, onDragStart } = useDragAndDrop<SubMenuTab>(subMenu.tabs ?? [], setsubmenuTabs);

    const importCasinoTabs = () => {
        setimportStatus(AsyncStatus.pending);
        resolvePendingStatus(setimportStatus, 300, AsyncStatus.success).then(() => {
            if (subMenu.id == selectedPage.id) {
                if (selectedPage && selectedPage.tabs) {
                    let tabs: Pick<SubMenuTab, "id" | "name">[] = selectedPage.tabs.map((tab, idx) => {
                        return { name: tab.name, id: tab.id };
                    });
                    const existingSubMenuMap = new Map();

                    tabs.forEach((item) => {
                        existingSubMenuMap.set(item.id, item);
                    });
                    if (subMenu.tabs) {
                        subMenu.tabs.forEach((item) => {
                            existingSubMenuMap.set(item.id, item);
                        });
                    }

                    let mergedSubMenus = Array.from(existingSubMenuMap.values());

                    mergedSubMenus = mergedSubMenus.map((submenu) => {
                        return { id: submenu.id, name: submenu.name, icon: submenu.icon, type: setType(submenu) };
                    });

                    setSubMenu((prev) => ({ ...prev, id: selectedPage.id, name: selectedPage.name, tabs: mergedSubMenus }));
                }
            } else {
                if (selectedPage && selectedPage.tabs) {
                    let tabs: Pick<SubMenuTab, "id" | "name" | "type">[] = selectedPage.tabs.map((tab, idx) => {
                        return { name: tab.name, id: tab.id, type: MenuItemType.CASINO };
                    });

                    tabs.map((submenu) => {
                        return { id: submenu.id, name: submenu.name };
                    });
                    setSubMenu((prev) => ({ ...prev, id: selectedPage.id, name: selectedPage.name, tabs: tabs }));
                }
            }

            setimportStatus(AsyncStatus.idle);
        });
    };

    const getCasinoPages = async () => {
        return await getContainer()
            .getCasinoPageService()
            .list({})
            .then((response: ListCasinoPageResponse) => response);
    };

    // Loads casino pages to pages and sets SelectedPage ( Does not matter witch one is the selected page ) OBS! could be effected if used when saving SubMenu
    useEffect(() => {
        getCasinoPages().then((response) => {
            if (!_.isUndefined(response) && !_.isUndefined(response.items)) {
                setPages(response.items);
                setSelectedPage(response.items[0]);
            }
        });
    }, []);

    // If existing SubMenu set it to be edited.
    useEffect(() => {
        if (!_.isUndefined(props.menuItem)) {
            if (props.menuItem.subMenu) {
                setSubMenu(props.menuItem.subMenu ?? {});
                let page = pages.find((p) => p.id == props.menuItem?.subMenu?.id);
                setSelectedPage(page ?? {});
            }
        }
        return () => {
            setSubMenu({});
        };
    }, [props.menuItem, pages]);

    function handlePageSelect(event: React.ChangeEvent<HTMLSelectElement>) {
        const page: any = pages.find((p) => p.id == Number(event.target.value));
        if (!_.isUndefined(page)) {
            setSelectedPage(page);
        }
    }

    function deleteMenuTab(id: number | string | undefined) {
        if (subMenu && subMenu.tabs) {
            let updatedTabs: SubMenuTab[] = [];
            subMenu.tabs.forEach((tab) => {
                if (tab.id !== id) {
                    updatedTabs.push(tab);
                }
            });
            setSubMenu((prev) => ({ ...prev, tabs: updatedTabs }));
        }
    }

    function addNewSubMenuTab(tab: SubMenuTab) {
        setSubMenu((prev) => ({ ...prev, tabs: [...(prev.tabs ?? []), { ...tab, type: MenuItemType.CUSTOM, id: addUniqueId(prev.tabs ?? []) }] }));
    }

    function saveEditedSubMenuTab(tab: SubMenuTab) {
        let tabs = subMenu.tabs?.map((t) => (t.id == tab.id ? tab : t));
        setSubMenu((prev) => ({ ...prev, tabs: tabs }));
    }

    function toggleEditView() {
        setIsOpen((p) => !p);
    }

    function resetSubmenu() {
        props.handleChange([], 0, "", false, true);
        setSubMenu({});
    }

    function editMenuTab(id: number | string) {
        if (subMenu && subMenu.tabs) {
            let tab = subMenu.tabs[id as any];
            if (tab) {
                setIsOpen((p) => !p);
                setEditTab(tab);
            }
        }
    }

    /* ------ */
    const saveSubmenu = (submenu: SubMenuTab[]) => {
        props.handleChange(submenu, subMenu.id ?? 0, subMenu.name ?? "", isDefaultPage() ? true : false);
    };

    useEffect(() => {
        saveSubmenu(subMenu.tabs ?? []);
    }, [subMenu]);

    const isDefaultPage = () => {
        let isDefault = false;
        pages.forEach((p) => {
            if (p.id == subMenu.id) {
                if ("defaultPage" in p) {
                    if (p.defaultPage == true) {
                        isDefault = true;
                    } else {
                        isDefault = false;
                    }
                }
            }
        });
        return isDefault;
    };

    return (
        <div>
            <div className="form-group">
                <label>Casino pages</label>
                <div className="submenu-option">
                    <select className="form-control" onChange={handlePageSelect} value={selectedPage && selectedPage.id}>
                        {pages.map((page) => {
                            return (
                                <option key={page.id} value={page.id}>
                                    {page.name}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <span className={"help-block"}>
                    Select casino page to import / sync tabs from, casino page tabs can be configured in <Link to={"/casino-pages"}>Casino pages</Link>
                </span>
            </div>
            <div className="d-flex flex-column mt-2 mb-2">
                <button onClick={importCasinoTabs} className="btn btn-primary no-wrap" type="button">
                    {importStatus == AsyncStatus.pending ? (
                        <div className="spinner-border spinner-border-sm text-light" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    ) : (
                        "Sync / Import Tabs"
                    )}
                </button>
                <span className={"help-block"}>import tabs from casino page, if new tabs are added in casino lobby, it will sync and add the new tabs.</span>

                {selectedPage.id != subMenu.id && Boolean(subMenu.tabs?.length) && (
            <span className="text-warning no-wrap p-2 mt-1" style={{ backgroundColor: 'transparent' }}>
                Press Sync/Import Tabs to reset Submeny<br/>Need to Sync/Import Tabs to be able to save Submenu
            </span>
        )}
            </div>
            <div>
                <div className="hr-line-dashed"></div>
                <ul className="list-group p-1">
                    {subMenu && subMenu.tabs && subMenu.tabs.map((item, idx) => <SubmenuItem key={idx} editSubMenuTab={editMenuTab} deleteSubMenuTab={deleteMenuTab} onDragEnd={onDragEnd} onDragOver={onDragOver} onDragStart={onDragStart} index={idx} item={item} />)}
                    {subMenu && !subMenu.tabs && (
                        <li className="row tab-row w-100">
                            <span className={"help-block"}>Empty List, import casino tabs or create custom tab</span>
                        </li>
                    )}
                </ul>
                <div className="hr-line-dashed mt-2"></div>
            </div>
            <div className="d-flex flex-column mt-2 mb-2">
                <button
                    onClick={() => {
                        toggleEditView();
                        setEditTab(undefined);
                    }}
                    className="btn btn-success no-wrap"
                    type="button"
                >
                    Add New Tab
                </button>
                <span className={"help-block"}>Create a custom tab that is not part of the synchronized casino pages tabs.</span>
                <div className="hr-line-dashed mt-2"></div>

                <div className="d-flex flex-column pt-2">
                    <button
                        onClick={() => {
                            resetSubmenu();
                        }}
                        className="btn btn-secondary no-wrap"
                        type="button"
                    >
                        Clear Sub Menu
                    </button>
                    <span className={"help-block"}>To remove submenu from sidebar completely, clear the submenu</span>
                </div>
            </div>
            <div className="hr-line-dashed mt-4"></div>
            <EditSubMenuItem isOpen={isOpen} setIsOpen={toggleEditView} subMenu={subMenu} subMenuTab={editTab} onSaveNew={addNewSubMenuTab} onSave={saveEditedSubMenuTab} />
        </div>
    );
}

interface EditSubMenuItemProps {
    subMenuTab?: SubMenuTab | undefined;
    subMenu?: SubMenu;
    isOpen: boolean;
    onSave: (item: SubMenuTab) => void;
    onSaveNew?: (tab: SubMenuTab) => void;
    setIsOpen: () => void;
    pageId?: string | number;
}

export const EditSubMenuItem = (props: EditSubMenuItemProps) => {
    const [subMenuTab, setsubMenuTab] = useState<SubMenuTab>({});
    const { register, handleSubmit, setValue, reset, watch } = useForm<SubMenuTab>({ values: subMenuTab });
    const [translation, setTranslation] = useState<{ [index: string]: string }>({});
    const [viewIcons, setViewIcons] = useState(false);

    const customIcon = watch("icon");

    const onSubmit = (form: SubMenuTab) => {
        if (props.subMenuTab == undefined && props.onSaveNew) {
            props.onSaveNew({ ...form });
        } else if (props.subMenuTab !== undefined && props.onSave) {
            props.onSave({ ...form });
        }
        props.setIsOpen();
        cleanUp();
    };

    const saveTranslations = (t: { [index: string]: string }) => {
        setValue("translations", t);
    };

    const toggleIconModal = () => {
        setViewIcons((p) => !p);
    };

    const saveIcon = (tag: string) => {
        setValue("icon", tag);
    };

    const cleanUp = () => {
        setsubMenuTab({});
        setTranslation({});
        reset({ name: "", url: "", icon: "", translations: {}, target: "", id: undefined, type: undefined });
    };

    const formatName = () => {
        if (!_.isUndefined(props.subMenu)) {
            let name = props.subMenu.name ?? "";
            if (props.subMenu.name) {
                name = props.subMenu.name.replace(/ /g, "-");
            }
            return name;
        }
    };

    useEffect(() => {
        if (props.subMenuTab !== undefined) {
            if (props.subMenuTab.type == MenuItemType.CASINO) {
                if (props.subMenu?.defaultCasinoPage) {
                    setValue("url", `casino/${props.subMenuTab.id}/${props.subMenuTab.name}`);
                } else {
                    setValue("url", `casino/page/${props.subMenu?.id}/${formatName()}/${props.subMenuTab.id}/${props.subMenuTab.name}`);
                }
            }
            setsubMenuTab(props.subMenuTab);
            setTranslation(props.subMenuTab.translations ?? {});
        } else {
            cleanUp();
        }
    }, [props.subMenuTab, props.isOpen]);

    useEffect(() => {
        return () => {
            cleanUp();
        };
    }, [props.subMenuTab]);

    return (
        <Modal show={props.isOpen} onHide={() => props.setIsOpen()}>
            <Modal.Header>
                <h4>Edit Sub Menu Tab</h4>
            </Modal.Header>
            <Modal.Body className="px-3 py-3">
                <form action="">
                    <div className={"form-group form-check p-0"}>
                        <label>Name</label>
                        <input disabled={subMenuTab.type == MenuItemType.CASINO} className={"form-control"} type={"text"} {...register("name")} />
                        {subMenuTab.type == MenuItemType.CASINO ? (
                            <span className={"help-block"}>
                                Update tab name by editing the corresponding casino lobby page. If changes are needed, delete the Menu Tab and import/sync tabs from casino pages. <br />
                                <Link to={"/casino-pages"}>Casino lobby</Link>
                            </span>
                        ) : (
                            <span className={"help-block"}>Select a title for the submenu tab. You'll have the option to translate it into different languages later in the form.</span>
                        )}
                    </div>
                    <div className={"form-group form-check p-0"}>
                        <label>Url</label>
                        <input disabled={subMenuTab.type == MenuItemType.CASINO} className={"form-control"} type={"text"} {...register("url")} />
                        {subMenuTab.type != MenuItemType.CASINO ? (
                            <span className={"help-block"}>
                                The sub menu URL, if it starts with / it will be considered to be an internal link and language etc will be appended automatically. (eg /poker) <br />
                                If link has not / it will be considerd an external link
                            </span>
                        ) : (
                            <span className={"help-block"}>
                                The casino page tabs url, its not configurable. To configure go to <Link to={"/casino-pages"}>Casino lobby</Link>
                            </span>
                        )}
                    </div>
                    {(subMenuTab.type == MenuItemType.CUSTOM || props.subMenuTab == undefined) && (
                        <div className={"form-group"}>
                            <label>Target</label>
                            <input {...register("target")} className={"form-control"} />
                            <span className={"help-block"}>Link target: If not specified, the default behavior will open the link in the same window. Use '_blank' to open the menu item in a new window or tab.</span>
                        </div>
                    )}
                    <div className="hr-line-dashed mt-2"></div>
                    <div className="icon-select-container">
                        <div className={"form-group icon-preview"}>
                            <button onClick={toggleIconModal} type="button" className={"btn btn-primary"}>
                                Edit Icon
                            </button>
                            <div className="form-group d-flex flex-column">
                                <label htmlFor="">Custom icon class</label>
                                <input disabled type="text" value={customIcon} />
                            </div>
                            <EditIcons icon={customIcon} isOpen={viewIcons} setIsOpen={toggleIconModal} saveIcon={saveIcon} />
                        </div>
                        <span className={"help-block"}>
                            Select Icon for Sub menu tab, to view selected: Click "Edit Icon". For more information on icon customization view our docs{" "}
                            <a href="https://docs.cubeia.com/docs/navigation-sidemenu" target="_blank" rel="noopener noreferrer">
                                Documentation
                            </a>
                        </span>
                    </div>
                    <Translation saveTranslations={saveTranslations} translations={translation} className="justify-content-between" />
                </form>
            </Modal.Body>
            <Modal.Footer>
                <div className="form-group">
                    <button onClick={() => props.setIsOpen()} type="button" className={"btn btn-primary"}>
                        Cancel
                    </button>
                    <button onClick={handleSubmit(onSubmit)} type="button" className={"btn btn-success"}>
                        Save
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};
