import React from "react";
import {getContainer} from "../../ioc/IOCSetup";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {LoadingViewState, RouterView} from "../RouterView";
import {SettingsService} from "../../services/SettingsService";
import {Settings} from "../../http/protocol";
import {LoadingSpinner} from "../../util/LoadingSpinner";
import {ProviderService} from "../../services/ProviderService";


interface State extends LoadingViewState {
    loading:boolean;
    settings?:Settings;
    url?:string;
}

interface Props extends RouteComponentProps{

}

export class ReportManagerView extends RouterView<Props,State> {
    settingsService:SettingsService;
    providerService:ProviderService;

    constructor(props : Props) {
        super(props);
        this.settingsService = getContainer().getSettingsService();
        this.providerService = getContainer().getProviderService();
        this.state = {loading:true}
    }

    loadContent(): Promise<any>  {
        console.log("loading manager datatt")
        return this.settingsService.getSettings().then((settings:Settings) => {

            const url = this.providerService.getIntegrationBOUrl("metabase");
            url.length > 0 && window.open(url);
            this.setState({settings:settings, loading: false, url:url});
        })
    }

    getTitle(): string {
        return "Report Manager";
    }

    renderContent() {
        if(this.state.settings == undefined) {
            return <LoadingSpinner />
        } else {
            if(!this.state.url) {
                return <div>No URL set for metabase</div>
            } else {
                return (
                    <div>
                        <div className={"card-box"}>
                            <div className="ibox-content">
                                The report manager Metabase will open in a new tab. <br/><br/>

                                Metabase is a powerful custom reporting tool where you can drill down into the databases, create custom reports and share them with the team.<br/><br/>

                                <strong>How to add Dashboards and Reports</strong><br/><br/>

                                You can add your own dashboards and reports to the Reports section in the menu by doing the following in Metabase:<br/>

                                <ol>
                                    <li>Create your dashboard or report in Metabase,</li>
                                    <li>Click on ‘Sharing and Embedding’</li>
                                    <li>Click on ‘Embed this dashboard in an application’</li>
                                    <li>Click on Publish</li>
                                </ol>

                                After that your dashboard should be showing in the menu if you reload Nano Admin.<br/><br/>

                                You can find example with screenshots in the Metabase section in the documentation portal for more information: <a href="https://docs.cubeia.com/docs/metabase-overview" target="_blank">https://docs.cubeia.com/docs/metabase-overview</a>


                            </div>
                        </div>
                    </div>
                );
            }
        }
    }

}

export default withRouter(ReportManagerView);
