import React, {Fragment} from "react";
import {ActionType} from "../list/list/DataTable";
import {BonusTrigger, BonusTriggerListRequest, TurnoverContributionsListRequest} from "../../http/protocol";
import {getContainer} from "../../ioc/IOCSetup";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {PagerModel} from "../list/list/PagerModel";
import {ListUtils} from "../list/list/ListUtils";
import {withRouter} from "react-router";
import {ListView, ListViewProps, ListViewState} from "../ListView";
import {FormListener} from "../form/Form";
import {OfferRuleService} from "../../services/OfferRuleService";


interface State extends ListViewState<TurnoverContributionsListRequest> {
    form? : BonusTriggerListRequest;
    validationMap? : any;
}
interface Props extends ListViewProps  {

}

export class OfferRuleListView extends ListView<State, BonusTriggerListRequest> implements FormListener<BonusTriggerListRequest>{

    columns = [
        { property : "id", name : "ID", orderBy : "id"},
        { property : "name", name : "Name"},
        { property:  "event", name : "Event"},
        { property:  "count", name : "Count"}
    ];

    actions = [
        { id : "delete", name : "delete", className : "btn btn-danger", iconClassName : "fa fa-times", type : ActionType.CONFIRMATION},
        { id : "view", name : "View", className : "btn btn-primary", iconClassName : "fa fa-user", type : ActionType.LINK, getLink : (item : any) => "/bonus/offer-rule/" + item.id }

    ];

    service : OfferRuleService;

    constructor(props : Props){
        super(props, "/bonus/offer-rules/list");
        this.state = {...this.state, validationMap : {}, form : {...this.state.listRequest}};
        this.service = getContainer().getOfferRuleService();
    }
    onSubmit() : void | Promise<any> {
        if(this.state.form) {
            let form = this.state.form;
            form.offset = 0;
            this.updateListState(form);
        }
    }
    onSubmitError() :  void {

    }

    formDidUpdate(formModel: BonusTriggerListRequest, validationMap?: any): void {
        this.setState({form : formModel, validationMap : validationMap});
    }

    onAction(item : BonusTrigger, action : string) : Promise<any> | undefined {
        if(action == "delete") {
            return  this.service.remove(""+item.id);
        }
    }

    pagerChanged(pager : PagerModel) {
        if(this.state.listRequest) {
            console.log("pager changed!", pager);
            let listRequest = ListUtils.toListRequest<any>(this.state.listRequest,pager);
            this.updateListState(listRequest);
        }
    }
    getTitle() {
        return "Triggers";
    }

    renderContent() {
        if(!this.state.listRequest) {
            return <div/>;
        }
        return (
            <Fragment>
                <EntityDataTable service={this.service}
                                 onPagerChange={this.pagerChanged.bind(this)}
                                 columns={this.columns}
                                 listRequest={this.state.listRequest}
                                 onAction={this.onAction.bind(this)}
                                 actions={this.actions}/>
            </Fragment>
        );
    }



}

export default withRouter(OfferRuleListView);
