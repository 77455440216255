import { IconSettings } from "../http/Types";
import { AdminSettings } from "../http/protocol";
import { ReduxAction } from "../user/UserStore";
import { ADMIN_SETTINGS, ICON_SETTINGS } from "./SettingsActions";

export interface AdminSettingsStoreState {
    settings?: AdminSettings;
    iconSettings?: IconSettings;
}

export function settingsReducer(state: AdminSettingsStoreState = {}, action: ReduxAction<any>) {
    switch (action.type) {
        case ADMIN_SETTINGS:
            return { ...state, settings: action.payload };
        case ICON_SETTINGS:
            return { ...state, iconSettings: action.payload };
        default:
            return state;
    }
}
