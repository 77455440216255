import { Fragment } from "react";
import { Route } from "react-router";
import { Home } from "../Home";
import AccountListView from "./account/AccountListView";
import AccountView from "./account/AccountView";
import EditAccountView from "./account/EditAccountView";
import TransactionView from "./account/TransactionView";
import AdminUserGroupListView from "./admin/AdminUserGroupListView";
import AdminUserListView from "./admin/AdminUserListView";
import AdminUserView from "./admin/AdminUserView";
import UserGroupView from "./admin/UserGroupView";
import AdminChartListView from "./charts/AdminChartListView";
import AdminChartView from "./charts/AdminChartView";
import { EditUserView } from "./user/EditUserView";
import UserAttributeListView from "./user/UserAttributeListView";
import UserAttributeView from "./user/UserAttributeView";
import UserListView from "./user/UserListView";
import UserView from "./user/UserView";

import CasinoCategoryListView from "./casino/CasinoCategoryListView";
import CasinoCategoryView from "./casino/CasinoCategoryView";

import CreateOrUpdateCurrencyView from "./account/CreateOrUpdateCurrencyView";
import SupportedCurrenciesListView from "./account/SupportedCurrenciesListView";
import SettingsGeneralView from "./admin/SettingsGeneralView";
import SettingsInternalView from "./admin/SettingsInternalView";
import { SettingsTransferLimitsView } from "./admin/SettingsTransferLimitsView";
import SettingsUserView from "./admin/SettingsUserView";
import AffiliateEditView from "./affiliates/AffiliateEditView";
import { AffiliateListView } from "./affiliates/AffiliateListView";
import AffiliateView from "./affiliates/AffiliateView";
import AffiliatesReportPayoutsView from "./affiliates/AffiliatesReportPayoutsView";
import { AffiliatesSettingsView } from "./affiliates/AffiliatesSettingsView";
import PaymentView from "./payments/PaymentView";
import PaymentsListView from "./payments/PaymentsListView";
import { BundleView } from "./store/BundleView";
import { BundlesListView } from "./store/BundlesListView";

import SettingsPWAView from "./admin/SettingsPWAView";
import { CasinoGameListView } from "./casino/CasinoGameListView";
import { CasinoGameTagListView } from "./casino/CasinoGameTagListView";
import CasinoStudioListView from "./casino/CasinoStudioListView";
import { EditCasinoGameTag } from "./casino/EditCasinoGameTag";
import EditCasinoGameView from "./casino/EditCasinoGameView";
import EditCasinoStudioView from "./casino/EditCasinoStudioView";
import TranslationsListView from "./translations/TranslationsListView";


import { CMSGuide } from "../guides/styling/CMSGuide";
import { CssStyling } from "../guides/styling/CssStyling";
import { TransactionListView } from "./account/TransactionListView";
import ProviderInfoView from "./admin/ProviderInfoView";
import { SecuritySettingsView } from "./admin/SecuritySettingsView";
import { SettingsAdvancedView } from "./admin/SettingsAdvancedView";

import { SettingsLoginSpamCoolDownView } from "./admin/SettingsLoginSpamCoolDownView";
import SettingsNotificationsView from "./admin/SettingsNotificationsView";
import AuditLogListView from "./audit/AuditLogListView";
import { AuditLogView } from "./audit/AuditLogView";
import { SystemEventListView } from "./audit/SystemEventListView";
import { SystemEventLogView } from "./audit/SystemEventLogView";
import { BatchTransactionView } from "./batch/BatchTransactionView";
import { CreateBatchTransactionView } from "./batch/CreateBatchTransactionView";
import BonusConfigListView from "./bonus/BonusConfigListView";
import BonusEventsListView from "./bonus/BonusEventsListView";
import BonusOfferListView from "./bonus/BonusOfferListView";
import BonusView from "./bonus/BonusView";
import CashbackConfigListView from "./bonus/CashbackConfigListView";
import CashbackLogListView from "./bonus/CashbackLogListView";
import EditBonusConfig from "./bonus/EditBonusConfig";
import EditBonusOffer from "./bonus/EditBonusOffer";
import EditCashbackConfig from "./bonus/EditCashbackConfig";
import EditMonetaryConfig from "./bonus/EditMonetaryConfig";
import EditOfferRule from "./bonus/EditOfferRule";
import { EditTurnoverContributions } from "./bonus/EditTurnoverContributions";
import MonetaryConfigListView from "./bonus/MonetaryConfigListView";
import OfferRuleListView from "./bonus/OfferRuleListView";
import TurnoverContributionsListView from "./bonus/TurnoverContributionsListView";
import CasinoLobbyView from "./casino/CasinoLobbyCacheView";
import { CasinoPageListView } from "./casino/CasinoPageListView";
import CasinoUpdateLogListView from "./casino/CasinoUpdateLogListView";
import CasinoUpdateLogView from "./casino/CasinoUpdateLogView";
import { EditCasinoPage } from "./casino/EditCasinoPage";
import { JackpotListView } from "./casino/JackpotListView";
import { IpRuleView } from "./iprule/IpRuleView";
import { ListMaintenanceView } from "./maintenance/ListMaintenanceView";
import { MaintenanceView } from "./maintenance/MaintenanceView";
import NotificationsView from "./notifications/NotificationsView";
import AmplitudeManagerView from "./report/AmplitudeManagerView";
import MetabaseDashboardListView from "./report/MetabaseDashboardListView";
import MetabaseDashboardView from "./report/MetabaseDashboardView";
import MetabaseReportView from "./report/MetabaseReportView";
import ReportManagerView from "./report/ReportManagerView";
import AssignRewardToUser from "./rewards/AssignRewardToUser";
import CreateUserReward from "./rewards/CreateUserReward";
import RewardTemplateListView from "./rewards/RewardTemplateListView";
import ShowRewardToUser from "./rewards/ShowRewardToUser";
import UserGameRewardListView from "./rewards/UserGameRewardListView";
import SportsbookBetListView from "./sportsbook/SportsbookBetListView";
import SportsbookBetView from "./sportsbook/SportsbookBetView";
import { EditUserJourney } from "./user/EditUserJourney";
import { EditUserRiskCollection } from "./user/EditUserRiskCollection";
import { UserCashbacksView } from "./user/UserCashbacksView";
import UserJourneyListView from "./user/UserJourneyListView";
import { UserRiskRuleCollectionListView } from "./user/UserRiskRuleCollectionListView";
import CreateVoucher from "./voucher/CreateVoucher";
import EditVoucher from "./voucher/EditVoucher";
import VoucherListView from "./voucher/VoucherListView";
import { EditWidget } from "./widget/EditWidget";
import { ScriptWidgetsListView } from "./widget/ScriptWidgetsListView";
import ProviderReportsView from "./finance/ProviderReportsView";
import CashbackConfigCasinoTagExclusions from "./bonus/CashbackConfigCasinoTagExclusions";
import {ListSitesView} from "./site/ListSitesView";
import {EditSite} from "./site/EditSite";
import {ListSettingsView} from "./settings/ListSettingsView";
import { InstantWithdrawalsView } from "./payments/InstantWithdrawalsView";
import AdminUserProfile from "./admin/AdminUserProfile";
import {MetabaseReportsPage} from "./report/MetabaseReportsPage";


import ListMenuView from "./menu/ListMenuView";
import EditMenuView from "./menu/EditMenuView";
import ConfigurationView from "./configuration/ConfigurationView";
import { Switch } from "react-router-dom";
import AffiliatesDetailedReportView from './affiliates/AffiliatesDetailedReportView';
import SportbookFreebetsListView from "./sportsbook/SportsbookBonusListView";
import SportsbookBonusDetailsView from "./sportsbook/SportbooksBonusDetailsView";
import CashbackSingleCurrencyPayoutListView from "./bonus/CashbackSingleCurrencyPayoutListView";
import CashbackPayoutListView from "./bonus/CashbackPayoutListView";
import SettingsIntegrationsView from "./admin/SettingsIntegrationsView";
import { ListIntegrationsView } from "./settings/ListIntegrationsView";
import {UserNextCashbackView} from "./user/UserNextCashbackView";

export const Routes = ( props : {}) => (
    <Fragment>
        <Route exact path="/" component={Home}/>
        <Route exact path="/admin/profile" component={AdminUserProfile}/>
        <Route path="/admin/admin-users" component={AdminUserListView}/>
        <Route exact path="/admin/admin-user/:id" component={AdminUserView}/>
        <Route path="/admin/user-groups" component={AdminUserGroupListView}/>
        <Route path="/admin/user-group/:id" component={UserGroupView}/>
        <Route path="/admin/settings/general/:id/:copyFrom?" component={SettingsGeneralView}/>
        <Route path="/admin/settings/list" component={ListSettingsView}/>
        <Route path="/admin/settings/menus" component={ListMenuView}/>
        <Route path="/admin/service-level/configuration" component={ConfigurationView}/>
        <Route path="/admin/settings/menu/:siteId" component={EditMenuView}/>
        <Route path="/admin/settings/integrations/list" component={ListIntegrationsView}/>
        <Route path="/admin/settings/integration/:siteId" component={SettingsIntegrationsView}/>
        <Route path="/admin/settings/transferLimits/:id" component={SettingsTransferLimitsView}/>
        <Route path="/admin/settings/user" component={SettingsUserView}/>
        <Route path="/admin/settings/internal" component={SettingsInternalView}/>
        <Route path="/admin/settings/pwa" component={SettingsPWAView}/>
        <Route path="/admin/settings/notifications" component={SettingsNotificationsView}/>
        <Route path="/admin/settings/spam-detector" component={SettingsLoginSpamCoolDownView}/>
        <Route path="/admin/settings/advanced/:config?" component={SettingsAdvancedView}/>
        <Route path="/admin/payments/instant-withdrawals" component={InstantWithdrawalsView}/>
        <Route path={"/user-attributes"} component={UserAttributeListView} />
        <Route path={"/user-attribute/:id"} component={UserAttributeView} />
        <Route path={"/users"} component={UserListView} />
        <Route path={"/payments"} component={PaymentsListView} />
        <Route path={"/vouchers"} component={VoucherListView} />
        <Route path={"/voucher/create"} exact component={CreateVoucher} />
        <Route path={"/voucher/details/:id"} component={EditVoucher} />
        <Route exact path={"/user/edit/:id"} component={EditUserView} />
        <Route exact path={"/user/:id"} component={UserView} />
        <Route exact path={"/user/cashbacks/:id"} component={UserCashbacksView} />
        <Route exact path={"/user/next/cashback/:id"} component={UserNextCashbackView} />
        <Route path={"/admin-charts/"} component={AdminChartListView} />
        <Route path={"/admin-chart/:id"} component={AdminChartView} />
        <Route path={"/audit-logs"} component={AuditLogListView} />
        <Route exact path={"/audit-log/:id"} component={AuditLogView} />
        <Route path={"/system-events"} component={SystemEventListView} />
        <Route exact path={"/system-event/:id"} component={SystemEventLogView} />
        <Route path={"/accounts"} component={AccountListView} />
        <Route exact path={"/account/:id"} component={AccountView} />
        <Route exact  path={"/account/edit/:id"} component={EditAccountView} />
        <Route exact  path={"/transaction/:id"} component={TransactionView} />
        <Route exact  path={"/currencies/list"} component={SupportedCurrenciesListView} />
        <Route exact  path={"/currencies/edit/:id"} component={CreateOrUpdateCurrencyView} />
        <Route path={"/casino-categories"} component={CasinoCategoryListView} />
        <Route path={"/cashback/configs/list"} component={CashbackConfigListView} />
        <Route path={"/cashback/log/list"} component={CashbackLogListView} />
        <Route path="/cashback/single-currency-payout/list" component={CashbackSingleCurrencyPayoutListView}/>
        <Route path="/cashback/payout/list" component={CashbackPayoutListView}/>
        <Route path={"/cashback/config/:id"} component={EditCashbackConfig} />
        <Route path={"/cashback/casino/exclusions"} component={CashbackConfigCasinoTagExclusions} />
        <Route path={"/bonus/turnover-contribution/list"} component={TurnoverContributionsListView} />
        <Route path={"/bonus/turnover-contributions/:id"} component={EditTurnoverContributions} />
        <Route path={"/bonus/configs/list"} component={BonusConfigListView} />
        <Route path={"/bonus/config/:id"} component={EditBonusConfig} />
        <Route path={"/bonus/offers/list"} component={BonusOfferListView} />
        <Route path={"/bonus/offer/:id"} component={EditBonusOffer} />
        <Route path={"/bonus/monetary-configs/list"} component={MonetaryConfigListView} />
        <Route path={"/bonus/monetary-config/:id"} component={EditMonetaryConfig} />
        <Route path={"/bonus/offer-rules/list"} component={OfferRuleListView} />
        <Route path={"/bonus/offer-rule/:id"} component={EditOfferRule} />
        <Route path={"/bonus/events/list"} component={BonusEventsListView} />
        <Route path={"/bonus/events/:id"} component={BonusEventsListView} />
        <Route path={"/bonus/view/:id"} component={BonusView} />
        <Route exact path={"/casino-category/:id"} component={CasinoCategoryView} />
        <Route path={"/casino-studios"} component={CasinoStudioListView} />
        <Route path={"/casino-lobby"} component={CasinoLobbyView} />
        <Route path={"/casino-game-tags"} component={CasinoGameTagListView} />
        <Route exact path={"/casino-game-tag/:id"} component={EditCasinoGameTag} />
        <Route path={"/casino-games"} component={CasinoGameListView} />
        <Route exact path={"/casino-game/:id"} component={EditCasinoGameView} />
        <Route exact path={"/casino-studio/:id"} component={EditCasinoStudioView} />
        <Route path={"/casino-update-log/list"} component={CasinoUpdateLogListView} />
        <Route path={"/casino-update-log/id/:id"} component={CasinoUpdateLogView} />
        <Route exact path={"/payment/:id"} component={PaymentView} />
        <Route exact path="/bundles" component={BundlesListView}/>
        <Route exact path="/bundles/:id" component={BundleView}/>
        <Switch>
        <Route exact path="/affiliates/settings" component={AffiliatesSettingsView}/>
        <Route path="/affiliates/reports/payouts" component={AffiliatesReportPayoutsView}/>
        <Route path="/affiliates" component={AffiliateListView}/>
    
        </Switch>
        <Route exact path="/affiliate/user/:id" component={AffiliateView}/>
        <Route exact path="/affiliate/user/edit/:id" component={AffiliateEditView}/>
        <Route exact path="/affiliate/detailed-report/:id" component={AffiliatesDetailedReportView}/>
        {/*<Route exact path="/affiliates/reports/revenue" component={AffiliatesReportRevenueView}/>*/}

        <Route exact path="/translation" component={TranslationsListView}/>
        <Route exact path="/guides/styling" component={CssStyling}/>
        <Route exact path="/guides/cms" component={CMSGuide}/>
        <Route exact path="/notifications" component={NotificationsView}/>
        <Route exact path="/integrations" component={ProviderInfoView}/>
        <Route exact path="/create-batch" component={CreateBatchTransactionView}/>
        <Route exact path="/batch-transaction/:id" component={BatchTransactionView}/>
        <Route exact path="/report/manage" component={ReportManagerView}/>
        <Route exact path="/analytics/amplitude" component={AmplitudeManagerView}/>
        <Route exact path="/metabase/dashboards" component={MetabaseDashboardListView}/>
        <Route exact path={"/metabase/reports"} component={MetabaseReportsPage} />
        <Route exact path="/metabase/dashboard/:id" component={MetabaseDashboardView}/>
        <Route exact path="/metabase/report/:id" component={MetabaseReportView}/>
        <Route exact path="/rewards/config/:id" component={CreateUserReward}/>
        <Route path="/rewards/configs/list" component={RewardTemplateListView}/>
        <Route exact path="/user-rewards/assign" component={AssignRewardToUser}/>
        <Route exact path="/user-reward/:id" component={ShowRewardToUser}/>
        <Route exact path={"/casino-page/:id"} component={EditCasinoPage} />
        <Route path={"/casino-pages"} component={CasinoPageListView} />
        <Route path="/user-rewards/list" component={UserGameRewardListView}/>
        <Route path="/user-journey/:id" component={EditUserJourney}/>
        <Route path="/user-journeys/" component={UserJourneyListView}/>
        <Route path={"/user-risk-rules"} component={UserRiskRuleCollectionListView} />
        <Route path={"/user-risk-rules-edit/:id"} component={EditUserRiskCollection} />
        <Route path="/security-settings/" component={SecuritySettingsView}/>
        <Route path="/widgets" component={ScriptWidgetsListView}/>
        <Route path={"/widget/:id"} component={EditWidget} />
        <Route path={"/settings/iprules"} component={IpRuleView} />
        <Route path={"/maintenance/:id"} component={MaintenanceView} />
        <Route path={"/maintenance"} exact={true} component={ListMaintenanceView} />
        <Route path={"/sportsbook/list"} component={SportsbookBetListView} />
        <Switch>
            <Route exact path="/sportsbook/freebets/list" component={SportbookFreebetsListView} />
            <Route path="/sportsbook/freebets/:id" component={SportsbookBonusDetailsView} />
        </Switch>
        <Route path={"/sportsbook/bet/:id"} component={SportsbookBetView} />
        <Route path={"/casino-jackpots"} component={JackpotListView} />
        <Route path={"/transactions"} component={TransactionListView} />
        <Route path={"/finance"} component={ProviderReportsView} />
        <Route path={"/admin/sites"} component={ListSitesView} />
        <Route path={"/admin/site/:id"} component={EditSite} />

    </Fragment>
)