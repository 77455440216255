import {HttpService} from "../http/HttpService";
import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {AwardBonusOfferRequest, BonusOffer, BonusOfferListResponse} from "../http/protocol";


export class BonusOfferService  extends AbstractAdminEntityService<BonusOffer, BonusOfferListResponse>  {

    constructor(http : HttpService) {
        super("/bonus/offer", http)
    }

    awardUserBonus(bonus:AwardBonusOfferRequest) {
        return this.http.post(this.entityPath + "/award", bonus);
    }

}