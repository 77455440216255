/* eslint-disable prefer-const */
import React, { useEffect, useRef, useState } from 'react';

import { Button } from 'react-bootstrap';
import { DateRangePicker, defaultStaticRanges, DateRange } from 'react-date-range';
import { isSameDay, startOfDay, subDays, endOfDay, startOfWeek, startOfMonth, subMonths, endOfMonth } from 'date-fns';

import moment from 'moment';
import { useClickOutside }  from "../../../util/utils";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

export enum DatePeriod {
    YEARLY = 'P1Y',
    MONTHLY = 'P1M',
    DAILY = 'P1D',
    WEEKLY = 'P7D',
}

export enum DateFormat {
    YEARLY = 'YYYY',
    MONTHLY = 'YYYY-MM',
    DAILY = 'YYYY-MM-DD',
    WEEKLY = 'YYYY-MM-DD',
    STATIC = "yyyy-MM-dd'T'HH:mm:ss",
}

export interface DetailsListRequest {
    from: string;
    to: string;
    period: DatePeriod;
}

interface CalendarProps {
    setRange?: any;
    range?: any;
    setRangeStatus?: any;
}
const DateFilter: React.FC<CalendarProps> = (props) => {

    const [show, setshow] = useState(false);
    const menuRef = useRef(null);

    let currentDate = new Date();
    let currentMonth = currentDate.getMonth();
    currentDate.setMonth(currentMonth - 1);

    const [startDate, setStartDate] = useState<Date>(props.range?.from ? new Date(props.range.from):currentDate.setMonth(currentMonth - 1) as any);
    const now = new Date();
    const utc_timestamp = now.toLocaleString("en-US",{timeZone: `Europe/Lisbon`});
    const [endDate, setEndDate] = useState<Date>(new Date(utc_timestamp));
    const [dateError, setDateError] = useState('');
    const setToggleStates = () => setshow(false);

    useClickOutside(menuRef, setToggleStates);
    useEffect(() => {
        if (props.setRangeStatus) {
            props.setRangeStatus({ from: moment(startDate), to: moment(endDate) });
        }
    }, [startDate, endDate]);

    function handleSelect(date:any) {
        if (!date) return;
        setStartDate(date?.selection?.startDate);
        setEndDate(date?.selection?.endDate);
    }

    function proceedFilter() {
        if (new Date(endDate).getTime() > new Date(utc_timestamp).getTime()) {
            setDateError("Not possible to filter past current date");
            throw new Error("Not possible to filter past current date" as any);
        } else {
            setDateError('');
        }

        const request = {
            from: moment(startDate).format(),
            to: moment(endDate).format(),
        };
        setshow(false);
        props.setRange(request);
    }

    function cancelFilter() {
        /* setStartDate(currentDate.setMonth(currentMonth - 1) as any); */
        setStartDate(new Date());
        setEndDate(new Date());
        props.setRange({ from: currentDate.setMonth(currentMonth - 1) as any, to: new Date() });
        setshow(false);
    }

    const selectionRange = {
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
    };

    customStaticRanges();

    return (
        <div ref={menuRef} className="table-calendar-filter-container dropdown">
            <div >
                <Button onClick={() => setshow((p) => !p)} variant="light" className="btn-sm table-calendar-filter-btn  ">
                    <i className="ki-duotone ki-calendar fs-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                    </i>
                    <div className="d-flex p-2 gap-sm-5  align-items-center">
                        <div className="">{props.range?.from ? moment(props?.range?.from).format('LL'):moment(startDate).format('LL')}</div>
                            <div className="">-</div>
                        <div className="">{moment(endDate).format('LL')}</div>
                    </div>
                </Button>
            </div>
    
                {show && (
                    <div className={'card table-calendar-filter-dropdown desktop dropdown-menu'} aria-labelledby="dropdownMenuButton">
                        <div className="px-2 pt-5">
                        <DateRangePicker rangeColors={['#51a8d3']} staticRanges={[...customStaticRanges()]} ranges={[selectionRange]} onChange={handleSelect} />
                        </div>
                        <div className="separator border-gray-200 mx-3"></div>
                        <div className="calendar-ranger-picker-actions">
                            <div className="d-flex gap-0 px-2">
                                <button onClick={cancelFilter} type="reset" className="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true">
                                   Reset
                                </button>

                                <button onClick={proceedFilter} type="submit" className="btn btn-sm btn-primary" data-kt-menu-dismiss="true">
                                   Apply
                                </button>
                            </div>
                            {dateError && (
                                <div className="d-flex gap-5 align-items-center">
                                    <p className="text-warning m-0 p-0 fs-6">{dateError}</p>
                                </div>
                            )}
                        </div>
                    </div>
                )}
          
                {/* {show && (
                    <div  className="card table-calendar-filter-dropdown mobile">
                        <div className="px-5">
                            <DateRange rangeColors={['#039ef7']} ranges={[selectionRange]} onChange={handleSelect} />
                        </div>
                        <div className="separator border-gray-200 mx-5"></div>
                        <div className="calendar-ranger-picker-actions">
                            <div className="d-flex gap-0 mt-2 mb-2 px-2">
                                <button onClick={cancelFilter} type="reset" className="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true">
                                   Reset
                                </button>

                                <button onClick={proceedFilter} type="submit" className="btn btn-sm btn-primary" data-kt-menu-dismiss="true">
                                   Apply
                                </button>
                            </div>
                            {!dateError && (
                                <div className="d-flex gap-5 align-items-center">
                                    <p className="text-warning m-0 p-0 fs-6">{dateError}</p>
                                </div>
                            )}
                        </div>
                    </div>
                )} */}
        
        </div>
    );
};

export default DateFilter;

export const customStaticRanges = () => {
    const selectedRanges = [] as any;
    let modifiedRanges = defaultStaticRanges.filter((r) => selectedRanges.includes(r?.label));
    const now = new Date();
    const utc_timestamp = now.toLocaleString("en-US",{timeZone: `Europe/Lisbon`});
    const last7Days = {
        label: "This WeeK",
        range: () => ({
            startDate: startOfWeek(new Date(), { weekStartsOn: 1 }), // Adjusted to start of the current week
            endDate: new Date(),
        }),

        isSelected(range:any) {
            const definedRange = this.range();
            return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate);
        },
    };
    const last30Days = {
        label: "This Month",
        range: () => ({
            startDate: startOfMonth(new Date()),
            endDate: new Date(utc_timestamp),
        }),
        isSelected(range:any) {
            const definedRange = this.range();
            return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate);
        },
    };
    const prevMonth = {
        label: "Previous Month",
        range: () => ({
            startDate: startOfMonth(subMonths(new Date(), 1)),
            endDate: endOfMonth(subMonths(new Date(), 1)),
        }),
        isSelected(range:any) {
            const definedRange = this.range();
            return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate);
        },
    };
    const last6months = {
        label: "Past 6 Months",
        range: () => ({
            startDate: startOfMonth(subMonths(new Date(), 6)),
            endDate: new Date(utc_timestamp),
        }),
        isSelected(range:any) {
            const definedRange = this.range();
            return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate);
        },
    };
    const lastYear = {
        label:"Past 12 Months",
        range: () => ({
            startDate: startOfDay(subDays(new Date(), 360)),
            endDate: new Date(utc_timestamp),
        }),
        isSelected(range:any) {
            const definedRange = this.range();
            return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate);
        },
    };

    modifiedRanges.push(last7Days);
    modifiedRanges.push(last30Days);
    modifiedRanges.push(prevMonth);
    modifiedRanges.push(last6months);
    modifiedRanges.push(lastYear);

    return modifiedRanges;
};
