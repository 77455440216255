import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {AffiliatesSettings, ListResponse} from "../http/protocol";
import {HttpService} from "../http/HttpService";


export class AffiliatesPaymentService extends AbstractAdminEntityService<AffiliatesSettings,ListResponse> implements AffiliatesPaymentService{
    constructor(http: HttpService) {
        super("/affiliates/payouts", http);
    }

}