import * as React from "react";
import {LoadingViewState, RouterView} from "../RouterView";
import {formatDate} from "../../util/date";
import {RouteComponentProps, withRouter} from "react-router";
import {WalletService} from "../../services/WalletService";
import {getContainer} from "../../ioc/IOCSetup";
import {TransactionEntriesService} from "../../services/TransactionEntriesService";
import {Account, AccountBalanceResult, AccountStatus, PermissionCategory} from "../../http/protocol";
import {AccountActions} from "./AccountActions";
import {UserLinkPanel} from "../user/UserLinkPanel";
import {AttributePanel} from "../AttributePanel";
import {TransactionEntriesList} from "./TransactionEntriesList";
import {t} from "../../i18n";
import {AccessLevel, PermissionRequired} from "../security/PermissionRequired";


interface State extends LoadingViewState{
    balance? : AccountBalanceResult;
    account? : Account;
}

export class AccountView extends RouterView<RouteComponentProps,State>{

    walletService : WalletService;
    transactionEntriesService : TransactionEntriesService;

    constructor(props : RouteComponentProps){
        super(props);
        this.walletService = getContainer().getWalletService();
        this.transactionEntriesService = getContainer().getTransactionEntriesService();
        this.state = { };
    }

    getTitle() {
        return t(`account.pageTitle`);
    }

    loadContent(): Promise<any>  {
        var id = (this.props.match.params as any).id;
        return Promise.all<any>([
            this.walletService.getAccountBalance(id),
            this.walletService.getAccount(id)

        ]).then((results) => {
            let balance : AccountBalanceResult = results[0];
            let account : Account = results[1];
            this.setState({ balance : balance, account : account});
        })
    }


    getDateTime(timestamp?:Date|number) {
        if (typeof(timestamp) == "undefined" || timestamp < 2) {
            return "";
        }
        return formatDate(timestamp);
    }

    getAccountStatusBadge() {

        if (this.state.account && this.state.account.status == AccountStatus.OPEN) {
            return (<span className="badge badge-success float-right">{t(`badge.open`)}</span>);
        } else {
            return (<span className="badge badge-danger float-right">{t(`badge.closed`)}</span>);
        }
    }

    renderContent() {
        const amount = this.state.balance && this.state.balance.balance && this.state.balance.balance.amount && this.state.balance.balance.amount.toLocaleString("en",{maximumFractionDigits:8, minimumFractionDigits:2});
        if (!this.state.balance || !this.state.balance.balance || !this.state.account || !this.state.account.attributes) {
            return <div></div>;
        }
        return (
            <div>

                    <div className="row">
                        <div className="col-lg-6">
                            <div className="card-box">
                                <div className="ibox-content">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="m-b-md">
                                                <dd className="pull-right">{this.getAccountStatusBadge()}</dd>
                                                <h4>{t(`title.accountDetails`)}</h4>
                                                <small className="text-success">{this.state.account.attributes["gameName"]}</small>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12">
                                            <table className="table table-striped">
                                                <tbody>
                                                <tr>
                                                    <td style={{ width : "30%"}}>{t(`dataTable.header.accountId`)}:</td>
                                                    <td>{this.state.balance.accountId}</td>
                                                </tr>
                                                <tr>
                                                    <td>{t(`dataTable.header.status`)}:</td>
                                                    <td>{this.state.account.status}</td>
                                                </tr>
                                                <tr>
                                                    <td>{t(`dataTable.header.balance`)}:</td>
                                                    <td>{amount}</td>
                                                </tr>

                                                <tr>
                                                    <td>{t(`dataTable.header.currency`)}:</td>
                                                    <td>{this.state.account.currencyCode}</td>
                                                </tr>
                                                <tr>
                                                    <td>{t(`dataTable.header.role`)}:</td>
                                                    <td>{this.state.account.attributes["ROLE"]}</td>
                                                </tr>
                                                <tr>
                                                    <td>{t(`dataTable.header.userId`)}:</td>
                                                    <td>{this.state.account.userId}</td>
                                                </tr>
                                                <tr>
                                                    <td>{t(`dataTable.header.operatorId`)}:</td>
                                                    <td>{this.state.account.operatorId}</td>
                                                </tr>
                                                <tr>
                                                    <td>{t(`dataTable.header.accountType`)}:</td>
                                                    <td>{this.state.account.type}</td>
                                                </tr>
                                                <tr>
                                                    <td>{t(`dataTable.header.subtype`)}:</td>
                                                    <td>{this.state.account.subtype}</td>
                                                </tr>
                                                <tr>
                                                    <td>{t(`dataTable.header.created`)}:</td>
                                                    <td>{this.getDateTime(this.state.account.created)}</td>
                                                </tr>
                                                <tr>
                                                    <td>{t(`dataTable.header.closed`)}:</td>
                                                    <td>{this.getDateTime(this.state.account.closed)}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {this.state.account && (
                                <AccountActions onAccountUpdated={()=>this.refreshContent()} account={this.state.account}/>
                            )}
                        </div>

                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-6">
                                    {this.state.account.userId &&
                                        <UserLinkPanel userId={this.state.account.userId} header="Account Owner" />
                                    }
                                </div>
                                { this.state.balance.balance &&
                                    <div className="col-lg-6">
                                        <div className="card-box">
                                            <span className="badge badge-success float-right">{this.state.balance.balance.currencyCode}</span>
                                            <h5>{t(`title.balance`)}</h5>
                                            <h4 className="no-margins">{amount+" "+this.state.balance.balance.currencyCode}</h4>
                                            <small className="text-muted">{this.state.account.attributes["ROLE"]}</small>

                                        </div>
                                    </div>
                                }

                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <AttributePanel header={t(`title.accountAttributes`)} attributes={this.state.account.attributes}/>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <PermissionRequired permissions={[PermissionCategory.TRANSACTIONS]} componentName={"Transactions List"} accessLevel={"r"} showError={true}>
                                <TransactionEntriesList accountId={(this.props.match.params as any).id} service={this.transactionEntriesService} />
                            </PermissionRequired>
                        </div>
                    </div>




            </div>
        );
    }

}
export default withRouter(AccountView);