import { AbstractAdminEntityService } from "./AbstractAdminEntityService";
import { HttpService } from "../http/HttpService";
import { AbuseReportRequest } from "../http/protocol";

export class AbuseService extends AbstractAdminEntityService<any, any> {
    constructor(http: HttpService) {
        super("/abuse", http);
    }

    reportAbuse(request: AbuseReportRequest): Promise<any> {
        return this.http.post(this.entityPath + `/report/`, request);
    }

    lookupReports(userId: string): Promise<any> {
        return this.http.get(this.entityPath + `/lookup/${userId}`);
    }

    removeReport(userId: string): Promise<any> {
        return this.http.delete(this.entityPath + `/remove/${userId}`);
    }
}