import * as React from "react";
import {AuditEntityType, AuditEventSubType, AuditLog, AuditObjectType, ListAuditLogsRequest} from "../../http/protocol";
import {ListView, ListViewProps, ListViewState} from "../ListView";
import {Form, FormListener} from "../form/Form";
import {ActionType} from "../list/list/DataTable";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {ListUtils} from "../list/list/ListUtils";
import {PagerModel} from "../list/list/PagerModel";
import {getContainer} from "../../ioc/IOCSetup";
import {withRouter} from "react-router";
import {AuditLogService} from "../../services/AuditLogService";
import {ColumnType} from "../list/list/ColumnType";
import {SelectInput} from "../form/SelectInput";
import {NavLink, RouteComponentProps} from "react-router-dom";


interface State extends ListViewState<ListAuditLogsRequest> {
    listRequest?: ListAuditLogsRequest,
    form?: ListAuditLogsRequest,
    validationMap?: any;
    operatorError?: boolean;
}

export class AuditLogListView extends ListView<State, ListAuditLogsRequest> implements FormListener<ListAuditLogsRequest> {


    auditLogService: AuditLogService;

    columns = [
        {property: "id", name: "ID", orderBy: "id"},
        {property: "timestamp", name: "Timestamp", orderBy: "timestamp", type: ColumnType.DATE},
        {
            property: "actorId", name: "Actor",
            renderer: (property: string, name: string, item: any) => {
                const email = item["actorEmail"]

                const actorType = item["actorType"]
                const actorId = item["actorId"]

                if (actorType === AuditEntityType.USER) {
                    return <span><NavLink to={"/user/" + actorId}><span className="badge badge-info">{actorType}</span> {email ? `${actorId} - ${email}` : `${actorId}`}</NavLink></span>
                } else if (actorType === AuditEntityType.ADMIN) {
                    return <span><NavLink to={"/admin/admin-user/" + actorId}><span className="badge badge-info">{actorType}</span> {email ? `${actorId} - ${email}` : `${actorId}`}</NavLink></span>
                } else if (actorType === AuditEntityType.AFFILIATE) {
                    return <span><NavLink to={"/affiliates/user/" + actorId}><span className="badge badge-info">{actorType}</span> {email ? `${actorId} - ${email}` : `${actorId}`}</NavLink></span>
                } else {
                    return <span><span className="badge badge-info">{actorType}</span> {actorId}</span>
                }
            }

        },
        {
            property: "targetId", name: "Target", 
            renderer: (property: string, name: string, item: any) => {
                const targetType = item["targetType"]
                const targetId = item["targetId"]

                if (targetType === AuditEntityType.USER) {
                    return <span><NavLink to={"/user/" + targetId}><span className="badge badge-info">{targetType}</span> {targetId}</NavLink></span>
                } else if (targetType === AuditEntityType.ADMIN) {
                    return <span><NavLink to={"/admin/admin-user/" + targetId}><span className="badge badge-info">{targetType}</span> {targetId}</NavLink></span>
                } else if (targetType === AuditEntityType.AFFILIATE) {
                    return <span><NavLink to={"/affiliates/user/" + targetId}><span className="badge badge-info">{targetType}</span> {targetId}</NavLink></span>
                } else {
                    return <span><span className="badge badge-info">{targetType}</span> {targetId}</span>
                }
            }
        },
        {
            property: "affectedObjectId", name: "Affected Object", 
            renderer: (property: string, name: string, item: any) => {
                const aoType = item["affectedObjectType"]
                const aoId = item["affectedObjectId"]

                if (!aoType  ||  !aoId) {
                    return <></>
                } else if (aoType == "VOUCHER") {
                    return <span><NavLink to={"/voucher/details/" + aoId}><span className="badge badge-info">{aoType}</span> {aoId}</NavLink></span>
                } else {
                    return <span><span className="badge badge-info">{aoType}</span> {aoId}</span>
                } 
            }
        },

        {property: "eventName", name: "Event Type"},
        {property: "description", name: "Description"},

    ];

    actions = [
        {
            id: "view",
            name: "Detail",
            className: "btn btn-primary",
            iconClassName: "fa fa-eye",
            type: ActionType.LINK,
            getLink: (item: AuditLog) => "/audit-log/" + item.id
        }
    ];


    constructor(props: ListViewProps) {
        super(props, "/audit-logs");
        let listRequest = {limit: 50, ascending: false, orderBy: "id"}
        this.state = {...this.state, validationMap: {}, form: {...listRequest, ...this.state.listRequest}, listRequest: {...listRequest, ...this.state.listRequest}};
        this.auditLogService = getContainer().getAuditLogService();
    }

    formDidUpdate(formModel: ListAuditLogsRequest, validationMap: any): void {
        this.setState({form: formModel, validationMap: validationMap});
    }

    onSubmitError(): void {
        console.log("submit error!")
    };

    onSubmit(): void {
        let listRequest: ListAuditLogsRequest = Object.assign({}, this.state.form);
        listRequest.offset = 0;
        this.updateListState(listRequest);
    };

    filterEmpty(o: any): any {
        if (o == null || o == undefined || o == "") {
            return undefined;
        } else {
            return o;
        }
    }


    pagerChanged(pager: PagerModel) {
        if (!this.state.listRequest) {
            return;
        }
        var listRequest = ListUtils.toListRequest(this.state.listRequest, pager);
        this.setState({listRequest: listRequest});
    }

    onAction(item: AuditLog, action: string): undefined | Promise<any> {
        return;
    }

    getTitle() {
        return "Audit Logs";
    }

    renderContent() {
        if (!this.state.listRequest) {
            return <span></span>
        }

        const sortedAuditEventSubTypes = Object.values(AuditEventSubType).sort();
        const sortedAuditObjectTypes = Object.values(AuditObjectType).sort();
        const sortedAuditEntityTypes = Object.values(AuditEntityType).sort();
        return (
            <div>
                <div className={"card-box"}>
                    <div className="ibox-content">

                        <Form className="form-horizontal" formListener={this} modelObject={this.state.form}
                              validationMap={this.state.validationMap}>
                            <FormGroup className="form-group">
                                <label className="col-lg-2 control-label">ID</label>
                                <div className="col-lg-10">
                                    <FormInput className="form-control" model="id" type={"number"}/>
                                </div>
                            </FormGroup>
                            <FormGroup className="form-group">
                                <label className="col-lg-2 control-label">Actor ID</label>
                                <div className="col-lg-10">
                                    <FormInput className="form-control" model="actorId" type={"number"}/>
                                </div>
                            </FormGroup>
                            <FormGroup className="form-group">
                                <label className="col-lg-2 control-label">Actor Email</label>
                                <div className="col-lg-10">
                                    <FormInput className="form-control" model="actorEmail" type={"text"}/>
                                </div>
                            </FormGroup>
                            <FormGroup className="form-group">
                                <label className="col-lg-2 control-label">Actor Type</label>
                                <div className="col-lg-10">
                                    <SelectInput size={1} className="form-control" model="actorType" optional={true}
                                                 values={sortedAuditEntityTypes} multiple={false}/>
                                </div>
                            </FormGroup>
                            <FormGroup className="form-group">
                                <label className="col-lg-2 control-label">Target ID</label>
                                <div className="col-lg-10">
                                    <FormInput className="form-control" model="targetId" type={"number"}/>
                                </div>
                            </FormGroup>
                            <FormGroup className="form-group">
                                <label className="col-lg-2 control-label">Target Type</label>
                                <div className="col-lg-10">
                                    <SelectInput size={1} className="form-control" model="targetType" optional={true}
                                                 values={sortedAuditEntityTypes} multiple={false}/>
                                </div>
                            </FormGroup>




                            <FormGroup className="form-group">
                                <label className="col-lg-2 control-label">Affected Object ID</label>
                                <div className="col-lg-10">
                                    <FormInput className="form-control" model="affectedObjectId" type={"string"}/>
                                </div>
                            </FormGroup>
                            <FormGroup className="form-group">
                                <label className="col-lg-2 control-label">Affected Object Type</label>
                                <div className="col-lg-10">
                                    <SelectInput size={1} className="form-control" model="affectedObjectType" optional={true}
                                                 values={sortedAuditObjectTypes} multiple={false}/>
                                </div>
                            </FormGroup>


                            <FormGroup className="form-group">
                                <label className="col-lg-2 control-label">Event Type</label>
                                <div className="col-lg-10">
                                    <SelectInput size={1} className="form-control" model="eventName" optional={true}
                                                 values={sortedAuditEventSubTypes} multiple={false}/>
                                </div>
                            </FormGroup>
                            
                            <div className="form-group">
                                <div className="col-lg-offset-2 col-lg-10">
                                    <input className="btn btn-primary" value="List Audit Logs" type={"submit"}/>
                                </div>
                            </div>
                            
                        </Form>
                    </div>
                </div>


                <EntityDataTable
                    service={this.auditLogService}
                    onPagerChange={this.pagerChanged.bind(this)}
                    columns={this.columns}
                    listRequest={this.state.listRequest}
                    onAction={this.onAction.bind(this)}
                    actions={this.actions}
                />
            </div>
        );
    }

}

export default withRouter(AuditLogListView)