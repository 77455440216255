
import { RefObject, useEffect } from 'react';


export function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function useClickOutside<T extends HTMLElement = HTMLElement>(ref: RefObject<T>, callback: () => void) {
    useEffect(() => {
        function handleClickOutside(event:any) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [callback, ref]);

    return ref;
}
