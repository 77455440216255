import React, {Fragment} from "react";
import {ListPaymentsRequest, ListUsersRequest, ListVouchersRequest, Payment, VoucherStatus} from "../../http/protocol";
import {PaymentService} from "../../services/PaymentService";
import {createInitialState, ListView, ListViewProps, ListViewState} from "../ListView";
import {Form, FormListener} from "../form/Form";
import {ColumnType} from "../list/list/ColumnType";
import {PagerModel} from "../list/list/PagerModel";
import {ListUtils} from "../list/list/ListUtils";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {ActionType} from "../list/list/DataTable";
import {Validators} from "../form/Validators";
import {SelectInput} from "../form/SelectInput";
import {Link} from "react-router-dom";
import {withRouter} from "react-router";
import {Alert} from "../form/Alert";
import {getContainer} from "../../ioc/IOCSetup";
import {MetricsSingleValuePanel} from "../metrics/MetricSingleValuePanel";
import {t} from "../../i18n";
import { Collapsible } from "../Collapsible";
import { VoucherService } from "../../services/VoucherService";

interface State extends ListViewState<ListVouchersRequest> {
    listRequest?: ListVouchersRequest;
    form?: ListVouchersRequest;
    validationMap?: any;
    lookupId?: string;
    lookupError?: string;
    pendingWithdraws?:number;
    failedPayments?:number;
}

interface Props extends ListViewProps {
}

function userLinkRenderer(prop : any , name : any, item : any) : any {
    if (item.userId) {
        return <Link key={"user-action" + item.userId} to={`/user/${item.userId}`}>{item.userId}</Link>;
    } else {
        return <div>n/a</div>;
    }
}

function amountRenderer(prop : any, name : any, item : any) : any{
    return <div>{item.amount} {item.currency}</div>
}

export class VoucherListView extends ListView<State, ListVouchersRequest> implements FormListener<ListVouchersRequest> {

    columns = [
        { property : "id", name : "Id", orderBy : "id"},
        { property : "userId", name : "User Id", renderer: userLinkRenderer },
        { property : "status", name : "Status"},
        { property : "amount", name : "Amount", renderer: amountRenderer },
        { property : "created", name : "Created", type : ColumnType.DATE},
        { property : "expiry", name : "Expiry", type : ColumnType.DATE},
    ];


    voucherService : VoucherService;

    constructor(props : Props){
        super(props, "/vouchers");

        let listRequest = {limit: 20, ascending: false, orderBy: "id"}
        this.state = {...this.state, validationMap: {}, form: {...listRequest, ...this.state.listRequest}, listRequest: {...listRequest, ...this.state.listRequest}};

        this.voucherService = getContainer().getVoucherService();
    }

    formDidUpdate(formModel: ListVouchersRequest, validationMap : any): void {
        this.setState({ form : formModel, validationMap : validationMap});
    }

    onSubmitError(): void {

    };

    onSubmit(): void {
        if (this.state.form){
            let listRequest = this.state.form;
            listRequest.offset = 0;
            listRequest.orderBy = "id";
            this.updateListState(listRequest);
        }
    };

    onStatusesChanged(statuses: string[]) {
        let newReq = Object.assign({}, this.state.listRequest, { statuses: statuses });
        this.setState({ listRequest: newReq });
    }

    pagerChanged(pager : PagerModel) {
        if(this.state.listRequest) {
            console.log("pager changed!", pager);
            let listRequest = ListUtils.toListRequest<any>(this.state.listRequest,pager);
            this.updateListState(listRequest);
        }
    }

    onAction(item : Payment, action : string) : Promise<any> | undefined {
        if (action == "details") {
            this.navigate("/voucher/details/" + item.id);
        }
        return;
    }

    getTitle() {
        return t(`payments.vouchers.pageTitle`);
    }

    renderContent() {
        if (!this.state.listRequest) {
            return <div />;
        }
        return (
            <Fragment>
                <div>
                    <div className="wrapper wrapper-content">


                        <div className={"card-box"}>
                            <div className="ibox-content">

                                <Form className="form-horizontal" formListener={this} modelObject={this.state.form}
                                    validationMap={this.state.validationMap}>
                                    <FormGroup className="form-group">
                                        <label className="col-lg-2 control-label">User Id</label>
                                        <div className="col-lg-10">
                                            <FormInput className="form-control" model="userId" type={"number"}/>
                                        </div>
                                    </FormGroup>

                                    <FormGroup className="form-group">
                                        <label className="col-lg-2 control-label">Code</label>
                                        <div className="col-lg-10">
                                            <FormInput className="form-control" model="code" type={"string"}/>
                                        </div>
                                    </FormGroup>

                                    <FormGroup className="form-group">
                                        <label className="col-lg-2 control-label">Voucher Status</label>
                                        <div className="col-lg-10">
                                            <SelectInput size={Object.values(VoucherStatus).length} className="form-control" model="statuses" optional={true}
                                                values={Object.values(VoucherStatus)} multiple={true}/>
                                        </div>
                                    </FormGroup>                                    

                                    <div className="form-group">
                                        <div className="col-lg-offset-2 col-lg-10">
                                            <input className="btn btn-primary" value="List Vouchers" type={"submit"}/>
                                        </div>
                                    </div>

                                </Form>
                            </div>
                        </div>


                        <EntityDataTable service={this.voucherService}
                            onPagerChange={this.pagerChanged.bind(this)}
                            columns={this.columns}
                            listRequest={this.state.listRequest}
                            onAction={this.onAction.bind(this)}
                            actions={
                                [
                                    { id: "details", name: "Details", className: "btn btn-primary", type: ActionType.LINK}
                                ]
                            }/>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default withRouter(VoucherListView);