import axios, {AxiosInstance, AxiosRequestConfig} from "axios";
import {UserActions} from "../user/UserActions";
import {reject} from "q";


export class HttpService {
    axios : AxiosInstance;
    lastActivity : number = Date.now();
    constructor(private baseUrl : string) {
        this.axios = axios.create({
            headers : {
                "Content-type" : "application/json"
            }
        });
        this.axios.interceptors?.request?.use((cfg) => {
            if(cfg.url?.indexOf("/user/ping") == -1){
                this.updateActivity();
            } else {
                console.log("Will ignore ping for activity check");
            }
            return cfg;
        })
    }
    updateActivity() {
        this.lastActivity = Date.now();
    }
    hasSeenActivitySince(minutes : number) {
        console.log("Last activity " + ((Date.now() - this.lastActivity) / 1000) + " seconds ago");
        return this.lastActivity > Date.now() - (minutes * 60 * 1000);
    }

    getBaseUrl = () => this.baseUrl;

    get<R>(url : string) : Promise<R> {
        return  new Promise((resolve,reject)=> {
            this.axios.get(this.baseUrl + url, { withCredentials : true })
                .then(response => resolve(response.data))
                .catch(e => this.handleError(url, e, reject))
        })
    }
    post<R,E>(url: string, entity : E, config?: AxiosRequestConfig) : Promise<R> {
        return  new Promise((resolve,reject)=> {
            this.axios.post(this.baseUrl + url,entity, {...config, withCredentials : true})
                .then(response => resolve(response.data))
                .catch(e => this.handleError(url, e, reject))
        })
    }

    put<R,E>(url:string, entity : E) : Promise<R> {
        return new Promise((resolve,reject) => {
            this.axios.put(this.baseUrl + url,entity, {withCredentials: true})
                .then(response => resolve(response.data))
                .catch(e => this.handleError(url, e, reject))
        })
    }

    delete(url: string) : Promise<{}> {
        return  new Promise((resolve,reject)=> {
            this.axios.delete(this.baseUrl + url,{ withCredentials : true })
                .then(r => resolve(r.data))
                .catch(e => this.handleError(url, e, reject))
        })
    }
    handleError(path: string, response : any, reject : (msg? : any)=>void) {
        if(path.indexOf("user/login") != -1 || path.indexOf("user/info") != -1) {
            console.log("Skipping error handling on resources ", path);
            reject({message : "Not logged in", status : response.status});
            return;
        }
        console.log("Error for path " + path + " with status " + response.status, response);
        var userActions = new UserActions();
        let status = response.request.status;
        if(status == 401) {
            userActions.getUserInfo(false);

        } else if(status == 403) {
            userActions.getUserInfo(false);
            userActions.requestForbidden();
        }
        if(status == 401 || status == 403){
            reject({ message : "Access denied", status : status });
        } else if (response.response.headers && response.response.headers["content-type"]  == "application/json") {
            reject({message : response.response.data.message, data: response.response.data, status : status})
        } else if (response.response.headers?.["content-type"] == "text/plain") {
            reject({message : response.response.data, status : status})
        } else if(status >= 500 && status<600) {
            reject({ message : "Internal server error, if this persist please contact support. ", status : status });
        } else {
            reject({ message : "Error with status " + status, status : status });
        }


    }
}