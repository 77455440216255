import * as React from "react";
import {SettingsService} from "../../services/SettingsService";
import {getContainer} from "../../ioc/IOCSetup";
import {LoadingViewState, RouterView} from "../RouterView";
import {Form, FormListener} from "../form/Form";
import {RouteComponentProps, withRouter} from "react-router";
import {Fragment} from "react";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Submit} from "../form/Submit";
import {Settings} from "../../http/protocol";

interface Props extends RouteComponentProps {

}

interface State extends LoadingViewState{
    settings?: Settings;
    validationMap? : any;

}

export class SettingsNotificationsView extends RouterView<Props,State> implements FormListener<Settings>{

    settingsService: SettingsService;

    constructor(props:Props) {
        super(props);

        this.settingsService = getContainer().getSettingsService();

        this.state = {validationMap : {}, loading : true};
    }

    loadContent(): Promise<any> | undefined {
        return this.settingsService.getSettings().then((data) => {
            this.setState({settings:data});
        });
    }

    componentDidUpdate(prevProps : RouteComponentProps, prevState : any) {
        super.componentDidUpdate(prevProps, prevState);

    }
    formDidUpdate(formModel:Settings, validationMap : any) {
        this.setState({ settings : formModel, validationMap : validationMap});
    }

    onSubmit():Promise<any> {
        if(!this.state.settings) {
            return Promise.reject("Unable to save settings");
        }


        return this.settingsService.saveSettings(this.state.settings).catch(err=>{
            throw "Unable to create/save settings";
        });
    }

    onSubmitError(){}

    getTitle() : string {
        return "Notifications Settings";
    }

    renderContent() {
        if(!this.state.settings) {
            return <span></span>
        }
        return(
            <Fragment>
                <Form className="form-horizontal" formListener={this} modelObject={this.state.settings} validationMap={this.state.validationMap}>
                    <div className={"card"}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <FormSubmitError />
                                    <FormSubmitSuccess text="Settings was updated successfully"/>

                                    <FormGroup className="custom-control custom-checkbox" model="pushNotificationsEnabled">
                                        <FormInput className="custom-control-input" model="pushNotificationsEnabled" id={"pushNotificationsEnabled"} type="checkbox"/>
                                        <label htmlFor={"pushNotificationsEnabled"} className={"custom-control-label"}>Enable Push Notifications</label><br/>
                                        <span className={"help-block"}></span>
                                    </FormGroup>
                                    <FormGroup className="form-group mb-3" model="loginsRequiredForPushPrompt">
                                        <label>Number Of Logins Required</label>
                                        <FormInput className="form-control" model="loginsRequiredForPushPrompt" type="number"/>
                                        <span className={"help-block"}>Number of logins before the user is prompted to enable notifications</span>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"card"}>
                        <div className={"card-body"}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="ibox float-e-margins">
                                        <div className="ibox-content">
                                            <div className="ibox-title">
                                                <h5>Actions</h5>
                                            </div>
                                            <div className="form-group">
                                                <div className="col-sm-10 col-sm-offset-2">
                                                    <Submit className="btn btn-primary waves-effect waves-light">Save Settings</Submit>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Form>


            </Fragment>
        )
    }

}

export default withRouter(SettingsNotificationsView);