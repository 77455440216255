import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {ListUserAttributeDefinitionResponse, UserAttributeDefinition} from "../http/protocol";
import {HttpService} from "../http/HttpService";

export class UserAttributeService extends AbstractAdminEntityService<UserAttributeDefinition, ListUserAttributeDefinitionResponse>   {

    constructor(http : HttpService) {
        super("/user-attribute", http)
    }

}