import {useRouteMatch} from "react-router-dom";
import {RouterViewTemplate, useLoadingState} from "../RouterView";
import {getContainer} from "../../ioc/IOCSetup";
import {AdminScriptWidget} from "../../http/protocol";
import {Form, useForm} from "../form/Form";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {FormSubmitError} from "../form/FormFeedback";
import {Submit} from "../form/Submit";
import {NavLink} from "react-router-dom";


export const EditWidget = () => {
    const match = useRouteMatch<{ id: string }>();

    let promise = match.params.id === "new" ? Promise.resolve({}) : getContainer().getScriptWidgetService().getEntity(match.params.id);

    const [form , listener, setFormState] = useForm<AdminScriptWidget>({}, () => {
         return getContainer().getScriptWidgetService().save(form.model).then(r => setFormState({ model : r, validationMap : form.validationMap}));
    });

    const status = useLoadingState(() => {
        return promise.then((w : AdminScriptWidget) => {
            setFormState({ model : w, validationMap : {}});
        })
    }, [])

    return (
        <RouterViewTemplate status={status} title={"Edit Widget"} path={"/widget/"+ (form.model.id ? form.model.id : "new")}>
            <div style={{margin: "10px 0"}}>
                <NavLink  to={"/widgets"}>Back to List</NavLink>
            </div>
            <div className={"card-box"}>

                <Form className={"form"} formListener={listener} modelObject={form.model} validationMap={form.validationMap}>
                    {match.params.id !== "new" && (
                        <FormGroup className={"form-group"}>
                            <label>ID</label>
                            <FormInput className={"form-control"} model={"id"} disabled={true}/>
                        </FormGroup>
                    )}
                    <FormGroup className={"form-group"}>
                        <label>Variable Name</label>
                        <FormInput className={"form-control"} model={"variableName"} required={true}/>
                        <span className={"help-block"}>Variable exposed from the script implementing the widget API</span>
                    </FormGroup>
                    <FormGroup className={"form-group"}>
                        <label>Script Url</label>
                        <FormInput className={"form-control"} model={"scriptUrl"} required={true}/>
                    </FormGroup>
                    <FormGroup className={"form-group"}>
                        <label>Integration Url</label>
                        <FormInput className={"form-control"} model={"integrationUrl"}/>
                        <span className={"help-block"}>Optional server side integration url called for authentication</span>
                    </FormGroup>
                    <FormGroup className={"form-group"}>
                        <label>Integration Api Key</label>
                        <FormInput className={"form-control"} model={"integrationApiKey"}/>
                        <span className={"help-block"}>Optional key used to authenticate</span>
                    </FormGroup>
                    <FormGroup className={"form-group"}>
                        <FormInput id={"enabled"} model={"enabled"} type={"checkbox"}/> <label htmlFor={"enabled"}>Enabled</label>
                    </FormGroup>

                    <FormSubmitError />
                    <Submit className={"btn btn-primary"}>Submit</Submit>
                </Form>
            </div>
        </RouterViewTemplate>
    )
};