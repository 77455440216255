import * as React from "react";

import {RouteComponentProps, withRouter} from "react-router";
import {LoadingViewState, RouterView} from "../RouterView";
import {CasinoStudio} from "../../http/protocol";
import {Form, FormListener} from "../form/Form";
import {getContainer} from "../../ioc/IOCSetup";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {Submit} from "../form/Submit";
import {CasinoStudioService} from "../../services/CasinoStudioService";
import {t} from "../../i18n";



interface State extends LoadingViewState {
    studio? : CasinoStudio;
    validationMap? : any;
}

export class EditCasinoStudioView extends RouterView<RouteComponentProps,State> implements FormListener<CasinoStudio>{

    service : CasinoStudioService;

    constructor(props : RouteComponentProps){
        super(props);
        this.service = getContainer().getCasinoStudioService();
        this.state = { studio : {}, validationMap : {} };
    }

    getTitle(): string {
        return "Edit Studio";
    }

    componentDidUpdate(prevProps : RouteComponentProps, prevState : State) {
        if((prevProps.match.params as any).id != (this.props.match.params as any).id)  {
            this.refreshContent();
        }
    }

    loadContent(): Promise<any>  {
        return this.service.getEntity((this.props.match.params as any).id).then((s : CasinoStudio) => {
            this.setState({ studio : s });
        });
    }

    onSubmit()  {
        if(!this.state.studio) return;
        return this.service.save(this.state.studio).then((s : CasinoStudio)=> {
            this.setState({studio : s});
        })
    }

    formDidUpdate(formModel: CasinoStudio, validationMap?: any) {
        this.setState({studio : formModel, validationMap : validationMap})
    }


    renderContent() {
        if(!this.state.studio) {
            return <span></span>
        }

        return (
            <div>


                <div className="wrapper wrapper-content animated fadeInRight">
                    <Form className="form form-horizontal" validationMap={this.state.validationMap} modelObject={this.state.studio} formListener={this}>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card-box">
                                    <h5>Account Info</h5>
                                    <div className="ibox-content">

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">ID</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="id" type="integer"  className="form-control" disabled={true}/>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Integration</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="integration" type="text"  className="form-control" disabled={true}/>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Name</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="name" type="text"  className="form-control" />
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Lobby Order</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="lobbyOrder" type="integer"  className="form-control" />
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label" htmlFor={"popular"}>Popular</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="popular" type="checkbox" id={"popular"}/>
                                            </div>
                                            <span className="help-block">If enabled this studio will show up under Popular Studios in the studio filter dialog.</span>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label" htmlFor={"popular"}>Mobile navigation</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="mobileNavigation" type="checkbox" id={"mobileNavigation"}/>
                                            </div>
                                            <span className="help-block">If enabled all games from this studio will have a mobile navigation/toolbar unless disabled on game level.</span>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Image URL</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="imageUrl" type="text"  className="form-control" />
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Icon</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="icon" type="text"  className="form-control" />
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Enabled</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="enabled" type="checkbox" />
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Allowed Countries</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="allowedCountries" type="text"  className="form-control" />
                                                <span className="help-block">Comma separated list of country code. Only allow this studio for the specified countries, example: SE,US</span>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Blocked Countries</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="blockedCountries" type="text"  className="form-control" />
                                                <span className="help-block">Comma separated list of country code. Block this studio for the specified countries, example: SE,US</span>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Blocked Countries By Provider</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="providerBlockedCountries" type="text"  className="form-control"  disabled={true}/>
                                                <span className="help-block">Comma separated list of country codes, blocked by provider and cannot be changed</span>
                                            </div>
                                        </FormGroup>

                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Blocked Currencies</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="blockedCurrencies" type="text"  className="form-control" />
                                                <span className="help-block">Comma separated list of currency codes. Block this studio for the specified currency, example: mBTC,USD</span>
                                            </div>
                                        </FormGroup>
                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Games Tags To Be Added</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="gameTagsToBeAdded" type="text"  className="form-control" />
                                                <span className="help-block">Comma separated list of game tags. Tags will be added to all games of the studio. Also, tags will be applied for the new games in the future.</span>
                                            </div>
                                        </FormGroup>
                                        <div className="hr-line-dashed"></div>
                                        <FormGroup className="form-group">
                                            <label className="col-sm-2 control-label">Games Tags To Be Removed</label>
                                            <div className="col-sm-10">
                                                <FormInput  model="gameTagsToBeRemoved" type="text"  className="form-control" />
                                                <span className="help-block">Comma separated list of game tags. Tags will be removed from all games of the studio.</span>
                                            </div>
                                        </FormGroup>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card-box">

                                    <FormSubmitError />
                                    <FormSubmitSuccess text={t(`alertInfo.accountSuccessfully`)}/>
                                    <div className="form-group">
                                        <div className="col-sm-12">
                                            <Submit className="btn  btn-primary">Save</Submit>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }

}

export default withRouter(EditCasinoStudioView);