import React, {Fragment} from "react";
import {ActionType} from "../list/list/DataTable";
import {
    ListCasinoLobbyCategoryRequest,
    ListChartsRequest,
    ListUsersRequest, TurnoverContributions,
    TurnoverContributionsListRequest,
    User
} from "../../http/protocol";
import {getContainer} from "../../ioc/IOCSetup";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {PagerModel} from "../list/list/PagerModel";
import {ListUtils} from "../list/list/ListUtils";
import {withRouter} from "react-router";
import {ListView, ListViewProps, ListViewState} from "../ListView";
import {UserService} from "../../services/UserService";
import {Form, FormListener} from "../form/Form";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Validators} from "../form/Validators";
import {Submit} from "../form/Submit";
import {ColumnType} from "../list/list/ColumnType";
import {CasinoCategoryService} from "../../services/CasinoCategoryService";
import {TurnoverContributionsService} from "../../services/TurnoverContributionsService";


interface State extends ListViewState<TurnoverContributionsListRequest> {
    form? : ListCasinoLobbyCategoryRequest;
    validationMap? : any;
}
interface Props extends ListViewProps  {

}

export class TurnoverContributionsListView extends ListView<State, TurnoverContributionsListRequest> implements FormListener<TurnoverContributionsListRequest>{

    columns = [
        { property : "id", name : "ID", orderBy : "id"},
        { property : "name", name : "Name"}
    ];

    actions = [
        { id : "delete", name : "delete", className : "btn btn-danger", iconClassName : "fa fa-times", type : ActionType.CONFIRMATION},
        { id : "view", name : "View", className : "btn btn-primary", iconClassName : "fa fa-user", type : ActionType.LINK, getLink : (item : any) => "/bonus/turnover-contributions/" + item.id }

    ];

    service : TurnoverContributionsService;

    constructor(props : Props){
        super(props, "/bonus/turnover-contribution/list");
        this.state = {...this.state,  listRequest : {...this.state.listRequest}, validationMap : {}, form : {}};
        this.service = getContainer().getTurnoverContributionsService();
    }
    onSubmit() : void | Promise<any> {
        if(this.state.form) {
            this.updateListState(this.state.form);
        }
    }
    onSubmitError() :  void {

    }

    formDidUpdate(formModel: TurnoverContributionsListRequest, validationMap?: any): void {
        this.setState({form : formModel, validationMap : validationMap});
    }

    onAction(item : TurnoverContributions, action : string) : Promise<any> | undefined {
        if(action == "delete") {
            return  this.service.remove(""+item.id);
        }
    }

    pagerChanged(pager : PagerModel) {
        if(this.state.listRequest) {
            console.log("pager changed!", pager);
            let listRequest = ListUtils.toListRequest<any>(this.state.listRequest,pager);
            this.updateListState(listRequest);
        }
    }
    getTitle() {
        return "Turnover Contributions";
    }

    renderContent() {
        if(!this.state.listRequest) {
            return <div/>;
        }
        return (
            <Fragment>
                <EntityDataTable service={this.service}
                                 onPagerChange={this.pagerChanged.bind(this)}
                                 columns={this.columns}
                                 listRequest={this.state.listRequest}
                                 onAction={this.onAction.bind(this)}
                                 actions={this.actions}/>
            </Fragment>
        );
    }



}

export default withRouter(TurnoverContributionsListView);
