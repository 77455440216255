import * as React from "react";
import {Entry, Money, Transaction} from "../../http/protocol";
import {TransactionEntriesService} from "../../services/TransactionEntriesService";
import {getContainer} from "../../ioc/IOCSetup";
import {RouteComponentProps, withRouter} from "react-router";
import {formatDate} from "../../util/date";
import {AttributePanel} from "../AttributePanel";
import {Link} from "react-router-dom";
import {Big} from "big.js";

interface State {
    transaction? : Transaction;
}

export class TransactionView extends React.Component<RouteComponentProps,State>{


    transactionEntriesService : TransactionEntriesService;

    constructor(props: RouteComponentProps){
        super(props);
        this.transactionEntriesService = getContainer().getTransactionEntriesService()
        this.state = { };
    }
    componentDidMount() {
        var id = (this.props.match.params as any).id;
        console.log("Account ID ", id);
        this.transactionEntriesService.getTransaction(id).then( (trans : Transaction) => {
            this.setState({transaction : trans});
        });


    }

    render() {
        if (this.state.transaction == null) {
            return <div></div>;
        }
        return (
            <div>
                <h3>Transactions</h3>

                <div className="wrapper wrapper-content animated fadeInRight">



                    <div className="row">
                        <div className="col-lg-6">
                            <div className="card-box">
                                <div className="ibox-content">

                                    <h4>Details</h4>

                                    <div className="row">
                                        <div className="col-lg-12">
                                            <table className="table table-striped">
                                               <tbody>
                                                   <tr>
                                                       <td style={{ width : "30%"}}>Transaction ID:</td>
                                                       <td>{this.state.transaction.id}</td>
                                                   </tr>
                                                   <tr>
                                                       <td>External ID:</td>
                                                       <td>{this.state.transaction.externalId}</td>
                                                   </tr>
                                                   <tr>
                                                       <td>Comment:</td>
                                                       <td>{this.state.transaction.comment}</td>
                                                   </tr>

                                                   <tr>
                                                       <td>Date:</td>
                                                       <td>{formatDate(this.state.transaction.timestamp)}</td>
                                                   </tr>

                                               </tbody>
                                            </table>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-12">
                                    { this.state.transaction && this.state.transaction.attributes &&
                                        <AttributePanel header="Attributes" attributes={this.state.transaction.attributes}/>}
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card-box">
                                <div className="ibox-content">
                                    <h2>Entries</h2>
                                    <table className="table table-striped">
                                        <thead>
                                        <tr>
                                            <th>Entry ID</th>
                                            <th>Account</th>
                                            <th>Account Type</th>
                                            <th>Account Status</th>
                                            <th>User ID</th>
                                            <th>Amount</th>
                                            <th>Resulting Balance</th>
                                            <th>Currency</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        { this.state.transaction && this.state.transaction.entries && this.state.transaction.entries.map( (e : Entry) => {

                                            return (
                                                <tr key={e.id}>
                                                    <td>{e.id}</td>
                                                    <td><Link to={"/account/" + e.accountId}>{e.accountId}</Link></td>
                                                    <td>{e.accountType}</td>
                                                    <td>{e.accountStatus}</td>
                                                    <td>{e.accountUserId && e.accountUserId > 0 && <Link to={"/user/" + e.accountUserId}>{e.accountUserId}</Link>}</td>
                                                    <td>{toMoneyHtml(e.amount)}</td>
                                                    <td>{toMoneyHtml(e.resultingBalance)}</td>
                                                    <td>{e.amount && e.amount.currencyCode}</td>
                                                </tr>
                                            )
                                        })}

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>



                </div>
            </div>
        );
    }

}
export default withRouter(TransactionView);

export function formatMoney(m : Money) : string {
    if(!m.amount) { return ""}
    return new Big(m.amount).toFixed(m.fractionalDigits);

}
export function toMoneyHtml(m? : Money) : JSX.Element{
    if(!m || !m.amount) { return <span></span>}
    return <span className={m.amount < 0 ?  "negative" : "positive"}>{formatMoney(m)}</span>;
}