import {
    AwardBonusOfferRequest,
    BonusConfig,
    BonusOffer,
    MonetaryConfig,
    MonetaryConfigListResponse,
    UserBonusListResponse,
    UserBonusView
} from "../http/protocol";
import {HttpService} from "../http/HttpService";
import {AbstractAdminEntityService} from "./AbstractAdminEntityService";


export class UserBonusService extends AbstractAdminEntityService<UserBonusView, UserBonusListResponse> {
    constructor(http : HttpService) {
        super("/user/bonus", http)
    }

    getActiveBonuses(userId: number): Promise<UserBonusView[]> {
        return this.http.get(`${this.entityPath}/active/${userId}`);
    }

    cancelUserBonus(userId:number, bonusId:number) {
        return this.http.post(this.entityPath + "/cancel/"+userId+"/"+bonusId, {});
    }

    tryEnding(bonusId: number): Promise<UserBonusView> {
        return this.http.put(this.entityPath + "/tryEnding/"+bonusId, {})
    }
}


