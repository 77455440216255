import React from "react";
import {getContainer} from "../../ioc/IOCSetup";
import {Form, FormListener} from "../form/Form";
import {
    AssignRewardToUserRequest,
    CasinoGame,
    AwardBonusOfferRequest, RewardTemplate, UserGameReward
} from "../../http/protocol";
import {Collapsible} from "../Collapsible";
import {SelectInput} from "../form/SelectInput";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {Submit} from "../form/Submit";
import {FormInput} from "../form/FormInput";
import {RewardsConfigService} from "../../services/RewardsConfigService";
import {AddCasinoGames} from "../casino/AddCasinoGames";
import {AssignRewardToUser} from "../rewards/AssignRewardToUser";
import {t} from "../../i18n";



interface Props {
    userId:number;
}


export class UserRewardComponent extends React.Component<Props, { }>{
    service:RewardsConfigService;
    constructor(props:Props) {
        super(props);
        this.service = getContainer().getRewardsConfigService();
        let userId = this.props.userId;
        let initForm:UserGameReward = {};
        initForm.userId = userId
        this.state = { };

    }



    render() {
        return(
            <Collapsible title={t(`title.manualUserReward`)} collapsed={true}>
                <AssignRewardToUser userId={this.props.userId}/>
            </Collapsible>
        )
    }
}