import React, {Fragment} from "react";
import {ListPaymentsResponse, Payment, PaymentResultCode, PaymentType, Transaction, User} from "../../http/protocol";
import {getContainer} from "../../ioc/IOCSetup";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {LoadingViewState, RouterView} from "../RouterView";
import {PaymentService} from "../../services/PaymentService";
import {ConfirmModal} from "../../util/ConfirmModal";
import {DataModal} from "../../util/DataModal";
import {FormGroup} from "react-bootstrap";
import {formatDate} from "../../util/date";
import {UserService} from "../../services/UserService";
import {LoadingSpinner} from "../../util/LoadingSpinner";
import {AdminNotes} from "../notes/AdminNotes";
import {t} from "../../i18n";
import {DeleteButtonModal} from "../../util/DeleteButtonModal";
import {Alert} from "../form/Alert";
import "./PaymentView.scss"

interface State extends LoadingViewState {
    payment? : Payment;
    userPaymentList?: ListPaymentsResponse;
    userInfo?: User;
    logData? : string;
    showActions? : boolean;
    showResolveRefundDialog? : boolean;
    showResolveNoRefundDialog? : boolean;
    showApproveDialog? : boolean;
    showRetryWithdrawalDialog? : boolean;
    showCancelDialog? : boolean;
    showFailDialog? : boolean;
    validationMap? : any;
    transactions?: Transaction[];
    totalDepEuro: number;
    totalWithdrawalEuro: number;
    delta: number;
    errorMessage?: string;
}

interface Props extends RouteComponentProps{
    params: {id: string}
}

export class PaymentView extends RouterView<Props, State>{

    paymentService: PaymentService;
    userService: UserService;

    constructor(props : Props) {
        super(props);
        this.paymentService = getContainer().getPaymentService();
        this.userService = getContainer().getUserService();
        this.state = { payment: {}, logData : "", showActions: false, showApproveDialog: false, showRetryWithdrawalDialog: false, showCancelDialog: false,
            showFailDialog: false, showResolveNoRefundDialog: false, showResolveRefundDialog: false, transactions: [], totalDepEuro:0, totalWithdrawalEuro:0, delta:0};
    }

    async loadContent() : Promise<Promise<any> | void> {
        let id = (this.props.match.params as any).id;
        let payment: Payment;
        let depEuro=0;
        let withdrawalEur=0;

        this.paymentService.getEntity(id).then((a : Payment) => {
            payment = a;
            let userId = payment.userId ? payment.userId : "";
            let request:any = a != undefined ? {userId:userId, ascending: false} : {};
            Promise.all([this.paymentService.list(request), this.userService.getEntity(userId.toString()), this.paymentService.paymentSummary(userId as number)]).then((data) => {
                if(data && data[2]){

                    data[2].forEach(value => {

                        if(value.type==PaymentType.DEPOSIT){
                            depEuro += value!.euroAmount!;
                        }else if(value.type==PaymentType.WITHDRAWAL){
                            withdrawalEur += value!.euroAmount!;
                        }

                    })

                }
                if(data && payment) {
                    this.setState({payment:payment, userPaymentList:data[0], userInfo:data[1], loading: false, showResolveRefundDialog: false, showResolveNoRefundDialog: false,totalWithdrawalEuro: withdrawalEur, totalDepEuro:depEuro,delta:(depEuro - withdrawalEur)})
                }
                this.refreshPayments();
            })
        });

        const txs = await this.paymentService.getPaymentTransactions(id)
        this.setState({transactions: txs})

    }


    refreshPayments() {
        var id = (this.props.match.params as any).id;

    }

    getTitle(): string {
        return "Payment Details";
    }

    async cancelPayment() {
        if (!this.state.payment || !this.state.payment.id) {
            return
        }

        this.setState({ showCancelDialog: false, loading: true, errorMessage: undefined });

        try {
            const resp = await this.paymentService.cancelPayment(this.state.payment.id)
        } catch (error) {
            this.showError(`${error}`)
        }

        this.loadContent();
    }

    // loaderTest() {
    //     if (this.state.payment && this.state.payment.id){
    //         this.setState({ loading: true });

    //         window.setTimeout(() => {
    //             this.loadContent()
    //         }, 5000)
    //     }
    // }

    showError(message: string) {
        this.setState({errorMessage: message})
    }

    async approvePayment() {
        if (!this.state.payment || !this.state.payment.id) {
            return
        }

        this.setState({ showApproveDialog: false, showRetryWithdrawalDialog: false, loading: true, errorMessage: undefined })
        const resp = await this.paymentService.approvePayment(this.state.payment.id)
        await this.loadContent()

        if (resp.result != PaymentResultCode.OK) {
            const code = PaymentResultCode[resp.result ?? PaymentResultCode.ERROR]
            const msg = `[${code}] ${resp.message}`
            this.showError(msg)
        }
    }

    async failPayment() {
        if (!this.state.payment || !this.state.payment.id) {
            return
        }

        this.setState({ showFailDialog: false, loading: true, errorMessage: undefined })
        try {
            const resp = await this.paymentService.failPayment(this.state.payment.id)
        } catch (error) {
            this.showError(`${error}`)
        }

        this.loadContent()
    }

    async resolvePayment(refund: boolean) {
        if (!this.state.payment || !this.state.payment.id) {
            return
        }

        this.setState({ loading: true, errorMessage: undefined })
        if(this.isDeposit()) {
            const resp = await this.paymentService.resolveDeposit(this.state.payment.id, refund)
        } else {
            const resp = await this.paymentService.resolvePayment(this.state.payment.id, refund)

        }
        this.loadContent();
    }

    renderLog() {
        if (this.state.payment && this.state.payment.log){
            let rows = this.state.payment.log.map(p => {
                return (
                    <tr key={p.id}>
                        <td>{p.id}</td>
                        {p.timestamp && (
                            <td>{formatDate(Number(p.timestamp.toString().split(".").join("").substring(0,13)))}</td>
                        )}
                        <td>{p.type}</td>
                        <td>{p.status}</td>
                        <td>{p.error ? "YES" : "NO" }</td>
                        <td>{p.message}</td>
                        <td>{p.data  &&  <button className="btn btn-xs btn-primary" onClick={ () => this.setState({logData: p.data}) }>View Data</button>}</td>
                    </tr>
                );
            });

            return (
                <table className="table m-b-xs table-striped">
                    <thead>
                        <tr>
                            <th>{t(`dataTable.header.id`)}</th>
                            <th>{t(`dataTable.header.timestamp`)}</th>
                            <th>{t(`dataTable.header.eventType`)}</th>
                            <th>Payment Status</th>
                            <th>Is Error</th>
                            <th>Message</th>
                            <th>Data</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            );
        }
    }

    renderActions() {
        if (this.state.payment) {
            let p = this.state.payment;
            let isDeposit = p.type === "DEPOSIT";

            let canCancel = !isDeposit  &&  (p.status === "INITIATED"  ||  p.status === "PENDING");
            let canApprove = !isDeposit  &&  (p.status === "INITIATED");

            return (
                <div>
                    <FormGroup className="form-group">
                        <div>
                            { canCancel &&  <button className="btn btn-spacing btn-danger" onClick={ () => this.setState({ showCancelDialog: true }) }>Cancel Payment</button>}
                            { canApprove  &&  <button className="btn btn-spacing btn-primary" onClick={ () => this.setState({ showApproveDialog: true }) }>Approve Payment</button> }


                            {/* <button className="btn btn-spacing btn-danger" onClick={ () => this.loaderTest() }>Test</button> */}
                        </div>
                    </FormGroup>
                </div>
            );
        }
    }

    renderUserHistory() {
        let list = this.state.userPaymentList && this.state.userPaymentList.items;
        let firstWithdraw : boolean = false;
        let withdrawCount: number = 0;
        let madeDeposit : boolean = false;
        let kycCompleted : boolean = false;
        let lastTenTransactions : Payment[] = [];
        let rows:any;

        list && list.forEach((item:Payment) => {
            if(item.type == "DEPOSIT") {
                if(item.status == "COMPLETED")
                    madeDeposit = true;
            }
            if(item.type == "WITHDRAWAL") {
                if(item.status == "COMPLETED") {
                    withdrawCount++;
                }
            }
        });

        withdrawCount >= 1 ? firstWithdraw = true : firstWithdraw = false;

        if(this.state.userInfo && this.state.userInfo.kycVerified) {
            kycCompleted = true;
        }
        if(list) {
            let listMaxLength = list.length > 9 ? 9 : list.length;
            lastTenTransactions = list.slice(0,listMaxLength);
            rows = lastTenTransactions.map(p => {
                return (
                    <tr key={p.id}>
                        {p.created && (
                            <td>{formatDate(Number(p.created.toString().split(".").join("").substring(0,13)))}</td>
                        )}
                        <td>{p.type}</td>
                        <td>{p.amount}</td>
                        <td>{p.status}</td>
                    </tr>
                );
            });
        }

        return (
            <div>
                <FormGroup className="form-group">
                    <div>
                        <p>User {this.state.userInfo && this.state.userInfo.id} has done the following: </p>
                        <table className="table table-bordered" style={{"width":"50%"}}>
                            <tbody>
                                {this.state.payment && this.state.payment.type=="WITHDRAWAL" && (this.state.payment.status == "PENDING" || this.state.payment.status == "COMPLETED" || this.state.payment.status == "INITIATED") &&(
                                    <tr style={{"width":"50%"}}>
                                        <td style={{"width":"40%"}}>Previous completed withdraw?</td>
                                        <td style={{"width":"10%"}} className={firstWithdraw ? "table-success": "table-danger"}>{firstWithdraw ? "YES" : "NO"}</td>
                                    </tr>
                                )}
                                <tr>
                                    <td style={{"width":"40%"}}>Previous deposit?</td>
                                    <td style={{"width":"10%"}} className={madeDeposit ? "table-success": "table-danger"}>{madeDeposit ? "YES": "NO"}</td>
                                </tr>
                                <tr>
                                    <td style={{"width":"40%"}}>KYC Completed?</td>
                                    <td style={{"width":"10%"}} className={kycCompleted ? "table-success": "table-danger"}>{kycCompleted ? "YES": "NO"}</td>
                                </tr>
                                <tr>
                                    <td style={{"width":"40%"}}>Withdrawal Amount In Last 24H</td>
                                    <td style={{"width":"10%"}}>{this.state.totalWithdrawalEuro.toFixed(2)} (€)</td>
                                </tr>
                                <tr>
                                    <td style={{"width":"40%"}}>Delta Amount (Deposits - Withdrawals) In Last 24H</td>
                                    <td style={{"width":"10%"}} className={this.state.delta >= 0 ? "table-success": "table-danger"}>{this.state.delta.toFixed(2)} (€)</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>Last {lastTenTransactions.length} Payments:</p>
                        <table className="table m-b-xs table-striped">
                            <thead>
                            <tr>
                                <th>{t(`dataTable.header.timestamp`)}</th>
                                <th>{t(`dataTable.header.eventType`)}</th>
                                <th>{t(`dataTable.header.amount`)}</th>
                                <th>{t(`dataTable.header.paymentStatus`)}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {rows}
                            </tbody>
                        </table>
                    </div>
                </FormGroup>
            </div>
        );
    }

    renderResolveActions() {
        if (this.state.payment && this.state.payment.status){
            let p = this.state.payment;
            if (p.status != "FAILED_PERMANENTLY" && p.status != "FAILED") {
                return <div/>;
            }

            return (
                <div>
                    <FormGroup className="form-group">
                        <div>
                            {this.isDeposit() && (
                                <>
                                    { (this.state.payment?.amount  ?? 0) > 0 && <button className="btn btn-spacing btn-danger" onClick={ () => this.setState({ showResolveRefundDialog: true }) }>Resolve and Credit { this.state.payment.amount + " " + this.state.payment.currency}</button>}
                                    { <button className="btn btn-spacing btn-danger" onClick={ () => this.setState({ showResolveNoRefundDialog: true }) }>Resolve Only</button>}
                                </>
                            )}
                            {!this.isDeposit() && (
                                <>
                                    { <button className="btn btn-spacing btn-primary" onClick={ () => this.setState({ showRetryWithdrawalDialog: true }) }>Approve Payment (retry)</button>}
                                    { <button className="btn btn-spacing btn-danger" onClick={ () => this.setState({ showResolveRefundDialog: true }) }>Resolve and Refund</button>}
                                    { <button className="btn btn-spacing btn-danger" onClick={ () => this.setState({ showResolveNoRefundDialog: true }) }>Resolve Only</button>}
                                </>
                            )}

                        </div>
                    </FormGroup>
                    <div className="ibox-title">
                        <h5>Manual Resolve</h5>
                    </div>
                    <div className="ibox-content">
                        <p>
                            <strong>NOTE:</strong> Those actions should only be used if the payment is stuck.
                        </p>
                        {this.isDeposit() &&
                            <p>
                                Use <strong>Resolve and Credit</strong> to mark as manually resolved. The payment will not be retried and reserved funds
                                will be credited to the user balance
                            </p>
                        }
                        {!this.isDeposit() && (
                            <p>
                                Use <strong>Resolve and Refund</strong> to mark as manually resolved. The payment will not be retried and reserved funds
                                will be transfered back to the user balance
                            </p>
                        )}
                        <p>
                            Use <strong>Reslove Only</strong> to just mark the payment as resolved without refunding the player. This should rarely be used.
                        </p>

                    </div>
                </div>
            );
        }
    }


    renderTransactions() {
        const userId = this.state.payment!.userId

        const rows = this.state.transactions!.map(tx => {
            const userEntry = tx.entries?.find(e => e.accountUserId == userId)

            return (
                <tr key={userEntry?.id}>
                    <td>{tx.id}</td>
                    <td>{formatDate(tx.timestamp)}</td>
                    <td>{userEntry?.amount?.amount} {userEntry?.amount?.currencyCode}</td>
                    <td>{tx.comment}</td>
                    <td><Link to={`/transaction/${tx.id}`} className="btn btn-xs btn-primary">View Transaction</Link></td>
                </tr>
            )
        })


        return (
            <div>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>{t(`dataTable.header.id`)}</th>
                            <th>{t(`dataTable.header.timestamp`)}</th>
                            <th>Amount</th>
                            <th>Comment</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>
        )
    }

    renderCryptoTxLink(p:Payment) {
        let url = ""
        let txRef = p.cryptoTxReference ?? "";

        let currency = p.currency;
        if(currency === "USDT" || currency === "USDC") {
            let method = p.attributes?.["PAYMENT_METHOD"];
            if(method == "ETH_USDT" || method == "ETH_USDC") {
                currency="ETH"
            } else if(method == "USDT-TRX" || method == "USDC-TRX") {
                currency = "TRX";
            } else if(method == "USDC-BSC" || method == "USDT-BSC") {
                currency = "BNB";
            }
        }
        switch (currency) {
            case "BTC":
            case "mBTC":
            case "uBTC":
            case "SAT":
                url = `https://live.blockcypher.com/btc/tx/${txRef}`;
                break;

            case "DOGE":
                url = `https://live.blockcypher.com/doge/tx/${txRef}`;
                break;

            case "ETH":
            case "mETH":
                //txRef = txRef.replace(/^0x/, '')
                //url = `https://live.blockcypher.com/eth/tx/${txRef}`;
                url = `https://etherscan.io/tx/${txRef}`
                break;

            case "LTC":
            case "mLTC":
                url = `https://live.blockcypher.com/ltc/tx/${txRef}`;
                break;

            case "SOL":
                url = `https://explorer.solana.com/tx/${txRef}`;
                break;
            case "TRX":
                url = `https://tronscan.org/#/transaction/${txRef}`;
                break;
            case "BUSD":
            case "BNB":
                url = `https://bscscan.com/tx/${txRef}`;
                break;
            default:
          }

        return (
            p.cryptoTxReference && (
                <tr>
                    <td className="col-md-4 ">
                        <strong>Crypto TX:</strong>
                    </td>
                    <td className="col-md-8" style={{wordBreak:"break-all"}}>
                        {url &&  <a target="_blank" href={url}>{p.cryptoTxReference}</a>}
                        {!url &&  p.cryptoTxReference}
                    </td>
                </tr>
            )
        )
    }
    isDeposit() {
        return this.state.payment?.type == PaymentType.DEPOSIT;
    }

    renderContent() {
        if (!this.state.payment) {
            return <LoadingSpinner/>;
        }

        let p = this.state.payment;
        let attributesJson = "";
        if(p.attributes) {
            try {
                attributesJson = JSON.stringify(p.attributes, null, 2)
            }catch (e) {
                console.log("Unable to parse attributes json",e);
            }
        }
        let amount = `${p.amount} ${p.currency}`;

        let userLink = p.userId ? <Link to={"/user/" + p.userId}>{p.userId}</Link> : <div>n/a</div>;

        return (
            <Fragment>
                <div>
                    <ConfirmModal
                        show={this.state.showCancelDialog}
                        heading="Cancel Payment"
                        description={ `Payment request of ${amount} will be canceled and the player will be refunded.` }
                        okLabel="Cancel Payment"
                        okClassName="btn-danger"
                        onOk={ this.cancelPayment.bind(this) }
                        onCancel={ () => this.setState({ showCancelDialog: false })}
                    />

                    <ConfirmModal
                        show={this.state.showApproveDialog}
                        heading="Approve Payment"
                        description={ `Approve and perform payment of ${amount}` }
                        okLabel="Approve Payment"
                        okClassName="btn-primary"
                        onOk={ this.approvePayment.bind(this) }
                        onCancel={ () => this.setState({ showApproveDialog: false })}
                    />

                    <ConfirmModal
                        show={this.state.showRetryWithdrawalDialog}
                        heading="Retry Approve Payment"
                        description={
                            <div className="retry-withdrawal">
                                <p>Please confirm that this transaction of {amount} has not been processed yet. If it hasn't, approve and perform the payment.</p>
                                <p>Are you certain you want to retry this payment?</p>
                            </div>
                        }
                        okLabel="Retry Approve Payment"
                        okClassName="btn-primary"
                        onOk={ this.approvePayment.bind(this) }
                        onCancel={ () => this.setState({ showRetryWithdrawalDialog: false })}
                    />

                    <ConfirmModal
                        show={this.state.showResolveRefundDialog}
                        heading={this.isDeposit() ? "Resolve and Credit Player" : "Resolve and Refund"}
                        description={ `Mark as resolved and ${this.isDeposit() ? "credit" : "refund"} player: ${amount}` }
                        okLabel={this.isDeposit() ? "Resolve and Credit Player" : "Resolve and Refund"}
                        okClassName="btn-primary"
                        onOk={ this.resolvePayment.bind(this, true) }
                        onCancel={ () => this.setState({ showResolveRefundDialog: false }) }
                    />

                    <ConfirmModal
                        show={this.state.showResolveNoRefundDialog}
                        heading="Resolve Without Refund"
                        description={ `Mark as resolved but don't refund player. This must be done manually in this case.` }
                        okLabel="Resolve"
                        okClassName="btn-primary"
                        onOk={ this.resolvePayment.bind(this, false) }
                        onCancel={ () => this.setState({ showResolveNoRefundDialog: false }) }
                    />

                    <DataModal
                        show={this.state.logData != ""}
                        heading="Log Data"
                        onClose={() => this.setState({ logData: "" }) }
                        data={this.state.logData}
                    />

                    {/* <div className="row wrapper border-bottom white-bg page-heading">
                        <div className="col-lg-12">
                            <h1>{this.getTitle()}</h1>
                        </div>
                    </div> */}
                    <div className="wrapper wrapper-content animated">
                            { this.state.errorMessage  &&
                                <div className="row">
                                    <div className="col-lg-12">
                                        <Alert type="danger" text={`${this.state.errorMessage}`}/>
                                    </div>
                                </div>
                            }

                            <div className="row">
                                <div className="col-lg-6">
                                    <div className={"card"}>
                                        <div className="card-header">
                                            <h4>{p.type} <strong>{amount}</strong> <br/>
                                            {p.amountEuro && (' (€'+p.amountEuro+')')}
                                            </h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="ibox-content">
                                                <table className="table m-b-xs table-striped">
                                                    <tbody>
                                                    <tr><td className="col-md-4"><strong>ID:</strong></td><td className="col-md-8">{p.id}</td></tr>
                                                    <tr>
                                                        <td className="col-md-4"><strong>User:</strong></td>
                                                        <td className="col-md-8">{userLink}</td>
                                                    </tr>
                                                    <tr><td className="col-md-4"><strong>Provider:</strong></td><td className="col-md-8">{p.provider}</td></tr>
                                                    <tr><td className="col-md-4"><strong>Finalized:</strong></td><td className="col-md-8">
                                                        {p.finalized ? <span className="text-success">True</span> : <span className="text-danger">False</span>}
                                                        {p.finalized === false && p.id && <DeleteButtonModal  className={"btn btn-secondary margin-left"} label={"Set to finalized"} onDelete={
                                                            ()=> getContainer().getPaymentService().finalizePayment(p.id!).then(()=> this.loadContent())} />}
                                                    </td>
                                                    </tr>
                                                    <tr><td className="col-md-4"><strong>External Id (provider):</strong></td><td className="col-md-8">{p.externalPaymentId}</td></tr>
                                                    <tr><td className="col-md-4"><strong>Amount:</strong></td><td className="col-md-8">{`${p.amount} ${p.currency}`}</td></tr>
                                                    <tr><td className="col-md-4"><strong>Amount Euro:</strong></td><td className="col-md-8">{p.amountEuro ? '€'+p.amountEuro : ""}</td></tr>
                                                    <tr><td className="col-md-4"><strong>UID:</strong></td><td className="col-md-8">{p.uid}</td></tr>
                                                    {p.notes && <tr><td className="col-md-4"><strong>Notes (memo):</strong></td><td className="col-md-8">{p.notes}</td></tr>}

                                                    {p.created && (
                                                        <tr><td className="col-md-4"><strong>Created:</strong></td><td className="col-md-8">{formatDate(Number(p.created.toString().split(".").join("").substring(0,13)))}</td></tr>
                                                    )}
                                                    {p.updated && (
                                                        <tr><td className="col-md-4"><strong>Updated:</strong></td><td className="col-md-8">{formatDate(Number(p.updated.toString().split(".").join("").substring(0,13)))}</td></tr>
                                                    )}
                                                    <tr><td className="col-md-4"><strong>Status:</strong></td><td className="col-md-8">{p.status}</td></tr>
                                                    <tr><td className="col-md-4"><strong>Transaction ID:</strong></td><td className="col-md-8">{p.paymentTxId}</td></tr>
                                                    {p.paymentReverseTxId && (
                                                        <tr><td className="col-md-4"><strong>Reverse Transaction ID:</strong></td><td className="col-md-8">{p.paymentReverseTxId}</td></tr>
                                                    )}
                                                    {p.address && (
                                                        <tr><td className="col-md-4 "><strong>Address:</strong></td><td className="col-md-8" style={{wordBreak:"break-all"}}>{p.address}</td></tr>
                                                    )}
                                                    {this.renderCryptoTxLink(p)}

                                                    <tr>
                                                        <td colSpan={2} className="col-md-12">
                                                            <strong>Attributes:</strong>
                                                            <div style={{width : "100%"}}>
                                                                <textarea disabled={true} rows={attributesJson.split(/\r\n|\r|\n/).length} style={{ fontSize : "11px", fontFamily : 'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace', border: "none", background: "transparent", width : "100%"}}  defaultValue={attributesJson}></textarea>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-lg-6"}>
                                    <div className={"row"}>
                                        <div className={"col-lg-12"}>
                                            <AdminNotes type={"PAYMENT"} typeId={""+this.state.payment.id} title={"Payment Notes"}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card-box">
                                        <div className="ibox-title">
                                            <h5>Event Log</h5>
                                        </div>
                                        <div className="ibox-content">
                                            {this.renderLog()}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card-box">
                                        <div className="ibox-title">
                                            <h5>Payment Transactions</h5>
                                        </div>
                                        <div className="ibox-content">
                                            {this.renderTransactions()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className={"card"}>
                                    <div className="card-header">
                                        <h4>History</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="ibox-content">
                                            {this.renderUserHistory()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className={"card"}>
                                    <div className="card-header">
                                        <h4>Actions</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="ibox-content">
                                            {this.renderActions()}
                                        </div>
                                        {this.renderResolveActions()}
                                    </div>

                                </div>
                            </div>
                        </div>
                            {/*<div className="row">*/}
                            {/*    <div className="col-lg-12">*/}
                            {/*        <div className="card-box">*/}
                            {/*            <div className="ibox-title">*/}
                            {/*                <h5>Payment Adjustment</h5>*/}
                            {/*            </div>*/}
                            {/*            <div className="ibox-content">*/}
                            {/*                <p>Make a transaction to/from the user with a reference to this payment.</p>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default withRouter(PaymentView);
