import "reflect-metadata";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {wireApplication} from "./ioc/IOCSetup";
import {setupI18n} from "./i18n";
import axios from "axios";
import {AdminConfig} from "./http/Types";
import {fetchLogoUrl} from "./user/UserActions";
import { fetchCurrencies } from "./wallet/WalletActions";
import { createRoot } from "react-dom/client";
//import './assets/custom-icons/custom/nano-icons.css'

(async function() {

    let config:any;
    try {
        config = await getConfig()

    } catch (e) {
        let hostname = document.location.hostname;
        console.log("Hostname "+hostname);
        if(hostname.startsWith("10.0") || hostname.startsWith("192.168")) {
            config = { baseUrl : "http://" + hostname+":9081/rest"};
        } if(hostname == "nano") {
            config = { baseUrl : "https://admin.neobet.io/rest", cubeiaDashboardBaseUrl: "http://localhost"}
        } else {
            console.log("URL = ");
            config = { baseUrl :  "http://localhost:8080/rest", cubeiaDashboardBaseUrl: "http://localhost"}
            // config = { baseUrl : "https://admin.neobet.io/rest", cubeiaDashboardBaseUrl: "http://localhost"}
        }
    }
    await setupI18n();
    wireApplication(config);
    const root = createRoot(document.getElementById('root') as HTMLElement)
    root.render(<App />);

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();


    await fetchCurrencies()



})();


export async function getConfig() : Promise<AdminConfig> {
    let http =axios.create({
        headers : {
            "Content-type" : "application/json"
        }
    });
    return http.get("/config/config.json").then( r => r.data);

}