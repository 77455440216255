import {MetabaseDashboard} from "../ui/report/MetabaseDashboardListView";
import {HttpService} from "../http/HttpService";
import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {PermissionCategory} from "../http/protocol";

export const MENU_SERVICE_NAME = "menuService";



export interface MenuRouteGroup {
    id?: string;
    name?: string;
    routes?: MenuRoute[];
    hideTitle?: boolean;
    icon?: string;
}

export interface MenuRoute {
    path?: string,
    label: string,
    icon?: string;
    displayInMenu?: boolean;
    children?: MenuRoute[];
    writeAccess?: boolean;
    permissions?: PermissionCategory[];
    externalLink?: boolean;
}

export interface RequiresPermission {
    category: string;
    requiresWriteAccess: boolean;
}

function intersects(arrA: string[], arrB: string[]): boolean {
    if (arrA == null || arrB == null) {
        return false;
    }
    return arrA.some(a => {
        return typeof (arrB.find(b => b == a)) != "undefined";
    });
}

let filterRoutes = function (routes?: MenuRoute[], permissions?: { [p: string]: boolean }): any {
    if (!routes) {
        return null;
    }
    
    return routes!.filter((route: MenuRoute) => {
        var permitted = hasPermission(route.permissions, route.writeAccess, permissions);
        return permitted;
    }).map(route => {
        return Object.assign({}, route, {children: filterRoutes(route.children, permissions)});
    });
};


export class MenuService extends AbstractAdminEntityService<any, any> {
    routes: Array<MenuRouteGroup> = [];

    addGroup(name: string, hideTitle: boolean, icon: string, routes: MenuRoute[]) {
        this.routes.push({
            name: name,
            id: name,
            hideTitle: hideTitle,
            routes: routes,
            icon: icon
        });
    }

    constructor(http: HttpService) {
        super("", http);

    }

    async getMetabaseDashboardList(): Promise<MetabaseDashboard[]> {
        return this.http.get('/metabase/dashboards')
    }

    async getMetabaseCardsList(): Promise<MetabaseDashboard[]> {
        return this.http.get('/metabase/cards')
    }

    async getMenuRoutes(permissions: { [key: string]: boolean }): Promise<MenuRouteGroup[]> {
        var routes = this.getRoutes(permissions);
        return routes;
    }

    isDisplayInMenu(route: any): boolean {
        return typeof (route.displayInMenu) == "undefined" || route.displayInMenu === true
    }

    async getRoutes(permissions: { [key: string]: boolean }): Promise<MenuRouteGroup[]> {
        let metabaseDashboardPaths: any = [];
        let metabaseReportsPaths: any = [];
        try {
            await this.getMetabaseDashboardList().then((dashboard: any) => {
                if (dashboard.length > 0) {
                    dashboard.map((dboard: MetabaseDashboard) => {
                        metabaseDashboardPaths.push({path: `/metabase/dashboard/${dboard.id}`, label: dboard.name})
                    })
                }
            });
        } catch (e) {
            console.log("Failed to load dashboards from metabase.")
        }
        try {
            await this.getMetabaseCardsList().then((cards: any) => {
                if (cards.length > 0) {
                    cards.map((card: any) => {
                        metabaseReportsPaths.push({path: `/metabase/report/${card.id}`, label: card.name})
                    })
                }
            });
        } catch (e) {
            console.log("Failed to load dashboards from metabase.")
        }

        this.routes = [];
        this.addGroup("Dashboards", true, "shop-4", [
            {path: '/', icon: "airplay", label: "Home"}
        ]);
        this.addGroup("Integrations", true, "shop-4", [
            {path: '/integrations', icon: "grid", permissions: [PermissionCategory.SETTINGS], label: "Integrations"}
        ]);

        this.addGroup("User Management", false, "air-balloon-1", [
            {
                icon: "user", label: "Users",
                permissions: [PermissionCategory.USERS],
                children: [
                    {path: "/users", label: "List Users"}
                ]
            },
            {
                icon: "tag", label: "User Attributes",
                permissions: [PermissionCategory.USER_ATTRIBUTE_CONFIG],
                children: [
                    {path: "/user-attributes", label: "List Attributes"},
                    {path: "/user-attribute/new", label: "Create Attribute"}
                ]
            },
            {
                icon: "tag", label: "User Journey",
                permissions: [PermissionCategory.USER_JOURNEY],
                children: [
                    {path: "/user-journeys", label: "List Journeys"},
                    {path: "/user-journey/new", label: "Create Journey"},
                    {path: "/user-risk-rules", label: "User Registration Rules"}
                ]
            },
            {
                icon: "message-circle", label: "Push Notification",
                permissions: [PermissionCategory.NOTIFICATIONS],
                children: [
                    {path: "/notifications", label: "Send Notification"},
                ]
            }
        ]);


        this.addGroup("Bonus", false, "shop-4", [
            {
                icon: "grid", label: "User Bonus Offers",
                permissions: [PermissionCategory.BONUS],
                children: [
                    {path: '/bonus/offers/list', label: "List Offers"},

                ]
            },
            {
                icon: "grid", label: "Bonus Configs",
                permissions: [PermissionCategory.BONUS],
                children: [
                    {path: '/bonus/configs/list', label: "List"},
                    {path: '/bonus/config/new', label: "Create"},

                ]
            },
            {
                icon: "grid", label: "Contributions",
                permissions: [PermissionCategory.BONUS],
                children: [
                    {path: '/bonus/turnover-contribution/list', label: "List"},
                    {path: '/bonus/turnover-contributions/new', label: "Create"},

                ]
            },
            {
                icon: "grid", label: "Monetary Config",
                permissions: [PermissionCategory.BONUS],
                children: [
                    {path: '/bonus/monetary-configs/list', label: "List"},
                    {path: '/bonus/monetary-config/new', label: "Create"},

                ]
            },
            {
                icon: "grid", label: "Triggers",
                permissions: [PermissionCategory.BONUS],
                children: [
                    {path: '/bonus/offer-rules/list', label: "List"},
                    {path: '/bonus/offer-rule/new', label: "Create"},
                ]
            },
            {
                icon: "grid", label: "Cashback Configs",
                permissions: [PermissionCategory.BONUS],
                children: [
                    {path: '/cashback/configs/list', label: "List"},
                    {path: '/cashback/config/new', label: "Create"},
                    {path: '/cashback/casino/exclusions', label: "Exclusions"},
                ]
            },
            {
                icon: "grid", label: "Cashbacks",
                permissions: [PermissionCategory.BONUS],
                children: [
                    {path: '/cashback/log/list', label: "Logs"},
                    {path: '/cashback/single-currency-payout/list', label: "Single Currency Payouts"},
                    {path: '/cashback/payout/list', label: "Payouts Per Currency"}

                ]
            },
        ]);

        this.addGroup("Rewards", false, "shop-4", [
            {
                icon: "grid", label: "Template Config",
                permissions: [PermissionCategory.BONUS],
                children: [
                    {path: '/rewards/configs/list', label: "List"},
                    {path: '/rewards/config/new', label: "Create"},

                ]
            },
            {
                icon: "grid", label: "User Rewards",
                permissions: [PermissionCategory.BONUS],
                children: [
                    {path: '/user-rewards/list', label: "List"},
                    {path: '/user-rewards/assign', label: "Award reward"}

                ]
            }
        ]);

        this.addGroup("Analytics", false, "shop-4", [
            {path: '/analytics/amplitude', icon: "bar-chart-2", label: "Amplitude", externalLink: true, permissions: [PermissionCategory.ANALYTICS]}
        ]);

        this.addGroup("Reports", false, "shop-4", [
            {path:'/metabase/reports', icon: "database", label: "Reports", permissions: [PermissionCategory.REPORTS]},
            {icon: "monitor", label: "Dashboards", permissions: [PermissionCategory.REPORTS], children: metabaseDashboardPaths},
            {path: '/report/manage', icon: "sliders", label: "Manage", externalLink: true, permissions: [PermissionCategory.REPORTS]},
        ]);

        this.addGroup("Charts", false, "air-balloon-1", [
            {
                icon: "bar-chart-2", label: "Admin Charts",
                permissions: [PermissionCategory.ANALYTICS],
                children: [
                    {path: "/admin-charts", label: "List Charts"},
                    {path: "/admin-chart/new", label: "Add New Chart"}
                ]
            },
        ]);
        this.addGroup("Payments", false, "air-balloon-1", [
            {
                icon: "dollar-sign", label: "Payments",
                permissions: [PermissionCategory.PAYMENTS],
                children: [
                    {path: "/payments/list", label: "List Payments"}
                ]
            },
            {
                icon: "dollar-sign", label: "Vouchers",
                permissions: [PermissionCategory.VOUCHERS],
                children: [
                    {path: "/vouchers/list", label: "List Vouchers"},
                    {path: '/voucher/create', label: "Create"}
                ]
            }
        ]);
        this.addGroup("Casino", false, "air-balloon-1", [
            {
                icon: "list", label: "Casino Lobby",
                permissions: [PermissionCategory.CASINO],
                children: [
                    {path: "/casino-pages", label: "List Pages"},
                    {path: "/casino-page/new", label: "Create Page"},
                    {path: "/casino-categories", label: "List Categories"},
                    {path: "/casino-category/new", label: "Create Category"},
                    {path: "/casino-lobby", label: "Lobby"},
                    {path: "/casino-jackpots", label : "Jackpots"}
                ]
            },
            {
                icon: "tv", label: "Casino Games",
                permissions: [PermissionCategory.CASINO],
                children: [
                    {path: "/casino-games", label: "List Games"}
                ]
            },
            {
                icon: "video", label: "Casino Studios",
                permissions: [PermissionCategory.CASINO],
                children: [
                    {path: "/casino-studios", label: "List Studios"}
                ]
            },
            {
                icon: "tag", label: "Game Tags",
                permissions: [PermissionCategory.CASINO],
                children: [
                    {path: "/casino-game-tags", label: "List Tags"},
                    {path: "/casino-game-tag/new", label: "Create Tag"}
                ]
            },
            {
                icon: "list", label: "Integration Updates",
                permissions: [PermissionCategory.CASINO],
                children: [
                    {path: "/casino-update-log/list", label: "List Updates"}
                ]
            }
        ]);


        this.addGroup("Sportsbook", false, "", [
            {
                icon: "award", label: "Sportsbook Bets",
                permissions: [PermissionCategory.SPORTSBOOK],
                children: [
                    {path: '/sportsbook/list', label: "Bets"},
                    {path: '/sportsbook/freebets/list', label: "Free Bets"},

                ]
            }
        ]);


        this.addGroup("Affiliate Management", false, "air-balloon-1", [
            {
                icon: "tag", label: "Affiliates",
                permissions: [PermissionCategory.AFFILIATES],
                children: [
                    {path: "/affiliates", label: "List"},
                    {path: "/affiliates/settings", label: "Settings"}
                ]
            },
            {
                icon: "list", label: "Affiliate Reports",
                permissions: [PermissionCategory.AFFILIATES],
                children: [
                    // {path: "/affiliates/reports/revenue", label: "Revenue"},
                    {path: "/affiliates/reports/payouts", label: "Payouts"},
                ]
            }
        ]);

        this.addGroup("Store", false, "shopping-cart", [
            {
                icon: "shopping-cart", label: "Bundles",
                permissions: [PermissionCategory.PAYMENTS],
                children: [
                    {path: "/bundles", label: "List Bundles"},
                    {path: "/bundles/new", label: "Create Bundle"}
                ]
            },
        ]);

        this.addGroup("Translations", false, "shopping-cart", [
            {
                icon: "file-text", label: "Translations",
                permissions: [PermissionCategory.TRANSLATIONS],
                children: [
                    {path: "/translation", label: "List translations"}
                ]
            },
        ]);

        this.addGroup("Accounting", false, "air-balloon-1", [
            {
                icon: "clipboard", label: "Accounts",
                permissions: [PermissionCategory.WALLET],
                children: [
                    {path: "/accounts", label: "Account List"},
                    {path: "/transactions", label: "Transaction List", permissions : [PermissionCategory.TRANSACTIONS]},
                    {path: "/account/edit/new", label: "Create"}
                ]
            },
            // { icon : "list", label : "Transactions",
            //     children : [
            //         { path : "/transactions/list",  label : "List"},
            //         { path : "/transactions/create",  label : "Create"}
            //     ]
            // },
            {
                icon: "dollar-sign", label: "Currencies",
                permissions: [PermissionCategory.WALLET],
                children: [
                    {path: "/currencies/list", label: "List"},
                    {path: "/currencies/edit/new", label: "Create"}
                ]
            },
            {
                icon: "dollar-sign", label: "Batch Transactions",
                permissions: [PermissionCategory.BATCH_WALLET],
                children: [
                    {path: "/create-batch", label: "Show & Create Batches"}
                ]
            },

        ]);

        this.addGroup("Finance", false, "air-balloon-1", [
            {
                icon: "layers", label: "Reports",
                permissions: [PermissionCategory.FINANCE],
                children: [
                    {path: "/finance", label: "Provider Reports"},
                ]
            }
        ]);

        this.addGroup("Audit", false, "air-balloon-1", [
            {
                icon: "clipboard", label: "Audit Logs",
                permissions: [PermissionCategory.AUDIT],
                children: [
                    {path: "/audit-logs", label: "List"},
                ]
            },
            {
                icon: "clipboard", label: "System Events",
                permissions: [PermissionCategory.AUDIT],
                children: [
                    {path: "/system-events", label: "List"},
                ]
            }
        ]);

        this.addGroup("Admin", false, "air-balloon-1", [
            {
                icon: "user", label: "Admin Users",
                permissions: [PermissionCategory.ADMIN_USERS],
                children: [
                    {path: "/admin/admin-users", label: "List Admins"},
                    {path: "/admin/admin-user/new", label: "Create Admin"}
                ]
            },
            {
                icon: "users", label: "User Groups",
                permissions: [PermissionCategory.ADMIN_USER_GROUPS],
                children: [
                    {path: "/admin/user-groups", label: "List Groups"},
                    {path: "/admin/user-group/new", label: "Create Group"}
                ]
            },
            {
                icon: "settings", label: "Settings",
                permissions: [PermissionCategory.SETTINGS, PermissionCategory.ADMIN_INSTANT_WITHDRAWALS],
                children: [
                    {path: "/admin/settings/list", label: "General"},
                    {path: "/admin/sites", label: "Site"},
                    {path: "/admin/settings/menus", label: "Menu Settings"},
                    {path: "/admin/service-level/configuration", label: "Service Enablement", permissions: [PermissionCategory.SERVICE_ENABLEMENT]},
                    {path: "/admin/settings/integrations/list", label: "Integrations"},
                    {path: "/admin/settings/user", label: "User"},
                    {path: "/admin/payments/instant-withdrawals", label: "Instant Withdrawals"},
                    {path: "/settings/iprules", label: "IP Rules"},
                    {path: "/admin/settings/internal", label: "Internal"},
                    {path: "/admin/settings/pwa", label: "PWA"},
                    {path: "/admin/settings/notifications", label: "Push Notifications"},
                    {path: "/admin/settings/spam-detector", label: "Spam Detector Settings"},
                    {path: "/admin/settings/advanced", label: "Advanced"},
                    {path: "/maintenance", label: "Maintenance", permissions: [PermissionCategory.MAINTENANCE]},
                    {path: "/security-settings", label: "Admin Security", permissions: [PermissionCategory.ADMIN_SECURITY]},
                    {path: "/widgets", label : "Script Widgets", permissions : [PermissionCategory.WIDGETS]}
                ]
            }
        ]);
        this.addGroup("Guides", false, "air-balloon-1", [
            {
                icon: "image", label: "Styling",
                permissions: [PermissionCategory.SETTINGS],
                children: [
                    {path: "/guides/styling", label: "CSS Styling Guide"},
                    {path: "/guides/cms", label: "Content/CMS"}
                ]
            }
        ]);


        try {
        } catch (e) {

        }
        return this.routes.map( (g : MenuRouteGroup) => {
            let routes = filterRoutes(g.routes, permissions)
            return Object.assign({}, g, { routes : routes});
        }).filter( (g : MenuRouteGroup) => g.routes && g.routes.length  > 0);
    }

}


function hasPermission(categories?: PermissionCategory[], requiresWriteAccess?: boolean, permissions?: { [key: string]: boolean }) {
    if (!categories || categories.length == 0) {
        return true;
    }
    if (!permissions) {
        return false;
    }
    if (typeof (requiresWriteAccess) == "undefined") {
        requiresWriteAccess = false;
    }

    /*
    if(!intersects(categories, AppCtx.getEnabledPermissionCategories())) {
        return false;
    }*/


    return categories.some(cat => {
        var writeAccess = permissions[cat];
        if (typeof (writeAccess) != "undefined") {
            if (writeAccess == true && requiresWriteAccess == true) {
                return true;
            } else if (requiresWriteAccess == false) {
                return true;
            }
        }
        return false;
    });
}
