import React, {Fragment} from "react";
import {ListRewardTemplateRequest, ListUsersRequest} from "../../http/protocol";
import {createInitialState, ListView, ListViewProps, ListViewState} from "../ListView";
import {FormListener} from "../form/Form";
import {ColumnType} from "../list/list/ColumnType";
import {PagerModel} from "../list/list/PagerModel";
import {ListUtils} from "../list/list/ListUtils";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {ActionType} from "../list/list/DataTable";
import {withRouter} from "react-router";
import {getContainer} from "../../ioc/IOCSetup";
import {RewardsConfigService} from "../../services/RewardsConfigService";

interface State extends ListViewState<ListRewardTemplateRequest> {
    listRequest?: ListRewardTemplateRequest;
    form?: ListRewardTemplateRequest;
    validationMap?: any;
}

interface Props extends ListViewProps{
}


export class RewardTemplateListView extends ListView<State, ListRewardTemplateRequest> implements FormListener<ListRewardTemplateRequest> {

    columns = [
        { property : "id", name : "Id", orderBy : "id"},
        { property : "description", name : "Description" },
        { property : "created", name : "Created", type : ColumnType.DATE},
        { property : "rewardType", name : "Type" },
    ];


    service : RewardsConfigService;

    constructor(props : Props){
        super(props, "/rewards/configs/list");
        this.service = getContainer().getRewardsConfigService();

        if (this.state.listRequest){
            let initialForm = {
                limit: 20,
                orderBy: "id",
                ascending: false
            };
            this.state = createInitialState(
                initialForm,
                this.state = {...this.state});
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.service.list({}).then((res) => {
            this.setState({})
        })
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    formDidUpdate(formModel: ListUsersRequest, validationMap : any): void {
        this.setState({ form : formModel, validationMap : validationMap});
    }

    onSubmitError(): void {

    };

    onSubmit(): void {
        if (this.state.form){
            let listRequest = this.state.form;
            listRequest.offset = 0;
            listRequest.orderBy = "id";
            this.updateListState(listRequest);
        }
    };

    onStatusesChanged(statuses: string[]) {
        let newReq = Object.assign({}, this.state.listRequest, { statuses: statuses });
        this.setState({ listRequest: newReq });
    }

    pagerChanged(pager : PagerModel) {
        if (this.state.listRequest) {
            var listRequest = ListUtils.toListRequest(this.state.listRequest, pager);
            this.setState({listRequest : listRequest});
        }
    }


    getTitle() {
        return "Reward Templates";
    }

    renderContent() {
        if (!this.state.listRequest) {
            return <div />;
        }
        return (
            <Fragment>
                <div>
                    <div className="wrapper wrapper-content">

                        <EntityDataTable service={this.service}
                                         onPagerChange={this.pagerChanged.bind(this)}
                                         columns={this.columns}
                                         listRequest={this.state.listRequest}
                                         onAction={() => {
                                             return new Promise<any>(() => console.log(""));
                                         }}
                                         actions={
                                             [
                                                 { id: "details", name: "Details", className: "btn btn-primary", type: ActionType.LINK, getLink: (item: any) => {
                                                         localStorage.setItem("paymentListForm", JSON.stringify(this.state.listRequest));
                                                         return "/rewards/config/" + item.id
                                                     } },
                                             ]
                                         }/>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default withRouter(RewardTemplateListView);