import React, {Fragment} from "react";
import {ActionType} from "../list/list/DataTable";
import {BonusConfig, BonusOffer, BonusOfferListRequest, BonusOfferStatus, ListRequest, ListSportsbookBetsRequest, SportsbookBet, SportsbookOddsType} from "../../http/protocol";
import {getContainer} from "../../ioc/IOCSetup";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {PagerModel} from "../list/list/PagerModel";
import {ListUtils} from "../list/list/ListUtils";
import {withRouter} from "react-router";
import {ListView, ListViewProps, ListViewState} from "../ListView";
import {Form, FormListener} from "../form/Form";
import {FormGroup} from "../form/FormGroup";
import {Submit} from "../form/Submit";
import {ColumnType} from "../list/list/ColumnType";
import {SelectInput} from "../form/SelectInput";
import {BonusOfferService} from "../../services/BonusOfferService";
import {FormInput} from "../form/FormInput";
import {Validators} from "../form/Validators";
import {t} from "../../i18n";
import { SportsbookService } from "../../services/SportsbookService";
import { Collapsible } from "../Collapsible";



interface State extends ListViewState<ListSportsbookBetsRequest> {
    form? : ListSportsbookBetsRequest;
    validationMap? : any;
}
interface Props extends ListViewProps  {

}

const oddsRenderer = (item: SportsbookBet) => {
    if (!item.odds == null  ||  !item.odds?.odds) {
        return '';
    } else if (item.odds.type == SportsbookOddsType.DECIMAL ||  !item.oddsDecimal) {
        return `${item.odds.odds.toFixed(2)} / ${item.odds.type}`;
    } else {
        return `${item.odds.odds.toFixed(2)} / ${item.odds.type} (${item.oddsDecimal.toFixed(2)})`
    }

}

export class SportsbookBetListView extends ListView<State, ListSportsbookBetsRequest> implements FormListener<ListSportsbookBetsRequest>{

    columns = [
        { property : "id", name : "ID", orderBy : "id"},
        { property : "created", name : "Created", type: ColumnType.DATE },
        { property : "userId", name : "User Id", type : ColumnType.LINK, getUrl : (item : BonusOffer) => "/user/"+item.userId},
        { property : "provider", name : "Provider" },
        { property : "stake", name : "Stake", type: ColumnType.COMPONENT, component: (item: SportsbookBet) => `${item.currency} ${item.stake}`},
        { property : "type", name : "Type" },
        { property : "odds", name : "Odds", type: ColumnType.COMPONENT, component: oddsRenderer},
        { property : "live", name : "Live", type: ColumnType.BOOLEAN },
        { property : "externalId", name : "External Id" },
        { property : "status", name : "Status" },
        { property : "result", name : "Result" },
        // { property:  "bonusConfig.id" , name : "Bonus Config Id", type:  ColumnType.LINK, getUrl : (item : BonusOffer) => { return item.bonusConfig ? "/bonus/config/"+item.id : false}},
        // { property:  "bonusConfig.name" , name : "Bonus Name"},
        // { property: "expires", name : "Expires", type : ColumnType.DATE},
        // { property: "status", name : "Status"}
    ];

    actions = [
        // { id : "delete", name : "delete", className : "btn btn-danger", iconClassName : "fa fa-times", type : ActionType.CONFIRMATION},
        { id : "view", name : "View", className : "btn btn-primary", iconClassName : "fa fa-user", type : ActionType.LINK, getLink : (item : any) => "/sportsbook/bet/" + item.id }

    ];

    service : SportsbookService;

    constructor(props : Props){
        super(props, "/sportsbook/list");
        this.state = {...this.state, validationMap : {}, form : {...this.state.listRequest} as any};
        this.service = getContainer().getSportsbookService();
    }

    onSubmit() : void | Promise<any> {
        if(this.state.form) {
            let form = this.state.form;
            form.offset = 0;

            if (form.betId + "" == "") {
                delete form.betId
            }

            if (form.userId + "" == "") {
                delete form.userId
            }
            if (form.externalId + "" == "") {
                delete form.externalId
            }

            this.updateListState(form);
        }
    }
    
    onSubmitError() :  void {

    }

    formDidUpdate(formModel: ListRequest, validationMap?: any): void {
        this.setState({form : formModel, validationMap : validationMap});
    }

    onAction(item : BonusConfig, action : string) : Promise<any> | undefined {
        if(action == "delete") {
            return  this.service.remove(""+item.id);
        }
    }

    pagerChanged(pager : PagerModel) {
        if(this.state.listRequest) {
            console.log("pager changed!", pager);
            let listRequest = ListUtils.toListRequest<any>(this.state.listRequest,pager);
            this.updateListState(listRequest);
        }
    }
    getTitle() {
        return t(`listSportsbookBets.pageTitle`);
    }

    renderContent() {
        if(!this.state.listRequest) {
            return <div/>;
        }
        return (
            <Fragment>
                <Collapsible title={t(`title.searchFilters`)} collapsed={true}>
                    <Form formListener={this} modelObject={this.state.form} validationMap={this.state.validationMap}>
                        <FormGroup className={"form-group"}>
                            <label>{t(`label.userId`)}</label>
                            <FormInput model={"userId"} className={"form-control"} type={"integer"} validators={[Validators.integer()]}/>
                        </FormGroup>
                        <FormGroup className={"form-group"}>
                            <label>{t(`label.externalId`)}</label>
                            <FormInput model={"externalId"} className={"form-control"} type={"string"} validators={[]}/>
                        </FormGroup>
                        <FormGroup className={"form-group"}>
                            <label>{t(`label.betId`)}</label>
                            <FormInput model={"betId"} className={"form-control"} type={"integer"} validators={[Validators.integer()]}/>
                        </FormGroup>


                        {/* <FormGroup className={"form-group"}>
                            <label>{t(`label.status`)}</label>
                            <SelectInput optional={true} model={"status"} className={"form-control"} values={Object.keys(BonusOfferStatus)}/>
                            <span className={"help-text"}>{t(`helpText.searchBonusWithStatus`)}</span>
                        </FormGroup> */}
                        <Submit className={"btn btn-primary"}>{t(`button.search`)}</Submit>
                    </Form>
                </Collapsible>
                
                <EntityDataTable service={this.service}
                                 onPagerChange={this.pagerChanged.bind(this)}
                                 columns={this.columns}
                                 listRequest={this.state.listRequest}
                                 onAction={this.onAction.bind(this)}
                                 actions={this.actions}/>
            </Fragment>
        );
    }
}

export default withRouter(SportsbookBetListView);
