import React, {Fragment} from "react";
import {Form, FormListener} from "../form/Form";
import {Chart, ChartType} from "../../http/protocol";
import {FormGroup} from "../form/FormGroup";
import {FormInput, FormTagsInput} from "../form/FormInput";
import {Submit} from "../form/Submit";
import {getContainer} from "../../ioc/IOCSetup";
import {RouteComponentProps, withRouter} from "react-router";
import {FormSubmitError, FormSubmitSuccess} from "../form/FormFeedback";
import {LoadingViewState, RouterView} from "../RouterView";
import {Validators} from "../form/Validators";
import {ChartService} from "../../services/ChartService";
import {SelectInput} from "../form/SelectInput";

interface State extends LoadingViewState {
    chart? : Chart;
    validationMap? : any;
}

interface Props extends RouteComponentProps {

}

export class AdminChartView extends RouterView<Props,State> implements FormListener<Chart>{

    service : ChartService;
    constructor(props : Props){
        super(props);
        this.service = getContainer().getChartService();

        this.state = { chart : {}, validationMap : {}, loading : true};
    }

    loadContent() : Promise<any> | undefined {
        var id = (this.props.match.params as any).id;
        if(id && id!= "new") {
            return this.service.getEntity(id).then((a : Chart) => {
                this.setState({ chart : a});
            })
        } else {
            this.setState({ chart : { tags : []}});
            return undefined;
        }

    }
    componentDidUpdate(prevProps : RouteComponentProps, prevState : any) {
        if((this.props.match.params as any).id != (prevProps.match.params as any).id) {
            this.refreshContent();
        } else {
            super.componentDidUpdate(prevProps, prevState);
        }
    }

    onSubmit():Promise<any> {
        if(!this.state.chart) {
           return Promise.reject("Unable to save attribute");
        }
        return this.service.save(this.state.chart).then( (a : Chart) => {
            if((this.props.match.params as any).id == "new") {
                this.navigate("/admin-chart/" + a.id);
            } else {
                this.setState({ chart : a });
            }

        }).catch(err=>{
            throw "Unable to create/save user";
        });
    }

    onSubmitError(){}

    formDidUpdate(formModel:Chart, validationMap : any) {
        this.setState({ chart : formModel, validationMap : validationMap});
    }


    getTitle() : string {
        return "Edit/Create Charts";
    }



    renderContent() {
        if(!this.state.chart) {
            return <span></span>
        }
        return (

            <Fragment>
                    <Form className="form-horizontal" formListener={this} modelObject={this.state.chart} validationMap={this.state.validationMap}>
                        <div className={"card"}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <FormSubmitError />
                                        <FormSubmitSuccess text="Chart was updated successfully"/>

                                        <FormGroup className="form-group mb-3" model="name">
                                            <label>Name </label>
                                            <FormInput className="form-control" model="name" type="text" validators={[Validators.required()]}/>
                                        </FormGroup>
                                        <div className="hr-line-dashed"></div>

                                        <FormGroup className="form-group mb-3" model="externalId">
                                            <label>External ID </label>
                                            <FormInput className="form-control" model="externalId" type="text" validators={[Validators.required()]}/>
                                            <span className={"help-block"}>The id of the chart on the provider side</span>
                                        </FormGroup>
                                        <div className="hr-line-dashed"></div>


                                        <FormGroup className="form-group mb-3" model="type">
                                            <label>Chart Type</label>
                                            <SelectInput values={[ChartType.LINE,ChartType.BAR]} className="form-control" model="type" type="text" validators={[Validators.required()]}/>
                                        </FormGroup>
                                        <div className="hr-line-dashed"></div>


                                        <FormGroup className="form-group mb-3" model="segmentTitles">
                                            <label>Segment Titles</label>
                                            <FormInput className="form-control" model="segmentTitles" type="text" />
                                            <span className={"help-block"}>Optional comma separated list of titles that will be matched to segments in amplitude</span>
                                        </FormGroup>
                                        <div className="hr-line-dashed"></div>

                                        <FormGroup className="form-group mb-3" model="segmentTitles">
                                            <label>Tags</label>
                                            <FormTagsInput className="tags-form-control" model="tags" />
                                            <span className={"help-block"}>Optional comma separated list of titles that will be matched to segments in amplitude</span>
                                        </FormGroup>
                                        <div className="hr-line-dashed"></div>



                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className={"card"}>
                            <div className={"card-body"}>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="ibox float-e-margins">
                                            <div className="ibox-content">
                                                <div className="ibox-title">
                                                    <h5>Actions</h5>
                                                </div>
                                                <div className="form-group">
                                                    <div className="col-sm-10 col-sm-offset-2">
                                                        <Submit className="btn btn-primary waves-effect waves-light">Save Chart</Submit>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Form>


            </Fragment>
        )
    }

}

export default withRouter(AdminChartView);