import * as React from "react";
import {getContainer} from "../../ioc/IOCSetup";
import {AdminNote, ListAdminNotesRequest, ListAdminNotesResponse} from "../../http/protocol";
import {AdminNoteComp} from "./AdminNoteComp";
import {t} from "../../i18n";


interface Props {
    type : string;
    typeId : string;
    title : string;
}
interface State {
    ready?: boolean;
    notes? : AdminNote[];
    comment?: string;
    addingComment?: boolean;
    expanded?: boolean;
}

export class AdminNotes extends React.Component<Props,State>{
    constructor(props : Props) {
        super(props);
        this.state = { ready : false, notes : [], comment : "", addingComment : false, expanded : false };
    }
    componentDidMount(): void {
       this.loadNotes();
    }
    loadNotes() {
        let listRequest : ListAdminNotesRequest = { type : this.props.type, typeId : this.props.typeId, limit : 100, offset: 0, orderBy : "created", ascending: false};
        getContainer().getAdminNotesService().list(listRequest).then( (res : ListAdminNotesResponse) => {
            if(res.items) {
                this.setState({ ready : true, notes : res.items });
            }
        })
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if(prevProps.typeId!=this.props.typeId){
            this.loadNotes();
        }

    }

    onChange(e : any) {
        this.setState({ comment : e.target.value });
    }
    addComment(e : any) {
        e.preventDefault();
        let note : AdminNote  ={
            type : this.props.type,
            typeId : this.props.typeId,
            comment : this.state.comment || ""
        };


        if (note.comment && note.comment.length !== 0 && note.comment.trim().length !== 0) {
            this.setState({ comment : "", addingComment : true})
            getContainer().getAdminNotesService().save(note).then( res => {
                this.setState({ addingComment : false})
                this.loadNotes();
            });
        }

    }
    pin(an: AdminNote) {
        let note = an;
        note.pinned = !note.pinned;
        this.setState({ready: false});
        getContainer().getAdminNotesService().save(note).then( res => {
            this.setState({ready : true})
            this.loadNotes();
        });
    }

    render() {
        if(!this.state.ready || !this.state.notes) {
            return <span/>
        }
        return (
            <div className="card-box expandable-box">

                {this.state.expanded && (
                    <a className="expand-button float-right" onClick={()=>this.setState({expanded : false})}><i className="mdi mdi-minus"></i></a>
                )}
                {!this.state.expanded && (
                    <a className="expand-button float-right" onClick={()=>this.setState({expanded : true})}><i className="mdi mdi-plus"></i></a>
                )}

                <h4 className="header-title mb-3" onClick={()=>this.setState({expanded : !this.state.expanded})}>{this.props.title}    <span className="badge badge-danger badge-pill ">{this.state.notes.length}</span></h4>

                {(this.state.notes.filter(note => note.pinned).length > 0 || this.state.expanded) && (
                    <div className="chat-conversation">
                        <div className="slimScrollDiv">
                            <ul className="conversation-list" style={{overflowY : "scroll"}}>
                                { this.state.notes.map( note => (
                                    <AdminNoteComp note={note} key={"note"+note.id} show={(note.pinned || this.state.expanded)? true: false} />
                                ))}
                            </ul>
                            <div className="slimScrollBar"
                            ></div>
                            <div className="slimScrollRail"
                            ></div>
                        </div>

                    </div>
                )}
                {this.state.expanded && (
                    <div className="chat-conversation">
                    <form onSubmit={(e)=>this.addComment(e)}>
                        <div className="row">
                            <div className="col">
                                <input type="text" value={this.state.comment} onChange={(e)=>this.onChange(e)} className="form-control chat-input" placeholder={t(`placeholder.enterYourText`)}/>
                            </div>
                            <div className="col-auto">
                                <button type="submit" className="btn btn-danger chat-send btn-block waves-effect waves-light">{t(`button.addComment`)}
                                </button>
                            </div>
                        </div>
                    </form>
                    </div>
                )}
            </div>
        )
    }
}