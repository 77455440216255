import React, { Fragment } from "react";
import { Form, FormListener } from "../form/Form";
import { CasinoGame, CasinoGameType, CasinoLobbyCategory, CasinoLobbyCategoryType, CasinoStudio, GameOrder, GameTag, ListCasinoGameRequest, Style } from "../../http/protocol";
import { FormGroup } from "../form/FormGroup";
import { FormInput } from "../form/FormInput";
import { Submit } from "../form/Submit";
import { getContainer } from "../../ioc/IOCSetup";
import { FormSubmitError, FormSubmitSuccess } from "../form/FormFeedback";
import { LoadingViewState } from "../RouterView";
import { Validators } from "../form/Validators";
import { SelectInput } from "../form/SelectInput";
import { CasinoCategoryService } from "../../services/CasinoCategoryService";
import { CasinoGameService } from "../../services/CasinoGameService";
import { AddCasinoGames } from "./AddCasinoGames";
import { changePosition, removeItemFromArray } from "../../util/ArrayUtils";
import { CasinoGameTagService } from "../../services/CasinoGameTagService";
import { AddCasinoStudio } from "./AddCasinoStudio";
import { CasinoStudioService } from "../../services/CasinoStudioService";

/**
 * Used as argument to addItem(), RemoveItem() and getItem(),
 */
enum ItemOptions {
    GAMECACHE = "gamesCache",
    STUDIOCACHE = "studioCache",
    STUDIOID = "studioIds",
    GAME = "games",
}

interface State extends LoadingViewState {
    category?: CasinoLobbyCategory;
    validationMap?: any;
    gameTypes?: CasinoGameType[];
    showAddGames?: boolean;
    showAddStudios?: boolean;
    gamesCache?: CasinoGame[];
    studioCache?: CasinoStudio[];
    tags?: GameTag[];
}

interface Props {
    id?: string;
    onSaveSuccess: (c: CasinoLobbyCategory) => void;
}

export class EditCasinoCategoryComponent extends React.Component<Props, State> implements FormListener<CasinoLobbyCategory> {
    service: CasinoCategoryService;
    gameService: CasinoGameService;
    tagService: CasinoGameTagService;
    studioService: CasinoStudioService;
    draggedEndGameId: any;
    draggedStartGameId: any;

    constructor(props: Props) {
        super(props);
        this.service = getContainer().getCasinoCategoryService();
        this.gameService = getContainer().getCasinoGameService();
        this.tagService = getContainer().getCasinoGameTagService();
        this.studioService = getContainer().getCasinoStudioService();
        const gameTypes = Object.keys(CasinoGameType) as CasinoGameType[];
        this.state = {
            tags: [],
            category: { gameTypes: [], games: [], studioIds: [], categoryType: CasinoLobbyCategoryType.GAME_SELECTION },
            validationMap: {},
            loading: true,
            gameTypes: gameTypes,
            showAddGames: false,
            showAddStudios: false,
            gamesCache: [],
            studioCache: [],
        };
    }

    componentDidMount() {
        this.load(true);
    }
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevProps.id != this.props.id) {
            this.load();
        }
    }

    load(mount?: boolean) {
        if (this.state.loading == false || mount) {
            this.loadContent()
                ?.catch((e) => {
                    console.log("Loading failed");
                    this.setState({ loadingFailed: true });
                })
                .then(() => {
                    setTimeout(() => {
                        this.setState({ loading: false, loadingFailed: false });
                    }, 100);
                });
        }
    }
    loadContent(): Promise<any> | undefined {
        var id = this.props.id;
        if (id && id != "new") {
            return this.service.getEntity(id).then((c: CasinoLobbyCategory) => {
                return this.tagService
                    .list({
                        limit: 100,
                        offset: 0,
                        orderBy: "name",
                        ascending: true,
                    } as any)
                    .then((tags) => {
                        if (c.games && c.games.length > 0) {
                            this.setState({ tags: tags.items });
                            let lq: ListCasinoGameRequest = {
                                gameIds: c.games,
                                limit: 2000,
                                offset: 0,
                                orderBy: "id",
                                includeDisabledGames: true,
                            };
                            return this.gameService.list(lq).then((gameList) => {
                                this.setState({ category: c, gamesCache: gameList.items });
                            });
                        } else {
                            this.setState({ category: c, tags: tags.items });
                        }
                    })
                    .then(() => {
                        if (c.studioIds && c.studioIds.length > 0) {
                            let promises = c.studioIds.map(async (id) => {
                                return await this.studioService.getEntity(id.toString());
                            });
                            Promise.all(promises).then((res) => {
                                this.setState({ category: c, studioCache: res });
                            });
                        }
                    });
            });
        } else {
            return this.tagService
                .list({
                    limit: 100,
                    offset: 0,
                    orderBy: "name",
                    ascending: true,
                } as any)
                .then((tags) => {
                    this.setState({
                        tags: tags.items,
                        category: { gameTypes: [], games: [], studioIds: [] },
                    });
                });
        }
    }

    onSubmit(): Promise<any> {
        let temCat: CasinoLobbyCategory = this.state.category as CasinoLobbyCategory;
        if (this.state.category?.itemView == 0) {
            temCat = { ...this.state.category, itemView: undefined };
        }
        if (!this.state.category) {
            return Promise.reject("Unable to save attribute");
        }
        return this.service
            .save(temCat)
            .then((a: CasinoLobbyCategory) => {
                this.props.onSaveSuccess?.(a);
            })
            .catch((err) => {
                throw "Unable to create/save user";
            });
    }

    onSubmitError() {}

    formDidUpdate(formModel: CasinoLobbyCategory, validationMap: any) {
        this.setState({ category: formModel, validationMap: validationMap });
    }

    getTitle(): string {
        return "Edit/Create Category";
    }

    /**
     * Used to add new items, either Games or Studios from table to be displayed in RenderItemList.
     * Available cache: gamesCache and studioCache
     * Available categories: games and studioIds
     */
    addItem<T extends CasinoGame | CasinoStudio>(itemOption: keyof CasinoLobbyCategory, cacheOption: keyof State, item: T) {
        if (this.state.category && this.state.category[itemOption] && this.state[cacheOption]) {
            let itemIds = (this.state.category[itemOption] as number[]).concat();
            if (item.id) {
                itemIds.push(item.id);
                let category = Object.assign({}, this.state.category, { [itemOption]: itemIds });
                let cache = this.state[cacheOption]?.concat();
                cache.push(item);
                this.setState({ category: category, [cacheOption]: cache });
            }
        }
    }

    /**
     * Used to remove items, -- || --
     */
    removeItem<T extends CasinoGame | CasinoStudio>(itemOption: keyof CasinoLobbyCategory, cacheOption: keyof State, itemId: number) {
        if (this.state.category && this.state.category[itemOption] && this.state[cacheOption]) {
            let items = removeItemFromArray(this.state.category[itemOption] as number[], (i: number) => i == itemId);
            let cache = removeItemFromArray(this.state[cacheOption], (i: T) => i.id == itemId);
            let category = Object.assign({}, this.state.category, { [itemOption]: items });
            this.setState({ category: category, [cacheOption]: cache });
        }
    }

    /**
     * Get items from cache  -- || --
     * Available cache: gamesCache and studioCache,
     */
    getItemFromCache<T extends CasinoStudio | CasinoGame>(cacheOption: string, itemId: number) {
        let cache = cacheOption as keyof State;
        if (this.state[cache]) {
            let item = this.state[cache].find((i: T) => itemId == i.id);
            if (item) {
                return item;
            }
        }
        return undefined;
    }

    addTranslation(lang: string, value: string) {
        if (this.state.category) {
            let trans = Object.assign({}, this.state.category.translations);
            trans[lang] = value;
            this.setState({
                category: { ...this.state.category, translations: trans },
            });
        }
    }
    removeTranslation(lang: string) {
        if (this.state.category) {
            let trans = Object.assign({}, this.state.category.translations);
            delete trans[lang];
            this.setState({
                category: { ...this.state.category, translations: trans },
            });
        }
    }

    //-----------------------------------------------------------------------------------------------//

    renderGameSelectionForm() {
        const catType = this.state.category?.categoryType;
        return (
            <span>
                <FormGroup className="form-group mb-3" model="maxNrOfGames">
                    <label>Max Nr Of Games/Studios</label>
                    <FormInput className="form-control" model="maxNrOfGames" type="integer" />
                    <span className={"help-block"}>The maximum nr of games to be displayed for this category, if the last category of the current tab you put 0 or -1 to get all games with infinite scroll</span>
                </FormGroup>
                <div className="hr-line-dashed"></div>
                <FormGroup className="form-group mb-3" model="alwaysRenderVertically">
                    <div className="custom-control custom-checkbox">
                        <FormInput id={"alwaysRenderVertically"} className="custom-control-input" model="alwaysRenderVertically" type="checkbox" />
                        <label htmlFor={"alwaysRenderVertically"} className={"custom-control-label"}>
                            Always render games vertically
                        </label>
                        <br />
                        <span className={"help-block"}>If ticked the games within this category will always be rendered vertically and not horizontally(with scroll).</span>
                    </div>
                </FormGroup>
                <div className="hr-line-dashed"></div>
                {catType == CasinoLobbyCategoryType.GAME_SELECTION && (
                    <>
                        <FormGroup className="form-group mb-3" model="gameOrder">
                            <label>Lobby Order </label>
                            <SelectInput className="form-control" model="gameOrder" values={Object.keys(GameOrder)} />
                            <span className={"help-block"}>The sort order of the games</span>
                        </FormGroup>
                        <div className="hr-line-dashed"></div>

                        <FormGroup className="form-group mb-3" model="gameTypes">
                            <label>Game Types</label>
                            <SelectInput optional={true} multiple={true} size={this.state.gameTypes!.length} values={this.state.gameTypes} className="form-control" model="gameTypes" />
                            <span className={"help-block"}>Select the game type(s) you want to include in this category. All games with this type will be included.</span>
                        </FormGroup>
                        <div className="hr-line-dashed"></div>

                        <FormGroup className="form-group mb-3" model="tags">
                            <label>Game Tags</label>
                            <SelectInput optional={true} multiple={true} size={this.state.tags!.length} values={this.state.tags} className="form-control" model="tags" optionModelValue={(t) => t.id} displayValue={(t) => t.name} />
                            <span className={"help-block"}>Select the game tag(s) you want to include in this category. All games with this tag will be included.</span>
                        </FormGroup>
                        <FormGroup className="form-group mb-3" model="matchAllSelectedTags">
                            <div className="custom-control custom-checkbox">
                                <FormInput id={"matchAllSelectedTags"} className="custom-control-input" model="matchAllSelectedTags" type="checkbox" />
                                <label htmlFor={"matchAllSelectedTags"} className={"custom-control-label"}>
                                    Match All Selected Tags
                                </label>
                                <br />
                                <span className={"help-block"}>If ticked a category will only include games that has all the selected tags, if not ticked it will include games that has at least one of the tags.</span>
                            </div>
                        </FormGroup>
                        <div className="hr-line-dashed"></div>
                    </>
                )}

                <div className="row">
                    <div className="col-12">
                        <h4>Studios</h4>
                    </div>
                    <div className="col-12">
                        <div className={"games-list drag-n-drop-container"}>{this.state.category!.studioIds && this.state.category!.studioIds.length > 0 && <RenderItemList<CasinoStudio, CasinoLobbyCategory | State> columns={["id", "Name", "-", "Integration", "Showing in Lobby", "Actions"]} onDragOver={(itemId) => this.onDragOver(itemId)} onDragStart={(e, itemId) => this.onDragStart(e, itemId)} onDragEnd={(itemOption, cacheOption, itemId) => this.onDragEnd<CasinoStudio>(itemOption, cacheOption, itemId)} getItemFromCache={(option, id) => this.getItemFromCache<CasinoStudio>(option, id)} removeItem={(itemOption, cacheOption, id) => this.removeItem<CasinoStudio>(itemOption, cacheOption, id)} itemOption={ItemOptions.STUDIOID} cashe={ItemOptions.STUDIOCACHE} itemIds={this.state.category!.studioIds} />}</div>
                        {(!this.state.category!.studioIds || this.state.category!.studioIds.length == 0) && <span>No Studios added to this category</span>}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div style={{ margin: "10px 0 10px 0px" }}>
                            <a className={"btn btn-primary"} onClick={() => this.toggleShowAddStudios(true)}>
                                <i className={"fe-plus-square"}></i> Add Studios
                            </a>
                        </div>
                    </div>
                </div>
                <div className="hr-line-dashed"></div>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <h4>Games</h4>
                    </div>
                </div>
                {this.state.category!.name && this.state.category!.name.toUpperCase() === "RECOMMENDED" && (
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <span className={"help-block"}>Drag and drop the games in the order you want to appear on the site</span>
                        </div>
                    </div>
                )}
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"games-list drag-n-drop-container"}>
                            {this.state.category!.games && this.state.category!.games.length > 0 && <RenderItemList<CasinoGame, CasinoLobbyCategory | State> columns={["id", "Name", "Studio", "Integration", "Showing in Lobby", "Actions"]} onDragOver={(itemId) => this.onDragOver(itemId)} onDragStart={(e, itemId) => this.onDragStart(e, itemId)} onDragEnd={(itemOption, cacheOption, itemId) => this.onDragEnd<CasinoGame>(itemOption, cacheOption, itemId)} getItemFromCache={(option, id) => this.getItemFromCache<CasinoGame>(option, id)} removeItem={(itemOption, cacheOption, id) => this.removeItem<CasinoGame>(itemOption, cacheOption, id)} itemOption={ItemOptions.GAME} cashe={ItemOptions.GAMECACHE} itemIds={this.state.category!.games} />}
                            {(!this.state.category!.games || this.state.category!.games.length == 0) && <span>No games added to this category</span>}
                        </div>
                    </div>
                    <div style={{ margin: "10px 0 10px 0" }} className={"row"}>
                        {" "}
                        {/* OBS! Button was off, fixed this in the class later */}
                        <div className={"col-12"}>
                            <a className={"btn btn-primary"} onClick={() => this.toggleShowAddGames(true)}>
                                <i className={"fe-plus-square"}></i> Add Games
                            </a>
                        </div>
                    </div>
                </div>
            </span>
        );
    }

    renderContentForm() {
        return (
            <span>
                <FormGroup className="form-group mb-3" model="contentKey">
                    <label>CMS Content Id</label>
                    <FormInput className="form-control" model="contentKey" type="string" />
                    <span className={"help-block"}>
                        The content model must be of type <code>GameListSection</code>. The <code>id</code> field should be copied here.
                    </span>
                </FormGroup>
            </span>
        );
    }

    render() {
        if (this.state.loading) {
            return <span>Please wait...</span>;
        }
        if (!this.state.category || !this.state.gameTypes || !this.state.gamesCache || !this.state.tags) {
            return <span></span>;
        }

        const catTypes = Object.keys(CasinoLobbyCategoryType) as CasinoLobbyCategoryType[];
        const imgVariant = Object.keys(Style);
        const catType = this.state.category.categoryType;

        return (
            <Fragment>
                <Form className="form-horizontal" formListener={this} modelObject={this.state.category} validationMap={this.state.validationMap}>
                    <div className={"card"}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <FormSubmitError />
                                    <FormSubmitSuccess text="Category was updated successfully" />

                                    <FormGroup className="form-group mb-3" model="name">
                                        <label>Name </label>
                                        <FormInput className="form-control" model="name" type="text" validators={[Validators.required()]} />
                                    </FormGroup>

                                    <FormGroup className="form-group mb-3" model="categoryType">
                                        <label>Category Type</label>
                                        <SelectInput
                                            optional={false}
                                            multiple={false}
                                            // size={catTypes.length}
                                            values={catTypes}
                                            className="form-control"
                                            model="categoryType"
                                            // optionModelValue={(t) => t.id}
                                            // displayValue={(t) => t.name}
                                        />
                                        <span className={"help-block"}>
                                            <dl>
                                                <dt>GAME_SELECTION</dt>
                                                <dd>Selection of games. This is the default category type.</dd>
                                                <dt>CONTENT</dt>
                                                <dd>Shows content defined in the CMS.</dd>
                                                <dt>STUDIO_SELECTION</dt>
                                                <dd>Selection of studio images that can link either to a specific game or all games for a studio</dd>
                                            </dl>
                                        </span>
                                    </FormGroup>
                                    <FormGroup className="form-group mb-3" model="imgVariant">
                                        <label>Image variants</label>
                                        <SelectInput optional={false} multiple={false} values={imgVariant} className="form-control" model="imgVariant" />
                                        <span className={"help-block"}>
                                            <label className="">Selection of styles for casino game images.</label>
                                            <dl>
                                                <dt>Info</dt>
                                                <dd>The generated image URL for each style is configured under Settings / Advanced / config.-central.images</dd>
                                            </dl>
                                        </span>
                                    </FormGroup>
                                    <div className="hr-line-dashed"></div>

                                    {/* ------------------------ */}
                                    <FormGroup className="form-group" model="itemView">
                                        <label>Number of items in category row</label>
                                        <SelectInput optional={false} multiple={false} value={this.state.category.itemView != null ? this.state.category.itemView : 0} values={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]} className="form-control" model="itemView" />
                                        <span className={"help-block"}>
                                            <label className="">The maximum nr of games to be displayed per row in this category, defualt is set to 5 games per row, the could be change between the range of 1 - 10</label> <br />
                                            <label>OBS! when option is set to 0, the current number of items in category row is default 5</label>
                                        </span>
                                    </FormGroup>
                                    {/* ------------------------ */}

                                    <div className="hr-line-dashed"></div>

                                    {(catType == CasinoLobbyCategoryType.GAME_SELECTION || catType == CasinoLobbyCategoryType.STUDIO_SELECTION) && this.renderGameSelectionForm()}
                                    {catType == CasinoLobbyCategoryType.CONTENT && this.renderContentForm()}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"card"}>
                        <div className="card-body">
                            <div className={"row"}>
                                <div className={"col-lg-12"}>
                                    <h4>Translations</h4>
                                    {this.state.category &&
                                        this.state.category.translations &&
                                        Object.keys(this.state.category.translations).map((k) => {
                                            return (
                                                <div className={"form-inline"}>
                                                    {k && <input className={"form-control"} value={k} type={"text"} disabled={true} />}
                                                    {this.state.category && this.state.category.translations && <input value={this.state.category.translations[k]} className={"form-control"} type={"text"} disabled={true} />}
                                                    <a className={"btn btn-danger"} onClick={() => this.removeTranslation(k)}>
                                                        Remove
                                                    </a>
                                                </div>
                                            );
                                        })}
                                    <br />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 form-inline">
                                    <div className="hr-line-dashed"></div>
                                    <AddTranslation onAdd={(lang, value) => this.addTranslation(lang, value)} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"card"}>
                        <div className={"card-body"}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="ibox float-e-margins">
                                        <div className="ibox-content">
                                            <div className="ibox-title">
                                                <h5>Actions</h5>
                                            </div>
                                            <div className="form-group">
                                                <div className="col-sm-10 col-sm-offset-2">
                                                    <Submit className="btn btn-primary waves-effect waves-light">Save Category</Submit>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>

                <AddCasinoGames
                    addGame={(game) => this.addItem<CasinoGame>(ItemOptions.GAME, ItemOptions.GAMECACHE, game)}
                    removeGame={(game) => {
                        game.id && this.removeItem<CasinoGame>(ItemOptions.GAME, ItemOptions.GAMECACHE, game.id);
                    }}
                    showAddGames={this.state.showAddGames}
                    addedGames={this.state.gamesCache}
                    toggleShowAddGames={(show) => this.toggleShowAddGames(show)}
                />
                <AddCasinoStudio
                    addStudio={(studio) => this.addItem<CasinoStudio>(ItemOptions.STUDIOID, ItemOptions.STUDIOCACHE, studio)}
                    removeStudio={(studio) => {
                        studio.id && this.removeItem<CasinoStudio>(ItemOptions.STUDIOID, ItemOptions.STUDIOCACHE, studio.id);
                    }}
                    showAddStudios={this.state.showAddStudios}
                    addedStudios={this.state.studioCache as CasinoStudio[]}
                    toggleShowAddStudios={(show) => this.toggleShowAddStudios(show)}
                />
            </Fragment>
        );
    }
    toggleShowAddGames(show: boolean) {
        this.setState({ showAddGames: show });
    }

    toggleShowAddStudios(show: boolean) {
        this.setState({ showAddStudios: show });
    }

    onDragStart = (event: any, itemId: number) => {
        event.dataTransfer.effectAllowed = "move";
        event.dataTransfer.setData("text/html", event.target.parentNode);
        event.dataTransfer.setDragImage(event.target.parentNode, 20, 20);
        this.draggedStartGameId = itemId;
    };

    onDragOver = (index: any) => {
        this.draggedEndGameId = index;
    };

    /**
     * Is used as props to RenderListItems, can be used on either gamesCache or studioCache list
     */
    onDragEnd = <T extends CasinoGame | CasinoStudio>(itemOption: keyof CasinoLobbyCategory, cacheOption: keyof State, itemId: number) => {
        if (this.state.category && this.state[cacheOption] && this.state.category[itemOption]) {
            let items = changePosition(
                this.state.category[itemOption] as number[],
                (g) => g == this.draggedStartGameId,
                (g) => g == this.draggedEndGameId
            );
            let cache = changePosition(
                this.state[cacheOption] as T[],
                (g) => g.id == this.draggedStartGameId,
                (g) => g.id == this.draggedEndGameId
            );
            let category = Object.assign({}, this.state.category, { [itemOption]: items });
            this.setState({ category: category, [cacheOption]: cache });
        }
    };
}

interface AddTranslationProps {
    onAdd?: (lang: string, val: string) => void;
}
class AddTranslation extends React.Component<AddTranslationProps, { lang: string; value: string }> {
    constructor(props: AddTranslationProps) {
        super(props);
        this.state = { lang: "", value: "" };
    }
    add() {
        if (this.props.onAdd && this.state.lang.length > 0 && this.state.value.length > 0) {
            this.props.onAdd(this.state.lang, this.state.value);
            this.setState({ lang: "", value: "" });
        }
    }
    render() {
        return (
            <div className={"form-group"}>
                <label className={"col-3"}>Add Translation</label>
                <div className={"col-4"}>
                    <input type={"text"} value={this.state.lang} onChange={(e) => this.setState({ lang: e.target.value, value: this.state.value })} className={"form-control"} placeholder={"Language code"} />
                </div>
                <div className={"col-4"}>
                    <input type={"text"} value={this.state.value} onChange={(e) => this.setState({ lang: this.state.lang, value: e.target.value })} className={"form-control"} placeholder={"Name in Language"} />
                </div>
                <div className={"col-1"}>
                    <a className={"btn btn-primary"} onClick={() => this.add()}>
                        Add
                    </a>
                </div>
            </div>
        );
    }
}

export default EditCasinoCategoryComponent;

interface RenderItemListProps<T, U> {
    columns: any;
    onDragOver: (id: number) => void;
    onDragStart: (event: any, id: number) => void;
    onDragEnd: (itemOption: keyof U, cacheOption: keyof U, id: number) => void;
    getItemFromCache: <K>(option: string, id: number) => T;
    removeItem: <K>(itemOption: keyof U, cacheOption: keyof U, id: number) => void;
    itemOption: ItemOptions;
    cashe: any;
    itemIds: number[];
}
/**
 * Renders the items from cache to table, renders either gamesCache or studioCache
 */
class RenderItemList<T extends CasinoGame | CasinoStudio, U extends CasinoLobbyCategory | State> extends React.Component<RenderItemListProps<T, U>> {
    draggedEndGameId: any;
    draggedStartGameId: any;

    constructor(props: RenderItemListProps<T, U>) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <table className={"table table-striped"}>
                <thead>
                    <tr>
                        <th></th>
                        {this.props.columns &&
                            this.props.columns.map((td: string) => {
                                return <th key={td}>{td}</th>;
                            })}
                    </tr>
                </thead>
                <tbody>
                    {this.props.itemIds &&
                        this.props.itemIds.map((itemId: number) => (
                            <tr key={itemId} onDragOver={() => this.props.onDragOver(itemId)} onDragStart={(e) => this.props.onDragStart(e, itemId)} onDragEnd={() => this.props.onDragEnd(this.props.itemOption as keyof U, this.props.cashe as keyof U, itemId)}>
                                {((item?: T) => {
                                    if (!item) return;
                                    return (
                                        <React.Fragment>
                                            <td style={{ cursor: "pointer" }} className="drag" draggable>
                                                <i className="fe-menu" />
                                            </td>
                                            <td>{itemId}</td>
                                            <td>{item.name}</td>
                                            <td>{(item as any).studio?.name ?? ""}</td>
                                            <td>{item.integration}</td>
                                            <td>{item && (item.enabled == true && item?.enabled == true ? <span className={"green"}>Yes</span> : <span className={"red"}>No</span>)}</td>
                                        </React.Fragment>
                                    );
                                })(this.props.getItemFromCache<T>(this.props.cashe, itemId))}
                                <td>
                                    <a onClick={() => this.props.removeItem<T>(this.props.itemOption as keyof U, this.props.cashe as keyof U, itemId)} className={"btn btn-danger"}>
                                        Remove
                                    </a>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        );
    }
}
