import { HttpService } from "../http/HttpService";
import {
    Account,
    AccountBalanceResult,
    AccountTransferRequest,
    Currency,
    ListUsersResponse,
    TransactionResult,
    TransferRequest,
    TxResult
} from "../http/protocol";
import { AbstractAdminEntityService } from "./AbstractAdminEntityService";



export class WalletService extends AbstractAdminEntityService<AccountBalanceResult, ListUsersResponse> implements WalletService{

    constructor(http : HttpService) {
        super("/wallet", http);
    }

    getAccountBalances(userId:string):Promise<AccountBalanceResult[]> {
        return this.http.get(this.entityPath + "/balances/"+userId);
    }

    getAccountBalance(accountId:string):Promise<AccountBalanceResult> {
        return this.http.get(this.entityPath + "/account/balance/"+accountId);
    }

    getAccount(accountId:string):Promise<Account> {
        return this.http.get(this.entityPath + "/account/"+accountId);
    }
    transfer(request : TransferRequest) : Promise<TxResult>{
        return this.http.post(this.entityPath + "/transfer", request);
    }
    getSupportedCurrencies():Promise<Currency[]> {
        return this.http.get(this.entityPath + "/account/currencies/list");
    }

    getCurrency(code:string):Promise<Currency> {
        return this.http.get(this.entityPath + "/account/currencies/"+code);
    }

    createOrUpdateCurrency(currency: Currency, createSystemAccounts: boolean): Promise<Currency> {
        return this.http.post(this.entityPath + "/account/currencies/edit?createSystemAccounts=" + createSystemAccounts, currency);
    }

    removeCurrency(code:string):Promise<{}> {
        return this.http.delete(this.entityPath + "/account/currencies/remove/"+code);
    }

}