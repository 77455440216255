import React, { useState } from "react";
import { RouterViewTemplate, useLoadingState } from "../RouterView";
import { EntityDataTable } from "../list/list/EntityDataTable";
import { ActionType } from "../list/list/DataTable";
import { getContainer } from "../../ioc/IOCSetup";
import { PagerModel } from "../list/list/PagerModel";
import { ListRequest } from "../../http/protocol";
import "./menu.scss";

export default function ListMenuView() {
    const service = getContainer().getSettingsService();

    const [listRequest, setListRequest] = useState<ListRequest>({
        limit: 20,
        offset: 0,
        orderBy: "id",
        ascending: false,
    });

    const pagerChanged = (pager: PagerModel) => {
        setListRequest({
            offset: pager.offset,
            limit: pager.limit,
            orderBy: pager.orderBy,
            ascending: pager.ascending,
        });
    };

    const status = useLoadingState(() => {
        return Promise.resolve();
    }, []);

    const onAction = () => {
        return Promise.resolve();
    };

    return (
        <RouterViewTemplate status={status} title={"List Sites menu"}>
            <EntityDataTable
                service={service}
                listRequest={listRequest}
                columns={[
                    { property: "id", name: "ID" },
                    { property: "title", name: "Title" },
                ]}
                actions={[{ type: ActionType.LINK, className: "btn btn-primary", id: "Edit", name: "Edit", getLink: (item) => `/admin/settings/menu/${item.id}` }]}
                onPagerChange={pagerChanged}
                onAction={onAction}
            />
        </RouterViewTemplate> // /admin/settings/menu/:id
    );
}
