import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {HttpService} from "../http/HttpService";
import {EndpointPermissions, ListUserGroupResponse, PermissionCategory, PermissionCategoryInfo, PermittedCategories, UserGroup} from "../http/protocol";


export class UserGroupService extends AbstractAdminEntityService<UserGroup, ListUserGroupResponse>   {

    constructor(http : HttpService) {
        super("/user-group", http)
    }

    listPermissionCategories() : Promise<PermissionCategoryInfo[]> {
        return this.http.get<PermissionCategoryInfo[]>(this.entityPath + "/list-permission-categories");
    }
    fetchUserGroup(nameId : string): Promise<UserGroup> {
       return this.http.get<UserGroup>("/user-group/" + nameId);
    }
    updateGroup(userGroup : UserGroup) : Promise<UserGroup> {
        return this.http.post<UserGroup, UserGroup>( this.entityPath + "/update", userGroup);
    }

    getEndpointPermissions(): Promise<EndpointPermissions[]> {
        return this.http.get<EndpointPermissions[]>(this.entityPath + "/endpoints");
    }

}
