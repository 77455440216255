import {ValidationResult} from "./Form";
export interface Validator<T> {
    validate(value : T, form : any) : boolean;
    getName() : string;
}

export class RangeValidator implements Validator<number> {
    constructor(private _min : number, private _max : number) {}

    validate(value:number, form : any) : boolean {
        if(value >= this._min &&  value <= this._max) {
            return true;
        } else {
            return false;
        }
    }
    getName() {
        return "range";
    }
}
export class RequiredValidator<T> implements Validator<T> {

    validate(value:T, form : any):boolean {
        if(value == null || value == undefined || (value as any).toString().trim().length == 0 ) {
            return false;
        } else {
            return true;
        }
    }
    getName() {
        return "required";
    }

}


export class RegExpValidator implements Validator<string> {
    constructor(private _regExp : RegExp) {}
    validate(value:string, form : any):boolean {
        return this._regExp.test(value);
    }
    getName() {
        return "regexp";
    }
}

export class LengthValidator implements Validator<string> {
    constructor(private _min : number, private _max : number) {}
    validate(value:string, form : any):boolean {
        if(typeof(value)=="undefined" || value === "") {
            return true;
        }
        let length = value.length;
        if(length >= this._min && length <= this._max) {
            return true;
        } else {
            return false;
        }
    }
    getName() {
        return "length";
    }
}
export class LengthsValidator implements Validator<string> {
    constructor(private _lengths : number[]) {}
    validate(value:string, form : any):boolean {
        var length = 0;
        if(typeof value != "undefined") {
            length = value.length;
        }
        return this._lengths.some( l => {
            return length == l;
        })
    }
    getName() {
        return "length";
    }

}


export class MatchInputValidator<T> implements Validator<T> {
    constructor(private _id : string) {}
    validate(value:T, form : any) : boolean {
        var val = form[this._id].value;
        if(value == val) {
            return true;
        } else {
            return false;
        }
    }
    getName() {
        return "match";
    }

}

export class NumberValidator implements Validator<number> {
    constructor() {}
    validate(value:number, form : any) : boolean {
        if(typeof(value) == "undefined" || ""+value == "") {
            return true;
        }
       return !isNaN(value);
    }
    getName() {
        return "number";
    }

}




export class IntegerValidator implements Validator<number> {
    constructor() {}
    validate(value:number, form : any) : boolean {
        if(typeof(value) == "undefined" || ""+value == "" || value == null) {
            return true;
        }
        return !isNaN(value) && parseInt(""+value) == value;
    }
    getName() {
        return "integer";
    }
}
export class BigValidator implements Validator<number> {
    constructor() {}
    validate(value:number, form : any) : boolean {
        if(typeof(value) == "undefined" || ""+value == "" || value == null) {
            return true;
        }
        return !isNaN(value) && parseInt(""+value) == value;
    }
    getName() {
        return "integer";
    }
}
export class Validators {
    public static range(min : number, max : number) : Validator<number> {
        return new RangeValidator(min,max);
    }
    public static min(min : number) : Validator<number> {
        return new RangeValidator(min, Number.MAX_SAFE_INTEGER);
    }
    public static max(max : number) : Validator<number>  {
        return new RangeValidator(Number.MAX_SAFE_INTEGER, max);
    }
    public static required<T>() : Validator<T> {
        return new RequiredValidator();
    }
    public static strLength(min : number, max : number) : Validator<string> {
        return new LengthValidator(min,max);
    }
    public static textInput() : Validator<string> {
        return new RegExpValidator(/^[^\\<\\>\\{\\}\\;]*$/)
    }
    public static strLengths(lengths :number[]) : Validator<string> {
        return new LengthsValidator(lengths);
    }
    public static regExp(regExp : RegExp) : Validator<string> {
        return new RegExpValidator(regExp);
    }

    public static number() {
        return new NumberValidator();
    }
    public static integer() {
        return new IntegerValidator();
    }

    public static email() {
        return Validators.regExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    }
    public static matchInput<T>(inputId : string) {
        return new MatchInputValidator(inputId);
    }

    static textId():Validator<string> {
        return Validators.regExp(/^[a-zA-Z0-9-_]+$/g);
    }
    static error(errorName : string, pristine? : boolean) : ValidationResult {
        if(typeof pristine == "undefined") {
            pristine = false;
        }
        return { errorName : errorName, pristine : pristine };
    }

    static valid(pristine? : boolean) {
        if(typeof pristine == "undefined") {
            pristine = false;
        }
        return { errorName : undefined, pristine : pristine };
    }


}