import React, {Fragment} from "react";
import {ActionType} from "../list/list/DataTable";
import {AdminUser, ListAdminUsersRequest, UserAttributeDefinition} from "../../http/protocol";
import {getContainer} from "../../ioc/IOCSetup";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {PagerModel} from "../list/list/PagerModel";
import {ListUtils} from "../list/list/ListUtils";
import {withRouter} from "react-router";
import {ListView, ListViewProps, ListViewState} from "../ListView";
import {UserAttributeService} from "../../services/UserAttributeService";
import {t} from "../../i18n";


interface State extends ListViewState<ListAdminUsersRequest> {

}
interface Props extends ListViewProps  {

}

export class AdminUserListView extends ListView<State, ListAdminUsersRequest> {

    columns = [
        { property : "id", name : "ID" },
        { property : "attributeKey", name : "Attribute Key" },
        { property : "type", name : "Type" },
        { property : "required", name: "Required", renderer: (p: string, n: string, i: any) => `${i.required ? "Yes" : "No"}` },
        { property : "ordinal", name : "Ordinal", renderer: (p: string, n: string, i: any) => `${i.ordinal ? i.ordinal : "Not set"}` }
    ];

    actions = [
        { id : "edit", name : "Edit", className : "btn btn-warning", iconClassName : "fa fa-eye", type : ActionType.LINK, getLink : (item : any) => "/user-attribute/" + item.id },
        { id : "delete", name : "Delete", className : "btn btn-danger", iconClassName : "fa fa-close", type : ActionType.CONFIRMATION, getLink : (item : any) => "/user-attribute/" + item.id }
    ];

    service : UserAttributeService;

    constructor(props : Props){
        super(props, "/user-attributes");
        this.service = getContainer().getUserAttributeService();
    }

    onAction(item : UserAttributeDefinition, action : string) : Promise<any> | undefined {
        if(action == "delete") {
            return this.service.remove(""+item.id);
        }
    }

    pagerChanged(pager : PagerModel) {
        if(this.state.listRequest) {
            console.log("pager changed!", pager);
            let listRequest = ListUtils.toListRequest<any>(this.state.listRequest,pager);
            this.updateListState(listRequest);
        }
    }
    getTitle() {
        return t(`usersManagement.listUsersAttributes.pageTitle`);
    }

    renderContent() {
        if(!this.state.listRequest) {
            return <div/>;
        }
        return (
            <Fragment>
                <EntityDataTable service={this.service}
                                 onPagerChange={this.pagerChanged.bind(this)}
                                 columns={this.columns}
                                 listRequest={this.state.listRequest}
                                 onAction={this.onAction.bind(this)}
                                 actions={this.actions}/>
            </Fragment>
        );
    }

}

export default withRouter(AdminUserListView);
