import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {AuditLog, ListAuditLogsResponse} from "../http/protocol";
import {HttpService} from "../http/HttpService";


export class AuditLogService extends AbstractAdminEntityService<AuditLog, ListAuditLogsResponse> implements AuditLogService {


    constructor(http: HttpService) {
        super("/audit-log", http);
    }

    getAuditLog(id: Number): Promise<AuditLog> {
        return this.http.get(this.entityPath + "/" + id);
    }
}