import * as React from "react";
import {FieldSet, FieldSetStatus} from "./Form";
import {Alert} from "./Alert";
import {Modal} from "react-bootstrap";
import {t} from "../../i18n";


export class FormSubmitError extends React.Component<{ fieldSet? : FieldSet, onClose? : ()=>void }, {}> {
    close() {
        if(this.props.onClose) {
            this.props.onClose();
        }
        this.props.fieldSet && this.props.fieldSet.resetSubmitStatus();
    }
    render(){
        if(!this.props.fieldSet) {
            return <span></span>
        }
        let status = this.props.fieldSet.getStatus();
        return (
            <Modal show={status == FieldSetStatus.SUBMIT_ERROR} onHide={this.close.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(`alertInfo.somethingWentWrong`)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert type="danger" text={this.props.fieldSet.getFormError()} />
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={this.close.bind(this)}>{t(`button.close`)}</button>
                </Modal.Footer>
            </Modal>
        );
    }

}

export class FormSubmitSuccess extends React.Component<{ fieldSet? : FieldSet, text? : string, onClose? : ()=>void }, {}> {
    close() {
        if(this.props.onClose) {
            this.props.onClose();
        }
        this.props.fieldSet && this.props.fieldSet.resetSubmitStatus();
    }
    render(){
        if(!this.props.fieldSet){
            return <span></span>
        }
        return (
            <Modal show={this.props.fieldSet.getStatus() == FieldSetStatus.SUBMIT_SUCCESS} onHide={ () => this.props.fieldSet && this.props.fieldSet.resetSubmitStatus()}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(`alertInfo.weDidIt`)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert type="success" text={this.props.text} />
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={this.close.bind(this)}>{t(`button.close`)}</button>
                </Modal.Footer>

            </Modal>
        );
    }
}

export class FormSubmitVariable extends React.Component<{ fieldSet? : FieldSet, text? : string, onClose? : ()=>void }, {}> {
    close() {
        if(this.props.onClose) {
            this.props.onClose();
        }
        this.props.fieldSet && this.props.fieldSet.resetSubmitStatus();
    }
    render(){
        if(!this.props.fieldSet) {
            return <span></span>
        }
        return (
            <Modal show={this.props.fieldSet.getStatus() == FieldSetStatus.SUBMIT_SUCCESS} onHide={ () => this.props.fieldSet && this.props.fieldSet.resetSubmitStatus()}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(`title.results`)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert type="variable" text={this.props.text} />
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={this.close.bind(this)}>Close</button>
                </Modal.Footer>

            </Modal>
        );
    }
}