import React, {Fragment} from "react";
import {ListBundlesRequest, ListRequest, UserJourneyWrapper} from "../../http/protocol";
import {ListView, ListViewProps, ListViewState} from "../ListView";
import {ActionType} from "../list/list/DataTable";
import {getContainer} from "../../ioc/IOCSetup";
import {PagerModel} from "../list/list/PagerModel";
import {ListUtils} from "../list/list/ListUtils";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {withRouter} from "react-router";
import {ColumnType} from "../list/list/ColumnType";
import {ScriptWidgetService} from "../../services/ScriptWidgetService";
import {NavLink} from "react-router-dom";

interface State extends ListViewState<ListRequest> {
    listRequest?: ListBundlesRequest;
}

interface Props extends ListViewProps {

}

export class ScriptWidgetsListView extends ListView<State, ListRequest> {

    columns = [
        { property : "id", name : "ID", orderBy : "ID"},
        { property : "variableName", name : "Variable Name"},
        { property:  "enabled", name : "Enabled", type : ColumnType.YES_OR_NO}
    ];

    actions = [
        { id : "view", name : "View", className : "btn btn-primary", iconClassName : "fa fa-eye", type : ActionType.LINK, getLink : (item : any) => "/widget/" + item.id },
        { id : "delete", name : "Delete", className: "btn btn-danger", iconClassName: "fa fa-trash", type : ActionType.CONFIRMATION }
    ];

    service : ScriptWidgetService;

    constructor(props : Props){
        super(props, "/widgets");
        this.service = getContainer().getScriptWidgetService();

    }

    onAction(item : UserJourneyWrapper, action : string) : undefined|Promise<any> {
        if(action == "delete") {
            return this.service.remove(""+item.id);
        }
    }

    pagerChanged(pager : PagerModel) {
        if(this.state.listRequest) {
            let listRequest = ListUtils.toListRequest<any>(this.state.listRequest,pager);
            this.updateListState(listRequest);
        }
    }
    getTitle() {
        return "List Widgets"
    }

    renderContent() {

        if(!this.state.listRequest) {
            return <div/>;
        }
        return (
            <Fragment>
                <div className={"card-box"}>
                    <NavLink className={"btn btn-primary"} to={"/widget/new"}>Add Widget</NavLink>
                </div>
                <EntityDataTable service={this.service}
                                 onPagerChange={this.pagerChanged.bind(this)}
                                 columns={this.columns}
                                 listRequest={this.state.listRequest}
                                 onAction={this.onAction.bind(this)}
                                 actions={this.actions}/>
            </Fragment>
        );
    }

}

export default withRouter(ScriptWidgetsListView);