import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {HttpService} from "../http/HttpService";
import {PushMessage} from "../http/protocol";

export class NotificationsService extends AbstractAdminEntityService<Response, any>  implements NotificationsService {
    constructor(http : HttpService) {
        super("/notifications", http)
    }

    async notifyUser(userId: number, message:PushMessage):Promise<Response>{
        return this.http.post(this.entityPath+'/notify?userId='+userId, message);
    };

    async notifyAllUsers(message:PushMessage):Promise<Response> {
        return this.http.post(this.entityPath+'/broadcast', message);
    }

    async getSubCount():Promise<number> {
        return this.http.get(this.entityPath+'/count');
    }
}