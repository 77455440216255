import { Fragment } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import {
    ActiveLocales,
    ListTranslationsResponse,
    Translation,
    TranslationExportType,
    TranslationsBatchUpdateResult
} from "../../http/protocol";
import { getContainer } from "../../ioc/IOCSetup";
import { TranslationsService } from "../../services/TranslationsService";
import { Collapsible } from "../Collapsible";
import { LoadingViewState, RouterView } from "../RouterView";
import { Alert } from "../form/Alert";
import { AddTranslationKeyForm } from "./AddTranslationKeyForm";
import { EditTranslation } from "./EditTranslation";



interface Props extends RouteComponentProps {

}
interface State extends LoadingViewState {
    locales? : string[];
    defaultLocale? : string;
    selectedLocale? : string;
    localeTranslations? : LocaleTranslations[];
    loadingTranslations? : boolean;
    showOnlyMissing?: boolean;
    newLocale?: string;
    batchResult?: TranslationsBatchUpdateResult;
    batchError?: boolean;
    importOpen?: boolean;
}

interface LocaleTranslations {
    locale: string;
    translations : Translation[];
}





export class TranslationsListView extends RouterView<Props, State> {
    service : TranslationsService;

    constructor(props : Props){
        super(props);
        this.state = { locales : [], localeTranslations : [], loadingTranslations : false, showOnlyMissing : false, newLocale : "", importOpen: false, batchError: false};
        this.service = getContainer().getTranslationsService();
    }

    getTitle(): string {
        return "Translations";
    }

    loadContent(): Promise<any> | void {
        return this.service.getLocales().then((locales : ActiveLocales)  => {
            let locale = locales.defaultLocale;
            this.setState({ locales : locales.locales, defaultLocale : locale, selectedLocale : locale})
            if(locale) {
                return this.fetchTranslations(locale);
            } else {
                return Promise.reject();
            }
        })
    }

    fetchTranslations(locale : string, skipLoadingProgress? : boolean) {
        this.setState({ loadingTranslations : skipLoadingProgress ? false : true});
        return this.service.list({ locale : locale, offset: 0, limit : 2000})
            .then(( resp : ListTranslationsResponse) => {
                if(!this.state.localeTranslations || !resp.items) {
                    return Promise.resolve();
                }
                let localeTrans : LocaleTranslations[] = this.state.localeTranslations;

                localeTrans = localeTrans.concat().filter(t => t.locale != locale);
                localeTrans.push({ locale : locale, translations : resp.items});
                this.setState({ localeTranslations : localeTrans, loadingTranslations : false, selectedLocale : locale});
            });
    }
    changeLocale(locale?: string) {
        if(locale) {
            this.fetchTranslations(locale);
        }
    }

    onUpdate(locale: string) {
            this.fetchTranslations(locale, true);
    }

    onKeyAdded() {
        if(this.state.defaultLocale) {
            this.fetchTranslations(this.state.defaultLocale);
        }
    }
    addLocale() {

        if(this.state.locales && this.state.newLocale && this.state.newLocale.trim().length >= 2) {
            let locales =  this.state.locales.concat();

            if(this.state.locales.find(l => l == this.state.newLocale)) {
                console.log("Trying to add locale " + this.state.newLocale + " that already exist");
                return;
            }
            locales.push(this.state.newLocale);

            this.setState({ locales : locales, newLocale : ""});
        }
    }

    importJsonFileChange(event: any) {
        let file = event.target.files[0];

        if (file) {
            const reader = new FileReader()
            reader.onloadend = (e) => this.handleImportedJsonFile(e.target?.result as string, this.state.selectedLocale!)
            reader.readAsText(file)
        }
    }

    async handleImportedJsonFile(json: string, locale: string) {
        const transObject = JSON.parse(json)

        const traverse = (node: any, path: string, transList: Translation[]) => {
            const children = Object.keys(node)

            if (typeof node ===  typeof "") {
                transList.push({key: path, value: node, locale: locale})
            } else {
                children.forEach(c => {
                    const p = path + (path === "" ? "" : ".") + c
                    traverse(node[c], p, transList)
                })
            }
        }

        const translationList: Translation[] = []
        traverse(transObject, "", translationList)

        this.setState({loading: true})
        await this.service.batchUpdateTranslations(translationList)
        await this.loadContent()
        this.setState({loading: false})
    }


    importCsvFileChange(event: any, deleteIfNullText: boolean) {
        let file = event.target.files[0];

        if (file) {
            const reader = new FileReader()
            reader.onloadend = (e) => this.handleImportedCsvFile(e.target?.result as string, deleteIfNullText)
            reader.readAsText(file)
        }
    }


    async handleImportedCsvFile(csv: string, deleteIfNullText: boolean) {
        this.setState({loading: true})

        try {
            const res = await this.service.importTranslationsCsv(csv, deleteIfNullText)

            const error = (res.errors?.length ?? 0) > 0;

            this.setState({batchError: error, batchResult: res})
        } catch (error) {
            this.setState({batchError: true, batchResult: undefined})
        }

        await this.loadContent()

        this.setState({loading: false})
    }

    renderErrors() {
        let items: any[] = []

        if (!this.state.batchResult  ||  (this.state.batchResult.errors?.length ?? 0) == 0) {
            items = [<li>Unknown error. Please check the CSV for errors.</li>]
        } else {
            let k = 0;

            items = this.state.batchResult.errors!.map(e => (
                <li key={k++}>{e.error}: line = {e.line ? e.line : "n/a"}, key = <code>{e.key}</code>, lang = <code>{e.lang}</code>, value = <code>{e.value}</code></li>
            ));
        }

        return (
            <div className="alert alert-danger">
                <p>Errors while performing the batch update. Please check the CSV file.</p>
                <ul>{items}</ul>
            </div>
        )
    }

    renderContent() {
        if(!this.state.locales || !this.state.localeTranslations || !this.state.selectedLocale) {
            return <span/>
        }
        let selectedTranslations  = this.state.localeTranslations.find(t => t.locale == this.state.selectedLocale);
        if(!selectedTranslations) {
            return <span />
        }
        let defaultTranslations = this.state.localeTranslations.find(t => t.locale == this.state.defaultLocale);
        if(!defaultTranslations) {
            return <span/>;
        }

        const batchResult = this.state.batchResult
        const selectedLocale = this.state.selectedLocale

        return (
            <Fragment>
                <div className={"card-box"}>
                    <h4>Show Locale</h4>
                    <div className={"form"}>
                        <div className={"form-group"}>
                            <select className={"form-control"} defaultValue={this.state.defaultLocale} onChange={(e)=>this.changeLocale(e.target.value)}>
                                {this.state.locales.map(l => <option key={l}>{l}</option>)}
                            </select>
                        </div>
                        <div className={"form-group form-check"}>
                            <input className={"checkbox"} id="showOnlyMissing"  type={"checkbox"} checked={this.state.showOnlyMissing} onChange={(e)=>this.setState({ showOnlyMissing : !this.state.showOnlyMissing})}/>
                            <label htmlFor={"showOnlyMissing"} className={"control-label"}>Show only missing translations </label>
                        </div>
                    </div>
                </div>
                <div className={"card-box"}>
                    <h4>Add Locale</h4>
                    <div className={"form form-inline"}>
                        <div className={"form-group form-check"}>
                            <input className={"form-control"} value={this.state.newLocale} type={"text"} onChange={(e)=>this.setState({ newLocale : e.target.value})} placeholder={"Eg: sv, zh-Hans"} />
                            <a className={"btn btn-primary"} onClick={()=>this.addLocale()}>Add Locale</a>
                        </div>
                    </div>
                </div>

                <Collapsible title="Import & Export" collapsed={!this.state.importOpen} onToggle={(c) => this.setState({importOpen: !c})} >
                    <div className={"card-box"}>
                        <h4>Export/Import CSV</h4>
                        <p>
                            The CSV-file must have headers with columns Language, Key, Text. Multiple languages are allowed. 
                            The format matches the output of the export.
                        </p>
                        <p>
                            Example of format:<br/>
                            <code>
                                Language,Key,Text<br/>
                                en,my.key,"My text value"<br/>
                                sv,my.key,"Mitt textvärde"
                            </code>
                        </p>
                        <p>
                            The export can either include empty placeholders for missing translations or only export what is defined for 
                            the selected language (<code>{selectedLocale}</code>).
                        </p>
                        <span>
                            <strong>Important:</strong>
                            <ul>
                                <li>Only translate values in the "Text" column</li>
                                <li>Don't change headers (first row)</li>
                                <li>LibreOffice is known to create compatible CSV and is recommended</li>
                                <li>Don't change values inside double curly braces or pipe characters. 
                                    Examples: <code>{'{{currency}}'}</code>, <code>|amount|</code>, <em>currency</em> and <em>amount</em> should NOT be translated.</li>
                            </ul>
                            
                        </span>

                        {/* { this.state.batchError  &&  <Alert type="danger" text={`${this.state.batchError}`}/> } */}
                        { this.state.batchError  &&  this.renderErrors() }
                        { batchResult  &&  <Alert type={this.state.batchError ? "warning" : "success"} text={`Created: ${batchResult.created}, updated: ${batchResult.updated}, deleted: ${batchResult.deleted}`}/> }

                        <div className={"form form-inline"}>
                            <div className={"form-group"}>
                                <a className={"btn btn-primary"} href={this.service.getCsvExportUrl([selectedLocale], TranslationExportType.INCLUDE_MISSING)}>Export {`${selectedLocale}`}, include missing</a>
                                <a className={"btn btn-primary"} href={this.service.getCsvExportUrl([selectedLocale], TranslationExportType.ONLY_MISSING)}>Export {`${selectedLocale}`}, only missing</a>
                                <a className={"btn btn-primary"} href={this.service.getCsvExportUrl([selectedLocale], TranslationExportType.ONLY_DEFINED)}>Export {`${selectedLocale}`}, only defined</a>

                                <label htmlFor={"importCsv"} className="btn btn-primary">Import</label>
                                <input id="importCsv" type="file"
                                    name="translationsCsvFile"
                                    style={{display:"none"}}
                                    onChange={(e) => this.importCsvFileChange(e, false)} />
                                &nbsp;

                                <label htmlFor={"importCsvDel"} className="btn btn-danger">Import & Delete Empty</label>
                                <input id="importCsvDel" type="file"
                                    name="translationsCsvFile"
                                    style={{display:"none"}}
                                    onChange={(e) => this.importCsvFileChange(e, true)} />

                            </div>
                        </div>

                        <Collapsible title="Advanced JSON Import/Export" collapsed={true}>
                                <p>
                                    Export and Import JSON-files for the seleted language: {selectedLocale}. 
                                    The format of the JSON-files is specified <a href="https://www.i18next.com/misc/json-format" target="_blank">here.</a>
                                </p>
                                <p>
                                    <strong>IMPORTANT:</strong> Make sure you import to the correct locale! Current locale: <code>{selectedLocale}</code>.
                                </p>

                                <div className={"form form-inline"}>
                                    <div className={"form-group"}>

                                        <a className={"btn btn-primary"} href={this.service.getJsonExportUrl(selectedLocale)}>Export JSON</a>
                                        <label htmlFor={"importJson"} className="btn btn-primary">Import JSON</label>
                                        <input id="importJson" type="file"
                                            name="translationsJsonFile"
                                            style={{display:"none"}}
                                            onChange={(e) => this.importJsonFileChange(e)} />
                                    </div>
                                </div>
                        </Collapsible>




                    </div>
                </Collapsible>
                <div className={"card-box"}>

                    { this.state.defaultLocale && this.state.defaultLocale == selectedLocale  &&  <AddTranslationKeyForm onAdded={()=>this.onKeyAdded()} locale={this.state.defaultLocale}/>}
                    { this.state.loadingTranslations && (
                        <div>Loading</div>
                    )}
                    { !this.state.loadingTranslations && (

                        <table className={"table table-striped"}>
                            <thead>
                            <tr>
                                <th>Key</th>
                                <th>Value</th>
                            </tr>
                            </thead>
                            <tbody>
                                {defaultTranslations &&  defaultTranslations.translations.map( t => {
                                    if(!selectedTranslations) {
                                        return false
                                    }
                                    let translation = selectedTranslations.translations.find( (s : Translation)=> s.key == t.key);
                                    if(this.state.showOnlyMissing && translation) {
                                        return false;
                                    }

                                    return (
                                    <Fragment key={t.id}>
                                        <tr>
                                            <td>{t.key}</td>
                                            <td>
                                                <EditTranslation 
                                                    translation={translation} 
                                                    locale={selectedTranslations.locale} 
                                                    akey={t.key} 
                                                    onUpdate={(l : string)=> this.onUpdate(l)}
                                                />
                                                {!translation && (
                                                    <span>{t.value}</span>
                                                )}
                                            </td>
                                        </tr>

                                    </Fragment>
                                    )
                                })}
                            </tbody>
                        </table>
                    )}
                </div>
            </Fragment>
        );
    }





}

export default withRouter(TranslationsListView);
