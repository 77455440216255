import * as React from "react";
import {useEffect, useState} from "react";
import {Jackpot, JackpotGame, JackpotList, JackpotPool} from "../../http/protocol";
import {CardBox, RouterViewTemplate, useLoadingState} from "../RouterView";
import {getContainer} from "../../ioc/IOCSetup";
import {Link} from "react-router-dom";


const toGames = (games? : JackpotGame[]) => {
    if(!games || games.length == 0) {
        return "N/A";
    } else if(games.length == 1) {
        return (games[0].name ?? "N/A") +" - " + (games[0].id ?? "" )+ " - " + games[0].externalId
    }
    return games.length + " Games";

}
const toPools = (pools? : JackpotPool[]) => {
    if(!pools || pools.length == 0) {
        return "N/A";
    } else if(pools.length == 1) {
        return (pools[0].type ?? "N/A");
    }
    return pools.length + " Pools";
}
export const JackpotListComponent = (props : { jackpots : Jackpot[], filter : string}) => {
    const [selected, setSelected] = useState(-1);

    useEffect(()=>{
        setSelected(-1);
    },[props.jackpots, props.filter])

    let jackpots = props.jackpots;
    if(props.filter.length > 2) {
        let filterString = props.filter.toLowerCase();
        jackpots = jackpots.filter(j => {
            if(j.games?.find(g => {
                if((g.name ?? "").toLowerCase().indexOf(filterString) != -1) {
                    return true;
                } else if(g.id && ""+g.id == filterString) {
                    return true;
                } else if(g.externalId && g.externalId.toLowerCase() == filterString) {
                    return true;
                }
                return false;
            })) {
                return true;
            } else if(j.pools?.find(p => (p.type ?? "").toLowerCase().indexOf(filterString) != -1)) {
                return true;
            } else if(j.externalId == filterString) {
                return true;
            } else if((j.name ?? "").toLowerCase().indexOf(filterString) != -1) {
                return true;
            }
            return false;
        })
    }
    return (
        <div>
            <table className={"table"}>
                <thead>
                    <tr>
                        <th>Jackpot Name</th>
                        <th>Jackpot ID</th>
                        <th>Integration</th>
                        <th>Games</th>
                        <th>Pools</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {jackpots.map( (j,i) => {
                        return (
                            <>
                                <tr key={i+"top"}>
                                    <td>{j.name}</td>
                                    <td>{j.externalId}</td>
                                    <td>{j.integration}</td>
                                    <td>{toGames(j.games)}</td>
                                    <td>{toPools(j.pools)}</td>
                                    {selected === i && (  <td><a  onClick={()=>setSelected(-1)} className={"btn btn-primary"}>Less</a></td>)}
                                    {selected != i && (  <td><a  onClick={()=>setSelected(i)} className={"btn btn-primary"}>More</a></td>)}
                                </tr>
                                {selected === i && (
                                    <tr key={i+"bottom"}>
                                        <td colSpan={6}>
                                           <div className={"row"}>
                                               <div className={"col-6"}>
                                                   <h4>Games</h4>
                                                   {j.games?.map((g,i) => (
                                                       <div key={"game"+i} className={"jackpot-game-row"}>
                                                            <span className={"jackpot-row-data name"}>
                                                               {g.id && <Link className="game-link" to={"/casino-game/"+g.id}>{g.id}</Link>}
                                                                {g.name ?? "Game not found"}
                                                            </span>
                                                            <span className={"external-id"}>
                                                                {g.externalId}
                                                            </span>
                                                       </div>
                                                   ))}
                                               </div>
                                               <div className={"col-6"}>
                                                   <h4>Pools</h4>
                                                   {j.pools?.map((p, i) => (
                                                       <div className={"jackpot-game-row"} key={"pool-type"+i}>
                                                            <span className={"jackpot-row-data type"}>
                                                                <strong>{p.type ?? "N/A"}</strong>
                                                            </span>
                                                           <span className={"jackpot-row-data type"}>
                                                                {p.pool?.map((p,i) => (
                                                                    <span className={"jackpot-row-pools"} key={"pool"+i}>
                                                                        {p.amount} {p.currencyCode}
                                                                    </span>
                                                                ))}
                                                           </span>
                                                       </div>
                                                   ))}
                                               </div>
                                           </div>
                                        </td>
                                    </tr>
                                )}
                            </>

                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export const JackpotListView = () => {

    const [jackpotList, setJackpotList] = useState<JackpotList|undefined>(undefined);
    const [filter, setFilter] = useState("");
    const status = useLoadingState(()=>{
        return getContainer().getCasinoJackpotService().list({}).then((mw) => {
            setJackpotList(mw);
        })

    },[]);
    let timeout : NodeJS.Timeout | undefined = undefined;
    const updateFilter = (str : string) => {
         if(timeout) {
             clearTimeout(timeout);
         }
         timeout = setTimeout(()=>{
            setFilter(str);
            timeout = undefined;
            },200)
    }
    return (
        <RouterViewTemplate status={status}>
            <CardBox title={"Casino Jackpots"}>
                <input className={"form-control"} onChange={(e) => updateFilter(e.target.value)} placeholder={"Filter for jackpot or game"}/>
                {jackpotList?.jackpots && <JackpotListComponent jackpots={jackpotList.jackpots} filter={filter}/>}
            </CardBox>
        </RouterViewTemplate>
    )
}
