import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {EventListRequest, EventListResponse} from "../http/protocol";
import {HttpService} from "../http/HttpService";


export class AnalyticsService extends AbstractAdminEntityService<EventListRequest, EventListResponse> {

    constructor(http: HttpService) {
        super("/analytics/events", http);
    }

}