
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import {
    CreateVoucherRequest
} from "../../http/protocol";
import { t } from "../../i18n";
import { getContainer } from "../../ioc/IOCSetup";
import { ApplicationState } from "../../user/UserStore";
import { LoadingStatus, RouterViewTemplate } from "../RouterView";
import { Form, useForm } from "../form/Form";
import { FormSubmitError, FormSubmitSuccess } from "../form/FormFeedback";
import { FormGroup } from "../form/FormGroup";
import { FormInput } from "../form/FormInput";
import { SelectInput } from "../form/SelectInput";
import { Submit } from "../form/Submit";
import { Validators } from "../form/Validators";


const CreateVoucher = () => {

    const currencies = useSelector((s: ApplicationState) => s.settings.settings?.currencies)
    const service = getContainer().getVoucherService();
    const history = useHistory()

    const onSubmit  = async () => {
        if (!form.model) return;
        const voucher = await service.createVoucher(form.model)    
        history.push("/voucher/details/" + voucher.id)
    }

    const onError = () => {}

    const [form, formListener] = useForm<CreateVoucherRequest>({expiryDays: 365, openAtStart: true}, onSubmit, onError)
    
    return (
        <RouterViewTemplate status={(currencies  &&  currencies.length > 0) ? LoadingStatus.DONE : LoadingStatus.LOADING} title={t("payments.vouchers.create.pageTitle")}>

            <div className="wrapper wrapper-content animated fadeInRight">
                <Form className="form form-horizontal" validationMap={form.validationMap} modelObject={form.model} formListener={formListener}>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card-box">
                                <div className="ibox-content">

                                    <FormGroup className="form-group">
                                        <label className="col-sm-2 control-label">User Id</label>
                                        <div className="col-sm-10">
                                            <FormInput  model="userId" validators={[Validators.number()]} type="number"  className="form-control" />
                                            <span className={"help"}>The owner user id</span>
                                        </div>
                                    </FormGroup>

                                    <FormGroup className="form-group">
                                        <label className="col-sm-2 control-label">Amount</label>
                                        <div className="col-sm-10">
                                            <FormInput  model="amount" validators={[Validators.required()]} type="number"  className="form-control" />
                                            <span className={"help"}>Value of the Voucher</span>
                                        </div>
                                    </FormGroup>

                                    <FormGroup className="form-group">
                                        <label className="col-sm-2 control-label">Currency</label>
                                        <div className="col-sm-10">
                                            <SelectInput values={currencies?.map(c => c.code)} validators={[Validators.required()]} model="currency" className={"form-control"}  />
                                        </div>
                                    </FormGroup>                                    

                                    <FormGroup className="form-group">
                                        <label className="col-sm-2 control-label">Valid Number of Days</label>
                                        <div className="col-sm-10">
                                            <FormInput type="number" model="expiryDays" className="form-control" validators={[Validators.required(), Validators.min(1), Validators.number()]}></FormInput>
                                        </div>
                                    </FormGroup>

                                    <FormGroup className="form-group">
                                        <label className="col-sm-2 control-label" htmlFor="open-at-start-check">Open from start</label>
                                        <div className="col-sm-10">
                                            <FormInput id="open-at-start-check" type="checkbox" model="openAtStart"></FormInput>
                                            <div className={"help"}>If checked the voucher will be open after creation. If not checked the voucher will need to be opened manually before use.</div>
                                        </div>
                                    </FormGroup>

                                    <FormGroup className="form-group">
                                        <label className="col-sm-2 control-label">Reference</label>
                                        <div className="col-sm-10">
                                            <FormInput  model="reference" validators={[]} type="string"  className="form-control" />
                                            <span className={"help"}>Optional reference to payment</span>
                                        </div>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card-box">

                                <FormSubmitError />
                                <FormSubmitSuccess text={t(`alertInfo.accountSuccessfully`)}/>
                                <div className="form-group">
                                    <div className="col-sm-12">
                                        <Submit className="btn  btn-primary">Save</Submit>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </RouterViewTemplate>
    );
}


export default withRouter(CreateVoucher);