import * as React from "react";

import {PagerModel} from "./PagerModel";
import {NextPreviousPager, Pager} from "./Pager";
import {ColumnType} from "./ColumnType";
import {Big} from "big.js";

import {ExportDataTable} from "./ExportDataTable";
import {formatDate} from "../../../util/date";
import {DeleteButtonModal} from "../../../util/DeleteButtonModal";
import {NavLink} from "react-router-dom";
import {ToggleButton} from "./ToggleButton";
import ReactTooltip from "react-tooltip";
import {t} from "../../../i18n";
import _ from "lodash";


interface Props {
    pager : PagerModel;
    columns: Array<{ property : string, secondProperty?:string, name : string,className? : string,  type? : ColumnType, 
        getUrl? : (item : any) => string; getExternalUrl? : (item : any) => string; 
        linkClass?: string, invertColors?: boolean, component?: (item: any) => React.Component, icon?: boolean }>;
    items : Array<any>;
    onPagerChange : (pager : PagerModel) => void;
    loading? :  boolean;
    onAction? : (item : any, actionId : string, index : number) =>  Promise<any> | undefined;
    actions? : Array<DataTableAction>;
    removed? : number[];
    itemsUpdating : number[];
    showIndex? : boolean;
    currencyCode?:string;
    setMaxLimit?:number;
    disableCount? : boolean;
    hidePagination? : boolean;

}

/**
 * Renderer for a column value. If the renderer is set on a column
 * it will be used to render it regardless of the property name.
 */
export interface Renderer<t> {
  (property: string, name: string, item: any): t
}

export enum ActionType {
    DEFAULT, LINK, CONFIRMATION, BLOCK, CANCEL,APPROVE
}
export interface DataTableAction {
    id : string,
    name : string;
    className? : string;
    iconClassName? : string;
    filter? : (item : any) => boolean;
    type? : ActionType;
    getLink? : (item : any) => string;
    isBlocked? : (item : any) => boolean;
    isApproved? : (item : any) => boolean;
    optional? : (item : any) => boolean;
    show? : (item: any) => boolean;
    disabled?: (item: any) => boolean;
}
export interface State {
    pagerOffsetPlaceholder: string;
}

export class DataTable extends React.Component<Props,State> {
    offsetError: boolean = false;
    types  = ColumnType;
    maxlimit =  this.props.setMaxLimit === 100 ? [10,20,50,100]  : [10,20,50,100,500,1000];
    limits : Array<number> = this.maxlimit;
    constructor(props: Props) {
        super(props);
        this.state = this.getStateFromPager(this.props.pager);
    }
    getStateFromPager(pager: PagerModel): State {
        return {pagerOffsetPlaceholder: ""+Math.floor(pager.offset/pager.limit + 1)};
    }

    getClass(column : any) {
        if(this.hasSort(column)) {
            if(this.props.pager.orderBy == column.orderBy) {
                if(this.props.pager.ascending) {
                    return "sorting_asc";
                } else {
                    return "sorting_desc";
                }
            } else {
                return "sorting";
            }
        } else {
            return "";
        }
    }

    getPropertyValue(item : any, property : string, column: any) : any {
        if (column.renderer != null) {
          return column.renderer(property, column.name, item);
        } else if (property.indexOf(".") != -1) {
            var parts = property.split(".");
            var value = item;

            for(var i = 0; i<parts.length; i++) {
                value = value[parts[i]];
            }
            return value;
        } else {
            return item[property];
        }

    }
    setOffset(offset : number) {
        var pager : PagerModel = Object.assign({}, this.props.pager);
        pager.offset = offset;
        this.props.onPagerChange(pager);
        this.setState(this.getStateFromPager(pager));
    }

    hasSort(column : any) {
        return typeof(column.orderBy) != "undefined";
    }

    onAction(item : any, id : string, index : number) : Promise<any> | undefined {
        if(this.props.onAction && this.props.itemsUpdating.indexOf(index) == -1) {
            return this.props.onAction(item,id, index);
        }
    }

    sort(column : any) {
        if(!column.orderBy) {
            return;
        }
        var ascending = false;
        if(this.props.pager.orderBy == column.orderBy) {
            ascending = !this.props.pager.ascending;
        } else {
            ascending = this.props.pager.ascending;
        }

        var pager : PagerModel = Object.assign({},this.props.pager);
        pager.orderBy = column.orderBy;
        pager.ascending = ascending;
        this.props.onPagerChange(pager);
    }

    getLimitOptions() {
        return this.limits.map( l => {
            return (
                <option key={'limit-'+l} value={""+l}>{l}</option>
            );
        });
    }

    getHeaders() {
        return this.props.columns.map( c => {
            let align : any= (c.type == ColumnType.BIG ? ({ textAlign : "right"}): ({ textAlign : ""}));
            if (this.hasSort(c)) {
                return (
                    <th key={"h-" + c.name} style={align} onClick={this.sort.bind(this, c)} className={this.getClass(c)}>{t(`dataTable.header.${_.camelCase(c.name)}`)}</th>
                );
            } else {
                return (
                    <th key={"h-" + c.name} style={align}>{t(`dataTable.header.${_.camelCase(c.name)}`)}</th>
                );
            }
        })
    }

    getColumns(item : any) {
        return this.props.columns.map( c => {
            let cssName = c.property.replace(/\\./g, "_");
            if(c.className) {
                cssName = c.className;
            }
            if(c.type == ColumnType.BIG) {
                let val 
                try {
                    val = new Big(this.getPropertyValue(item, c.property, c));
                } catch (e) {
                    return <td className={cssName} key={c.property + "column"} style={{textAlign : "right"}}></td>
                }

                let container = <span className={val.lt(0) ? "negative" : "positive"}>{val.toFixed(10)}</span>;
                return <td className={cssName} key={c.property + "column"} style={{textAlign : "right"}}>{container}</td>
            } else if(c.type === ColumnType.NOTE) {
                let text:string = this.getPropertyValue(item, c.property, c);
                if(text && text.length > 0) {
                    console.log("note: ", c);
                    console.log("note: ", item);
                    return <td key={c.property + "column"} className={cssName} >
                        <div className="note-icon" style={{position:"relative"}}>
                            {item && item.pinnedNote && <i className="fas fa-thumbtack" style={{
                                color: "dodgerblue",
                                transform: "rotate(-45deg)",
                                position: "absolute",
                                top: "-5px",
                                left: "-5px",
                                zIndex: 2
                            }}/>}
                            <i className="far fa-comment-alt" style={{fontSize:"18px", zIndex:1, position:"absolute"}}  data-tip data-for={"noteTip_"+text}/>
                        </div>
                        <ReactTooltip id={"noteTip_"+text} place="top" effect="solid" type="info">
                            {text}
                        </ReactTooltip>
                    </td>
                } else {
                    return <td key={c.property + "column"} className={cssName} ></td>

                }
            } else if(c.type == ColumnType.DATE) {
                let timestamp: number = this.getPropertyValue(item,c.property, c) ? Number(this.getPropertyValue(item,c.property, c).toString().split(".").join("").substring(0,13)) : 0;
                return <td key={c.property + "column"} className={cssName} >{formatDate(timestamp)}</td>
            } else if(c.type == ColumnType.ENUM) {
                return <td key={c.property + "column"} className={cssName + " " + this.getPropertyValue(item,c.property, c)}>{ this.getPropertyValue(item,c.property, c)}</td>
            } else if(c.type == ColumnType.BOOLEAN) {
                const bool = this.getPropertyValue(item,c.property, c);
                let boolStr;
                if (bool == undefined  ||  bool == null) {
                    boolStr = ""
                } else if (bool) {
                    boolStr = "True"
                } else {
                    boolStr = "False"
                }

                return <td key={c.property + "column"} className={cssName + " " + bool}>{boolStr}</td>
            }  else if(c.type == ColumnType.YES_OR_NO) {
                const bool = this.getPropertyValue(item,c.property, c);
                let boolStr;
                let color = "";
                if (bool == undefined  ||  bool == null) {
                    boolStr = ""
                } else if (bool) {
                    boolStr = t(`status.yes`)
                    color = c.invertColors === true ? "red" : "green";
                } else {
                    boolStr = t(`status.no`)
                    color = c.invertColors === true ? "green" : "red";
                }
                return <td key={c.property + "column"} className={cssName + " " + color}>{boolStr}</td>
            } else if(c.type == ColumnType.IMAGE) {
                return <td key={c.property + "column"} className={cssName} > <img alt={"list view"} src={this.getPropertyValue(item,c.property, c)} className="listViewImage" /> </td>
            } else if(c.type == ColumnType.LIST) {
                return <td key={c.property + "column"} className={cssName} > {(this.getPropertyValue(item,c.property, c) as any[]).join(", ")} </td>
            } else if(c.type === ColumnType.LINK && c.getUrl) {
                let url = c.getUrl(item);
                return (
                    <td key={c.property + "column"} className={cssName} >
                        { url && <NavLink className={c.linkClass ? c.linkClass : ""} to={c.getUrl(item)}>{this.getPropertyValue(item, c.property, c)}</NavLink> }
                        { !url && <a>{this.getPropertyValue(item, c.property, c)}</a>}
                    </td>
                );
            } else if(c.type === ColumnType.LINK && c.getExternalUrl) {
                let url = c.getExternalUrl(item);
                if(url != undefined) {
                    return (
                        <td key={c.property + "column"} className={cssName} >
                            <a className={c.linkClass ? c.linkClass : ""} target="_blank" href={url}>{this.getPropertyValue(item, c.property, c)} <i className="fa fa-external-link-alt"/> </a>
                        </td>
                    );
                } else {
                    return <td key={c.property + "column"} className={cssName} >{this.getPropertyValue(item, c.property, c)}</td>
                }
            } else if (c.type === ColumnType.COMPONENT) {
                return <td key={c.property + "column"} className={cssName}>{c.component!(item)}</td>
            } else if(c.type === ColumnType.MONEY && c.secondProperty) {
                return <td key={c.property + "column"} className={cssName} >{this.getPropertyValue(item, c.property, c)} {this.getPropertyValue(item, c.secondProperty, c)}</td>
            } else {
                const v = this.getPropertyValue(item, c.property, c)
                const s = v == undefined ? t(`status.na`) : (v instanceof Object ? v : `${v}`);
                return <td key={c.property + "column"} className={cssName} >{s}</td>
            }
        });
    }
    getActions(item : any, index : number) : any {
        if(!this.props.actions || this.props.actions.length==0 || this.isRemoved(index)){
            return false;
        }
        return this.props.actions.filter( a=> {
            if(a.filter && a.filter(item) === false)  {
                return false;
            } else {
                return true;
            }
        }).map( a => {
            if(a.type == ActionType.CONFIRMATION) {
                let disabled = a.disabled ? a.disabled(item) : false;
                return <DeleteButtonModal iconClassName={a.iconClassName} className={a.className} disabled={disabled} key={"delete-"+a.id} onDelete={()=>this.onAction(item,a.id, index)} label={a.name}
                    show={a.show == undefined  ||  a.show(item)} />;
            } else if(a.getLink && a.type == ActionType.LINK) {
                return <NavLink key={"action" + a.id} className={a.className} to={a.getLink(item)}><i className={a.iconClassName}></i> {a.name}</NavLink>
            } else if(a.isBlocked && a.type == ActionType.BLOCK) {
                let disabled = a.isBlocked(item);
                return <ToggleButton key={a.id} akey={a.id} onAction={()=>this.onAction(item, a.id, index)} disabled={disabled} type={ActionType.BLOCK}/>
            }else if(a.isApproved && a.type == ActionType.APPROVE) {
                let disabled = a.isApproved(item);
                return <ToggleButton key={a.id} akey={a.id} onAction={()=>this.onAction(item, a.id, index)} disabled={disabled} type={ActionType.APPROVE}/>
            } else if(a.optional && a.type == ActionType.CANCEL) {
                let optional = a.optional(item);
                if(optional) {
                    return <DeleteButtonModal iconClassName={a.iconClassName} className={a.className} key={"delete-"+a.id} onDelete={()=>this.onAction(item,a.id, index)} label={a.name}/>;
                } else return null;
            } else {
                return (
                    <a key={"action-"+a.id} onClick={()=>this.onAction(item, a.id, index)} className={a.className}><i className={a.iconClassName}></i> {a.name}</a>
                );
            }

        });
    }
    isRemoved(index : number){
        return this.props.removed && this.props.removed.length && this.props.removed.indexOf(index) != -1;
    }
    isUpdating(index : number) {
        return  this.props.itemsUpdating && this.props.itemsUpdating.length && this.props.itemsUpdating.indexOf(index) != -1;
    }
    geActionColumn(index : number, item : any) : any {
        if(this.props.actions && this.props.actions.length > 0) {
            return (
                <td className="actions">
                    {this.getActions(item,index)}
                </td>
            );
        }
        return false;
    }
    getRowClasses(index : number) {

        if(this.isRemoved(index)) {
            return "removed";
        }
        if(this.isUpdating(index)) {
            return "updating";
        }
        return "";
    }
    getResults() {
        return this.props.items.map( (item,index) => {
            return (
                <tr key={index + "-" + item.id} className={this.getRowClasses(index)}>
                    {this.getIndexColumn(index)}
                    {this.getColumns(item)}
                    {this.geActionColumn(index,item)}
                </tr>
            )

        })
    }

    getContainerClass() : string {
        if(this.props.loading) {
            return "loading";
        } else {
            return "";
        }
    }
    limitChange(e : React.FormEvent) {
        var value : string = (e.target as any).value;
        var pager : PagerModel = Object.assign({},this.props.pager);
        pager.limit = parseInt(value);
        pager.offset = 0;
        this.props.onPagerChange(pager);
        this.setState(this.getStateFromPager(pager));
    }
    offsetChange(e : React.FormEvent) {
        let value : string = (e.target as any).value;
        if (!isNaN(+value) && value.length > 0) {
            this.props.pager.offset = parseInt(value);
            this.offsetError = false;
            this.setState({pagerOffsetPlaceholder: ""+parseInt(value)});
        } else {
            this.offsetError = true;
            this.setState({pagerOffsetPlaceholder: value});
        }
    }
    updateOffsetIfEnter(e : any) {
        if (e.keyCode == 13 && e.shiftKey == false && this.offsetError == false) {
            this.setOffset((parseInt(this.state.pagerOffsetPlaceholder)-1)*this.props.pager.limit);
        }
    }
    getIndexHeader() : any {
        if(this.props.showIndex) {
            return <th>Pos</th>
        }
        return false;
    }
    getActionHeader() : any {
        if(this.props.actions && this.props.actions.length > 0) {
            return <th className="actions">{t(`dataTable.header.actions`)}</th>;
        }
        return false;
    }
    render() {
        let disableMargin = this.props.hidePagination;

        return (


            <div className="dataTables_wrapper dt-bootstrap">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form form-inline" >
                            {!this.props.hidePagination && (
                                <>
                                    <label>
                                        {t(`dataTable.label.goToPage`)}
                                        <input className={"form-control" + (this.offsetError ? " error" : "")}
                                               style={{width: "50px", margin: "5px"}}
                                               value={this.state.pagerOffsetPlaceholder}
                                               onChange={this.offsetChange.bind(this)}
                                               onKeyDown={this.updateOffsetIfEnter.bind(this)}/>
                                    </label>
                                    <label>
                                        {t(`dataTable.label.show`)}&nbsp;
                                        <select className="form-control input-sm" value={this.props.pager.limit}
                                                onChange={this.limitChange.bind(this)}>
                                            {this.getLimitOptions()}
                                        </select>
                                    </label>
                                </>)
                            }
                            <ExportDataTable disableMargin={disableMargin} items={this.props.items} columns={this.props.columns}/>
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className="table-responsive">
                            <table className="table table-striped mb-0 dt-responsive dataTable no-footer dtr-inline">
                                <thead>
                                <tr role="row">
                                    {this.getIndexHeader()}
                                    {this.getHeaders()}
                                    {this.getActionHeader() }
                                </tr>
                                </thead>
                                <tbody>
                                { this.getResults() }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {!this.props.hidePagination && (
                    <>
                        <div className={"row"}>
                            <div className={"col-sm-12 col-md-12 col-lg-1 col-12"}>
                                <div className="dataTables_info">
                                    {this.getNrOfResults()}
                                </div>
                            </div>
                            <div className={"col-sm-12 col-md-12 col-lg-12 col-12"}>
                                {!this.props.disableCount && (
                                    <Pager onOffsetUpdate={this.setOffset.bind(this)} model={this.props.pager}/>
                                )}
                                {this.props.disableCount && (
                                    <NextPreviousPager onOffsetUpdate={this.setOffset.bind(this)}
                                                       model={this.props.pager}/>
                                )}
                            </div>
                        </div>
                    </>)
                }

            </div>


        );
    }

    getIndexColumn(index: number): any {
        if (this.props.showIndex) {
            return <td>{index + 1 + (this.props.pager.offset ? this.props.pager.offset : 0)}</td>
        }
        return false;
    }

    private getNrOfResults() {
        if (this.props.disableCount) {
            if (this.props.pager.nrOfResults == 0) {
                return <span>No more results</span>;
            }
            let offset = this.props.pager.offset + 1;
            if (offset == this.props.pager.nrOfResults) {
                return <span>Showing result {offset}</span>
            }
            return <span>Showing result {offset} - {this.props.pager.nrOfResults}</span>;
        }
        if (this.props.pager.nrOfResults < 0) { // unspecified nbr of results - implemented for some very large tables
            return <span/>;
        }
        return <span>{t(`dataTable.label.totleNrResults`)} {this.props.pager.nrOfResults}</span>;
    }
}
