import React, { useEffect } from "react";
import { MenuType } from "./EditMenuView";
import { useForm } from "react-hook-form";

interface Props {
  selected: MenuType;
  setSelected: (value: MenuType) => void;
}

export default function SelectMenuEdit(props: Props) {
  const { register, watch } = useForm<{ menu: MenuType }>({ defaultValues: { menu: props.selected } });

  const menu = watch("menu");

  useEffect(() => {
    props.setSelected(menu);
  }, [menu]);

  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-lg-12">
            <div className={"form-group mt-2"}>
              <label>Select menu to edit</label>
              <select {...register("menu")} className={"form-control"}>
                {[MenuType.MENU_MAIN, MenuType.MENU_FOOTER].map((v, i) => {
                  return (
                    <option key={i} value={v}>
                      {v}
                    </option>
                  );
                })}
              </select>
              {props.selected == MenuType.MENU_MAIN && <span className={"help-block"}> The "Menu Main" controls the side, top, and bottom menus. Select this option to edit these menus.</span>}
              {props.selected == MenuType.MENU_FOOTER && <span className={"help-block"}>The "Menu Footer" is specifically for the side menu and will appear below the "Menu Main." Select this option to edit these menus.</span>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
