import React, { useState, useEffect, Fragment } from "react";
import { RouteComponentProps, withRouter, NavLink } from "react-router-dom";
import { MenuRoute, MenuRouteGroup } from "../services/MenuService";
import { getContainer } from "../ioc/IOCSetup";
import { t } from "../i18n";
import _ from "lodash";
import { getStore } from "../RootReducer";
import { toggleMenu } from "../user/UserActions";
import { isMobile } from "react-device-detect";

interface Props extends RouteComponentProps {}

const CustomLink = (props: {
  onClick: () => void;
  path?: string;
  className?: string;
  children: any;
}) => {
  if (props.path) {
    return (
      <NavLink
        to={props.path}
        onClick={props.onClick}
        className={props.className}
      >
        {props.children}
      </NavLink>
    );
  } else {
    return (
      <a onClick={props.onClick} className={props.className}>
        {props.children}
      </a>
    );
  }
};

const SideBar: React.FC<Props> = (props: Props) => {
  const [activeTopMenu, setActiveTopMenu] = useState<string>("Home");
  const [routes, setRoutes] = useState<any[]>([]);

  useEffect(() => {
    const getRoutes = async () => {
      const perms = getStore().getState().user.permissions ?? {};
      const nonRestrictedRoutes = await getContainer()
        .getMenuService()
        .getMenuRoutes(perms);

      setRoutes(nonRestrictedRoutes);
    };

    getRoutes();
  }, []);

  const toggleTopMenu = (menu: string) => {
    setActiveTopMenu(menu);
  };

  const isExpanded = (
    title: string,
    route?: string,
    children?: MenuRoute[]
  ): boolean => {
    if (!title) {
      return false;
    }
    if (activeTopMenu == title) {
      return true;
    }
    return isCurrentPath(route, children);
  };

  const isCurrentPath = (
    menuRoute?: string,
    children?: MenuRoute[]
  ): boolean => {
    let path = props.location.pathname;

    if (menuRoute == path) {
      return true;
    }

    if (!children) {
      return false;
    }

    return children.some((c: MenuRoute) => {
      if (c.path == path) {
        return true;
      } else {
        return false;
      }
    });
  };

  const closeSidebar = (route: MenuRoute) => {
    const shouldCloseSideBar =
      route.children == null || route.children.length < 1;

    if (shouldCloseSideBar) toggleMenu(false);
  };

  return (
    <div className={"left-side-menu"}>
      <div id="sidebar-menu" className="active">
        <ul
          className="metismenu in"
          id={"side-menu"}
          style={{ paddingTop: "10px" }}
        >
          {routes.map((route: MenuRouteGroup) => {
            return (
              <Fragment key={route.id}>
                {!route.hideTitle && (
                  <li className={"menu-title"}>
                    {t(`sideBar.menuTitleName.${_.camelCase(route.name)}`)}
                  </li>
                )}
                {route &&
                  route.routes &&
                  route.routes.map((r: MenuRoute) => {
                    return (
                      <li
                        key={route.name + r.label}
                        className={
                          isCurrentPath(r.path, r.children) ? "active" : ""
                        }
                      >
                        <CustomLink
                          path={r.path}
                          onClick={() => {
                            toggleTopMenu(r.label);
                            if (isMobile) closeSidebar(r);
                          }}
                          className={
                            isExpanded(r.label, r.path, r.children)
                              ? "active"
                              : ""
                          }
                        >
                          <i className={"fe-" + r.icon}></i>
                          <span>
                            {t(`sideBar.nav.${_.camelCase(r.label)}`)}
                          </span>
                          {r.children && <span className="menu-arrow"></span>}
                          {!r.children && r.externalLink && (
                            <span
                              className="fe-external-link"
                              style={{ right: "20px", position: "absolute" }}
                            ></span>
                          )}
                        </CustomLink>
                        {r.children && (
                          <ul
                            className={
                              "nav-second-level collapse " +
                              (isExpanded(r.label, r.path, r.children)
                                ? "in"
                                : "")
                            }
                            style={
                              isExpanded(r.label, r.path, r.children)
                                ? {}
                                : { height: "0px" }
                            }
                          >
                            {r.children.map((c: MenuRoute) => {
                              const labelCamel = _.camelCase(r.label);
                              const childCamel = _.camelCase(c.label);
                              return (
                                <li
                                  key={route.name + r.label + c.label}
                                  className={
                                    isCurrentPath(c.path, []) ? "active" : ""
                                  }
                                >
                                  {c.path && (
                                    <NavLink
                                      onClick={() => {
                                        if (isMobile) closeSidebar(c);
                                      }}
                                      to={c.path}
                                    >
                                      {labelCamel === "reports" ||
                                      labelCamel === "dashboards"
                                        ? c.label
                                        : t(
                                            `sideBar.navSecondLevel.${childCamel}`
                                          )}
                                    </NavLink>
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </li>
                    );
                  })}
              </Fragment>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default withRouter(SideBar);
