import * as React from "react";
import { EventListRequest, EventType } from "../../http/protocol";
import {getContainer} from "../../ioc/IOCSetup";
import { AnalyticsService } from "../../services/AnalyticsService";
import { ColumnType } from "../list/list/ColumnType";
import { EntityDataTable } from "../list/list/EntityDataTable";
import { ListUtils } from "../list/list/ListUtils";
import { PagerModel } from "../list/list/PagerModel";
import {t} from "../../i18n";


interface Props {
    userId : number;
}
interface State {
    listRequest? : EventListRequest;
}

export class PlayerAudit extends React.Component<Props,State>{
    analyticsService : AnalyticsService;

    columns = [
        { property : "timestamp", name : "Timestamp", type : ColumnType.DATE},
        { property : "type", name : "Type" },
        { property : "remoteAddress", name : "IP Address" },
    ];

    constructor(props : Props) {
        super(props);
        this.analyticsService = getContainer().getAnalyticsService();
        this.state = { listRequest: { userId : props.userId, limit : 10, offset: 0, orderBy : "timestamp", ascending: false, "type": EventType.LOGIN} };
    }

    pagerChanged(pager : PagerModel) {
        if(!this.state.listRequest) {
            return;
        }
        var listRequest = ListUtils.toListRequest<EventListRequest>(this.state.listRequest, pager);
        this.setState({listRequest : listRequest});
    }

    render() {
        if(!this.state.listRequest) {
            return <span/>
        }
        return (
            <div className={"card"}>
                <div className="card-header">
                    <h4>{t(`title.playerAudit`)}</h4>
                </div>
                <EntityDataTable
                    columns={this.columns}
                    service={this.analyticsService}
                    listRequest={this.state.listRequest}
                    onPagerChange={this.pagerChanged.bind(this)}
                    onAction={()=>undefined}
                />
           </div>
        )
    }
}