import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { PermissionCategory, ServiceEnabledKey, ServiceEnabledStateResponse, ServiceEnablementCategory, ServicesEnabledResponse } from "../../http/protocol";
import { getContainer } from "../../ioc/IOCSetup";
import { PermissionRequired } from "../security/PermissionRequired";
import { ConfirmModal } from "../../util/ConfirmModal";
import { DeleteButtonModal } from "../../util/DeleteButtonModal";
import { NavLink } from "react-router-dom";

export default function EnablementSettings() {
    const [config, setConfig] = useState<ServicesEnabledResponse>({});
    const [error, setError] = useState<boolean>(false);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const { setValue, watch } = useForm<ServicesEnabledResponse>({ values: config });

    const handleChange = (index: number, value: boolean) => {
        setValue(`services.${index}.softEnabled`, value);
        // setValue(`services.${index}.statusChange`, moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
        let service = watch(`services.${index}`);
        return updateServiceConfig(service.key!, value)
    };

    /* Service enums that are not yet implemented.
     * When implementing a new service, remove it from this list to show in admin. 
     */
    const NOT_IMPLEMENTED = [ 
        ServiceEnablementCategory.REWARDS,
        ServiceEnablementCategory.CASHBACK,
        ServiceEnablementCategory.RECAPTCHA,
        ServiceEnablementCategory.CUSTOMER_IO,
        ServiceEnablementCategory.POS,
        ServiceEnablementCategory.VOUCHER,
        ServiceEnablementCategory.AGENT_PAYOUT_SYSTEM,
        ServiceEnablementCategory.AFFILIATE_PAYOUT_SYSTEM
    ]

    async function updateServiceConfig(key: ServiceEnabledKey, enabled: boolean) {
        try {
            const response = await getContainer().getServiceLevelConfigurationService().updateServiceConfig(key, enabled);
            response.services = filterAndSort(response.services)
            setConfig(response)
        } catch (error) {
            setError(true);
        }
    }

    async function getServiceConfig() {
        try {
            const response = await getContainer().getServiceLevelConfigurationService().getServiceConfig();
            if (response) {
                response.services = filterAndSort(response.services)
                setConfig(response);
            }
        } catch (error) {
            setError(true);
        }
    }

    function filterAndSort(services: ServiceEnabledStateResponse[] | undefined) {
        // NOTE: when all services are implemented the first filtering should be removed.
        const implementedServices = services?.filter(s => NOT_IMPLEMENTED.indexOf(s.key?.category!) == -1 ) ?? []
        implementedServices.sort((s1, s2) => {
            const n1 = (s1.key?.category ?? '') + s1.key?.component
            const n2 = (s2.key?.category ?? '') + s2.key?.component

            return n1.localeCompare(n2) ?? 0;
        })
        return implementedServices
    }


    useEffect(() => {
        getServiceConfig();
    }, []);

    return (

        <div>
            {error && <div className="alert alert-danger">Oops! Something went wrong. Please try again later.</div>}
            <div className="card">
                <div className="card-body">

                    <div className="mb-3">
                        <h4>Available Services to Enable/Disable</h4>
                        <p><strong>CAUTION:</strong> This page is intended for emergency use only. Services disabled using this page might render broken UI on the site!</p>
                        <p>Services that are hard enabled can be disabled/enabled from here. If they are hard disabled they must be re-configured and restarted by a system operator.</p>

                        <PermissionRequired accessLevel={"r"} permissions={[PermissionCategory.AUDIT]} showError={false}>
                            <p>
                                <NavLink className="btn btn-primary" to={`/audit-logs/limit/20/ascending/false/orderBy/id/offset/0/eventName/SERVICE_ENABLEMENT`}>Audit Logs</NavLink>
                            </p>
                        </PermissionRequired>

                    </div>
                    <div className="service-enablement-div">
                        <table className="table m-b-xs table-striped">
                            <thead>
                                <tr>
                                    <th>Category</th>
                                    <th>Name</th>
                                    <th>Enabled By Property (hard)</th>
                                    <th>Enabled By Configuration (soft)</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {config?.services &&
                                    config.services.map((service, index) => {
                                        const softEnabled = watch(`services.${index}.softEnabled`);
                                        return (
                                            <tr key={service.name}>
                                                <td className="w-25">{service.key?.category}</td>
                                                <td className="w-25">{service.name}</td>
                                                <td>
                                                    { service.hardEnabled == undefined  &&
                                                        <span style={{ minWidth: "80px" }} className={`p-1`}>N/A</span>
                                                    }

                                                    { service.hardEnabled == true  &&
                                                        <span style={{ minWidth: "80px" }} className={`badge p-1 badge-success`}>ENABLED</span>
                                                    }

                                                    { service.hardEnabled == false  &&
                                                        <span style={{ minWidth: "80px" }} className={`badge p-1 badge-danger`}>DISABLED</span>
                                                    }
                                                </td>
                                                <td>
                                                    { service.softEnabled == undefined  &&
                                                        <span style={{ minWidth: "80px" }} className={`p-1`}>N/A</span>
                                                    }

                                                    { service.softEnabled == true  &&
                                                        <span style={{ minWidth: "80px" }} className={`badge p-1 badge-success`}>ENABLED</span>
                                                    }

                                                    { service.softEnabled == false  &&
                                                        <span style={{ minWidth: "80px" }} className={`badge p-1 badge-danger`}>DISABLED</span>
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        <PermissionRequired permissions={[PermissionCategory.SERVICE_ENABLEMENT]} accessLevel="w">
                                                            <DeleteButtonModal className={`btn btn-xs p-1 btn-${!softEnabled ? "primary" : "secondary"}`} onDelete={() => handleChange(index, !softEnabled)}
                                                                label={`${softEnabled ? 'Disable' : 'Enable'}`} />
                                                        </PermissionRequired>
                                                    }
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}
