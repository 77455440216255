import React from "react";
import {NavDropdown} from "react-bootstrap";
import {SettingsService} from "../services/SettingsService";
import {getContainer} from "../ioc/IOCSetup";
import {Currency, CurrencyDefinition, Settings} from "../http/protocol";
import {LoadingViewState, RouterView} from "./RouterView";
import {RouteComponentProps, withRouter} from "react-router";
import { connect } from "react-redux";
import { ApplicationState } from "../user/UserStore";


interface Props extends RouteComponentProps {
    currencies?: CurrencyDefinition[]
}

interface State extends LoadingViewState{
    activeCurrency: string;
}

export const ACTIVE_CURRENCY_KEY = "activeCurrency"

export class CurrencySelector extends RouterView<Props, State> {

    settingsService: SettingsService;
    constructor(props:Props) {
        super(props);

        this.settingsService = getContainer().getSettingsService();

        this.state = {activeCurrency: ""};
    }

    loadContent(): void {
        const localStorageActiveCurrency = window.localStorage.activeCurrency ? window.localStorage.activeCurrency:  "";
        this.setState({activeCurrency: localStorageActiveCurrency})
    }

    componentDidUpdate(prevProps: Props, prevState: State): void {
        const localStorageActiveCurrency = window.localStorage.activeCurrency

        if (this.props.currencies  &&  this.props.currencies.length > 0  &&  !localStorageActiveCurrency) {
            const active = this.props.currencies[0].code
            window.localStorage.setItem(ACTIVE_CURRENCY_KEY, active!)
        }
    }

    onClick(currency:any) {
        if(currency) {
            window.localStorage.setItem(ACTIVE_CURRENCY_KEY, currency);
            this.setState({activeCurrency : currency});
            window.location.reload();
        }
    }

    getCurrencies() {
        if (!this.props.currencies) {
            return <></>;
        } else {
            let currencyFormatted = this.props.currencies!.map((currency: CurrencyDefinition) => {
                let curr = currency.code
                return <NavDropdown.Item key={curr} onClick={() => (this.onClick(curr))} active={curr == this.state.activeCurrency}>{currency.code}</NavDropdown.Item>
            });

            return currencyFormatted
        }
    }

    getTitle(): string {
        return "";
    }

    renderContent() {
        return(
            <div>
                <NavDropdown id={"currency-selector"} title={<span>{this.state.activeCurrency}<i className="mdi mdi-chevron-down"></i></span>}>
                    {this.getCurrencies()}
                </NavDropdown>
            </div>
        )
    }
}

const mapStateToProps = (state : ApplicationState) => {
    return { currencies: state.settings.settings?.currencies }
};


export default connect(mapStateToProps)(withRouter(CurrencySelector))

