import { HttpService } from "../http/HttpService";
import { ListSportsbookBetsResponse, SportsbookBet, SportsbookBetAndEvents, SportsbookBetEvent, SportsbookUpdateBetRequest } from "../http/protocol";
import { AbstractAdminEntityService } from "./AbstractAdminEntityService";


export class SportsbookService extends AbstractAdminEntityService<SportsbookBet, ListSportsbookBetsResponse>  {

    constructor(http : HttpService) {
        super("/sportsbook", http)
    }

    getBetAndEvents(betId: number): Promise<SportsbookBetAndEvents> {
        return this.http.get(`${this.entityPath}/${betId}/bet-and-events`)
    }

    getEventsForBet(betId: number): Promise<SportsbookBetEvent[]> {
        return this.http.get(`${this.entityPath}/${betId}/events`)
    }

    updateBet(betId: number, req: SportsbookUpdateBetRequest): Promise<SportsbookBetAndEvents> {
        return this.http.post(`${this.entityPath}/${betId}/update`, req)
    }
}