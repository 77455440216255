import {CardBox, LoadingStatus, RouterViewTemplate} from "../RouterView";
import * as React from "react";
import {useState} from "react";
import {getContainer} from "../../ioc/IOCSetup";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {ActionType} from "../list/list/DataTable";
import {PagerModel} from "../list/list/PagerModel";
import {ListUtils} from "../list/list/ListUtils";
import {Link} from "react-router-dom";

export const UserRiskRuleCollectionListView = () => {
    const [listRequest, setListRequest] = useState({
        limit : 100,
        offset : 0,
    })
    const columns = [
        { property : "id", name : "ID", orderBy : "ID"},
        { property : "name", name : "Name"},
        { property : "description", name : "Description"}
    ];
    const pagerChanged = (pager : PagerModel) => {
        if(listRequest) {
            let lr = ListUtils.toListRequest<any>(listRequest,pager);
            setListRequest(lr);
        }
    }

    const actions = [
        { id : "view", name : "View", className : "btn btn-primary", iconClassName : "fa fa-eye", type : ActionType.LINK, getLink : (item : any) => "/user-risk-rules-edit/" + item.id }
    ];
    return (
        <RouterViewTemplate status={LoadingStatus.DONE} title={"User Registration Rules"}>
            <CardBox>
                <Link className="btn btn-primary" to={"/user-risk-rules-edit/new"}>Create New Collection</Link>
            </CardBox>
            <EntityDataTable
                service={getContainer().getUserRiskCollectionService()}
                listRequest={listRequest}
                columns={columns}
                actions={actions}
                onPagerChange={pagerChanged}
                onAction={(item : any,action)=>{
                    return undefined;
                }} />
        </RouterViewTemplate>
    );
}
