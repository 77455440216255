import {CardBox, RouterViewTemplate, useLoadingState} from "../RouterView";
import * as React from "react";
import {useState} from "react";
import {IpRule, IpRuleAction} from "../../http/protocol";
import {getContainer} from "../../ioc/IOCSetup";
import {Form, useForm} from "../form/Form";
import {Modal} from "react-bootstrap";
import {FormGroup} from "../form/FormGroup";
import {FormInput} from "../form/FormInput";
import {Submit} from "../form/Submit";
import {SelectInput} from "../form/SelectInput";
import {Validators} from "../form/Validators";
import {DeleteButtonModal} from "../../util/DeleteButtonModal";

const EditRule = (props : { rule? : IpRule, updated : () => void, hide : () => void }) => {
    let rule = props.rule;
    if(!rule) {
        rule = {};
    }
    const [form , listener, setFormState] = useForm<IpRule>(rule, () => {
        return getContainer().getIpRuleService().save(form.model).then(m => {
            props.updated();
            return Promise.resolve();
        });
    });
    const remove = () => {
        if(!rule?.id) {
            return Promise.resolve();
        }
        return getContainer().getIpRuleService().remove("" + rule?.id).then(r => {
            props.updated()
        })
    }
    return (
        <Modal onHide={props.hide} show={true}>
            <Modal.Header>
                {rule?.id ? <>Edit Rule <DeleteButtonModal  className={"btn btn-danger"} onDelete={remove}>Remove</DeleteButtonModal></> : "Create Rule"}
            </Modal.Header>
            <Modal.Body>
                <Form formListener={listener} modelObject={form.model} validationMap={form.validationMap}>
                    {rule?.id && (
                        <FormGroup className={"form-group"}>
                            <label>ID</label>
                            <FormInput model={"id"} className={"form-control"} disabled={true}/>
                        </FormGroup>
                    )}
                    <FormGroup model={"ipAddress"} className={"form-group"}>
                        <label>IP Address</label>
                        <FormInput model={"ipAddress"} className={"form-control"} required={true} />
                    </FormGroup>
                    <FormGroup model={"description"} className={"form-group"}>
                        <label>Description</label>
                        <FormInput model={"description"} className={"form-control"} validators={[Validators.strLength(1,200)]}/>
                    </FormGroup>
                    <FormGroup model={"action"} className={"form-group"}>
                        <label>Action</label>
                        <SelectInput values={Object.keys(IpRuleAction)} required={true} model={"action"} className={"form-control"} />
                    </FormGroup>
                    <div className={"form-group"} style={{ marginTop : "10px"}}>
                        <a className={"btn btn-danger"} onClick={()=>props.hide()}>Cancel</a>
                        <Submit className={"btn btn-primary"}>Save</Submit>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
export const IpRuleView = () => {
    const [rules, setRules] = useState<IpRule[]|undefined>();
    const [editRule, setEditRule] = useState<IpRule|undefined>(undefined)

    let listRequest = { limit: 1000, offset: 0};
    const status = useLoadingState(()=>{
        return getContainer().getIpRuleService().list(listRequest).then((current) => {
            setRules(current.items);
        })
    },[]);

    const updated = () => {
        getContainer().getIpRuleService().list(listRequest).then(r => {
            setRules(r.items);
            setEditRule(undefined);
        });
    };
    return (
        <RouterViewTemplate status={status} title={"IP Rules"}>
            {editRule && <EditRule hide={()=>setEditRule(undefined)} rule={editRule} updated={updated}/>}

            <div style={{ marginBottom : "10px"}}>
                <a className={"btn btn-primary"} onClick={()=> setEditRule({})}>Add Rule</a>
            </div>
            <CardBox title={"Ip Rules"}>
                <table className={"table table-striped"}>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>IP Address</th>
                            <th>Description</th>
                            <th>Rule Action</th>
                            <th>Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                    {rules && (
                        rules.map(r => (
                            <tr key={r.id}>
                                <td>{r.id}</td>
                                <td>{r.ipAddress}</td>
                                <td>{r.description}</td>
                                <td>{r.action}</td>
                                <td><a className={"btn btn-primary"} onClick={()=>setEditRule(r)}>Edit</a></td>
                            </tr>
                        ))
                    )}
                    </tbody>
                </table>

            </CardBox>
        </RouterViewTemplate>
    )
}
