import React from "react";
import {ActionType} from "../list/list/DataTable";
import {ListAdminUsersRequest, ListRequest, UserGroup} from "../../http/protocol";
import {getContainer} from "../../ioc/IOCSetup";
import {EntityDataTable} from "../list/list/EntityDataTable";
import {PagerModel} from "../list/list/PagerModel";
import {ListUtils} from "../list/list/ListUtils";
import {withRouter} from "react-router";
import {ListView, ListViewProps, ListViewState} from "../ListView";
import {UserGroupService} from "../../services/UserGroupService";

interface State extends ListViewState<ListAdminUsersRequest> {}


interface Props extends ListViewProps {}

export class AdminUserGroupListView extends ListView<State, ListRequest> {
    columns = [
        { property : "id", name : "ID", orderBy : "ID"},
        { property : "name", name : "Name"}
    ];

    actions = [
        { id : "delete", name : "Delete", className : "btn btn-danger", iconClassName : "fa fa-eye", type : ActionType.CONFIRMATION, disabled: (item: UserGroup) => item.name === "ALL_PERMISSIONS"},
        { id : "view", name : "View", className : "btn btn-primary", iconClassName : "fa fa-eye", type : ActionType.LINK, getLink: (item: any) => "/admin/user-group/" + item.id }
    ];

    service : UserGroupService;

    constructor(props : Props) {
        super(props, "/admin/user-groups");
        this.service = getContainer().getUserGroupService();
        this.onAction = this.onAction.bind(this);
    }

    onAction(item : UserGroup, action : string): Promise<any> | undefined {
        if(item.name == "ALL_PERMISSIONS") {
            return undefined;
        }
        if (action === "delete" && item && item.id) {
            return this.service.remove("" + item.id);
        }
        return undefined;
    }

    pagerChanged(pager : PagerModel) {
        if(this.state.listRequest) {
            console.log("pager changed!", pager);
            let listRequest = ListUtils.toListRequest<any>(this.state.listRequest,pager);
            this.updateListState(listRequest);
        }
    }
    getTitle() {
        return "List User Groups"
    }

    renderContent() {
        if(!this.state.listRequest) {
            return <div/>;
        }
        return (
            <EntityDataTable
                service={this.service}
                onPagerChange={this.pagerChanged.bind(this)}
                columns={this.columns}
                listRequest={this.state.listRequest}
                onAction={this.onAction}
                actions={this.actions}
            />
        );
    }
}

export default withRouter(AdminUserGroupListView);
