import {AbstractAdminEntityService} from "./AbstractAdminEntityService";
import {HttpService} from "../http/HttpService";
import {
    ListCryptoAddressLogRequest,
    ListCryptoAddressLogResponse,
    ListPaymentsResponse, ListRequest,
    Payment, PaymentDashboardStats, PaymentResult, PaymentSummary,
    Transaction
} from "../http/protocol";


export class PaymentService extends AbstractAdminEntityService<Payment, ListPaymentsResponse> {

    constructor(http : HttpService) {
        super("/payments", http)
    }


    // async listWithCount(req : ListRequest) : Promise<ListPaymentsResponse> {
    //     return this.http.post(`${this.entityPath}/list-with-count`, req)
    // }

    async getDashboardStats() : Promise<PaymentDashboardStats> {
        return this.http.get(`${this.entityPath}/dashboard-stats`)
    }

    async getPaymentById(paymentId: number, includeLog = false){
        let url = `${this.entityPath}/id/${paymentId}?log=${includeLog}`;
        return this.http.get(url);
    };
    
    async getPaymentByUid(paymentUid: string, includeLog = false){
        let url = `${this.entityPath}/uuid/${paymentUid}?log=${includeLog}`;
        return this.http.get(url);
    };

    async getPaymentTransactions(paymentId: number): Promise<Transaction[]> {
        let url = `${this.entityPath}/transactions/${paymentId}`;
        return this.http.get(url)
    }

    async cancelPayment(paymentId: number) { 
        let url = `${this.entityPath}/cancel/${paymentId}`; 
        return this.http.get(url);
    };
    async finalizePayment(paymentId:number) {
        let url = `${this.entityPath}/finalize/${paymentId}`;
        return this.http.get(url);
    }

    async failPayment(paymentId: number) {
        let url = `${this.entityPath}/fail/${paymentId}`;
        return this.http.post(url, null);
    };

    async approvePayment(paymentId: number): Promise<PaymentResult> {
        let url = `${this.entityPath}/approve/${paymentId}`;
        return this.http.get(url);
    };

    async resolvePayment(paymentId : number, refund : boolean) {
        let url = `${this.entityPath}/resolve/${paymentId}?refund=${refund}`;
        return this.http.get(url);
    };

    async resolveDeposit(paymentId : number, creditUser : boolean) {
        let url = `${this.entityPath}/resolve-deposit/${paymentId}?credit=${creditUser}`;
        return this.http.get(url);
    };

    async getCryptoAddresses(request:ListCryptoAddressLogRequest):Promise<ListCryptoAddressLogResponse> {
        return this.http.post<{},{}>(`${this.entityPath}/cryptoaddresses`,
            request) ;
    }

    async paymentSummary(userId:number):Promise<PaymentSummary[]> {
        let url = `${this.entityPath}/summary/${userId}`;
        return this.http.get(url);
    }
}