import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { MenuComponentType, MenuPosition, MenuSettings, Settings } from "../../http/protocol";
import { Button } from "react-bootstrap";

interface Props {
  settings?: Settings;
  setSettings?: (settings: MenuSettings) => void;
}

export default function EditGeneralMenu(props: Props) {
  const { register, watch } = useForm<MenuSettings>({
    values: {
      position: props.settings?.menu?.settings?.position ?? MenuPosition.ABSOLUTE,
      iconPack: props.settings?.menu?.settings?.iconPack,
      enabeLogoSideMenu: props.settings?.menu?.settings?.enabeLogoSideMenu,
      enableLogoTopMenu: props.settings?.menu?.settings?.enableLogoTopMenu,
      menuComponents: { loggedInComponent: props.settings?.menu?.settings?.menuComponents?.loggedInComponent ?? MenuComponentType.NONE, loggedOutComponent: props.settings?.menu?.settings?.menuComponents?.loggedOutComponent ?? MenuComponentType.NONE },
    },
  });
  let position = watch("position");
  let iconPack = watch("iconPack");
  let enabeLogoSideMenu = watch("enabeLogoSideMenu");
  let enabeLogoTopMenu = watch("enableLogoTopMenu");
  let loggedInComponent = watch("menuComponents.loggedInComponent");
  let loggedOutComponent = watch("menuComponents.loggedOutComponent");

  const onSave = () => {
    if (props.setSettings) {
      props.setSettings({ position: position, iconPack: iconPack, enabeLogoSideMenu: enabeLogoSideMenu, enableLogoTopMenu: enabeLogoTopMenu, menuComponents: { loggedInComponent: loggedInComponent, loggedOutComponent: loggedOutComponent } });
    }
  };

  useEffect(() => {
    onSave();
  }, [position, iconPack, enabeLogoSideMenu, enabeLogoTopMenu, loggedInComponent, loggedOutComponent]);

  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <h4 className=" p-2 w-full d-flex justify-content-between">General Menu Settings</h4>
          <div className="col-lg-12">
            <form action="">
              <div className={"form-group mt-2"}>
                <label>Sidebar position</label>
                <select {...register("position")} className={"form-control"}>
                  {[MenuPosition.FIXED, MenuPosition.ABSOLUTE].map((v, i) => {
                    return (
                      <option key={i} value={v}>
                        {v}
                      </option>
                    );
                  })}
                </select>
                {position == MenuPosition.FIXED && <span className={"help-block"}> Selecting 'Fixed' will position the sidebar as a part of the layout. It will push the layout when expanded.</span>}
                {position == MenuPosition.ABSOLUTE && <span className={"help-block"}>Selecting 'Absolute' will position the menu as an overlay sidebar without affecting the current layout. This is the default behavior.</span>}
              </div>
              <div className={"form-group form-check p-0"}>
                <label>Icon Pack URL</label>
                <input {...register("iconPack")} className={"form-control"} type="text" placeholder="URL" />
                <span className={"help-block"}>
                  Set the URL of the CSS file containing the icon pack styles you want to use. <span className="text-warning">OBS! To use and assign icons in menu items save settings after entering url before usage.</span>{" "}
                </span>
              </div>
              <div className="hr-line-dashed mt-2"></div>

              <div className={"form-group mt-2"}>
                <label>LoggedIn component</label>
                <select {...register("menuComponents.loggedInComponent")} className={"form-control"}>
                  {[MenuComponentType.NONE, MenuComponentType.USER_LEVEL, MenuComponentType.CASHBACK].map((v, i) => {
                    return (
                      <option key={i} value={v}>
                        {v}
                      </option>
                    );
                  })}
                </select>
                <span className={"help-block"}>Select component to be showed in sidemenu for when user is loggedIn, choose NONE to disable component</span>
              </div>
              <div className={"form-group mt-2"}>
                <label>LoggedOut component</label>
                <select {...register("menuComponents.loggedOutComponent")} className={"form-control"}>
                  {[MenuComponentType.NONE, MenuComponentType.LOGIN_REGISTER, MenuComponentType.CASHBACK].map((v, i) => {
                    return (
                      <option key={i} value={v}>
                        {v}
                      </option>
                    );
                  })}
                </select>
                <span className={"help-block"}>Select component to be showed in sidemenu for when user is loggedIn, choose NONE to disable component</span>
              </div>

              <div className="hr-line-dashed mt-2"></div>

              <div className={"form-group form-check p-0 mt-2"}>
                <label>
                  <input {...register("enabeLogoSideMenu")} type={"checkbox"} /> Enable brand logo for Sidemenu
                </label>
                <span className={"help-block"}> - If you want brand logo to be displayed in Sidemenu "fixed layout"</span>
              </div>
              <div className={"form-group form-check p-0 mt-2"}>
                <label>
                  <input {...register("enableLogoTopMenu")} type={"checkbox"} /> Enable brand logo for Topmenu
                </label>
                <span className={"help-block"}> - If you want brand logo to be displayed in Topmenu "fixed layout"</span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
